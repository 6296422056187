export default {
  key1000000: `复制`,
  key1000001: `标题：`,
  key1000002: `链接地址：`,
  key1000003: `内容已被停用或删除！`,
  key1000004: `复制成功！`,
  key1000005: `请输入菜单关键词`,
  key1000006: `请输入正确的关键词`,
  key1000007: `请输入关键词`,
  key1000008: `上架工具`,
  key1000009: `通途Listing`,
  key1000010: `YMS商城`,
  key1000011: `请输入关键字`,
  key1000012: `搜索`,
  key1000013: `全部商品类目`,
  key1000014: `当前有 `,
  key1000015: ` 条订单超时未发，`,
  key1000016: `请点击处理`,
  key1000017: `运营管理`,
  key1000018: `仓储管理`,
  key1000019: `查看更多`,
  key1000020: `时区：`,
  key1000021: `您当前的时间：`,
  key1000022: `世界标准时间：`,
  key1000023: `登出`,
  key1000024: `运营系统`,
  key1000025: `供应商系统`,
  key1000026: `分销商系统`,
  key1000027: `商户编号：`,
  key1000028: `通途客户编号：`,
  key1000029: `正在退出...`,
  key1000030: `成为分销商`,
  key1000031: `立即注册`,
  key1000032: `成为供应商`,
  key1000033: `供应商入驻`,
  key1000034: `入驻指南`,
  key1000035: `供应商登录`,
  key1000036: `帮助中心`,
  key1000037: `常见问题`,
  key1000038: `法律声明`,
  key1000039: `服务条款`,
  key1000040: `隐私政策`,
  key1000041: `关于我们`,
  key1000042: `YMS商城介绍`,
  key1000043: `平台业务`,
  key1000044: `联系我们`,
  key1000045: `业务咨询`,
  key1000046: `分销商在线服务`,
  key1000047: `深圳云卖供应链管理有限公司`,
  key1000048: `备案号：粤ICP备2020140415号-1`,
  key1000049: `多`,
  key1000050: `海量优质货源`,
  key1000051: `20w+SKU，每日上新500+`,
  key1000052: `快`,
  key1000053: `一键上传全平台`,
  key1000054: `开店必备，5分钟开好店铺`,
  key1000055: `好`,
  key1000056: `专业团队品质保障`,
  key1000057: `专业拣货、分拣、打包、发货等服务`,
  key1000058: `省`,
  key1000059: `0库存开店，无资金压力`,
  key1000060: `无需囤货备货，先出单后付款`,
  key1000061: `请输入要搜索的关键词`,
  key1000062: `上传图片搜索`,
  key1000063: `一站式跨境服装分销`,
  key1000064: `上传文件格式有误,`,
  key1000065: `请选择jpg、jpeg、png格式文件`,
  key1000066: `文件大小受限,文件`,
  key1000067: `太大,`,
  key1000068: `不能超过20M`,
  key1000069: `全部商品`,
  key1000070: `已停售`,
  key1000071: `售卖国：`,
  key1000072: `商户号：`,
  key1000073: `退出`,
  key1000074: `请登录`,
  key1000075: `免费注册`,
  key1000076: `消息`,
  key1000077: `入门指引`,
  key1000078: `个人中心`,
  key1000079: `我的收藏`,
  key1000080: `我的订单`,
  key1000081: `Hi，欢迎来到TONGTOOL！`,
  key1000082: `Hi，欢迎来到YMS商城！`,
  key1000083: `搜全站`,
  key1000084: `搜本店`,
  key1000085: `操作成功!`,
  key1000086: `商品名称：`,
  key1000087: `请选择`,
  key1000088: `无匹配数据`,
  key1000089: `加载中`,
  key1000090: `暂无数据`,
  key1000091: `更多`,
  key1000092: `删除`,
  key1000093: `暂无筛选结果`,
  key1000094: `筛选`,
  key1000095: `重置`,
  key1000096: `全部`,
  key1000097: `确定`,
  key1000098: `取消`,
  key1000099: `自定义列展示`,
  key1000100: `自定义列显示`,
  key1000101: `恢复默认`,
  key1000102: `保存为默认`,
  key1000103: `保存成功`,
  key1000104: `更多筛选项`,
  key1000105: `最近一周`,
  key1000106: `最近一个月`,
  key1000107: `最近三个月`,
  key1000108: `现货一件代发`,
  key1000109: `物流面单`,
  key1000110: `云仓面单`,
  key1000111: `无面单`,
  key1000112: `混合面单`,
  key1000113: `文件大小超出限制，最大为10M`,
  key1000114: `系统繁忙，上传文件失败！`,
  key1000115: `文件格式有误`,
  key1000116: `收起`,
  key1000117: `没有相关记录`,
  key1000118: `系统繁忙，请重新尝试`,
  key1000119: `密码不能为空`,
  key1000120: `密码不能包含中文`,
  key1000121: `请填写邮件地址`,
  key1000122: `不是有效的邮箱或者邮箱格式不正确`,
  key1000123: `手机号码不能为空`,
  key1000124: `手机号码格式不正确`,
  key1000125: `只允许输入数字格式号码`,
  key1000126: `固定电话号码格式不正确`,
  key1000127: `省市区地址不能为空`,
  key1000128: `请选择完整的省市区地址`,
  key1000129: `确认是否删除`,
  key1000130: `温馨提示`,
  key1000131: `操作成功`,
  key1000132: `包裹号`,
  key1000133: `资料覆盖提醒`,
  key1000134: `新的售卖产品资料将覆盖已经生成过的售卖产品资料，生成后不可恢复，是否生成？`,
  key1000135: `立即生成`,
  key1000136: `请先勾选要生成售卖资料的数据！`,
  key1000137: `任务生成成功，请至生成售卖资料模块中查看结果。`,
  key1000138: `当前用户没有未开启仓储管理设置`,
  key1000139: `知道了`,
  key1000140: `当前用户已开启仓储管理设置`,
  key1000141: `调价`,
  key1000142: `跳转失败！`,
  key1000143: `普货`,
  key1000144: `纯电池`,
  key1000145: `配套电池`,
  key1000146: `液体`,
  key1000147: `药品`,
  key1000148: `粉末`,
  key1000149: `危险品`,
  key1000150: `内置电池`,
  key1000151: `纽扣电池`,
  key1000152: `带磁`,
  key1000153: `1天`,
  key1000154: `2天`,
  key1000155: `3天`,
  key1000156: `4天`,
  key1000157: `5天`,
  key1000158: `6天`,
  key1000159: `7天`,
  key1000160: ` 相同`,
  key1000161: `图片`,
  key1000162: `未匹配`,
  key1000163: `指定产品`,
  key1000164: `指定站点`,
  key1000165: `指定帐号`,
  key1000166: `并且`,
  key1000167: `或者`,
  key1000168: `以`,
  key1000169: `不以`,
  key1000170: `运输类型为空`,
  key1000171: `添加成功`,
  key1000172: `操作失败，请重新尝试`,
  key1000173: `删除失败,请重新尝试`,
  key1000174: `指定店铺`,
  key1000175: `指定国家`,
  key1000176: `指定分类`,
  key1000177: `请将邮编段填写完整`,
  key1000178: `指定基础物流`,
  key1000179: `多品`,
  key1000180: `单品`,
  key1000181: `拣货单:`,
  key1000182: `拣货仓库：`,
  key1000183: `物流商:`,
  key1000184: `拣货单类型：`,
  key1000185: `出库单数量：`,
  key1000186: `创建时间：：`,
  key1000187: `SKU总数：`,
  key1000188: `拣货人员：`,
  key1000189: `SKU种类：`,
  key1000190: `所在库区`,
  key1000191: `所在库位`,
  key1000192: `需拣货数量`,
  key1000193: `中文描述`,
  key1000194: `产品规格`,
  key1000195: `英文描述`,
  key1000196: `批次号`,
  key1000197: `物流商`,
  key1000198: `等`,
  key1000199: `物流渠道`,
  key1000200: `多种物流渠道`,
  key1000201: `选择引用的渠道`,
  key1000202: `请选择物流商：`,
  key1000203: `请选择物流渠道：`,
  key1000204: `物流渠道名称不能为空`,
  key1000205: `关闭`,
  key1000206: `SKU信息`,
  key1000207: `规格：`,
  key1000208: `分配数量`,
  key1000209: `可用数量`,
  key1000210: `可用数量必须是大于等于0的整数`,
  key1000211: `供应商库存详情`,
  key1000212: `中心仓库存详情`,
  key1000213: `处理中...`,
  key1000214: `选择类目`,
  key1000215: `已选类名：`,
  key1000216: `操作日志`,
  key1000217: `属性`,
  key1000218: `属性值`,
  key1000219: `操作类型`,
  key1000220: `操作内容描述`,
  key1000221: `操作时间`,
  key1000222: `操作人`,
  key1000223: `分销商操作`,
  key1000224: `供应商`,
  key1000225: `原值：`,
  key1000226: `修改后：`,
  key1000227: `编辑属性`,
  key1000228: `必填`,
  key1000229: `非必填`,
  key1000230: `推荐填写`,
  key1000231: `规格属性`,
  key1000232: `普通属性`,
  key1000233: `属性值多选`,
  key1000234: `属性值单选`,
  key1000235: `是尺码属性`,
  key1000236: `不是尺码属性`,
  key1000237: `允许自定义属性值`,
  key1000238: `不允许自定义属性值`,
  key1000239: `编辑属性值`,
  key1000240: `处理费修改：`,
  key1000241: `物流费修改：`,
  key1000242: `原价：`,
  key1000243: `货品金额修改：`,
  key1000244: `菜单设置`,
  key1000245: `快捷搜索侧边栏菜单`,
  key1000246: `仓库：`,
  key1000247: `上传文件：`,
  key1000248: `文件名称：`,
  key1000249: `导入的数据已存在时：`,
  key1000250: `请选择文件`,
  key1000251: `模板下载`,
  key1000252: `忽略`,
  key1000253: `更新`,
  key1000254: `覆盖`,
  key1000255: `追加`,
  key1000256: `导入成功，当前导入的ymsSku不存在：`,
  key1000257: `仓库`,
  key1000258: `导入`,
  key1000259: `上传文件失败，请重新尝试`,
  key1000260: `提示`,
  key1000261: `上传成功`,
  key1000262: `库位所属库区不匹配：`,
  key1000263: `所选仓库内无此库区，请核对后重试`,
  key1000264: `所选仓库内无此库位，请核对后重试`,
  key1000265: `存在重复数据，请确认后重试`,
  key1000266: `导入新增库存货主角色存在异常，请核对后重试`,
  key1000267: `导入新增库存货品供应商商户错误`,
  key1000268: `下载文件模板失败！`,
  key1000269: `请先选择上传文件`,
  key1000270: `导出类型：`,
  key1000271: `任务编号：`,
  key1000272: `导出时间：`,
  key1000273: `选择日期`,
  key1000274: `查询`,
  key1000275: `序号`,
  key1000276: `任务编号`,
  key1000277: `导出类型`,
  key1000278: `导出时间`,
  key1000279: `导出状态`,
  key1000280: `正在导出`,
  key1000281: `系统操作`,
  key1000282: `备注`,
  key1000283: `操作`,
  key1000284: `文件保留7天，超过7天将不能下载！`,
  key1000285: `下载文件`,
  key1000286: `导出中`,
  key1000287: `导出完成`,
  key1000288: `导出失败`,
  key1000289: `日期格式有误`,
  key1000290: `消息中心`,
  key1000291: `查看全部通知`,
  key1000292: `请输入`,
  key1000293: `已选`,
  key1000294: `项`,
  key1000295: `提交`,
  key1000296: `跳过`,
  key1000297: `其他`,
  key1000298: `必填项不能为空！`,
  key1000299: `请输入类目关键词`,
  key1000300: `全部分类`,
  key1000301: `商品可售卖国：`,
  key1000302: `无可用选项`,
  key1000303: `商品售卖国设置`,
  key1000304: `商品售卖国修改`,
  key1000305: `设置售卖国成功！`,
  key1000306: `编辑售卖国成功！`,
  key1000307: `主料选择`,
  key1000308: `工厂：`,
  key1000309: `工厂SPU`,
  key1000310: `请输入工厂SPU`,
  key1000311: `工厂SKU`,
  key1000312: `请输入工厂SKU`,
  key1000313: `商品名称`,
  key1000314: `请输入商品名称`,
  key1000315: `工厂不能为空`,
  key1000316: `商品信息`,
  key1000317: `工厂SPU：`,
  key1000318: `工厂SKU：`,
  key1000319: `所属分类`,
  key1000320: `POD平台未绑定`,
  key1000321: `暂未绑定POD平台，请绑定后重试。`,
  key1000322: `前往绑定`,
  key1000323: `关联属性管理`,
  key1000324: `新增关联属性`,
  key1000325: `从属性池选择`,
  key1000326: `规格`,
  key1000327: `普通`,
  key1000328: `是`,
  key1000329: `否`,
  key1000330: `单选`,
  key1000331: `多选`,
  key1000332: `属性中文名称`,
  key1000333: `属性值类型`,
  key1000334: `可选值`,
  key1000335: `自定义`,
  key1000336: `是否自定义`,
  key1000337: `填写要求`,
  key1000338: `单选/多选`,
  key1000339: `查看`,
  key1000340: `移除`,
  key1000341: `是否确认将该属性移出关联属性列表？`,
  key1000342: `请输入承运商名称`,
  key1000343: `承运商名称：`,
  key1000344: `承运人`,
  key1000345: `承运商名称`,
  key1000346: `承运人电话`,
  key1000347: `是否可用`,
  key1000348: `可用`,
  key1000349: `不可用`,
  key1000350: `选取`,
  key1000351: `承运商的名称不能为空!`,
  key1000352: `所属站点：`,
  key1000353: `规格/普通属性：`,
  key1000354: `属性值类型：`,
  key1000355: `属性值单选/多选：`,
  key1000356: `填写要求：`,
  key1000357: `是否尺码属性：`,
  key1000358: `是否需填写比例：`,
  key1000359: `属性名称`,
  key1000360: `属性单位`,
  key1000361: `尺码组名：`,
  key1000362: `添加属性单位`,
  key1000363: `允许自定义`,
  key1000364: `添加属性值`,
  key1000365: `属性已被使用，不可进行修改。`,
  key1000366: `属性值已被使用，不可进行修改。`,
  key1000367: `属性单位（`,
  key1000368: `新增属性`,
  key1000369: `中文名称`,
  key1000370: `英文-US`,
  key1000371: `英文-UK`,
  key1000372: `英文-AU`,
  key1000373: `英文-EU`,
  key1000374: `法语名称`,
  key1000375: `德语名称`,
  key1000376: `西班牙语名称`,
  key1000377: `荷兰语名称`,
  key1000378: `波兰语名称`,
  key1000379: `葡萄牙语名称`,
  key1000380: `意大利语名称`,
  key1000381: `罗马尼亚语名称`,
  key1000382: `法码`,
  key1000383: `德码`,
  key1000384: `西班牙码`,
  key1000385: `荷兰码`,
  key1000386: `波兰码`,
  key1000387: `葡萄牙码`,
  key1000388: `意大利码`,
  key1000389: `罗马尼亚码`,
  key1000390: `已存在相同名称的属性，请修改属性名称后再提交！`,
  key1000391: `中文、英文-US属性名称不能为空!`,
  key1000392: `同语种的属性值都要必填!`,
  key1000393: `同一个语种下的属性值不能相同！`,
  key1000394: `新增成功`,
  key1000395: `上传失败`,
  key1000396: `是否取消该尺码组别？`,
  key1000397: `是否确认取消该尺码组别？确认后该尺码组别不会显示在属性值里，所填写的数据也会清空。`,
  key1000398: `确定取消`,
  key1000399: `状态`,
  key1000400: `启用`,
  key1000401: `停用`,
  key1000402: `参考图片`,
  key1000403: `上传图片`,
  key1000404: `简介`,
  key1000405: `请填写属性单位`,
  key1000406: `同语种下属性单位不能相同`,
  key1000407: `请填写国家尺码信息`,
  key1000408: `同语种下属性值不能相同`,
  key1000409: `稍后提醒`,
  key1000410: `审核成功，系统将在指定时间发送公告`,
  key1000411: `退回成功`,
  key1000412: `取消成功`,
  key1000413: `操作成功！`,
  key1000414: `添加多张图片`,
  key1000415: `已选择上传图片：`,
  key1000416: `图片要求：`,
  key1000417: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，，若不符合该尺寸则会导致上传失败。`,
  key1000418: `图片清晰无水印；`,
  key1000419: `包含商品整体款式和商品细节，比如：自拍模特图、海报图、白底图、3D图、细节图等。`,
  key1000420: `4.点击图片可使用系统提供的裁切工具助您更好的展示商品的特点！`,
  key1000421: `点击裁剪`,
  key1000422: `删除图片`,
  key1000423: `确定上传`,
  key1000424: `图片加载失败`,
  key1000425: `文件大小超出限制，最大为5M！`,
  key1000426: `上传图片格式错误！系统仅支持JPG、JPEG、PNG、WEBP、BMP格式图片上传`,
  key1000427: `上传图片中...`,
  key1000428: `已阅读并接受`,
  key1000429: `和`,
  key1000430: `开通成功！`,
  key1000431: `需阅读并接受服务条款和隐私政策`,
  key1000432: `缺货数量不能为空`,
  key1000433: `预计到货时间不能为空`,
  key1000434: `请选择预计到货时间`,
  key1000435: `按订单查看`,
  key1000436: `按SKU查看`,
  key1000437: `缺货`,
  key1000438: `不缺货`,
  key1000439: `补货`,
  key1000440: `停售`,
  key1000441: `系统将自动将此SKU进行停售`,
  key1000442: `弹窗内单次最多可处理100行订单数据`,
  key1000443: `稍后处理`,
  key1000444: `确认`,
  key1000445: `超时订单处理`,
  key1000446: `订单号`,
  key1000447: `数量`,
  key1000448: `缺货数量`,
  key1000449: `预计到货时间`,
  key1000450: `标记缺货`,
  key1000451: `以下订单已超时，请更新预计发货时间。`,
  key1000452: `所选订单商品信息如下：`,
  key1000453: `预计到货时间要大于今天的日期！`,
  key1000454: `标记成功！`,
  key1000455: `导入运费模板`,
  key1000456: `计费方式：`,
  key1000457: `计重方式：`,
  key1000458: `选择文件：`,
  key1000459: `导入处理：`,
  key1000460: `（材积重：`,
  key1000461: `批量上传`,
  key1000462: `下载`,
  key1000463: `保存`,
  key1000464: `普通计费`,
  key1000465: `首续重计费`,
  key1000466: `固定收费`,
  key1000467: `实重`,
  key1000468: `抛重`,
  key1000469: `两者比较`,
  key1000470: `覆盖原有数据`,
  key1000471: `国家相同覆盖，不同追加`,
  key1000472: `选择上传文件`,
  key1000473: `选择指定异常`,
  key1000474: `本条件用于筛选异常状况，以下条件符合任何一项，即认为符合本条件。`,
  key1000475: `姓名字符中空格数小于`,
  key1000476: `俄罗斯邮政要求收件人地址为全名，此处可输入2`,
  key1000477: `姓名字符数小于`,
  key1000478: `输入1时，相当于收件人姓名为空`,
  key1000479: `地址字符数小于`,
  key1000480: `地址1+地址2的总字符长度`,
  key1000481: `城市名字字符数小于`,
  key1000482: `输入1时，相当于城市名称为空`,
  key1000483: `省/州名字字符数小于`,
  key1000484: `输入1时，相当于省州名称为空`,
  key1000485: `邮编字符数小于`,
  key1000486: `输入1时，相当于邮编为空`,
  key1000487: `电话号码数字字符个数小于`,
  key1000488: `电话、手机两个号码必须都小于该值才认为该条件成立`,
  key1000489: `审核通过后，自动生成`,
  key1000490: `分类：`,
  key1000491: `商品状态：`,
  key1000492: `中文标题：`,
  key1000493: `供应商SPU：`,
  key1000494: `SKU数量：`,
  key1000495: `商品总数量：`,
  key1000496: `暂存`,
  key1000497: `在售`,
  key1000498: `供应商SKU`,
  key1000499: `商品状态`,
  key1000500: `供货价`,
  key1000501: `上架`,
  key1000502: `打印条码`,
  key1000503: `打印二维码`,
  key1000504: `是否确认删除？`,
  key1000505: `打印提示`,
  key1000506: `SKU中包含中文，暂不支持条形码打印，请使用二维码打印!`,
  key1000507: `驳回原因`,
  key1000508: `大量现货`,
  key1000509: `放心推广`,
  key1000510: `付款`,
  key1000511: `勾选中`,
  key1000512: `条订单`,
  key1000513: `支付方式：`,
  key1000514: `账户余额支付`,
  key1000515: `上传支付凭证`,
  key1000516: `平台提示：`,
  key1000517: `平台会根据订单的应付总额使用账户余额进行预扣，请确保账户有足够的余额。`,
  key1000518: `平台会在“付款管理”列表中发起一条付款信息，可于此处上传支付凭证。`,
  key1000519: `付款成功`,
  key1000520: `图片裁剪`,
  key1000521: `裁切须知`,
  key1000522: `1.为了保证您的商品可以更好的展示给分销商并带来更多的订单，上传的商品图片长宽尺码必须≥800。`,
  key1000523: `2.若原始上传的图片尺码长宽≤800，则需要重新上传图片。`,
  key1000524: `3.商户可通过裁切，调整已上传图片的展示比例，突出商品的特点。`,
  key1000525: `图片宽度：`,
  key1000526: `图片高度：`,
  key1000527: `预览`,
  key1000528: `+重新上传图片`,
  key1000529: `图片尺寸至少要≥`,
  key1000530: `且`,
  key1000531: `文件大小超出限制，最大为`,
  key1000532: `加载图片中...`,
  key1000533: `裁剪图片.jpeg`,
  key1000534: `退回`,
  key1000535: `退回说明：`,
  key1000536: `请输入退回说明`,
  key1000537: `退回后此公告将流转为待修改状态，请确认是否退回修改？`,
  key1000538: `退回说明不能为空`,
  key1000539: `日志内容`,
  key1000540: `改为`,
  key1000541: `操作日期`,
  key1000542: `运营系统操作`,
  key1000543: `供应商系统操作`,
  key1000544: `SPU价格调整`,
  key1000545: `部分SKU价格调整`,
  key1000546: `SKU价格调整`,
  key1000547: `允许不同库区组的出库单进入同一张拣货单？`,
  key1000548: `允许不同库区的出库单进入同一张拣货单？`,
  key1000549: `允许不同物流商的包裹进入同一张拣货单？`,
  key1000550: `允许相同物流商不同邮寄方式的包裹进入同一张拣货单？`,
  key1000551: `每张常规拣货单最大包裹数`,
  key1000552: `每张备货拣货单最大包裹数`,
  key1000553: `允许`,
  key1000554: `不允许`,
  key1000555: `选择要刊登的店铺`,
  key1000556: `站点：`,
  key1000557: `刊登店铺：`,
  key1000558: `刊登模版：`,
  key1000559: `设置`,
  key1000560: `请选择刊登店铺`,
  key1000561: `请选择刊登模版`,
  key1000562: `刊登草稿失败`,
  key1000563: `消息通知`,
  key1000564: `没有更多数据了...`,
  key1000565: `稍后再看`,
  key1000566: `您的商品：`,
  key1000567: `，审核未通过，驳回原因：`,
  key1000568: `处理中`,
  key1000569: `已标记已读！`,
  key1000570: `不能为空`,
  key1000571: `供应商币种`,
  key1000572: `添加供应商币种`,
  key1000573: `添加分销商币种`,
  key1000574: `更换币种`,
  key1000575: `分销商币种`,
  key1000576: `币种`,
  key1000577: `库存事务明细`,
  key1000578: `库存变化类型：`,
  key1000579: `操作时间：`,
  key1000580: `选择开始日期`,
  key1000581: `选择结束日期`,
  key1000582: `到`,
  key1000583: `excel导出`,
  key1000584: `可用库存变化`,
  key1000585: `已分配库存变化`,
  key1000586: `事务类型`,
  key1000587: `配货`,
  key1000588: `取消配货`,
  key1000589: `出库`,
  key1000590: `盘点`,
  key1000591: `分拣撤回`,
  key1000592: `加工入库`,
  key1000593: `加工出库`,
  key1000594: `可用库存结存`,
  key1000595: `分配库存变化`,
  key1000596: `分配库存结存`,
  key1000597: `相关单据号`,
  key1000598: `收货`,
  key1000599: `分配`,
  key1000600: `取消分配`,
  key1000601: `库存移动`,
  key1000602: `库存冻结`,
  key1000603: `取消冻结`,
  key1000604: `库存调整`,
  key1000605: `调拨出库`,
  key1000606: `收货上架`,
  key1000607: `调整顺序`,
  key1000608: `取消出库`,
  key1000609: `同步库存`,
  key1000610: `取消收货`,
  key1000611: `回收库存`,
  key1000612: `归库`,
  key1000613: `导入库存`,
  key1000614: `更新库存`,
  key1000615: `获取列表数据失败`,
  key1000616: `选择日期时间`,
  key1000617: `请输入模板名称`,
  key1000618: `已生成的报表文件，系统最多保留7天，超过7天不支持下载。`,
  key1000619: `查看导出列表`,
  key1000620: `导出模板：`,
  key1000621: `管理导出模板`,
  key1000622: `返回`,
  key1000623: `时间范围：`,
  key1000624: `请选择导出维度：`,
  key1000625: `请勾选需导出的字段：`,
  key1000626: `全选`,
  key1000627: `存为新模板`,
  key1000628: `导出`,
  key1000629: `未选择`,
  key1000630: `新增成功！`,
  key1000631: `模板名称不能为空！`,
  key1000632: `是否要删除当前模板？`,
  key1000633: `删除成功`,
  key1000634: `选择邮寄方式`,
  key1000635: `全部收起`,
  key1000636: `全部展开`,
  key1000637: `图片上传规范`,
  key1000638: `轮播图上传规范`,
  key1000639: `推荐按以下顺序上传图片，合理清晰的图片顺序方便查看商品。`,
  key1000640: `单张图片尺寸`,
  key1000641: `尺寸建议800*800px以上（800*800px，1000*1000px，1500*1500px）最大不超过5MB。`,
  key1000642: `比例为正方形，合适的尺寸能看清楚细节。`,
  key1000643: `我知道了`,
  key1000644: `enter可以输入自定义属性`,
  key1000645: `请选择规格`,
  key1000646: `基本信息`,
  key1000647: `类型：`,
  key1000648: `商品类目：`,
  key1000649: `商品货号/SPU：`,
  key1000650: `请输入商品货号/SPU`,
  key1000651: `选择主料：`,
  key1000652: `商品轮播图：`,
  key1000653: `批量编辑组装信息：`,
  key1000654: `图片类型：`,
  key1000655: `商品标题组成:商品特征+商品品类即可，不需要加年份、eBay、 亚马逊等字眼`,
  key1000656: `商品属性`,
  key1000657: `仅限输入数字及小数点`,
  key1000658: `价格库存`,
  key1000659: `服务与承诺`,
  key1000660: `商品服务：`,
  key1000661: `发货时效：`,
  key1000662: `商品销售建议`,
  key1000663: `查看示例`,
  key1000664: `组装信息`,
  key1000665: `、主属性图片不能为空`,
  key1000666: `供货价格`,
  key1000667: `设置此商品在`,
  key1000668: `累计销售达到一定数量后进行优惠的规则，此优惠将直接给到分销商，但分销商无法直接查看设置的优惠规则，仅在分销商达到某一分层的数量后才能查看下一分层优惠，请放心设置。`,
  key1000669: `提交审批`,
  key1000670: `普通商品`,
  key1000671: `组装商品`,
  key1000672: `原材料`,
  key1000673: `选择商品类目`,
  key1000674: `修改类目`,
  key1000675: `准确选择类目有利于商品曝光，促进转化。`,
  key1000676: `为了方便管理，可以自定义商品编码，比如：货号，发布成功后生效。`,
  key1000677: `选择工厂主料`,
  key1000678: `重新选择`,
  key1000679: `首图`,
  key1000680: `上传网络图片`,
  key1000681: `首图优先带背景模特拼图，没有拼图时选择模特正面图，带背景模特图按一定顺序排列。`,
  key1000682: `查看规范`,
  key1000683: `图片要求`,
  key1000684: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，若不符合该尺寸则会导致上传失败。`,
  key1000685: `推荐JPG、JPEG格式图片，但平台支持PNG、WEBP、BMP格式图片上传（受限平台限制会将非JPG、JPEG格式都转化为JPEG格式，但会导致图片会有略微失真）`,
  key1000686: `编辑`,
  key1000687: `自拍模特图`,
  key1000688: `网红图`,
  key1000689: `实物摆拍图`,
  key1000690: `自动生成`,
  key1000691: `Listing商品属性信息：`,
  key1000692: `展开填写更多属性`,
  key1000693: `以下属性需填写属性值比例：`,
  key1000694: `请先选择商品类目`,
  key1000695: `请准确填写属性，更多的商品属性将有利于提高下单转化率。`,
  key1000696: `商品规格`,
  key1000697: `仅支持第一组规格设置图片，套装首图要选择套装图，每个规格最多上传5张图片，建议尺寸：800*800px。`,
  key1000698: `多规格排序`,
  key1000699: `规格明细`,
  key1000700: `自定义列`,
  key1000701: `尺码表`,
  key1000702: `请先添加商品规格`,
  key1000703: `固定金额`,
  key1000704: `折扣`,
  key1000705: `添加分层`,
  key1000706: `提交审核后暂不上架`,
  key1000707: `审核通过`,
  key1000708: `驳回`,
  key1000709: `该商品类目成功匹配YMS商品类目信息`,
  key1000710: `“，确认选择该主料后将会替换当前商品类目信息，商品规格等设置信息会被新类目对应的信息覆盖。`,
  key1000711: `商品分类不能为空`,
  key1000712: `商品货号/SPU不能为空`,
  key1000713: `商品轮播图不能为空`,
  key1000714: `图片类型不能为空`,
  key1000715: `累计购买数量`,
  key1000716: `至`,
  key1000717: `不可小于等于左区间`,
  key1000718: `优惠金额`,
  key1000719: `折扣系数`,
  key1000720: `请输入优惠金额,如：1.00`,
  key1000721: `请输入折扣系数,如：0.1`,
  key1000722: `分层价格参考`,
  key1000723: `优惠价：`,
  key1000724: `无限`,
  key1000725: `删除分层`,
  key1000726: `商品`,
  key1000727: `属性值已被停用，请更换为相近的属性值`,
  key1000728: `属性值异常，请更换属性值`,
  key1000729: `基础属性的可选值最多只能选取5个!`,
  key1000730: `此属性已存在，请输入其他属性名。`,
  key1000731: `更新成功`,
  key1000732: `选项未填写完整`,
  key1000733: `商品属性的输入框仅限输入数字及小数点！`,
  key1000734: `商品中文名称不能为空！`,
  key1000735: `属性值比例不能为空`,
  key1000736: `属性值比例必须是大于0的正整数`,
  key1000737: `每个属性的属性值比例总和要等于100%`,
  key1000738: `规格属性存在已停用属性值，更换为相近的属性值后重试。`,
  key1000739: `规格属性属性值异常，请更换属性值后重试。`,
  key1000740: `基础属性的属性值异常，请更换属性值后重试。`,
  key1000741: `审核成功!`,
  key1000742: `均码`,
  key1000743: `、输入值后enter`,
  key1000744: ` 自动生成`,
  key1000745: `自动生成sku规则：商品货号/SPU + 规格属性`,
  key1000746: `包装尺寸（长，宽，高cm）`,
  key1000747: `包装长宽高各边最长不超过40cm，泳装除外，春夏款式高度最高不超过3cm，最低不低于1cm`,
  key1000748: `主料尺寸（长、宽、高cm）：`,
  key1000749: `长`,
  key1000750: `宽`,
  key1000751: `高`,
  key1000752: `长宽高`,
  key1000753: `重量`,
  key1000754: `重量(g)`,
  key1000755: `重量填写最大码克重`,
  key1000756: `主料重量（g）：`,
  key1000757: `供货价（CNY）`,
  key1000758: `供货价（`,
  key1000759: `建议供货价小于或等于1688价格`,
  key1000760: `库存`,
  key1000761: `库存为整数`,
  key1000762: `是否组装`,
  key1000763: `主料SKU信息`,
  key1000764: `加工主料未选择`,
  key1000765: `点击选择加工主料`,
  key1000766: `辅料工艺`,
  key1000767: `请先选择主料信息`,
  key1000768: `点击选择辅料工艺`,
  key1000769: `辅料工艺未选择`,
  key1000770: `位置&素材`,
  key1000771: `设置第一列'位置&素材'信息后，勾选可使拥有相同可用打印位置的商品获得相同'位置&素材'设置，但您需自行判断，不同规格的商品，是否存在信息需要微调。`,
  key1000772: `位置&素材未设置`,
  key1000773: `(属性值已被停用)`,
  key1000774: `预览图`,
  key1000775: `活动价`,
  key1000776: `活动价（`,
  key1000777: `销售价配置`,
  key1000778: `百分比加价数值不能为空`,
  key1000779: `固定加价数值不能为空`,
  key1000780: `仅支持输入数字及小数点`,
  key1000781: `减价不能大于等于供货价`,
  key1000782: `下浮不能大于等于100%`,
  key1000783: `最多只允许输入四位小数`,
  key1000784: `上浮`,
  key1000785: `下浮`,
  key1000786: `加价`,
  key1000787: `减价`,
  key1000788: `百分比加价`,
  key1000789: `固定加价`,
  key1000790: `请输入浮动比例`,
  key1000791: `请输入数值`,
  key1000792: `销售价`,
  key1000793: `销售价（`,
  key1000794: `商品货号/SPU不能为空!`,
  key1000795: `尺码`,
  key1000796: `单个规格最多上传5张图片`,
  key1000797: `关闭提示`,
  key1000798: `内容未保存提醒`,
  key1000799: `未保存的内容将丢失，请确认是否退出？`,
  key1000800: `确认退出`,
  key1000801: `标题不能为空`,
  key1000802: `选择主料确认`,
  key1000803: `该主料不可用`,
  key1000804: `该主料商品类目不属于YMS商品类目表中类目内容，请更换选择主料，或手动绑定工厂加工商品。`,
  key1000805: `切换主料SKU确认`,
  key1000806: `切换主料SKU后，部位&素材设置信息需要重新填写。请确认是否切换主料SKU?`,
  key1000807: `确认切换`,
  key1000808: `商品规格不可为空，必须选择填写一种商品规格信息`,
  key1000809: `填充的数据不能为0`,
  key1000810: `填充`,
  key1000811: `计费模型编辑`,
  key1000812: `支持中文、英文、二字码搜索`,
  key1000813: `材积(kg)：`,
  key1000814: `材积重：`,
  key1000815: `（材积(kg) = 长(cm) × 宽(cm) × 高(cm) ÷`,
  key1000816: `验证运费是否正确：重量(`,
  key1000817: `已选中国家：`,
  key1000818: `清空`,
  key1000819: `设置邮编`,
  key1000820: `查看邮编`,
  key1000821: `新增重量区间`,
  key1000822: `验证运费`,
  key1000823: `测试运费：`,
  key1000824: `运费 = (重量 * 单价 + 挂号费 + 操作费) * (1 + 附加费)`,
  key1000825: `重量小于等于首重，运费=首重运费*（1+附加费）`,
  key1000826: `重量大于首重，运费={首重运费 +向上取整(总重量-首重)/续重单位重量) * 续重单价 + 挂号费 + 操作费}*（1+附加费）`,
  key1000827: `运费 = (单价 + 挂号费 + 操作费) * (1 + 附加费)`,
  key1000828: `不在重量区间之内`,
  key1000829: `起始重量`,
  key1000830: `截止重量`,
  key1000831: `挂号费`,
  key1000832: `操作费`,
  key1000833: `附加费（%）`,
  key1000834: `价格`,
  key1000835: `首重（`,
  key1000836: `首重运费(`,
  key1000837: `续重单位重量（`,
  key1000838: `续重单价(`,
  key1000839: `截止重量要大于起始重量`,
  key1000840: `请选择国家`,
  key1000841: `价格不能为空`,
  key1000842: `首重不能为空`,
  key1000843: `首重运费不能为空`,
  key1000844: `续重单位重量不能为空`,
  key1000845: `续重单价不能为空`,
  key1000846: `材积重不能为空`,
  key1000847: `订阅状态：`,
  key1000848: `选择确认`,
  key1000849: `创建后若后续平台订单中存在此商品都无需手动匹配YMS商品，可直接通过此关联关系进行此YMS商品匹配。提示：已创建的关联关系可通过路径“商品管理-收藏的商品-操作项[设置关联关系]”进行查看/编辑;（未收藏的商品也可创建关联关系，可在收藏后对其进行查看/编辑）`,
  key1000850: `条`,
  key1000851: `已选择：`,
  key1000852: `设为主料`,
  key1000853: `是否确认选择该YMS商品进行订单商品匹配？`,
  key1000854: `创建关联关系`,
  key1000855: `SPU,支持多个按行分隔`,
  key1000856: `SKU,支持多个按行分隔`,
  key1000857: `中心仓库存`,
  key1000858: `添加商品`,
  key1000859: `已收藏`,
  key1000860: `未收藏`,
  key1000861: `供应商SPU`,
  key1000862: `请输入供应商SPU,支持多个按行分隔`,
  key1000863: `请输入SPU,支持多个逗号和按行分隔`,
  key1000864: `请输入SKU,支持多个逗号和按行分隔`,
  key1000865: `请输入商品名称,支持多个逗号和按行分隔`,
  key1000866: `供应商商户号`,
  key1000867: `商品标题`,
  key1000868: `请输入商品标题`,
  key1000869: `供应商名称`,
  key1000870: `供应商库存`,
  key1000871: `商品SPU：`,
  key1000872: `商品SKU：`,
  key1000873: `可用库存`,
  key1000874: `近30天销量`,
  key1000875: `选择`,
  key1000876: `我的库存`,
  key1000877: `在途库存：`,
  key1000878: `可用库存：`,
  key1000879: `单价`,
  key1000880: `长宽高(cm)`,
  key1000881: `试卖`,
  key1000882: `清仓`,
  key1000883: `商品数量不能为空!`,
  key1000884: `请先选择商品！`,
  key1000885: `该商品现已存在关联关系！`,
  key1000886: `系统公告`,
  key1000887: `不再提醒`,
  key1000888: `商品搜索：`,
  key1000889: `主料`,
  key1000890: `辅料`,
  key1000891: `请输入完整SPU或商品名称`,
  key1000892: `请输入完整SPU / SKU或商品名称`,
  key1000893: `辅料的数量不能为空！`,
  key1000894: `主料的数量不能为空！`,
  key1000895: `请先选择主料数据！`,
  key1000896: `你没有访问该页面的权限`,
  key1000897: `返回上一页`,
  key1000898: `抱歉，您访问的页面不存在！`,
  key1000899: `返回系统首页`,
  key1000900: `返回有权限菜单`,
  key1000901: `请先勾选需要展示的自定义列表字段！`,
  key1000902: `介质材料`,
  key1000903: `标签大小`,
  key1000904: `起止信息`,
  key1000905: `最大支持100mm`,
  key1000906: `字体`,
  key1000907: `每pt约占0.35mm宽`,
  key1000908: `*注:打印内容可拖动改变位置`,
  key1000909: `打印`,
  key1000910: `不打印`,
  key1000911: `条码`,
  key1000912: `自定义内容`,
  key1000913: `产品库位`,
  key1000914: `产品库位预览占位`,
  key1000915: `产品绑定库区`,
  key1000916: `产品绑定库区预览占位`,
  key1000917: `参考编号`,
  key1000918: `参考编号预览占位`,
  key1000919: `通过拖拽调整规格排序`,
  key1000920: `打印箱唛`,
  key1000921: `创建时间：`,
  key1000922: `打印数量：`,
  key1000923: `打印YMS面单`,
  key1000924: `打印数量必须大于0`,
  key1000925: `分类检索`,
  key1000926: `最近使用：`,
  key1000927: `已选类目：`,
  key1000928: `切换类目确认`,
  key1000929: `切换类目后，商品属性及规格等信息需要重新填写。请确认是否切换分类?`,
  key1000930: `请选择分类`,
  key1000931: `请输入手机号码`,
  key1000932: `请输入图形验证码`,
  key1000933: `验证码图片`,
  key1000934: `请输入验证码`,
  key1000935: `请输入密码`,
  key1000936: `请再次确认密码`,
  key1000937: `邮箱（选填）`,
  key1000938: `完善账号信息`,
  key1000939: `温馨提示:为了你的账号安全，请绑定手机号码，绑定后可通过手机号码和密码登录。`,
  key1000940: `绑定手机号码`,
  key1000941: `暂不绑定`,
  key1000942: `发送验证码`,
  key1000943: `图形验证码不能为空`,
  key1000944: `验证码不能为空`,
  key1000945: `重新发送`,
  key1000946: `手机验证码已发送！`,
  key1000947: `确认新密码不能为空`,
  key1000948: `密码与确认密码不一致`,
  key1000949: `绑定手机成功`,
  key1000950: `请先勾选已阅读并接受`,
  key1000951: `编辑模板`,
  key1000952: `模板名称：`,
  key1000953: `编辑成功！`,
  key1000954: `支持批量输入，请换行分隔`,
  key1000955: `截取邮编前`,
  key1000956: `位`,
  key1000957: `邮编前`,
  key1000958: `位：`,
  key1000959: `邮编：`,
  key1000960: `邮编不能为空`,
  key1000961: `邮编格式只能是数字或者字母`,
  key1000962: `订单取消提示`,
  key1000963: `本次作业中存在已取消的订单，订单所含出库单详情信息如下。`,
  key1000964: `继续分拣`,
  key1000965: `移除已取消的出库单`,
  key1000966: `出库单号`,
  key1000967: `打印配置`,
  key1000968: `常规订单面单`,
  key1000969: `长：`,
  key1000970: `宽：`,
  key1000971: `速卖通全托管货品标签`,
  key1000972: `速卖通全托管物流面单`,
  key1000973: `常规订单面单长度和宽度不能为空！`,
  key1000974: `速卖通全托管货品标签长度和宽度不能为空！`,
  key1000975: `速卖通全托管物流面单长度和宽度不能为空！`,
  key1000976: `保存成功！`,
  key1000977: `商品驳回通知`,
  key1000978: `以下商品审核被驳回，请修改后重新提交审核:`,
  key1000979: `下次提醒`,
  key1000980: `已复制！`,
  key1000981: `选择要刊登的平台`,
  key1000982: `此类目暂未维护平台映射，如有需要请咨询客服。`,
  key1000983: `暂无可支持刊登的平台！`,
  key1000984: `未绑定店铺`,
  key1000985: `你的账号暂时未绑定店铺，或绑定的店铺已过期。`,
  key1000986: `从地址添加图片`,
  key1000987: `图片地址：`,
  key1000988: `请填写图片地址`,
  key1000989: `链接地址格式不正确`,
  key1000990: `获取上传图片失败！`,
  key1000991: `新品`,
  key1000992: `已授权列表`,
  key1000993: `可输入代号、账户名进行搜索`,
  key1000994: `代号`,
  key1000995: `账户名`,
  key1000996: `有效`,
  key1000997: `无效`,
  key1000998: `更新店铺`,
  key1000999: `添加店铺`,
  key1001000: `关键字`,
  key1001001: `规则`,
  key1001002: `可输入多个，中间用逗号或换行隔开`,
  key1001003: `可输入多个，中间用英文逗号隔开`,
  key1001004: `请输入关键字...`,
  key1001005: `多邮编换行请求半角输入逗号隔开`,
  key1001006: `请选择平台`,
  key1001007: `请输入运输类型名称搜索`,
  key1001008: `新增运输类型`,
  key1001009: `您确定要删除吗？`,
  key1001010: `美元(USD)`,
  key1001011: `或欧元(EUR)`,
  key1001012: `或英镑(GBP)`,
  key1001013: `或澳元(AUD)`,
  key1001014: `或人民币(CNY)`,
  key1001015: `请输入SKU，多个SKU用逗号或换行隔开`,
  key1001016: `请选择或输入所在地`,
  key1001017: `请选择店铺，可输入搜索`,
  key1001018: `请选择物流`,
  key1001019: `十日达订单即X日达订单，如5日达、10日达`,
  key1001020: `指定范围`,
  key1001021: `指定类型`,
  key1001022: `开头`,
  key1001023: `结尾`,
  key1001024: `指定规律`,
  key1001025: `指定SKU`,
  key1001026: `指定仓库`,
  key1001027: `或`,
  key1001028: `指定平台`,
  key1001029: `已选条件`,
  key1001030: `订单包含货品:`,
  key1001031: `体积重计重因子`,
  key1001032: `包裹货品总数量满足以下选中的条件：`,
  key1001033: `订单重量满足以下全部条件：`,
  key1001034: `订单货品长度满足以下全部条件：`,
  key1001035: `订单货品宽度满足以下全部条件：`,
  key1001036: `订单货品高度满足以下全部条件：`,
  key1001037: `订单货品体积满足以下全部条件`,
  key1001038: `订单货品总数量满足以下选中的条件：`,
  key1001039: `订单收货邮编字符长度满足以下选中的条件:`,
  key1001040: `订单包含货品：`,
  key1001041: `并且上述货品的数量总和满足以下选中的所有条件：`,
  key1001042: `与体积重取重对比取重的作为订单重量计算`,
  key1001043: `提示：订单中每件货品在商品管理中记录的最长边为a1、中间值为b1、最短边为c，所有`,
  key1001044: `a1中的最大值为a，所有b1中的最大值为b，订单中所有货品的短边c相加为d，则a、b、`,
  key1001045: `d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品高度。体积`,
  key1001046: `重(g)=(a*b*d)/计重因子*1000。`,
  key1001047: `提示：订单中每件货品在商品管理中记录的最长边为a1、中间值为b1、最短边为c，`,
  key1001048: `所有a1中的最大值为a，所有b1中的最大值为b，订单中所有货品的短边c相加为d，`,
  key1001049: `则a、b、d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品高度。`,
  key1001050: `则a、b、d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品`,
  key1001051: `高度，a*b*d即订单货品体积。`,
  key1001052: `订单包含多个交易（且运输类型完全相同`,
  key1001053: `订单仅包含一个交易`,
  key1001054: `订单包含多个交易（且运输类型不完全相同`,
  key1001055: `指定条件`,
  key1001056: `订单收件人电话符合以下选中的条件：`,
  key1001057: `移动电话可读字符长度≤`,
  key1001058: `固定电话可读字符长度≤`,
  key1001059: `移动电话前缀`,
  key1001060: `指定字符串`,
  key1001061: `指定要在地址(仅街道1+街道2，不含国家/地区省市信息)中查找的字符：`,
  key1001062: `新增`,
  key1001063: `指定长度`,
  key1001064: `订单地址信息字符长度小于：`,
  key1001065: `*此处地址信息由街道1与街道2合并组成，不包含国家/地区、省州、城市信息。`,
  key1001066: `,并且`,
  key1001067: `,或者`,
  key1001068: `包含`,
  key1001069: `指定格式`,
  key1001070: `订单邮编至少符合以下选中的条件中的任意一个条件(输入内容仅限数字、英文字母、中划线和空隔)：`,
  key1001071: `指定异常`,
  key1001072: `且邮编在`,
  key1001073: `邮编段`,
  key1001074: `邮编列表`,
  key1001075: `已选择`,
  key1001076: `注：其他币种转化成美元,所有选项都必须填写`,
  key1001077: `买家支付的运费转换为币种：`,
  key1001078: `之后满足以下全部条件：`,
  key1001079: `指定的商品状态`,
  key1001080: `并且为`,
  key1001081: `指定货品`,
  key1001082: `指定邮寄方式`,
  key1001083: `包裹总金额包含多订单合并到一包裹中的所有订单的金额，不同币种的订单的金额将被统一按汇率转换为如下设置中的币种。`,
  key1001084: `包裹总金额转换为币种：`,
  key1001085: `指定要在买家ID中查找的字符：`,
  key1001086: `规则名称：`,
  key1001087: `可选条件`,
  key1001088: `请指定`,
  key1001089: `未选择规则`,
  key1001090: `规则名称不能为空`,
  key1001091: `更多平台`,
  key1001092: `批量配置标签`,
  key1001093: `标签：`,
  key1001094: `请选择标签`,
  key1001095: `添加标签`,
  key1001096: `删除标签`,
  key1001097: `导入类型：`,
  key1001098: `请输入任务编号`,
  key1001099: `导入时间：`,
  key1001100: `导入中`,
  key1001101: `导入完成`,
  key1001102: `导入失败`,
  key1001103: `导入类型`,
  key1001104: `导入文件`,
  key1001105: `导入时间`,
  key1001106: `导入状态`,
  key1001107: `成功/失败`,
  key1001108: `成功`,
  key1001109: `失败`,
  key1001110: `位置&素材设置`,
  key1001111: `点击选择部位`,
  key1001112: `点击输入`,
  key1001113: `部位不能为空`,
  key1001114: `请先选择部位`,
  key1001115: `可印区域不能为空`,
  key1001116: `不能超过可印区域长度`,
  key1001117: `不能超过可印区域宽度`,
  key1001118: `请先输入印刷尺寸`,
  key1001119: `添加`,
  key1001120: `位置`,
  key1001121: `部位示意图`,
  key1001122: `可印区域（长、宽cm）`,
  key1001123: `印刷尺寸（长、宽cm）`,
  key1001124: `素材`,
  key1001125: `素材编码`,
  key1001126: `供应商用于标识素材的信息`,
  key1001127: `成品效果图`,
  key1001128: `切换部位确认`,
  key1001129: `切换部位后，印刷尺寸、素材等信息需要重新输入、请确认是否切换部位？`,
  key1001130: `文件大小超出限制，最大为20M！`,
  key1001131: `有必填项未填！`,
  key1001132: `跟踪信息`,
  key1001133: `运单号：`,
  key1001134: `状态：`,
  key1001135: `待查询`,
  key1001136: `查询中`,
  key1001137: `未查到`,
  key1001138: `运输中`,
  key1001139: `等待领取`,
  key1001140: `已签收`,
  key1001141: `投递失败`,
  key1001142: `运输过久`,
  key1001143: `可能异常`,
  key1001144: `申请成为分销商`,
  key1001145: `寻找商品`,
  key1001146: `收藏商品`,
  key1001147: `商品刊登&下载资料包`,
  key1001148: `下单发货`,
  key1001149: `订单管理`,
  key1001150: `财务管理`,
  key1001151: `去注册`,
  key1001152: `点击商城首页右上角“成为分销商”，使用手机号注册或使用通途账号授权登录。`,
  key1001153: `去浏览`,
  key1001154: `搜索关键字，找到想要分销的商品。`,
  key1001155: `在分类导航，找到想要分销的商品。`,
  key1001156: `在首页和核心页面，找到想要分销的商品。`,
  key1001157: `去收藏`,
  key1001158: `在商品列表页，找到商品后，点击“收藏”按钮收藏商品，以便刊登时使用。`,
  key1001159: `在商品详情页，点击“立即收藏”按钮收藏商品，以便刊登时使用。`,
  key1001160: `4.商品刊登&下载资料包`,
  key1001161: `商品刊登`,
  key1001162: `使用通途Listing，将商品一键刊登到ebay、Aliexpress、Wish、Amazon等海外电商平台。`,
  key1001163: `下载资料包`,
  key1001164: `您也可以在“商品列表”、“商品详情页”或“商品管理”页面，免费下载资料包，再到第三方销售平台商家后台上架商品。`,
  key1001165: `基础设置`,
  key1001166: `启用物流`,
  key1001167: `在通途ERP2.0，启用第三方物流，通途系统已内置了行业内用户使用数较多，口碑较好的一些物流商，您可以根据需要选择并启用。`,
  key1001168: `启用邮寄方式`,
  key1001169: `选择希望启用哪种邮寄方式，启用后会提示“邮寄方式启用成功”。`,
  key1001170: `仓库设置`,
  key1001171: `在第三方仓库中选择“云卖供应链“启用。`,
  key1001172: `绑定物流渠道`,
  key1001173: `选择“云卖供应链“仓库，在右侧点击”绑定物流渠道“，选择物流方式后，点“确认”按钮。`,
  key1001174: `生成通途SKU`,
  key1001175: `在通途ERP2.0的“商品管理-云卖分销商品”模块，点击已收藏的商品，在商品详情中，点击“生成商品资料”。`,
  key1001176: `在通途ERP2.0中，选择“发货管理-托管仓库-等待配货”模块，仓库选择“云卖供应链”，点击“云卖下单”，余额不足时，可联系客服充值。`,
  key1001177: `去查看`,
  key1001178: `在“订单管理-我的订单“模块，进行订单查询，进入“订单详情页”可以查看发货情况。`,
  key1001179: `在“财务管理”模块，可以查询充值记录和查看费用明细。`,
  key1001180: `发货方式：`,
  key1001181: `运输方式：`,
  key1001182: `物流方案：`,
  key1001183: `发货地址：`,
  key1001184: `物流商：`,
  key1001185: `物流单号：`,
  key1001186: `YMS物流`,
  key1001187: `自有物流`,
  key1001188: `快递`,
  key1001189: `YMS上门揽收`,
  key1001190: `请在`,
  key1001191: `账户管理-基本信息`,
  key1001192: `中维护发货地址信息`,
  key1001193: `请务必提前与YMS进行线下确认，是否可进行上门揽收！！`,
  key1001194: `发货时货品需贴YMS商品条码，大包外需粘贴箱唛在显眼处！`,
  key1001195: `结束装箱`,
  key1001196: `标记发货成功，提单号：`,
  key1001197: `请选择物流方案`,
  key1001198: `请选择发货地址`,
  key1001199: `请选择物流商`,
  key1001200: `物流单号不能为空`,
  key1001201: `物流信息`,
  key1001202: `标记发货`,
  key1001203: `修改物流信息`,
  key1001204: `中国`,
  key1001205: `您的浏览器不支持websocket！`,
  key1001206: `分钟前`,
  key1001207: `小时前`,
  key1001208: `天前`,
  key1001209: `周前`,
  key1001210: `个月前`,
  key1001211: `年前`,
  key1001212: `周日`,
  key1001213: `周一`,
  key1001214: `周二`,
  key1001215: `周三`,
  key1001216: `周四`,
  key1001217: `周五`,
  key1001218: `周六`,
  key1001219: `天`,
  key1001220: `小时`,
  key1001221: `分钟`,
  key1001222: `1分钟`,
  key1001223: `刚刚`,
  key1001224: `供应商操作`,
  key1001225: `该功能需使用通途打印控件，请下载安装`,
  key1001226: `点击下载`,
  key1001227: `如果已安装，请开启通途打印控件`,
  key1001228: `当前浏览器 Not support websocket`,
  key1001229: `连接菜鸟打印控件失败,请确认是否安装或启用菜鸟打印控件`,
  key1001230: `账户余额不足`,
  key1001231: `你的账户余额不足，请充值足够的金额后，再提交订单！`,
  key1001232: `去充值`,
  key1001233: `你的账户余额不足，请联系运营工作人员进行充值或选择其他支付方式，再进行订单支付!`,
  key1001234: `大洋洲`,
  key1001235: `欧洲`,
  key1001236: `亚洲`,
  key1001237: `南极`,
  key1001238: `美洲`,
  key1001239: `非洲`,
  key1001240: `加载中...`,
  key1001241: `请求超时`,
  key1001242: `账户已被停用，若需重新启用账户，请联系`,
  key1001243: `运营人员。`,
  key1001244: `您已有`,
  key1001245: `账号，可直接使用此`,
  key1001246: `账号作为供应商进行入驻。`,
  key1001247: `账号开通分销商账户。`,
  key1001248: `没有权限访问该系统`,
  key1001249: `亲爱的通途用户，您没有权限访问该系统!`,
  key1001250: `请联系，系统管理员！`,
  key1001251: `账单待确认提示`,
  key1001252: `存在未确认账单，请及时确认，若账单生成后14天内未确认账单系统将默认账单无误，自动确认账单。`,
  key1001253: `查看账单`,
  key1001254: `稍后确认`,
  key1001255: `账户停用提示`,
  key1001256: `成为供应商确认`,
  key1001257: `前往入驻`,
  key1001258: `成为分销商确认`,
  key1001259: `确认开通`,
  key1001260: `您已有通途账号，可直接使用此账号开通分销商账户。`,
  key1001261: `未揽收`,
  key1001262: `已揽收`,
  key1001263: `已分拣`,
  key1001264: `已出库`,
  key1001265: `提单，`,
  key1001266: `【提单号】`,
  key1001267: `，存在货品多发，已转为备货库存存放于`,
  key1001268: `中心仓。`,
  key1001269: `订单自动取消通知`,
  key1001270: `您的订单`,
  key1001271: `订单号】`,
  key1001272: `，已超时自动取消，请及时关注。`,
  key1001273: `直接出库`,
  key1001274: `待拣货`,
  key1001275: `拣货中`,
  key1001276: `待分拣`,
  key1001277: `分拣中`,
  key1001278: `待包装`,
  key1001279: `包装中`,
  key1001280: `包装完成`,
  key1001281: `已取消`,
  key1001282: `已创建`,
  key1001283: `备货中`,
  key1001284: `已拣货`,
  key1001285: `已换单`,
  key1001286: `已装箱`,
  key1001287: `已发货`,
  key1001288: `等待揽收`,
  key1001289: `已上架`,
  key1001290: `已入库`,
  key1001291: `已作废`,
  key1001292: `揽收分拣异常`,
  key1001293: `已打单`,
  key1001294: `单选可自定值`,
  key1001295: `多选可自定义值`,
  key1001296: `已匹配商品`,
  key1001297: `已匹配物流`,
  key1001298: `已下单`,
  key1001299: `核查中`,
  key1001300: `待发货`,
  key1001301: `部分发货`,
  key1001302: `部分签收`,
  key1001303: `部分取消`,
  key1001304: `销售出库`,
  key1001305: `自有仓备货`,
  key1001306: `三方仓备货`,
  key1001307: `多发补单`,
  key1001308: `编辑中`,
  key1001309: `待审核`,
  key1001310: `审核驳回`,
  key1001311: `货品金额`,
  key1001312: `包裹处理费`,
  key1001313: `退费`,
  key1001314: `物流处理费`,
  key1001315: `补贴金额`,
  key1001316: `国内快递费`,
  key1001317: `清关费`,
  key1001318: `订单取消处理费`,
  key1001319: `正常`,
  key1001320: `完成扣款`,
  key1001321: `预扣款`,
  key1001322: `商城注册`,
  key1001323: `人工添加`,
  key1001324: `待确认`,
  key1001325: `待收款`,
  key1001326: `已收款`,
  key1001327: `待供应商确认`,
  key1001328: `待打款`,
  key1001329: `已打款`,
  key1001330: `/yms-distributor-service/video/YMS功能介绍.mp4`,
  key1001331: `/yms-distributor-service/video/店铺授权.mp4`,
  key1001332: `/yms-distributor-service/video/刊登模板设置.mp4`,
  key1001333: `/yms-distributor-service/video/一键刊登（YMS).mp4`,
  key1001334: `/yms-distributor-service/video/一键刊登规则.mp4`,
  key1001335: `/yms-distributor-service/video/注册云卖供应链.mp4`,
  key1001336: `中文`,
  key1001337: `英文`,
  key1001338: `订单缺货提醒`,
  key1001339: `订单状态标记为缺货时`,
  key1001340: `订单编号`,
  key1001341: `你的订单：<span class="highlight_color">【订单编号】</span>缺货，请及时关注！`,
  key1001342: `备货`,
  key1001343: `订单备货提醒`,
  key1001344: `你有<span class="highlight_color">【X】</span>笔订单，备货已超时，请及时备货！`,
  key1001345: `拣货`,
  key1001346: `订单拣货提醒`,
  key1001347: `你有<span class="highlight_color">【X】</span>笔订单，拣货已超时，请及时拣货！`,
  key1001348: `换单`,
  key1001349: `订单换单提醒`,
  key1001350: `你有<span class="highlight_color">【X】</span>笔订单，换单已超时，请及时换单！`,
  key1001351: `装箱`,
  key1001352: `订单装箱提醒`,
  key1001353: `你有<span class="highlight_color">【X】</span>笔订单，装箱已超时，请及时装箱！`,
  key1001354: `发货`,
  key1001355: `订单发货提醒`,
  key1001356: `你有<span class="highlight_color">【X】</span>笔订单，发货已超时，请及时发货！`,
  key1001357: `订单取消提醒`,
  key1001358: `订单状态标记为取消时`,
  key1001359: `分销商已取消订单：<span class="highlight_color">【订单编号】</span>，请及时关注发货情况`,
  key1001360: `商品停售通知`,
  key1001361: `供应商标记SPU或SKU停售时`,
  key1001362: `尊敬的分销商您好：您收藏的：<span class="highlight_color">【商品名称】</span>，商品编码：<span class="highlight_color">【SPU/SKU】</span>已停售，如果您已上架该商品，请及时下架。`,
  key1001363: `商品描述或图片被修改时`,
  key1001364: `商品信息修改通知`,
  key1001365: `商品<span class="highlight_color">【SPU】</span>信息修改通知`,
  key1001366: `尊敬的分销商您好：您收藏的商品 <span class="highlight_color">【SPU】</span>，描述/图片已修改，如果您已上架该商品，请及时更新成最新的产品信息。`,
  key1001367: `商品价格被修改时`,
  key1001368: `商品调价通知`,
  key1001369: `商品<span class="highlight_color">【SPU】</span>调价通知`,
  key1001370: `尊敬的分销商您好：由于成本变动，您收藏的商品 <span class="highlight_color">【SPU】</span>，价格有调整，请及时关注。`,
  key1001371: `关注的供应商上新商品通过审核时`,
  key1001372: `商品上新通知`,
  key1001373: `您关注的供应商有商品上新`,
  key1001374: `您关注的供应商：<span class="highlight_color">【供应商名称】</span>有商品上新。`,
  key1001375: `入库分拣结束时，提单内存在多收货品时`,
  key1001376: `货品多发通知`,
  key1001377: `提单号`,
  key1001378: `通过分销商配置的自动规则调整库存或上架工具状态时`,
  key1001379: `商品状态自动同步通知`,
  key1001380: `您订阅的商品<span class="highlight_color">【SPU】</span>，已停售，已根据规则自动调整相关信息，请及时关注。`,
  key1001381: `通过分销商配置的自动规则调整在线刊登价格时`,
  key1001382: `在线刊登自动调价通知`,
  key1001383: `您订阅的商品<span class="highlight_color">【SPU】</span>，供货价格有调整，在线刊登价格根据规则自动调整，请及时关注。`,
  key1001384: `通过分销商配置的超时时间自动取消订单时`,
  key1001385: `通过平台订单自动下单由于余额不足导致下单失败时`,
  key1001386: `余额不足下单失败通知`,
  key1001387: `余额不足通知`,
  key1001388: `平台`,
  key1001389: `您的余额不足，<span class="highlight_color">【平台】</span>订单<span class="highlight_color">【订单号】</span>自动下单失败，请及时充值。`,
  key1001390: `商品审核被驳回时`,
  key1001391: `商品审核驳回通知`,
  key1001392: `您的商品：<span class="highlight_color">【SPU】</span>，审核未通过，驳回原因：XXXXXXXXX。`,
  key1001393: `待装箱`,
  key1001394: `缺货中`,
  key1001395: `常规单`,
  key1001396: `Temu样品订单`,
  key1001397: `Temu货品订单`,
  key1001398: `速卖通全托管订单`,
  key1001399: `使用已有IOSS`,
  key1001400: `物流代缴`,
  key1001401: `手动录入`,
  key1001402: `待付款`,
  key1001403: `等待在途库存`,
  key1001404: `待商家拣货`,
  key1001405: `待商家复核`,
  key1001406: `待商家打包`,
  key1001407: `快递运输中`,
  key1001408: `到达中心仓`,
  key1001409: `中心仓已到货`,
  key1001410: `待物流商上门取件`,
  key1001411: `已交付物流商`,
  key1001412: `英文-US名称`,
  key1001413: `英文-UK名称`,
  key1001414: `英文-AU名称`,
  key1001415: `英文-EU名称`,
  key1001416: `登录后可见`,
  key1001417: `确认中`,
  key1001418: `被退回`,
  key1001419: `已完成`,
  key1001420: `付款中`,
  key1001421: `原收件名称`,
  key1001422: `原收件邮箱`,
  key1001423: `原收件电话`,
  key1001424: `原收件用户`,
  key1001425: `原收国家编码`,
  key1001426: `原收件人手机`,
  key1001427: `原收件收货地址1`,
  key1001428: `原收件收货地址2`,
  key1001429: `原收件城市`,
  key1001430: `原收件人州/省`,
  key1001431: `原收件人邮编`,
  key1001432: `原收件人护照编码`,
  key1001433: `订单[`,
  key1001434: `]，修改地址成功:`,
  key1001435: `付款，实付金额：`,
  key1001436: `分销商已付款，实付金额：`,
  key1001437: `调价;货品原价总金额：`,
  key1001438: `;分销商应付金额：`,
  key1001439: `;供应商应收金额：`,
  key1001440: `已到仓`,
  key1001441: `标记为非`,
  key1001442: `订单,清空商品&物流信息`,
  key1001443: `自动匹配订单商品或物流失败`,
  key1001444: `原始单订单取消`,
  key1001445: `未匹配到任何物流`,
  key1001446: `匹配基础物流失败`,
  key1001447: `取消订单成功`,
  key1001448: `自动匹配物流成功：`,
  key1001449: `订单`,
  key1001450: `自动匹配物流成功:`,
  key1001451: `]，匹配物流特殊规则：[`,
  key1001452: `]，匹配适用范围:`,
  key1001453: `匹配方案规则:`,
  key1001454: `手工匹配商品成功：平台SKU：`,
  key1001455: `，匹配`,
  key1001456: `手工匹配商品失败：平台SKU：`,
  key1001457: `，未匹配`,
  key1001458: `自动匹配商品成功：平台SKU：`,
  key1001459: `自动匹配商品失败：平台SKU：`,
  key1001460: `SKU失败，SKU已停售`,
  key1001461: `下单失败，分销商余额不足`,
  key1001462: `变更物流信息，原物流方式：`,
  key1001463: `原运单号：`,
  key1001464: `原物流商单号：`,
  key1001465: `标发货失败，原因：`,
  key1001466: `标发货成功，运单号：`,
  key1001467: `取消订单失败，原因：`,
  key1001468: `下单失败`,
  key1001469: `下单成功`,
  key1001470: `自动匹配物流失败，未找到符合条件的物流方式`,
  key1001471: `(未找到符合基本条件的物流`,
  key1001472: `修改地址信息失败`,
  key1001473: `]，修改物流成功:`,
  key1001474: `原物流`,
  key1001475: `改成`,
  key1001476: `]，修改物流信息失败`,
  key1001477: `添加授权`,
  key1001478: `更新授权`,
  key1001479: `授权失效`,
  key1001480: `手动标记平台订单标发货成功`,
  key1001481: `编辑IOSS;IOSS名称：`,
  key1001482: `;IOSS编号：`,
  key1001483: `添加IOSS;IOSS名称：`,
  key1001484: `添加规则;规则名称：`,
  key1001485: `;使用的IOSS：`,
  key1001486: `;适用平台：`,
  key1001487: `;适用店铺：`,
  key1001488: `编辑规则;规则名称：`,
  key1001489: `取消交易，取消金额：`,
  key1001490: `部分取消，取消金额：`,
  key1001491: `创建备货单`,
  key1001492: `确认接单，接受调价`,
  key1001493: `拒绝调价`,
  key1001494: `未到货部分已退款，退款金额`,
  key1001495: `，币种：`,
  key1001496: `子单全部到仓，已完结`,
  key1001497: `下单`,
  key1001498: `货品缺货,补货中：;预计到货时间：`,
  key1001499: `;缺货内容如下;`,
  key1001500: `货品缺货,已停售：;缺货内容如下;`,
  key1001501: `请补充邮编信息后重试`,
  key1001502: `添加属性`,
  key1001503: `启用属性`,
  key1001504: `停用属性`,
  key1001505: `出库成功`,
  key1001506: `下发物流成功`,
  key1001507: `变更物流信息;原物流方式：`,
  key1001508: `;原运单号：`,
  key1001509: `;原物流商单号：`,
  key1001510: `支付成功`,
  key1001511: `作废订单成功`,
  key1001512: `生成账单`,
  key1001513: `更新仓储账单`,
  key1001514: `生成客户账单`,
  key1001515: `物流分拣完成`,
  key1001516: `新增费率：`,
  key1001517: `编辑费率：`,
  key1001518: `生成一条供应商账单`,
  key1001519: `生成一条分销商账单`,
  key1001520: `换单失败退回到生成拣货单列表`,
  key1001521: `从拣货单【`,
  key1001522: `】移除`,
  key1001523: `重置分拣`,
  key1001524: `标记已拣货`,
  key1001525: `已生成拣货单，拣货单编号:【`,
  key1001526: `换单失败退回到拣货单列表`,
  key1001527: `提单已揽收`,
  key1001528: `提单已创建`,
  key1001529: `提单已入库`,
  key1001530: `提单已上架`,
  key1001531: `提单已分拣`,
  key1001532: `有多余包裹，请联系供应商`,
  key1001533: `审核不通过的原因：`,
  key1001534: `由审核通过`,
  key1001535: `更改为`,
  key1001536: `审核不通过：`,
  key1001537: `由审核不通过`,
  key1001538: `审核不通过`,
  key1001539: `调整补贴`,
  key1001540: `由`,
  key1001541: `调整为`,
  key1001542: `;调整说明：`,
  key1001543: `运营人工添加供应商`,
  key1001544: `停用供应商`,
  key1001545: `启用供应商`,
  key1001546: `编辑供应商`,
  key1001547: `商城入驻注册供应商`,
  key1001548: `商城入驻完善供应商企业信息`,
  key1001549: `审核驳回，驳回原因：`,
  key1001550: `新增配置`,
  key1001551: `编辑配置`,
  key1001552: `已分配库存`,
  key1001553: `匹配智能物流规则：`,
  key1001554: `创建订单`,
  key1001555: `出库单号：`,
  key1001556: `自动匹配仓库成功;发货仓库：`,
  key1001557: `已取消订单`,
  key1001558: `取消订单，取消原因：`,
  key1001559: `物流[`,
  key1001560: `]修改为[`,
  key1001561: `;特殊规则：`,
  key1001562: `,适用范围`,
  key1001563: `,运费/时效优先级`,
  key1001564: `上传包裹前获取包裹状态异常`,
  key1001565: `申请运单号失败`,
  key1001566: `获取运单号失败`,
  key1001567: `获取速卖通货品标签异常`,
  key1001568: `申请运单号成功`,
  key1001569: `(运单号：`,
  key1001570: `(失败原因:：`,
  key1001571: `edis重新上传包裹异常异常`,
  key1001572: `手动修改：更新仓储账单【单据费用】`,
  key1001573: `手动修改：更新仓储账单【其他费用】`,
  key1001574: `手动修改：新增其他费用【`,
  key1001575: `添加备注`,
  key1001576: `手动修改`,
  key1001577: `币种修改`,
  key1001578: `导入账单更新`,
  key1001579: `确认结算`,
  key1001580: `人工标记标记允许取消订单`,
  key1001581: `由于地址修改，当前物流无法送达，原订单已取消，请重新匹配物流后进行下单。`,
  key1001582: `收货中`,
  key1001583: `由于地址修改，原订单已取消。`,
  key1001584: `作废提单，原因：`,
  key1001585: `手动标记付款成功`,
  key1001586: `买家修改收货地址，更新订单收货地址`,
  key1001587: `缴费金额：`,
  key1001588: `，时长：`,
  key1001589: `个月`,
  key1001590: `备货单首次分配`,
  key1001591: `商户工商信息变更，驳回原因：`,
  key1001592: `供应商修改了:`,
  key1001593: `商户工商信息变更，审核通过`,
  key1001594: `订单新增标签：`,
  key1001595: `订单删除标签：`,
  key1001596: `存在备货单尚未到货，等待在途库存。`,
  key1001597: `订单新增备注：`,
  key1001598: `美西仓库存不足，等待下次分配`,
  key1001599: `审核账单`,
  key1001600: `确认账单`,
  key1001601: `确认打款`,
  key1001602: `停售商品：`,
  key1001603: `停售货品：`,
  key1001604: `，生成货品标签异常;`,
  key1001605: `线下付款成功`,
  key1001606: `手动匹配仓库成功:`,
  key1001607: `分销商付款`,
  key1001608: `[ 运费最低 ]`,
  key1001609: `[ 时效最快 ]`,
  key1001610: `无法备货至`,
  key1001611: `仓`,
  key1001612: `当前最多可下单`,
  key1001613: `个`,
  key1001614: `存在重复，请修改`,
  key1001615: `尺码值语种：`,
  key1001616: `不可为空`,
  key1001617: `出库单:`,
  key1001618: `不是等待拣货状态，请移除有再完成追加`,
  key1001619: `速卖通预约交货失败`,
  key1001620: `数量超出限制，请核对数量`,
  key1001621: `当前容器`,
  key1001622: `正在被占用`,
  key1001623: `已存在`,
  key1001624: `运营下发物流异常：不同仓库`,
  key1001625: `订单不可同时发货`,
  key1001626: `运营下发物流异常：店铺地址不匹配`,
  key1001627: `已预约，揽收单号：`,
  key1001628: `供应商系统出库异常，提单单号：`,
  key1001629: `交接单号：`,
  key1001630: `预约交货异常`,
  key1001631: `非此订单所需货品，请确认货品所属订单`,
  key1001632: `提单：`,
  key1001633: `不存在，请输入正确提单号`,
  key1001634: `存在已下发的云卖订单`,
  key1001635: `启用状态：`,
  key1001636: `请输入规则名称`,
  key1001637: `新增规则`,
  key1001638: `优先级`,
  key1001639: `置底`,
  key1001640: `置顶`,
  key1001641: `移动`,
  key1001642: `规则名称`,
  key1001643: `启用状态`,
  key1001644: `创建人`,
  key1001645: `创建时间`,
  key1001646: `最后更新时间`,
  key1001647: `修改成功`,
  key1001648: `删除提示`,
  key1001649: `规则删除后，将不可恢复，是否删除？`,
  key1001650: `仓库不能为空`,
  key1001651: `动作类型：`,
  key1001652: `选择仓库：`,
  key1001653: `指定履约方`,
  key1001654: `设定动作`,
  key1001655: `分配发货仓库`,
  key1001656: `所分配仓库缺货时支持继续匹配其他分配仓库规则`,
  key1001657: `禁用`,
  key1001658: `阿里国际`,
  key1001659: `查看规则`,
  key1001660: `编辑规则`,
  key1001661: `复制规则`,
  key1001662: `请先选择规则项！`,
  key1001663: `复制成功`,
  key1001664: `新增容器类型`,
  key1001665: `容器类型：`,
  key1001666: `请输入容器类型，比如：周转箱`,
  key1001667: `重量(kg)：`,
  key1001668: `体积(cm³)：`,
  key1001669: `长(cm)：`,
  key1001670: `宽(cm)：`,
  key1001671: `高(cm)：`,
  key1001672: `备注：`,
  key1001673: `容器类型`,
  key1001674: `重量(kg)`,
  key1001675: `体积(cm³)`,
  key1001676: `长(cm)`,
  key1001677: `宽(cm)`,
  key1001678: `高(cm)`,
  key1001679: `容器类型不能为空`,
  key1001680: `容器重量不能为空`,
  key1001681: `容器体积不能为空`,
  key1001682: `容器长度不能为空`,
  key1001683: `容器宽度不能为空`,
  key1001684: `容器高度不能为空`,
  key1001685: `平台币种：`,
  key1001686: `供应商币种：`,
  key1001687: `添加支持币种`,
  key1001688: `分销商币种：`,
  key1001689: `平台币种`,
  key1001690: `交易币种`,
  key1001691: `汇率配置`,
  key1001692: `固定汇率`,
  key1001693: `实时汇率浮动`,
  key1001694: `浮动比例`,
  key1001695: `请输入固定汇率`,
  key1001696: `汇率展示`,
  key1001697: `实时汇率：`,
  key1001698: `系统汇率：`,
  key1001699: `固定汇率不能为空！`,
  key1001700: `固定汇率必须是大于等于0且最多允许4位小数`,
  key1001701: `实时汇率浮动不能为空！`,
  key1001702: `实时汇率浮动必须是大于等于0且最多允许4位小数`,
  key1001703: `打印容器条码`,
  key1001704: `介质材料：`,
  key1001705: `标签大小：`,
  key1001706: `自定义内容：`,
  key1001707: `自定义内容字体`,
  key1001708: `条码字体`,
  key1001709: `每pt约占0.35mm宽，请确保最长的SKU字符数*左侧pt值*0.35小于介质尺寸`,
  key1001710: `不打印自定义内容`,
  key1001711: `打印自定义内容`,
  key1001712: `增加`,
  key1001713: `统一设置打印数量`,
  key1001714: `注:打印内容可拖动改变位置`,
  key1001715: `请输入自定义内容`,
  key1001716: `新增消息模板`,
  key1001717: `消息名称`,
  key1001718: `消息类型`,
  key1001719: `通知渠道`,
  key1001720: `通知方式`,
  key1001721: `站内信`,
  key1001722: `邮箱->短信`,
  key1001723: `短信->邮箱`,
  key1001724: `删除消息模板后，将不再发送提醒消息，你确定要删除？`,
  key1001725: `消息名称：`,
  key1001726: `请输入消息名称，最多输入20个汉字`,
  key1001727: `消息类型：`,
  key1001728: `触发条件：`,
  key1001729: `通知渠道：`,
  key1001730: `通知方式：`,
  key1001731: `是否弹窗：`,
  key1001732: `提醒时间：`,
  key1001733: `提醒时间段为：6:00-23:00，其他时间段不提醒`,
  key1001734: `请选择时间`,
  key1001735: `状态停留时间超过`,
  key1001736: `及时提醒`,
  key1001737: `固定时间`,
  key1001738: `每`,
  key1001739: `提醒一次`,
  key1001740: `1)可从右侧拖拽添加动态字段。`,
  key1001741: `2)蓝色字体文字为动态参数，请勿修改。`,
  key1001742: `3)自定义内容仅支持站内信与邮件，短信按默认内容发送。`,
  key1001743: `动态参数`,
  key1001744: `标题不能为空！`,
  key1001745: `内容不能为空！`,
  key1001746: `消息类型不能为空`,
  key1001747: `内容：`,
  key1001748: `编辑消息模板`,
  key1001749: `创建成功`,
  key1001750: `编辑成功`,
  key1001751: `提醒时间类型为固定时间且单位为天时，具体的提醒时间段不能为空！`,
  key1001752: `触发条件的频率次数不能为空！`,
  key1001753: `提醒类型为固定时间的频率次数不能为空！`,
  key1001754: `库区名称：`,
  key1001755: `库区代码：`,
  key1001756: `库区状态：`,
  key1001757: `仓库名称：`,
  key1001758: `打印库区条码`,
  key1001759: `库区代码`,
  key1001760: `库区`,
  key1001761: `新建库区`,
  key1001762: `批量打印条码`,
  key1001763: `库区名称`,
  key1001764: `仓库名称不能为空`,
  key1001765: `库区名称不能为空`,
  key1001766: `库区代码不能为空`,
  key1001767: `状态不能为空`,
  key1001768: `打印数量`,
  key1001769: `查看库区`,
  key1001770: `编辑库区`,
  key1001771: `请先勾选需要打印的数据！`,
  key1001772: `所属仓库：`,
  key1001773: `容器编号：`,
  key1001774: `请入输入容器编号`,
  key1001775: `容器颜色：`,
  key1001776: `作业状态：`,
  key1001777: `容器数量：`,
  key1001778: `请输入颜色名称`,
  key1001779: `批量删除`,
  key1001780: `上传音频`,
  key1001781: `容器编号`,
  key1001782: `容器颜色`,
  key1001783: `作业状态`,
  key1001784: `所属仓库`,
  key1001785: `最后一次占用时间`,
  key1001786: `编辑容器颜色`,
  key1001787: `所属仓库不能为空`,
  key1001788: `空闲`,
  key1001789: `货品分拣完成`,
  key1001790: `质检中`,
  key1001791: `待贴单`,
  key1001792: `贴单中`,
  key1001793: `待上架`,
  key1001794: `上架中`,
  key1001795: `待多品分拣`,
  key1001796: `多品分拣中`,
  key1001797: `物流分拣中`,
  key1001798: `新增容器`,
  key1001799: `请选择数据`,
  key1001800: `容器数量不能为空`,
  key1001801: `容器数量必须是大于0的正整数`,
  key1001802: `上传音频失败，请重新尝试`,
  key1001803: `模板选择不能为空`,
  key1001804: `仓库编码：`,
  key1001805: `仓库类型：`,
  key1001806: `模板选择：`,
  key1001807: `常规订单处理费：`,
  key1001808: `常规多品订单续件：`,
  key1001809: `常规订单耗材费：`,
  key1001810: `备货类订单处理费：`,
  key1001811: `仓库租金：`,
  key1001812: ` / 件`,
  key1001813: ` / 单`,
  key1001814: ` / 月`,
  key1001815: `仓库处理费`,
  key1001816: `+添加分层价`,
  key1001817: `每日`,
  key1001818: `时`,
  key1001819: `新增仓库`,
  key1001820: `仓库编码不能为空`,
  key1001821: `仓库类型不能为空`,
  key1001822: `常规订单处理费不能为空`,
  key1001823: `常规多品订单续件不能为空`,
  key1001824: `备货类订单处理费不能为空`,
  key1001825: `仓库发货地址`,
  key1001826: `仓库收货地址`,
  key1001827: `仓库退货地址`,
  key1001828: `姓名`,
  key1001829: `国家/地区`,
  key1001830: `省/州`,
  key1001831: `城市`,
  key1001832: `区县`,
  key1001833: `街道地址`,
  key1001834: `手机号`,
  key1001835: `邮政编码`,
  key1001836: `公司名称`,
  key1001837: `邮箱`,
  key1001838: `名称：`,
  key1001839: `编辑仓库`,
  key1001840: `查看仓库`,
  key1001841: `名称不能为空`,
  key1001842: `左区间不能为空！`,
  key1001843: `右区间不能为空！`,
  key1001844: `分层价不能为空！`,
  key1001845: `左区间不能大于等于右区间`,
  key1001846: `标记为已读`,
  key1001847: `共`,
  key1001848: `条消息，其中未读消息`,
  key1001849: `点击查看`,
  key1001850: `消息标题`,
  key1001851: `时间`,
  key1001852: `请先勾选要标记已读的数据！`,
  key1001853: `目的国`,
  key1001854: `清关费率`,
  key1001855: `更新人`,
  key1001856: `更新时间`,
  key1001857: `日志`,
  key1001858: `启用确认`,
  key1001859: `停用确认`,
  key1001860: `启用后，此目的国将校验IOSS编号，请确认是否启用？`,
  key1001861: `停用后，此目的国将不再校验IOSS编号，请确认是否停用？`,
  key1001862: `确认启用`,
  key1001863: `确认停用`,
  key1001864: `启用成功`,
  key1001865: `停用成功`,
  key1001866: `商城默认语言配置`,
  key1001867: `语言选择：`,
  key1001868: `默认语言已设置为`,
  key1001869: `商城语言配置`,
  key1001870: `修改默认语言`,
  key1001871: `设置成功！`,
  key1001872: `目的国：`,
  key1001873: `清关费率：`,
  key1001874: `目的国不能为空`,
  key1001875: `清关费率不能为空`,
  key1001876: `最多支持两位小数，需大于等于0`,
  key1001877: `添加税率设置`,
  key1001878: `编辑税率设置`,
  key1001879: `清关费率不能为空！`,
  key1001880: `申报的SKU品种数`,
  key1001881: `每个申报的SKU品种申报的数量`,
  key1001882: `包裹包含多SKU时选择申报的货品信息`,
  key1001883: `申报重量的计算方法`,
  key1001884: `申报价格的计算方法`,
  key1001885: `默认申报设置`,
  key1001886: `全部申报`,
  key1001887: `限制最多申报品种数`,
  key1001888: `实际数量`,
  key1001889: `限制最多申报数量`,
  key1001890: `本项设置仅为确定申报所使用的品名及数量，对报关重量和价格无影响`,
  key1001891: `使用固定的总重量申报`,
  key1001892: `固定包裹总重量为`,
  key1001893: `如果申报时各品种需要详细的申报重量，系统将自动按照申报的品种的真实货品重量加权平均计算`,
  key1001894: `使用货品真实重量申报`,
  key1001895: `设置包裹封顶重量为`,
  key1001896: `申报币种`,
  key1001897: `使用固定价格申报`,
  key1001898: `固定申报价格`,
  key1001899: `本项设置仅录入金额，币种取本申报币种`,
  key1001900: `使用价格比例申报`,
  key1001901: `使用包裹`,
  key1001902: `的`,
  key1001903: `设置最低价格`,
  key1001904: `设置封顶价格`,
  key1001905: `设置为本选项后，系统将根据申报币种自动做汇率转换`,
  key1001906: `总金额`,
  key1001907: `商品总金额`,
  key1001908: `按照货品成本单价由高到低顺序选择`,
  key1001909: `按照货品成本单价X数量由高到低顺序选择`,
  key1001910: `按照货品单品体积由大到小顺序选择`,
  key1001911: `按照货品单品体积X数量由大到小顺序选择`,
  key1001912: `按照货品单品重量由大到小顺序选择`,
  key1001913: `按照货品单品重量X数量由大到小顺序选择`,
  key1001914: `封顶价格要大于最低价格`,
  key1001915: `请输入设置最低价格`,
  key1001916: `请输入设置封顶价格`,
  key1001917: `请输入设置包裹封顶重量`,
  key1001918: `仓库名称`,
  key1001919: `仓库代码`,
  key1001920: `仓库类型`,
  key1001921: `国家`,
  key1001922: `自营`,
  key1001923: `请先选择所属仓库`,
  key1001924: `所属库区名称：`,
  key1001925: `库位代码：`,
  key1001926: `库位状态：`,
  key1001927: `打印库位条码`,
  key1001928: `库位代码`,
  key1001929: `库位`,
  key1001930: `导入库位`,
  key1001931: `新建库位`,
  key1001932: `提示：`,
  key1001933: `1.支持扩展名:`,
  key1001934: `2.当导入数据存在异常时，数据导入失败;`,
  key1001935: `3.导入操作后，请稍后在“导入/导出任务-导入查看”中查看导入结果。`,
  key1001936: `所属库区名称`,
  key1001937: `所属库区代码`,
  key1001938: `库位代码不能为空`,
  key1001939: `查看库位`,
  key1001940: `编辑库位`,
  key1001941: `返回列表`,
  key1001942: `请入输入模板名称`,
  key1001943: `停用地址`,
  key1001944: `新增地址`,
  key1001945: `需要选择新的地址替换被停用的地址，请选择要替换的地址模板`,
  key1001946: `模板名称`,
  key1001947: `手机号码`,
  key1001948: `已启用`,
  key1001949: `已停用`,
  key1001950: `停用成功！`,
  key1001951: `入库分拣设置`,
  key1001952: `拣货容器：`,
  key1001953: `包装容器：`,
  key1001954: `切换仓库`,
  key1001955: `提单揽收`,
  key1001956: `流程状态：启用`,
  key1001957: `提单入库`,
  key1001958: `入库分拣`,
  key1001959: `极速出库模式`,
  key1001960: `分拣时进行贴单`,
  key1001961: `商品上架`,
  key1001962: `多品分拣`,
  key1001963: `单品任务跳过此流程`,
  key1001964: `包装作业`,
  key1001965: `物流分拣`,
  key1001966: `上架容器`,
  key1001967: `贴单+上架容器`,
  key1001968: `出库容器`,
  key1001969: `包装容器`,
  key1001970: `贴单+包装容器`,
  key1001971: `拣货容器`,
  key1001972: `贴单+拣货容器`,
  key1001973: `分拣容器出库单数量限制`,
  key1001974: `暂无仓库数据权限，请配置仓库权限后重试。`,
  key1001975: `存在进行中的入库分拣任务，请完成后再进行修改。`,
  key1001976: `姓名：`,
  key1001977: `国家/地区：`,
  key1001978: `省/州：`,
  key1001979: `城市：`,
  key1001980: `区县：`,
  key1001981: `街道地址：`,
  key1001982: `手机号：`,
  key1001983: `邮政编码：`,
  key1001984: `公司名称：`,
  key1001985: `邮箱：`,
  key1001986: `模板名称不能为空`,
  key1001987: `姓名不能为空`,
  key1001988: `国家/地区不能为空`,
  key1001989: `省州不能为空`,
  key1001990: `城市不能为空`,
  key1001991: `区县不能为空`,
  key1001992: `街道地址不能为空`,
  key1001993: `邮政编码不能为空`,
  key1001994: `编辑地址`,
  key1001995: `查看地址`,
  key1001996: `特殊申报规则`,
  key1001997: `添加时间`,
  key1001998: `订单自动规则--编辑`,
  key1001999: `等级名称：`,
  key1002000: `被展示分销商：`,
  key1002001: `新增供应商等级`,
  key1002002: `所有（包含未登录）`,
  key1002003: `部分`,
  key1002004: `等级名称不能为空`,
  key1002005: `等级名称`,
  key1002006: `被展示分销商`,
  key1002007: `查看供应商等级`,
  key1002008: `编辑供应商等级`,
  key1002009: `是否要删除当前供应商等级？`,
  key1002010: `请选择分销等级！`,
  key1002011: `手机号码：`,
  key1002012: `请填写邮箱`,
  key1002013: `营业执照：`,
  key1002014: `法定代表人身份证照：`,
  key1002015: `经营规模：`,
  key1002016: `联系人姓名：`,
  key1002017: `请填写联系人姓名`,
  key1002018: `联系人手机号码：`,
  key1002019: `请填联系人手机号码`,
  key1002020: `固定电话：`,
  key1002021: `请填写固定电话号码`,
  key1002022: `店铺名称：`,
  key1002023: `请填写店铺名称`,
  key1002024: `店铺Logo：`,
  key1002025: `经营模式：`,
  key1002026: `计划经营类目：`,
  key1002027: `预计上架商品数：`,
  key1002028: `商品发布频率：`,
  key1002029: `请填写具体数量`,
  key1002030: `营业地址：`,
  key1002031: `请填写详细地址`,
  key1002032: `归属仓库为供应商发货的目的仓库，至少选择1个。`,
  key1002033: `每月的X日生成上月账单，不含当月账单`,
  key1002034: `供应商等级：`,
  key1002035: `是否允许打印物流面单：`,
  key1002036: `是否允许直接发货：`,
  key1002037: `申请时间：`,
  key1002038: `驳回原因：`,
  key1002039: `账号信息`,
  key1002040: `密码：`,
  key1002041: `重新生成`,
  key1002042: `企业信息`,
  key1002043: `仅支持JPG、JPEG、PNG格式。`,
  key1002044: `人面像`,
  key1002045: `国徽面`,
  key1002046: `店铺信息`,
  key1002047: `尺寸：300*300px，仅支持JPG、JPEG、PNG格式。`,
  key1002048: `款`,
  key1002049: `归属仓库：`,
  key1002050: `首选仓库`,
  key1002051: `设为首选仓库`,
  key1002052: `账期结算：`,
  key1002053: `每月`,
  key1002054: `日出账单`,
  key1002055: `收款信息`,
  key1002056: `审核结果`,
  key1002057: `上传`,
  key1002058: `上传文件格式有误,文件`,
  key1002059: `格式错误,`,
  key1002060: `请选择[jpg,jpeg,png]`,
  key1002061: `添加供应商`,
  key1002062: `请选择经营规模`,
  key1002063: `联系人不能为空`,
  key1002064: `店铺名称不能为空`,
  key1002065: `请选择经营模式`,
  key1002066: `请选择计划经营类目`,
  key1002067: `请选择预计上架商品数`,
  key1002068: `详细地址不能为空`,
  key1002069: `供应商等级不能为空`,
  key1002070: `月结`,
  key1002071: `半月结`,
  key1002072: `周结`,
  key1002073: `10人以下`,
  key1002074: `10~30人`,
  key1002075: `30~50人`,
  key1002076: `50~100人`,
  key1002077: `100人以上`,
  key1002078: `自有工厂`,
  key1002079: `渠道代理`,
  key1002080: `经销批发`,
  key1002081: `女装`,
  key1002082: `男装`,
  key1002083: `男童`,
  key1002084: `女童`,
  key1002085: `婴童`,
  key1002086: `10以下`,
  key1002087: `100以上`,
  key1002088: `待定`,
  key1002089: `每天发布`,
  key1002090: `每周发布`,
  key1002091: `每月发布`,
  key1002092: `收款方式：`,
  key1002093: `开户人姓名：`,
  key1002094: `开户行：`,
  key1002095: `银行卡号：`,
  key1002096: `身份证号：`,
  key1002097: `支付宝账户：`,
  key1002098: `开户人证件号：`,
  key1002099: `公司`,
  key1002100: `入驻审核状态：`,
  key1002101: `工商信息变更审核状态：`,
  key1002102: `供应商详情`,
  key1002103: `编辑供应商信息`,
  key1002104: `审核供应商`,
  key1002105: `审核供应商工商信息变更`,
  key1002106: `银行卡`,
  key1002107: `支付宝`,
  key1002108: `营业执照`,
  key1002109: `法定代表人身份证国徽面`,
  key1002110: `法定代表人身份证人像面`,
  key1002111: `店铺logo`,
  key1002112: `请先上传营业执照`,
  key1002113: `请先上传法定代表人身份证国徽面`,
  key1002114: `请先上传法定代表人身份证人像面`,
  key1002115: `请先上传店铺logo`,
  key1002116: `请先选择商品发布频率类型！`,
  key1002117: `账期结算不能为空！`,
  key1002118: `审核成功！`,
  key1002119: `商品发布数不能为空`,
  key1002120: `商品发布数必须是大于0的正整数`,
  key1002121: `营业地址不能为空`,
  key1002122: `请选择完整的省市区营业地址`,
  key1002123: `提交时间：`,
  key1002124: `搜索字符：`,
  key1002125: `供应商名称/供应商商户号/联系人/手机/邮箱`,
  key1002126: `新增供应商`,
  key1002127: `供应商编号`,
  key1002128: `请输入供应商编号，支持多个逗号或换行分隔`,
  key1002129: `请输入手机号，支持多个逗号或换行分隔`,
  key1002130: `请输入邮箱，支持多个逗号或换行分隔`,
  key1002131: `已认证供应商`,
  key1002132: `黑名单供应商`,
  key1002133: `启用成功！`,
  key1002134: `联系人`,
  key1002135: `手机`,
  key1002136: `电话`,
  key1002137: `SKU总数量`,
  key1002138: `注册时间`,
  key1002139: `停用时间`,
  key1002140: `是否启用该供应商？启用后该供应商的账号可正常登录平台进行操作。`,
  key1002141: `名称`,
  key1002142: `停用原因：`,
  key1002143: `请输入停用原因`,
  key1002144: `停用后供应商将无法登录系统，无法进行发货等任何操作，请谨慎操作！`,
  key1002145: `供应商信息是否允许通过审核？`,
  key1002146: `供应商状态`,
  key1002147: `初审`,
  key1002148: `信息变更`,
  key1002149: `法人代表身份证`,
  key1002150: `经营规模`,
  key1002151: `联系人姓名`,
  key1002152: `联系人手机号码`,
  key1002153: `固定电话`,
  key1002154: `提交审核时间`,
  key1002155: `驳回时间`,
  key1002156: `审核`,
  key1002157: `通过审核`,
  key1002158: `入驻审核`,
  key1002159: `工商信息变更审核`,
  key1002160: `驳回时间：`,
  key1002161: `确认通过`,
  key1002162: `驳回申请`,
  key1002163: `请输入驳回原因`,
  key1002164: `驳回成功！`,
  key1002165: `统计`,
  key1002166: `订单状态为已出库的累计订单总金额。`,
  key1002167: `订单状态为已出库的累计订单总数量。`,
  key1002168: `商城SKU状态为在售的总数量。`,
  key1002169: `统计所有分销商订阅商品总量，同一个商品多个分销商订阅只计算1次。`,
  key1002170: `统计所有分销商已上架到平台的商品总数量，同一个商品不同的Item只计算一次。`,
  key1002171: `统计所有分销商发布刊登的总数量，按Item数量统计。`,
  key1002172: `销售额`,
  key1002173: `订单量`,
  key1002174: `供应商订单排名`,
  key1002175: `分销商订单排名`,
  key1002176: `总销售额(`,
  key1002177: `本月销售额`,
  key1002178: `昨日销售额`,
  key1002179: `今日销售额`,
  key1002180: `订单总数（单）`,
  key1002181: `本月订单数`,
  key1002182: `昨日订单数`,
  key1002183: `今日订单数`,
  key1002184: `在售商品数（SKU）`,
  key1002185: `本月上新`,
  key1002186: `昨日上新`,
  key1002187: `今日上新`,
  key1002188: `商品订阅数（SPU）`,
  key1002189: `本月订阅`,
  key1002190: `昨日订阅`,
  key1002191: `今日订阅`,
  key1002192: `上架商品数（SPU）`,
  key1002193: `本月上架数`,
  key1002194: `昨日上架数`,
  key1002195: `今日上架数`,
  key1002196: `发布刊登数（Item）`,
  key1002197: `本月刊登数`,
  key1002198: `昨日刊登数`,
  key1002199: `今日刊登数`,
  key1002200: `供应商总数`,
  key1002201: `本月新增`,
  key1002202: `昨日新增`,
  key1002203: `今日新增`,
  key1002204: `分销商总数`,
  key1002205: `订单状态`,
  key1002206: `待下单`,
  key1002207: `待换单`,
  key1002208: `本周`,
  key1002209: `本月`,
  key1002210: `本年`,
  key1002211: `月`,
  key1002212: `时间不能跨度超过一年`,
  key1002213: `排名`,
  key1002214: `较上月`,
  key1002215: `较上周`,
  key1002216: `分销商商户号`,
  key1002217: `分销商名称`,
  key1002218: `제품 목록`,
  key1002219: `商品贴单`,
  key1002220: `库存余量`,
  key1002221: `提单列表`,
  key1002222: `出库明细`,
  key1002223: `问卷统计`,
  key1002224: `补贴审核`,
  key1002225: `充值记录`,
  key1002226: `供应商总账单`,
  key1002227: `供应商明细账单`,
  key1002228: `供应商订单`,
  key1002229: `物品属性池`,
  key1002230: `客户账单`,
  key1002231: `物流账单`,
  key1002232: `仓储账单`,
  key1002233: `库存明细`,
  key1002234: `物品属性`,
  key1002235: `商品资料`,
  key1002236: `类目映射`,
  key1002237: `通知对象：`,
  key1002238: `创建人：`,
  key1002239: `发送时间：`,
  key1002240: `创建公告`,
  key1002241: `草稿`,
  key1002242: `待修改`,
  key1002243: `待发送`,
  key1002244: `已发送`,
  key1002245: `公告名称`,
  key1002246: `发送状态`,
  key1002247: `通知对象`,
  key1002248: `发送时间`,
  key1002249: `（预定）`,
  key1002250: `审核人`,
  key1002251: `审核通过提示`,
  key1002252: `审核通过后系统将按指定时间发送公告，请确认是否审核通过？`,
  key1002253: `删除公告提示`,
  key1002254: `公告删除后不可恢复，请确认是否删除？`,
  key1002255: `删除成功！`,
  key1002256: `取消发送`,
  key1002257: `取消发送提示`,
  key1002258: `取消后此公告将流转为草稿状态，请确认是否取消发送？`,
  key1002259: `新建问卷`,
  key1002260: `问卷名称`,
  key1002261: `问卷状态`,
  key1002262: `已暂停`,
  key1002263: `答题中`,
  key1002264: `浏览量`,
  key1002265: `回收率`,
  key1002266: `最后修改时间`,
  key1002267: `停用后不可答题`,
  key1002268: `停用后，问卷答题将暂停，你确定停用？`,
  key1002269: `问卷标题`,
  key1002270: `问卷说明`,
  key1002271: `请输入题目标题`,
  key1002272: `请输入选项`,
  key1002273: `投放渠道`,
  key1002274: `显示方式`,
  key1002275: `触发事件`,
  key1002276: `选择题型`,
  key1002277: `选填`,
  key1002278: `添加选项`,
  key1002279: `添加其他`,
  key1002280: `问卷设置`,
  key1002281: `文本`,
  key1002282: `分销商端`,
  key1002283: `弹窗`,
  key1002284: `不显示【跳过】按钮`,
  key1002285: `显示【跳过】按钮`,
  key1002286: `首次登录`,
  key1002287: `选项`,
  key1002288: `其他____`,
  key1002289: `是否保存问卷内容`,
  key1002290: `你有未保存的问卷，是否需要保存？`,
  key1002291: `不保存`,
  key1002292: `新建成功！`,
  key1002293: `题目标题不能为空！`,
  key1002294: `题目标题不能重复！`,
  key1002295: `新增指引`,
  key1002296: `PDF文件`,
  key1002297: `在线内容`,
  key1002298: `指引标题`,
  key1002299: `所属模块`,
  key1002300: `指引类型`,
  key1002301: `排序`,
  key1002302: `停用提醒`,
  key1002303: `停用后，用户将无法查看使用教程，`,
  key1002304: `你确定要停用吗？`,
  key1002305: `删除提醒`,
  key1002306: `你即将永久删除，删除后将无法恢复，`,
  key1002307: `你确定要删除吗？`,
  key1002308: `目录名称`,
  key1002309: `内容标题：`,
  key1002310: `请输入内容标题`,
  key1002311: `正文内容：`,
  key1002312: `排序：`,
  key1002313: `展示状态：`,
  key1002314: `复制文章标题`,
  key1002315: `最多可输入28个中文字符`,
  key1002316: `数字越大，越靠后`,
  key1002317: `开`,
  key1002318: `关`,
  key1002319: `添加内容`,
  key1002320: `是否确认删除【`,
  key1002321: `】分类？`,
  key1002322: `标题`,
  key1002323: `发布日期`,
  key1002324: `最后更新日期`,
  key1002325: `展示状态`,
  key1002326: `首页推荐`,
  key1002327: `新增目录`,
  key1002328: `文章标题不能为空`,
  key1002329: `目录`,
  key1002330: `首页最多推荐5条帮助文档，请先关闭其他推荐，再开启！`,
  key1002331: `是否确认删除当前文章？`,
  key1002332: `编辑目录`,
  key1002333: `目录分类最多只能有三个层级关系，且只允许同级拖动排序！`,
  key1002334: `内容`,
  key1002335: `在新的标签页打开图片`,
  key1002336: `1:1显示图片`,
  key1002337: `问题汇总`,
  key1002338: `鼠标滚轮缩放图片`,
  key1002339: `指引标题：`,
  key1002340: `请输入指引标题`,
  key1002341: `所属模块：`,
  key1002342: `显示位置：`,
  key1002343: `指引类型：`,
  key1002344: `提示:`,
  key1002345: `1.仅支持扩展名为PDF的文件。`,
  key1002346: `2.文件大小不超过10Mb。`,
  key1002347: `指引标题不能为空`,
  key1002348: `所属模块不能为空`,
  key1002349: `显示位置不能为空`,
  key1002350: `面包屑右侧`,
  key1002351: `编辑指引`,
  key1002352: `上传文件成功`,
  key1002353: `请先上传PDF文件！`,
  key1002354: `正文内容不能为空！`,
  key1002355: `公告名称：`,
  key1002356: `若审核通过时已超出发送时间，公告将在审核通过后立即发送。`,
  key1002357: `存为草稿`,
  key1002358: `提交审核`,
  key1002359: `请输入公告名称`,
  key1002360: `请选择通知对象`,
  key1002361: `编辑公告`,
  key1002362: `提交审核成功`,
  key1002363: `请输入正文内容`,
  key1002364: `请选择发送时间`,
  key1002365: `导出数据`,
  key1002366: `按选中导出`,
  key1002367: `按查询结果导出`,
  key1002368: `请先勾选需要导出的数据！`,
  key1002369: `当前筛选结果暂无数据，无法导出！`,
  key1002370: `商户号`,
  key1002371: `开始答题时间`,
  key1002372: `结束答题时间`,
  key1002373: `客户名称：`,
  key1002374: `联系人：`,
  key1002375: `手机：`,
  key1002376: `请输入手机号`,
  key1002377: `客户等级：`,
  key1002378: `仓库权限：`,
  key1002379: `客户类型：`,
  key1002380: `分销客户`,
  key1002381: `阿里国际分销`,
  key1002382: `客户等级不能为空`,
  key1002383: `链接详情`,
  key1002384: `链接名称：`,
  key1002385: `来源渠道：`,
  key1002386: `推广人：`,
  key1002387: `推广方式：`,
  key1002388: `推广内容：`,
  key1002389: `推广组：`,
  key1002390: `备注信息`,
  key1002391: `链接基本信息`,
  key1002392: `链接数据`,
  key1002393: `点击总人数`,
  key1002394: `注册总人数`,
  key1002395: `注册转换率`,
  key1002396: `请填写链接名称`,
  key1002397: `分时数据`,
  key1002398: `点击人数`,
  key1002399: `注册人数`,
  key1002400: `创建链接`,
  key1002401: `请填写链接地址`,
  key1002402: `创建成功！`,
  key1002403: `客户详情`,
  key1002404: `客户信息`,
  key1002405: `编号：`,
  key1002406: `客户来源：`,
  key1002407: `客户币种：`,
  key1002408: `关联商户：`,
  key1002409: `客户负责人：`,
  key1002410: `请填写备注`,
  key1002411: `余额：`,
  key1002412: `预扣金额：`,
  key1002413: `总额度：`,
  key1002414: `已使用额度：`,
  key1002415: `请输入姓名`,
  key1002416: `职务：`,
  key1002417: `请输入职务`,
  key1002418: `请输入邮箱`,
  key1002419: `请输入固定电话`,
  key1002420: `微信号：`,
  key1002421: `请输入微信号`,
  key1002422: `QQ号：`,
  key1002423: `请输入微QQ号`,
  key1002424: `客户旅程`,
  key1002425: `主联系人`,
  key1002426: `下次联系时间：`,
  key1002427: `操作人：`,
  key1002428: `客户基本信息`,
  key1002429: `客户财务信息`,
  key1002430: `客户仓库权限`,
  key1002431: `客户API权限`,
  key1002432: `添加联系人`,
  key1002433: `设为主要联系人`,
  key1002434: `暂无旅程数据`,
  key1002435: `联系记录`,
  key1002436: `开通产品记录`,
  key1002437: `日志信息`,
  key1002438: `不是有效的邮箱或者邮箱格式不正确！`,
  key1002439: `信息至少要填写其中一个！`,
  key1002440: `创建分组`,
  key1002441: `分组名称`,
  key1002442: ` 分组客户数量`,
  key1002443: `分组备注`,
  key1002444: `删除分组提示`,
  key1002445: `正在操作删除分组，请确认是否删除？`,
  key1002446: `删除分组`,
  key1002447: `注册时间：`,
  key1002448: `注册来源：`,
  key1002449: `请选择注册来源`,
  key1002450: `客户标签：`,
  key1002451: `请选择客户标签`,
  key1002452: `请选择客户等级`,
  key1002453: `客户名称/客户编号/联系人/手机/邮箱/备注`,
  key1002454: `新增客户`,
  key1002455: `移动分组`,
  key1002456: `批量审核`,
  key1002457: `客户编号`,
  key1002458: `请输入客户编号，支持多个逗号或换行分隔`,
  key1002459: `是否确认启用？`,
  key1002460: `是否确认停用？`,
  key1002461: `仅待审核客户可进行审核，请重新选择!`,
  key1002462: `请先勾选要审核的数据`,
  key1002463: `未分组`,
  key1002464: `客户名称`,
  key1002465: `客户分组`,
  key1002466: `客户标签`,
  key1002467: `客户等级`,
  key1002468: `客户币种`,
  key1002469: `余额`,
  key1002470: `预扣金额`,
  key1002471: `总额度`,
  key1002472: `已使用额度`,
  key1002473: `注册来源`,
  key1002474: `客户备注`,
  key1002475: `请先选择客户`,
  key1002476: `标签名称：`,
  key1002477: `请输入标签名称`,
  key1002478: `标签备注：`,
  key1002479: `请填写标签名称`,
  key1002480: `创建标签`,
  key1002481: `编辑标签`,
  key1002482: `指定分组：`,
  key1002483: `请选择分组`,
  key1002484: `本次操作中存在删除分组行为，请为分组下的客户重新进行分组`,
  key1002485: `非通途客户`,
  key1002486: `通途客户`,
  key1002487: `通途商户号不能为空`,
  key1002488: `查看客户`,
  key1002489: `标签名称`,
  key1002490: `标签客户数量`,
  key1002491: `标签备注`,
  key1002492: `删除标签提示`,
  key1002493: `删除标签后，此标签将同步从客户上去除，请确认是否删除？`,
  key1002494: `标签详情`,
  key1002495: `标签基本信息`,
  key1002496: `标签类型：`,
  key1002497: `标签数据`,
  key1002498: `统计时间：`,
  key1002499: `搜索链接名称`,
  key1002500: `请选择标签类型`,
  key1002501: `搜索标签名称`,
  key1002502: `链接名称`,
  key1002503: `链接类型`,
  key1002504: `查看二维码`,
  key1002505: `复制链接`,
  key1002506: `分组名称：`,
  key1002507: `请输入分组名称`,
  key1002508: `分组备注：`,
  key1002509: `请填写分组名称`,
  key1002510: `最多输入20个字符`,
  key1002511: `编辑分组`,
  key1002512: `等级折扣系数：`,
  key1002513: `新增客户等级`,
  key1002514: `等级`,
  key1002515: `查看客户等级`,
  key1002516: `编辑客户等级`,
  key1002517: `是否要删除当前客户等级？`,
  key1002518: `等级折扣系数`,
  key1002519: `注册时默认等级`,
  key1002520: `默认`,
  key1002521: `设为默认`,
  key1002522: `更换注册默认等级`,
  key1002523: `当前操作将会影响商城客户注册时的默认等级，是否确认更换默认等级？`,
  key1002524: `等级折扣系数不能为空`,
  key1002525: `等级折扣系数必须大于 0 且小于等于 1`,
  key1002526: `输入出库单号查找`,
  key1002527: `删除确认`,
  key1002528: `拣货单详情`,
  key1002529: `拣货单号：`,
  key1002530: `拣货单状态：`,
  key1002531: `是否打印：`,
  key1002532: `打印拣货单`,
  key1002533: `创建人员：`,
  key1002534: `是否删除最后一条？如果删除该拣货单也会删除！`,
  key1002535: `按出库单查看`,
  key1002536: `按商品查看`,
  key1002537: `出库单状态`,
  key1002538: `物流下发状态`,
  key1002539: `未下发`,
  key1002540: `已下发`,
  key1002541: `从拣货单移除`,
  key1002542: `所在仓库`,
  key1002543: `产品图片`,
  key1002544: `已拣货数量`,
  key1002545: `体积`,
  key1002546: `未拣货`,
  key1002547: `成品`,
  key1002548: `原料`,
  key1002549: `拣货单：`,
  key1002550: `物料类型`,
  key1002551: `原料SPU`,
  key1002552: `原料SKU`,
  key1002553: `制单人`,
  key1002554: `（签字/日期）`,
  key1002555: `拣货人`,
  key1002556: `（签字日期）`,
  key1002557: `取消打印`,
  key1002558: `标记打印成功`,
  key1002559: `订单号：`,
  key1002560: `多个出库单请用逗号分隔`,
  key1002561: `品类：`,
  key1002562: `排序方式：`,
  key1002563: `生成拣货单`,
  key1002564: `按选中生成拣货单`,
  key1002565: `生成拣货单（所有结果集）`,
  key1002566: `按创建时间`,
  key1002567: `按物流方式`,
  key1002568: `Temu 样`,
  key1002569: `Temu 货`,
  key1002570: `出库单类型`,
  key1002571: `分销商订单号`,
  key1002572: `SKU数量`,
  key1002573: `物品数量`,
  key1002574: `查看详情`,
  key1002575: `请先勾选需要生成拣货单的数据！`,
  key1002576: `当前筛选结果暂无数据，无法生成拣货单！`,
  key1002577: `未指定物流商`,
  key1002578: `未指定邮寄方式`,
  key1002579: `拣货单编号：`,
  key1002580: `打印状态：`,
  key1002581: `批量操作`,
  key1002582: `标记为已拣货`,
  key1002583: `未打印`,
  key1002584: `已打印`,
  key1002585: `按添加时间`,
  key1002586: `按拣货单状态`,
  key1002587: `查询已包装的拣货单，创建时间不能为空！`,
  key1002588: `标记成功`,
  key1002589: `请选择操作数据`,
  key1002590: `拣货单编号`,
  key1002591: `拣货单类型`,
  key1002592: `拣货状态`,
  key1002593: `打印状态`,
  key1002594: `SKU数`,
  key1002595: `货品数`,
  key1002596: `拣货完成时间`,
  key1002597: `拣货单来源`,
  key1002598: `出库单`,
  key1002599: `分拣容器`,
  key1002600: `打印组装标签`,
  key1002601: `获取打印组装标签链接地址失败!`,
  key1002602: `单位换算`,
  key1002603: `YMS属性单位`,
  key1002604: `平台属性单位`,
  key1002605: `设置成功`,
  key1002606: `属性名称：`,
  key1002607: `请输入属性名称，支持多语种搜索`,
  key1002608: `是否自定义：`,
  key1002609: `单选/多选：`,
  key1002610: `物品属性导入`,
  key1002611: `导出属性`,
  key1002612: `2.当导入数据存在异常时，数据导入失败。`,
  key1002613: `编号`,
  key1002614: `规格/普通属性`,
  key1002615: `是否尺码属性`,
  key1002616: `规格/普通属性(规格、普通)`,
  key1002617: `是否自定义(是、否)`,
  key1002618: `属性值单选/多选(多选、单选)`,
  key1002619: `是否尺码属性(是、否)`,
  key1002620: `填写要求(必填、非必填、推荐填写)`,
  key1002621: `属性信息`,
  key1002622: `中文-简体`,
  key1002623: `法语`,
  key1002624: `德语`,
  key1002625: `西班牙语`,
  key1002626: `荷兰语`,
  key1002627: `波兰语`,
  key1002628: `葡萄牙语`,
  key1002629: `意大利语`,
  key1002630: `属性状态`,
  key1002631: `属性值/单位信息`,
  key1002632: `属性删除提示`,
  key1002633: `本次修改中存在删除属性行为，删除后现有商品信息中该属性字段将一并删除，请确认是否保存此次修改？`,
  key1002634: `停用属性后，供应商发布新商品和修改已发布的商品时，此属性将不可使用，你确定要停用?`,
  key1002635: `立即停用`,
  key1002636: `新增模板`,
  key1002637: `部位`,
  key1002638: `单位`,
  key1002639: `是否要删除当前尺码模板？`,
  key1002640: `标签商品数量`,
  key1002641: `标签名称不能为空`,
  key1002642: `标签`,
  key1002643: `删除标签后，此标签将同步从商品上去除，请确认是否删除？`,
  key1002644: `请输入中文标题,支持多个按行分隔`,
  key1002645: `供应商：`,
  key1002646: `商品类型：`,
  key1002647: `商品标签：`,
  key1002648: `请选择商品标签`,
  key1002649: `导出商品列表`,
  key1002650: `批量停售`,
  key1002651: `按选中停售`,
  key1002652: `按查询结果停售`,
  key1002653: `取消选择`,
  key1002654: `面单`,
  key1002655: `供应商信息`,
  key1002656: `分类`,
  key1002657: `商品标签`,
  key1002658: `商品可售卖国`,
  key1002659: `最近停售时间`,
  key1002660: `首次上架时间`,
  key1002661: `首次提交审核时间`,
  key1002662: `查看SKU`,
  key1002663: `查看中心仓库存`,
  key1002664: `查看供应商库存`,
  key1002665: `1688找同款`,
  key1002666: `请输入供应商SKU,支持多个按行分隔`,
  key1002667: `商品类目`,
  key1002668: `销售状态`,
  key1002669: `最后上架时间`,
  key1002670: `中心仓分配库存`,
  key1002671: `供应商分配库存`,
  key1002672: `商品类型`,
  key1002673: `以SPU维度导出`,
  key1002674: `(报表中每个SPU一行)`,
  key1002675: `以SKU维度导出`,
  key1002676: `(报表中每个SKU一行)`,
  key1002677: `最小值`,
  key1002678: `最大值`,
  key1002679: `供应商仓库存`,
  key1002680: `商品上架时间`,
  key1002681: `请先勾选需要停售的数据！`,
  key1002682: `当前筛选结果暂无数据，无法操作停售！`,
  key1002683: `库存最小值不可大于最大值，请修改条件后再查询。`,
  key1002684: `请先选择商品`,
  key1002685: `1. 选择平台`,
  key1002686: `2. 选择站点`,
  key1002687: `4. 选择Product Type`,
  key1002688: `4. 选择Family Type`,
  key1002689: `5. 商品规格映射`,
  key1002690: `6. 商品属性映射`,
  key1002691: `自定义值`,
  key1002692: `平台属性变更，原值：`,
  key1002693: `选择站点`,
  key1002694: `类目不能为空`,
  key1002695: `商品规格映射`,
  key1002696: `属性:`,
  key1002697: `已映射:`,
  key1002698: `待映射:`,
  key1002699: `属性值:`,
  key1002700: `属性值已映射:`,
  key1002701: `属性值待映射:`,
  key1002702: `自动匹配`,
  key1002703: `正在匹配中，请稍后...`,
  key1002704: `自动匹配完成，已匹配属性：`,
  key1002705: `已匹配属性值：`,
  key1002706: `查看更多可选值`,
  key1002707: `无数据`,
  key1002708: `商品属性映射`,
  key1002709: `关联属性：`,
  key1002710: `添加平台`,
  key1002711: `平台不能为空`,
  key1002712: `站点不能为空`,
  key1002713: `平台属性`,
  key1002714: `平台属性值类型`,
  key1002715: `编辑平台`,
  key1002716: `查看平台`,
  key1002717: `（已映射）`,
  key1002718: `Product Type不能为空`,
  key1002719: `FamiltType不能为空`,
  key1002720: `开始匹配`,
  key1002721: `正在匹配中...`,
  key1002722: `匹配数据获取完成`,
  key1002723: `数据匹配中`,
  key1002724: `接口请求错误，可能导致数据匹配不全`,
  key1002725: `名称数据匹配`,
  key1002726: `平台映射表`,
  key1002727: `电商平台：`,
  key1002728: `站点名称：`,
  key1002729: `分类名称：`,
  key1002730: `自定义属性值`,
  key1002731: `正在搜索`,
  key1002732: `输入文字进行搜索`,
  key1002733: `平台标签属性`,
  key1002734: `映射属性`,
  key1002735: `listing关键词的生成规则`,
  key1002736: `随机`,
  key1002737: `不选`,
  key1002738: `切换Product Type会清空列表数据`,
  key1002739: `请先选择Product Type`,
  key1002740: `平台属性不能重复`,
  key1002741: `未查询到平台类目`,
  key1002742: `价格区间(CNY)：`,
  key1002743: `综合评分：`,
  key1002744: `24小时揽收率：`,
  key1002745: `48小时揽收率：`,
  key1002746: `搜索关键词：`,
  key1002747: ` 起`,
  key1002748: `起批`,
  key1002749: `分`,
  key1002750: `近30天成交：`,
  key1002751: `不限`,
  key1002752: `5星`,
  key1002753: `4.5星-5.0星`,
  key1002754: `4星-4.5星`,
  key1002755: `4星以下`,
  key1002756: `认证工厂`,
  key1002757: `当日发货`,
  key1002758: `24小时发货`,
  key1002759: `48小时发货`,
  key1002760: `7天无理由`,
  key1002761: `一件代发`,
  key1002762: `支持包邮`,
  key1002763: `按价格`,
  key1002764: `按月销量`,
  key1002765: `价格开始区间不能大于价格结束区间`,
  key1002766: `中文名称不能为空！`,
  key1002767: `尺码表模板名称`,
  key1002768: `新增部位`,
  key1002769: `新增单位`,
  key1002770: `同一列部位的中文名称不能重复！`,
  key1002771: `部位1`,
  key1002772: `英文名称`,
  key1002773: ` 荷兰语名称`,
  key1002774: `同一列的部位多语种名称必须填写完整或全为空！`,
  key1002775: `单位名称不能为空！`,
  key1002776: `新增模板成功！`,
  key1002777: `更新模板成功！`,
  key1002778: `上移`,
  key1002779: `下移`,
  key1002780: `不能选择重复的单位值！`,
  key1002781: `属性池管理`,
  key1002782: `请输入属性中文名称`,
  key1002783: `商品规格中只能有一个是尺码属性！`,
  key1002784: `请先选择属性池数据！`,
  key1002785: `输入关键词搜索`,
  key1002786: `分类ID：`,
  key1002787: `父级类目已配置不展示，此类目不展示于商城。`,
  key1002788: `报关编码：`,
  key1002789: `中文报关名称：`,
  key1002790: `英文报关名称：`,
  key1002791: `尺码表模板：`,
  key1002792: `新增子分类`,
  key1002793: `导入映射`,
  key1002794: `分类信息`,
  key1002795: `商城展示配置：`,
  key1002796: `展示`,
  key1002797: `不展示`,
  key1002798: `平台信息`,
  key1002799: `报关信息`,
  key1002800: `您确认`,
  key1002801: `这个平台吗？`,
  key1002802: `商品总数：`,
  key1002803: `您确认删除这条内容吗？`,
  key1002804: `报关编码不能为空`,
  key1002805: `中文报关名称不能为空`,
  key1002806: `英文报关名称不能为空`,
  key1002807: `尺码表模板不能为空`,
  key1002808: `平台名称`,
  key1002809: `站点`,
  key1002810: `商品分类`,
  key1002811: `修改`,
  key1002812: `是否将其他物品属性listing关键词的生成规则更新为当前生成规则`,
  key1002813: `同步`,
  key1002814: `主属性设置`,
  key1002815: `主属性`,
  key1002816: `设为主属性`,
  key1002817: `只能同级拖动`,
  key1002818: `请先设置商品规格的主属性！`,
  key1002819: `是否要删除当前分类？`,
  key1002820: `服装`,
  key1002821: `不能包含->特殊字符`,
  key1002822: `文件格式有误, 仅支持上传xls、xlsx格式文件`,
  key1002823: `请输入打印数量`,
  key1002824: `打印面单`,
  key1002825: `供应商名称/供应商商户号`,
  key1002826: `批量驳回`,
  key1002827: `供应商货号/SPU`,
  key1002828: `中文品名`,
  key1002829: `供应商商户号 / 名称`,
  key1002830: `审核状态`,
  key1002831: `请勾选需要批量审核的数据!`,
  key1002832: `请先勾选需要批量驳回的数据！`,
  key1002833: `功能设置`,
  key1002834: `功能描述`,
  key1002835: `商品标签是否在商城展示`,
  key1002836: `是否启用商品可售卖国设置`,
  key1002837: `账单打款确认`,
  key1002838: `打款密码：`,
  key1002839: `请输入打款密码`,
  key1002840: `是否已核对账单无误，确认打款后款项将直接通过支付宝转账至供应商账户中，请谨慎操作。`,
  key1002841: `账单时间：`,
  key1002842: `客户商户号：`,
  key1002843: `请输入客户商户号`,
  key1002844: `导出客户账单`,
  key1002845: `年`,
  key1002846: `订单金额`,
  key1002847: `订单币种`,
  key1002848: `账单号`,
  key1002849: `账单周期`,
  key1002850: `客户商户号`,
  key1002851: `月累计充值金额`,
  key1002852: `支付宝充值金额`,
  key1002853: `手工充值金额`,
  key1002854: `累计消费金额`,
  key1002855: `账单生成时间`,
  key1002856: `账单信息`,
  key1002857: `所属商户`,
  key1002858: `所属账单`,
  key1002859: `充值流水号`,
  key1002860: `第三方交易号`,
  key1002861: `充值金额`,
  key1002862: `付款方式`,
  key1002863: `充值成功时间`,
  key1002864: `消费记录`,
  key1002865: `第三方单号`,
  key1002866: `货品总数`,
  key1002867: `物流重量(g)`,
  key1002868: `商品金额`,
  key1002869: `物流费`,
  key1002870: `订单完成时间`,
  key1002871: `账单维度`,
  key1002872: `明细维度`,
  key1002873: `物流费用为供应商应付项，货品金额及补贴金额为应收项，计算时请注意`,
  key1002874: `费用明细`,
  key1002875: `审核确认`,
  key1002876: `线下已打款`,
  key1002877: `货品结算金额`,
  key1002878: `货品金额小计`,
  key1002879: `供应商账单号：`,
  key1002880: `供应商名称：`,
  key1002881: `供应商商户号：`,
  key1002882: `账单生成时间：`,
  key1002883: `账单结算状态：`,
  key1002884: `账单状态更新时间：`,
  key1002885: `账单金额：`,
  key1002886: `货品金额（应付）：`,
  key1002887: `物流费用（应收）：`,
  key1002888: `补贴金额（应付）：`,
  key1002889: `账单周期：`,
  key1002890: `物流费用`,
  key1002891: `账单审核确认`,
  key1002892: `是否已审核账单无误，审核确认后将变为可打款状态。`,
  key1002893: `账单线下已打款确认`,
  key1002894: `请确认是否已进行线下打款。`,
  key1002895: `确认线下已打款`,
  key1002896: `费用类型`,
  key1002897: `货品数量`,
  key1002898: `SPU近30天累计销量`,
  key1002899: `提单编号`,
  key1002900: `结束装箱时间`,
  key1002901: `揽收时间`,
  key1002902: `云卖网发货时间`,
  key1002903: `应付物流费：`,
  key1002904: `应付其他费用：`,
  key1002905: `物流实重(g)：`,
  key1002906: `未对账订单数量：`,
  key1002907: `物流商币种：`,
  key1002908: `账单明细`,
  key1002909: `仅看亏损订单`,
  key1002910: `仅看未对账订单`,
  key1002911: `盈亏(当前筛选条件)：`,
  key1002912: `账单编号：`,
  key1002913: `账单更新时间：`,
  key1002914: `客户实付物流费：`,
  key1002915: `其他应付费用：`,
  key1002916: `总费用：`,
  key1002917: `单据号`,
  key1002918: `单据状态`,
  key1002919: `系统计算物流重量(g)`,
  key1002920: `运单号`,
  key1002921: `物流实重(g)`,
  key1002922: `客户实付物流费`,
  key1002923: `应付物流费`,
  key1002924: `未对账`,
  key1002925: `应付其他费用`,
  key1002926: `总费用`,
  key1002927: `盈亏`,
  key1002928: `包裹出库时间`,
  key1002929: `对账数据来源`,
  key1002930: `导入账单`,
  key1002931: `应付物流费不能为空`,
  key1002932: `备注不能为空`,
  key1002933: `账单确认结算`,
  key1002934: `正在操作账单结算，结算后将无法再对账单进行修改。请确认是否结算？`,
  key1002935: `结算成功!`,
  key1002936: `修改币种成功！`,
  key1002937: `分销商商户号：`,
  key1002938: `充值时间：`,
  key1002939: `充值状态：`,
  key1002940: `充值流水号：`,
  key1002941: `请输入充值流水号,支持多个按行分隔`,
  key1002942: `导出充值记录`,
  key1002943: `充值`,
  key1002944: `充值中`,
  key1002945: `充值成功`,
  key1002946: `充值失败`,
  key1002947: `第三方凭证号`,
  key1002948: `第三方凭证`,
  key1002949: `充值前余额`,
  key1002950: `充值后余额`,
  key1002951: `初始化额度`,
  key1002952: `剩余额度`,
  key1002953: `充值状态`,
  key1002954: `订单处理费：`,
  key1002955: `订单耗材费：`,
  key1002956: `附加服务费：`,
  key1002957: `退货处理费：`,
  key1002958: `费用名称：`,
  key1002959: `费用金额：`,
  key1002960: `+添加费用`,
  key1002961: `月处理订单数：`,
  key1002962: `总金额：`,
  key1002963: `客户实付金额：`,
  key1002964: `其他费用：`,
  key1002965: `费用名称`,
  key1002966: `金额`,
  key1002967: `关联单据号`,
  key1002968: `订单内货品数量`,
  key1002969: `订单处理费`,
  key1002970: `订单耗材费`,
  key1002971: `附加服务费`,
  key1002972: `退货处理费`,
  key1002973: `合计`,
  key1002974: `客户实付金额`,
  key1002975: `费用产生时间`,
  key1002976: `修改费用`,
  key1002977: `单据费用`,
  key1002978: `其他费用`,
  key1002979: `订单处理费不能为空`,
  key1002980: `费用金额不能为空`,
  key1002981: `费用名称不能为空`,
  key1002982: `添加费用`,
  key1002983: `添加费用成功`,
  key1002984: `修改费用成功`,
  key1002985: `添加备注成功`,
  key1002986: `充值币种：`,
  key1002987: `若充值币种与分销商币种不一致时，系统将按汇率转为分销商币种进行充值。`,
  key1002988: `充值面额：`,
  key1002989: `付款方式：`,
  key1002990: `支付流水号：`,
  key1002991: `支付流水图片：`,
  key1002992: `上传附件`,
  key1002993: `分销商货币为`,
  key1002994: `，实际到账`,
  key1002995: `系统汇率：1`,
  key1002996: `现金`,
  key1002997: `分销商商户号不能为空`,
  key1002998: `付款方式不能为空`,
  key1002999: `支付流水号不能为空`,
  key1003000: `充值币种不能为空`,
  key1003001: `自定义的充值面额不能为空！`,
  key1003002: `请上传支付流水图片！`,
  key1003003: `充值成功！`,
  key1003004: `最多支持上传五个附件！`,
  key1003005: `请输入分销商商户号`,
  key1003006: `（分销商币种）`,
  key1003007: `账单状态：`,
  key1003008: `可搜索账单号/供应商名称`,
  key1003009: `导出供应商账单`,
  key1003010: `批量打款`,
  key1003011: `批量确认线下打款`,
  key1003012: `供应商账单号`,
  key1003013: `请输入供应商账单号，支持多个逗号或换行分隔`,
  key1003014: `账单金额`,
  key1003015: `账单币种`,
  key1003016: `订单数量`,
  key1003017: `账单结算状态`,
  key1003018: `供应商确认时间`,
  key1003019: `审核时间`,
  key1003020: `打款时间`,
  key1003021: `打款人`,
  key1003022: `打款方式`,
  key1003023: `收款账户信息`,
  key1003024: `收款方式`,
  key1003025: `开户人姓名`,
  key1003026: `开户行`,
  key1003027: `账户`,
  key1003028: `身份证号`,
  key1003029: `账单明细信息`,
  key1003030: `供货价(CNY)`,
  key1003031: `货品金额小计(CNY)`,
  key1003032: `产生费用时间`,
  key1003033: `面单类型`,
  key1003034: `订单创建时间`,
  key1003035: `中心仓发货时间`,
  key1003036: `是否中心仓备货`,
  key1003037: `总账单`,
  key1003038: `仅收款账户为支付宝的才能批量打款！`,
  key1003039: `请先选择账单！`,
  key1003040: `支付宝订单号`,
  key1003041: `线下支付`,
  key1003042: `补贴规则：`,
  key1003043: `请选择补贴规则`,
  key1003044: `物流`,
  key1003045: `物流方式`,
  key1003046: `补贴规则`,
  key1003047: `提单重量`,
  key1003048: `提货单内货品数量`,
  key1003049: `准时发货率`,
  key1003050: `入库时间`,
  key1003051: `分拣时间`,
  key1003052: `最后更新人`,
  key1003053: `请输入提单号,支持多个逗号或换行分隔`,
  key1003054: `请输入运单号,支持多个逗号或换行分隔`,
  key1003055: `请输入供应商商户号,支持多个逗号或换行分隔`,
  key1003056: `仅待审核的订单可批量审核！`,
  key1003057: `请先勾选需要审核的数据！`,
  key1003058: `上门揽收`,
  key1003059: `说明`,
  key1003060: `已审核`,
  key1003061: `提单内货品数量`,
  key1003062: `重新审核`,
  key1003063: `补贴说明`,
  key1003064: `总计：`,
  key1003065: `物流-快递`,
  key1003066: `物流-上门揽收`,
  key1003067: `.截止`,
  key1003068: `前的`,
  key1003069: `单`,
  key1003070: `按提单个数补贴`,
  key1003071: `按提单重量补贴`,
  key1003072: `无补贴`,
  key1003073: `发货方式`,
  key1003074: `订单发货率`,
  key1003075: `补贴对象`,
  key1003076: `补贴类型`,
  key1003077: `补贴条件`,
  key1003078: `使用`,
  key1003079: `实际数据`,
  key1003080: `补贴计算`,
  key1003081: `1.单个提单补贴：`,
  key1003082: `1.0-1公斤补贴：`,
  key1003083: `2.续重每公斤补贴：`,
  key1003084: `3.单个提单补贴上限：`,
  key1003085: `符合子规则：`,
  key1003086: `符合规则：`,
  key1003087: `补贴手动调整为：`,
  key1003088: `导出仓储账单`,
  key1003089: `更新账单`,
  key1003090: `结算状态`,
  key1003091: `结算中`,
  key1003092: `已结算`,
  key1003093: `总金额(CNY)`,
  key1003094: `订单处理量`,
  key1003095: `仓库租金`,
  key1003096: `账单更新时间`,
  key1003097: `月订单处理量(CNY)`,
  key1003098: `订单耗材费用(CNY)`,
  key1003099: `附加费用(CNY)`,
  key1003100: `退货处理费用(CNY)`,
  key1003101: `仓库租金(CNY)`,
  key1003102: `订单耗材费用`,
  key1003103: `附加服务费用`,
  key1003104: `退货处理费用`,
  key1003105: `合计总金额`,
  key1003106: `仓储费用重新计算中，请稍候刷新列表查看！`,
  key1003107: `请选勾选需要更新的数据！`,
  key1003108: `规则计算补贴：`,
  key1003109: `调整后补贴：`,
  key1003110: `请输入调整后补贴金额`,
  key1003111: `调整说明：`,
  key1003112: `请输入调整说明`,
  key1003113: `调整后补贴金额不能为空`,
  key1003114: `调整说明不能为空`,
  key1003115: `费用流水状态：`,
  key1003116: `费用类型：`,
  key1003117: `费用产生时间：`,
  key1003118: `分销商`,
  key1003119: `单据编号`,
  key1003120: `账单所属月份`,
  key1003121: `费用流水状态`,
  key1003122: `已生成账单`,
  key1003123: `未生成账单`,
  key1003124: `导出物流账单`,
  key1003125: `导入物流商账单`,
  key1003126: `导入物流账单`,
  key1003127: `1.已结算的账单数据导入后不再进行更新，将显示为失败;`,
  key1003128: `2.支持扩展名:`,
  key1003129: `3.当导入数据存在异常时，数据导入失败;`,
  key1003130: `账号`,
  key1003131: `月包裹数`,
  key1003132: `账单匹配数量`,
  key1003133: `请选择补贴审核结果：`,
  key1003134: `审核不通过原因：`,
  key1003135: `审核原因不能为空`,
  key1003136: `删除打款密码`,
  key1003137: `请输入打款密码：`,
  key1003138: `新打款密码：`,
  key1003139: `请再次输入新密码：`,
  key1003140: `请再次输入打款密码`,
  key1003141: `请再次输入密码：`,
  key1003142: `账单打款密码`,
  key1003143: `删除密码`,
  key1003144: `删除打款密码后,账单线上打款确认时将不再需要输入密码,请谨慎操作。`,
  key1003145: `未设置`,
  key1003146: `设置打款密码`,
  key1003147: `打款密码不能为空`,
  key1003148: `更换成功!`,
  key1003149: `保存成功!`,
  key1003150: `两次输入的密码不一致`,
  key1003151: `已设置`,
  key1003152: `修改打款密码`,
  key1003153: `消费记录（订单仅计算已取消及已出库部分）`,
  key1003154: `月累计充值：`,
  key1003155: `月支付宝累计充值：`,
  key1003156: `月手工累计充值：`,
  key1003157: `月累计消费：`,
  key1003158: `第三方交易凭证`,
  key1003159: `多个`,
  key1003160: `SKU请换行分隔`,
  key1003161: `提单SKU详情`,
  key1003162: `当前提单内SKU如下图所示，共`,
  key1003163: `种，合计`,
  key1003164: `件。`,
  key1003165: `货品上架`,
  key1003166: `待上架数量`,
  key1003167: `上架数量`,
  key1003168: `仓库名称不能为空!`,
  key1003169: `库区名称不能为空!`,
  key1003170: `库位名称不能为空!`,
  key1003171: `上架数量不能为空!`,
  key1003172: `上架数量必须是大于0的整数`,
  key1003173: `上架数量不能大于待上架数量!`,
  key1003174: `上架数量必须大于0`,
  key1003175: `扫描或输入供应商SKU`,
  key1003176: `批量打印`,
  key1003177: `正在贴单作业...`,
  key1003178: `结束贴单作业`,
  key1003179: `应贴数量`,
  key1003180: `扫描成功！`,
  key1003181: `请输入正确的供应商SKU！`,
  key1003182: `供应商SKU不能为空!`,
  key1003183: `请先勾选要打印的数据！`,
  key1003184: `打印数量不能为空!`,
  key1003185: `常规分拣`,
  key1003186: `备货分拣`,
  key1003187: `扫描：`,
  key1003188: `可扫描出库单号、运单号、物流商单号、容器编号`,
  key1003189: `称重：`,
  key1003190: `请保持系统重量单位和电子秤重量单位一致`,
  key1003191: `实际称重(`,
  key1003192: `地址`,
  key1003193: `称重`,
  key1003194: `物流渠道：`,
  key1003195: `结束容器分拣`,
  key1003196: `结袋`,
  key1003197: `打印预约交货面单提示`,
  key1003198: `速卖通线上发货需打印预交面单，请结袋后在物流交接单中打印预交面单。`,
  key1003199: `暂不结袋`,
  key1003200: `直接结袋`,
  key1003201: `预约并结袋`,
  key1003202: `打印揽收面单提示`,
  key1003203: `速卖通全托管JIT订单需打印揽收面单，若未打印将无法交付速卖通揽收。`,
  key1003204: `打印并结袋`,
  key1003205: `移除包裹提示`,
  key1003206: `正在操作移除包裹，移除后可重新扫描装箱`,
  key1003207: `还需`,
  key1003208: `完成`,
  key1003209: `移除成功`,
  key1003210: `克`,
  key1003211: `千克`,
  key1003212: `称重单位为：`,
  key1003213: `请输入出库单号或运单号！`,
  key1003214: `称重必须是大于0的整数！`,
  key1003215: `称重不能为空！`,
  key1003216: `获取面单地址失败！`,
  key1003217: `你还有包裹未完成分拣，你是否结束分拣？`,
  key1003218: `物流分拣异常提示`,
  key1003219: `强制结束`,
  key1003220: `交接人：`,
  key1003221: `请输入交接人`,
  key1003222: `标记出库`,
  key1003223: `交接单号`,
  key1003224: `包裹数量`,
  key1003225: `交货方式`,
  key1003226: `快递至揽收点`,
  key1003227: `物流下发方式`,
  key1003228: `系统下发`,
  key1003229: `手动下发`,
  key1003230: `国内物流单号`,
  key1003231: `交接时间`,
  key1003232: `交接人`,
  key1003233: `继续装箱`,
  key1003234: `打印预交面单`,
  key1003235: `打印快递面单`,
  key1003236: `获取快递面单地址失败`,
  key1003237: `预约揽收`,
  key1003238: `打印揽收面单`,
  key1003239: `请输入交接单号,支持多个逗号或换行分隔`,
  key1003240: `请输入出库单号,支持多个逗号或换行分隔`,
  key1003241: `请先勾选要标记出库的数据！`,
  key1003242: `获取预交面单地址失败`,
  key1003243: `预约成功！`,
  key1003244: `获取揽收面单地址失败`,
  key1003245: `请扫描或录入提单号/快递单号/三方提单编号`,
  key1003246: `三方提单编号`,
  key1003247: `箱/袋内出库单数量`,
  key1003248: `入库成功！`,
  key1003249: `请输入提单号！`,
  key1003250: `上架作业：`,
  key1003251: `扫描或输入容器编号`,
  key1003252: `支持多个容器编号，用逗号或换行分隔`,
  key1003253: `导出上架数据`,
  key1003254: `容器状态：`,
  key1003255: `容器状态`,
  key1003256: `商品数量`,
  key1003257: `作业开始时间`,
  key1003258: `继续上架`,
  key1003259: `实际上架数量`,
  key1003260: `作业结束时间`,
  key1003261: `作业时长`,
  key1003262: `按容器维度导出`,
  key1003263: `按SKU维度导出`,
  key1003264: `容器内SKU数量`,
  key1003265: `上架库位`,
  key1003266: `提货单号不能为空！`,
  key1003267: `商品上架作业提醒`,
  key1003268: `你有商品上架作业未完成，是否继续作业？`,
  key1003269: `贴单作业：`,
  key1003270: `导出贴单数据`,
  key1003271: `贴单作业`,
  key1003272: `继续贴单`,
  key1003273: `已贴单`,
  key1003274: `容器编号不能为空！`,
  key1003275: `商品贴单作业提醒`,
  key1003276: `你有商品贴单作业未完成，是否继续作业？`,
  key1003277: `分拣异常：`,
  key1003278: `揽收时间：`,
  key1003279: `导出提单列表`,
  key1003280: `批量标记已揽收`,
  key1003281: `缺失包裹`,
  key1003282: `物流轨迹`,
  key1003283: `应收货品数量`,
  key1003284: `应收部分实收数量`,
  key1003285: `供应商多发数量`,
  key1003286: `异常状态`,
  key1003287: `数量异常，请点击查看`,
  key1003288: `发货地址`,
  key1003289: `标记已揽收`,
  key1003290: `作废`,
  key1003291: `请输入SKU,支持多个逗号或换行分隔`,
  key1003292: `请输入三方提单编号,支持多个逗号或换行分隔`,
  key1003293: `提货单号`,
  key1003294: `供应商商户名`,
  key1003295: `快递单号`,
  key1003296: `运输方式`,
  key1003297: `按提货单维度导出`,
  key1003298: `请先勾选要标记已揽收的数据！`,
  key1003299: `出库时间：`,
  key1003300: `明细信息`,
  key1003301: `(已取消)`,
  key1003302: `包裹实重（g）`,
  key1003303: `数据来源`,
  key1003304: `称重员`,
  key1003305: `移除成功！`,
  key1003306: `发货设置`,
  key1003307: `单位：`,
  key1003308: `截去：`,
  key1003309: `部分物流商面单扫描的运单号会带前缀或后缀，这样系统就无法识别运单号，勾选可截去相对应的前缀或后缀。`,
  key1003310: `开启(必填)`,
  key1003311: `开启(非必填)`,
  key1003312: `不开启`,
  key1003313: `前`,
  key1003314: `后`,
  key1003315: `请扫描TEMU标签号`,
  key1003316: `TEMU标签号`,
  key1003317: `是否已扫描`,
  key1003318: `重量（g）`,
  key1003319: `打印打包标签`,
  key1003320: `请扫描TEMU标签号！`,
  key1003321: `扫描成功`,
  key1003322: `请扫描所有TEMU小包后再进行结袋操作。`,
  key1003323: `未获取到面单地址！`,
  key1003324: `作废提单确认`,
  key1003325: `作废原因：`,
  key1003326: `请输入作废原因`,
  key1003327: `作废提单后，未发货订单将重新流转至供应商待发货列表中。`,
  key1003328: `确认作废`,
  key1003329: `作废成功！`,
  key1003330: `扫描记录`,
  key1003331: `撤销操作`,
  key1003332: `容器已结束不可撤销`,
  key1003333: `分拣容器：`,
  key1003334: `提单编号：`,
  key1003335: `分拣时间：`,
  key1003336: `供应商SKU：`,
  key1003337: `请先扫描提单号！`,
  key1003338: `撤销操作提示`,
  key1003339: `撤销后，对应容器中的SKU将移除，请谨慎操作。`,
  key1003340: `撤销`,
  key1003341: `提单中存在多发货品，`,
  key1003342: `将作为备货进行处理，并已自动创建相应备货单号，类型为多发补单`,
  key1003343: `提单号：`,
  key1003344: `三方提单编号：`,
  key1003345: `提单申明数量及实际到仓数量`,
  key1003346: `销售订单：`,
  key1003347: `备货单：`,
  key1003348: `多发货品数量`,
  key1003349: `实收数量`,
  key1003350: `目的仓库`,
  key1003351: `已包装`,
  key1003352: `操作内容/留言`,
  key1003353: `请输入备注内容！`,
  key1003354: `分拣作业：`,
  key1003355: `扫描或输入提单号/快递单号/三方提单编号`,
  key1003356: `导出分拣数据`,
  key1003357: `进入作业界面`,
  key1003358: `提货单状态：`,
  key1003359: `提货单状态`,
  key1003360: `提货单类型`,
  key1003361: `出库单数量`,
  key1003362: `分拣作业`,
  key1003363: `提货单货品数`,
  key1003364: `实际分拣数量`,
  key1003365: `sku数量`,
  key1003366: `入库操作时间`,
  key1003367: `分拣开始时间`,
  key1003368: `分拣结束时间`,
  key1003369: `分拣结束操作人`,
  key1003370: `入库分拣作业提醒`,
  key1003371: `你有入库分拣作业未完成，是否继续作业？`,
  key1003372: `上架异常提示`,
  key1003373: `上架数量不能超出待上架数量`,
  key1003374: `库区库位未填写`,
  key1003375: `部分上架`,
  key1003376: `结束上架`,
  key1003377: `SKU上架数量与待上架数量不一致，详情如下，请确认是否结束上架？`,
  key1003378: `继续作业`,
  key1003379: `应上架数量`,
  key1003380: `存在异常SKU，请检查后重试`,
  key1003381: `上架数据未填写完整`,
  key1003382: `扫描或输入`,
  key1003383: `SKU/供应商SKU/物流面单`,
  key1003384: `扫描或输入提货单号`,
  key1003385: `请扫描或输入容器编号`,
  key1003386: `正在分拣作业...`,
  key1003387: `结束分拣作业`,
  key1003388: `结束本单分拣`,
  key1003389: `逐件扫描`,
  key1003390: `超出应发数量是否提示`,
  key1003391: `启用语音播报`,
  key1003392: `需贴单`,
  key1003393: `请添加容器`,
  key1003394: `容器完拣`,
  key1003395: `找到多种类型面单，请正确选择！`,
  key1003396: `暂不处理`,
  key1003397: `绑定容器`,
  key1003398: `结束分拣`,
  key1003399: `已在`,
  key1003400: `容器中存在相同SKU，请结束`,
  key1003401: `容器后重新扫描提单`,
  key1003402: `SKU/供应商SKU/物流面单不能不为空！`,
  key1003403: `应收数量`,
  key1003404: `分拣数量`,
  key1003405: `还需分拣`,
  key1003406: `已扫数量`,
  key1003407: `请扫描商品`,
  key1003408: `已分拣数量`,
  key1003409: `未分拣`,
  key1003410: `提货单号不能为空!`,
  key1003411: `提货单中没有此商品，请仔细确认！`,
  key1003412: `此包裹已被分拣，请勿重复扫描`,
  key1003413: `容器完拣提示`,
  key1003414: `此容器拣货数量已达上限，系统将自动将容器完拣，请绑定新容器继续作业。`,
  key1003415: `此容器包装数量已达上限，系统将自动将容器完拣，请绑定新容器继续作业。`,
  key1003416: `将同时结束提货单与容器分拣，你确定要结束？`,
  key1003417: `绑定成功！`,
  key1003418: `容器颜色重复提示`,
  key1003419: `此颜色容器已存在，请选择不同颜色的容器。`,
  key1003420: `设置容器颜色提示`,
  key1003421: `此容器无颜色，请选择具有颜色属性的容器。`,
  key1003422: `请先扫描提单号!`,
  key1003423: `容器编号不能为空!`,
  key1003424: `请选择面单类型！`,
  key1003425: `SKU超出应收提示`,
  key1003426: `正在进行作业的SKU已超出应收数量，分拣完成后若存在超出应收数量的SKU，系统将自动进行补单并向供应商发送提示，无需人工操作。`,
  key1003427: `此SKU需进行上架，暂未绑定贴单+上架容器，请绑定贴单+上架容器：`,
  key1003428: `此SKU需进行上架，暂未绑定上架容器，请绑定上架容器：`,
  key1003429: `SKU数量异常提示`,
  key1003430: `以下SKU数量与应发数量有差异，结束分拣后实际多收的SKU系统将自动进行补单并向供应商发送提示。`,
  key1003431: `国内快递选择`,
  key1003432: `下单方式：`,
  key1003433: `国内快递：`,
  key1003434: `此物流渠道需发快递至揽收点，结袋后请将此渠道下的所有包裹再次打包，最外层贴国内快递面单！`,
  key1003435: `系统下单`,
  key1003436: `线下下单`,
  key1003437: `请选择国内快递`,
  key1003438: `请输入取消`,
  key1003439: `订单原因，此原因分销商可见。`,
  key1003440: `取消订单`,
  key1003441: `订单取消原因：`,
  key1003442: `确认取消`,
  key1003443: `订单原因`,
  key1003444: `取消成功!`,
  key1003445: `收款渠道：`,
  key1003446: `收款账户：`,
  key1003447: `新增收款账户`,
  key1003448: `收款渠道`,
  key1003449: `收款账户`,
  key1003450: `补充收款信息`,
  key1003451: `创建日期`,
  key1003452: `启用中`,
  key1003453: `编辑&查看`,
  key1003454: `是否确认删除该信息？`,
  key1003455: `确认删除`,
  key1003456: `删除成功!`,
  key1003457: `供应商订单总金额：`,
  key1003458: `分销商订单总金额：`,
  key1003459: `分销商应付金额：`,
  key1003460: `供应商应收金额：`,
  key1003461: `保存并下单`,
  key1003462: `的供应商订单总金额和分销商订单总金额不能为空`,
  key1003463: `保存并下单成功`,
  key1003464: `备货单状态：`,
  key1003465: `备货单号：`,
  key1003466: `请输入备货单号,支持多个按行分隔`,
  key1003467: `下单时间：`,
  key1003468: `付款时间：`,
  key1003469: `完结时间：`,
  key1003470: `订单金额：`,
  key1003471: `订单原金额：`,
  key1003472: `调价后金额：`,
  key1003473: `已取消金额：`,
  key1003474: `已退款金额：`,
  key1003475: `分销商：`,
  key1003476: `供应商子单单号：`,
  key1003477: `发货时间：`,
  key1003478: `入库时间：`,
  key1003479: `金额：`,
  key1003480: `调价：`,
  key1003481: `结算：`,
  key1003482: `原单价：`,
  key1003483: `调价后：`,
  key1003484: `单价：`,
  key1003485: `订单数量：`,
  key1003486: `收货数量：`,
  key1003487: `上架数量：`,
  key1003488: `取消数量：`,
  key1003489: `退款数量：`,
  key1003490: `缺货数量：`,
  key1003491: `预计到货时间：`,
  key1003492: `单号：`,
  key1003493: `异常状态：`,
  key1003494: `补货中`,
  key1003495: `收货数量`,
  key1003496: `取消数量`,
  key1003497: `退款数量`,
  key1003498: `待调价`,
  key1003499: `待供应商接单`,
  key1003500: `待分销商付款`,
  key1003501: `待入库`,
  key1003502: `请输入SPU，支持多个逗号或换行分隔`,
  key1003503: `请输入SKU，支持多个逗号或换行分隔`,
  key1003504: `请输入分销商商户号，支持多个逗号或换行分隔`,
  key1003505: `请输入供应商商户号，支持多个逗号或换行分隔`,
  key1003506: `查询中...`,
  key1003507: `待重发`,
  key1003508: `部分到货`,
  key1003509: `已完结`,
  key1003510: `下单确认`,
  key1003511: `正在操作下单。请确认是否下单？`,
  key1003512: `确认下单`,
  key1003513: `下单成功！`,
  key1003514: `主信息`,
  key1003515: `收货人信息`,
  key1003516: `物流商信息`,
  key1003517: `订单详情：`,
  key1003518: `订单SKU`,
  key1003519: `打包信息`,
  key1003520: `已发货（`,
  key1003521: `行号`,
  key1003522: `供应商订单号`,
  key1003523: `缺货状态`,
  key1003524: `缺货中，请查看详情`,
  key1003525: `订单状态更新时间`,
  key1003526: `是否作废`,
  key1003527: `未作废`,
  key1003528: `详情`,
  key1003529: `中文申报名`,
  key1003530: `英文申报名`,
  key1003531: `申报价值`,
  key1003532: `申报重量`,
  key1003533: `申报数量`,
  key1003534: `海关编码`,
  key1003535: `销售链接`,
  key1003536: `申报信息`,
  key1003537: `日志内容描述`,
  key1003538: `面单类型：`,
  key1003539: `分销商订单号：`,
  key1003540: `发货仓库：`,
  key1003541: `收货人名称：`,
  key1003542: `国家：`,
  key1003543: `买家识别号：`,
  key1003544: `详细地址1：`,
  key1003545: `详细地址2：`,
  key1003546: `物流商单号：`,
  key1003547: `邮寄方式：`,
  key1003548: `运单号1：`,
  key1003549: `运单号2：`,
  key1003550: `物流商称重（g）：`,
  key1003551: `物流商运费：`,
  key1003552: `Temu标签号`,
  key1003553: `SKU*数量`,
  key1003554: `包裹重量(g)`,
  key1003555: `暂无打包标签`,
  key1003556: `已配货`,
  key1003557: `已备货`,
  key1003558: `新增行请填写完整`,
  key1003559: `请先添加申报信息！`,
  key1003560: `当前小包未进行物流分拣，无法打印箱唛`,
  key1003561: `sku信息`,
  key1003562: `出库单类型：`,
  key1003563: `到仓时间：`,
  key1003564: `所有SKU`,
  key1003565: `到仓异常数量SKU`,
  key1003566: `发起备货数量`,
  key1003567: `实际到仓数量`,
  key1003568: `修改物流方式`,
  key1003569: `选择修改后物流方式：`,
  key1003570: `账号：`,
  key1003571: `修改失败数据`,
  key1003572: `物流动态参数`,
  key1003573: `确认修改`,
  key1003574: `原物流商`,
  key1003575: `原物流方式`,
  key1003576: `目的地`,
  key1003577: `原因`,
  key1003578: `未选择数据`,
  key1003579: `请选择物流渠道`,
  key1003580: `账号不能为空`,
  key1003581: `SKU，支持多个逗号或换行分隔`,
  key1003582: `订单状态：`,
  key1003583: `订单标签：`,
  key1003584: `下发状态：`,
  key1003585: `物流方式：`,
  key1003586: `订单类型：`,
  key1003587: `来源：`,
  key1003588: `履约方：`,
  key1003589: `是云卖物流或来源为自有渠道的数据，才能修改物流方式！`,
  key1003590: `下发物流`,
  key1003591: `订单号，支持多个逗号或换行分隔`,
  key1003592: `状态更新时间：`,
  key1003593: `订单创建时间：`,
  key1003594: `订单发货时间：`,
  key1003595: `订单取消时间：`,
  key1003596: `个供应商子单`,
  key1003597: `物品数量：`,
  key1003598: `订单信息`,
  key1003599: `订单SKU信息`,
  key1003600: `订单发货时间`,
  key1003601: `发货时效`,
  key1003602: `请输入分销商订单号，支持多个逗号或换行分隔`,
  key1003603: `请输入供应商订单号，支持多个逗号或换行分隔`,
  key1003604: `请输入运单号，支持多个逗号或换行分隔`,
  key1003605: `请输入Temu标签号，支持多个逗号或换行分隔`,
  key1003606: `物流三方单号`,
  key1003607: `请输入物流三方单号，支持多个逗号或换行分隔`,
  key1003608: `（待执行发货）`,
  key1003609: `待下发`,
  key1003610: `下发中`,
  key1003611: `下发成功`,
  key1003612: `下发失败`,
  key1003613: `国内快递费：`,
  key1003614: `货品金额：`,
  key1003615: `物流费：`,
  key1003616: `处理费：`,
  key1003617: `需通过快递发至揽收点的包裹将加收国内快递费。`,
  key1003618: `清关费：`,
  key1003619: `更新承运商`,
  key1003620: `添加承运商`,
  key1003621: `物流三方单号：`,
  key1003622: `国内快递单号：`,
  key1003623: `发货信息`,
  key1003624: `客选物流：`,
  key1003625: `供应商子单信息`,
  key1003626: `部分补货中`,
  key1003627: `部分已停售`,
  key1003628: `查看子单`,
  key1003629: `货品信息`,
  key1003630: `通知分销商`,
  key1003631: `允许取消`,
  key1003632: `自有渠道`,
  key1003633: `三方渠道`,
  key1003634: `商品费用(CNY)`,
  key1003635: `总费用(CNY)`,
  key1003636: `处理费(CNY)`,
  key1003637: `物流费(CNY)`,
  key1003638: `物流名称`,
  key1003639: `物流商单号`,
  key1003640: `运单号1`,
  key1003641: `运单号2`,
  key1003642: `供应商出库时间`,
  key1003643: `提单揽收时间`,
  key1003644: `订单取消时间`,
  key1003645: `订单取消原因`,
  key1003646: `订单类型`,
  key1003647: `订单标签`,
  key1003648: `平台订单信息`,
  key1003649: `平台站点`,
  key1003650: `店铺名`,
  key1003651: `平台订单号`,
  key1003652: `平台订单金额`,
  key1003653: `平台订单状态`,
  key1003654: `平台订单SKU信息`,
  key1003655: `下单时间`,
  key1003656: `付款时间`,
  key1003657: `订单维度`,
  key1003658: `SKU维度`,
  key1003659: `(列)`,
  key1003660: `(行)`,
  key1003661: `按行展示时，通用信息将进行合并，统计时请注意。`,
  key1003662: `状态更新时间`,
  key1003663: `按更新时间`,
  key1003664: `供应商仓直发`,
  key1003665: `分销集货仓发`,
  key1003666: `分销集货仓发(未贴标)`,
  key1003667: `单次最多查询1000条，请调整后重试。`,
  key1003668: `更新成功！`,
  key1003669: `请选择已付款且下发状态为待下发或下发失败的订单进行下发物流！`,
  key1003670: `请先勾选要下发物流的数据！`,
  key1003671: `请先勾选要修改的数据！`,
  key1003672: `请先勾选要添加标签的数据！`,
  key1003673: `删除标签确认`,
  key1003674: `正在操作删除订单标签。请确认是否删除?`,
  key1003675: `已装箱状态允许取消订单确认`,
  key1003676: `取消处理费：`,
  key1003677: `此订单已装箱，请务必确认现场是否未发出，取消后需及时联系相关人员将包裹进行取出。`,
  key1003678: `允许取消订单`,
  key1003679: `取消处理费不能为空`,
  key1003680: `供应商订单号：`,
  key1003681: `出库单状态：`,
  key1003682: `货品数量：`,
  key1003683: `缺货信息`,
  key1003684: `请选择备货单状态`,
  key1003685: `供应商出库单状态：`,
  key1003686: `请选择出库单状态`,
  key1003687: `供应商出库单类型：`,
  key1003688: `请选择供应商出库单类型`,
  key1003689: `导出订单`,
  key1003690: `已收货`,
  key1003691: `Sku,支持多个逗号或换行分隔`,
  key1003692: `备货单号`,
  key1003693: `请输入备货单号,支持多个逗号或换行分隔`,
  key1003694: `备货单状态`,
  key1003695: `供应商出库单状态`,
  key1003696: `供应商出库单类型`,
  key1003697: `发货时间`,
  key1003698: `取消时间`,
  key1003699: `请选择订单状态`,
  key1003700: `结算状态：`,
  key1003701: `请选择结算状态`,
  key1003702: `超时时间：`,
  key1003703: `，仓库实收数量不足，请至发货记录中查看详情`,
  key1003704: `已作废，需重新发货。`,
  key1003705: `剩余发货时间`,
  key1003706: `已超时时间`,
  key1003707: `条码：`,
  key1003708: `到仓数量：`,
  key1003709: `超时时间`,
  key1003710: `完结时间`,
  key1003711: `完结时间指此订单流程已全部结束时间节点，订单将已此节点时间计入对应账期。`,
  key1003712: `标记到货`,
  key1003713: `更新标记`,
  key1003714: `超时未发`,
  key1003715: `请输入订单号，支持多个逗号或换行分隔`,
  key1003716: `请输入供应商SKU，支持多个逗号或换行分隔`,
  key1003717: `请输入供应商名称，支持多个逗号或换行分隔`,
  key1003718: `供应商商户编号`,
  key1003719: `订单总金额`,
  key1003720: `子单类型`,
  key1003721: `未入账`,
  key1003722: `超时时间开始时长不能大于结束时长`,
  key1003723: `获取标记缺货详情数据失败！`,
  key1003724: `已超时`,
  key1003725: `秒`,
  key1003726: `请输入收款渠道`,
  key1003727: `请输入收款账户`,
  key1003728: `收款币种：`,
  key1003729: `补充收款信息：`,
  key1003730: `可根据收款渠道，在“补充收款信息”处，补充输入使用该渠道进行支付时所需的必要信息;`,
  key1003731: `收款渠道不能为空`,
  key1003732: `收款账户不能为空`,
  key1003733: `收款币种不能为空`,
  key1003734: `编辑&查看收款账户`,
  key1003735: `请输入业务单号,支持多个按行分隔`,
  key1003736: `支付批次号：`,
  key1003737: `请输入支付批次号,支持多个按行分隔`,
  key1003738: `业务单号：`,
  key1003739: `支付渠道：`,
  key1003740: `运营收款账户：`,
  key1003741: `对账状态：`,
  key1003742: ` 日志信息`,
  key1003743: `批量对账`,
  key1003744: `支付批次号`,
  key1003745: `订单应付总额`,
  key1003746: `支付渠道`,
  key1003747: `运营收款账户`,
  key1003748: `分销商户号`,
  key1003749: `业务单号`,
  key1003750: `业务金额`,
  key1003751: `支付流水图片`,
  key1003752: `对账状态`,
  key1003753: `对账`,
  key1003754: `待对账`,
  key1003755: `对账通过`,
  key1003756: `对账退回`,
  key1003757: `请先勾选需要操作的数据！`,
  key1003758: `请选择待对账的支付数据进行对账`,
  key1003759: `添加备注：`,
  key1003760: `订单金额修改`,
  key1003761: `订单总金额：`,
  key1003762: `货品金额修改`,
  key1003763: `是否允许开启分销商线上余额充值入口`,
  key1003764: `是否开启分销商订单”线下支付"付款方式`,
  key1003765: `至少需要开启一个功能入口`,
  key1003766: `修改运单号`,
  key1003767: `请输入运单号`,
  key1003768: `请输入物流商单号`,
  key1003769: `请选择物流方式`,
  key1003770: `运单号不能为空`,
  key1003771: `请输入退回原因`,
  key1003772: `条信息`,
  key1003773: `对账结果：`,
  key1003774: `退回原因：`,
  key1003775: `对账退回表示运营方未收到此订单款项或实收与应收金额不符，需分销商重新进行支付，审核通过后，运营方才会对此订单进行履约。`,
  key1003776: `对账通过表示运营方已收到此订单款项，该订单会由运营方进行后续履约。`,
  key1003777: `订单支付对账`,
  key1003778: `SKU，用逗号或换行分隔`,
  key1003779: `SPU，用逗号或换行分隔`,
  key1003780: `多个供应商商户号，用逗号或换行分隔`,
  key1003781: `库位：`,
  key1003782: `请输入库位代码`,
  key1003783: `货主：`,
  key1003784: `请输入商户号`,
  key1003785: `总库存：`,
  key1003786: `导出库存余量`,
  key1003787: `导出库存明细`,
  key1003788: `导出库存余量数据`,
  key1003789: `导出库存明细数据`,
  key1003790: `1.导入库存后将覆盖原来库存，请谨慎导入;`,
  key1003791: `商品导入`,
  key1003792: `按SKU`,
  key1003793: `规格属性值`,
  key1003794: `货主角色`,
  key1003795: `货主商户号`,
  key1003796: `按产品查询`,
  key1003797: `按产品/库位查询`,
  key1003798: `可用数量必须是大于等于0的正整数`,
  key1003799: `可用库存开始区间不能大于可用库存结束区间`,
  key1003800: `明细`,
  key1003801: `库存详情`,
  key1003802: `显示库存为0的数据`,
  key1003803: `常规包装`,
  key1003804: `备货包装`,
  key1003805: `包装人：`,
  key1003806: `请输入拣货单号，支持多个按行分隔`,
  key1003807: `导出多品分拣`,
  key1003808: `扫描拣货单单号/容器编号：`,
  key1003809: `你有包装中作业未完成，拣货单号：`,
  key1003810: `，是否继续作业?`,
  key1003811: `拣货单号`,
  key1003812: `拣货单状态`,
  key1003813: `出库单数`,
  key1003814: `拣货/分拣完成时间`,
  key1003815: `拣货/分拣人`,
  key1003816: `包装人`,
  key1003817: `开始作业`,
  key1003818: `作业操作人`,
  key1003819: `开始作业时间`,
  key1003820: `结束作业时间`,
  key1003821: `常规包装作业提醒`,
  key1003822: `备货包装作业提醒`,
  key1003823: `处理中....`,
  key1003824: `请输入拣货单号！`,
  key1003825: `单品拣货单打印时货品列表输入顺序：`,
  key1003826: `多品拣货单打印时货品列表输入顺序：`,
  key1003827: `集货超时：`,
  key1003828: `分拣时打印地址面单：`,
  key1003829: `拣货单设置`,
  key1003830: `集货超时设置`,
  key1003831: `包装作业设置`,
  key1003832: `常规分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1003833: `启用左侧分拣货架`,
  key1003834: `货架矩阵`,
  key1003835: `行`,
  key1003836: `列`,
  key1003837: `启用中间分拣货架`,
  key1003838: `启用右侧分拣货架`,
  key1003839: `备货分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1003840: `保存见货出单设置`,
  key1003841: `仅按货品库位字符升序`,
  key1003842: `仅按货品库位降序`,
  key1003843: `请至少选择一个常规拣货架！`,
  key1003844: `请至少选择一个备货拣货架！`,
  key1003845: `分拣人：`,
  key1003846: `你有分拣中作业未完成，拣货单号:`,
  key1003847: `分拣人`,
  key1003848: `扫描/录入拣货单单号开始多品分拣：`,
  key1003849: `常规分拣作业提醒`,
  key1003850: `备货分拣作业提醒`,
  key1003851: `包裹重量不能为空`,
  key1003852: `小包结袋`,
  key1003853: `包裹重量(g)：`,
  key1003854: `当前小包内SKU如下图所示，共`,
  key1003855: `件，确认数量后请先进行称重，再进行结袋操作。`,
  key1003856: `打印标签并结袋`,
  key1003857: `待盘点`,
  key1003858: `盘点中`,
  key1003859: `盘点单:`,
  key1003860: `盘点仓库：`,
  key1003861: `拣货提醒：`,
  key1003862: `盘点单状态：`,
  key1003863: `动态盘点：`,
  key1003864: `盲盘：`,
  key1003865: `盘点人员：`,
  key1003866: `库存数`,
  key1003867: `盘点数`,
  key1003868: `差异数`,
  key1003869: `成功生成`,
  key1003870: `张拣货单`,
  key1003871: `拣货单单号`,
  key1003872: `拣货单生成规则：`,
  key1003873: `去拣货单列表`,
  key1003874: `每张备货拣货单最大包裹数不能为空！`,
  key1003875: `每张常规拣货单最大包裹数不能为空！`,
  key1003876: `拣货单`,
  key1003877: `商品标签选择`,
  key1003878: `拣货单号：{pickingGoodsNo} 已经分拣完成，点击【结束本次分拣】结束本次作业`,
  key1003879: `您扫描的货品{sku}，当前拣货单中的包裹均不需要或需求数量已经满足。`,
  key1003880: `当前分拣作业拣货单：`,
  key1003881: `结束本次分拣`,
  key1003882: `SKU/货品身份码`,
  key1003883: `全部分拣完成`,
  key1003884: `货品异常警告`,
  key1003885: `可能的原因：`,
  key1003886: `1.您拣货的时候错误拣出该货品或该货品拣货过多。`,
  key1003887: `2.该货品条码粘贴错误。`,
  key1003888: `请将该货品交由异常处理部门处理。`,
  key1003889: `继续扫描其他货品（回车）`,
  key1003890: `拣货单信息：`,
  key1003891: `出库单信息：`,
  key1003892: `将该包裹重置为未开始分拣`,
  key1003893: `将该包裹从当前拣货单中移除等待生成新拣货单`,
  key1003894: `打印Temu中间标签`,
  key1003895: `请选择商品标签：`,
  key1003896: `见货货单`,
  key1003897: `分拣单`,
  key1003898: `Temu发货订单`,
  key1003899: `sku/名称/规格`,
  key1003900: `总货品数量`,
  key1003901: `已分拣货品数量`,
  key1003902: `包裹`,
  key1003903: `拣货单下出库单均已换单`,
  key1003904: `请扫描正确的SKU/货品身份码`,
  key1003905: `没有可用拣货篮`,
  key1003906: `当前货品无标签地址`,
  key1003907: `多品备货分拣—结束分拣`,
  key1003908: `拣货单取消提示`,
  key1003909: `拣货单内已无出库单，拣货单已取消，点击按钮后将自动结束本次作业。`,
  key1003910: `打印失败`,
  key1003911: `打印标签成功！`,
  key1003912: `分拣打印完成`,
  key1003913: `应发数量`,
  key1003914: `容器货品数量`,
  key1003915: `待分拣数量`,
  key1003916: `打印标签`,
  key1003917: `打印Temu中间标签成功！`,
  key1003918: `获取Temu中间标签地址失败！`,
  key1003919: `未获取到Temu中间标签面单地址`,
  key1003920: `拣货复核-结束作业`,
  key1003921: `您即将结束拣货单 {pickingGoodsNo} 的拣货复核`,
  key1003922: `共 {scannedCount} 人参与本次拣货复核`,
  key1003923: `包装总时间 {scannedTime} 分钟`,
  key1003924: `总计(人.时) {userScannedTime} 分钟`,
  key1003925: `您即将结束拣货单 {pickingGoodsNo} 的拣货复核，但系统检测到以下异常`,
  key1003926: `以下包裹货品已扫描但包裹正在打印中`,
  key1003927: `保存为Excel`,
  key1003928: `通常因打印机信息返回异常导致，需要人工确认地址标签是否已经正常打印。`,
  key1003929: `如果您标记为已打印，强制结束拣货复核时将认为这些包裹已经正常包装，包裹无法再生成新的拣货单。`,
  key1003930: `如果您标记为未打印，强制结束拣货复核时将认为这些包裹未打印包装，系统将把这些包裹从拣货单中移除，您可以重新生成新的拣货单。`,
  key1003931: `以下包裹尚未打印或包裹打印前被作废`,
  key1003932: `强制结束拣货复核时未打印包裹将从拣货单中移除，再次生成新的拣货单。`,
  key1003933: `拣货单打印之后，地址标签打印之前，包裹被作废，将会导致拣货的实物货品剩余。`,
  key1003934: `正常情况下，本项货品汇总应与您手中的货品实物一致。`,
  key1003935: `以下包裹打印之后被作废`,
  key1003936: `地址标签打印后，包裹被作废，会导致包裹被正常包装，建议立即拦截，并在[回收包裹]中进行确认。`,
  key1003937: `结束作业`,
  key1003938: `返回继续包装`,
  key1003939: `已扫描数量`,
  key1003940: `未拣数量`,
  key1003941: `已完成包装包裹总数`,
  key1003942: `已完成包装货品总数`,
  key1003943: `统计项目`,
  key1003944: `拣货单数量`,
  key1003945: `实际完成数量`,
  key1003946: `作业人`,
  key1003947: `包裹总数`,
  key1003948: `作业时长(分钟)`,
  key1003949: `包裹速度(每分钟)`,
  key1003950: `货品速度(每分钟)`,
  key1003951: `收件人`,
  key1003952: `扫描时间`,
  key1003953: `已打`,
  key1003954: `未打`,
  key1003955: `货品小计`,
  key1003956: `扫描货品`,
  key1003957: `获取包裹信息`,
  key1003958: `校对货品数量`,
  key1003959: `发送至打印机`,
  key1003960: `标记已打印`,
  key1003961: `SKU异常`,
  key1003962: `您扫描或输入的SKU【 {warnSku} 】在当前拣货单中未找到`,
  key1003963: `未匹配到指定`,
  key1003964: `当前拣货复核拣货单：`,
  key1003965: `当前操作员：`,
  key1003966: `退出当前作业`,
  key1003967: `自动匹配包裹：`,
  key1003968: `清空已扫描货品并重新扫描`,
  key1003969: `拣货单的出库单`,
  key1003970: `您需要先处理当前未打印的包裹`,
  key1003971: `您提交的扫描的结果，在当前拣货单中未能找到包裹信息，可能原因：`,
  key1003972: `*当前包裹拣货货品存在错误`,
  key1003973: `*您扫描的过程中存在遗漏或者重复。`,
  key1003974: `拣货单不存在或状态已改变,请刷新后重试`,
  key1003975: `拣货复核扫描的出库单不存在`,
  key1003976: `拣货复核扫描出库单已作废`,
  key1003977: `SKU未匹配合适出库单`,
  key1003978: `拣货复核已结束`,
  key1003979: `存在未完成包装的出库单`,
  key1003980: `等待提交后获取包裹信息`,
  key1003981: `物流商/邮寄方式`,
  key1003982: `SKU及数量`,
  key1003983: `打印货品标签`,
  key1003984: `获取速卖通全托管货品条码失败！`,
  key1003985: `名称 / 规格`,
  key1003986: `校对`,
  key1003987: `当前SKU对应订单为速卖通全托管JIT订单，需打印对应货品条码。请注意纸张大小需为60mm*30mm。`,
  key1003988: `打印成功`,
  key1003989: `打印Temu条码`,
  key1003990: `当前出库单：`,
  key1003991: `已装货品数量：`,
  key1003992: `整单完结`,
  key1003993: `分拣容器及对应出库单`,
  key1003994: `名称/规格`,
  key1003995: `已扫描数量不能为空！`,
  key1003996: `已扫 / 未扫`,
  key1003997: `已扫`,
  key1003998: `未扫`,
  key1003999: `打印Temu标签`,
  key1004000: `（部分包装）`,
  key1004001: `（已包装）`,
  key1004002: `（已完结）`,
  key1004003: `超出拣货单数量，请检查实际货品数量!`,
  key1004004: `SKU非此订单所需货品，请确认货品所属订单！`,
  key1004005: `SKU不能为空!`,
  key1004006: `存在未完结出库单，请标记完结后再结束作业。`,
  key1004007: `所有货品均已扫描，请结束作业`,
  key1004008: `超出拣货单数量，请检查实际货品数量`,
  key1004009: `此出库单已标记完结，请勿重复标记！`,
  key1004010: `整单编辑完结后不可进行变更，请确认无误后再进行标记。`,
  key1004011: `标记完结`,
  key1004012: `完结异常提示`,
  key1004013: `当前出库单末进行包装作业，标记完结后状态将流转至待生成拣货单中，请确认无误后再进行标记。`,
  key1004014: `请结束小包后再标记整单完结。`,
  key1004015: `您即将结束拣货单 {pickingGoodsNo} 的分拣作业，但系统检测到以下异常`,
  key1004016: `以下包裹未完成所有货品的分拣`,
  key1004017: `1.拣货货品小于拣货单要求数量。`,
  key1004018: `2.之前的分拣过程在某个拣货框中放入了过多的货品。`,
  key1004019: `3.等待分拣区尚有剩余货品未扫描。`,
  key1004020: `拣货与分拣过程均确保数量准确的情况下，待拣部分货品应该与等待分拣区的剩余货品数量一致。`,
  key1004021: `如果您觉得错误过多，无法追溯，您可以将所有拣货框中的货品全部放回等待分拣区，`,
  key1004022: `并`,
  key1004023: `撤销本次分拣`,
  key1004024: `拣货单可以再次开启分拣作业。`,
  key1004025: `返回继续分拣`,
  key1004026: `拣货框`,
  key1004027: `未分配`,
  key1004028: `货品（已拣/待拣）`,
  key1004029: `异常原因`,
  key1004030: `未开始分拣`,
  key1004031: `分拣未完成`,
  key1004032: `已拣`,
  key1004033: `待拣`,
  key1004034: `货品数量异常提示`,
  key1004035: `本次作业中存在以下SKU仍未扫描，请再次核对货品数量，若存在丢货情况请及时与管理人员联系。`,
  key1004036: `返回作业`,
  key1004037: `应扫数量`,
  key1004038: `实扫数量`,
  key1004039: `打印货品条码`,
  key1004040: `应发货品数量`,
  key1004041: `拣货框货品数量`,
  key1004042: `获取打印面单地址失败！`,
  key1004043: `重置成功！`,
  key1004044: `多品常规分拣—结束分拣`,
  key1004045: `获取货品条码失败！`,
  key1004046: `(Temu样品订单)`,
  key1004047: `(Temu货品订单)`,
  key1004048: `不存在拣货单明细`,
  key1004049: `打印SKU`,
  key1004050: `删除选中`,
  key1004051: `不自动关闭`,
  key1004052: `请下载通途打印控件`,
  key1004053: `条形码未生成成功，请重新点击打印。`,
  key1004054: `展示更多`,
  key1004055: `超出输入限制长度`,
  key1004056: `添加备注成功！`,
  key1004057: `添加备注失败！`,
  key1004058: `新增失败`,
  key1004059: `SKU，支持多个按行分隔`,
  key1004060: `导出出库明细`,
  key1004061: `订单号，支持多个按行分隔`,
  key1004062: `请输入分销商商户号，支持多个按行分隔`,
  key1004063: `请输入分销商订单号，支持多个按行分隔`,
  key1004064: `请输入运单号，支持多个按行分隔`,
  key1004065: `出库人`,
  key1004066: `生成拣货单人`,
  key1004067: `物流分拣人`,
  key1004068: `发货数量（件）`,
  key1004069: `分配库存时间`,
  key1004070: `生成拣货单时间`,
  key1004071: `完成拣货时间`,
  key1004072: `完成分拣时间`,
  key1004073: `完成包装时间`,
  key1004074: `完成物流分拣时间`,
  key1004075: `出库时间`,
  key1004076: `物流商运单号`,
  key1004077: `发货数量`,
  key1004078: `操作人员`,
  key1004079: `活动状态：`,
  key1004080: `活动名称：`,
  key1004081: `请输入活动名称关键字`,
  key1004082: `新增活动`,
  key1004083: `未开始`,
  key1004084: `进行中`,
  key1004085: `已结束`,
  key1004086: `活动图片`,
  key1004087: `活动名称`,
  key1004088: `活动状态`,
  key1004089: `活动开始时间`,
  key1004090: `活动结束时间`,
  key1004091: `长期有效`,
  key1004092: `立即开始`,
  key1004093: `立即开始活动确认`,
  key1004094: `确认后将活动将立即开始并展示在商城端，请确认是否开始？`,
  key1004095: `立即结束`,
  key1004096: `立即结束活动确认`,
  key1004097: `确认后将活动将立即结束，请确认是否结束？`,
  key1004098: `请输入入口名称`,
  key1004099: `图片：`,
  key1004100: `点击跳转：`,
  key1004101: `更换`,
  key1004102: `效果图`,
  key1004103: `金刚区`,
  key1004104: `图片上传`,
  key1004105: `(建议尺寸：60*60)`,
  key1004106: `未填写`,
  key1004107: `效果预览`,
  key1004108: `入口`,
  key1004109: `金刚区图片为空，请上传后重试。`,
  key1004110: `名称不能为空！`,
  key1004111: `请先上传`,
  key1004112: `的图片！`,
  key1004113: `的点击跳转地址不能为空！`,
  key1004114: `图片成功`,
  key1004115: `图片失败`,
  key1004116: `点击跳转`,
  key1004117: `跳转目标类型：`,
  key1004118: `跳转目标：`,
  key1004119: `活动`,
  key1004120: `指定链接`,
  key1004121: `不跳转`,
  key1004122: `跳转目标类型不能为空`,
  key1004123: `指定链接地址必须是https://或者http://开头的链接！`,
  key1004124: `跳转目标不能为空!`,
  key1004125: `请输入站点名称`,
  key1004126: `站点域名：`,
  key1004127: `请输入站点域名`,
  key1004128: `站点说明：`,
  key1004129: `请输入站点说明`,
  key1004130: `站点名称`,
  key1004131: `站点域名`,
  key1004132: `站点说明`,
  key1004133: `查看站点信息`,
  key1004134: `编辑站点信息`,
  key1004135: `banner图：`,
  key1004136: `首页轮播图`,
  key1004137: `顶部广告位`,
  key1004138: `侧边广告位`,
  key1004139: `首页-个人信息下方广告位`,
  key1004140: `更改启用状态提示`,
  key1004141: `此操作将更改该广告位在商城端是否可见，请确认是否继续更改。`,
  key1004142: `(建议尺寸：70*228)`,
  key1004143: `广告图为空，请上传后重试。`,
  key1004144: `点击跳转地址不能为空！`,
  key1004145: `(建议高度：80px)`,
  key1004146: `banner图为空，请上传后重试。`,
  key1004147: `(建议尺寸：228*70)`,
  key1004148: `统一定价(`,
  key1004149: `是否新增为首页tab：`,
  key1004150: `是否展示供应商信息：`,
  key1004151: `活动开始时间：`,
  key1004152: `选择活动开始时间`,
  key1004153: `活动结束时间：`,
  key1004154: `选择活动结束时间`,
  key1004155: `是否统一设置价格：`,
  key1004156: `配置匹配规则`,
  key1004157: `匹配规则：`,
  key1004158: `上新天数：`,
  key1004159: `移除商品确认`,
  key1004160: `活动信息`,
  key1004161: `(建议尺寸：1200*160)`,
  key1004162: `活动定价`,
  key1004163: `活动商品`,
  key1004164: `指定商品`,
  key1004165: `规则匹配`,
  key1004166: `配置规则`,
  key1004167: `批量移除`,
  key1004168: `规则说明：`,
  key1004169: `1.系统将自动匹配满足一定上新天数内的商品，如：填写7天，则系统将按审核通过时间匹配与今天相差7天内的商品添加至活动中。`,
  key1004170: `移除后商品将不再参加此活动，价格可能发生改变，系统将提醒已订阅的分销商。`,
  key1004171: `移除商品`,
  key1004172: `活动开始时间不能为空`,
  key1004173: `按上新天数匹配`,
  key1004174: `匹配规则不能为空`,
  key1004175: `上新天数不能为空`,
  key1004176: `标语：`,
  key1004177: `商品活动`,
  key1004178: `活动结束时间不能为空`,
  key1004179: `请先勾选参与活动的商品！`,
  key1004180: `请先配置匹配规则！`,
  key1004181: `统一定价不能为空！`,
  key1004182: `活动结束时间不能小于活动开始时间！`,
  key1004183: `费用配置`,
  key1004184: `发货仓：`,
  key1004185: `单件处理费：`,
  key1004186: `续件处理费：`,
  key1004187: `仓库处理费 = `,
  key1004188: `（件数-1）`,
  key1004189: `发货仓不能为空`,
  key1004190: `单件处理费不能为空`,
  key1004191: `续件处理费不能为空`,
  key1004192: `(建议尺寸：644*324)`,
  key1004193: `删除轮播项`,
  key1004194: `+添加轮播项`,
  key1004195: `轮播项`,
  key1004196: `轮播图为空，请上传后重试。`,
  key1004197: `轮播项1`,
  key1004198: `创建补贴规则`,
  key1004199: `补贴规则名称`,
  key1004200: `规则状态`,
  key1004201: `添加子规则`,
  key1004202: `运费补贴`,
  key1004203: `添加费用配置`,
  key1004204: `订单续件`,
  key1004205: `件`,
  key1004206: `发货仓`,
  key1004207: `费用规则`,
  key1004208: `补贴对象：`,
  key1004209: `补贴类型：`,
  key1004210: `选择时间`,
  key1004211: `多个商户号请用英文逗号，或换行分隔`,
  key1004212: `条件`,
  key1004213: `此名称客户可见，请合理命名`,
  key1004214: `补贴条件：`,
  key1004215: `添加条件`,
  key1004216: `补贴金额：`,
  key1004217: `子规则补贴将与主规则叠加，请合理配置`,
  key1004218: `单个提单补贴：`,
  key1004219: `0-1公斤补贴：`,
  key1004220: `续重每公斤补贴：`,
  key1004221: `单个提单补贴上限：`,
  key1004222: `补贴对象不能为空`,
  key1004223: `补贴类型不能为空`,
  key1004224: `创建子规则`,
  key1004225: `编辑补贴规则`,
  key1004226: `编辑子规则`,
  key1004227: `补贴规则未完善，请完整填写后重试。`,
  key1004228: `提单内货品数量必须是大于等于0整数`,
  key1004229: `订单发货率必须是大于等于0`,
  key1004230: `补贴金额未完善，请完整填写后重试。`,
  key1004231: `输入商户号`,
  key1004232: `服务器选择`,
  key1004233: `搜索：`,
  key1004234: `云卖刊登`,
  key1004235: `入驻成功`,
  key1004236: `更多角色`,
  key1004237: `可输入员工姓名、邮箱、手机号`,
  key1004238: `账号状态：`,
  key1004239: `管理员工角色`,
  key1004240: `添加新员工`,
  key1004241: `按姓名`,
  key1004242: `按邮箱`,
  key1004243: `最后登录时间`,
  key1004244: `是否要删除当前角色？`,
  key1004245: `角色名称：`,
  key1004246: `请输入角色名称`,
  key1004247: `组装加工 - `,
  key1004248: `拣货 - `,
  key1004249: `模块`,
  key1004250: `添加新角色`,
  key1004251: `角色名称不能为空`,
  key1004252: `编辑角色`,
  key1004253: `基本资料`,
  key1004254: `所属角色`,
  key1004255: `数据权限`,
  key1004256: `仓库权限`,
  key1004257: `仓库所有数据权限`,
  key1004258: `拓客数据权限`,
  key1004259: `所有数据权限`,
  key1004260: `新增员工`,
  key1004261: `邮箱不能为空`,
  key1004262: `查看员工`,
  key1004263: `编辑员工`,
  key1004264: `规则名：`,
  key1004265: `实际发货物流方式：`,
  key1004266: `帐号：`,
  key1004267: `选择指定物流`,
  key1004268: `选择符合条件物流`,
  key1004269: `已停用，请修改后重新保存`,
  key1004270: `物流相关设置`,
  key1004271: `时效：小于`,
  key1004272: `无`,
  key1004273: `有`,
  key1004274: `请输入规则名`,
  key1004275: `请添加物流方式`,
  key1004276: `时效和运单号请至少选择一个`,
  key1004277: `实际发货物流方式不能为空`,
  key1004278: `账户不能为空`,
  key1004279: `新增物流`,
  key1004280: `选择物流：`,
  key1004281: `请选择自定义物流类型：`,
  key1004282: `自定义物流商代码：`,
  key1004283: `自定义物流商名称：`,
  key1004284: `API对接`,
  key1004285: `类型不能为空`,
  key1004286: `自定义物流商代码不能为空`,
  key1004287: `自定义物流商名称不能为空`,
  key1004288: `无需数据对接`,
  key1004289: `Excel对接`,
  key1004290: `输入名称搜索`,
  key1004291: `物流渠道名称/代码：`,
  key1004292: `未启用物流方式`,
  key1004293: `新建自定义物流`,
  key1004294: `物流方案代码`,
  key1004295: `接收方指定提交代码`,
  key1004296: `物流时效`,
  key1004297: `发件地址`,
  key1004298: `揽收地址`,
  key1004299: `退件地址`,
  key1004300: `请勾选要启用的物流方式`,
  key1004301: `关联物流`,
  key1004302: `物流渠道名称：`,
  key1004303: `添加关联`,
  key1004304: `物流仅支持绑定一条渠道！`,
  key1004305: `(天)`,
  key1004306: `确定要取消关联？`,
  key1004307: `取消关联`,
  key1004308: `请先添加关联物流！`,
  key1004309: `添加成功!`,
  key1004310: `请先选择完整的物流方式!`,
  key1004311: `编辑自定义物流`,
  key1004312: `交运方式不能为空`,
  key1004313: `揽收时间不能为空`,
  key1004314: `交货方式：`,
  key1004315: `国内快递费用：`,
  key1004316: `物流服务商(Carrier)：`,
  key1004317: `发货国家：`,
  key1004318: `揽收方式：`,
  key1004319: `配送服务：`,
  key1004320: `交运方式：`,
  key1004321: `面单模板`,
  key1004322: `'面单尺寸'`,
  key1004323: `发货说明：`,
  key1004324: `全球可达(运费相同)`,
  key1004325: `全球可达(运费不同)`,
  key1004326: `非全球可达`,
  key1004327: ` 运费折扣： `,
  key1004328: ` 通折： `,
  key1004329: `渠道基础信息`,
  key1004330: `物流商类型：`,
  key1004331: `无对接`,
  key1004332: `物流方案代码：`,
  key1004333: `参考时效：`,
  key1004334: `保险服务：`,
  key1004335: `尺寸限制(cm)：`,
  key1004336: `适用重量`,
  key1004337: `周长限制(cm)：`,
  key1004338: `最长边限制(cm)：`,
  key1004339: `体积限制(cm³)：`,
  key1004340: `追踪信息查询网址：`,
  key1004341: `揽收点地址信息`,
  key1004342: `国内快递收货地址选择：`,
  key1004343: `使用固定地址模板`,
  key1004344: `使用订单地址`,
  key1004345: `物流商可选设置(默认设置)`,
  key1004346: `派送范围及运费`,
  key1004347: `派送范围`,
  key1004348: `计费模型`,
  key1004349: `从其他渠道引用`,
  key1004350: `批量导入`,
  key1004351: `新增分区`,
  key1004352: `运费币种：`,
  key1004353: `运费折扣`,
  key1004354: `通折`,
  key1004355: `请注意，无折扣时，请输入1，八折时，请输入0.8`,
  key1004356: `适用仓库`,
  key1004357: `全部自营仓库`,
  key1004358: `平台标发货设置`,
  key1004359: `不填运单号`,
  key1004360: `（默认）`,
  key1004361: `添加特例国家`,
  key1004362: `面单打印设置`,
  key1004363: `打印配货清单`,
  key1004364: `标准报价`,
  key1004365: `操作费（元/票）`,
  key1004366: `首重（g）`,
  key1004367: `首重运费`,
  key1004368: `续重单位重量（g）`,
  key1004369: `续重单价（元）`,
  key1004370: `特例国家/地区不能为空`,
  key1004371: `物流服务商(Carrier)不能为空`,
  key1004372: `不能添加相同名称的配送服务！`,
  key1004373: `分区`,
  key1004374: `默认分区`,
  key1004375: `通达国家`,
  key1004376: `所有国家`,
  key1004377: `计费方式`,
  key1004378: `计重方式`,
  key1004379: `计费公式`,
  key1004380: `手动同步`,
  key1004381: `下发物流商成功后同步`,
  key1004382: `获取运单号成功后同步`,
  key1004383: `出库成功后同步`,
  key1004384: `折扣价`,
  key1004385: `寄件人地址`,
  key1004386: `揽收人地址`,
  key1004387: `退件人地址`,
  key1004388: `买家自送`,
  key1004389: `国内快递费用不能为空`,
  key1004390: `物流渠道代码不能为空`,
  key1004391: `默认分区外计费模型国家不能为空`,
  key1004392: `分段计费表格参数不匹配或不完整`,
  key1004393: `编辑物流渠道`,
  key1004394: `查看物流渠道`,
  key1004395: `新增物流渠道`,
  key1004396: `首重`,
  key1004397: `元`,
  key1004398: `续重`,
  key1004399: `不能添加相同名称的物流商服务！`,
  key1004400: `规则指定订单来源`,
  key1004401: `规则指定订单目的国家/地区包括`,
  key1004402: `规则指定发货指定方式`,
  key1004403: `适用范围`,
  key1004404: `适用平台：`,
  key1004405: `适用店铺：`,
  key1004406: `适用包裹金额($)：`,
  key1004407: `买家支付的运费范围($)：`,
  key1004408: `包裹包含SKU：`,
  key1004409: `支持多个SKU，请用逗号或空格分开`,
  key1004410: `商品总数量范围：`,
  key1004411: `运费最低`,
  key1004412: `时效最快`,
  key1004413: `上网时间最短`,
  key1004414: `妥投率最高`,
  key1004415: `智能物流匹配逻辑说明`,
  key1004416: `匹配物流渠道基础属性`,
  key1004417: `必要流程：找出可发物流商`,
  key1004418: `订单匹配物流渠道基础属性中的：`,
  key1004419: `1.尺寸限制`,
  key1004420: `2.重量限制`,
  key1004421: `3.接收类型限制`,
  key1004422: `4.配送范围限制`,
  key1004423: `匹配结果=1结束，匹配结果>1进行下一步`,
  key1004424: `5.发货仓库支持的物流渠道`,
  key1004425: `匹配物流规则`,
  key1004426: `上一步匹配成功的结果集跑物流匹配规则，通过规则引擎匹配，从上到下匹配`,
  key1004427: `匹配物流适用范围`,
  key1004428: `可选流程：适用范围`,
  key1004429: `用户可自定义物流渠道的适用范围，匹配所有渠道的适用范围`,
  key1004430: `运费/时效优先级`,
  key1004431: `必要流程：选择运费最低或时效最快`,
  key1004432: `计算所有符合条件物流渠道的运费/时效，根据设置的优先级选择运费最低或时效最快的`,
  key1004433: `物流规则`,
  key1004434: `新增物流规则`,
  key1004435: `已选发货指定方式：`,
  key1004436: `物流渠道适用范围`,
  key1004437: `更多仓库`,
  key1004438: `拖拽`,
  key1004439: `规格名称`,
  key1004440: `最后修改人`,
  key1004441: `物流商/物流渠道`,
  key1004442: `平台/账号`,
  key1004443: `运费范围`,
  key1004444: `包含sku`,
  key1004445: `数量范围`,
  key1004446: `所有`,
  key1004447: `新建规则`,
  key1004448: `物流名称：`,
  key1004449: `关联物流渠道：`,
  key1004450: `关联物流渠道`,
  key1004451: `关联`,
  key1004452: `分销商物流`,
  key1004453: `供应商物流`,
  key1004454: `适用范围：`,
  key1004455: `适用重量 (g)：`,
  key1004456: `体积限制(cm)：`,
  key1004457: `省州：`,
  key1004458: `街道：`,
  key1004459: `旺旺：`,
  key1004460: `揽件时间：`,
  key1004461: `代理人`,
  key1004462: `国内`,
  key1004463: `国外`,
  key1004464: `方案基础信息`,
  key1004465: `重量单位：`,
  key1004466: `速卖通线上发货设置`,
  key1004467: `eDIS物流设置`,
  key1004468: `Logistics设置`,
  key1004469: `选择发件地址：`,
  key1004470: `选择揽收地址：`,
  key1004471: `交运地址：`,
  key1004472: `选择退件地址：`,
  key1004473: `交运方式与揽件时间`,
  key1004474: `卖家自送`,
  key1004475: `清关费用配置`,
  key1004476: `是否收取清关费用：`,
  key1004477: `收取`,
  key1004478: `不收取`,
  key1004479: `比例配置：申报价值在`,
  key1004480: `至150EUR时，按如下税率计算后`,
  key1004481: `超出150欧的包裹将下发物流失败。`,
  key1004482: `续重单价`,
  key1004483: `税率`,
  key1004484: `税率必须是大于等于0且最多允许2位小数`,
  key1004485: `默认分区外计费模型国家不能为空!`,
  key1004486: `必填项不能为空!`,
  key1004487: `内容不能为空`,
  key1004488: `物流商名称：`,
  key1004489: `是否可用：`,
  key1004490: `适用平台不能为空`,
  key1004491: `线下物流`,
  key1004492: `线上物流`,
  key1004493: `物流商名称`,
  key1004494: `分销商是否可用`,
  key1004495: `物流账户`,
  key1004496: `适用平台`,
  key1004497: `对接类型`,
  key1004498: `编辑账号`,
  key1004499: `修改适用范围`,
  key1004500: `分销商是否可用：`,
  key1004501: `账号名称不能为空`,
  key1004502: `个人姓名/公司名称不能为空！`,
  key1004503: `地址信息不能为空！`,
  key1004504: `账号名称：`,
  key1004505: `授权状态：`,
  key1004506: `个人姓名/公司名称：`,
  key1004507: `联系电话：`,
  key1004508: `地址信息：`,
  key1004509: `重新授权`,
  key1004510: `去授权`,
  key1004511: `已授权`,
  key1004512: `未授权`,
  key1004513: `EU代理人`,
  key1004514: `获取申请授权回调地址失败！`,
  key1004515: `使用的IOSS：`,
  key1004516: `条件类型：`,
  key1004517: `平台：`,
  key1004518: `店铺：`,
  key1004519: `此名称仅用于更好区分不同IOSS`,
  key1004520: `按店铺`,
  key1004521: `按平台`,
  key1004522: `请选择使用的IOSS`,
  key1004523: `请选择店铺`,
  key1004524: `添加规则`,
  key1004525: `添加IOSS`,
  key1004526: `申报说明：`,
  key1004527: `YMS将按照产品在YMS中的销售价格，即对应YMS订单的商品价格进行申报。`,
  key1004528: `查看税率`,
  key1004529: `当平台订单中存在IOSS号时，YMS将优先使用平台返回的IOSS编号，若无法匹配到IOSS号时系统将拦截此订单。`,
  key1004530: `手工订单`,
  key1004531: `IOSS管理`,
  key1004532: `IOSS规则配置`,
  key1004533: `IOSS名称`,
  key1004534: `IOSS编号`,
  key1004535: `使用IOSS名称`,
  key1004536: `使用IOSS编号`,
  key1004537: `适用平台/店铺`,
  key1004538: `适用店铺`,
  key1004539: `启用IOSS确认`,
  key1004540: `停用IOSS确认`,
  key1004541: `启用IOSS后，相关规则将可被匹配，请确认是否启用IOSS？`,
  key1004542: `停用IOSS后，使用此IOSS编号的规则将同时停用，请确认是否停用IOSS?`,
  key1004543: `启用IOSS规则`,
  key1004544: `停用IOSS规则`,
  key1004545: `请确认是否启用规则？`,
  key1004546: `请确认是否停用规则？`,
  key1004547: `原价`,
  key1004548: `调整后价格`,
  key1004549: `费率`,
  key1004550: `验证身份`,
  key1004551: `设置新邮箱`,
  key1004552: `验证码：`,
  key1004553: `手机验证码：`,
  key1004554: `请输入手机验证码`,
  key1004555: `新邮箱：`,
  key1004556: `邮箱验证码：`,
  key1004557: `请输入邮箱验证码`,
  key1004558: `下一步`,
  key1004559: `提`,
  key1004560: `交`,
  key1004561: `图片验证码不能为空`,
  key1004562: `手机号格式不正确!`,
  key1004563: `手机号和图片验证码不能为空！`,
  key1004564: `邮箱格式不正确!`,
  key1004565: `邮箱不能为空！`,
  key1004566: `验证码已发送！`,
  key1004567: `手机号不能为空`,
  key1004568: `邮箱格式不正确`,
  key1004569: `手机验证`,
  key1004570: `邮箱验证`,
  key1004571: `登录密码`,
  key1004572: `定期更换密码有助于账户安全`,
  key1004573: `上新日期：`,
  key1004574: `上新SPU：`,
  key1004575: `全部标记已读`,
  key1004576: `标记已读`,
  key1004577: `仅看未读`,
  key1004578: `订单异常通知`,
  key1004579: `停售通知`,
  key1004580: `调价通知`,
  key1004581: `店铺上新通知`,
  key1004582: `自动规则通知`,
  key1004583: `请先勾选要标记的数据！`,
  key1004584: `分销商名称：`,
  key1004585: `币种：`,
  key1004586: `修改币种`,
  key1004587: `修改手机`,
  key1004588: `电话：`,
  key1004589: `修改邮箱`,
  key1004590: `IOSS名称：`,
  key1004591: `IOSS编号：`,
  key1004592: `IOSS名称不能为空`,
  key1004593: `编辑IOSS`,
  key1004594: `IOSS编号不能为空`,
  key1004595: `请输入IM+10位数字`,
  key1004596: `原密码：`,
  key1004597: `新密码：`,
  key1004598: `再次确认新密码：`,
  key1004599: `原密码不能为空`,
  key1004600: `新密码不能为空`,
  key1004601: `更新成功!`,
  key1004602: `新密码与确认新密码不一致`,
  key1004603: `设置新手机`,
  key1004604: `新手机号：`,
  key1004605: `请输入新手机号`,
  key1004606: `邮箱和图片验证码不能为空！`,
  key1004607: `手机号码格式不正确!`,
  key1004608: `手机号不能为空！`,
  key1004609: `最小库存`,
  key1004610: `最大库存`,
  key1004611: `¥最低价`,
  key1004612: `¥最高价`,
  key1004613: `价格：`,
  key1004614: `一键刊登`,
  key1004615: `下载资料`,
  key1004616: `综合`,
  key1004617: `销量`,
  key1004618: `上新时间`,
  key1004619: `总库存`,
  key1004620: `请勾选需要取消收藏的商品`,
  key1004621: `取消收藏成功!`,
  key1004622: `请勾选需要收藏的商品`,
  key1004623: `商品收藏成功`,
  key1004624: `请勾选需要下载资料的商品`,
  key1004625: `YMS订单`,
  key1004626: `平台订单`,
  key1004627: `备货单`,
  key1004628: `订单规则`,
  key1004629: `每隔`,
  key1004630: `分钟一次`,
  key1004631: `可用数量=`,
  key1004632: `订单超过`,
  key1004633: `小时未装箱时自动取消`,
  key1004634: `库存同步规则`,
  key1004635: `商品状态同步规则`,
  key1004636: `店铺`,
  key1004637: `刊登模板`,
  key1004638: `同步时间`,
  key1004639: `启动`,
  key1004640: `库存同步`,
  key1004641: `商品状态同步`,
  key1004642: `价格同步`,
  key1004643: `供应商仓库`,
  key1004644: `商品停售时库存自动调整为0`,
  key1004645: `商品停售时自动同步状态至上架工具`,
  key1004646: `删除后将不可恢复，你确定要删除？`,
  key1004647: `请选择适用平台`,
  key1004648: `此规则适用于商品价格发生变动时自动通过`,
  key1004649: `调整价格`,
  key1004650: `请选择适用店铺`,
  key1004651: `添加价格同步规则`,
  key1004652: `修改价格同步规则`,
  key1004653: `价格同步规则`,
  key1004654: `可能会因为库存数量基数较小，浮动后使库存为0，也可能因为库存基数较大，浮动后与浮动前库存没有变化，请谨慎设置浮动比例。`,
  key1004655: `公式计算结果四舍五入，比如：计算结果为9.5，实际取值为10。`,
  key1004656: `向下浮动：`,
  key1004657: `同步时间：`,
  key1004658: `请选择仓库`,
  key1004659: `添加库存规则`,
  key1004660: `编辑库存规则`,
  key1004661: `个平台`,
  key1004662: `订单超时不能为空`,
  key1004663: `订单同步规则`,
  key1004664: `此规则适用于商品停售时库存自动调0及与上架工具状态同步。`,
  key1004665: `当任一规则中勾选此项后，商品停售状态将同步至上架工具中。`,
  key1004666: `添加商品状态同步规则`,
  key1004667: `修改商品状态同步规则`,
  key1004668: `变动类型`,
  key1004669: `采购到货`,
  key1004670: `分配库存`,
  key1004671: `库存盘点`,
  key1004672: `变动时间`,
  key1004673: `Temu店铺：`,
  key1004674: `上传状态`,
  key1004675: `已上传`,
  key1004676: `未上传`,
  key1004677: `重新上传`,
  key1004678: `上传文件格式有误，请上传条码PDF文件或PNG图片`,
  key1004679: `刊登店铺`,
  key1004680: `刊登状态：`,
  key1004681: `刊登平台：`,
  key1004682: `已刊登`,
  key1004683: `未刊登`,
  key1004684: `刊登数量（item）`,
  key1004685: `刊登状态`,
  key1004686: `设置别名`,
  key1004687: `请输入别名`,
  key1004688: `该别名已与 YMSSKU`,
  key1004689: `相同`,
  key1004690: `不允许出现相同别名`,
  key1004691: `设置商品别名后，出现平台订单，订单商品SKU与YMS商品SKU匹配失败时，订单商品SKU`,
  key1004692: `可再与YMS商品的别名进行匹配，匹配成功后订单自动匹配该商品，无需分销商手动选择匹配;`,
  key1004693: `收藏状态：`,
  key1004694: `收藏时间：`,
  key1004695: `售卖资料：`,
  key1004696: `库存：`,
  key1004697: `请输入整数`,
  key1004698: `导出商品资料`,
  key1004699: `生成售卖资料提示`,
  key1004700: `批量生成售卖资料`,
  key1004701: `批量导出商品资料`,
  key1004702: `忽略已生成`,
  key1004703: `生成`,
  key1004704: `勾选的`,
  key1004705: `条商品中有`,
  key1004706: `条已生成售卖记录，请选择是否重新生成?`,
  key1004707: `库存（件）`,
  key1004708: `享`,
  key1004709: `折`,
  key1004710: `减`,
  key1004711: `件以上`,
  key1004712: `折扣价：`,
  key1004713: `优惠：`,
  key1004714: `下一阶段优惠：`,
  key1004715: `近30天内销量`,
  key1004716: `上架时间`,
  key1004717: `收藏时间`,
  key1004718: `售卖资料`,
  key1004719: `生成售卖时间`,
  key1004720: `生成售卖资料`,
  key1004721: `取消收藏`,
  key1004722: `重新刊登`,
  key1004723: `设置关联关系`,
  key1004724: `商品SPU`,
  key1004725: `请输入商品SPU,支持多个按行分隔`,
  key1004726: `商品SKU`,
  key1004727: `请输入商品SKU,支持多个按行分隔`,
  key1004728: `请输入商品标题,支持多个按行分隔`,
  key1004729: `请输入供应商名称,支持多个按行分隔`,
  key1004730: `请输入供应商编号,支持多个按行分隔`,
  key1004731: `商品基本信息`,
  key1004732: `商品基本信息表`,
  key1004733: `商品图片`,
  key1004734: `轮播图`,
  key1004735: `属性图`,
  key1004736: `未生成`,
  key1004737: `已生成`,
  key1004738: `超出单次下载数量`,
  key1004739: `一次最多可下载10个商品，请重新选择后下载。`,
  key1004740: `请输入SKU`,
  key1004741: `此SKU已与 YMSSKU`,
  key1004742: `形成关联关系`,
  key1004743: `平台商品SKU：`,
  key1004744: `一个平台商品仅允许形成一个绑定关系`,
  key1004745: `设置商品关联关系后，出现平台订单，订单商品SKU与YMS商品SKU匹配失败时，订单商品可通过关联关`,
  key1004746: `系与YMS商品进行匹配，匹配成功后订单自动匹配该商品，无需分销商手动选择匹配；当YMS商品同时设`,
  key1004747: `置了`,
  key1004748: `别名`,
  key1004749: `关联关系”时，优先使用`,
  key1004750: `关联关系”进行匹配;`,
  key1004751: `需要将“平台`,
  key1004752: `、“店铺”、“平台商品SKU”填写完整，才会创建对应的关联关系;`,
  key1004753: `请输入商品SPU，支持多个按行分隔`,
  key1004754: `商品标题：`,
  key1004755: `发起生成时间：`,
  key1004756: `发起人：`,
  key1004757: `剩余`,
  key1004758: `条预计`,
  key1004759: `分钟后生成完毕`,
  key1004760: `批量停止生成售卖资料`,
  key1004761: `生成中`,
  key1004762: `生成成功`,
  key1004763: `生成失败`,
  key1004764: `生成状态`,
  key1004765: `待生成`,
  key1004766: `发起生成售卖时间`,
  key1004767: `失败原因`,
  key1004768: `发起人`,
  key1004769: `停止生成`,
  key1004770: `只有待生成的状态可以停止生成售卖资料`,
  key1004771: `请先勾选要停止生成的数据！`,
  key1004772: `停止生成售卖资料确认`,
  key1004773: `正在操作停止生成售卖资料，确认后将取消所有未生成的任务，如需重新生成需重新在收藏的商品中进行操作，请确认是否停止生成？`,
  key1004774: `库存优先级配置`,
  key1004775: `使用库存优先级`,
  key1004776: `是否等待在途库存`,
  key1004777: `优先使用本人货权的库存`,
  key1004778: `优先使用其他在YMS仓的库存`,
  key1004779: `等待`,
  key1004780: `不等待`,
  key1004781: `SKU库存区间：`,
  key1004782: `导出库存`,
  key1004783: `7日：`,
  key1004784: `14日：`,
  key1004785: `最近销量`,
  key1004786: `我的可用库存`,
  key1004787: `我的在途库存`,
  key1004788: `平均货品成本`,
  key1004789: `SKU库存开始区间不能大于SKU库存结束区间`,
  key1004790: `选择时长`,
  key1004791: `店铺时长`,
  key1004792: `价格CNY`,
  key1004793: `1个月`,
  key1004794: `3个月`,
  key1004795: `6个月`,
  key1004796: `12个月`,
  key1004797: `开通店铺`,
  key1004798: `续费确认`,
  key1004799: `确认续费`,
  key1004800: `正在充值中...`,
  key1004801: `开通成功`,
  key1004802: `续费成功`,
  key1004803: `开通失败`,
  key1004804: `续费失败`,
  key1004805: `请输入代号、账户名`,
  key1004806: `未添加店铺时，如果需要eDIS发货，请先在eDIS绑定eBayID后再添加店铺。`,
  key1004807: `已添加店铺时，如果需要eDIS发货，请在eDIS绑定eBayID后再重新授权店铺。`,
  key1004808: `授权状态`,
  key1004809: `前往授权`,
  key1004810: `首次授权时间`,
  key1004811: `授权更新时间`,
  key1004812: `通途状态`,
  key1004813: `到期时间`,
  key1004814: `永久有效`,
  key1004815: `未开通`,
  key1004816: `已过期`,
  key1004817: `开通`,
  key1004818: `续费`,
  key1004819: `授权成功！`,
  key1004820: `添加账号成功！`,
  key1004821: `角色描述：`,
  key1004822: `请输入角色描述`,
  key1004823: `权限分配`,
  key1004824: `新增角色`,
  key1004825: `查看角色`,
  key1004826: `添加角色`,
  key1004827: `角色名称`,
  key1004828: `角色描述`,
  key1004829: `用户数量（已启用）`,
  key1004830: `角色下已关联用户时，不能删除角色。`,
  key1004831: `确认要删除该角色？`,
  key1004832: `删除该角色后，将不可恢复，你确认要删除？`,
  key1004833: `删除角色成功！`,
  key1004834: `查看用户`,
  key1004835: `账号类型`,
  key1004836: `主账号`,
  key1004837: `子账号`,
  key1004838: `账号角色`,
  key1004839: `请输入邮箱地址`,
  key1004840: `生成密码`,
  key1004841: `店铺权限`,
  key1004842: `添加用户`,
  key1004843: `编辑用户`,
  key1004844: `新增成功，上架工具用户角色授权更新失败！`,
  key1004845: `请输入手机号码、邮箱或姓名`,
  key1004846: `修改密码`,
  key1004847: `修改密码后，原密码将不可使用，请谨慎修改！`,
  key1004848: `请输入供应商名称`,
  key1004849: `粉丝数：`,
  key1004850: `进入店铺`,
  key1004851: `是否确定取消关注？`,
  key1004852: `货品费用退费`,
  key1004853: `包裹处理退费`,
  key1004854: `物流退费`,
  key1004855: `国内快递退费`,
  key1004856: `货品费用`,
  key1004857: `活动说明`,
  key1004858: `您当前可用余额为`,
  key1004859: `继续支付`,
  key1004860: `支付成功，后台入账中`,
  key1004861: `,第三方凭证号:`,
  key1004862: `导出费用明细`,
  key1004863: `扣款`,
  key1004864: `退款`,
  key1004865: `平台订单币种`,
  key1004866: `商品数量(件)`,
  key1004867: `物流重量`,
  key1004868: `sku销售价`,
  key1004869: `sku优惠价`,
  key1004870: `请输入平台订单号`,
  key1004871: `余额充值`,
  key1004872: `充值金额：`,
  key1004873: `其他金额`,
  key1004874: `当前汇率：1`,
  key1004875: `账户货币为`,
  key1004876: `充值金额不能超过100000000`,
  key1004877: `充值金额不能小于等于0`,
  key1004878: `（已取消）`,
  key1004879: `备货至 `,
  key1004880: `取消金额：`,
  key1004881: `退款金额：`,
  key1004882: `订单商品`,
  key1004883: `商品单价`,
  key1004884: `实付单价`,
  key1004885: `下单数量`,
  key1004886: `到仓数量`,
  key1004887: `部分货品未到仓，未到仓部分已退款。`,
  key1004888: `用户取消货品数量`,
  key1004889: `商品总重量(g)`,
  key1004890: `货品总值`,
  key1004891: `已付款`,
  key1004892: `供应商已发货`,
  key1004893: `待备货`,
  key1004894: `未完结`,
  key1004895: `请选择Temu店铺`,
  key1004896: `请填写Temu订单号`,
  key1004897: `你还未添加商品`,
  key1004898: `处理费=3+(商品数量-1)*0.5`,
  key1004899: `应付总额会在提交订单时进行预扣，请确保账户有足够的余额。`,
  key1004900: `收起地址`,
  key1004901: `更多地址`,
  key1004902: `发货订单`,
  key1004903: `寄样订单`,
  key1004904: `删除商品`,
  key1004905: `请注意：`,
  key1004906: `1.寄样订单，若一个SPU下需发多个SKU，请务必将SKU置于一个订单内进行下单。`,
  key1004907: `2.发货订单，请务必按照TEMU后台备货单进行下单，不可将多张备货单置于同一YMS订单下。`,
  key1004908: `商品数量：`,
  key1004909: `商品总重量(g)：`,
  key1004910: `商品总金额：`,
  key1004911: `收货地址`,
  key1004912: `设为默认地址`,
  key1004913: `新增收货地址`,
  key1004914: `应付总额：`,
  key1004915: `数量（件）`,
  key1004916: `小计`,
  key1004917: `Temu店铺不能为空`,
  key1004918: `Temu订单号不能为空`,
  key1004919: `请先勾选要移除的商品！`,
  key1004920: `编辑收货地址`,
  key1004921: `是否要删除当前收货地址？`,
  key1004922: `取消提示`,
  key1004923: `取消后将不会保存已填写的内容，你确定要取消？`,
  key1004924: `继续填写`,
  key1004925: `订单信息填写不完整，请填写完订单信息再提交!`,
  key1004926: `配送方式`,
  key1004927: `运费：`,
  key1004928: `修改配送方式`,
  key1004929: `选择配送方式`,
  key1004930: `使用IOSS：`,
  key1004931: `前往配置`,
  key1004932: `物流代缴时将产生额外费用，YMS将代为收取。`,
  key1004933: `本单将额外收取`,
  key1004934: `请先选择物流`,
  key1004935: `仓库存`,
  key1004936: `是否确定提交该订单？`,
  key1004937: `当您账户余额充足时，会自动使用账户余额进行订单金额预扣，如果账户余额不足，`,
  key1004938: `则会为您创建“待付款”状态的订单，需要您进行支付后，平台才会进行订单履约。`,
  key1004939: `当前的收货地址不支持配送！`,
  key1004940: `请选择使用的IOSS！`,
  key1004941: `请输入IOSS编号！`,
  key1004942: `请输入IM+10位数字！`,
  key1004943: `订单提交`,
  key1004944: `账户余额不足，以为您创建"待付款”状态订单`,
  key1004945: `账户余额充足，预扣成功`,
  key1004946: `录入IOSS`,
  key1004947: `匹配物流：`,
  key1004948: `更换物流后，清关费用将重新计算，原先收取的费用将退回本单将收取`,
  key1004949: `清关费用`,
  key1004950: `匹配物流`,
  key1004951: `指定仓库物流方式`,
  key1004952: `修改物流地址`,
  key1004953: `买家修改地址，修改前后信息如下，请确认是否修改阿里国际订单地址？`,
  key1004954: `原地址：`,
  key1004955: `收件人姓名`,
  key1004956: `联系方式`,
  key1004957: `新地址：`,
  key1004958: `失败原因：`,
  key1004959: `地址更新失败提示`,
  key1004960: `标记已处理`,
  key1004961: `重试`,
  key1004962: `物流渠道名称`,
  key1004963: `请先勾选要配送方式的数据！`,
  key1004964: `不在重量区间之内！`,
  key1004965: `订单原因，此原因仅`,
  key1004966: `可见。`,
  key1004967: `取消范围：`,
  key1004968: `取消商品：`,
  key1004969: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单!`,
  key1004970: `当前`,
  key1004971: `订单中暂无商品`,
  key1004972: `取消订单后，订单不再发货，已装箱订单取消需收取手续费，取消后本单将收取`,
  key1004973: `费用，确定取消订单?`,
  key1004974: `请选择取消范围`,
  key1004975: `整单取消`,
  key1004976: `取消部分商品`,
  key1004977: `取消部分商品时，取消数量不能全部为空！`,
  key1004978: `取消部分商品时，取消总数量不能大于等于订单总数量！`,
  key1004979: `确认取消订单`,
  key1004980: `订单轨迹`,
  key1004981: `揽收中，正送往ymsWarehouseName仓`,
  key1004982: ` 订单轨迹`,
  key1004983: `订单已确认，待商家拣货`,
  key1004984: `订单已拣货完成，待商家复核`,
  key1004985: `订单已复核完成，待商家打包`,
  key1004986: `快递运输中，正送往ymsWarehouseName,快递单号：expressageNo`,
  key1004987: `ymsWarehouseName已收货，待仓库处理`,
  key1004988: `ymsWarehouseName配货中`,
  key1004989: `货品拣货完成，待仓库包装`,
  key1004990: `货品包装完成，待仓库装箱`,
  key1004991: `仓库已发货，已通知shippingMethodName物流取件`,
  key1004992: `订单已出库，需要尾程物流轨迹，请自行到物流官方网站或第三方平台查询`,
  key1004993: `订单已取消`,
  key1004994: `当前配送至：`,
  key1004995: `请输入省/州`,
  key1004996: `请输入城市`,
  key1004997: `请输入区县`,
  key1004998: `详细地址：`,
  key1004999: `请输入详细地址信息，如：道路、门牌号、小区、楼栋号、单元等信息`,
  key1005000: `请输入邮政编码`,
  key1005001: `收货人姓名：`,
  key1005002: `长度不超过25个字符`,
  key1005003: `请输入固定电话号码`,
  key1005004: `邮箱地址：`,
  key1005005: `设置为默认收货地址`,
  key1005006: `省/州不能为空`,
  key1005007: `收货人姓名不能为空`,
  key1005008: `添加成功！`,
  key1005009: `取消原因：`,
  key1005010: `取消类型：`,
  key1005011: `请选择平台订单取消原因，此原因买家可见。`,
  key1005012: `取消平台订单时，系统将自动调用平台接口取消订单,并同时给买家退款!`,
  key1005013: `已装箱订单取消需收取手续费，取消后本单将收取`,
  key1005014: `费用`,
  key1005015: `请选择取消类型`,
  key1005016: `请选择取消原因`,
  key1005017: `取消平台订单`,
  key1005018: `平台订单交易号`,
  key1005019: `取消部分商品时，取消商品不能全部勾选！`,
  key1005020: `请先勾选需要取消的数据！`,
  key1005021: `当前平台订单中暂无商品`,
  key1005022: `买家提供地址存在问题`,
  key1005023: `应买家要求取消订单`,
  key1005024: `买家未付款`,
  key1005025: `商品缺货或存在其他履约问题`,
  key1005026: `买家不再想要该商品`,
  key1005027: `买家买错商品`,
  key1005028: `买卖双方对此商品存在争议`,
  key1005029: `商品缺货`,
  key1005030: `仅剩次品`,
  key1005031: `仲裁取消`,
  key1005032: `其他原因`,
  key1005033: `买家未领取订单`,
  key1005034: `无法送达该地区`,
  key1005035: `订单被运输途中丢失`,
  key1005036: `客户要求取消`,
  key1005037: `定价错误`,
  key1005038: `欺诈交易，取消订单`,
  key1005039: `买家地址不可用`,
  key1005040: `无法送达`,
  key1005041: `物品已损坏`,
  key1005042: `物品退回发件人`,
  key1005043: `匹配仓库`,
  key1005044: `仓库选择：`,
  key1005045: `由于此订单由阿里国际多商家履约，已自动拆分为不同订单，需按订单进行支付。`,
  key1005046: `付款状态`,
  key1005047: `去付款`,
  key1005048: `更新状态`,
  key1005049: `付款确认`,
  key1005050: `是否付款成功？`,
  key1005051: `状态未更新提示`,
  key1005052: `获取到阿里国际订单信息状态仍为未付款，请稍后重试或手动标记为已成功。`,
  key1005053: `手动标记`,
  key1005054: `更换订单商品`,
  key1005055: `确认更换`,
  key1005056: `请选择需更换的商品及数量`,
  key1005057: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单！`,
  key1005058: `需更换数量`,
  key1005059: `需小于订单数量`,
  key1005060: `原订单内SKU信息`,
  key1005061: `替换SKU信息`,
  key1005062: `选择商品`,
  key1005063: `替换商品为当前商品`,
  key1005064: `更换商品数量需小于订单数量`,
  key1005065: `未选择更换订单商品`,
  key1005066: `未输入选择商品的替换数量`,
  key1005067: `请选择替换商品`,
  key1005068: `提交中`,
  key1005069: `修改收货人信息`,
  key1005070: `买家姓名：`,
  key1005071: `地址1：`,
  key1005072: `地址2：`,
  key1005073: `州/省：`,
  key1005074: `电话号码：`,
  key1005075: `当前所选目的国家需要录入IOSS相关信息`,
  key1005076: `请注意，修改地址信息后此订单将作废并生成新的订单!`,
  key1005077: `校验中...`,
  key1005078: `地址变更提示`,
  key1005079: `当前物流方式不支持此地区，更换地址后物流方式将被置空，请在待处理状态中重新匹配物流。请确认是否变更地址?`,
  key1005080: `确定变更`,
  key1005081: `修改中...`,
  key1005082: `订单详情`,
  key1005083: `计费重量(`,
  key1005084: `已处理`,
  key1005085: `预计 `,
  key1005086: ` 到货`,
  key1005087: `此时间为供应商仓预计到货时间，距尾程包裹发出时间仍需2~3天。`,
  key1005088: `出库单编号`,
  key1005089: `收货人名称`,
  key1005090: `买家识别号`,
  key1005091: `详细地址1`,
  key1005092: `详细地址2`,
  key1005093: `邮寄方式`,
  key1005094: `物流商称重（g）`,
  key1005095: `物流商运费`,
  key1005096: `待物流揽收`,
  key1005097: `供应商备货中`,
  key1005098: `物流已揽收`,
  key1005099: `平台订单自动创建`,
  key1005100: `取消数量不能为空`,
  key1005101: `由于价格调整待供应商接单暂无法付款`,
  key1005102: `商品已停售`,
  key1005103: `部分取消成功！`,
  key1005104: `第三方订单：`,
  key1005105: `请输入第三方订单,支持多个按行分隔`,
  key1005106: `订单备注：`,
  key1005107: `付款状态：`,
  key1005108: `添加订单`,
  key1005109: `批量导入订单`,
  key1005110: `批量付款`,
  key1005111: `订单商品信息`,
  key1005112: `订单号,支持多个按行分隔`,
  key1005113: `订单编号：`,
  key1005114: `第三方单号：`,
  key1005115: `需通过快递发至揽收点的包裹将加收国内快递费。具体费用可在自有物流模块中点击对应渠道查看。`,
  key1005116: `订单备注`,
  key1005117: `目的地及买家信息`,
  key1005118: `目的地：`,
  key1005119: `买家ID：`,
  key1005120: `商品费：`,
  key1005121: `清关费用：`,
  key1005122: `部分缺货，商品已停售`,
  key1005123: `取消订单确认`,
  key1005124: `是否确认取消该订单，取消后的订单将会移入“已取消”列表，订单信息无法直接恢复，请谨慎操作!`,
  key1005125: `全部 `,
  key1005126: `下发物流失败`,
  key1005127: `请输入第三方单号,支持多个按行分隔`,
  key1005128: `请输入运单号,支持多个按行分隔`,
  key1005129: `待处理`,
  key1005130: `账户完整信息`,
  key1005131: `账户补充信息：`,
  key1005132: `所属平台：`,
  key1005133: `分销订单状态：`,
  key1005134: `所属店铺：`,
  key1005135: `是否有退款：`,
  key1005136: `平台订单异常状态：`,
  key1005137: `分销订单异常状态：`,
  key1005138: `执行发货状态：`,
  key1005139: `导出平台订单`,
  key1005140: `批量标记非`,
  key1005141: `执行发货`,
  key1005142: `批量接单`,
  key1005143: `标发货`,
  key1005144: `标发货设置`,
  key1005145: `非`,
  key1005146: `执行发货后，`,
  key1005147: `将自动流转Temu订单状态，勾选的订单将作为一个物流包裹发出。`,
  key1005148: `正在确认通过`,
  key1005149: `履约速卖通全托管JIT订单，由于JIT订单限制较多，若出现平台处罚，`,
  key1005150: `将按照售后条款进行处理，无额外赔付，请确认是否接单？`,
  key1005151: ` 平台订单信息`,
  key1005152: `PO单号：`,
  key1005153: `平台订单商品信息`,
  key1005154: `是否标发货：`,
  key1005155: `下单数量：`,
  key1005156: `买家备注：`,
  key1005157: `平台订单异常`,
  key1005158: `分销订单信息`,
  key1005159: `阿里国际站订单`,
  key1005160: `发货物流：`,
  key1005161: `分销订单商品信息`,
  key1005162: `分销订单状态`,
  key1005163: `分销订单金额`,
  key1005164: `分销订单异常`,
  key1005165: `缺货，商品已停售`,
  key1005166: `匹配商品`,
  key1005167: `处理订单`,
  key1005168: `确认等待`,
  key1005169: `接单`,
  key1005170: `修改物流`,
  key1005171: `集货中`,
  key1005172: `待执行发货`,
  key1005173: `已执行发货`,
  key1005174: `已标发货`,
  key1005175: `平台订单已取消`,
  key1005176: `待匹配商品`,
  key1005177: `匹配物流失败`,
  key1005178: `PO单号`,
  key1005179: `所属平台`,
  key1005180: `店铺名称`,
  key1005181: `平台订单标发货状态`,
  key1005182: `订单原始SKU*数量`,
  key1005183: `平台订单是否有退款`,
  key1005184: `平台订单异常状态`,
  key1005185: `买家ID`,
  key1005186: `买家姓名`,
  key1005187: `买家备注`,
  key1005188: `分销订单号`,
  key1005189: `分销订单SKU信息`,
  key1005190: `分销订单创建时间`,
  key1005191: `分销订单发货时间`,
  key1005192: `分销订单发货时效`,
  key1005193: `待接单`,
  key1005194: `待匹配物流`,
  key1005195: `标发货失败`,
  key1005196: `待入录IOSS`,
  key1005197: `待匹配仓库`,
  key1005198: `客户更换地址`,
  key1005199: `客户标记取消`,
  key1005200: `可输入平台订单号、PO单号、分销订单号、包裹号、买家ID、 买家姓名、SKU、平台产品编码`,
  key1005201: `可输入平台订单号、分销订单号、包裹号、买家ID、 买家姓名、SKU、平台产品编码`,
  key1005202: `请输入平台订单号，支持多个逗号或换行分隔`,
  key1005203: `请输入PO单号，支持多个逗号或换行分隔`,
  key1005204: `请先勾选要标发货的数据！`,
  key1005205: `标记发货中，请稍后刷新页面查看。`,
  key1005206: `请先勾选要下单的数据！`,
  key1005207: `部分下单异常`,
  key1005208: `不同店铺的订单不可同时执行发货！`,
  key1005209: `仅待执行发货的订单可执行发货，请重新选择！`,
  key1005210: `执行发货成功!`,
  key1005211: `手工订单和平台订单不可同时执行发货，请分批操作！`,
  key1005212: `样品订单与发货订单不可同时执行发货，请分批操作！`,
  key1005213: `请先勾选要执行发货的数据！`,
  key1005214: `确认等待订单`,
  key1005215: `正在操作确认等待订单，若订单未按时间到达，仍会进行二次提醒，请确认是否等待订单到货？`,
  key1005216: `手动标记发货`,
  key1005217: `系统向平台标记发货失败，请前往平台后台进行标记发货，在平台后台标记发货后可在此处点击确认已标记发货按钮将此订单异常状态消除。`,
  key1005218: `确认已标记发货`,
  key1005219: `只有待接单的订单才能进行接单！`,
  key1005220: `请先勾选需要接单的数据！`,
  key1005221: `确认接单`,
  key1005222: `接单成功！`,
  key1005223: `部分接单异常`,
  key1005224: `样品订单`,
  key1005225: `货品订单`,
  key1005226: `常规订单`,
  key1005227: `平台订单已取消，请自行前往阿里国际中取消订单，取消后请手动标记已处理。`,
  key1005228: `可搜索商品名称、`,
  key1005229: `关键词搜索：`,
  key1005230: `导出备货单`,
  key1005231: `备货单信息`,
  key1005232: `采购状态：`,
  key1005233: `备货仓：`,
  key1005234: `采购商品信息`,
  key1005235: `货品总值：`,
  key1005236: `时间信息`,
  key1005237: `取消时间：`,
  key1005238: `部分停售`,
  key1005239: `取消交易`,
  key1005240: `缺货-补货中`,
  key1005241: `缺货-已停售`,
  key1005242: `采购状态`,
  key1005243: `备货仓`,
  key1005244: `已取消金额`,
  key1005245: `已退款金额`,
  key1005246: `SKU单价`,
  key1005247: `SKU实付单价`,
  key1005248: `取消交易提示`,
  key1005249: `正在操作取消备货单，请确认是否取消？`,
  key1005250: `取消交易成功！`,
  key1005251: `账户收款币种对应金额：`,
  key1005252: `查看完整消息`,
  key1005253: `提交后需运营人员审核确认支付信息的有效性，确认后订单才会生效，请准确的输入支付信息。`,
  key1005254: `上传文件格式有误，限制上传图片格式为JPG、JPEG、PNG`,
  key1005255: `图片大小最大不超过5MB`,
  key1005256: `该渠道账户已被停用，请联系运营人员或选择其他支付渠道账户`,
  key1005257: `支付渠道不能为空`,
  key1005258: `运营收款账户不能为空`,
  key1005259: `编辑支付凭证`,
  key1005260: `查看支付凭证`,
  key1005261: `修改支付凭证`,
  key1005262: `支付流水图片不能为空！`,
  key1005263: `条码缺失提示`,
  key1005264: `以下商品无`,
  key1005265: `信息，请在`,
  key1005266: `我的商品`,
  key1005267: `中上传后重试。`,
  key1005268: `商品条码`,
  key1005269: `样品条码`,
  key1005270: `请输入订单备注`,
  key1005271: `1、请确保文件内容中订单商品信息以及文件表头字段对应关系的准确无误，若文件中存在无`,
  key1005272: `效内容，会导致文件上传失败;`,
  key1005273: `2、当前仅支持xls、xlsx格式文件`,
  key1005274: `3、文件上传成功后，解析对应的订单内容会存入“YMS订单-待付款”状态列表，需用户二次`,
  key1005275: `确认下单后才会创建YMS订单。`,
  key1005276: `来源备注只能输入英文！`,
  key1005277: `文件格式有误,仅支持 xls、xlsx格式文件`,
  key1005278: `应付总额`,
  key1005279: `退回原因`,
  key1005280: `取消付款`,
  key1005281: `取消确认`,
  key1005282: `是否确认取消该付款信息？`,
  key1005283: `到仓详情`,
  key1005284: `匹配SKU成功`,
  key1005285: `匹配SKU成功(人工匹配)`,
  key1005286: `不匹配`,
  key1005287: `阿里国际单号：`,
  key1005288: `分销单号：`,
  key1005289: `展开`,
  key1005290: `原始SKU：`,
  key1005291: `物流服务：`,
  key1005292: `价格及数量`,
  key1005293: `已解码SKU：`,
  key1005294: `匹配SKU失败`,
  key1005295: `手动匹配`,
  key1005296: `重新匹配`,
  key1005297: `SKU已停售，请取消或更换商品`,
  key1005298: `商品为空，无法关联`,
  key1005299: `总费用=商品+处理费+物流费`,
  key1005300: `处理费=3+（商品数量-1）*0.5`,
  key1005301: `总费用(`,
  key1005302: `商品费(`,
  key1005303: `处理费(`,
  key1005304: `物流费(`,
  key1005305: `国内快递费(`,
  key1005306: `清关费用(`,
  key1005307: `阿里国际订单信息`,
  key1005308: `收货地址信息`,
  key1005309: `平台订单地址信息`,
  key1005310: `暂无更多数据...`,
  key1005311: `发货仓库`,
  key1005312: `国内快递单号`,
  key1005313: `下载订单`,
  key1005314: `已标记发货`,
  key1005315: `待标记发货`,
  key1005316: `订单已整单取消`,
  key1005317: `订单已部分取消`,
  key1005318: `阿里国际商家已发货`,
  key1005319: `，预计到货时间：`,
  key1005320: `，已停售`,
  key1005321: `创建日期：`,
  key1005322: `新建模板`,
  key1005323: `删除模板确认`,
  key1005324: `是否确认删除模板，删除后信息不可恢复，请谨慎操作！`,
  key1005325: `订单多SKU导入方式：`,
  key1005326: `每行一个`,
  key1005327: `同一行多列`,
  key1005328: `模板字段映射`,
  key1005329: `多商品时，第二个商品SKU列标题以第一个商品SKU列标题按数字递增；`,
  key1005330: `如：商品SKU原列标题名称为“商品SKU”，如果存在多个商品，那么第一`,
  key1005331: `个商品SKU列标题名称则设为“商品SKU1”，`,
  key1005332: `第二个商品SKU列标题名称`,
  key1005333: `设为“商品SKU2”，以此递增；`,
  key1005334: `注:输入框内仅需输入第一个商品SKU列标题即可，“数量`,
  key1005335: `内容同理;`,
  key1005336: `文件中出现相同订单号时：`,
  key1005337: `认为是包含多SKU的订单`,
  key1005338: `报错`,
  key1005339: `文件中订单号在系统相同备注中已存在时：`,
  key1005340: `忽略并导入其他订单`,
  key1005341: `第三方订单号`,
  key1005342: `配送至（国家）`,
  key1005343: `详细地址`,
  key1005344: `收货人姓名`,
  key1005345: `邮箱地址`,
  key1005346: `IOSS号`,
  key1005347: `YMS订单信息`,
  key1005348: `内容性质`,
  key1005349: `处理方式`,
  key1005350: `映射`,
  key1005351: `Excel文件列标题`,
  key1005352: `映射的Excel文件列标题不能为空！`,
  key1005353: `备货仓库`,
  key1005354: `YMS仓库：`,
  key1005355: `下单后自动付款`,
  key1005356: `是否确定提交该备货单？`,
  key1005357: `当前您已勾选”下单后自动付款`,
  key1005358: `，当您账户余额充足时，会自动使用账户余额进行支`,
  key1005359: `付，如果账户余额不足，则会为您创建“待付款”状态的备货单，需要您进行支付后，平台才会进行备货单履约`,
  key1005360: `请选择备货仓库!`,
  key1005361: `账户余额不足，以为您创建"待付款”状态备货单`,
  key1005362: `账户余额充足，支付成功`,
  key1005363: `配置方式：`,
  key1005364: `全局统一配置`,
  key1005365: `按平台配置`,
  key1005366: `可选择以下任一YMS订单节点作为标发货节点：`,
  key1005367: `物流：`,
  key1005368: `此信息需与买手确认，未填写时将不在包裹上额外粘贴收货信息`,
  key1005369: `收货人：`,
  key1005370: `请填写收货人`,
  key1005371: `收货组：`,
  key1005372: `请填写收货组`,
  key1005373: `运费将附加在执行发货的首个订单中`,
  key1005374: `第一步：选择物流`,
  key1005375: `第二步：填写收件信息`,
  key1005376: `物流不能为空`,
  key1005377: `请填写物流单号`,
  key1005378: `第一步：上传打包标签`,
  key1005379: `第二步：填写物流单号`,
  key1005380: `包裹编号`,
  key1005381: `包裹重量（g）`,
  key1005382: `包裹货品总数`,
  key1005383: `上传打包标签`,
  key1005384: `无需上传标签`,
  key1005385: `请先上传打包标签!`,
  key1005386: `物流单号不能为空!`,
  key1005387: `派送范围：`,
  key1005388: `帐号名称不能为空`,
  key1005389: `账户授权`,
  key1005390: `帐号名称：`,
  key1005391: `关联店铺`,
  key1005392: `平台店铺：`,
  key1005393: `未启用`,
  key1005394: `正在启用`,
  key1005395: `账户，请确认后操作。`,
  key1005396: `停用该`,
  key1005397: `账户后，相关联的店铺将无法下发物流，请确认是否停用账户？`,
  key1005398: `物流渠道代码`,
  key1005399: `物流类型`,
  key1005400: `停用物流提示`,
  key1005401: `停用该物流后，将无法适用该物流进行下发，请确认是否停用？`,
  key1005402: `启用物流提示`,
  key1005403: `正在启用物流，请确认后操作。`,
  key1005404: `启用渠道提示`,
  key1005405: `正在启用物流渠道，请确认后操作。`,
  key1005406: `启用账户提示`,
  key1005407: `停用渠道提示`,
  key1005408: `停用该物流渠道后，将无法适用该物流渠道进行下发，请确认是否停用？`,
  key1005409: `停用账户提示`,
  key1005410: `帐号`,
  key1005411: `请您按照以下要求上传营业执照，如您没有按要求上传，您的申请可能会被拒绝`,
  key1005412: `请确保营业执照图片字体清晰，边角完整，无任何无关水印，不可故意遮盖二维码或信息。若拍摄模糊建议上传扫描件。`,
  key1005413: `请您先自行在国家企业信用信息公示系统查询您填写的统一社会信用代码/注册号，确保能查询到您的主体信息，且登记状态为“存续（在营、开业、在册）”。`,
  key1005414: `若公司名称或法定代表人等信息发生过变更，请上传最新核准日期的营业执照图片。`,
  key1005415: `示例图`,
  key1005416: `请您按照以下要求上传，不符合以下规范将会被审核驳回`,
  key1005417: `请确保您所上传法定代表人身份证上的姓名与营业执照上的法定代表人姓名一致。`,
  key1005418: `请确认法定代表人身份证字体清晰无反光、边角完整，无任何无关水印及故意遮盖。`,
  key1005419: `YMS平台隐私政策`,
  key1005420: `请填写营业执照上公司全称`,
  key1005421: `请选择[jpg、jpeg、png]`,
  key1005422: `公司名称不能为空`,
  key1005423: `法人身份证国徽面`,
  key1005424: `法人身份证人像面`,
  key1005425: `请先上传法人身份证国徽面`,
  key1005426: `请先上传法人身份证人像面`,
  key1005427: `法定代表人身份证照规范`,
  key1005428: `营业执照规范`,
  key1005429: `图形验证码：`,
  key1005430: `请输入短信验证码`,
  key1005431: `设置密码：`,
  key1005432: `8~26位（建议使用两种或两种以上字符组合）`,
  key1005433: `确认密码：`,
  key1005434: `请再次输入密码`,
  key1005435: `此手机号已注册分销商，继续则视为以此账户入驻供应商`,
  key1005436: `我已阅读并同意`,
  key1005437: `密码长度必须为8~26位`,
  key1005438: `请先填写手机号和图形验证码！`,
  key1005439: `账号注册成功`,
  key1005440: `你的账号已注册成功，请继续完善入驻信息，完成入驻！`,
  key1005441: `请先勾选我已阅读并同意`,
  key1005442: `设置密码不能为空`,
  key1005443: `设置密码与确认密码不一致`,
  key1005444: `填写账号信息`,
  key1005445: `填写企业信息`,
  key1005446: `入驻资料审核`,
  key1005447: `注册成功`,
  key1005448: `已有账号？`,
  key1005449: `立即登录`,
  key1005450: `提交成功`,
  key1005451: `你的申请材料已提交，通常我们会在3个工作日`,
  key1005452: `内完成审核，请耐心等待，如你有任何疑问，请`,
  key1005453: `联系在线客服！`,
  key1005454: `入驻信息审核中，请耐心等待~`,
  key1005455: `审核通过后，即可正常使用供应商后台，如你有`,
  key1005456: `任何疑问，请联系在线客服！`,
  key1005457: `你的申请，审核未通过`,
  key1005458: `请核对并修改以下信息后，再重新提交。`,
  key1005459: `重新修改`,
  key1005460: `一切就绪`,
  key1005461: `你的申请，已经审核通过！`,
  key1005462: `去发布商品`,
  key1005463: `正在退出`,
  key1005464: `账号登录`,
  key1005465: `手机号码/邮箱/通途账号`,
  key1005466: `密码`,
  key1005467: `验证码`,
  key1005468: `短信登录`,
  key1005469: `短信验证码`,
  key1005470: `立即授权`,
  key1005471: `找回密码`,
  key1005472: `手机号码/邮箱/通途账号不能为空`,
  key1005473: `短信验证码不能为空`,
  key1005474: `手机号/邮箱格式不正确`,
  key1005475: `手机号注册`,
  key1005476: `邮箱注册`,
  key1005477: `邮箱（可不填）`,
  key1005478: `通途登录`,
  key1005479: `欢迎注册`,
  key1005480: `注册`,
  key1005481: `已有账号，请登录`,
  key1005482: `通途账号登录`,
  key1005483: `如果您已有通途账号，可直接使用通途账号登录。`,
  key1005484: `登录将为您开通YMS商城分销权限。`,
  key1005485: `注册成功！`,
  key1005486: `设置密码`,
  key1005487: `请输入邮箱 / 已验证手机号`,
  key1005488: `去登录`,
  key1005489: `手机号/邮箱不能为空`,
  key1005490: `确认密码不能为空`,
  key1005491: `提 交`,
  key1005492: `首页`,
  key1005493: `供应商入驻指南`,
  key1005494: `立即入驻`,
  key1005495: `欢迎加入YMS分销平台`,
  key1005496: `YMS是中国领先的跨境服装一站式分销平台，创始团队成员深耕跨境行业十多年，有着丰富且优质的跨境电商供应链资源，`,
  key1005497: `可提供集货、仓储、物流、分销等一体化服务，可帮助源头工厂、品牌代理商、工贸一体企业等类型供应商拓展海外销路，`,
  key1005498: `秉承着“合作共赢、共同发展”的经营理念，现诚邀优质跨境电商货源供应商加入，助力您的海外市场。`,
  key1005499: `我们的优势`,
  key1005500: `招商品类`,
  key1005501: `供应商资质要求`,
  key1005502: `企业资料`,
  key1005503: `入驻材料`,
  key1005504: `材料要求`,
  key1005505: `三证合一的企业营业执照；`,
  key1005506: `确保申请入驻的企业不在《经营异常名录》中，且所销售的`,
  key1005507: `商品在其经营范围内；`,
  key1005508: `距离有效期截止时间应大于3个月。`,
  key1005509: `身份证`,
  key1005510: `法人身份证；`,
  key1005511: `距离有效期截止时间应大于1个月。`,
  key1005512: `支持银行卡或支付宝。`,
  key1005513: `产品要求`,
  key1005514: `产品资质`,
  key1005515: `商品合法合规`,
  key1005516: `承诺提供的商品合法合规，符合销售目的地的法律法规、质`,
  key1005517: `量标准及YMS上架规范；`,
  key1005518: `专利产品，需提供销售区域专利证书；`,
  key1005519: `有品牌的供应商，需提供品牌授权书；`,
  key1005520: `具备稳定的供货渠道，愿意遵循平台规则。`,
  key1005521: `真诚长远合作`,
  key1005522: `保证商品价格全网最低；`,
  key1005523: `认同YMS发展理念，抱有真诚长远的合作态度。`,
  key1005524: `订单履约`,
  key1005525: `保证商品在发货期内到达YMS仓库。`,
  key1005526: `入驻流程`,
  key1005527: `即刻入驻YMS供应商，即享快速代发新模式`,
  key1005528: `YMS诚邀你加入我们，成为我们的优质伙伴，赶快点击入驻！`,
  key1005529: `专`,
  key1005530: `专注跨境电商`,
  key1005531: `10余年专业跨境电商服装产品团队`,
  key1005532: `广`,
  key1005533: `覆盖主流电商平台`,
  key1005534: `分销店铺覆盖Amazon、eBay等主流电商平台`,
  key1005535: `集`,
  key1005536: `订单集中处理`,
  key1005537: `供应商只需发货至YMS集运仓库`,
  key1005538: `免`,
  key1005539: `免仓储服务费`,
  key1005540: `入驻即获得仓储免费服务`,
  key1005541: `快速代发`,
  key1005542: `一站式代发系统托管，节省仓储精力，控制人工成本`,
  key1005543: `女 装`,
  key1005544: `男 装`,
  key1005545: `女 童`,
  key1005546: `男 童`,
  key1005547: `婴 童`,
  key1005548: `产品调研`,
  key1005549: `YMS协助进行产品调研，确定产品符合市场需求，且具备价格竞争优势。`,
  key1005550: `注册账号`,
  key1005551: `按要求填写账号信息，注册企业账号。`,
  key1005552: `填写入驻资料`,
  key1005553: `提交营业执照、法定代表人身份证，填写店铺相关信息。`,
  key1005554: `入驻资料提交后，YMS将在3个工作日内完成审核。`,
  key1005555: `入驻完成`,
  key1005556: `审核通过后，供应商登录后台，发布商品开始销售。`,
  key1005557: `请描述你的问题，如：物流`,
  key1005558: `视频教程`,
  key1005559: `云卖供应链`,
  key1005560: `功能介绍`,
  key1005561: `云卖供应链功能介绍`,
  key1005562: `注册云卖供应链`,
  key1005563: `店铺授权`,
  key1005564: `自助服务`,
  key1005565: `定期更换密码有助于保障账户安全`,
  key1005566: `修改手机号码`,
  key1005567: `手机号涉及登录、认证等关键业务，保障账户安全`,
  key1005568: `邮箱涉及登录、消息通知等关键业务，并保障账户安全`,
  key1005569: `搜索“`,
  key1005570: `”的相关结果，共`,
  key1005571: `无匹配搜索结果`,
  key1005572: `帮助首页`,
  key1005573: `登录`,
  key1005574: `登录查看价格`,
  key1005575: `每日上新`,
  key1005576: `潮流女装`,
  key1005577: `时尚男装`,
  key1005578: `精品童装`,
  key1005579: `HOT 热销商品`,
  key1005580: `好货推荐`,
  key1005581: `YMS商城（www.yms.com）是深圳云卖供应链管理有限公司建立的一个专注跨境电商服装品类的供应链服务平台。平台集成源头工厂服装商城、通途Listing刊登平台、通途ERP平台和通途物流平台，为跨境电商卖家与源头工厂打通了一条高效的订单履约链路，国外销售终端的顾客订单直接由源头工厂一件代发。`,
  key1005582: `只需3步，让跨境生意更简单`,
  key1005583: `你只需专注于运营，其他的，我们来帮你解决`,
  key1005584: `选品`,
  key1005585: `免费注册账号，在YMS商城订阅商品`,
  key1005586: `刊登`,
  key1005587: `直连通途Listing刊登系统，一键快速上架到eBay、Amazon等多平台店铺`,
  key1005588: `店铺出单后，即可在YMS商城下单，支持一件代发`,
  key1005589: `YMS商城跨境电商，专注于服装分销`,
  key1005590: `现在你可以把成千上万的服装商品从YMS商城刊登到海外各大平台，从而赢得更多的销售机会`,
  key1005591: `海量商品选购`,
  key1005592: `40000+服装SKU，1000+每月上新`,
  key1005593: `0库存风险`,
  key1005594: `无需国货备货，0库存风险`,
  key1005595: `无资金压力`,
  key1005596: `先出单后付款，提升资金周转率`,
  key1005597: `你只需专注于商品销售，就算只卖出一件商品，我们也会贴心地帮你发货`,
  key1005598: `快速上架到多平台`,
  key1005599: `自有品牌标识，让客户更信赖`,
  key1005600: `可以通过包裹、包装标识自有品牌，让客户更信赖`,
  key1005601: `订单自动跟踪`,
  key1005602: `销售订单系统自动跟踪，无需复制和粘贴客户订单详细信息`,
  key1005603: `发货快，出货时效有保障`,
  key1005604: `开放API接口`,
  key1005605: `YMS商城提供开放接口，可实现商品管理、订单管理、仓储管理等诸多功能`,
  key1005606: `分销商业务`,
  key1005607: `1）服务内容：为跨境电商服装卖家的eBay、Walmart、速卖通、Wish、Shopify等平台订单提供源头工厂现货一件代发服务。`,
  key1005608: `2）服务流程：`,
  key1005609: `注册成为YMS商城分销商`,
  key1005610: `方案1：通过通途ERP账户（http:`,
  key1005611: `方案2：直接手机号注册开通。`,
  key1005612: `刊登销售`,
  key1005613: `在YMS商城选品收藏商品，通途Listing系统快速刊登到eBay、Walmart、速卖通等平台销售。`,
  key1005614: `订单发货`,
  key1005615: `通途ERP抓取平台订单，匹配分销商设定的国际物流方式，下发至YMS商城。订单即可履约一件代发给国外顾客。`,
  key1005616: `供应商业务`,
  key1005617: `1）服务内容：跨境电商服装品类的工厂入驻YMS商城开设直营店铺。`,
  key1005618: `服装工厂联系供应商招商经理注册进驻。`,
  key1005619: `经营店铺，规范上架商品，管理库存，操作订单发货。`,
  key1005620: `中心仓仓储业务`,
  key1005621: `1）服务内容：为YMS商城供应商提供仓储服务。`,
  key1005622: `YMS商城供应商开通云仓。`,
  key1005623: `中心仓备货和发货，供应商创建备货计划，贴YMS商城商品条码入仓，中心仓自动匹配订单发货。`,
  key1005624: `平台业务介绍`,
  key1005625: `联系地址：深圳市南山区学苑大道南山智园C1栋6楼`,
  key1005626: `联系电话：400-0600-405`,
  key1005627: `分销商在线服务QQ：2853913746`,
  key1005628: `达到上一销量后可查看`,
  key1005629: `地址：`,
  key1005630: `当前位置：`,
  key1005631: `上架时间：`,
  key1005632: `优惠`,
  key1005633: `30天内累计分销`,
  key1005634: `收藏`,
  key1005635: `一键刊登支持以下平台`,
  key1005636: `优质供应商`,
  key1005637: `价格：登录后可见`,
  key1005638: `商品详细信息`,
  key1005639: `物品参数`,
  key1005640: `供应商建议`,
  key1005641: `暂无权限查看，请联系客服开通权限。`,
  key1005642: `关注店铺`,
  key1005643: `包装尺寸(cm)`,
  key1005644: `美西仓库存`,
  key1005645: `取消收藏成功`,
  key1005646: `★ 已关注`,
  key1005647: `入门视频`,
  key1005648: `新用户入门操作`,
  key1005649: `新用户注册`,
  key1005650: `授权店铺`,
  key1005651: `一键刊登流程`,
  key1005652: `一键刊登成功前提：成功授权店铺`,
  key1005653: `设置店铺刊登模板`,
  key1005654: `一键刊登规则`,
  key1005655: `第一步：刊登模板设置`,
  key1005656: `刊登模板设置`,
  key1005657: `第二步：刊登规则设置`,
  key1005658: `刊登规则设置`,
  key1005659: `第三步：一键刊登`,
  key1005660: `公司档案`,
  key1005661: `图片类型`,
  key1005662: `一键刊登支持平台：`,
  key1005663: `是否收藏：`,
  key1005664: `联系人姓名不能为空`,
  key1005665: `结算币种：`,
  key1005666: `省/市/区：`,
  key1005667: `仓库配置`,
  key1005668: `开启仓储管理`,
  key1005669: `发货地址信息`,
  key1005670: `+添加发货地址`,
  key1005671: `您更改提交的商户工商信息，平台正在审核中......`,
  key1005672: `您更改提交的商户工商信息已被平台驳回！请查看驳回原因后重新提交审核。`,
  key1005673: `查看驳回原因>>>`,
  key1005674: `您更改提交的商户工商信息平台已审核通过！`,
  key1005675: `未开启仓储管理`,
  key1005676: `工商信息已经在审核中，暂不支持修改！`,
  key1005677: `确认后不保存已编辑信息`,
  key1005678: `是否关闭仓储功能？`,
  key1005679: `当前仓储功能关闭后，暂不支持重新开启，是否确认关闭？`,
  key1005680: `已开启仓储管理`,
  key1005681: `请输入开户人姓名`,
  key1005682: `请输入开户行`,
  key1005683: `请输入银行卡号`,
  key1005684: `请输入身份证号`,
  key1005685: `请输入支付宝登录号，支持邮箱和手机号格式`,
  key1005686: `请输入该支付宝账户的开户人姓名`,
  key1005687: `请输入该支付宝账户的开户人证件号`,
  key1005688: `绑定`,
  key1005689: `绑定收款账户`,
  key1005690: `（推荐）`,
  key1005691: `更换成功！`,
  key1005692: `请输入支付宝登录号`,
  key1005693: `(推荐)`,
  key1005694: `变更日志`,
  key1005695: `绑定账户`,
  key1005696: `更换账户`,
  key1005697: `更换绑定`,
  key1005698: `原记录`,
  key1005699: `变更后记录`,
  key1005700: `变更时间`,
  key1005701: `暂未添加`,
  key1005702: `添加收款账户`,
  key1005703: `暂未绑定`,
  key1005704: `更换收款账户`,
  key1005705: `修改失败`,
  key1005706: `操作失败`,
  key1005707: `角色`,
  key1005708: `审核中,审核后将展示补贴金额。`,
  key1005709: `符合`,
  key1005710: `不满足现有补贴规则、如有疑问请联系您的`,
  key1005711: `对接人员`,
  key1005712: `收货时间`,
  key1005713: `应发部分实到数量`,
  key1005714: `多发数量`,
  key1005715: `发往仓库`,
  key1005716: `追加货品`,
  key1005717: `作废提单`,
  key1005718: `作废提单提示`,
  key1005719: `提单作废后，提单内所有订单将流转至待重发状态，出库单将流转至待拣货状态，请确认是否作废提单?`,
  key1005720: `正在装箱`,
  key1005721: `三方提单号`,
  key1005722: `物流单号`,
  key1005723: `请输入提单编号,支持多个逗号或换行分隔`,
  key1005724: `作废成功!`,
  key1005725: `运营商操作`,
  key1005726: `修改备货数量`,
  key1005727: `修改后数量`,
  key1005728: `未输入时视为不修改`,
  key1005729: `请输入需修改的商品数量`,
  key1005730: `修改备货数量成功!`,
  key1005731: `备货单导入`,
  key1005732: `目的仓库：`,
  key1005733: `1、一次性上传过多备货信息容易读取超时失败，建议单批次上传不超过100条备货信息;`,
  key1005734: `2、文件内容SKU若无商品SKU匹配项，或SKU对应数量内容非正整数，则视为无效内容;`,
  key1005735: `3、仅支持xls、xlsx格式文件;`,
  key1005736: `4、若文件中存在无效内容，则会上传失败;`,
  key1005737: `出库单类型不能为空！`,
  key1005738: `目的仓库不能为空！`,
  key1005739: `发起备货`,
  key1005740: `批量发货`,
  key1005741: `已选订单`,
  key1005742: `筛选结果订单`,
  key1005743: `仅出库单类型为自有仓备货的备货单可打印`,
  key1005744: `条码！`,
  key1005745: `扫描SKU打印`,
  key1005746: `打印所选订单条码`,
  key1005747: `请先勾选需要标记发货的数据！`,
  key1005748: `当前筛选结果暂无数据，无法标记发货！`,
  key1005749: `当前订单中出库状态为待发货状态的订单，才可以执行发货！`,
  key1005750: `当前订单中备货状态为待发货状态的订单，才可以执行发货！`,
  key1005751: `发货台内当前无商品，无法打印配货清单。`,
  key1005752: `请先勾选需要打印的订单条码数据！`,
  key1005753: `当前筛选结果暂无数据！无法打印订单条码`,
  key1005754: `取消备货`,
  key1005755: `请确认是否取消备货？`,
  key1005756: `配货清单格式`,
  key1005757: `清单格式：`,
  key1005758: `纸质清单`,
  key1005759: `表格文件`,
  key1005760: `扫描换单`,
  key1005761: `请扫描/输入SKU`,
  key1005762: `请扫描/输入SKU：`,
  key1005763: `批量打印：`,
  key1005764: `关闭弹窗`,
  key1005765: `sku不能为空！`,
  key1005766: `打印数量不能为空！`,
  key1005767: `获取打印地址失败！`,
  key1005768: `商品加工状态：`,
  key1005769: `工厂名称：`,
  key1005770: `请输入工厂名称`,
  key1005771: `结算金额：`,
  key1005772: `商品加工状态`,
  key1005773: `工厂名称`,
  key1005774: `拒绝`,
  key1005775: `待客户付款`,
  key1005776: `请输入订单号,支持多个逗号或换行分隔`,
  key1005777: `请输入供应商SKU,支持多个逗号或换行分隔`,
  key1005778: `待加工`,
  key1005779: `加工中`,
  key1005780: `加工完成待发货`,
  key1005781: `自发货订单`,
  key1005782: `POD订单`,
  key1005783: `缺货订单需标记到货后再进行发货！`,
  key1005784: `当前订单中出库单为待发货状态的订单，才可以执行发货！`,
  key1005785: `当前筛选结果暂无数据，无法操作！`,
  key1005786: `请先勾选需要标记的数据！`,
  key1005787: `标记到货提示`,
  key1005788: `标记到货后订单将流转为待发货，请确认是否标记到货？`,
  key1005789: `仅可对缺货订单标记到货，请重新选择！`,
  key1005790: `仅可对非缺货订单标记缺货，请重新选择！`,
  key1005791: `接单确认`,
  key1005792: `拒绝接单确认`,
  key1005793: `正在操作接单，确认接单后将按照此订单金额进行结算，需等待客户付款，请确认是否接单？`,
  key1005794: `拒绝接单后，订单将可能被取消，请确认是否拒绝接单？`,
  key1005795: `拒绝接单`,
  key1005796: `接单成功`,
  key1005797: `拒绝接单成功`,
  key1005798: `发货提醒`,
  key1005799: `商品数量不能为空或为0`,
  key1005800: `数量必须是大于0的整数`,
  key1005801: `请先添加商品！`,
  key1005802: `扫描`,
  key1005803: `请扫描或输入扫描`,
  key1005804: `提货单号：`,
  key1005805: `请输入提货单号`,
  key1005806: `完成追加`,
  key1005807: `已扫描出库单`,
  key1005808: `编码信息`,
  key1005809: `装箱提示`,
  key1005810: `你扫描的出库单号，与当前已装箱的出库单目的仓库不同，请扫描同一仓库的出库单号装箱。`,
  key1005811: `请输入sku`,
  key1005812: `打印数量不能为空`,
  key1005813: `商品管理`,
  key1005814: `发布新商品`,
  key1005815: `销售趋势图`,
  key1005816: `热销商品排行榜`,
  key1005817: `统计本月（自然月）店铺已完成`,
  key1005818: `仓已发出`,
  key1005819: `的订单总金额。`,
  key1005820: `统计本月（自然月）已完成`,
  key1005821: `的订单总数。`,
  key1005822: `上月销售额`,
  key1005823: `本月订单量（单）`,
  key1005824: `上月订单量`,
  key1005825: `昨日订单量`,
  key1005826: `今日订单量`,
  key1005827: `在售SPU总数`,
  key1005828: `统计在售SPU总数量，不包含、停售、暂存状态的SPU。`,
  key1005829: `在售SKU总数`,
  key1005830: `统计在售SKU总数量，不包含、停售、暂存状态的SKU。`,
  key1005831: `订阅商品数（SPU）`,
  key1005832: `统计分销商订阅商品的总数，同一个商品多个分销商订阅只计算1次。`,
  key1005833: `本月订阅数`,
  key1005834: `昨日订阅数`,
  key1005835: `今日订阅数`,
  key1005836: `统计分销商已上架到平台的SPU总数量，同一个SPU不同的Item只计算一次。`,
  key1005837: `统计分销商已发布刊登SPU的总数量，按Item数量统计。`,
  key1005838: `近30天发货时效（小时）`,
  key1005839: `统计近30天所有订单从待拣货到已出库（快递揽收）的平均耗时`,
  key1005840: `近7天发货时效`,
  key1005841: `近3天发货时效`,
  key1005842: `在售中`,
  key1005843: `已售罄`,
  key1005844: `已驳回`,
  key1005845: `近7天`,
  key1005846: `近30天`,
  key1005847: `销售额(CNY)： ¥ `,
  key1005848: `订单量(单)：`,
  key1005849: `中心仓`,
  key1005850: `库存明细日志`,
  key1005851: `账单`,
  key1005852: `提单`,
  key1005853: `上架/停售`,
  key1005854: `请输入中文标题`,
  key1005855: `审核状态：`,
  key1005856: `批量改价`,
  key1005857: `批量上架/停售`,
  key1005858: `导出商品`,
  key1005859: `获取1688商品`,
  key1005860: `采集1688商品`,
  key1005861: `打印条形码`,
  key1005862: `1.导入销售价后将覆盖原来价格，请谨慎导入;`,
  key1005863: `4.导入操作后，请稍后在“导入/导出任务-导入查看”中查看导入结果。`,
  key1005864: `1.按照SKU维度进行上架/停售，停售后分销商将无法继续下单;`,
  key1005865: `sku,支持多个按行分隔`,
  key1005866: `更新时间：`,
  key1005867: `请输入供应商sku,支持多个按行分隔`,
  key1005868: `修改价格`,
  key1005869: `导入商品`,
  key1005870: `中心仓名称`,
  key1005871: `中心仓分配数量`,
  key1005872: `中心仓可用数量`,
  key1005873: `自有仓分配数量`,
  key1005874: `自有仓可用数量`,
  key1005875: `供货价（元）`,
  key1005876: `，该商品正在被映射商品使用，不能删除`,
  key1005877: `，SKU不存在`,
  key1005878: `，该SKU有库存不允许删除`,
  key1005879: `，系统繁忙，请重新尝试`,
  key1005880: `选择分类：`,
  key1005881: `模板：`,
  key1005882: `第1步：下载模板`,
  key1005883: `第2步：上传资料`,
  key1005884: `导入数据发现相同SPU时：`,
  key1005885: `1.请按照模板中的导入说明文档进行商品信息上传。`,
  key1005886: `2.仅支持拓展名：.zip。`,
  key1005887: `3.当导入数据存在异常时，数据导入失败。`,
  key1005888: `5.暂无法对组装商品进行导入或覆盖。`,
  key1005889: `文件大小超出限制，最大为500M`,
  key1005890: `请先选择分类！`,
  key1005891: `下载模板成功！`,
  key1005892: `获取类型：`,
  key1005893: `选择店铺：`,
  key1005894: `商品链接：`,
  key1005895: `请输入1688商品链接，如:https://detail.1688.com/offer/673838787879.html支持多个按行分隔`,
  key1005896: `请选择1688商品创建时间`,
  key1005897: `获取`,
  key1005898: `将尽快补充映射。`,
  key1005899: `匹配映射失败，`,
  key1005900: `SKU重复`,
  key1005901: `时间范围不能为空`,
  key1005902: `店铺不能为空`,
  key1005903: `1688店铺未绑定`,
  key1005904: `暂未绑定1688店铺，请绑定后重试。`,
  key1005905: `获取商品失败`,
  key1005906: `获取商品部分失败`,
  key1005907: `复制失败SPU`,
  key1005908: `匹配映射失败，可点击`,
  key1005909: `获取商品成功`,
  key1005910: `查看商品`,
  key1005911: `获取商品成功，可在商品页面`,
  key1005912: `商品获取中，请稍等!`,
  key1005913: `商品链接不能为空!`,
  key1005914: `指定链接地址必须是https://或者http://开头的链接!`,
  key1005915: `1688类目：`,
  key1005916: `批量采集`,
  key1005917: `近90天成交：`,
  key1005918: `商品采集中，请稍等!`,
  key1005919: `品牌名称不能为空`,
  key1005920: `品牌名称：`,
  key1005921: `请输入品牌名称`,
  key1005922: `品牌描述：`,
  key1005923: `请输入品牌描述`,
  key1005924: `品牌状态：`,
  key1005925: `品牌网址：`,
  key1005926: `请输入品牌网址`,
  key1005927: `品牌LOGO：`,
  key1005928: `新增品牌`,
  key1005929: `单击或拖动此处上传文件`,
  key1005930: `按品牌状态`,
  key1005931: `按品牌名称`,
  key1005932: `品牌LOGO`,
  key1005933: `品牌名称`,
  key1005934: `品牌描述`,
  key1005935: `品牌网址`,
  key1005936: `品牌状态`,
  key1005937: `是否要删除当前品牌？`,
  key1005938: `编辑品牌`,
  key1005939: `价格不能为0或为空！`,
  key1005940: `等待收款`,
  key1005941: `商品原价`,
  key1005942: `货品金额（应收）：`,
  key1005943: `补贴金额（应收）：`,
  key1005944: `物流费用（应付）：`,
  key1005945: `请输入账单编号，支持多个逗号或换行分隔`,
  key1005946: `导出账单`,
  key1005947: `确认账单即表示已对账单内容、金额核对无误，请确认账单后操作，确认账单后`,
  key1005948: `后台将发起打款流程，资金将转入所绑定的收款账户。`,
  key1005949: `货品结算金额(CNY)`,
  key1005950: `收款账户未绑定`,
  key1005951: `当前账户未绑定收款账户，无法完成收款流程，请绑定后重试。`,
  key1005952: `去绑定`,
  key1005953: `账单编号`,
  key1005954: `账单状态更新时间`,
  key1005955: `中文描述：`,
  key1005956: `导出库存(自有仓库)`,
  key1005957: `(已删除)`,
  key1005958: `SKU、商品名称`,
  key1005959: `模糊搜索`,
  key1005960: `SKU属性`,
  key1005961: `库存数量`,
  key1005962: `冻结数量`,
  key1005963: `不良品数量`,
  key1005964: `请选择库区`,
  key1005965: `导出库存(`,
  key1005966: `中心仓)`,
  key1005967: `库区：`,
  key1005968: `自有仓库`,
  key1005969: `开启拣货复核异常`,
  key1005970: `您扫描或输入的拣货单【 {pickList} 】已完成`,
  key1005971: `您扫描或输入的拣货单【 {pickList} 】不存在或状态已改变,请刷新后重试`,
  key1005972: `您扫描或输入的拣货单【 {pickList} 】下不存在出库单`,
  key1005973: `您扫描或输入的拣货单【 {pickList} 】下不存在有效出库单`,
  key1005974: `您扫描或输入的拣货单【 {pickList} 】下存在普通打印出库单`,
  key1005975: `您扫描或输入的拣货单【 {pickList} 】未进行多品分拣`,
  key1005976: `您扫描或输入的拣货单【 {pickList} 】下存在出库单未下发物流商成功`,
  key1005977: `您扫描或输入的拣货单【 {pickList} 】拣货未完成`,
  key1005978: `您扫描或输入的拣货单【 {pickList} 】在系统中未找到，请确认拣货单是否已被删除`,
  key1005979: `扫描/录入拣货单单号开始拣货复核`,
  key1005980: `正在进行的拣货复核`,
  key1005981: `混合`,
  key1005982: `时长`,
  key1005983: `包裹进度`,
  key1005984: `货品进度`,
  key1005985: `小组成员`,
  key1005986: `单品单件`,
  key1005987: `单品多件`,
  key1005988: `存在正在进行的拣货复核，是否立即进入`,
  key1005989: `存在正在进行的拣货复核，是否立即跳转`,
  key1005990: `已上传物流商`,
  key1005991: `请先选择物流商`,
  key1005992: `出库单详情：`,
  key1005993: `出库单编号：`,
  key1005994: `参考编号：`,
  key1005995: `拣货状态：`,
  key1005996: `装箱状态：`,
  key1005997: `最后修改时间：`,
  key1005998: `最后修改人：`,
  key1005999: `通途估算重量：`,
  key1006000: `通途称重重量：`,
  key1006001: `通途估算运费：`,
  key1006002: `通途称重后运费：`,
  key1006003: `物流商称重：`,
  key1006004: `分配列表`,
  key1006005: `其他出库`,
  key1006006: `组装加工`,
  key1006007: `行状态`,
  key1006008: `分配异常原因`,
  key1006009: `产品sku`,
  key1006010: `填入计划调拨数量`,
  key1006011: `已发货数量`,
  key1006012: `身份码`,
  key1006013: `已分配数量`,
  key1006014: `未分配数量`,
  key1006015: `分配库位`,
  key1006016: `分配批次`,
  key1006017: `分配完成时间`,
  key1006018: `订单创建`,
  key1006019: `部分分配`,
  key1006020: `分配完成`,
  key1006021: `完全发货`,
  key1006022: `订单完成`,
  key1006023: `未装箱`,
  key1006024: `详情加载失败`,
  key1006025: `中心仓备货时进行包装作业：`,
  key1006026: `多品多件包裹自动分拣功能：`,
  key1006027: `拣货复核时进行包装：`,
  key1006028: `此设置对供应商备货流程无效`,
  key1006029: `是否要在拣货复核时对包裹称重？`,
  key1006030: `对包装作业中的已包装的包裹标记为已发货？`,
  key1006031: `开启`,
  key1006032: `分拣时打印地址面单`,
  key1006033: `不称重`,
  key1006034: `分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1006035: `仅按货品SKU字符升序`,
  key1006036: `请至少选择一个拣货架`,
  key1006037: `可扫描出库单号、运单号、物流商单号`,
  key1006038: `有些物流商面单扫描的运单号会带前缀或后缀，这样系统就无法识别运单号，勾选可截去相对应的前缀或后缀`,
  key1006039: `尺寸：`,
  key1006040: `称重异常提醒`,
  key1006041: `直发`,
  key1006042: `扫描出库`,
  key1006043: `切换为批量出库`,
  key1006044: `量体积`,
  key1006045: `称重单位为:`,
  key1006046: `自动执行发货`,
  key1006047: `实际称重：`,
  key1006048: `截去`,
  key1006049: `已发`,
  key1006050: `批量标记发货`,
  key1006051: `清空全部`,
  key1006052: `需要称重`,
  key1006053: `需要量尺寸`,
  key1006054: `称重异常提醒设置`,
  key1006055: `当包裹中的商品为：`,
  key1006056: `且包裹称重与商品资料重量误差大于等于`,
  key1006057: `时系统将提示`,
  key1006058: `发货运费异常设置`,
  key1006059: `逐单扫描发货计算运费大于`,
  key1006060: `产品重量小计(g)：`,
  key1006061: `重量总计(g)：`,
  key1006062: `继续发货`,
  key1006063: `取消发货`,
  key1006064: `个包裹`,
  key1006065: `总重`,
  key1006066: `导出物流确认excel`,
  key1006067: `产品重量`,
  key1006068: `已扫描`,
  key1006069: `标发货成功`,
  key1006070: `此包裹已存在`,
  key1006071: `量尺寸`,
  key1006072: `包裹号不能为空`,
  key1006073: `无权限`,
  key1006074: `您当前筛选的包裹汇总信息：`,
  key1006075: `常用标签：`,
  key1006076: `订单数`,
  key1006077: `SKU品种数`,
  key1006078: `开启多品分拣作业异常`,
  key1006079: `您扫描或输入的拣货单【 {pickList} 】在系统中未找到，请确认拣货单是否已被删除或拣货单号输入错误。`,
  key1006080: `您扫描或输入的拣货单【 {pickList} 】不是多品多件拣货单，无法开启分拣作业。`,
  key1006081: `系统每个操作员同一时间只允许开启一个分拣作业，所以，很抱歉，你无法对拣货单【 {pickList} 】开启分拣作业。`,
  key1006082: `您扫描或输入的拣货单【 {pickList} 】正在进行分拣作业，每张拣货单同一时间只允许一位操作员进行分拣作业，无法再次开启。`,
  key1006083: `您扫描或输入的拣货单【 {pickList} 】已经分拣完成，您可以对该拣货单重新开启分拣作业。`,
  key1006084: `您扫描或输入的拣货单【 {pickList} 】正在进行拣货复核，无法开启分拣作业。`,
  key1006085: `您扫描或输入的拣货单【 {pickList} 】已经完成拣货复核，无法开启分拣作业。`,
  key1006086: `您扫描或输入的拣货单【 {pickList} 】下存在已进行普通打印出库单`,
  key1006087: `扫描/录入拣货单单号开始多品分拣`,
  key1006088: `正在进行的分拣作业`,
  key1006089: `拣货篮不能找到唯一没包装完成的拣货单`,
  key1006090: `拣货篮没有待完成的拣货单`,
  key1006091: `当前系统中存在由您开启的、尚未完成的分拣作业，`,
  key1006092: `分拣作业开始时间：`,
  key1006093: `我搞错了，我不需要重新分拣`,
  key1006094: `是的，我需要对这个拣货单进行重新分拣`,
  key1006095: `分拣状态`,
  key1006096: `正在分拣`,
  key1006097: `分拣完成`,
  key1006098: `操作员`,
  key1006099: `没有权限`,
  key1006100: `部分拣货`,
  key1006101: `等待分拣`,
  key1006102: `等待复核`,
  key1006103: `正在复核`,
  key1006104: `已复核`,
  key1006105: `普通打印`,
  key1006106: `见货出单`,
  key1006107: `拣货提醒`,
  key1006108: `请确认是否取消`,
  key1006109: `导出成功！`,
  key1006110: `拣货单生成时间：`,
  key1006111: `单品规则保存`,
  key1006112: `多品规则保存`,
  key1006113: `单品规则`,
  key1006114: `多品规则`,
  key1006115: `合并规则`,
  key1006116: `时间设置`,
  key1006117: `固定周期`,
  key1006118: `每过：`,
  key1006119: `小时生成拣货单`,
  key1006120: `每天定时`,
  key1006121: `每天：`,
  key1006122: `是否开启`,
  key1006123: `请填写时间`,
  key1006124: `正常拣货单`,
  key1006125: `自动拣货波次`,
  key1006126: `请输入提单编号`,
  key1006127: `批量添加出库单`,
  key1006128: `输入多个请用逗号或者换行分隔`,
  key1006129: `导出提单数据`,
  key1006130: `新增箱/袋`,
  key1006131: `添加包裹`,
  key1006132: `是否要删除当前提单编号？`,
  key1006133: `请输入出库单!`,
  key1006134: `包裹不存在`,
  key1006135: `包裹不是出库状态`,
  key1006136: `该包裹已提单`,
  key1006137: `未拣货/打印出库单明细`,
  key1006138: `同一个出库单中未拣数量至少要有一个是大于0`,
  key1006139: `打印时间：`,
  key1006140: `请输入拣货单号`,
  key1006141: `请输入运单号，多个运单号可以英文逗号隔开`,
  key1006142: `请输入出库单号`,
  key1006143: `请输入订单号`,
  key1006144: `请输入买家姓名`,
  key1006145: `请输入买家ID`,
  key1006146: `可输入订单号、出库单号、买家ID、买家姓名、SKU`,
  key1006147: `多个库区，用逗号或换行分隔`,
  key1006148: `是否有运单号：`,
  key1006149: `是否有物流商号：`,
  key1006150: `是否有物流商重量：`,
  key1006151: `是否有物流商运费：`,
  key1006152: `之前`,
  key1006153: `以下SKU列表是所选包裹中所包含的SKU的汇总，请选择缺货的SKU，标记缺货后，系统将自动将包裹还原到缺货状态，并还原所选SKU的库存，同时建立所选SKU的盘点单，请进行盘点修正库存。`,
  key1006154: `按打印时间`,
  key1006155: `按付款时间`,
  key1006156: `按出库单号`,
  key1006157: `买家ID/姓名`,
  key1006158: `标记为已发货`,
  key1006159: `请选择包裹`,
  key1006160: `标出库`,
  key1006161: `标出库，系统将符合您选择的包裹标记为已出库。`,
  key1006162: `系统将符合您选择的包裹标记为已发货。`,
  key1006163: `标出库（所有结果集）`,
  key1006164: `所有结果集标出库，系统将符合您当前查询条件的所有包裹标记为已出库。`,
  key1006165: `请选择标记的订单`,
  key1006166: `个拣货篮：`,
  key1006167: `打印选中面单`,
  key1006168: `包材`,
  key1006169: `重打面单`,
  key1006170: `拣货复核`,
  key1006171: `没有正在扫描的拣货单`,
  key1006172: `当前勾选中的数据都已打印！`,
  key1006173: `箱/袋管理`,
  key1006174: `扫描装箱`,
  key1006175: `冻结分配`,
  key1006176: `解冻并分配`,
  key1006177: `入库单`,
  key1006178: `冻结单`,
  key1006179: `产品ID`,
  key1006180: `库位使用`,
  key1006181: `收货库位`,
  key1006182: `拣货库位`,
  key1006183: `来源商户：`,
  key1006184: `身份码：`,
  key1006185: `导出出库单数据`,
  key1006186: `作废出库单异常`,
  key1006187: `新增出库单`,
  key1006188: `请输入运单号，多个运单号可用英文逗号分隔`,
  key1006189: `请输入物流商号，多个物流商号可用英文逗号分隔`,
  key1006190: `按出库时间`,
  key1006191: `此出库单为其他出库单实际发货数量超出应发数量所产生的补单。`,
  key1006192: `来源渠道`,
  key1006193: `来源商户`,
  key1006194: `运单号/物流商单号`,
  key1006195: `物流商重量/物流商运费`,
  key1006196: `下载面单`,
  key1006197: `关联订单号`,
  key1006198: `买家名称`,
  key1006199: `邮编`,
  key1006200: `打印时间`,
  key1006201: `打印人`,
  key1006202: `实际重量`,
  key1006203: `物流商重量(g)`,
  key1006204: `组织`,
  key1006205: `物品SKU`,
  key1006206: `物品身份码`,
  key1006207: `多品分拣—结束分拣`,
  key1006208: `已出库数量`,
  key1006209: `货品(已拣/待拣)`,
  key1006210: `新增订单成功!`,
  key1006211: `扫描拣货单号/出库单号/运单号/`,
  key1006212: `面单：`,
  key1006213: `出库单为空，无法操作结束装箱！`,
  key1006214: `请先扫描提单编号！`,
  key1006215: `请输入提单编号！`,
  key1006216: `请输入拣货单号/出库单号/运单号！`,
  key1006217: `是否要删除当前出库单号？`,
  key1006218: `(作废)`,
  key1006219: `库区组`,
  key1006220: `拣货数量`,
  key1006221: `识别号：`,
  key1006222: `扫描SKU：`,
  key1006223: `可扫描SKU`,
  key1006224: `扫描身份码：`,
  key1006225: `可扫描身份码`,
  key1006226: `标记异常`,
  key1006227: `包装设置`,
  key1006228: `异常`,
  key1006229: `校对SKU`,
  key1006230: `已扫描包裹`,
  key1006231: `取消包装`,
  key1006232: `继续包装`,
  key1006233: `包材名称`,
  key1006234: `应包装数量`,
  key1006235: `克（g）`,
  key1006236: `千克（Kg）`,
  key1006237: `待包装数量`,
  key1006238: `产品重量(g)`,
  key1006239: `当前包裹中还在sku还未扫描完成！`,
  key1006240: `当前包裹中还有身份码未扫描完成！`,
  key1006241: `当前扫描的出库单号或运单号或物流商单号，已经扫描出库！`,
  key1006242: `识别号不能为空！`,
  key1006243: `当前扫描的数量不能大于应包装数量！`,
  key1006244: `当前扫描的sku不存在！`,
  key1006245: `SKU不能为空！`,
  key1006246: `称重不能为空且要大于0！`,
  key1006247: `请先扫描识别号！`,
  key1006248: `已扫描数量不能全等于待包装数量，不然无法标记异常！`,
  key1006249: `标记异常成功！`,
  key1006250: `已扫描包裹成功！`,
  key1006251: `重打数量`,
  key1006252: `已打印数量`,
  key1006253: `已扫描完成`,
  key1006254: `匹配失败`,
  key1006255: `多个订单号请用逗号分隔`,
  key1006256: `SKU/数量：`,
  key1006257: `品牌：`,
  key1006258: `发货提醒：`,
  key1006259: `拣货单生成设置`,
  key1006260: `品类`,
  key1006261: `拣货库区组`,
  key1006262: `拣货库区`,
  key1006263: `标签名不能为空`,
  key1006264: `选择导入文件：`,
  key1006265: `选择文件`,
  key1006266: `下载模板`,
  key1006267: `存在空的通途sku`,
  key1006268: `存在空的skuCode`,
  key1006269: `通途sku系统不存在`,
  key1006270: `skuCode 系统不存在`,
  key1006271: `头程成本必须是数字`,
  key1006272: `作废出库单`,
  key1006273: `勾选重置出库单，会重新生成一个出库单`,
  key1006274: `重置出库单`,
  key1006275: `条出库单被重置状态，成功执行后的记录，将回到【等待分配】状态,同时出库单会执行以下操作：`,
  key1006276: `1.出库单如果已经下发物流成功，会尝试调用物流商接口取消下单`,
  key1006277: `2.出库单如果已经分配库存成功，会归还已分配库存`,
  key1006278: `代号：`,
  key1006279: `请输入代号`,
  key1006280: `账号名：`,
  key1006281: `请输入授权的平台账号`,
  key1006282: `到期时间：`,
  key1006283: `账号名`,
  key1006284: `授权时间`,
  key1006285: `数据同步`,
  key1006286: `选择仓库`,
  key1006287: `选择多个仓库将同步所有仓库总库存。`,
  key1006288: `同步仓库`,
  key1006289: `授权`,
  key1006290: `新增商品`,
  key1006291: `请先勾选仓库！`,
  key1006292: `库存 =`,
  key1006293: `主仓实际库存`,
  key1006294: `订单占有数`,
  key1006295: `仓库待发数`,
  key1006296: `虚拟库存`,
  key1006297: `采购在途数`,
  key1006298: `销退仓库存`,
  key1006299: `进货仓库存`,
  key1006300: `次品库存`,
  key1006301: `库存 = `,
  key1006302: `规则项不能为空!`,
  key1006303: `您没有权限访问该资源,请与管理员联系`,
  key1006304: `系统繁忙，请重新尝试（接口报错了，请在控制台查看接口错误）`,
  key1006305: `账号已被停用`,
  key1006306: `供应商结算状态需为等待结算且`,
  key1006307: `出库状态需为已出库`,
  key1006308: `客户结算状态需为等待结算且`,
  key1006309: `确认支付账单失败，该账单已作废`,
  key1006310: `分类ID已存在`,
  key1006311: `父节点不存在`,
  key1006312: `分类名称不能包含->`,
  key1006313: `名称不能包含空格`,
  key1006314: `已到最大分类层级`,
  key1006315: `同级分类名已存在`,
  key1006316: `邮箱已经被占用`,
  key1006317: `手机号已经被占用`,
  key1006318: `包裹非已揽收状态`,
  key1006319: `查询不到相关出库单`,
  key1006320: `提单不为已分拣状态`,
  key1006321: `存在多个包裹`,
  key1006322: `√`,
  key1006323: `存在非分拣中状态`,
  key1006324: `出库单非已拣货状态`,
  key1006325: `交接单非已装箱状态`,
  key1006326: `已是客户`,
  key1006327: `图形验证码错误`,
  key1006328: `验证码错误`,
  key1006329: `邮箱未注册`,
  key1006330: `页面重复提交`,
  key1006331: `发送短信失败`,
  key1006332: `查询用户失败`,
  key1006333: `手机号为空`,
  key1006334: `密码错误`,
  key1006335: `短信验证码校验错误`,
  key1006336: `图形验证码失效`,
  key1006337: `超过错误次数,请输入图形验证码再重试`,
  key1006338: `重复提交`,
  key1006339: `非当前用户邮箱`,
  key1006340: `非当前用户手机`,
  key1006341: `登录失败,用户不存在`,
  key1006342: `请使用通途管理员账号登录注册分销账户`,
  key1006343: `通途帐号登录自动创建客户帐号成功,审核通过后再访问`,
  key1006344: `当前用户请审核通过之后登录`,
  key1006345: `商品不存在`,
  key1006346: `客户等级已绑定供应商等级`,
  key1006347: `供应商已设置该供应商等级`,
  key1006348: `查询的拣货单编号不存在`,
  key1006349: `品牌名已存在`,
  key1006350: `非草稿状态不能删除`,
  key1006351: `待审核不能修改中文名称,英文名称,标题,图片`,
  key1006352: `不能包含逗号`,
  key1006353: `存在相同属性SKU`,
  key1006354: `SPU或SKU存在重复`,
  key1006355: `变参商品SKU不能与SPU重复`,
  key1006356: `该商品正在被映射商品使用，不能删除`,
  key1006357: `包裹存在待完成的补拣单`,
  key1006358: `sku不存在`,
  key1006359: `异常包裹中存在正常拣货包裹`,
  key1006360: `包裹已作废`,
  key1006361: `标出库失败，此包裹已作废待回收`,
  key1006362: `完成包装失败，包装状态已变更!`,
  key1006363: `不是多品多件拣货单，无法开启分拣作业。`,
  key1006364: `系统每个操作员同一时间只允许开启一个分拣作业`,
  key1006365: `拣货单正在被别人分拣中`,
  key1006366: `已经分拣完成，您可以对该拣货单重新开启分拣作业。`,
  key1006367: `存在已进行普通打印出库单`,
  key1006368: `拣货单未打印，不能开始拣货复核`,
  key1006369: `拣货单已完成`,
  key1006370: `拣货单下不存在出库单`,
  key1006371: `拣货单下不存在有效出库单`,
  key1006372: `拣货单下存在商品备注`,
  key1006373: `拣货单下存在普通打印出库单`,
  key1006374: `拣货单未进行多品分拣`,
  key1006375: `拣货单下存在出库单未下发物流商成功`,
  key1006376: `拣货单拣货未完成`,
  key1006377: `分拣仅支持销售出库拣货单`,
  key1006378: `sku与upc不可重复`,
  key1006379: `upc已存在`,
  key1006380: `UPC存在重复`,
  key1006381: `存在重复SKU`,
  key1006382: `非销售出库单拣货单不支持拣货复核`,
  key1006383: `包裹已截留`,
  key1006384: `用户正在包装其他拣货单`,
  key1006385: `未找到可以分配的库存，无法分配`,
  key1006386: `包裹已出库`,
  key1006387: `包裹信息不存在`,
  key1006388: `箱号不能为空`,
  key1006389: `sku不能为空`,
  key1006390: `未找到对应出库单`,
  key1006391: `出库单号错误`,
  key1006392: `SKU不存在在`,
  key1006393: `拣货单不存在`,
  key1006394: `清空扫描失败，该包裹已经扫描完成`,
  key1006395: `装箱数量错误`,
  key1006396: `该拣货单下只有一个包裹，不能移除`,
  key1006397: `拣货单已完成拣货复核,无法重新分拣`,
  key1006398: `仓库正在配货中，请稍后再试`,
  key1006399: `包裹没有待补拣的货品`,
  key1006400: `入库单已关闭,不能进行收货!`,
  key1006401: `当前包裹不是已打印状态`,
  key1006402: `扫描的sku数量与应包装数量不一致`,
  key1006403: `未匹配合适出库单`,
  key1006404: `无效key`,
  key1006405: `扫描sku数量与身份码数量不一致`,
  key1006406: `当前提单状态不是装箱中不可以继续装箱`,
  key1006407: `结束分拣的拣货单分拣状态不是分拣中,不能结束分拣`,
  key1006408: `已拣货的拣货单不能再次标记为已拣货`,
  key1006409: `包裹状态已变更,操作异常`,
  key1006410: `提单号不存在`,
  key1006411: `提单非等待揽收/正在装箱状态,`,
  key1006412: `不能删除`,
  key1006413: `当前属性正在被引用，不可删除`,
  key1006414: `同名属性已存在`,
  key1006415: `请停售被删除的SKU后重试`,
  key1006416: `该SKU有库存不允许删除`,
  key1006417: `SKU已存在`,
  key1006418: `订单已作废`,
  key1006419: `存在非等待揽收状态`,
  key1006420: `提单揽收包裹数量不统一`,
  key1006421: `属性不合法`,
  key1006422: `颜色的中文名称已存在`,
  key1006423: `库区编码重复`,
  key1006424: `库区名称重复`,
  key1006425: `仓库名称重复`,
  key1006426: `仓库编码重复`,
  key1006427: `该`,
  key1006428: `订单已换单无法取消`,
  key1006429: `操作人已完成拣货，请勿重复提交`,
  key1006430: `已分拣完成处于带包装状态，请勿重复提交`,
  key1006431: `已经撤销成功，请勿重复提交`,
  key1006432: `库位编号重复`,
  key1006433: `拣货单正在分拣`,
  key1006434: `没有匹配到待包装`,
  key1006435: `当前拣货单状态为非待拣货/拣货中状态`,
  key1006436: `拣货单不是待包装或者包装中`,
  key1006437: `状态已变更,请刷新后重试!`,
  key1006438: `只有已换单的包裹才可以扫描`,
  key1006439: `分类下存在商品`,
  key1006440: `分类下存在子分类`,
  key1006441: `重量不能为空`,
  key1006442: `剩下一个sku时`,
  key1006443: `不可以删除`,
  key1006444: `获取分类信息异常`,
  key1006445: `非物流面单类型不允许标记出库`,
  key1006446: `面单异常`,
  key1006447: `不能映射被删除的属性`,
  key1006448: `存在映射属性，不允许删除`,
  key1006449: `提单已删除`,
  key1006450: `变更的类别下存在映射属性，请先进行映射属性删除`,
  key1006451: `当前充值的客户编号不存在`,
  key1006452: `同一平台下不能存在相同站点/无站点则不能存在相同分类`,
  key1006453: `货品没有图片信息，请及时上传`,
  key1006454: `货品没有颜色图片信息，请及时上传`,
  key1006455: `物流商不存在`,
  key1006456: `物流商账号名称已存在`,
  key1006457: `平台帐号已停用`,
  key1006458: `服务名称或者编号重复`,
  key1006459: `物流商代码已存在`,
  key1006460: `存在相同名称的自动规则`,
  key1006461: `货代物理关联重复`,
  key1006462: `店铺编号已存在`,
  key1006463: `店铺名称已存在`,
  key1006464: `店铺名称和编号已存在`,
  key1006465: `已授权店铺不能切换店铺`,
  key1006466: `订单状态异常`,
  key1006467: `没有满足`,
  key1006468: `物流特殊规则的`,
  key1006469: `物流范围规则的`,
  key1006470: `取消订单异常`,
  key1006471: `更新用户信息异常`,
  key1006472: `更新物流信息异常`,
  key1006473: `创建订单失败`,
  key1006474: `订单已下单`,
  key1006475: `包裹已经出库`,
  key1006476: `正在处理下发物流操作`,
  key1006477: `取消物流失败`,
  key1006478: `供应商系统作废子单失败`,
  key1006479: `查询指定货品信息为空`,
  key1006480: `手工下单失败`,
  key1006481: `生成发货信息失败`,
  key1006482: `物流渠道停用失败，已关联`,
  key1006483: `物流无法停用`,
  key1006484: `物流渠道代码不可重复`,
  key1006485: `该包裹不处于等待出库状态`,
  key1006486: `指定拣货单列表为空`,
  key1006487: `中心仓备货的出库单已经生成拣货单，不允许作废`,
  key1006488: `面单正在拣货复核中`,
  key1006489: `出库单不是`,
  key1006490: `中心仓备货的出库单`,
  key1006491: `存在已出库的供应商子单`,
  key1006492: `没有找到sku对应的货品信息`,
  key1006493: `中心仓备货拣货单正在被扫描`,
  key1006494: `同类型通知消息模板已存在`,
  key1006495: `商户未设置刊登地址`,
  key1006496: `角色名称已存在`,
  key1006497: `角色在使用中`,
  key1006498: `未设置默认客户等级`,
  key1006499: `出库单已标缺货`,
  key1006500: `供应商未进行直接发货设置，不允许直接发货`,
  key1006501: `当前容器入库状态不是待换单`,
  key1006502: `同名容器类型已存在`,
  key1006503: `当前容器类型已被绑定`,
  key1006504: `容器与提单不在同一个仓库`,
  key1006505: `分拣容器中不存在该`,
  key1006506: `容器不存在`,
  key1006507: `容器已被绑定`,
  key1006508: `容器状态异常或已变更`,
  key1006509: `容器为空，不可结束分拣`,
  key1006510: `提单分拣数量异常，存在未完成分拣的sku或运单`,
  key1006511: `当前容器入库状态不是换单中`,
  key1006512: `订单已被分拣`,
  key1006513: `提单正在分拣`,
  key1006514: `容器状态不存在`,
  key1006515: `提单状态需为已入库或分拣中`,
  key1006516: `存在分拣中提单`,
  key1006517: `该提单其他用户正在分拣中`,
  key1006518: `已绑定同类型容器`,
  key1006519: `请完成当前的容器贴单作业`,
  key1006520: `容器正在贴单`,
  key1006521: `请完成当前的容器上架作业`,
  key1006522: `容器正在上架`,
  key1006523: `存在处理中的容器`,
  key1006524: `分拣交接单中已存在该`,
  key1006525: `订单需要先走商品分拣`,
  key1006526: `订单已发货，不可被移除`,
  key1006527: `正在处理下发物流,请稍后再试`,
  key1006528: `当前尺码类型正在被使用`,
  key1006529: `模板已被使用，不允许删除`,
  key1006530: `部位中文名称已被使用，不允许修改`,
  key1006531: `尺码值已存在`,
  key1006532: `规格属性已被使用，不允许删除`,
  key1006533: `规格属性已被使用，不允许修改`,
  key1006534: `当前尺码值,正在被使用,不允许删除`,
  key1006535: `主属性的规格属性已被使用，不允许修改`,
  key1006536: `导出模板异常`,
  key1006537: `聚水潭商户未授权`,
  key1006538: `未添加聚水潭商户`,
  key1006539: `未找到已勾选同步库存并且已启用的聚水潭商户`,
  key1006540: `Sku值为空`,
  key1006541: `不是在售的spu不能停售`,
  key1006542: `上架数量与拣货数量不一致`,
  key1006543: `导出账单信息为空`,
  key1006544: `手机未注册`,
  key1006545: `SPU不能包含中文`,
  key1006546: `当前尺码属性模板正在被使用`,
  key1006547: `已存在相同名称的属性，请修改属性名称后再提交`,
  key1006548: `没有创建仓库，请先创建仓库`,
  key1006549: `该订单已取消，请在线下补货到中心仓`,
  key1006550: `已装箱的出库单不能作废`,
  key1006551: `存在多个主属性`,
  key1006552: `未满足重复刊登条件`,
  key1006553: `入驻商户不存在`,
  key1006554: `密码校验错误`,
  key1006555: `未查询到收款人信息`,
  key1006556: `拣货单不是待分拣或者分拣中`,
  key1006557: `拣货单已取消`,
  key1006558: `拣货单不处于包装中`,
  key1006559: `正在装箱的出库单不能作废`,
  key1006560: `活动结束时间不能小于活动开始时间`,
  key1006561: `该商品已经存在其他活动中`,
  key1006562: `未找到商品对应类型的容器，请绑定后重新扫描。`,
  key1006563: `暂未授权WishPost，请在自有物流管理中授权并关联店铺后重试`,
  key1006564: `订单对应店铺暂未关联WishPost账户，请在自有物流管理中关联后重试`,
  key1006565: `WishPost授权无效，请在自有物流管理中重新授权后重试`,
  key1006566: `店铺已关联其他账号`,
  key1006567: `关联店铺未启用`,
  key1006568: `同名问卷已存在`,
  key1006569: `当前问卷下同名问题已存在`,
  key1006570: `仓库已关联供应商`,
  key1006571: `扫描包裹与交接单发货仓库不相同`,
  key1006572: `1688平台未授权店铺`,
  key1006573: `链接不存在`,
  key1006574: `币种配置已存在`,
  key1006575: `此拣货单为常规单，请至常规包装模块进行作业`,
  key1006576: `此拣货单为备货拣货单，请至备货分拣进行作业`,
  key1006577: `客户暂未执行发货，请下轮作业重试`,
  key1006578: `未查到相关小包信息`,
  key1006579: `当前小包已经被分拣扫描`,
  key1006580: `存在未包装完成小包`,
  key1006581: `存在未被物流分拣小包,请分拣完成后再结袋`,
  key1006582: `修改订单同步状态为同步中失败`,
  key1006583: `物流交接单所在仓未授权,请申请对应仓库权限`,
  key1006584: `交接单不存在`,
  key1006585: `请先开通Temu虚拟物流`,
  key1006586: `平台订单已存在`,
  key1006587: `物流商单号已存在`,
  key1006588: `已充值币种不可修改`,
  key1006589: `创建商品后币种不可修改`,
  key1006590: `正在进行中的作业不是分拣中或包装中`,
  key1006591: `扫描的sku数量超出拣货单的应拣数量`,
  key1006592: `已存在商品使用此属性作为规格属性，不可删除`,
  key1006593: `还原库存失败`,
  key1006594: `账单已经生成，补贴审核失败`,
  key1006595: `物流仅支持绑定一条渠道`,
  key1006596: `上传包裹失败`,
  key1006597: `获取面单失败`,
  key1006598: `仓库地址不存在`,
  key1006599: `物流未关联基础物流`,
  key1006600: `物流商获取token失败`,
  key1006601: `不允许扫描temu出库单`,
  key1006602: `速卖通oaid不存在`,
  key1006603: `解密失败`,
  key1006604: `账单已经生成，补贴重新审核失败`,
  key1006605: `打印所选订单条码异常`,
  key1006606: `打印sku条码异常`,
  key1006607: `存在非缺货中的货品无法标记到货`,
  key1006608: `无此商品订单，请核对订单`,
  key1006609: `提单非等待揽收状态`,
  key1006610: `出库单已出库,不可进行其他操作`,
  key1006611: `当前订单中存在出库单为非待发货状态的订单`,
  key1006612: `此拣货单内只有一个订单，此拣货单无需分拣，请进行备货包装作业`,
  key1006613: `此拣货单为常规单，请至常规分拣进行作业`,
  key1006614: `此拣货单为备货单，请至备货分拣进行作业`,
  key1006615: `追加货品，同步运营提单失败`,
  key1006616: `当前订单中存在出库单为缺货状态的订单`,
  key1006617: `中间标签码不可为空`,
  key1006618: `无发货订单`,
  key1006619: `该拣货单存在在未整单完结的`,
  key1006620: `手工订单和平台订单不可同时执行发货`,
  key1006621: `货品订单和样品订单不可同时执行发货`,
  key1006622: `不同店铺的订单不可同时执行发货`,
  key1006623: `不同收货地址的订单不可同时执行发货`,
  key1006624: `该订单存在未结袋的小包`,
  key1006625: `订单已经完结,不可以再次扫描`,
  key1006626: `手动修改的扫描数必须是大于0的正整数`,
  key1006627: `所选出库单批次下出库单超出最大限制，请调整最大包裏数`,
  key1006628: `每张拣货单最大出库单数不能为空或小于1`,
  key1006629: `拣货单正在被其他用户作业，不可再次作业`,
  key1006630: `temu_s拣货单存在未打印`,
  key1006631: `提单与容器所属仓库不同,请检查后重试`,
  key1006632: `非待匹配商品或匹配物流失败或下单失败状态，无法标记为非`,
  key1006633: `订单操作`,
  key1006634: `同系统同模块下已存在该标题的标题指引，请重新定义标题`,
  key1006635: `物流/基础物流不能同时传`,
  key1006636: `物流/基础物流必传其一`,
  key1006637: `基础物流trackingNumber,labelPath必传`,
  key1006638: `sku已停售`,
  key1006639: `商品发货仓库匹配到多个，请拆分平台订单后，再下单`,
  key1006640: `匹配仓库失败`,
  key1006641: `商品可用库存为空或不合法`,
  key1006642: `账号未绑定店铺，或者账号已过期`,
  key1006643: `同名客户标签已存在`,
  key1006644: `该客户分组名已存在`,
  key1006645: `该用户已开启其他拣货作业`,
  key1006646: `手机已绑定,不支持更新手机`,
  key1006647: `商品所属类目未映射平台`,
  key1006648: `获取供应商币种接口失败`,
  key1006649: `SPU存在重复，请修改`,
  key1006650: `查询三方平台订单失败`,
  key1006651: `支付宝支付订单失败`,
  key1006652: `新增充值记录失败`,
  key1006653: `获取分销商币种失败`,
  key1006654: `未查询到可操作订单`,
  key1006655: `回传发货状态失败`,
  key1006656: `店铺地址为空`,
  key1006657: `物流交接单内订单为空`,
  key1006658: `已换单后不允许多品分拣移除拣货单`,
  key1006659: `未申请预约单`,
  key1006660: `容器类型已被使用，请删除已使用此类型的容器后，再编辑此容器类型`,
  key1006661: `未找到物流追踪记录`,
  key1006662: `未匹配到有效物流渠道`,
  key1006663: `存在下单失败的`,
  key1006664: `订单,请完成后再更换商品`,
  key1006665: `客户等级名称已存在,请更换后重试`,
  key1006666: `已装箱后不允许修改买家收件地址`,
  key1006667: `邮箱注册方式邮箱不可为空`,
  key1006668: `邮箱和手机至少需要填写一个`,
  key1006669: `此物流渠道暂不可用，详情请咨询客服`,
  key1006670: `已选商品正在生成资料中`,
  key1006671: `生成售卖资料失败`,
  key1006672: `已存在供应商账号`,
  key1006673: `请重新登录`,
  key1006674: `已存在分销商账号，请重新登录`,
  key1006675: `暂未开通供应商账号权限，请联系主账号开通，如有疑问，请联系在线客服咨询！`,
  key1006676: `html类型面单只允许单个打印`,
  key1006677: `销售价小于或等于0`,
  key1006678: `标记缺货的时候，缺货数量必须大于1`,
  key1006679: `该账号为运营商系统账号，请更换账号再试`,
  key1006680: `此发货仓已有此类型费用配置，请检查后重试`,
  key1006681: `未设置仓库处理费`,
  key1006682: `固定计费未设置常规或多品订单处理费`,
  key1006683: `分层计费未设置分层价或多频订单处理费`,
  key1006684: `当前勾选的账单存在已被确认过的账单`,
  key1006685: `此规格属性已被使用，不可修改为普通属性`,
  key1006686: `存在相同模板名称的地址薄`,
  key1006687: `备注内容过长,添加失败`,
  key1006688: `上架工具权限授权更新失败`,
  key1006689: `备货单所在提单已装箱`,
  key1006690: `同平台配置已存在`,
  key1006691: `未配置指定平台`,
  key1006692: `未配置指定店铺`,
  key1006693: `未配置刊登模板`,
  key1006694: `店铺token失效`,
  key1006695: `未设置超时时间`,
  key1006696: `请先开通速卖通全托管物流`,
  key1006697: `IOSS编码格式不正确`,
  key1006698: `目的国税率设置已存在`,
  key1006699: `同名IOSS配置已存在`,
  key1006700: `其中有原料正在被组装商品使用，不能删除`,
  key1006701: `平台订单IOSS已录入`,
  key1006702: `物流代缴，清关费用不可为空`,
  key1006703: `此物流渠道已停用,请重新选择物流渠道`,
  key1006704: `存在已经生成售卖资料的数据`,
  key1006705: `分销商备货单不是待支付状态`,
  key1006706: `分销商取消备货单货品数量校验异常`,
  key1006707: `分销商备货单不是待调价状态`,
  key1006708: `分销商备货单不是供应商待接价状态`,
  key1006709: `分配备货单上架库存失败`,
  key1006710: `设置组装商品中包含已删除货品`,
  key1006711: `设置组装商品中原材料包含组装货品`,
  key1006712: `组装商品原材料不能和当前商品一致`,
  key1006713: `存在相同名称的导出模板`,
  key1006714: `未配置组装商品虚拟库位`,
  key1006715: `组装商品原料及成品出入库失败`,
  key1006716: `物流交接单非分拣中状态`,
  key1006717: `可操作单数量与选择不一致`,
  key1006718: `没有匹配买家邮政编码的`,
  key1006719: `可操作单数量于选择不一致`,
  key1006720: `保存申报信息失败`,
  key1006721: `不支持的库存操作`,
  key1006722: `库存操作失败`,
  key1006723: `调接口获取供应商数据失败`,
  key1006724: `当前提单非自有物流且处在等待揽收状态，不可修改物流`,
  key1006725: `货品分配数失败`,
  key1006726: `撤回`,
  key1006727: `调速卖通创建揽收接口失败`,
  key1006728: `打印揽收面单图片访问失败`,
  key1006729: `调速卖通全托管打印揽收面单接口失败`,
  key1006730: `速卖通预约交货接口调用API异常`,
  key1006731: `超出此物流尺寸限制，请更换物流后重试`,
  key1006732: `超出此物流重量限制，请更换物流后重试`,
  key1006733: `超出此物流配送范围，请更换物流后重试`,
  key1006734: `待接单状态无法匹配商品`,
  key1006735: `不同目的仓订单请分开发货`,
  key1006736: `由于货品数量过多，请在详情中进行单个打印`,
  key1006737: `商品属性未填写属性占比`,
  key1006738: `当前交接单内存在已取消的包裹，请移除已取消包裹后重试`,
  key1006739: `存在非已装箱的订单`,
  key1006740: `此商品已停售，`,
  key1006741: `仓库存数量小于订单货品数量，暂无法下单`,
  key1006742: `生成拣货单失败`,
  key1006743: `存在分销商未取消订单，请稍后再试`,
  key1006744: `当前物流不可达，请取消订单后重新下单`,
  key1006745: `暂未开通分销商账号权限，请联系主账号开通，如有疑问，请联系在线客服咨询！`,
  key1006746: `更换收货地址取消`,
  key1006747: `订单异常`,
  key1006748: `更换收货地址失败，订单已取消`,
  key1006749: `存在重复快递单号，此包裹请通过提单号进行扫描`,
  key1006750: `非等待揽收状态不可作废`,
  key1006751: `作废供应商提单失败`,
  key1006752: `站点名称已经被使用，请更改其他站点名`,
  key1006753: `仅支持已创建、备货中订单进行取消，请刷新数据查看`,
  key1006754: `未设置云卖物流规则`,
  key1006755: `回传样品发货信息失败`,
  key1006756: `获取大仓收货地址失败`,
  key1006757: `商品条码查询失败`,
  key1006758: `查询寄样单列表`,
  key1006759: `未匹配到发货方式`,
  key1006760: `物流代缴，清关费不可为空`,
  key1006761: `无有效的发货信息`,
  key1006762: `平台单不存在交易信息`,
  key1006763: `订单已发货`,
  key1006764: `出库单非已出库状态`,
  key1006765: `商品非待审核状态`,
  key1006766: `已关注该店铺`,
  key1006767: `商品分类信息不存在`,
  key1006768: `已取消收藏`,
  key1006769: `当前待上架提单不是已入库状态`,
  key1006770: `重置包裹状态失败`,
  key1006771: `未匹配到仓库，请联系客服处理`,
  key1006772: `容器非货品分拣完成状态`,
  key1006773: `已经处于完成上架状态，请勿重复操作`,
  key1006774: `该货品已经分拣完成`,
  key1006775: `未设置刊登规则`,
  key1006776: `未匹配到库区，请联系客服处理`,
  key1006777: `SKU与货位号不匹配，请检查后重试`,
  key1006778: `SKU已完拣`,
  key1006779: `账单不是付款状态`,
  key1006780: `必传其一`,
  key1006781: `供应商未设置归属仓库`,
  key1006782: `未完全分配ymsWarehouseLocation`,
  key1006783: `速卖通线上发货UserNick必传`,
  key1006784: `包裹所在仓未授权`,
  key1006785: `物流交接单仓库与包裹仓库不匹配`,
  key1006786: `物流交接单仓库与包裹物流不一致`,
  key1006787: `缺少包裹`,
  key1006788: `物流商编号已存在`,
  key1006789: `三方平台不存在`,
  key1006790: `获取店铺的发货,退货地址失败`,
  key1006791: `当前云卖订单未发货`,
  key1006792: `当前订单物流方式不是云卖物流`,
  key1006793: `速卖通打印面单接口失败`,
  key1006794: `单次最多可处理200个包裹，请移出后再结袋`,
  key1006795: `分销商备货单已经支付，不可再次支付`,
  key1006796: `分类不存在`,
  key1006797: `订单编号查询订单Id集合异常`,
  key1006798: `包裹信息保存失败`,
  key1006799: `获取物流商异常`,
  key1006800: `打印面单异常`,
  key1006801: `订单已经存在包裹`,
  key1006802: `物流商帐号不存在`,
  key1006803: `存在未回收包裹`,
  key1006804: `包裹订单数据异常`,
  key1006805: `获取订单异常`,
  key1006806: `更新订单打印时间失败`,
  key1006807: `发货系统包裹数据与订单系统数据不一致`,
  key1006808: `出库单下发物流未成功`,
  key1006809: `出库单标记打印失败,部分包裹已经进行其他打印方式`,
  key1006810: `包裹未交运`,
  key1006811: `无效的可用库存数请输入大于0可用库存数`,
  key1006812: `发货台内当前无商品，无法打印配货清单`,
  key1006813: `修改备货单货品数量失败`,
  key1006814: `当前提单非等待揽收状态，不可修改物流`,
  key1006815: `当前提单非自有物流，不可修改物流`,
  key1006816: `获取仓库异常`,
  key1006817: `更新包裹包装状态异常`,
  key1006818: `用户没有开启分拣时打印地址面单`,
  key1006819: `存在商品在使用该品牌，不能删除!`,
  key1006820: `提交存在相同属性`,
  key1006821: `变参商品空属性`,
  key1006822: `属性key不一致`,
  key1006823: `删除SKU异常`,
  key1006824: `上传图片路径为空`,
  key1006825: `普通商品spu与sku必须一致`,
  key1006826: `变参商品属性不能为空`,
  key1006827: `特性标签包含逗号`,
  key1006828: `非变参不能添加格外sku`,
  key1006829: `spu不能为空`,
  key1006830: `cnName不能为空`,
  key1006831: `分类ID不能为空`,
  key1006832: `productGoodsList不能为空`,
  key1006833: `sku未匹配`,
  key1006834: `请求参数为空`,
  key1006835: `取消分配失败`,
  key1006836: `取消分配失败出库单不存在`,
  key1006837: `取消分配失败已发货、已取消、已生成拣货单或已拣货的不可取消`,
  key1006838: `取消分配失败删除拣货单失败`,
  key1006839: `取消分配失败还原原库存数据`,
  key1006840: `(分配之前`,
  key1006841: `)失败`,
  key1006842: `取消分配失败修改库存产品明细数据失败`,
  key1006843: `(已分配数量`,
  key1006844: `取消分配失败产品分配记录删除失败`,
  key1006845: `操作出库单失败`,
  key1006846: `明细数据为空`,
  key1006847: `未找到出库单`,
  key1006848: `操作货品失败`,
  key1006849: `拣货状态不正确`,
  key1006850: `发货状态状态不正确`,
  key1006851: `物流地址为空，请填写物流地址`,
  key1006852: `地址不匹配，请联系在线客服咨询`,
  key1006853: `调用支付宝转账异常`,
  key1006854: `同步平台地址失败`,
  key1006855: `仅待审核客户可进行审核，请重新选择`,
  key1006856: `速卖通接口异常`,
  key1006857: `pms物流账号token为空`,
  key1006858: `包裹号为空`,
  key1006859: `商品链接为空`,
  key1006860: `物流取消异常`,
  key1006861: `获取ioss税率配置失败`,
  key1006862: `当前订单不是手工单`,
  key1006863: `未查询到客户编号所对应的联系人`,
  key1006864: `未查询到库存统计表中要更新的数据YmsInventoryWarehouse`,
  key1006865: `修改alibabagj收货地址失败`,
  key1006866: `当前订单中存在出库单为非尾程物流的POD订单`,
  key1006867: `确认订单付款报错，接口异常`,
  key1006868: `阿里国际平台单非手工取消`,
  key1006869: `发货重试`,
  key1006870: `未找到已匹配商品的分销商平台订单`,
  key1006871: `wms发货失败`,
  key1006872: `未找到符合订单条件的云卖物流`,
  key1006873: `wms取消失败`,
  key1006874: `仓库为空`,
  key1006875: `当前拣货单中包含状态为非`,
  key1006876: `待拣货/拣货中`,
  key1006877: `包裹下的ordershipingid已经存在`,
  key1006878: `获取发货设置异常`,
  key1006879: `出库单列表数量无效`,
  key1006880: `分配库存异常`,
  key1006881: `未出库的包裹`,
  key1006882: `更新账单和出库单状态失败`,
  key1006883: `同一平台下不能存在相同站点`,
  key1006884: `请确认商户号是否正确`,
  key1006885: `店铺不存在`,
  key1006886: `拣货单正在包装中`,
  key1006887: `拣货单不是待拣货或拣货中`,
  key1006888: `当前云卖面单正在拣货复核中`,
  key1006889: `商品状态错误`,
  key1006890: `拣货单状态异常`,
  key1006891: `获取包装设置异常`,
  key1006892: `未匹配到指定云卖订单`,
  key1006893: `未获取面单异常`,
  key1006894: `库区名称name重复`,
  key1006895: `库区库位不能都为null`,
  key1006896: `可用库存不能小于0`,
  key1006897: `商品生成面单标签异常`,
  key1006898: `生成云卖面单异常`,
  key1006899: `无效的仓储库存信息ID`,
  key1006900: `仅支持对待审核状态账单进行批量审核，请检查后重试`,
  key1006901: `仅支持对待打款状态账单进行批量审核，请检查后重试`,
  key1006902: `无此API权限，请联系客服开通`,
  key1006903: `备货单拆单失败`,
  key1006904: `订单同名标签已存在`,
  key1006905: `此订单中含有特殊需加工商品，无法直接取消，若需取消请联系客服`,
  key1006906: `同名商品标签已存在`,
  key1006907: `同名订单导入模板已存在`,
  key1006908: `存在相同Excel文件标题`,
  key1006909: `等级折扣系数值设置无效`,
  key1006910: `存在非待确认支付信息`,
  key1006911: `取消失败，运营已对账通过，请刷新后查看`,
  key1006912: `未匹配平台仓库`,
  key1006913: `查询卖家发货仓库基础信息接口失败`,
  key1006914: `仅普通属性可选值可关联子属性`,
  key1006915: `商品属性之间存在循环关联`,
  key1006916: `被关联属性不可修改为规格属性`,
  key1006917: `编辑商品`,
  key1006918: `尺码表模板`,
  key1006919: `新增/编辑尺码表模板`,
  key1006920: `待审核列表`,
  key1006921: `审核/编辑商品`,
  key1006922: `商品设置`,
  key1006923: `供应商订单详情`,
  key1006924: `分销商备货单`,
  key1006925: `供应商备货单`,
  key1006926: `供应商备货单详情`,
  key1006927: `订单支付设置`,
  key1006928: `订单收款账户`,
  key1006929: `入库管理`,
  key1006930: `提单管理`,
  key1006931: `提单管理详情`,
  key1006932: `查看出库单详情`,
  key1006933: `拣货单列表`,
  key1006934: `见货出单设置`,
  key1006935: `物流商交接单管理`,
  key1006936: `物流商交接单管理详情`,
  key1006937: `供应商管理`,
  key1006938: `供应商列表`,
  key1006939: `供应商审核`,
  key1006940: `供应商等级管理`,
  key1006941: `客户管理`,
  key1006942: `客户列表`,
  key1006943: `拓客链接`,
  key1006944: `供应商账单`,
  key1006945: `供应商账单详情`,
  key1006946: `客户账单详情`,
  key1006947: `物流账单详情`,
  key1006948: `仓储账单详情`,
  key1006949: `密码管理`,
  key1006950: `物流管理`,
  key1006951: `基础物流`,
  key1006952: `智能物流规则`,
  key1006953: `仓库管理`,
  key1006954: `库区管理`,
  key1006955: `库位管理`,
  key1006956: `消息模板`,
  key1006957: `容器管理`,
  key1006958: `容器类型设置`,
  key1006959: `地址库管理`,
  key1006960: `出入库流程配置`,
  key1006961: `IOSS税率设置`,
  key1006962: `商城配置`,
  key1006963: `申报规则`,
  key1006964: `自动分仓规则`,
  key1006965: `内容管理`,
  key1006966: `问卷调查`,
  key1006967: `编辑问卷`,
  key1006968: `操作指引`,
  key1006969: `数据中心`,
  key1006970: `营销管理`,
  key1006971: `站点管理`,
  key1006972: `活动管理`,
  key1006973: `新增/编辑活动`,
  key1006974: `广告管理`,
  key1006975: `分销商费用配置`,
  key1006976: `消息、邮箱查看`,
  key1006977: `员工管理`,
  key1006978: `任务中心`,
  key1006979: `导入任务`,
  key1006980: `导出任务`,
  key1006981: `订单中心`,
  key1006982: `订单列表`,
  key1006983: `备货单详情`,
  key1006984: `发货记录`,
  key1006985: `发货记录详情`,
  key1006986: `商品中心`,
  key1006987: `新增/编辑商品`,
  key1006988: `品牌管理`,
  key1006989: `第三方平台`,
  key1006990: `平台授权`,
  key1006991: `对账管理`,
  key1006992: `账户管理`,
  key1006993: `出库单详情`,
  key1006994: `提货单`,
  key1006995: `拣货作业`,
  key1006996: `出库作业`,
  key1006997: `出库作业设置`,
  key1006998: `商城`,
  key1006999: `分销市场`,
  key1007000: `收藏的商品`,
  key1007001: `生成售卖记录`,
  key1007002: `自动规则`,
  key1007003: `店铺管理`,
  key1007004: `收藏的供应商`,
  key1007005: `平台订单详情`,
  key1007006: `添加Temu订单`,
  key1007007: `付款管理`,
  key1007008: `订单导入模板`,
  key1007009: `权限管理`,
  key1007010: `角色管理`,
  key1007011: `用户管理`,
  key1007012: `价格变动详情`,
  key1007013: `账号管理`,
  key1007014: `安全信息`,
  key1007015: `商品、店铺列表`
}
