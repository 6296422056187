import Vue from 'vue';
import VueI18n from 'vue-i18n';
import local31d5cc861e6d49e3b643f6a64ff3efc3 from './lang/en-US';
import local445226e9df2b480d9ba45d8ea61ba3ff from './lang/ko-KR';
import local8e69f09f31c542a6992f63f07a53962e from './lang/zh-CN';

Vue.use(VueI18n);
function handleSystemLanguage() {
  let language = '';
  let defaultLanguage = window.navigator.language;
  if (defaultLanguage) {
    if (['zh', 'zh-CN'].includes(defaultLanguage)) {
      language = 'zh-CN';
    } else if (['en', 'en-US'].includes(defaultLanguage)) {
      language = 'en-US';
    } else {
      language = 'zh-CN';
    }
    return language;
  }
}
let lang = localStorage.getItem('lang')　|| handleSystemLanguage() || 'zh-CN';
localStorage.setItem('lang', lang);
Vue.config.lang = lang;

var localeMap = {"en-US":"English","ko-KR":"한국어","zh-CN":"简体中文"}
const messages = {'en-US':{...local31d5cc861e6d49e3b643f6a64ff3efc3},'ko-KR':{...local445226e9df2b480d9ba45d8ea61ba3ff},'zh-CN':{...local8e69f09f31c542a6992f63f07a53962e}};
export const i18n = new VueI18n({
				  locale: lang,
				  messages
				});
