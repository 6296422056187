<template>
  <div class="operation_center">
    <div class="operation_button_box" :style="{justifyContent: align}">
      <div class="operation_button_list" :class="[className, operationButtonStyle]" id="operationButtonList" v-if="buttonList.length>0">
        <template v-for="(item, index) in buttonList">
          <!--通用的操作按钮-->
          <template v-if="!item.customRender">
            <div :key="index" v-if="item.show" class="operation_button_item" :data-text="item.text"
              :style="{color: Object.keys(item).indexOf('color') >= 0 ? item.color : buttonColor}" @click="item.clickFn">
              {{ item.text }}
            </div>
          </template>
          <!--render函数自定义操作按钮-->
          <template v-else>
            <RenderDom :renderCustomContent="item.renderCustomContent" :paramsData="item.paramsData"></RenderDom>
          </template>
        </template>
      </div>
      <!--下拉框中的操作按钮-->
      <Dropdown transfer v-if="dropdownList.length>0 && isShowMoreBtn" id="dropDownBtn">
        <a href="javascript:void(0)" :style="{color: buttonColor}">
          <span>{{ $t('key1000091') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </a>
        <template #list>
          <DropdownMenu>
            <template v-for="(item, index) in dropdownList">
              <DropdownItem v-if="!item.customRender" :name="index" @click.native="item.clickFn">{{ item.text }}</DropdownItem>
              <RenderDom v-else :renderCustomContent="item.renderCustomContent" :paramsData="item.paramsData"></RenderDom>
            </template>
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import RenderDom from '@/utils/renderCustomContent'
import {isAlibabaDistributor} from "@/utils/common";

export default {
  name: "optButtonList",
  props: {
    // 对齐方式
    align: {
      type: String,
      default: 'flex-start',
    },
    // 操作栏按钮数据
    operationList: {
      type: Array,
      default: () => {
        return [
          /*{
            text: '', 按钮文本
            show: true,  是否展示按钮  true/false
            color: '', 按钮文本颜色
            clickFn: () => { 按钮的操作方法

            },
             customRender: true, // 开启自定义内容的render函数  true/false
             renderCustomContent: (h)=>{} // 自定义内容的render函数
          },*/
        ]
      }
    },
    className: {
      type: String,
      default: ''
    },
    // 是否展示更多按钮，默认超过三个按钮展示更多按钮，不是更多按钮时，是展示所有按钮
    isShowMoreBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // 列表按钮
    buttonList() {
      let list = this.operationList.filter((item) => item.show)
      let arr = [];
      if (list.length > 0) {
        // 处理删除按钮标红
        list.map((item) => {
          if (item.text === aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000092')) {
            item.color = '#FF0000';
          }
        })
        if (list.length <= 3) {
          arr = list.slice(0, 3)
        } else {
          arr = list.slice(0, 2)
        }
      }
      return this.isShowMoreBtn ? arr : list;
    },
    // 下拉框按钮
    dropdownList() {
      let list = this.operationList.filter((item) => item.show)
      let arr = [];
      if (list) {
        if (list.length > 3) {
          arr = list.slice(2)
        }
      }
      return arr;
    },
    // 处理按钮文本颜色
    buttonColor() {
      let textColor = '';
      let pathname = window.location.pathname;
      let clorList = [
        {pathname: '/yms.html', themeColor: '#2d8cf0'},
        {pathname: '/supplier.html', themeColor: '#2d8cf0'},
        {pathname: '/distributor.html', themeColor: '#FD5425'}
      ];
      clorList.map((item) => {
        if (pathname.includes(item.pathname)) {
          if (isAlibabaDistributor()) {
            textColor = clorList[0].themeColor;
          } else {
            textColor = item.themeColor;
          }
        }
      });
      return textColor
    },
    operationButtonStyle() {
      return this.isShowMoreBtn ? '' : 'operation_Button_style'
    }
  },
  methods: {
    // 动态设置操作栏的宽度
    handerWidth(data) {
      let v = this;
      let width = 0;
      let width1 = 0;
      let arr1 = [];
      let textList = [];
      if (data) {
        v.$nextTick(() => {
          let newData = data.filter((item) => item.show)
          newData.map((item) => {
            textList.push(item.text);
          });
          let operationButtonList = [...document.querySelectorAll("div[id='operationButtonList']")];
          if (operationButtonList && operationButtonList.length > 0) {
            operationButtonList.forEach((item) => {
              let arr = [];
              width1 = 0;
              // 计算下拉框的宽度
              if (textList.length > 3) {
                let dropDownList = item.parentElement.children;
                if (dropDownList && dropDownList.length > 1) {
                  arr.push(dropDownList[1].clientWidth)
                }
              }
              // 计算列表每个按钮的宽度
              let childrenList = [...item.children];
              if (childrenList && childrenList.length > 0) {
                childrenList.forEach((ele) => {
                  let text = ele.dataset.text;
                  if (textList.includes(text)) {
                    arr.push(ele.clientWidth)
                  }
                });
                if (arr.length > 0) {
                  let sum = arr.reduce((pre, cur) => {
                    return pre + cur;
                  }, 0)
                  arr1.push(Math.ceil(sum))
                }
              }
              if (arr1.length > 0) {
                width1 = Math.max(...arr1);
              }
            });
          }
          if (newData.length > 0) {
            width = width1 + 50;
            if (width !== 0) {
              v.$emit('setWidth', width)
            }
          }
        });
      }
    }
  },
  watch: {
    operationList: {
      handler(data) {
        let v = this;
        v.handerWidth(data);
      },
      deep: true,
      immediate: true
    },
    $route: {
      handler(data) {
        let v = this;
        v.optionBtnWidth = [];
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    RenderDom
  }
}
</script>

<style lang="less" scoped>
.operation_center {
  width: 100%;
  text-align: left;

  .operation_button_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .operation_button_list, .operation_Button_style {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .operation_button_item {
        margin: 0;
        padding: 2px 6px 2px 6px;
        background-color: transparent;
        cursor: pointer;
        display: inline-block;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .operation_Button_style {
      flex-direction: column;
      align-items: flex-start;
    }

    .ivu-dropdown a {
      padding: 2px 6px 2px 6px;

      &:hover {
        text-decoration: initial;
      }
    }
  }
}
</style>
