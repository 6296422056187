
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
/*封装系统中常用的公共方法*/
import axios from 'axios';
import api from '@/api/';
import proxyFile from "../../proxy";
import store from '@/store/store';
import {Message, Modal} from "view-design";
import {showBillConfirmed} from "@/utils/asist";


/**
 * @Description: 处理缓存到sessionStorage的数据
 * @author jing
 * @date 2024/6/6 上午10:23
 * @params:
 */
export const commonSessionStorage = {
  setItem(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  getItem(key) {
    const value = sessionStorage.getItem(key);
    // 尝试解析 JSON，如果失败则直接返回原值
    try {
      return value ? JSON.parse(value) : null;
    }
      // 如果解析失败，直接返回原值
    catch (e) {
      return value;
    }

    return value ? JSON.parse(value) : null;
  },
  removeItem(key) {
    sessionStorage.removeItem(key);
  }, clearAllItem() {
    sessionStorage.clear();
  }
}

/**
 * @Description: 处理缓存到localStorage的数据
 * @author jing
 * @date 2024/7/5 上午9:23
 * @params:
 */

export const commonLocalStorage = {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getItem(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  },
  removeItem(key) {
    localStorage.removeItem(key);
  }
}


// 处理获取指定的系统服务
function handleService(pathname) {
  let service = '';
  const serviceList = [{pathname: '/yms.html', service: 'yms-core-service'}, {
    pathname: '/supplier.html',
    service: 'yms-supplier-service'
  }, {pathname: '/distributor.html', service: 'yms-distributor-service'}];
  serviceList.map((item) => {
    if (pathname.includes(item.pathname)) {
      service = item.service;
    }
  });
  return service;
}

// 添加语言样式
export const addLangStyle = () => {
  let link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = './static/css/' + localStorage.getItem('lang') + '.css';
  document.getElementsByTagName('head')[0].appendChild(link);
}

/**
 * @Description:  根据userId批量获取用户信息
 * @author jing
 * @date 2023/12/13 10:46
 * @params:
 */
export const getUserInfoByUserIdsData = (userIds, v) => {
  return new Promise(resolve => {
    if (userIds.length > 0) {
      axios.post(api.get_userInfo_queryMapByUserIds, userIds).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.userInfoList = data;
            resolve(data);
          } else {
            resolve(false);
          }
        }
      })
    }
  });
}

/**
 * @Description:  根据userId匹配获取用户名称
 * @author jing
 * @date 2023/12/13 13:48
 * @params:
 */

export const getUserNameByUserId = (userId, v) => {
  let userName = '';
  let path = v.$route.path;
  let pathList = ['/supplierOrders', '/distributorStockList'];
  if (v.userInfoList) {
    if (v.userInfoList[userId] !== undefined) {
      userName = v.userInfoList[userId].userName;
    } else if (userId === aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000281')) {
      userName = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000281');
    } else if (pathList.includes(path)) {
      userName = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001224');
    } else {
      userName = '';
    }
    return userName;
  }
}

// 校验数值是否为空，不包括0
export const isValueEmpty = (value) => {
  return value === null || value === undefined || isNaN(value) || value === '';
}
// 校验字符串是否为空
export const isStringValueEmpty = (value) => {
  return value === null || value === undefined || value === '';
}

/**
 * 下载模板
 * @param templateName String 名称
 * @param type String  ['template','tool']
 *  例：https://admin-test.pvt.yms.com/yms-core-service/filenode/s/yms-core-service/template/attributeTemplate.xlsx
 */
export const downloadTemplate = (templateName, type = 'template') => {
  let pathname = window.location.pathname;
  let origin = window.location.origin;
  let talg = window.location.href.split('//');
  let link;
  let target = proxyFile['/'].target;
  let service = handleService(pathname);
  let path = window.location.href.split('/');
  let filenodes = store.state.erpConfig.filenodeViewTargetUrl;
  let filenodeViewTargetUrl = filenodes.split('.')[1] || filenodes.split(path[2])[1];
  if (talg[1].includes('localhost')) {
    link = `${target}/${service}${filenodeViewTargetUrl}/${service}/${type}/${templateName}`;
  } else {
    link = `${origin}/${service}${filenodeViewTargetUrl}/${service}/${type}/${templateName}`;
  }
  window.location.href = link;
}

// 处理图片的放大图片的样式(默认放大的图片最大的尺寸为300*300)
export const handlerImgSize = (imgWdith, imgHeight) => {
  let obj;
  let talgSize = 300;
  if (imgWdith || imgHeight) {
    if (imgWdith > imgHeight) {
      if (imgWdith > talgSize) {
        obj = {
          width: talgSize + 'px', height: 'auto', verticalAlign: 'middle'
        }
      } else {
        obj = {
          width: imgWdith + 'px', maxWidth: talgSize + 'px', height: 'auto', verticalAlign: 'middle'
        }
      }
    } else {
      if (imgHeight > talgSize) {
        obj = {
          width: 'auto', height: talgSize + 'px', verticalAlign: 'middle'
        }
      } else {
        obj = {
          width: 'auto', height: imgHeight + 'px', maxHeight: talgSize + 'px', verticalAlign: 'middle'
        }
      }
    }
  } else {
    obj = {
      width: '300px', height: 'auto', verticalAlign: 'middle'
    }
  }
  return obj;
}

/**
 * @Description: 根据菜单路由获取对应菜单的id，高亮显示菜单
 * @author jing
 * @date 2023/9/6 17:40
 * @params:
 */
export const getMenuRoutingId = (path) => {
  let id = 0;
  let menuData = localStorage.getItem('pagesPermissions') ? JSON.parse(localStorage.getItem('pagesPermissions')) : [];
  if (menuData.length > 0) {
    function childrenToList(list, result = []) {
      list.forEach(node => {
        result.push(node)
        if (node.children && node.children.length > 0) {
          childrenToList(node.children, result)
        }
      })
      return result
    }

    let newList = childrenToList(menuData);
    if (newList.length > 0) {
      newList.map((item) => {
        if (item.path === path) {
          id = item.id;
        }
      })
    }
  }
  return id;
}
// 前端数据的排序 （默认降序）desc  升序：asc
export const handerDataSorting = (property, type) => {
  return function (obj1, obj2) {
    let value1 = obj1[property];
    let value2 = obj2[property];
    return type === 'asc' ? value1 - value2 : value2 - value1;
  };
}

/**
 * @Description: 导入、导出下载文件
 * @author jing
 * @date 2022/5/25 15:33
 * @params: url 文件路径
 */
export const downloadTemplateFile = (url, type) => {
  let filenodes = store.state.erpConfig.filenodeViewTargetUrl;
  if (url.includes('https://') || url.includes('http://')) {
    window.open(url, type);
  } else {
    let pathname = window.location.pathname;
    let talg = window.location.href.split('//');
    let link = '';
    let target = proxyFile['/'].target;
    let service = handleService(pathname);
    let path = window.location.href.split('/');
    let filenodeViewTargetUrl = filenodes.split('.')[1] || filenodes.split(path[2])[1];
    if (talg[1].includes('localhost')) {
      link = `${target}/${service}${filenodeViewTargetUrl}${url}`;
      window.open(link, type);
    } else {
      window.open(filenodes + url, type);
    }
  }
}

/**
 * @Description: 前端自动拼接完整路径
 * @author jing
 * @date 2023/5/17 10:24
 * @params:
 */
export const setLinkPath = (path) => {
  if (path) {
    if (path.includes('http://') || path.includes('https://')) {
      return path;
    } else {
      let talg = window.location.href.split('//');
      let link = '';
      let target = proxyFile['/'].target;
      let system = localStorage.getItem('system');
      let service = '';
      let service_arr = [{type: 'yms', service: '/yms-core-service'}, {
        type: 'shopping',
        service: '/yms-core-service'
      }, {type: 'supplier', service: '/yms-supplier-service'}, {
        type: 'distributor',
        service: '/yms-distributor-service'
      },];
      service_arr.map((item) => {
        if (item.type === system) {
          service = item.service;
        }
      });
      if (store.state.erpConfig) {
        let filenodes = store.state.erpConfig.filenodeViewTargetUrl;
        let filenodeViewTargetUrl = filenodes.split('.')[1];
        if (talg[1].includes('localhost')) {
          link = target + service + filenodeViewTargetUrl + path;
          return link;
        } else {
          link = filenodes + path
          return link;
        }
      }
    }
  } else {
    return '';
  }
}

/**
 * @Description:
 * 1.是优先匹配当前需求中列举出来的对应快递code，跳转到对应的快递查询快递信息，
 * 2.如果匹配不上就会按照之前的需求，默认跳转到快递100进行查询（有code和快递单号的情况下）
 * 3.如果当前没有code和快递单号就是默认跳转到快递100按照顺丰根据运单号查询
 *
 * @params:
 * expressageCode 快递公司code
 * expressageNo 快递单号
 * trackingNumber 运单号
 */
export const jumpToTheCourierInquiry = (expressageCode, expressageNo, trackingNumber) => {
  // 快递100的地址
  const EXPRESS_100 = 'http://www.kuaidi100.com/chaxun';
  let courierLink = '';
  if (expressageCode && expressageNo) {
    axios.get(api.get_expressageHundred_query + '?type=kuaidi100').then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        if (data.length > 0) {
          data.map((item) => {
            if (item.code === expressageCode) {
              if (item.url) {
                // 优先匹配当前需求中列举出来的对应快递code，跳转到对应的快递查询快递信息
                courierLink = handleExpressLink(item.url, expressageNo);
              }
              // 如果匹配不上就会按照之前的需求，默认调转到快递100进行查询（有code和快递单号的情况下）
              else {
                courierLink = EXPRESS_100 + `?com=${expressageCode}&nu=${expressageNo}`
              }
              // 如果是 联昊通物流 需要自动复制单号
              if (['lianhaowuliu'].includes(expressageCode)) {
                let content = expressageNo || trackingNumber;
                commonCopyContent(content);
              }
            }
          });
          window.open(courierLink, '_blank');
        }
      }
    });
  }
  // 如果当前没有code和快递单号就是默认调转到快递100按照顺丰根据运单号查询
  else {
    let code = 'shunfeng';
    courierLink = EXPRESS_100 + `?com=${code}&nu=${trackingNumber}`;
    window.open(courierLink, '_blank');
  }

  function handleExpressLink(courierLink, expressageNo) {
    let link = '';
    let tagName = '${expressageNo}';
    if (courierLink) {
      if (courierLink.includes(tagName)) {
        link = courierLink.replace(tagName, expressageNo);
      } else {
        link = courierLink;
      }
    }
    return link;
  }
}

/**
 * @Description: 针对 InputNumber formatter 处理保留对应的小数点位数
 * @author jing
 * @date
 * @params: VauleNumber 数值， length 保留的小数点位数（默认保留两位小数）
 */
export const formatterNumber = (VauleNumber, length = 2) => {
  let newNumber = null;
  if (!isNaN(VauleNumber)) {
    if (VauleNumber >= 0) {
      let reg = /.*\..*/
      if (reg.test(VauleNumber) && String(VauleNumber).split(".")[1].length > length) {
        newNumber = parseFloat(VauleNumber).toFixed(length)
      } else {
        newNumber = VauleNumber
      }
    }
    return newNumber
  }
  return 0
};

/**
 * @Description: 获取打印控件的下载地址的公共方法
 * @author jing
 * @date 2021/8/25 10:01
 * @params: 例：https://admin-test.pvt.yms.com/yms-core-service/filenode/s/yms-core-service/tool/TongtoolPrinter.exe
 */
export const downloadControl = (fileName) => {
  return new Promise((resolve) => {
    let pathname = window.location.pathname;
    let talg = window.location.href.split('//');
    let link = '';
    let target = proxyFile['/'].target;
    let service = handleService(pathname);
    let path = window.location.href.split('/');
    let filenodes = store.state.erpConfig.filenodeViewTargetUrl;
    let filenodeViewTargetUrl = filenodes.split('.')[1] || filenodes.split(path[2])[1];
    if (talg[1].includes('localhost')) {
      link = `${target}/${service}${filenodeViewTargetUrl}/${service}${fileName}`;
    } else {
      link = `${filenodes}/${service}${fileName}`;
    }
    Modal.info({
      title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000260'), okText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000098'), render: (h, params) => {
        return h('div', {
          class: 'mt10 flex flex-wrap'
        }, [h('div', {class: 'font-size-15'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001225')), h('a', {
          attrs: {href: link, target: '_blank'}, class: 'font-weight-bold font-size-15 ml2 mr2',
        }, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001226')), h('div', {class: 'font-size-15'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001227'))]);
      }, onOk: () => {
        resolve(true);
      }
    });
  });
}


/**
 * @Description: 运营系统---校验拣货单中出库单状态
 * @author jing
 * @date 2022/6/27 17:34
 * @params: url：接口  ymsPickingOrderIds：拣货单流水号
 */
export const ymsVerifyPicking = (url, ymsPickingOrderIds) => {
  return new Promise((resolve => {
    let query = {
      ymsPickingOrderIds: ymsPickingOrderIds
    }
    axios.put(url, query).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        resolve(data);
      }
    });
  }));
}

/**
 * @Description: 供应商系统---校验拣货单中出库单状态
 * @author jing
 * @date 2022/6/29 16:40
 * @params: url：接口  pickingGoodsNos：拣货单编号
 */
export const supplierVerifyPicking = (url, pickingGoodsNos) => {
  return new Promise((resolve => {
    let query = {
      pickingGoodsNos: pickingGoodsNos
    }
    axios.put(url, query).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        resolve(data);
      }
    });
  }));
}

/**
 * @Description: 判断当前广告类型跳转到指定的链接地址
 * @author jing
 * @date 2022/7/25 17:41
 * @params: data 当前的广告数据源
 */
export const advertisingLinkAddress = (data) => {
  if (data) {
    let jumpTargetType = data.jumpTargetType;
    // 活动
    if (jumpTargetType === 0) {
      pageJump(`/index.html#/shopList?activityTitle=${data.jumpTargetName}&ymsActivityPlanId=${data.jumpTargetValue}`, '/yms-shopping-service', '_blank');
    }
    // 分类
    else if (jumpTargetType === 2) {
      pageJump(`/index.html#/shopList?ymsProductCategoryId=${data.jumpTargetValue}`, '/yms-shopping-service', '_blank');
    }
    // 指定链接
    else if (jumpTargetType === 1) {
      let link = data.jumpTargetValue;
      window.open(link, '_blank');
    }
  }
}

/**
 * @Description: 页面之间的跳转方法
 * @author jing
 * @date 2021/4/26 10:18
 * @params:
 * url：跳转的链接地址
 * service：跳转到的服务
 * type：窗口打的方式 （_self：在当前窗口打开  _blank 在新窗口打开）
 * links 重定向的链接地址
 */

export const pageJump = (url, service, type, links) => {
  let talg = window.location.href.split('//');
  let tagLink = links ? links : '';
  if (talg[1].includes('localhost')) {
    let link = `${talg[0]}//${window.location.host}${url}` + tagLink;
    window.open(link, type);
  } else {
    let shoppingInfo = store.state.shoppingInfo;
    let link = `${service + url}` + tagLink;
    if (shoppingInfo && shoppingInfo.shoppingDomain) {
      let origin = shoppingInfo.shoppingDomain.replace(/\.com.*/, '.com');
      link = origin + link;
    }
    window.open(link, type);
  }
};

/**
 * 组装访问地址
 * @date: 2022/3/31 14:03
 * url：跳转的链接地址
 * service：跳转到的服务
 * type：窗口打的方式 （_self：在当前窗口打开  _blank 在新窗口打开
 */
export const getHrefPath = (url, service, links) => {
  let talg = window.location.href.split('//');
  let tagLink = links ? links : '';
  if (talg[1].includes('localhost')) {
    return `${talg[0]}//${window.location.host}${url}`;
  } else {
    let link = `${service + url}` + tagLink;
    let shoppingInfo = store.state.shoppingInfo;
    if (shoppingInfo && shoppingInfo.shoppingDomain) {
      let origin = shoppingInfo.shoppingDomain.replace(/\.com.*/, '.com');
      link = origin + link;
    }
    return link;
  }
};

/**
 * @Description:
 * 跨系统跳转链接的时候，会存在访问权限问题，所以在跳转的时候，需要指定完整的链接地址
 * 在不同的环境下，配置各个系统链接地址
 * @author jing
 * @date 2023/6/27 14:23
 * @params:
 * url 跳转的链接地址
 * system 跳转到指定的系统
 */

export const crossSystemJumpLink = (url, system) => {
  let link;
  let obj;
  let origin = window.location.origin;
  if (origin.includes('localhost')) {
    let talg = window.location.href.split('//');
    let url = `${talg[0]}//${window.location.host}`;
    obj = {
      yms: url, supplier: url, distributor: url
    }
  } else if (origin.includes('dev')) {
    obj = {
      yms: 'http://newerp-dev.tongtool.com',
      supplier: 'http://newerp-dev.tongtool.com',
      distributor: 'http://newerp-dev.tongtool.com'
    }
  } else if (origin.includes('test')) {
    obj = {
      yms: 'https://admin-test.pvt.yms.com',
      supplier: 'https://supplier-test.pvt.yms.com',
      distributor: 'https://www-test.pvt.yms.com'
    }
  } else if (origin.includes('uat')) {
    obj = {
      yms: 'https://admin-uat.pvt.yms.com',
      supplier: 'https://supplier-uat.pvt.yms.com',
      distributor: 'https://www-uat.pvt.yms.com'
    }
  } else {
    obj = {
      yms: 'https://admin.yms.com', supplier: 'https://supplier.yms.com', distributor: 'https://www.yms.com'
    }
  }
  link = obj[system] + url;
  return link;
}

export const getQueryString = name => {
  // 获取url里面的参数
  let reg = new RegExp('(^|&?)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.href.substr(1).match(reg);
  if (r !== null) {
    return unescape(r[2]);
  } else {
    return null;
  }
};

/**
 * @Description: 删除ticket参数@
 * @author gan
 * @date: 2020/9/27 11:21
 * @params: url
 */
export const deleteTicket = (url) => {
  if (url.includes('ticket')) {
    let arr = url.split('?');
    let params = arr[1].split('&');
    let paramsR = params.filter(i => {
      if (!i.includes('ticket') && i) {
        return i;
      }
    });
    if (paramsR && paramsR.length > 0) {
      return arr[0] + '?' + paramsR.join('&');
    } else {
      if (arr[0][arr[0].length - 1] === '/') {
        return arr[0].slice(0, -1);
      } else {
        return arr[0];
      }
    }
  } else {
    return url;
  }
};

/**
 * @Description:  校验登录的时候才提示弹窗
 * @author jing
 * @date 2023/5/31 15:49
 * @params:
 */

export const verifyLoginTicket = () => {
  return new Promise(resolve => {
    let talg = false;
    if (window.location.href.includes('ticket=')) {
      let newTicket = getQueryString('ticket');
      let old = commonSessionStorage.getItem('oldTicket');
      talg = newTicket !== old;
      if (talg) {
        // 登录系统成功之后，延迟30秒将新的ticket存入sessionStorage中
        setTimeout(() => {
          commonSessionStorage.setItem('oldTicket', newTicket)
        }, 30 * 1000);
      }
    }
    resolve(talg);
  });
}

// 查询商城中正在进行中的活动
export const getOngoingActivities = (type) => {
  let href = window.location.href;
  let ongoingActivitiesData = store.state.ongoingActivitiesData || commonSessionStorage.getItem('ongoingActivitiesData');

  function _getData(url) {
    return new Promise((resolve) => {
      let list = [{activityTitle: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000069'), activityType: 'all'}, {activityTitle: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000991'), activityType: 'news'}];
      let config = {
        headers: {
          'Language': localStorage.getItem('setLanguages')
        }
      }
      axios.get(url, config).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          data.map((item) => {
            item.activityType = item.ymsActivityPlanId;
          });
          let newList = [...list, ...data];
          store.commit('ongoingActivitiesData', newList);
          commonSessionStorage.setItem('ongoingActivitiesData', newList);
          resolve(newList)
        }
      });
    });
  }

  return new Promise((resolve, reject) => {
    if (type === 'distributor') {
      // 判断如果是在登录成功或者缓存的数据已经失效的情况重新调接口获取数据
      if (href.includes('ticket=') || !ongoingActivitiesData) {
        _getData(api.get_erpCommon_ymsActivityPlan_shopping).then((data) => {
          resolve(data);
        });
      } else {
        let data = JSON.parse(JSON.stringify(ongoingActivitiesData));
        resolve(data);
      }
    } else {
      _getData(api.get_ymsActivityPlan_shopping).then((data) => {
        resolve(data);
      });
    }
  });
}

// 查询商城中已经启用的广告
export const getMallAdvertising = (type) => {
  let href = window.location.href;
  let mallAdvertising = store.state.mallAdvertising || commonSessionStorage.getItem('mallAdvertising');

  function _getData(url) {
    return new Promise((resolve) => {
      let config = {
        headers: {
          'Language': localStorage.getItem('setLanguages')
        }
      }
      axios.get(url, config).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          store.commit('mallAdvertising', data);
          commonSessionStorage.setItem('mallAdvertising', data);
          resolve(data);
        }
      });
    });
  }

  return new Promise((resolve, reject) => {
    if (type === 'distributor') {
      // 判断如果是在登录成功或者缓存的数据已经失效的情况重新调接口获取数据
      if (href.includes('ticket=') || !mallAdvertising) {
        _getData(api.get_erpCommon_ymsAdvertisement_shopping).then((data) => {
          resolve(data);
        });
      } else {
        let data = JSON.parse(JSON.stringify(mallAdvertising));
        resolve(data);
      }
    } else {
      _getData(api.get_ymsAdvertisement_shopping).then((data) => {
        resolve(data);
      });
    }
  });
}

// 获取币种的基础数据
export const getCurrencyData = () => {
  return new Promise(resolve => {
    let newData = [];
    if (localStorage.getItem('currencyData')) {
      newData = JSON.parse(localStorage.getItem('currencyData'));
    }
    if (newData.length > 0) {
      resolve(newData);
    } else {
      axios.get(api.get_currencyInfo + '?time=' + new Date().getTime()).then(response => {
        let data = response.data.datas;
        if (data) {
          localStorage.setItem('currencyData', JSON.stringify(data));
          resolve(data);
        }
      });
    }
  });
}

/**
 * @Description: 校验当前的字符串是否json格式的字符串
 * @author jing
 * @date 2023/2/17 14:45
 * @params:
 */
export const isJSON = (str) => {
  if (typeof str == 'string') {
    try {
      let obj = JSON.parse(str);
      return !!(typeof obj == 'object' && obj);
    } catch (e) {
      return false;
    }
  }
}

// 处理url参数
export const qs = (params) => {
  let str = '';
  for (let key in params) {
    str += key + '=' + params[key] + '&';
  }
  return str.slice(0, -1);
};

/**
 * 金额加法计算
 * @date: 2022/3/17 10:07
 * @param args 需要计算的金额
 */
export const amountAddition = (...args) => {
  let arr = [];
  let dotLength = 0;
  args.forEach(i => {
    if (i) {
      if (!isNaN(Number(i)) && i.toString().includes('.') && Math.pow(10, i.toString().split('.')[1].length) > dotLength) {
        dotLength = Math.pow(10, i.toString().split('.')[1].length);
      }
    }
  });
  args.forEach(i => {
    if (i) {
      if (dotLength > 0) {
        arr.push(i * dotLength);
      } else {
        arr.push(i);
      }
    }
  });
  let amount = arr.reduce((a, b) => {
    return a + b;
  }, 0);
  if (dotLength > 0) {
    return (amount / dotLength).toFixed(2);
  } else {
    return amount.toFixed(2);
  }
};

/**
 * 金额处理全局函数
 * @date: 2022/3/17 9:13
 * @param amount 金额 string number
 * @param number 保留的位数
 */
export const amountHandel = (amount, number = 2) => {
  if (isNaN(Number(amount))) {
    return 0;
  } else {
    return Number(amount).toFixed(number);
  }
};

/**
 * 菜鸟打印
 * @param   postID  packageId
 * @param  cainiaoData 打印内容
 * */
export const cainiaoPrint = (websocket, postID, cainiaoData) => {
  return new Promise(resolve => {
    websocket.onmessage = function (event) {
      let data = JSON.parse(event.data);
      let status = data['status'];
      if (typeof status !== 'undefined' && status === 'success') {
        resolve(data);
      } else if (typeof status !== 'undefined') {
      }
    };
    let arr = cainiaoData.split('|');
    let contents = [];
    for (let i = 0; i < arr.length; i++) {
      contents.push(JSON.parse(arr[i]));
    }
    let content = {
      cmd: 'print', requestID: new Date().getTime() + '', // 随机数
      version: '1.0', task: {
        taskID: new Date().getTime() + '', // 随机数
        preview: false, documents: [{
          contents: contents, documentID: postID // postID
        }]
      }
    };
    // 连接成功建立的回调方法
    websocket.send(JSON.stringify(content));
  });
};

// 判断当前浏览器是否支持WebSocket
export const initCainiao = () => {
  let websocket;
  if ('WebSocket' in window) {
    websocket = new WebSocket('ws://127.0.0.1:13528');
  } else {
    Message.info(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001228'));
  }
  // 连接发生错误的回调方法
  websocket.onerror = function () {
    Message.info(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001229'));
  };
  return new Promise(resolve => {
    websocket.onopen = function () {
      resolve(websocket);
    };
  });
};

/**
 * 获取供应商id
 * @date: 2021/6/8 15:30
 */
export const getDistributorId = (domain) => {
  return new Promise(resolve => {
    domain = domain.includes('localhost') ? 'https://shop000817-test.pvt.yms.com' : domain;
    axios.get(api.get_ymsSupplierMerchant_getYmsMerchantByDomain + '?supplierWebDomain=' + domain).then((response) => {
      if (response.data.code === 0) {
        store.commit('distributorInfo', response.data.datas);
        localStorage.setItem('distributorInfo', JSON.stringify(response.data.datas))
        resolve(true);
      }
    });
  });
};

// 数据埋点
export const webDataCollectionFn = () => {
  if (!window.webDataCollection) {
    console.error('webDataCollection is undefined');
    return;
  }
  let _mid = null;
  let _uid = null;
  if (store.state.erpConfig) {
    _mid = store.state.erpConfig.userInfo.merchantId;
    _uid = store.state.erpConfig.userInfo.userId;
  }
  let reporter = new webDataCollection({
    bid: 'yms', // bid系统标识
    mid: _mid, // merchantId
    uid: function () {
      return _uid; // userId
    }, excludeUrl: [], //不需要上报的API地址，API接口地址包含以下内容将不会上报数据
    plugins: [[wpkinterfacePlugin, {
      withBody: true // 是否上报请求的body参数
    }]]
    //,beforeSend : function(logData) {
    // 根据自身业务和数据，判断是否要上报
    //return true;
    //},
  });
  reporter.install();
}

/**
 * 临时方案 匿名接口获取 isMultiSpecificationAttributeMerchant
 * true 为默认版本  false 为sass版本
 * */
export const getMultiSpecificationAttributeMerchantConfig = () => {
  let _isMultiSpecificationAttributeMerchant = store.state._isMultiSpecificationAttributeMerchant;
  if (_isMultiSpecificationAttributeMerchant) {
    store.commit('_isMultiSpecificationAttributeMerchant', _isMultiSpecificationAttributeMerchant);
    webDataCollectionFn();
  } else {
    axios.post(api.post_ymsDistributorCommon_loadConfig).then((response) => {
      if (response.data.code === 0) {
        let data = response.data.datas;
        if (data) {
          let isMultiSpecificationAttributeMerchant = data.isMultiSpecificationAttributeMerchant === 'N';
          store.commit('_isMultiSpecificationAttributeMerchant', isMultiSpecificationAttributeMerchant);
          webDataCollectionFn();
        }
      }
    });
  }
};

// 处理退出异常url ?#
export const errorUrlHandel = (url) => {
  return url.includes('?#') ? url.replace('?#', '#') : url;
};

/**
 * 获取商城地址
 * @date: 2021/6/8 15:29
 */
export const getShopDomain = () => {
  return new Promise(resolve => {
    axios.get(api.get_ymsCommon_shopping_baseInfo).then((response) => {
      if (response.data.code === 0) {
        store.commit('shoppingInfo', response.data.datas);
        resolve(true);
      }
    });
  });
};

// 判断平台是否开通服装大类
export const openClothingCategories = () => {
  return new Promise((resolve, reject) => {
    let openClothingCategories = commonSessionStorage.getItem('openClothingCategories') || store.state.openClothingCategories;
    if (openClothingCategories) {
      store.commit('openClothingCategories', openClothingCategories);
      resolve(openClothingCategories);
    } else {
      axios.get(api.get_ymsProductCategory_checkIsApparelCategory).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          store.commit('openClothingCategories', data);
          commonSessionStorage.setItem('openClothingCategories', data)
          resolve(data);
        } else {
          resolve(false);
        }
      });
    }
  });
};

// 判断当前供应商系统中是否存在待确认的账单
export const isBillConfirmed = (to) => {
  let talg = false;
  if (Object.keys(to.query).length > 0) {
    if (to.query.ticket) {
      let newTicket = to.query.ticket;
      let old = commonSessionStorage.getItem('oldTicket');
      talg = newTicket !== old
      setTimeout(() => {
        let isAdmin = store.state.isAdmin;
        let roleData = store.state.roleData;
        if (isAdmin || roleData.includes('supplier_reconciliationManage')) {
          // 只有在登录进来的时候，才调用
          if (window.location.href.includes('ticket=') && talg) {
            axios.get(api.get_ymsSupplierBill_waitConfirmSupplierBill).then(response => {
              if (response.data.code === 0) {
                let data = response.data.datas;
                commonSessionStorage.setItem('oldTicket', newTicket);
                if (data && data.length > 0) {
                  showBillConfirmed();
                }
              }
            });
          }
        }
      }, 1000);
    }
  }
}


/**
 * @Description: 根据ymsLogisServiceId批量获取云卖物流信息
 * @author jing
 * @date 2021/10/28 10:31
 * @params:
 */
export const getYmsLogisServiceData = (ymsLogisServiceIdsList) => {
  return new Promise(resolve => {
    axios.post(api.post_ymsLogisService_queryByIds, ymsLogisServiceIdsList).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        resolve(data);
      } else {
        resolve(true);
      }
    });
  });
}

// 自有物流渠道适用范围数据
export const getOwnLogisticsChannel = () => {
  return new Promise(resolve => {
    axios.get(api.get_ymsCarrier_queryDistributorEnableCarrier).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        resolve(data);
      }
    });
  });
}

// 处理平台图片、logo数据
export const handleImagesData = (data) => {
  if (data.length > 0) {
    data.map((item) => {
      let images = item.images || [];
      if (images.length > 0) {
        images.map((ele) => {
          switch (ele.size) {
            case '16x16':
              item.iconPath = ele.imagePath;
              break;
            case '107x42':
              item.logoPath = ele.imagePath;
              break;
          }
        })
      }
    });
  }
  return data;
}

/**
 * @Description: 根据币种显示具体的 默认返回 币种+币种名称
 * @author jing
 * @date 2022/12/13 9:25
 * @params: currency 币种   value 需要展示的字段
 */
export const currencyNameCode = (currency, value) => {
  let currencyData = [];
  let currencyName = '';
  if (localStorage.getItem('currencyData')) {
    currencyData = JSON.parse(localStorage.getItem('currencyData'));
  }
  if (currencyData.length > 0) {
    currencyData.map((item) => {
      if (item.currencyCode === currency) {
        if (value) {
          currencyName = item[value]
        } else {
          currencyName = item.currencyCnName + ' ' + item.currencyCode
        }
      }
    });
  }
  return currencyName;
}

/**
 * @Description: 根据仓库id获取库区数据
 * @author jing
 * @date 2024/5/7 16:55
 * @params: ymsWarehouseId 仓库id
 * @params: system 系统标识  yms 运营系统  distributor 分销系统  supplier 供应商系统
 */

export const getYmsWarehouseBlockList = (ymsWarehouseId, system = 'yms') => {
  return new Promise((resolve) => {
    let url = '';
    switch (system) {
      case 'yms':
        url = api.get_ymsWarehouseBlock_queryByYmsWarehouesId
        break;
      case'supplier':
        url = api.get_ymsWarehouseBlock_api_queryByYmsWarehouseId
        break;
    }
    if (ymsWarehouseId) {
      axios.get(url + `?ymsWarehouseId=${ymsWarehouseId}`).then(response => {
        if (response.data.code === 0) {
          let newList = response.data.datas || [];
          resolve(newList);
        }
      });
    }
  });
}

/**
 * @Description: 获取运营商信息配置
 * @author jing
 * @date 2024/5/24 上午11:39
 * @params:
 */

export const getYmsInfoConfig = () => {
  return new Promise((resolve) => {
    let businessCode = commonLocalStorage.getItem('businessCode');
    if (businessCode) {
      commonLocalStorage.setItem('businessCode', businessCode);
      resolve(businessCode);
    } else {
      axios.get(api.get_ymsDistributorMerchant_api_queryYmsCoreMerchant).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            businessCode = data.businessCode;
            commonLocalStorage.setItem('businessCode', businessCode);
            resolve(businessCode);
          }
        }
      });
    }
  });
}

/**
 * @Description: 默认将系统中除了公司名称将所有‘云卖’字符统一替换为后台配置的 ‘YMS’
 * @author jing
 * @date 2024/5/24 下午1:49
 * @params:
 */

export const setConfigCharacters = commonLocalStorage.getItem('businessCode') || 'YMS';

/**
 * @Description:  判断当前商户是否是阿里国际分销商
 * @author jing
 * @date 2024/6/11 下午3:23
 * @params:
 */
export const isAlibabaDistributor = () => {
  let talg = false;
  let obj = commonSessionStorage.getItem('erpConfig');
  if (obj) {
    let ymsSite = obj.ymsSite || null;
    if (ymsSite) {
      talg = ymsSite.merchantType === 'ALIGJ'
    }
  }
  commonSessionStorage.setItem('isAlibabaSite', talg);
  window.dispatchEvent(new Event('isAlibabaSiteUpdated'));
  return talg;
}

/**
 * @Description: 是阿里国际分销，需要过滤掉相关菜单
 * @author jing
 * @date 2024/6/4 上午9:35
 * @params:
 */

export const hideAlibabaDistributorsMenuData = (menuData) => {

  function handleData(menuData, distributorType) {
    const result = [];
    for (let i = 0; i < menuData.length; i++) {
      const item = menuData[i];
      let newItem = {...item};
      if (newItem.children && newItem.children.length > 0) {
        newItem.children = handleData(newItem.children, distributorType); // 递归处理 children
        if (newItem.children.length > 0) {
          result.push(newItem);
        }
      } else if (newItem.distributorType && newItem.distributorType.includes(distributorType)) {
        result.push(newItem);
      }
    }
    return result;
  }

  if (isAlibabaDistributor()) {
    return handleData(menuData, 'ALIGJ');
  } else {
    return handleData(menuData, 'YMS');
  }
}
/**
 * @Description: 获取运营系统所有站点的数据
 * @author jing
 * @date 2024/6/11 上午10:42
 * @params:
 */

export const getYmsSiteData = (query) => {
  return new Promise((resolve) => {
    let system = localStorage.getItem('system');
    let url;
    if (query) {
      url = api.get_ymsSite_api_query + query;
    } else {
      url = system === 'yms' ? api.get_ymsSite_queryAll : api.get_ymsSite_api_query
    }
    axios.get(url).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        resolve(data);
      }
    });
  })
}

/**
 * @Description: 复制内容的公共方法
 * @author jing
 * @date 2024/6/21 下午2:15
 * @params:
 */

export const commonCopyContent = (content, text) => {
  return new Promise(resolve => {
    let oInput = document.createElement('textarea');
    oInput.value = content;
    document.body.appendChild(oInput);
    oInput.select();
    window.document.execCommand('Copy');
    oInput.remove();
    if (text) {
      Message.success(text);
    }
    resolve();
  });
}

/**
 * @Description: 通过后缀判断是否是图片
 * @author jing
 * @date 2023/8/9 9:24
 * @params: fileName 文件/链接路径
 */

export const isImageFile = (fileName) => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg', '.ico'];
  const fileExtension = fileName.toLowerCase().substr(fileName.lastIndexOf('.'));
  return imageExtensions.includes(fileExtension);
}

/**
 * @Description: 通过域名获取当前站点信息
 * @author jing
 * @date 2024/7/4 上午9:56
 * @params:
 */

export const getDomainSiteInfo = (domainName) => {
  return new Promise((resolve) => {
    let query = null;
    if (domainName) {
      let hostname = domainName.split('https://')[1] || domainName.split('http://')[1];
      query = `?siteDomain=${hostname}`;
    } else {
      let hostname = window.location.hostname;
      let talg = window.location.href.split('//');
      if (talg[1].includes('localhost')) {
        let target = proxyFile['/'].target;
        let domainName = target.split('https://')[1] || target.split('http://')[1];
        let testHostname = domainName.replace(/\/$/, '');
        query = `?siteDomain=${testHostname}`
      } else {
        query = `?siteDomain=${hostname}`
      }
    }
    getYmsSiteData(query).then(data => {
      let list = data || [];
      resolve(list);
    })
  })
}

/**
 * @Description: 获取当前系统配置的域名数据
 * @author jing
 * @date 2025/1/14 17:03
 * @params:
 */

export const getSystemDomainNameData = () => {
  return new Promise((resolve) => {
    axios.get(api.get_ymsBase_info_query).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas;
        resolve(data);
      }
    });
  });
}


/**
 * @Description: 根据分类id批量查询类目信息
 * @author jing
 * @date 2024/6/21 下午3:24
 * @params:
 * originalData 列表数据
 * url 请求接口
 * ymsProductCategoryIds 分类id集合
 * returnTalg 是否需要返回原始数据，false 默认不返回，true返回原始数据
 */

export const getBatchCategoryInfoData = (url, originalData, ymsProductCategoryIds, returnTalg = false) => {
  return new Promise((resolve) => {
    axios.post(url, ymsProductCategoryIds).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        if (returnTalg) {
          resolve(data);
        } else {
          originalData.map((item) => {
            item['classificationName'] = '-';
            data.map((ele) => {
              if (item.ymsProductCategoryId === ele.ymsProductCategoryId) {
                let txt = ele.productCategoryNavigation.split('->');
                let name = txt.join(' / ');
                item['classificationName'] = name;
              }
            })
          });
          resolve(originalData);
        }
      }
    });
  })
}

/**
 * @Description: 根据商户号、用户id获取ticket的公共方法
 * @author jing
 * @date 2024/6/24 下午3:27
 * @params: userId、merchantId
 */

export const getCommonTicket = (query, value) => {
  return new Promise(resolve => {
    axios.post(api.post_createTicket, query, {loading: value}).then((response) => {
      if (response.data.code === 0) {
        resolve(response.data.datas);
      }
    });
  });
}

/**
 * @Description: 处理上传图片的地址
 * @author jing
 * @date 2024/7/15 下午1:39
 * @params: 例：http://admin-test.pvt.yms.com/yms-supplier-service/filenode/s/temp/yms-supplier-service/000815/2024-07-15/b3505bd690bb4628bd6c92d06aaf5e23.jpg
 */

export const handleUploadImageAddress = (tempUrl) => {
  let link = tempUrl;
  let service = '';
  let service_arr = [{type: 'yms', service: '/yms-core-service'}, {
    type: 'shopping',
    service: '/yms-core-service'
  }, {type: 'supplier', service: '/yms-supplier-service'}, {type: 'distributor', service: '/yms-distributor-service'}];
  let system = localStorage.getItem('system');
  service_arr.map((item) => {
    if (item.type === system) {
      service = item.service;
    }
  });
  let target = proxyFile['/'].target;
  let filenodeViewTargetUrl = '/filenode/s';
  let origin = window.location.origin;
  let talg = window.location.href.split('//');
  if (talg[1].includes('localhost')) {
    link = target + service + filenodeViewTargetUrl + tempUrl;
  } else {
    link = origin + service + filenodeViewTargetUrl + tempUrl;
  }
  return link;
}

/**
 * @Description: base64 转 Blob 对象
 * @author jing
 * @date 2024/7/15 下午2:54
 * @params:
 */
export const base64ToBlob = (base64) => {
  return new Promise((resolve, reject) => {
    try {
      const byteCharacters = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const mime = base64.split(',')[0].split(':')[1].split(';')[0];
      const blob = new Blob([byteArray], {type: mime});
      resolve(blob);
    } catch (error) {
      reject(error);
    }
  })
}

/**
 * @Description:
 * @author jing
 * @date 2024/7/26 上午9:12
 * @params:
 */

export const base64ToFile = (base64, fileName) => {
  return base64ToBlob(base64).then((blob) => {
    // 获取文件类型
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    // 创建一个File对象
    const file = new File([blob], fileName, {type: mimeString});
    return file;
  })
}

/**
 * @Description: 查询商户下拥有所有授权仓库的用户
 * @author jing
 * @date 2024/7/23 上午9:41
 * @params:
 */

export const getYmsUserWarehouseOpersUserName = (userId) => {
  return new Promise(resolve => {
    axios.get(api.get_ymsCoreUserInfo_getYmsUserWarehouseOpers + userId).then((response) => {
      if (response.data.code === 0) {
        let data = response.data.datas;
        resolve(data);
      }
    });
  });
}

/**
 * @Description: 查询商户是否配置了pod工厂
 * @author jing
 * @date 2024/12/9 19:30
 * @params:
 */

export const getMerchantConfigurationFactory = (userInfo) => {
  let paramKey = 'productGoodsFactory';
  let value = null;
  return new Promise(resolve => {
    let query = {
      paramKeys: [paramKey],
      businessId: userInfo.merchantId
    }
    axios.post(api.post_erpCommonSettingParam_queryByUK, query).then((response) => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        if (data.length > 0) {
          data.map((item) => {
            if (item.paramKey === paramKey) {
              value = item.paramValue;
            }
          })
        }
        resolve(value);
      }
    });
  });
}
/**
 * @Description: 获取商品标签所有数据
 * @author jing
 * @date 2024/12/25 14:13
 * @params:
 */
export const getAllProductLabelData = () => {
  return new Promise(resolve => {
    let system = localStorage.getItem('system');
    let url = '';
    if (system === 'yms') {
      url = api.get_ymsProductTag_queryAllList;
    } else {
      url = api.get_ymsProductTag_api_queryProductTagList;
    }
    axios.get(url).then((response) => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        data.map((item) => {
          let obj = JSON.parse(item.name);
          for (let key in obj) {
            item['productLabel_' + key] = obj[key];
          }
        })
        resolve(data);
      }
    });
  });
}
/**
 * @Description: 账户余额不足的相关提示
 * @author jing
 * @date 2024/12/25 14:14
 * @params:
 */
export const insufficientAccountBalance = (v) => {
  let distributorParamKeysSetting = commonLocalStorage.getItem('distributorParamKeysSetting') || store.state.distributorParamKeysSetting;
  let onlineBalanceRecharge = distributorParamKeysSetting?.onlineBalanceRecharge || false;
  if (onlineBalanceRecharge) {
    Modal.confirm({
      title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001230'),
      content: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001231'),
      okText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001232'),
      cancelText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000098'),
      onOk: () => {
        const {href} = v.$router.resolve({
          name: 'RechargeRecord'
        });
        localStorage.setItem('openRechargeTalg', JSON.stringify(true));
        window.open(href, '_blank');
      },
      onCancel: () => {
      }
    })
  } else {
    Modal.warning({
      title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001230'),
      content: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001233'),
      okText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000139'),
    })
  }
}
/**
 * @Description: 商城获取商品设置数据
 * @author jing
 * @date 2024/12/25 14:14
 * @params:
 */
export const getProductSettings = () => {
  return new Promise((resolve) => {
    let obj = {};
    axios.get(api.get_ymsProductSetUp_api_queryDistributorMallShowSetupInfo).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        if (data.length > 0) {
          data.map((item) => {
            obj[item.paramKey] = Number(item.paramValue);
          });
          store.commit('productSettings', obj);
          commonSessionStorage.setItem('productSettings', obj);
        }
        resolve(true)
      }
    });
  });
}

/**
 * @Description: 通过paramKeys获取对应paramValue设置的公共接口
 * @author jing
 * @date 2024/12/25 14:18
 * @params:
 * businessId: 支持用户级别、商户级别
 * paramKeys：对应设置的key
 * isParamValue：是否直接返回paramValue值
 */

export const getCommonParamKeysData = (businessId, paramKeys, isParamValue = false) => {
  return new Promise(resolve => {
    let query = {
      businessId: businessId,
      paramKeys: paramKeys
    }
    let system = localStorage.getItem('system');
    let url = system === 'yms' ? api.post_erpCommonSettingParam_queryByUK : api.post_erpCommonSettingParam_api_queryByUK;
    axios.post(url, query, {loading: true}).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        if (isParamValue) {
          if (data.length > 0) {
            data.map((item) => {
              if (paramKeys.includes(item.paramKey)) {
                resolve(item.paramValue);
              }
            })
          } else {
            resolve(false);
          }
        } else {
          resolve(data);
        }
      }
    });
  });
}

/**
 * 压缩图片 base64
 * */
export function compressImage(file, quality = 0.7) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const maxWidth = 100;
        const maxHeight = 100;
        let width = img.width;
        let height = img.height;

        if (width > height && width > maxWidth) {
          height = (maxWidth / width) * height;
          width = maxWidth;
        } else if (height > width && height > maxHeight) {
          width = (maxHeight / height) * width;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const base64Data = canvas.toDataURL(file.type, quality);
        resolve(base64Data);
      };
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

/**
 * @Description:获取所有支付渠道数据
 * @author jing
 * @date 2025/1/17 13:52
 * @params:
 */

export const getPaymentChannelsData = (status) => {
  return new Promise(resolve => {
    let url = api.get_ymsReceivePayAccount_queryAll;
    if (status) {
      url = url + status;
    }
    axios.get(url).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        resolve(data);
      }
    });
  });
}

/**
 * @Description: 支付币种转换
 * @author jing
 * @date 2025/1/21 14:03
 * @params:
 */

export const getCurrencyConversion = (query) => {
  return new Promise(resolve => {
    axios.post(api.post_ymsCommon_api_conversionPayPrice, query).then((response) => {
      if (response.data.code === 0) {
        let data = response.data.datas;
        resolve(data);
      }
    });
  });
}

/**
 * @Description: 通过paramKeys自定义设置获取对应paramValue设置的公共接口
 * @author jing
 * @date 2024/12/25 14:18
 * @params:
 * businessId: 支持用户级别、商户级别
 * paramKeys：对应设置的key
 * isParamValue：是否直接返回paramValue值
 */

export const getqueryByParamKeysData = (query, paramKeys, isParamValue = false) => {
  let system = localStorage.getItem('system');
  return new Promise(resolve => {
    let url = system === 'yms' ? api.post_erpCommonSettingParam_queryByParam : api.post_erpCommonSettingParam_api_queryByParam;
    axios.post(url, query, {loading: true}).then(response => {
      if (response.data.code === 0) {
        let data = response.data.datas || [];
        if (isParamValue) {
          if (data.length > 0) {
            data.map((item) => {
              if (paramKeys.includes(item.paramKey)) {
                resolve(item.paramValue);
              }
            })
          } else {
            resolve(false);
          }
        } else {
          resolve(data);
        }
      }
    });
  });
}

/**
 * @Description: 处理所有国家数据按照洲区分类
 * @author jing
 * @date 2025/3/11 16:57
 * @params:
 */

export const handleCountryContinentData = (countries) => {
  const continentMapping = {
    Oceania: {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001234'), continent: 'Oceania', twoCode: 'Oceania', children: []},
    Europe: {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001235'), continent: 'Europe', twoCode: 'Europe', children: []},
    Asia: {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001236'), continent: 'Asia', twoCode: 'Asia', children: []},
    Antarctic: {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001237'), continent: 'Antarctic', twoCode: 'Antarctic', children: []},
    Americas: {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001238'), continent: 'Americas', twoCode: 'Americas', children: []},
    Africa: {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001239'), continent: 'Africa', twoCode: 'Africa', children: []},
    other: {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000297'), continent: 'other', twoCode: 'other', children: []}
  };

  // 遍历国家数据并填充大洲对象的 children
  countries.forEach(country => {
    const continent = continentMapping[country.continent];
    if (continent) {
      const {cnName, ...otherFields} = country; // 解构国家，删除 cnName
      continent.children.push({
        ...otherFields,
        title: cnName
      });
    }
  });
  // 返回只包含非空的洲信息
  return Object.values(continentMapping).filter(({children}) => children.length > 0);
}




