
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 处理对应code码对应的动态错误信息
 * @author jing
 * @date 2023/7/13 15:52
 * @params:
 */
import {isJSON, isValueEmpty, setConfigCharacters} from "@/utils/common";

// 处理数组数据问题
function handleArrayData(data) {
  if (Array.isArray(data)) {
    return data.join('、');
  } else {
    return data;
  }
}

export const dynamicErrorCodeFun = (data, value) => {
  if (data) {
    let codeObj = {};
    let code = data.code;
    let message = data.others ? data.others.errorMessage : '';
    let jsonTalg = isJSON(message);
    let text = '';
    if (jsonTalg) {
      let obj = JSON.parse(message);
      let ymsWarehouseName = '';
      let originalYmsWarehouseData = localStorage.getItem('originalYmsWarehouseData') ? JSON.parse(localStorage.getItem('originalYmsWarehouseData')) : [];
      if (originalYmsWarehouseData.length > 0) {
        if (obj.ymsWarehouseId) {
          ymsWarehouseName = originalYmsWarehouseData.find(item => item.ymsWarehouseId === obj.ymsWarehouseId).name;
        }
      }
      if (obj.ymsSpus) {
        codeObj['112812'] = `${obj.ymsSpus.join('、')}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001610')}${ymsWarehouseName}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001611')}`
      }
      if (!isValueEmpty(obj.message)) {
        codeObj['1112810'] = `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001612')}${obj.message}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001613')}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    } else {
      codeObj = {
        220029: `SKU: ${message} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001614')}`,
        220031: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001615')}${message} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001616')}`,
        220032: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001617')} ${message} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001618')}`,
        1112765: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001619')} ${message}`,
        1112772: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001620')} ${message}`,
        1112777: `${message}`,
        1112778: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001621')} ${message} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001622')}`,
        111294: `${message} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001623')}`,
        111297: `${message}`,
        1112759: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001624')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001625')} ${message}`,
        1112764: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001626')} ${message}`,
        1112803: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001627')}${message}`,
        111190: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001628')}${handleArrayData(message)}`,
        112834: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001629')}${message} ${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001630')}`,
        1112739: `${message}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001631')}`,
        122008: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001632')}${message}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001633')}`,
        1112760: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001634')}${message}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    }
    return text;
  }
}