import Cookies from 'js-cookie';
import config from '@/config';
import {commonSessionStorage} from "@/utils/common";

export const TOKEN_KEY = 'token';
export const SHOP_TOKEN_KEY = 'shop_token';
export const TOKEN_TIME = 'token_time';
export const YMS_SESSION_ID = 'YMS_SESSION_ID';
export const setToken = token => {
  let inFifteenMinutes = new Date(new Date().getTime() + (config.token_time + config.curTime));
  commonSessionStorage.setItem('token', token);
  Cookies.set(TOKEN_KEY, token, {expires: inFifteenMinutes});
};
export const getToken = (type) => {
  let key = TOKEN_KEY;
  let token = '';
  let origin = window.location.origin;
  let domain = window.location.host;
  if (/shop.*\.yms\.com/.test(origin)) {
    key = SHOP_TOKEN_KEY;
    domain = '';
  }

  // 重定向到登录页面时，需要重置token和shop_token
  if (window.location.href.includes('login.html#/login')) {
    let domain = window.location.host.replace(/.*?(?=\.)/, '');
    Cookies.remove(TOKEN_KEY);
    Cookies.remove(SHOP_TOKEN_KEY, {path: '/', domain: domain});
    Cookies.remove(YMS_SESSION_ID);
    Cookies.remove(YMS_SESSION_ID, {path: '/', domain: domain});
    commonSessionStorage.removeItem('shop_token');
    commonSessionStorage.removeItem('token');
    token = '';
  } else {
    // 点击退出登录
    if (type === 'signOut') {
      token = Cookies.get(key, {domain: domain});
    } else {
      token = Cookies.get(key, {domain: domain}) ? Cookies.get(key, {domain: domain}) : commonSessionStorage.getItem(TOKEN_KEY); // hack 方案
    }
  }
  if (token) {
    return token;
  } else {
    return false;
  }
};
export const removeToken = () => {
  return new Promise(resolve => {
    let domain = window.location.host.replace(/.*?(?=\.)/, '');
    let host = window.location.host;
    window.businessCode = '';
    // 清空缓存在sessionStorage的所有数据
    commonSessionStorage.clearAllItem();

    let localKeyList = ['supplierMerchantId', 'systemAnnouncementNotice', 'area', 'overTimeNum', 'distributorInfo', 'distributorParamKeysSetting'];
    localKeyList.map((key) => {
      localStorage.removeItem(key);
    });
    Cookies.remove(TOKEN_KEY);
    Cookies.remove(TOKEN_KEY, {domain: host});
    Cookies.remove(TOKEN_KEY, {domain: host});
    Cookies.remove(YMS_SESSION_ID);
    Cookies.remove(YMS_SESSION_ID, {path: '/', domain: host});
    Cookies.remove(SHOP_TOKEN_KEY, {path: '/', domain: domain});
    console.log('删除token');
    resolve(true);
  });
};

export const setTokenTime = time => {
  let expireTime = new Date(new Date().getTime() + time);
  Cookies.set(TOKEN_TIME, time, expireTime);
};
export const getTokenTime = () => {
  return Cookies.get(TOKEN_TIME);
};

export const setAdidToken = token => {
  let inFifteenMinutes = new Date(new Date().getTime() + (config.adidTime));
  Cookies.set('adidToken', token, {expires: inFifteenMinutes});
};

export const getAdidToken = (key) => {
  let adidToken = Cookies.get(key);
  return adidToken;
};
