// 封装base store

export default {
  state: {
    timezoom: localStorage.getItem('timezoom') || '', // 时区存储
    timezoomChange: '', // 时区改变的监听
    isAdmin: false, // 管理员
    erpConfig: null, // 获取ERP公共配置
    roleList: '', // 页面权限
    roleData: [], // 用户的菜单权限
    platformGroup: null,
    unreadData: [], // 未读消息通知的数据
    unreadTotal: 0, // 未读消息通知的总数
    _isMultiSpecificationAttributeMerchant: false, // 临时方案 匿名接口获取isMultiSpecificationAttributeMerchant true 展示广告信息,
    openClothingCategories: false, // 是否开通服装大类
    currency: null,
    isWarehouseManagement: 'N', // 供应商系统仓储管理配置是否开启，默认关闭
    configureLanguage: [], // 系统配置的语种
    setLanguages: 'CN', // 当前系统设置的语种，默认是中文
    changeTopMenu: false, // 切换头部的导航
    updateDistributorMenu: false, // 手动更新分销商系统侧边栏菜单的标识
    updateSystemMenu: false, // 手动更新运营、供应商系统侧边栏菜单的标识
    overTimeNum: 0, // 超时未发的总数
    updatePendingCount: 0, // 记录更新待处理的标识
    distributorParamKeysSetting: null, // 分销商系统--订单支付设置
  },
  mutations: {
    timezoomChange(state, data) {
      state.timezoomChange = data;
    },
    isAdmin(state, data) {
      state.isAdmin = data;
    },
    erpConfig(state, data) {
      state.erpConfig = data;
    },
    roleList(state, data) {
      state.roleList = data;
    },
    roleData(state, data) {
      state.roleData = data;
    },
    platformGroup(state, data) {
      state.platformGroup = data;
    },
    unreadData(state, data) {
      state.unreadData = data;
    },
    unreadTotal(state, data) {
      state.unreadTotal = data;
    },
    _isMultiSpecificationAttributeMerchant(state, data) {
      state._isMultiSpecificationAttributeMerchant = data;
    },
    openClothingCategories(state, data) {
      state.openClothingCategories = data;
    },
    currency(state, data) {
      state.currency = data;
    },
    isWarehouseManagement(state, data) {
      state.isWarehouseManagement = data;
    },
    configureLanguage(state, data) {
      state.configureLanguage = data;
    },
    setLanguages(state, data) {
      state.setLanguages = data;
    },
    changeTopMenu(state, data) {
      state.changeTopMenu = data;
    },
    updateDistributorMenu(state, data) {
      state.updateDistributorMenu = data;
    },
    overTimeNum(state, data) {
      state.overTimeNum = data;
    },
    updateSystemMenu(state, data) {
      state.updateSystemMenu = data;
    },
    updatePendingCount(state, data) {
      state.updatePendingCount = data;
    },
    distributorParamKeysSetting(state, data) {
      state.distributorParamKeysSetting = data;
    }
  },
  actions: {}
};
