
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';
import proxyFile from "../../proxy";
import api from '@/api/';
import {getToken} from "@/utils/cookie";

let pathname = window.location.pathname;
let origin = window.location.origin;
let webSocketList = [
  {pathname: '/yms.html', webSocketApi: api.get_ymsWebsocket}, // 运营系统
  {pathname: '/supplier.html', webSocketApi: api.get_supplierWebsocket}, // 供应商系统
  {pathname: '/distributor.html', webSocketApi: api.get_distributorWebsocket}, // 分销商系统
];
let webSocketApi = '';
export default {
  socket: null,
  defaultCount: 0, // 默认重新连接的次数
  minCount: 1,
  mediumCount: 5,
  maxCount: 10,
  minCountTime: null,
  mediumCountTime: null,
  maxCountTime: null,
  heartTimeOut: 25 * 1000, // 接收到消息 25秒后发生消息到后端
  serverTimeoutObj: null,
  heartReset() {
    clearTimeout(this.serverTimeoutObj);
    return this;
  },
  heartStart() {
    let v = this;
    v.timeoutObj = setTimeout(() => {
      v.socket.send("keep online test");
    }, v.heartTimeOut);
  },

  // 初始化 WebSocket
  initWebSocket() {
    let v = this;
    try {
      if ('WebSocket' in window) {
        let host = window.location.host;
        let target = '';
        if (host.includes('localhost')) {
          target = proxyFile['/'].target.split('//')[1];
        } else {
          // 店铺列表的websocket走分销商系统的域名
          if (origin.includes('shop') && origin.includes('.yms.com')) {
            if (origin.includes('test.pvt.yms.com')) {
              target = 'newerp-test.tongtool.com';
            } else {
              target = 'www.yms.com';
            }
          } else {
            target = host;
          }
        }
        if (!pathname.includes('/yms.html') && !pathname.includes('/supplier.html') && !pathname.includes('/distributor.html')) {
          webSocketApi = webSocketList[2].webSocketApi;
        }
        webSocketList.map((item) => {
          if (pathname.includes(item.pathname)) {
            webSocketApi = item.webSocketApi;
          }
        });
        // 只有在获取到token时，才初始化websocket
        if(getToken()) {
          const protocol = window.location.href.includes('https') ? 'wss://' : 'ws://';
          let url = protocol + target + webSocketApi + getToken();
          v.socket = new WebSocket(url);
        }
      } else {
        v.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001205'));
        console.log('您的浏览器不支持websocket');
      }
      // 监听 WebSocket 连接
      v.socket.onopen = function (e) {
        v.heartReset().heartStart();
        console.log('------WebSocket连接成功------', e);
      };
      // 监听 WebSocket 错误信息
      v.socket.onerror = function (e) {
        console.log('----WebSocket连接发生错误----', e);
      };
      // 数据接收
      /** webSocket 消息通知的类型
       * notice: 消息模板通知  pay：分销商充值成功通知
       */
      /*v.socket.onmessage = function (e) {
        v.heartCheck.reset().start();
        onMessage && onMessage(e);
      };*/
      // 关闭websocket
      v.socket.onclose = function (e) {
        console.log('---WebSocket 断开连接----', e);
      };
    } catch (e) {
    }
  }
};
