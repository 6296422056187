/**
 * @Description: 正则匹配的整理归纳
 * @author jing
 * @date: 2020/10/9 15:11
 * @params:
 * 使用方法-例： this.$regular.isPhone
 */

let regular = {
  isPhone: /^1[3456789]\d{9}$/, // 校验手机号  /^([0-9]{3,4}-)?[0-9]{7,8}$/
  isMob: /^((\+?86)|(\(\+86\)))?(13[012356789][0-9]{8}|15[012356789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7}|178[0-9]{8})$/,
  maxNumber_25: /^\d{0,25}$/, // 请输入0-25位数字
  MatchChinese: /^[\u2E80-\u9FFF]+$/, // 只允许输入中文
  noMatchChinese: /[\u4E00-\u9FA5]/g, // 不允许输入中文
  MatchEnglish: /^[a-zA-Z]+$/, // 只允许输入英文
  AllNumber: /^\d+$/, // 只允许数字
  hasPriceNum: /^\d*[.]?\d{1,4}$/, // 价格必须为数字,且最多允许4位小数
  NoDecima: /^\d+\.\d+$/, // 不能为小数
  isMailbox: /^[a-zA-Z0-9]+([_.-][a-zA-Z0-9]+)*@([a-zA-Z0-9]+[-_.]?[.])+[a-zA-Z]{2,3}$/, // 校验邮箱
  postalCode: /^[0-9]{6}$/, // 邮政编码
  integer: /^[0-9]+.?[0-9]*$/, // 大于0的整数
  spuRegExp: /^[0-9#&+-./A-Z[\]^_a-z}{~ ]{0,100}$/,
  fixedTelephone: /^\d{3}-\d{8}$|^\d{4}-\d{8}$/, // 固定电话
  positiveInteger:  /^[1-9]\d*$/, // 大于0的正整数
  minPositiveInteger: /^(0|[1-9][0-9]*)$/, // 大于等于0的正整数
  checkHttps: /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/, // 校验链接是https或者http 开头的
  decimal: /^[0-9]\d*(\.\d+)?$/, // 只允许输入大于等于0的数字（包含小数）
  linkAddress: /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?$/, // 校验是否是正确的链接地址
  decimalPlaces: /^\d+(\.\d{0,4})?$/, // 校验小数点位数只能是四位
  twoDecimalPlaces: /^(0|[1-9]\d*)(\.\d{1,2})?$/, // 最多支持两位小数，需大于等于0
  isIoss:  /^IM\d{10}$/, // 校验IOSS编号
  squareBrackets: /\[(.+?)\]/g, // 匹配获取中括号内的内容
  matchLettersNumbers: /^[a-zA-Z0-9\n]*$/, // 匹配字母和数字
  proportion: /^\d*[.]?\d{1,2}$/, // 价格必须为数字,且最多允许2位小数
  numbersWithDecimalPoints: /^(?=\d)(\d+|\d*\.\d+)$/, // 数字或带小数点的数字
};
export default regular;
