/**
 * @Description: 供应商系统api
 * @author jing
 * @date 2021/11/22 14:41
 * @params:
 */
import commonApi from "@/api/commonApi";

const supplierApi = {
  ...commonApi,
  post_wmsInventory_export: "/wmsInventory/export", // excel导出
  post_wmsInventory_exportYms: "/wmsInventory/exportYms", // 库存余量--云卖中心仓--excel导出
  post_wmsInventory_list: "/wmsInventory/list", // 库存余量查询功能
  post_wmsInventoryLog_export: "/wmsInventoryLog/export", // 库存管理-导出库存事务明细列表信息
  post_wmsInventoryLog_list: "/wmsInventoryLog/list", // 库存事务明细页面查询功能
  get_organizationSource_query: "/organizationSource/query", // 获取来源渠道数据
  post_wmsPicking_querySalesPickingCarriers:
    "/wmsPicking/querySalesPickingCarriers", // 获取物流商的邮寄方式
  post_wmsPicking_querySalesPicking: "/wmsPicking/querySalesPicking", // 出库管理-查询销售出库单列表数据
  post_packageGoods_queryByPackageIds: "/packageGoods/queryByPackageIds", // 根据出库单流水号列表查询货品信息
  get_wmsPickingGoods_pickingGoodsTags: "/wmsPickingGoods/pickingGoodsTags", // 获取拣货单标签的数据
  post_wmsPickingGoods_pickingGoodsTags_save:
    "/wmsPickingGoods/pickingGoodsTags/save", // 添加拣货单标签
  delete_wmsPickingGoods_pickingGoodsTag: "/wmsPickingGoods/pickingGoodsTag", // 删除拣货单标签
  post_wmsPickingGoods_create: "/wmsPickingGoods/create", // 生成拣货单
  post_wmsPicking_querySaleInfomation: "/wmsPicking/querySaleInfomation", // 生成拣货单前表格查询(销售出库)
  post_wmsPickingGoods_createTask: "/wmsPickingGoods/createTask", // // 创建任务
  get_wmsPickingGoods_queryPickingGoodsTask:
    "/wmsPickingGoods/queryPickingGoodsTask", // 查询自动波次
  post_wmsPickupOrder_query: "/wmsPickupOrder/query", // 获取装箱/袋管理列表的数据
  post_wmsPickupOrder_queryWmsPickupOrderList:
    "/wmsPickupOrder/queryWmsPickupOrderList", // 获发货记录列表的数据
  put_wmsPickupOrder_updateLogistics: "/wmsPickupOrder/updateLogistics", // 修改物流信息
  delete_wmsPickupOrder: "/wmsPickupOrder/", // 装箱列表--删除包裹
  get_wmsPickupOrder_scan: "/wmsPickupOrder/scan/", // 获取查看装箱详情的数据
  get_wmsPickupOrder: "/wmsPickupOrder/", // 查看详情
  get_wmsPickupOrder_wmsPickupOrderDetail:
    "/wmsPickupOrder/wmsPickupOrderDetail/", // 发货记录--查看详情
  post_wmsPickupOrderDetail_queryByPackageInfoToGoods:
    "/wmsPickupOrderDetail/queryByPackageInfoToGoods", // 获取销售单、备货单明细数据
  post_wmsPickupOrder_export: "/wmsPickupOrder/export", // 导出提单数据
  post_wmsPickupOrder_exportWmsPickupOrderExcel:
    "/wmsPickupOrder/exportWmsPickupOrderExcel", // 发货记录--导出订单数据
  post_wmsPickupOrder_invalid: "/wmsPickupOrder/invalid/", // 作废提单
  post_wmsPickupOrderDetail: "/wmsPickupOrderDetail", // 批量添加出库单
  put_wmsPickupOrder_printWmsPickupOrder:
    "/wmsPickupOrder/printWmsPickupOrder/", // 箱/袋管理--打印箱唛
  put_wmsPickupOrder_printBoxShippingMark:
    "/wmsPickupOrder/printBoxShippingMark/", // 发货记录--打印箱唛
  put_wmsPickupOrder_overPickupOrder: "/wmsPickupOrder/overPickupOrder", // 结束装箱
  post_wmsPickupOrderDetail_scanPackaging:
    "/wmsPickupOrderDetail/scanPackaging/", // 扫描拣货单号/出库单号/运单号
  get_wmsPickupOrder_continuePickupOrder:
    "/wmsPickupOrder/continuePickupOrder/", // 继续装箱
  post_wmsPickupOrder: "/wmsPickupOrder", // 新增装箱/袋
  post_wmsPickupOrder_appendProductGoods: "/wmsPickupOrder/appendProductGoods", // 等待揽收 追加货品
  put_wmsPickupOrder_overAppendGoods: "/wmsPickupOrder/overAppendGoods", //追加获批-追加完成
  delete_wmsPickupOrder_deletePackage: "/wmsPickupOrder/deletePackage/", // 追加货品-移除包裹
  delete_wmsPickupOrderDetail_deletePackage:
    "/wmsPickupOrderDetail/deletePackage/", // 移除已扫描的出库单号
  import_packageInfoExcelImport: "/packageInfo/excelImport", // 出库单检索 Excel导入
  down_SalePackageTemplet: "/packageInfo/getSalePackageTemplet", // 出库单检索 Excel导入模板
  post_packageInfo_query: "/packageInfo/query", // 出库单检索
  post_packageInfo_cancelPackageDistribution:
    "/packageInfo/cancelPackageDistribution", // 批量取消分配
  post_packageInfo_export: "/packageInfo/export", // 出库单检索--导出
  set_uploadPickingExcel: "/wmsPickingBox/importPickingBox", // 箱子excel导入
  get_carrier_queryCarrier: "/carrier/queryCarrier", // 获取物流商下拉
  get_carrierShippingMethod_queryCarrierShippingMethodByCarrierId:
    "/carrierShippingMethod/queryCarrierShippingMethodByCarrierId", // 获取邮寄方式下拉
  update_Picking: "/wmsPicking/updatePicking", // 其他出库 保存
  del_obsoletePicking: "/wmsPicking/obsoletePicking/", // 其他出库 删除
  get_boxTemplet: "/wmsPickingBox/getTemplet", // 获取箱子导入模板
  set_saveWmsPickingBox: "/wmsPickingBox/saveWmsPickingBox", // 添加箱子
  updateDeclare: "/packageDeclare", // 更新包裹
  saveDeclareInfo: "/packageDeclare/insert", // 保存申报信息
  getOrderFront: "/saleAccount/getSaleAccountsByIds", // 获取订单前缀
  get_sellStockOutDtl: "/wmsPicking/querySalesPickingDetail", // 销售出库单详情(销售出库)
  getSalesPickingStatus: "/wmsPicking/getSalesPickingStatus/", // 销售出库的图标状态
  getOtherPickingStatus: "/wmsPicking/getOtherPickingStatus/", // 其他出库的图标状态
  add_wmsPicking_createLog: "/wmsPicking/createLog", // 新增备注
  assign_sellStockSingle: "/wmsPicking/modifySalesDistributionSingle", // 分配库存(销售出库单个)
  cancel_sellStock: "/wmsPicking/modifySalesDistributionCancelSingle", // 销售出库单个取消分配
  refresh_msgNote: "/wmsPicking/queryPickingLog", // 新增留言成功刷新留言
  get_locationInv: "/wmsPicking/queryListParamInventory", // 获取库位
  stockLocation: "/wmsWorking/stockLocation", // 商品组装加工的库位列表
  blockList: "/wmsWorking/blockList", // 库区
  get_wareList: "/wmsPicking/queryBlocakListParam", // 仓库区级联下拉框数据
  locationList: "/wmsWorking/locationList",
  get_wareLocationList: "/wmsPicking/queryLocationListParam", // 库位查询
  add_Notes: "/wmsReceiptLog/create", // 添加备注信息
  createWorkingLog: "/wmsWorkingLog/create", // 新增日志列表
  get_inWareGoodsList: "/wmsReceipt/goodsList", // 入库单添加商品
  get_outStockProduct: "/wmsPicking/product", // 出库单添加商品
  categoryList: "/wms/common/categoryList", // 获取商品的树形结构数据
  get_FrozenInventory: "/wmsPicking/getOtherPickingFrozenInventory", // 获取冻结分配列表的数据
  post_otherPickingDistributionFrozen:
    "/wmsPicking/otherPickingDistributionFrozen", // 解冻并分配的接口
  post_packageInfo_queryForOutWarehouse: "/packageInfo/queryForOutWarehouse", // 包裹出库列表
  put_packageInfo_batchMarkPackageDelivery:
    "/packageInfo/batchMarkPackageDelivery", // 标记出库
  put_packageInfo_markPackageDelivery: "/packageInfo/markPackageDelivery", // 标记包裹出库
  get_packageProductList: "/packageInfo/queryPackageGoodsForMissing", // 获取选取包裹商品详情
  set_markPackageGoodsMissing: "/packageInfo/markPackageGoodsMissing", // 标记缺货
  get_packageInfo_getDeliveryBatchNo: "/packageInfo/getDeliveryBatchNo", // 获取发货批次号
  put_packageInfo_validateDeliveryPackage:
    "/packageInfo/validateDeliveryPackage", // 扫描称重后校验包裹
  put_packageInfo_scanBatchMarkPackageDelivery:
    "/packageInfo/scanBatchMarkPackageDelivery", // 扫描批量标出库
  set_DeliverySetting: "/packageSettingCommon/saveDeliverySetting", // 保存发货设置
  get_DeliverySetting: "/packageSettingCommon/queryDeliverySetting", // 获取发货设置
  get_packageByCode: "/packageInfo/getPackageByCode/", // 扫描包裹
  post_wmsPickupOrder_packageBagging: "/wmsPickupOrder/packageBagging", // 供应商的结袋
  set_packageOverBag: "/packageInfo/createExcelForBagging", // 结袋导出
  post_wmsPickingGoods_remove: "/wmsPickingGoods/remove", // 列表的拣货单删除
  post_wmsPickingGoods_queryCarriersForPickingGoodsList:
    "/wmsPickingGoods/queryCarriersForPickingGoodsList", // 查询邮寄方式拣货单
  post_wmsPickingGoods_list: "/wmsPickingGoods/list", // 获取拣货单列表数据
  post_wmsPickingGoods_modifyToPicking: "/wmsPickingGoods/modifyToPicking", // 标记已拣货
  get_wmsPickingGoods_exportSkuNumber: "/wmsPickingGoods/exportSkuNumber", // 拣货单导出单个sku
  post_wmsPickingGoods_modifyPickingByNo: "/wmsPickingGoods/modifyPickingByNo", // // 删除拣货单(详情内部)
  post_wmsPickingGoods_detail: "/wmsPickingGoods/detail", // 获取拣货单详情
  get_scanDetailData: "/wmsInventoryCheck/detail", // 查询盘点明细信息
  getBarCode: "/productGoods/queryPrintSkuData", // 获取打印拣货单条码
  post_wmsPickingGoods_print: "/wmsPickingGoods/print", // 拣货单打印
  put_wmsInventory: "/wmsInventory", // 库存余量--编辑可用数量
  update_sortingSetting: "/packageSettingCommon/updateSortingSetting", // 更新见货出单设置
  get_packageSetting: "/packageSettingCommon/queryErpCommonSettingParam", // 查询见货出单设置
  get_wmsPickingGoods_querySortingList: "/wmsPickingGoods/querySortingList", // 分拣-获取正在进行的分拣
  get_wmsPickingGoods_startSorting: "/wmsPickingGoods/startSorting", // 开始分拣
  post_wmsPickingGoods_cancelSorting: "/wmsPickingGoods/cancelSorting/", // 撤销分拣
  post_packageInfo_queryDeliveryListPath: "/packageInfo/queryDeliveryListPath", // 打印配货清单
  get_packageGoods_queryByPickingGoodsId:
    "/packageGoods/queryByPickingGoodsId/", // 拣货单货品列表查询
  get_packageInfo_queryByPickingGoodsId: "/packageInfo/queryByPickingGoodsId/", // 出库单列表查询
  put_wmsPickingGoods_sortingFinishPrint: "/wmsPickingGoods/sortingFinishPrint", // 打印面单成功后 -分拣打印完成
  post_wmsPickingGoods_endSorting: "/wmsPickingGoods/endSorting", // 分拣-分拣结束（如果有异常的出库单errorpackageidlist从拣货单中移除）
  post_wmsPickingGoods_excludePackageInfo:
    "/wmsPickingGoods/excludePackageInfo", // 将该包裹从当前拣货单中移除等待生成新的拣货单
  post_packageInfo_exportOrderExcel: "/packageInfo/exportOrderExcel", // 分拣出库单异常保存EXCEL
  put_wmsPickingGoods_scanPickingGoodsNo: "/wmsPickingGoods/scanPickingGoodsNo", // 包装作业扫描拣货单
  get_wmsPickingGoods_getScanningPickingGoods:
    "/wmsPickingGoods/getScanningPickingGoods", // 获取当前用户正在包装作业中的拣货单信息
  get_wmsPickingGoods_getPackingPickingGoodsInfo:
    "/wmsPickingGoods/getPackingPickingGoodsInfo", // 获取当前用户作业包裹统计数量 与
  // 系统正在进行的包装作业
  put_wmsPickingGoods_packingClearScan: "/wmsPickingGoods/packingClearScan", // 清空扫描货品,针对已扫描未打印的包裹,把扫描状态修改为初始值
  put_wmsPickingGoods_packingWeighting: "/wmsPickingGoods/packingWeighting", // 称重
  post_wmsPickingGoods_packingScanSku: "/wmsPickingGoods/packingScanSku", // 扫描sku,传参若成功匹配包裹,则返回包裹数据,反之返回null
  post_wmsPickingGoods_packageFinishPrint:
    "/wmsPickingGoods/packageFinishPrint", // 打印面单成功后 - 扫描包装完成
  put_wmsPickingGoods_preparFinishPacking:
    "/wmsPickingGoods/preparFinishPacking/", // 结束作业
  put_wmsPickingGoods_quitPacking: "/wmsPickingGoods/quitPacking/", // 退出作业
  get_wmsPickingGoods_statisticsPacking: "/wmsPickingGoods/statisticsPacking/", // 结束作业后 - 获取包装统计
  get_wmsPickingGoods_getPackingWarnPackages:
    "/wmsPickingGoods/getPackingWarnPackages/", // 结束作业后 - 获取异常包裹信息
  put_wmsPickingGoods_confirmFinishPacking:
    "/wmsPickingGoods/confirmFinishPacking", // 确认强制结束作业
  post_packageGoods_exportPackingPrintingSkusExcel:
    "/packageGoods/exportPackingPrintingSkusExcel", // 包装作业结束异常，正在打印中货品异常列表
  post_packageInfo_exportPackingPrintingExcel:
    "/packageInfo/exportPackingPrintingExcel", // 包装作业结束异常，正在打印中出库单异常列表
  post_packageGoods_exportPackingUnPrintSkusExcel:
    "/packageGoods/exportPackingUnPrintSkusExcel", // 包装作业结束异常，尚未打印或打印前作废货品异常列表
  post_packageInfo_exportPackingUnPrintExcel:
    "/packageInfo/exportPackingUnPrintExcel", // 包装作业结束异常，尚未打印或打印前作废出库单异常列表
  post_packageGoods_exportPackingInvalidSkusExcel:
    "/packageGoods/exportPackingInvalidSkusExcel", // 包装作业结束异常，打印后作废货品异常列表
  post_packageInfo_exportPackingInvalidExcel:
    "/packageInfo/exportPackingInvalidExcel", // 包装作业结束异常，打印后作废出库单异常列表
  put_wmsPicking_packingWeighting: "/wmsPicking/packingWeighting", // 称重
  put_wmsPicking_scanPackaging: "/wmsPicking/scanPackaging/", // 包装作业扫描出库单号、运单号、物流商单号
  put_packageInfo_validatePickingPackage: "/packageInfo/validatePickingPackage", // 包装作业--扫描称重后校验包裹
  get_OperationsSetting: "/packageSettingCommon/queryPackingOperationsSetting", // 获取包装设置
  post_wmsPickingGoods_markException: "/wmsPickingGoods/markException", // 包装作业---标记异常
  post_wmsInventory_excelImport: "/wmsInventory/excelImport", // 库存余量导入
  get_carrier_queryForEnableCarriers: "/carrier/queryForEnableCarriers", // 查询可用物流商(api对接)
  get_wmsPickingLog: "/wmsPickingLog/listByNextToken", // 获取wms 操作日志
  post_packageInfo_rePrintExpressBill: "/packageInfo/rePrintExpressBill", // 重打面单

  get_ymsSupplierMerchant_info: "/ymsSupplierMerchant", // 获取供应商基本信息
  get_ymsSupplierMerchant_closeAuditTips:
    "/ymsSupplierMerchant/closeAuditTips/", // 关闭审核成功提示
  post_ymsSupplierMerchant_updateSupplierCompanyInfo:
    "/ymsSupplierMerchant/updateSupplierCompanyInfo", // 更新供应商的注册企业信息
  post_role_query: "/role/query", // 获取所有角色列表
  get_role: "/role/", // 获取角色的详情数据
  post_ymsSupplierUserInfo_id: "/ymsSupplierUserInfo/", // 查看员工信息     ymsSupplierUserInfo
  post_ymsSupplierUserInfo: "/ymsSupplierUserInfo", // 新增员工
  put_ymsSupplierUserInfo: "/ymsSupplierUserInfo", // 修改员工
  put_ymsSupplierUserInfo_enable: "/ymsSupplierUserInfo/enable", // 启用员工
  put_ymsSupplierUserInfo_disable: "/ymsSupplierUserInfo/disable", // 停用员工
  delete_role: "/role/", // 删除角色
  post_ymsSupplierUserInfo_query: "/ymsSupplierUserInfo/query", // 分页查询员工列表
  post_role: "/role", // 新增角色
  put_role: "/role", // 修改角色
  get_ymsSupplierUserInfo_queryMenu: "/ymsSupplierUserInfo/queryMenu", // 查询用户菜单
  post_ymsSupplierUserInfo_queryOperByMenu:
    "/ymsSupplierUserInfo/queryOperByMenu/", // 获取单页权限

  put_productBrand_disable: "/productBrand/disable", // 停用商品品牌
  put_productBrand_enable: "/productBrand/enable", // 启用商品品牌
  delete_productBrand: "/productBrand/", // 删除商品品牌
  post_productBrand: "/productBrand", // 新增商品品牌
  put_productBrand: "/productBrand", // 编辑商品品牌
  post_productBrand_query: "/productBrand/query", // 查询商品品牌
  get_userInfo_queryMapByUserIds:
    "/erpCommon/common-service/userInfo/queryMapByUserIds", // 根据用户Id列表查询用户信息
  post_productGoodsImage_queryByProductGoodsIds:
    "/productGoodsImage/queryByProductGoodsIds", // 获取商品货品图片
  post_productInfo_excelImport: "/productInfo/excelImport", // 导入商品
  get_productBrand_queryAll: "/productBrand/queryAll", // 查询所有商品品牌
  post_productGoods_batchDelete: "/productGoods/batchDelete", // 批量删除所有结果集货品
  post_productInfo_query: "/productInfo/query", // 商品列表
  post_productGoods_batchUpdate: "/productGoods/batchUpdate", // 批量修改所有结果集货品
  product_ueditor_enter: "/erpCommon/ueditorEnter", // 富文本编辑器的接口
  post_uploadNetworkFiles: "/erpCommon/uploadNetworkFiles", // 批量下载网络图片
  get_productInfo_getDevelopers: "/productInfo/getDevelopers", // 获取开发人员的数据
  put_productGoods: "/productGoods", // 更新SKU货品信息
  post_productGoods: "/productGoods", // 新增SKU货品信息
  get_ymsSupplierUserInfo_getAllUser: "/ymsSupplierUserInfo/getAllUser", // 获取用户信息

  put_productInfo: "/productInfo", // 编辑商品(供应商系统)
  post_productInfo: "/productInfo", // 新增商品
  post_productInfo_alibabaProductQuery: "/productInfo/alibabaProductQuery", // 采集1688商品数据
  post_productInfo_gathers: "/productInfo/gathers", // 批量采集
  get_productInfo_queryForSkuList: "/productInfo/queryForSkuList/", // 查看SKU
  get_productInfo: "/productInfo/", // SPU详情
  put_productInfo_productId: "/productInfo/", // 停售SPU商品
  put_productInfo_dercarriage: "/productInfo/dercarriage/", // 上架SPU
  delete_productInfo: "/productInfo/", // 删除SPU
  put_productGoods_updateStatus: "/productGoods/updateStatus", // 上架、停售sku
  delete_productGoods: "/productGoods/", // 删除sku
  post_productGoodsOperationLog_query: "/productGoodsOperationLog/query", // spu、sku操作日志
  post_erpCommon_queryTask: "/erpCommon/queryTask", // 导入导出查看列表
  get_packageCommon_downloadTongtoolPrinter:
    "/packageCommon/downloadTongtoolPrinter", // 下载全路径 = 当前系统域名:端口 + 控件路径
  get_ymsSupplierBill_supplierBillDetail:
    "/ymsSupplierBill/supplierBillDetail/", // 获取供应商账单的基本信息
  post_productInfo_inventoryExcelImport: "/productInfo/inventoryExcelImport", // 商品列表--导入库存
  post_productInfo_queryGoodsInventoryById:
    "/productInfo/queryGoodsInventoryById", // 查询供应商库存详情
  post_ymsInventory_api_queryGoodsForSupplierProductIds:
    "/erpCommon/yms-core-service/ymsInventory/api/queryGoodsForSupplierProductIds", // 查询中心仓库存详情
  put_productInfo_updateSkuInventory: "/productInfo/updateSkuInventory", // 编辑可用库存

  // 对账管理
  post_ymsSupplierBill_query: "/ymsSupplierBill/query", // 对账管理列表
  supplier_get_ymsPackageDetail_api_queryBillId:
    "/erpCommon/yms-core-service/ymsPackageDetail/api/queryBillId", // 供应商账单明细列表
  supplier_get_ymsSupplierMerchantGrade_list:
    "/erpCommon/yms-core-service/ymsSupplierMerchantGrade/list", // 获取供应商等级所有数据
  supplier_get_ymsSupplierMerchant:
    "/erpCommon/yms-core-service/ymsSupplierMerchant/", // 查看供应商基本信息
  get_ymsProductColorTemplate_api_query:
    "/erpCommon/yms-core-service/ymsProductColorTemplate/api/query", // 获取颜色属性的数据
  get_ymsProductSizeValue_api_query:
    "/erpCommon/yms-core-service/ymsProductSizeValue/query", // 获取尺码管理的数据
  get_reportManagementStatistics_query: "/reportManagementStatistics/query", // 即时运营数据
  post_productGoods_query: "/productGoods/query", // 获取货品列表的数据
  post_productInfo_querySpu: "/productInfo/querySpu", // 获取spu级别的列表数据
  post_packageInfo_supplier: "/packageInfo", // 新增出库单
  get_expressageHundred_query:
    "/erpCommon/common-service/expressageHundred/query", // 获取运输方式的数据
  post_packageInfo_obsoletePackage: "/packageInfo/obsoletePackage", // 作废出库单
  post_packageInfo_printingSurfaceSingle: "/packageInfo/printingSurfaceSingle", // 返回sku条形码
  put_wmsPickingGoods_confirmFinishStockOrderPacking:
    "/wmsPickingGoods/confirmFinishStockOrderPacking", // 确认结束备货订单作业
  post_ymsProductCategory_api_queryAll:
    "/erpCommon/yms-core-service/ymsProductCategory/api/queryAll", // 获取云卖分类的数据
  get_ymsProductCategory_api_detail:
    "/erpCommon/yms-core-service/ymsProductCategory/api/detail/", // 获取物品属性的数据(api) 只返回可选值是已启用的
  get_ymsProductCategory_api_detailForAll:
    "/erpCommon/yms-core-service/ymsProductCategory/api/detailForAll/", // 获取物品属性的数据(api) 返回所有可选值
  get_ymsProductSizeTemplate_api_queryByYmsProductCategoryId:
    "/erpCommon/yms-core-service/ymsProductSizeTemplate/api/queryByYmsProductCategoryId/", // 获取尺码表的数据
  post_ymsProductInfo_api_queryByYmsProductGoodsIds:
    "/erpCommon/yms-core-service/ymsProductGoods/api/queryByProductGoodsIds", //api 商品id查询产品信息
  get_supplierWebsocket: "/notify/default/", // websocket连接后台服务实时更新消息通知
  get_ymsSupplierStationNotification_lastUnRead:
    "/ymsSupplierStationNotification/lastUnRead", // 获取消息提醒的数据
  post_ymsSupplierStationNotification_query:
    "/ymsSupplierStationNotification/query", // 获取消息中心列表数据
  get_ymsSupplierStationNotification: "/ymsSupplierStationNotification/", // 获取消息中心详情的数据
  get_ymsSupplierStationNotification_count:
    "/ymsSupplierStationNotification/count", // 获取已读未读消息个数
  post_ymsSupplierStationNotification_batchRead:
    "/ymsSupplierStationNotification/batchRead", // 批量标记已读
  get_ymsSupplierStationNotification_markPopipRead:
    "/ymsSupplierStationNotification/markPopipRead/", // 弹窗消息通知（全部标记已读）
  get_wmsWarehouse_queryYmsWarehouse: "/wmsWarehouse/queryYmsWarehouse", // 查询云卖中心仓仓库
  post_wmsInventory_queryYmsInventory: "/wmsInventory/queryYmsInventory", // 查询云卖中心仓库存
  post_wmsWarehouse_queryYmsWarehouseLocationIds:
    "/wmsWarehouse/queryYmsWarehouseLocationIds", // 查询云卖仓库库位接口
  post_productInfo_queryByYmsProductGoodsIds:
    "/productInfo/queryByYmsProductGoodsIds", // 查询商品信息
  post_productInfo_queryByProductGoodsIds:
    "/productInfo/queryByProductGoodsIds", // 供应商系统通过 productGoodsId 查询商品公共信息
  post_wmsInventoryLog_queryYmsInventoryChangeLog:
    "/wmsInventoryLog/queryYmsInventoryChangeLog", // 查询云卖中心仓库存变更日志

  get_productGoods_priceDetails: "/productGoods/priceDetails", // 获取修改价格数据
  put_productGoods_batchUpdatePrice: "/productGoods/batchUpdatePrice", // 修改价格
  post_productGoods_excelExport: "/productGoods/excelExport", // 导出商品
  post_productGoods_excelPricesImport: "/productGoods/excelPricesImport", // 导入商品价格
  post_productGoods_excelSkuStatusImport: "/productGoods/excelSkuStatusImport", // 批量上架/停售

  post_ymsPlatformAccount_query: "/ymsPlatformAccount/query", // 获取平台授权的列表数据
  get_ymsThirdPlatform_query: "/ymsThirdPlatform/query", // 获取平台数据
  put_ymsPlatformAccount_disable: "/ymsPlatformAccount/disable", // 停用平台
  put_ymsPlatformAccount_enable: "/ymsPlatformAccount/enable", // 启用平台
  post_ymsPlatformAccount_applyAuth: "/ymsPlatformAccount/applyAuth", // 申请授权
  get_productInfo_getImportExcelTemplate:
    "/productInfo/getImportExcelTemplate/", // 根据分类id，动态获取对应的导入商品资料的模板
  put_ymsPlatformAccount_type: "/ymsPlatformAccount/", // 同步数据
  post_erpCommonSettingParam_saveAlibabaSyncSetting:
    "/erpCommonSettingParam/saveAlibabaSyncSetting", // 保存1688平台数据同步的设置
  post_erpCommonSettingParam_queryPlatformSyncSetting:
    "/erpCommonSettingParam/queryPlatformSyncSetting", // 查询对应平台下的数据同步设置

  post_wmsInventory_syncJuShuiTanInventory:
    "/wmsInventory/syncJuShuiTanInventory", // 自有仓库--同步库存
  post_productInfo_appendProductInfoExpressBill:
    "/productInfo/appendProductInfoExpressBill", // 打印条形码、二维码（SPU级别）
  post_ymsSupplierBill_billExport: "/ymsSupplierBill/billExport", // 导出账单
  post_productGoods_appendProductGoodsExpressBill:
    "/productGoods/appendProductGoodsExpressBill", // 打印条形码、二维码（SKU级别）
  post_ymsPlatform_api_query:
    "/erpCommon/yms-core-service/ymsPlatform/api/query", //获取平台
  get_ymsWarehouseBlock_api_queryByYmsWarehouseId:
    "/erpCommon/yms-core-service/ymsWarehouseBlock/api/queryByYmsWarehouseId", // 获取根据仓库id获取对应的库区数据
  get_ymsWarehouseLocation_api_queryByYmsWarehouseBlockId:
    "/erpCommon/yms-core-service/ymsWarehouseLocation/api/queryByYmsWarehouseBlockId", // 获取库位的基础数据
  post_erpCommonSettingParam_queryByParamKeys:
    "/erpCommonSettingParam/queryByParamKeys", // 获取系统的属性设置数据
  post_erpCommonSettingParam_save: "/erpCommonSettingParam/save", // 保存系统的属性设置

  post_erpCommonSettingParam_queryMerchantByParamKeys:
    "/erpCommonSettingParam/queryMerchantByParamKeys",
  post_erpCommonSettingParam_saveMerchantSetting:
    "/erpCommonSettingParam/saveMerchantSetting",
  post_erpCommonSettingParam_saveSetting: "/erpCommonSettingParam/saveSetting",

  get_ymsPlatformAccountWarehouse_warehouseQuery:
    "/ymsPlatformAccountWarehouse/warehouseQuery", // 查询同步库存到仓库的仓库基础数据
  get_ymsPlatformAccountWarehouse_queryWarehouseByAccountId:
    "/ymsPlatformAccountWarehouse/queryWarehouseByAccountId/", // 查询仓库设置
  post_ymsPlatformAccountWarehouse_insertWarehouse:
    "/ymsPlatformAccountWarehouse/insertWarehouse", // 同步库存到仓库

  put_ymsSupplierBill_confirmBill: "/ymsSupplierBill/confirmBill/", // 确认账单
  get_ymsSupplierBill_waitConfirmSupplierBill:
    "/ymsSupplierBill/waitConfirmSupplierBill", // 查询供应商系统中是否存在待确认的账单

  get_ymsPlatformAccount_callback: "/ymsPlatformAccount/callback", // 授权回调
  get_ymsPlatformAccount_queryAuthAccountList:
    "/ymsPlatformAccount/queryAuthAccountList", // 获取指定平台下店铺的数据

  get_ymsPayAccount: "/ymsPayAccount", // 获取绑定支付数据
  post_ymsPayAccountLog_query: "/ymsPayAccountLog/query", // 获取支付信息变更日志
  post_ymsPayAccount_bindAliPayAccount: "/ymsPayAccount/bindAliPayAccount", // 绑定支付
  put_ymsPayAccount_updateAccount: "/ymsPayAccount/updateAccount", // 变更支付

  put_wmsPickingGoods_checkPickingGoods: "/wmsPickingGoods/checkPickingGoods", // 校验拣货单中出库单状态
  put_wmsPickingGoods_deletePickingGoodsRelatedByIds:
    "/wmsPickingGoods/deletePickingGoodsRelatedByIds", // 批量移除订单
  put_wmsPicking_checkScanPackaging: "/wmsPicking/checkScanPackaging/", // 检查包装作业扫描出库单
  post_wmsPickupOrderDetail_checkScanPackaging:
    "/wmsPickupOrderDetail/checkScanPackaging/", // 检查扫描包裹

  get_YmsSupplierCommon_index: "/ymsSupplierCommon/index", // 获取数据看板信息（卡片、订单状态、商品管理）
  get_YmsSupplierCommon_getTrendMap: "/ymsSupplierCommon/getTrendMap", // 获取趋势图-热销商品的数据

  get_ymsSupplierAscriptionWarehouse_api_query:
    "/erpCommon/yms-core-service/ymsSupplierAscriptionWarehouse/api/query/", // 获取归属仓库的数据

  post_productInfo_syncAlibabaProduct: "/productInfo/syncAlibabaProduct", // 获取1688平台商品
  get_ymsCurrencyConfig_query:
    "/erpCommon/yms-core-service/ymsCurrencyConfig/api/query", // 查询系统币种配置数据
  post_ymsSupplierMerchant_saveCurrency: "/ymsSupplierMerchant/saveCurrency", // 供应商保存币种

  get_address_query: "/address/query", // 获取供应商地址信息
  post_address_save: "/address/save", // 保存供应商地址信息
  post_ymsSupplierMerchant_updateSupplierRegisterInfo:
    "/ymsSupplierMerchant/updateSupplierRegisterInfo", // 更新供应商企业信息

  post_ymsLogisService_api_query:
    "/erpCommon/yms-core-service/ymsLogisService/api/query", // 获取云卖物流信息

  post_packageInfo_queryPackageInfoOrder: "/packageInfo/queryPackageInfoOrder", // 订单列表
  post_packageInfo_exportPackageInfoOrder:
    "/packageInfo/exportPackageInfoOrder", // 导出订单数据
  post_wmsPickupOrder_batchDeliver: "/wmsPickupOrder/batchDeliver", // 批量发货
  post_packageInfo_appendPackageInfoOrderExpressBill:
    "/packageInfo/appendPackageInfoOrderExpressBill", // 批量打印订单条码
  post_packageInfo_appendYmsSkuExpressBill:
    "/packageInfo/appendYmsSkuExpressBill", // 扫描SKU打印
  post_packageInfo_printPrepareGoods: "/packageInfo/printPrepareGoods", // 打印配货清单

  post_packageInfo_queryForStock: "/packageInfo/queryForStock", // 备货单列表数据
  post_packageInfo_createStock: "/packageInfo/createStock", // 发起备货
  post_packageInfo_printPrepareGoodsStock:
    "/packageInfo/printPrepareGoodsStock", // 备货单--打印配货清单
  post_packageInfo_appendYmsSkuExpressBillStcok:
    "/packageInfo/appendYmsSkuExpressBillStcok", // 备货单--扫描SKU打印
  post_packageInfo_appendPackageInfoOrderStockExpressBill:
    "/packageInfo/appendPackageInfoOrderStockExpressBill", //  备货单--批量打印订单条码
  get_packageInfo_stock: "/packageInfo/stock/", // 查看备货单详情
  post_packageInfo_queryPackageGoodsById: "/packageInfo/queryPackageGoodsById", // 获取备货单sku列表数据
  post_packageInfo_exportPackageInfoStockOrder:
    "/packageInfo/exportPackageInfoStockOrder", // 导出备货数据
  post_packageInfo_excelImportStockOrder: '/packageInfo/excelImportStockOrder', // 备货单导入
  put_packageInfo_upatePackageGoodsQuantity:
    "/packageInfo/upatePackageGoodsQuantity", // 修改备货数量
  get_packageInfo_queryAllPackageGoodsById:
    "/packageInfo/queryAllPackageGoodsById/", // 获取当前备货单下所有sku信息
  post_packageInfo_obsoletePackageStock: "/packageInfo/obsoletePackageStock", // 取消备货

  post_ymsOperatingInstructions_api_queryListByParam:
    "/erpCommon/yms-core-service/ymsOperatingInstructions/api/queryListByParam", // 获取系统的操作指引数据
  get_ymsOperatingInstructions_api:
    "/erpCommon/yms-core-service/ymsOperatingInstructions/api/", // 查询指定指引的内容
  post_ymsNoticeInfo_queryNewNoticeInfo: "/ymsNoticeInfo/queryNewNoticeInfo", // 获取系统最新公告
  post_ymsNoticeInfo_disable: "/ymsNoticeInfo/disable", // 标记系统公告已读
  post_ymsNoticeInfo_queryNoticeInfoCount:
    "/ymsNoticeInfo/queryNoticeInfoCount", // 获取系统公告未读数据

  get_ymsLangDictionaryDetail_queryLang:
    "/erpCommon/yms-core-service/ymsLangDictionaryDetail/queryLang", // 获取系统的语种配置
  get_common_countrys_query: "/erpCommon/yms-core-service/common/country/query", // 获取所有国家的基础数据
  get_common_state_queryByCountryId:
    "/erpCommon/yms-core-service/common/state/queryByCountryId", // 根据countryId（国家id）获取对应省级区域的基础数据
  get_common_city_queryByStateCode:
    "/erpCommon/yms-core-service/common/city/queryByStateCode", // 根据code获取对应市级区域的基础数据
  get_common_district_queryByCityCode:
    "/erpCommon/yms-core-service/common/district/queryByCityCode", // 根据code获取对应区级区域的基础数据
  get_common_country_queryZoneCountry:
    "/erpCommon/yms-core-service/common/country/queryZoneCountry", // 获取地区国家的基础数据
  get_common_country_getDetailByTwoCode:
    "/erpCommon/yms-core-service/common/country/getDetailByTwoCode/", // 根据国家二次码获取省市区地址的基础数据
  get_ymsSupplierCommon_getPendingCount: "/ymsSupplierCommon/getPendingCount", // 获取供应商系统待处理业务数量

  post_ymsSupplierMerchant_confirmAccount:
    "/ymsSupplierMerchant/confirmAccount", // 确认开通供应商账户
  post_packageInfo_queryOvertimeUnsentList:
    "/packageInfo/queryOvertimeUnsentList", // 获取超时未发待处理的数据
  post_packageInfo_markOutStock: "/packageInfo/markOutStock", // 标记缺货

  post_listing_queryCategory: "/listing/queryCategory", // 查询站点对应的类目
  get_packageInfo_queryPackageInfoOrderTabTotal:
    "/packageInfo/queryPackageInfoOrderTabTotal", // 获取订单列表待处理的数量
  put_packageInfo_receivingOrders: "/packageInfo/receivingOrders", // 批量接单、拒绝接单
  get_ymsSupplierStationNotification_getProductReject:
    "/ymsSupplierStationNotification/getProductReject", // 获取商品驳回消息通知数据

  get_ymsDistributorMerchant_api_queryYmsCoreMerchant:
    "/erpCommon/yms-core-service/ymsDistributorMerchant/api/queryYmsCoreMerchant", // 获取运营商信息
  post_ymsProductCategory_api_queryDetailByCategoryIds:
    "/erpCommon/yms-core-service/ymsProductCategory/api/queryDetailByCategoryIds", // 根据分类id批量查询分类信息
  post_erpCommonSettingParam_api_queryByParam: "/erpCommon/yms-core-service/erpCommonSettingParam/api/queryByParam", // 查询自定义设置

  // 对接pod工厂端
  get_ymsPlatformAccount_queryPodAuthAccountList: "/ymsPlatformAccount/queryPodAuthAccountList", // 获取工厂下拉列表
  get_ymsProductCategory_api_navigation: "/erpCommon/yms-core-service/ymsProductCategory/api/productCategoryNavigation", // {productCategoryNavigation} 根据主料查询否能匹配上YMS商品类目表中类目信息
  post_productGoods_queryPodProductList: "/productGoods/queryPodProductList", //工厂商品查询
  post_erpCommonSettingParam_api_queryByUK: '/erpCommon/yms-core-service/erpCommonSettingParam/api/queryByUK', // 获取自定义导出的字段
};

for (let key in supplierApi) {
  supplierApi[key] = "/yms-supplier-service" + supplierApi[key];
}

export default supplierApi;
