import axios from 'axios';
import api from '@/api/';
import store from '@/store/store';
import {setToken, getToken, setTokenTime, getTokenTime} from './cookie';
import config from '@/config';
import Cookies from "js-cookie";
import {
  verifyLoginTicket,
  getOngoingActivities,
  getMallAdvertising,
  webDataCollectionFn,
  getQueryString,
  commonSessionStorage,
  isAlibabaDistributor, getProductSettings, getCommonParamKeysData, commonLocalStorage, getqueryByParamKeysData
} from "@/utils/common";
import Vue from "vue";
// 获取token
const versionName = 'YMS_version';

let pathname = window.location.pathname;
let system = '';
let targetService = '';
let pathnameList = [
  {system: 'yms', pathname: '/yms.html', service: 'yms-core-service'},
  {system: 'supplier', pathname: '/supplier.html', service: 'yms-supplier-service'},
  {system: 'distributor', pathname: '/distributor.html', service: 'yms-distributor-service'}
];
pathnameList.map((item) => {
  if (pathname.includes(item.pathname)) {
    targetService = item.service;
    system = item.system;
  }
});

let listingTimer = null;
export const getTokenByListing = () => {
  let ticket = getQueryString('ticket');
  return new Promise(resolve => {
    axios.get(api.get_token + ticket, {
      headers: {'Authorization-Special': 'no-Login'}
    }).then(response => {
      if (response.data.code === 0) {
        setToken(response.data.datas.token);
        resolve();
        clearTimeout(listingTimer);
        refreshTokenByListing();
      } else {
        resolve();
      }
    }).catch(() => {
      resolve();
    });
  });
};
export const refreshTokenByListing = () => {
  let v = this;
  axios.get(api.get_refresh_token + getToken()).then(response => {
    if (response.data.code === 0) {
      setToken(response.data.datas.token);
      listingTimer = setTimeout(function () {
        refreshTokenByListing();
      }, config.curTime);
    }
  });
};
export const getAccessToken = (path, value) => {
  return new Promise(resolve => {
    if (path.indexOf('ticket') > 0) {
      let ticket = getQueryString('ticket');
      axios.get(api.get_token + ticket).then(response => {
        if (response.data.code === 0) {
          setTokenTime(config.token_time);
          setToken(response.data.datas.token);
          localStorage.setItem('systemAnnouncementNotice', JSON.stringify(true));
          // 只有在商城和店铺的时候才需要缓存shop_token 的cookie值
          if (value) {
            let inFifteenMinutes = new Date(new Date().getTime() + (config.token_time + config.curTime));
            Cookies.set('shop_token', response.data.datas.token, {
              expires: inFifteenMinutes,
              path: '/',
              domain: '.yms.com'
            });
          }
          resolve(true);
        }
      });
    } else {
      resolve(true);
    }
  });
};
// 刷新token
export const refreshToken = (curTime, setShopToken) => {
  const timeout = getTokenTime();
  if (timeout <= config.curTime) {
    if (getToken()) {
      axios.get(api.get_refresh_token + getToken()).then(response => {
        if (response.data.code === 0) {
          setTokenTime(config.token_time);
          setToken(response.data.datas.token);
          if (setShopToken) {
            let inFifteenMinutes = new Date(new Date().getTime() + (config.token_time + config.curTime));
            let domain = window.location.host.replace(/.*?(?=\.)/, '');
            Cookies.set('shop_token', response.data.datas.token, {
              expires: inFifteenMinutes,
              path: '/',
              domain: domain
            });
          }
          setTimeout(function () {
            refreshToken(config.curTime, setShopToken);
          }, config.curTime);
        }
      });
    }
  } else {
    setTokenTime(timeout - curTime);
    setTimeout(function () {
      refreshToken(config.curTime, setShopToken);
    }, config.curTime);
  }
};
// 获取配置基本信息
export const getErpconfig = (type) => {
  getVersion();

  function configureBasicInformation(data) {
    return new Promise((resolve) => {
      let {merchant, userInfo} = data;
      let isAdmin = userInfo.isAdmin === '1' || false;
      store.commit('erpConfig', data);
      store.commit('warehouseId', data.warehouseId);
      store.commit('currency', data.currency);
      store.commit('isAdmin', isAdmin);
      commonSessionStorage.setItem('erpConfig', data);
      commonSessionStorage.setItem('isAdmin', isAdmin);
      localStorage.setItem('warehouseId', JSON.stringify(data.warehouseId));
      imInit({
        merchantId: merchant.merchantId,
        memberId: userInfo.userId,
        memberName: userInfo.userName,
        role: userInfo.roleName,
        loginUrl: null
      });
      webDataCollectionFn();
      getShoppingLanguage(userInfo, type).then(() => {
        resolve(true);
        // 分销商
        if (type === 'distributor') {
          getCommonParamKeysData(userInfo.merchantId, ['onlineBalanceRecharge', 'offlinePayment']).then((list) => {
            if (list.length > 0) {
              let obj = {};
              list.map((item) => {
                obj[item.paramKey] = item.paramValue === '1'
              });
              store.commit('distributorParamKeysSetting', obj);
              commonLocalStorage.setItem('distributorParamKeysSetting', obj);
            }
            resolve(true);
          })
        } else {
          resolve(true);
        }
      });
    });
  }

  return new Promise(resolve => {
    let cacheData = commonSessionStorage.getItem('erpConfig') || store.state.erpConfig;
    if (cacheData && Object.keys(cacheData).length > 0) {
      configureBasicInformation(cacheData).then(() => {
        resolve(cacheData);
      });
    } else {
      axios.get(api.get_erpConfig + '?time=' + new Date().getTime()).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            configureBasicInformation(data).then(() => {
              resolve(data);
            });
          }
        }
      });
    }
  });
};
export const imInit = ({memberId, memberName, merchantId, role, loginUrl} = {}) => {
  if (!isAlibabaDistributor()) {
    if (!window.tmsIm) return;
    let env;
    let origin = window.location.origin;
    if (origin.includes('dev')) {
      env = 'dev';
    } else if (origin.includes('localhost')) { // 本地代理开发默认走测试环境
      env = 'test';
    } else if (origin.includes('test')) {
      env = 'test';
    } else if (origin.includes('uat')) {
      env = 'uat';
    } else {
      env = 'pro';
    }
    new window.tmsIm({
      // 用户id
      memberId: memberId || '',
      // 用户名
      memberName: memberName || '',
      // 商户编号
      merchantId: merchantId || '',
      // 【必填】项目 "1": "erp2.0","2": "刊登","3": "官网","4": "YMS",
      projectCode: "4",
      // 用户角色
      role: role || '',
      // 引导登录地址
      loginUrl: loginUrl || '',
      // 【必填】连接im环境 可选值 dev test pro  对应开发环境、测试环境、生产环境
      env: env
    });
  }
};

export const getDistributorErpConfig = (isLogin) => {
  return new Promise(resolve => {
    let params = {};
    if (!isLogin) {
      params = {
        headers: {'Authorization-Special': 'no-Login'}
      };
    }
    axios.get(api.get_distributorErpConfig + '?time=' + new Date().getTime(), params).then(response => {
      if (response && response.data && response.data.code === 0) {
        let data = response.data.datas;
        let {merchant, userInfo} = data;
        let isAdmin = userInfo.isAdmin === '1' || false;
        store.commit('erpConfig', data);
        store.commit('currency', data.currency);
        store.commit('isAdmin', isAdmin);
        commonSessionStorage.setItem('isAdmin', isAdmin);
        commonSessionStorage.setItem('erpConfig', data);
        webDataCollectionFn();
        imInit({
          merchantId: merchant.merchantId,
          memberId: userInfo.userId,
          memberName: userInfo.userName,
          role: userInfo.roleName,
          loginUrl: null
        });
        getShoppingLanguage(userInfo, 'shopping').then(() => {
          resolve(response.data.datas);
        });
      } else {
        let href = window.location.href;
        imInit({
          loginUrl: window.location.origin + `/yms-shopping-service/login.html#/login?u=${href}`
        });
        getShoppingLanguage('', 'shopping').then(() => {
          resolve('noLogin');
        });
      }
    });
  });
};

function getVersion() {
  axios.get('./static/version.js', {
    headers: {'Cache-Control': 'no-cache'}
  }).then(data => {
    if (data && data.data.code === 0) {
      if (data.data.datas.version + '' !== localStorage.getItem(versionName) || !localStorage.getItem(versionName)) {
        localStorage.setItem(versionName, data.data.datas.version);
        window.location.reload();
      }
    }
  });
}


/**
 * @Description: 判断访问的页面是否存在，不存在即重定向到404页面
 * @author jing
 * @date 2021/6/17 13:37
 * @params:
 */

export const nonexistent = (to, next) => {
  if (!to.path.includes('ticket=')) {
    if (to.matched.length === 0) {
      next({
        path: '/noPersermission',
        query: {
          type: '404'
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
};
// 获取当前页面下的操作按钮的权限
export const getSinglePageRoleCommon = (menuKey, service) => {
  let subsystemCode = service ? service : targetService;
  return new Promise((resolve, reject) => {
    // supplier_productCenter_productGoods 外部创建页面
    if (['supplier_productDetailsByExternal'].includes(menuKey) || menuKey.indexOf('noPersermission') > 0) {
      resolve(true);
    } else {
      let url = service ? api.post_ymsDistributorUserInfo_queryOperByMenu : '';
      switch (system) {
        case 'yms':
          url = api.post_ymsCoreUserInfo_queryOperByMenu;
          break;
        case 'supplier':
          url = api.post_ymsSupplierUserInfo_queryOperByMenu;
          break;
        case 'distributor':
          url = api.post_ymsDistributorUserInfo_queryOperByMenu;
          break;
      }
      axios.post(url + menuKey + `?subsystemCode=${subsystemCode}`).then(response => {
        if (response.data.code === 0) {
          let pos = {};
          let data = response.data.datas || [];
          if (data.length > 0) {
            data.map((item) => {
              pos[item] = true;
            });
          }
          if (Object.keys(pos).length > 0) {
            commonSessionStorage.setItem('roleList', pos);
            store.commit('roleList', pos);
          }
          resolve(true);
        }
      });
    }
  });
};

// 获取用户菜单权限
export const getQueryMenu = (service) => {
  let subsystemCode = service ? service : targetService; // roleData
  return new Promise((resolve, reject) => {
    let roleData = commonSessionStorage.getItem('roleData') || store.state.roleData || [];
    if (roleData.length > 0) {
      store.commit('roleData', roleData);
      resolve(roleData);
    } else {
      let url = service ? api.get_ymsDistributorUserInfo_queryMenu : '';
      switch (system) {
        case 'yms':
          url = api.get_ymsCoreUserInfo_queryMenu;
          break;
        case 'supplier':
          url = api.get_ymsSupplierUserInfo_queryMenu;
          break;
        case 'distributor':
          url = api.get_ymsDistributorUserInfo_queryMenu;
          break;
      }
      axios.get(url + `?subsystemCode=${subsystemCode}`).then(response => {
        if (response.data.code === 0) {
          let newRoleData = response.data.datas;
          if (newRoleData) {
            commonSessionStorage.setItem('roleData', newRoleData)
            store.commit('roleData', newRoleData);
          }
          resolve(newRoleData);
        }
      });
    }
  });
};

// 登录也检查登录
export const checkLogin = () => {
  return new Promise(resolve => {
    axios.post(api.post_ymsUserInfo_checkLogin, {
      u: getQueryString('u')
    }).then((response) => {
      if (response.data.datas) {
        window.location.href = response.data.datas;
      }
      resolve();
    }).catch(() => {
      resolve();
    });
  });
};

/**
 * 获取商户校验信息 缺少信息会跳转绑定信息页面
 * @date: 2022/3/28 15:19
 */
export const getValidMerchant = () => {
  return new Promise(resolve => {
    verifyLoginTicket().then((val) => {
      if (val) {
        axios.get(api.get_ymsDistributorCommon_validMerchant).then(() => {
          resolve(true);
        }).catch(() => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  });
};

// 查询当前商城、分销商设置的语种
export const getShoppingLanguage = (userInfo, type) => {
  return new Promise((resolve) => {
    if (['distributor', 'shopping'].includes(type)) {
      let key = 'language';
      let configureLanguage = localStorage.getItem('configureLanguage');
      let languageData = store.state.configureLanguage ? store.state.configureLanguage : configureLanguage ? JSON.parse(configureLanguage) : [];
      if (Object.keys(userInfo).length > 0) {
        let query = {
          paramKeys: [key],
          businessId: userInfo.userId
        }
        axios.post(api.post_erpCommonSettingParam_queryByUK, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            let val = '';
            if (data.length > 0) {
              data.map((item) => {
                if (item.paramKey === key) {
                  val = item.paramValue;
                  if (languageData.length > 0) {
                    languageData.map((ele) => {
                      if (ele.code === val) {
                        store.commit('setLanguages', val);
                        localStorage.setItem('setLanguages', val);
                        localStorage.setItem('lang', ele.name);
                        Vue.config.lang = ele.name;
                      }
                    })
                  }
                }
              });
            }
            // 如果当前商户没有设置语种，默认拿当前浏览器设置的语言
            else {
              getSystemLanguage(userInfo);
            }
            handleQuery(type)
          }
        });
      } else {
        let val = localStorage.getItem('lang');
        if (languageData.length > 0) {
          if (val) {
            languageData.map((item) => {
              if (item.name === val) {
                let flagPath = item.iconPath;
                let selectLang = item.title;
                commonSessionStorage.setItem('flagPath', flagPath);
                commonSessionStorage.setItem('selectLang', selectLang);

                store.commit('setLanguages', item.code);
                localStorage.setItem('setLanguages', item.code);
              }
            });
            handleQuery(type)
          } else {
            getSystemLanguage();
          }
        } else {
          getSystemLanguage();
        }
        handleQuery(type)
      }

      function handleQuery(type) {
        Promise.all([getOngoingActivities(type), getMallAdvertising(type), getProductSettings()]).then(result => {
          if (result[0] && result[1] && result[2]) {
            resolve(true);
          }
        });
      }
    } else {
      resolve(true);
    }
  });
}

// 获取系统配置的语种
export const getConfigureLanguage = () => {
  return new Promise((resolve, reject) => {
    let configureLanguage = store.state.configureLanguage || [];
    if (configureLanguage.length > 0) {
      resolve(configureLanguage);
    } else {
      axios.get(api.get_ymsLangDictionaryDetail_queryLang).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            localStorage.setItem('configureLanguage', JSON.stringify(data));
            store.commit('configureLanguage', data);
            // 判断是否是在店铺域名下
            let talg = getStoreDomainName();
            if (talg) {
              localStorage.setItem('lang', talg);
              Vue.config.lang = talg;
            }
            resolve(data);
          }
        } else {
          resolve([]);
        }
      });
    }
  });
};
// 获取店铺域名中的语种参数
export const getStoreDomainName = () => {
  let origin = window.location.origin;
  const currentURL = window.location.href;
  let lang = '';
  if (origin.includes('shop') && origin.includes('.yms.com')) {
    const urlObj = new URL(currentURL);
    lang = urlObj.searchParams.get('lang');
  }
  return lang;
}

// 获取当前浏览器中默认设置的语言
export const getSystemLanguage = (userInfo) => {
  let langTalg = localStorage.getItem('lang');
  let configureLanguage = localStorage.getItem('configureLanguage');
  if (userInfo && Object.keys(userInfo).length > 0) {
    getqueryByParamKeysData([{businessId: userInfo.merchantId, paramKeys: ['defaultLanguage']}]).then((data) => {
      if (data.length > 0) {
        let paramValue = data[0].paramValue;
        if (configureLanguage) {
          let newList = JSON.parse(configureLanguage);
          if (newList.length > 0) {
            newList.map((item) => {
              if (item.code === paramValue) {
                let flagPath = item.iconPath;
                let selectLang = item.title;
                commonSessionStorage.setItem('flagPath', flagPath);
                commonSessionStorage.setItem('selectLang', selectLang);
              }
            })
          }
        }
      } else {
        handleData();
      }
    })
  } else {
    handleData();
  }

  function handleData() {
    if (!langTalg) {
      let languageData = store.state.configureLanguage ? store.state.configureLanguage : configureLanguage ? JSON.parse(configureLanguage) : [];
      let language = '';
      // 当用户没有设置语种，默认拿当前浏览器设置的语言
      let defaultLanguage = window.navigator.language;
      if (defaultLanguage) {
        if (['zh', 'zh-CN'].includes(defaultLanguage)) {
          language = 'zh-CN';
        } else if (['en', 'en-US'].includes(defaultLanguage)) {
          language = 'en-US';
        } else {
          language = 'zh-CN';
        }
        let setLanguages = language.split('-')[1];
        localStorage.setItem('lang', language);
        store.commit('setLanguages', setLanguages);
        localStorage.setItem('setLanguages', setLanguages);
        if (languageData.length > 0) {
          languageData.map((item) => {
            if (item.code === language) {
              let flagPath = item.iconPath;
              let selectLang = item.title;
              commonSessionStorage.setItem('flagPath', flagPath);
              commonSessionStorage.setItem('selectLang', selectLang);
            }
          });
        }
      }
    } else {
      let setLanguages = langTalg.split('-')[1];
      store.commit('setLanguages', setLanguages);
      localStorage.setItem('setLanguages', setLanguages);
      if (configureLanguage) {
        let newList = JSON.parse(configureLanguage);
        if (newList.length > 0) {
          newList.map((item) => {
            if (item.code === langTalg) {
              let flagPath = item.iconPath;
              let selectLang = item.title;
              commonSessionStorage.setItem('flagPath', flagPath);
              commonSessionStorage.setItem('selectLang', selectLang);
            }
          })
        }
      }
    }
  }
}

// 处理浏览器系统语种的数据
export const handleSystemLanguage = () => {
  let language = '';
  let defaultLanguage = window.navigator.language;
  if (defaultLanguage) {
    if (['zh', 'zh-CN'].includes(defaultLanguage)) {
      language = 'zh-CN';
    } else if (['en', 'en-US'].includes(defaultLanguage)) {
      language = 'en-US';
    } else {
      language = 'zh-CN';
    }
    return language;
  }
}
