export default {
  key1000000: `copy`,
  key1000001: `Title:`,
  key1000002: `Link address:`,
  key1000003: `The content has been deactivated or deleted!`,
  key1000004: `Successfully copied!`,
  key1000005: `Please enter the menu keywords`,
  key1000006: `Please enter the correct keywords`,
  key1000007: `Please enter a keyword`,
  key1000008: `Publishing tools`,
  key1000009: `Transit Listing`,
  key1000010: `YMS Mall`,
  key1000011: `Please enter keywords.`,
  key1000012: `search`,
  key1000013: `All product categories`,
  key1000014: `Currently, there are`,
  key1000015: `The order has exceeded the deadline and has not been shipped,`,
  key1000016: `Please click to process`,
  key1000017: `operation management`,
  key1000018: `Warehouse Management`,
  key1000019: `View more`,
  key1000020: `Time zone:`,
  key1000021: `Your current time:`,
  key1000022: `UTC:`,
  key1000023: `Log out`,
  key1000024: `Operating system`,
  key1000025: `Supplier system`,
  key1000026: `Distributor System`,
  key1000027: `Merchant ID:`,
  key1000028: `Tongtool Customer code:`,
  key1000029: `Exiting`,
  key1000030: `Become a distributor`,
  key1000031: `Sign up now`,
  key1000032: `Becoming a supplier`,
  key1000033: `Supplier settlement`,
  key1000034: `Entry Guide`,
  key1000035: `Supplier login`,
  key1000036: `Help Center`,
  key1000037: `common problem`,
  key1000038: `Legal Notices`,
  key1000039: `Terms of Service`,
  key1000040: `Privacy Policy`,
  key1000041: `About Us`,
  key1000042: `Introduction to YMS Mall`,
  key1000043: `Platform business`,
  key1000044: `Contact us`,
  key1000045: `Business Consulting`,
  key1000046: `Distributor Online Services`,
  key1000047: `Shenzhen Yunmai Supply Chain Management Co., Ltd.`,
  key1000048: `Record number: Guangdong ICP No. 2020140415-1`,
  key1000049: `Many`,
  key1000050: `Massive high-quality supply`,
  key1000051: `20w+SKU, daily update of 500+`,
  key1000052: `Quick`,
  key1000053: `One-click upload to all platforms`,
  key1000054: `A must for opening a store, open a store in 5 minutes`,
  key1000055: `Okay`,
  key1000056: `Professional team quality assurance`,
  key1000057: `Professional picking, sorting, packaging, shipping and other services`,
  key1000058: `Province`,
  key1000059: `0 inventory to open a store, no financial pressure`,
  key1000060: `No need to stock up and stock up, pay first and then pay`,
  key1000061: `Please enter keywords to search`,
  key1000062: `Upload image search`,
  key1000063: `One-stop cross-border clothing distribution`,
  key1000064: `The format of the uploaded file is incorrect,`,
  key1000065: `Please select a file in jpg, jpeg, or png format`,
  key1000066: `File size is limited, file`,
  key1000067: `too big,`,
  key1000068: `No more than 20M`,
  key1000069: `All products`,
  key1000070: `Discontinued`,
  key1000071: `Country of Sale:`,
  key1000072: `Merchant number:`,
  key1000073: `Exit`,
  key1000074: `Please log in`,
  key1000075: `free registration`,
  key1000076: `Message`,
  key1000077: `Getting started`,
  key1000078: `personal center`,
  key1000079: `my collection`,
  key1000080: `My Order`,
  key1000081: `Hi, welcome to TONGTOOL!`,
  key1000082: `Hi, welcome to YMS Mall!`,
  key1000083: `Search from the whole site`,
  key1000084: `Search from our shop`,
  key1000085: `The operation was successful!`,
  key1000086: `product name:`,
  key1000087: `Please select`,
  key1000088: `No matching data`,
  key1000089: `Loading`,
  key1000090: `No Data`,
  key1000091: `More`,
  key1000092: `Delete`,
  key1000093: `No filter data`,
  key1000094: `Confirm`,
  key1000095: `Reset`,
  key1000096: `All`,
  key1000097: `Confirm`,
  key1000098: `Cancel`,
  key1000099: `Custom Column Display`,
  key1000100: `Custom column display`,
  key1000101: `Restore default`,
  key1000102: `Save as default`,
  key1000103: `Save successfully`,
  key1000104: `More Options(Recipient country, Payment date, etc.)`,
  key1000105: `Last week`,
  key1000106: `Last month`,
  key1000107: `Last three months`,
  key1000108: `Spot shipping`,
  key1000109: `Logistics waybill`,
  key1000110: `Yuncang noodles`,
  key1000111: `No waybill`,
  key1000112: `Mixed waybill`,
  key1000113: `File size exceeds the limit, up to 10M`,
  key1000114: `The system is busy and the file upload failed!`,
  key1000115: `File format error`,
  key1000116: `pack up`,
  key1000117: `No relevant records`,
  key1000118: `System Busy, Please Try Again`,
  key1000119: `Password can not be blank`,
  key1000120: `The password cannot contain Chinese characters`,
  key1000121: `Please fill in the email address.`,
  key1000122: `Not valid mailbox or incorrect mailbox format`,
  key1000123: `Mobile phone number cannot be empty`,
  key1000124: `Incorrect phone number format`,
  key1000125: `Only numeric format numbers are allowed to be entered`,
  key1000126: `The fixed phone number format is incorrect`,
  key1000127: `The address of a province or city cannot be empty`,
  key1000128: `Please select the complete province/city address`,
  key1000129: `Confirm whether to remove`,
  key1000130: `Tips`,
  key1000131: `Successful operation`,
  key1000132: `Parcel number`,
  key1000133: `Data coverage reminder`,
  key1000134: `The new sales product information will overwrite the already generated sales product information, which cannot be restored after generation. Do you want to generate it?`,
  key1000135: `Generate Now`,
  key1000136: `Please check the data to generate sales information first!`,
  key1000137: `The task has been successfully generated. Please check the results in the Generate Sales Information module.`,
  key1000138: `The current user does not have warehouse management settings that are not enabled`,
  key1000139: `Got it`,
  key1000140: `The current user has enabled warehouse management settings`,
  key1000141: `Price adjustment`,
  key1000142: `Jump failed!`,
  key1000143: `General cargo`,
  key1000144: `Pure battery`,
  key1000145: `Battery matching`,
  key1000146: `liquid`,
  key1000147: `Drugs`,
  key1000148: `powder`,
  key1000149: `Dangerous goods`,
  key1000150: `Built-in battery`,
  key1000151: `Button Battery`,
  key1000152: `With magnetic`,
  key1000153: `1 day`,
  key1000154: `2 days`,
  key1000155: `3 days`,
  key1000156: `4 days`,
  key1000157: `5 days`,
  key1000158: `6 days`,
  key1000159: `7 days`,
  key1000160: ` same`,
  key1000161: `image`,
  key1000162: `Unmatched`,
  key1000163: `Specified products`,
  key1000164: `Designated site`,
  key1000165: `Designated account number`,
  key1000166: `and`,
  key1000167: `or`,
  key1000168: `with`,
  key1000169: `not with`,
  key1000170: `Transportation type is null.`,
  key1000171: `Add success`,
  key1000172: `Operation failed, please try again.`,
  key1000173: `Delete failed, please try again`,
  key1000174: `Designated shop`,
  key1000175: `designated country`,
  key1000176: `Specified classification`,
  key1000177: `Please enter a full postcode.`,
  key1000178: `Designate basic logistics`,
  key1000179: `Multiple products`,
  key1000180: `Single item`,
  key1000181: `Pick list:`,
  key1000182: `Picking warehouse:`,
  key1000183: `Logistics provider:`,
  key1000184: `Picking order type:`,
  key1000185: `Number of outbound orders:`,
  key1000186: `Creation time:`,
  key1000187: `Number of SKUs:`,
  key1000188: `Pickers:`,
  key1000189: `Number of SKUs:`,
  key1000190: `Storage area`,
  key1000191: `Storage bin`,
  key1000192: `Quantity to be picked`,
  key1000193: `Chinese description`,
  key1000194: `Product variation`,
  key1000195: `English description`,
  key1000196: `Batch No.`,
  key1000197: `Logistics providers`,
  key1000198: `Waiting`,
  key1000199: `Logistics channel`,
  key1000200: `Multiple shipping method`,
  key1000201: `Select the referenced channel`,
  key1000202: `Please select a logistics provider:`,
  key1000203: `Please select a logistics channel:`,
  key1000204: `Logistics channel name cannot be null.`,
  key1000205: `Close`,
  key1000206: `SKU Information`,
  key1000207: `Specifications:`,
  key1000208: `Allocated quantity`,
  key1000209: `Quantity Available`,
  key1000210: `The available quantity must be an integer greater than or equal to 0`,
  key1000211: `Supplier Inventory Details`,
  key1000212: `Details of central warehouse storage`,
  key1000213: `Processing...`,
  key1000214: `Choose category`,
  key1000215: `Selected category name:`,
  key1000216: `Operation log`,
  key1000217: `attribute`,
  key1000218: `Attribute value`,
  key1000219: `Operation type`,
  key1000220: `description of operation content`,
  key1000221: `operation time`,
  key1000222: `Operator`,
  key1000223: `Distributor Operations`,
  key1000224: `Supplier`,
  key1000225: `Original value:`,
  key1000226: `After modification:`,
  key1000227: `Edit Properties`,
  key1000228: `Required`,
  key1000229: `Not required`,
  key1000230: `Recommended`,
  key1000231: `Specification properties`,
  key1000232: `Normal attributes`,
  key1000233: `Multiple selection of attribute values`,
  key1000234: `Single Choice of Attribute Values`,
  key1000235: `It is a size attribute`,
  key1000236: `Not a size attribute`,
  key1000237: `Allow custom attribute values`,
  key1000238: `Custom attribute values are not allowed`,
  key1000239: `Edit attribute values`,
  key1000240: `处理费修改：`,
  key1000241: `物流费修改：`,
  key1000242: `Original price:`,
  key1000243: `货品金额修改：`,
  key1000244: `Menu settings`,
  key1000245: `Quick search sidebar menu`,
  key1000246: `：warehouse:`,
  key1000247: `Upload file:`,
  key1000248: `File Name:`,
  key1000249: `When imported data already exists:`,
  key1000250: `Please select the file`,
  key1000251: `Template download`,
  key1000252: `ignore`,
  key1000253: `Update`,
  key1000254: `Overwrite`,
  key1000255: `add`,
  key1000256: `Import succeeded. The currently imported ymsSku does not exist:`,
  key1000257: `Warehouse`,
  key1000258: `Import`,
  key1000259: `Failed to upload files, please try again`,
  key1000260: `Tips`,
  key1000261: `Upload success`,
  key1000262: `The storage area of the location does not match:`,
  key1000263: `The selected warehouse does not have this storage area. Please verify and try again`,
  key1000264: `The selected warehouse does not have this location. Please verify and try again`,
  key1000265: `There is duplicate data, please confirm and try again`,
  key1000266: `There is an exception in importing the new inventory owner role, please check and try again.`,
  key1000267: `Supplier Merchant Error Importing New Inventory`,
  key1000268: `Download file template failed!`,
  key1000269: `Please select an upload file first`,
  key1000270: `Export type:`,
  key1000271: `Task Number:`,
  key1000272: `Export time:`,
  key1000273: `Select a date`,
  key1000274: `query`,
  key1000275: `Serial number`,
  key1000276: `Task Number`,
  key1000277: `export type`,
  key1000278: `Export time`,
  key1000279: `export status`,
  key1000280: `Exporting`,
  key1000281: `System operation`,
  key1000282: `Remarks`,
  key1000283: `Operation`,
  key1000284: `The file is kept for 7 days, and it cannot be downloaded after 7 days!`,
  key1000285: `download files`,
  key1000286: `in exporting`,
  key1000287: `complete export`,
  key1000288: `Failed to export`,
  key1000289: `Date format error`,
  key1000290: `Message Center`,
  key1000291: `View all notifications`,
  key1000292: `Please input`,
  key1000293: `Selected`,
  key1000294: `term`,
  key1000295: `Submit`,
  key1000296: `skip`,
  key1000297: `other`,
  key1000298: `Required fields cannot be empty!`,
  key1000299: `Please enter category keywords`,
  key1000300: `All categories`,
  key1000301: `Goods available for sale:`,
  key1000302: `No options available`,
  key1000303: `Setting of commodity selling country`,
  key1000304: `Modification of the country of sale of goods`,
  key1000305: `设置售卖国成功！`,
  key1000306: `编辑售卖国成功！`,
  key1000307: `Main material selection`,
  key1000308: `Factory:`,
  key1000309: `Factory SPU`,
  key1000310: `Please enter factory SPU`,
  key1000311: `Factory SKU`,
  key1000312: `Please enter factory SKU`,
  key1000313: `Commodity name`,
  key1000314: `Please enter the product name`,
  key1000315: `Factory cannot be empty`,
  key1000316: `Product information`,
  key1000317: `Factory SPU:`,
  key1000318: `Factory SKU:`,
  key1000319: `Classification`,
  key1000320: `POD platform not bound`,
  key1000321: `The POD platform has not been bound yet. Please try again after binding.`,
  key1000322: `Go to Bind`,
  key1000323: `关联属性管理`,
  key1000324: `新增关联属性`,
  key1000325: `Select from Property Pool`,
  key1000326: `Specification`,
  key1000327: `ordinary`,
  key1000328: `Yes`,
  key1000329: `No`,
  key1000330: `Single choice`,
  key1000331: `Multiple choices`,
  key1000332: `Property Chinese name`,
  key1000333: `Attribute value type`,
  key1000334: `Optional value`,
  key1000335: `Custom`,
  key1000336: `Do you want to customize it`,
  key1000337: `Fill in the requirements`,
  key1000338: `Single/Multiple Choice`,
  key1000339: `See`,
  key1000340: `Remove`,
  key1000341: `是否确认将该属性移出关联属性列表？`,
  key1000342: `Please enter the name of the carrier`,
  key1000343: `Carrier Name:`,
  key1000344: `Carrier`,
  key1000345: `Carrier name`,
  key1000346: `Carrier phone`,
  key1000347: `Usable or not`,
  key1000348: `available`,
  key1000349: `Unavailable`,
  key1000350: `Pick`,
  key1000351: `The name of the carrier cannot be empty!`,
  key1000352: `Own site:`,
  key1000353: `Specifications/General Properties:`,
  key1000354: `Attribute value type:`,
  key1000355: `Attribute value single selection/multiple selection:`,
  key1000356: `Fill in requirements:`,
  key1000357: `Size attribute:`,
  key1000358: `Whether to fill in the proportion:`,
  key1000359: `Attribute name`,
  key1000360: `属性单位`,
  key1000361: `Size Group Name:`,
  key1000362: `添加属性单位`,
  key1000363: `Allow customization`,
  key1000364: `Add attribute values`,
  key1000365: `The property has been used and cannot be modified.`,
  key1000366: `The attribute value has been used and cannot be modified.`,
  key1000367: `属性单位（`,
  key1000368: `New attributes`,
  key1000369: `Chinese Name `,
  key1000370: `English-US`,
  key1000371: `English-UK`,
  key1000372: `English-AU`,
  key1000373: `English-EU`,
  key1000374: `French name`,
  key1000375: `German name`,
  key1000376: `Spanish name`,
  key1000377: `Dutch name`,
  key1000378: `Polish name`,
  key1000379: `Portuguese name`,
  key1000380: `Italian name`,
  key1000381: `Romanian name`,
  key1000382: `Code`,
  key1000383: `Code`,
  key1000384: `Spanish Code`,
  key1000385: `Dutch Code`,
  key1000386: `Poland Code`,
  key1000387: `Portugal Code`,
  key1000388: `Italian Code`,
  key1000389: `Romania Code`,
  key1000390: `An attribute with the same name already exists. Please modify the attribute name before submitting!`,
  key1000391: `Chinese, English US attribute names cannot be empty!`,
  key1000392: `The attribute value of the same language must be filled in!`,
  key1000393: `Attribute values cannot be the same in the same language!`,
  key1000394: `Add Successfully`,
  key1000395: `Failed to upload`,
  key1000396: `Cancel this size group?`,
  key1000397: `Confirm to cancel this size group? After confirmation, this size group will not be displayed in the attribute value, and the filled data will be cleared.`,
  key1000398: `OK Cancel`,
  key1000399: `state`,
  key1000400: `Enable`,
  key1000401: `Disable`,
  key1000402: `Reference image`,
  key1000403: `Upload pictures`,
  key1000404: `brief introduction`,
  key1000405: `请填写属性单位`,
  key1000406: `同语种下属性单位不能相同`,
  key1000407: `Please fill in the country size information`,
  key1000408: `Attribute values cannot be the same under the same language`,
  key1000409: `Reminder later`,
  key1000410: `Successfully reviewed, the system will send an announcement at the specified time`,
  key1000411: `Successfully returned`,
  key1000412: `Cancellation success`,
  key1000413: `The operation was successful！`,
  key1000414: `Add multiple pictures`,
  key1000415: `Selected images to upload:`,
  key1000416: `Picture requirements:`,
  key1000417: `It is recommended to upload large real images of squares with 800*800 pixels or more, for example: 800*800px,1000*1000px,1500 * 1500px, with a maximum of no more than 5MB. If the size is not met, the upload will fail.`,
  key1000418: `Clear images without watermarks;`,
  key1000419: `Include the overall style and product details of the product, such as selfie model images, poster images, white background images, 3D images, detail images, etc.`,
  key1000420: `4. Click on the picture to use the cutting tools provided by the system to help you better display the characteristics of the goods!`,
  key1000421: `Click Crop`,
  key1000422: `Delete Picture`,
  key1000423: `OK to upload`,
  key1000424: `Picture failed to load`,
  key1000425: `File size exceeds limit, max 5M!`,
  key1000426: `Upload image format error! The system only supports uploading images in JPG, JPEG, PNG, WEBP, and BMP formats.`,
  key1000427: `upload pictures in...`,
  key1000428: `Have read and accepted`,
  key1000429: `and`,
  key1000430: `Successfully opened!`,
  key1000431: `Need to read and accept the service terms and privacy policy`,
  key1000432: `Out of stock quantity cannot be empty`,
  key1000433: `Expected delivery time cannot be empty`,
  key1000434: `Please select the estimated delivery time`,
  key1000435: `View by Order`,
  key1000436: `View by SKU`,
  key1000437: `stockout`,
  key1000438: `Not out of stock`,
  key1000439: `replenishment`,
  key1000440: `Halt sales`,
  key1000441: `The system will automatically suspend the sale of this SKU`,
  key1000442: `A maximum of 100 rows of order data can be processed in a single pop-up window`,
  key1000443: `Process Later`,
  key1000444: `Confirm`,
  key1000445: `Timed order processing`,
  key1000446: `Order No.`,
  key1000447: `Quantity `,
  key1000448: `Out of stock quantity`,
  key1000449: `Estimated delivery time`,
  key1000450: `mark as out-of-stock`,
  key1000451: `The following order has expired. Please update the estimated delivery time.`,
  key1000452: `The selected order product information is as follows:`,
  key1000453: `The expected delivery time should be greater than today's date!`,
  key1000454: `Successfully marked!`,
  key1000455: `Import Freight Template`,
  key1000456: `Billing method:`,
  key1000457: `Weight calculation method:`,
  key1000458: `Select the file:`,
  key1000459: `Import:`,
  key1000460: `(Volume weight:`,
  key1000461: `Bulk upload`,
  key1000462: `Download`,
  key1000463: `Save`,
  key1000464: `General accounting`,
  key1000465: `Continuous recosting`,
  key1000466: `fixed charges`,
  key1000467: `Actual weight`,
  key1000468: `Throwing weight`,
  key1000469: `Comparison between the two`,
  key1000470: `Overwrite the original data`,
  key1000471: `The same coverage of the country, different additions`,
  key1000472: `Select upload file`,
  key1000473: `select designated exception`,
  key1000474: `This condition is used to screen abnormal situations. If any one of the following conditions is met, this condition is considered to be satisfied.`,
  key1000475: `The number of spaces in the name characters is less than`,
  key1000476: `Russian Post requires to write the full name of recipient, here you can enter 2`,
  key1000477: `The number of name characters is less than`,
  key1000478: `Entering 1 means the recipient name is empty.`,
  key1000479: `The character length of address is less than`,
  key1000480: `The total character length of address1 and address2`,
  key1000481: `The character length of city name is less than`,
  key1000482: `Entering 1 means the city name is empty.`,
  key1000483: `The character length of province/state is less than`,
  key1000484: `Entering 1 means the province/state name is empty.`,
  key1000485: `The character length of postcode is less than`,
  key1000486: `Entering 1 means the zip code is empty.`,
  key1000487: `The character length of telephone number is less than`,
  key1000488: `The condition will be established only if both telephone number and cellphone number are less than the value.`,
  key1000489: `Automatically generated after approval`,
  key1000490: `Category:`,
  key1000491: `Product status:`,
  key1000492: `Chinese title:`,
  key1000493: `Supplier SPU:`,
  key1000494: `Number of SKUs:`,
  key1000495: `Total number of products:`,
  key1000496: `Temporary storage`,
  key1000497: `on sale`,
  key1000498: `Supplier SKU`,
  key1000499: `Product Status`,
  key1000500: `Supply price`,
  key1000501: `on shelf`,
  key1000502: `Print barcode`,
  key1000503: `Print QR code`,
  key1000504: `Are you sure to delete?`,
  key1000505: `Print prompt`,
  key1000506: `SKU contains Chinese and currently does not support barcode printing. Please use QR code printing!`,
  key1000507: `Reason for rejection`,
  key1000508: `A large number of spot`,
  key1000509: `Promote with confidence`,
  key1000510: `Payment`,
  key1000511: `勾选中`,
  key1000512: `条订单`,
  key1000513: `Payment method:`,
  key1000514: `账户余额支付`,
  key1000515: `上传支付凭证`,
  key1000516: `平台提示：`,
  key1000517: `平台会根据订单的应付总额使用账户余额进行预扣，请确保账户有足够的余额。`,
  key1000518: `平台会在“付款管理”列表中发起一条付款信息，可于此处上传支付凭证。`,
  key1000519: `Payment successful`,
  key1000520: `Picture Cropping`,
  key1000521: `Cutting Instructions`,
  key1000522: `1. In order to ensure that your products can be better displayed to distributors and bring more orders, the length and width of the uploaded product pictures must be ≥ 800.`,
  key1000523: `2. If the length and width of the originally uploaded picture are less than or equal to 800, the picture needs to be uploaded again.`,
  key1000524: `3. Merchants can adjust the display proportion of uploaded pictures by cutting to highlight the characteristics of goods.`,
  key1000525: `Picture width:`,
  key1000526: `Picture height:`,
  key1000527: `Preview`,
  key1000528: `+ Re-upload pictures`,
  key1000529: `Picture size at least ≥`,
  key1000530: `and`,
  key1000531: `File size exceeds limit, maximum is`,
  key1000532: `loading pictures in...`,
  key1000533: `Crop Image. jpeg`,
  key1000534: `return`,
  key1000535: `Return instructions:`,
  key1000536: `Please enter a return description`,
  key1000537: `After returning, this announcement will be circulated as pending modification. Please confirm whether to return the modification?`,
  key1000538: `Return description cannot be empty`,
  key1000539: `Log content`,
  key1000540: `Change to`,
  key1000541: `Operation date`,
  key1000542: `Operating system operation`,
  key1000543: `Supplier system operation`,
  key1000544: `SPU price adjustment`,
  key1000545: `Partial SKU price adjustment`,
  key1000546: `SKU price adjustment`,
  key1000547: `Issue orders of different storage area groups are allowed to enter the same pick list？`,
  key1000548: `Issue orders from different storage area areas are allowed to enter the same pick list？`,
  key1000549: `Packages from different logistics providers are allowed to enter the same picking list？`,
  key1000550: `Allow packages of the same logistics provider with different shipping methods to enter the same picking list？`,
  key1000551: `Maximum number of packages per regular picking list`,
  key1000552: `Maximum number of packages per stocking and picking list`,
  key1000553: `Allow`,
  key1000554: `Not allowed`,
  key1000555: `Choose a store to publish`,
  key1000556: `Site:`,
  key1000557: `Publishing store:`,
  key1000558: `Publication template:`,
  key1000559: `Set up`,
  key1000560: `Please select a publishing store`,
  key1000561: `Please select a publication template`,
  key1000562: `Failed to publish draft`,
  key1000563: `Message notification`,
  key1000564: `No more data...`,
  key1000565: `Let's see later`,
  key1000566: `Your goods:`,
  key1000567: `, the audit failed, the reason for rejection:`,
  key1000568: `in processing`,
  key1000569: `Marked as read!`,
  key1000570: `cannot be empty`,
  key1000571: `Supplier Currency`,
  key1000572: `Add Supplier Currency`,
  key1000573: `Add Distributor Currency`,
  key1000574: `Change currency`,
  key1000575: `Distributor Currency`,
  key1000576: `Currency`,
  key1000577: `Inventory event details`,
  key1000578: `Inventory change type:`,
  key1000579: `Operating time:`,
  key1000580: `Select start date`,
  key1000581: `Select end date`,
  key1000582: `to`,
  key1000583: `Export excel`,
  key1000584: `Changes in available inventory`,
  key1000585: `Allocated inventory changes`,
  key1000586: `Event type`,
  key1000587: `Allocate inventory`,
  key1000588: `Cancel distribution`,
  key1000589: `Outbound`,
  key1000590: `Check inventory`,
  key1000591: `Sort Recall`,
  key1000592: `Processing and warehousing`,
  key1000593: `Processing outbound`,
  key1000594: `Available inventory balance`,
  key1000595: `Allocation inventory changes`,
  key1000596: `Allocation of inventory balance`,
  key1000597: `Reference no.`,
  key1000598: `receive the products`,
  key1000599: `Allocate`,
  key1000600: `Unassign`,
  key1000601: `Inventory movement`,
  key1000602: `Stock freeze`,
  key1000603: `Unfreeze`,
  key1000604: `Inventory adjustment`,
  key1000605: `Transfer out`,
  key1000606: `Receipt on shelf`,
  key1000607: `Adjust the order`,
  key1000608: `Cancel out`,
  key1000609: `Synchronize inventory`,
  key1000610: `Cancel receiving`,
  key1000611: `Recycle inventory`,
  key1000612: `Return to the warehouse`,
  key1000613: `Import inventory`,
  key1000614: `Update inventory`,
  key1000615: `Failed to get list data`,
  key1000616: `Select Date Time`,
  key1000617: `Please enter the template name`,
  key1000618: `The generated report file can be kept by the system for a maximum of 7 days, and downloading is not supported if it exceeds 7 days.`,
  key1000619: `View Export List`,
  key1000620: `Export template:`,
  key1000621: `Manage export templates`,
  key1000622: `Back`,
  key1000623: `Time range:`,
  key1000624: `Please select an export dimension:`,
  key1000625: `Please check the fields to be exported:`,
  key1000626: `Select All`,
  key1000627: `Save as new template`,
  key1000628: `export`,
  key1000629: `Not selected`,
  key1000630: `added successfully!`,
  key1000631: `The template name cannot be empty!`,
  key1000632: `Do you want to delete the current template?`,
  key1000633: `Deleting Success`,
  key1000634: `Select a shipping method`,
  key1000635: `Pack up`,
  key1000636: `spread`,
  key1000637: `Image upload specifications`,
  key1000638: `Uploading specifications for rotation charts`,
  key1000639: `It is recommended to upload images in the following order, with a reasonable and clear image sequence for easy viewing of products.`,
  key1000640: `Single image size`,
  key1000641: `The recommended size is 800 * 800px or above (800 * 800px, 1000 * 1000px, 1500 * 1500px), and the maximum size should not exceed 5MB.`,
  key1000642: `The proportion is square, and the appropriate size can clearly see the details.`,
  key1000643: `I got it!`,
  key1000644: `Enter can enter custom attributes`,
  key1000645: `Please select a specification`,
  key1000646: `Essential information`,
  key1000647: `Type:`,
  key1000648: `Product category:`,
  key1000649: `Commodity number/SPU:`,
  key1000650: `Please enter the product number/SPU`,
  key1000651: `Select the main material:`,
  key1000652: `Product rotation chart:`,
  key1000653: `Batch edit assembly information:`,
  key1000654: `Image type:`,
  key1000655: `Product title composition: Product features+product category, no need to add words such as year, eBay, Amazon, etc`,
  key1000656: `Commodity attributes`,
  key1000657: `仅限输入数字及小数点`,
  key1000658: `Price Inventory`,
  key1000659: `Service and Commitment`,
  key1000660: `Commodity service:`,
  key1000661: `Delivery time:`,
  key1000662: `Product sales suggestions`,
  key1000663: `Viewing Examples`,
  key1000664: `Assembly information`,
  key1000665: `The main attribute image cannot be empty`,
  key1000666: `Supply Price`,
  key1000667: `Set this item in`,
  key1000668: `After the cumulative sales reach a certain number of preferential rules, this discount will be directly given to the distributor, but the distributor can not directly view the set preferential rules, only after the distributor reaches a certain level of quantity to view the next level of preferential, please rest assured to set.`,
  key1000669: `Submit`,
  key1000670: `Normal products`,
  key1000671: `Assembling products`,
  key1000672: `raw material`,
  key1000673: `Select product category`,
  key1000674: `Modify Category`,
  key1000675: `Accurately selecting categories is beneficial for product exposure and promotion of conversion.`,
  key1000676: `For the convenience of management, you can customize the product code, such as the product number, which will take effect after successful publication.`,
  key1000677: `Select plant main material`,
  key1000678: `Reselect`,
  key1000679: `Home image`,
  key1000680: `Upload network images`,
  key1000681: `Priority should be given to using background model puzzles for the first image. If there are no puzzles, choose the front image of the model. Models with backgrounds should be arranged in a certain order.`,
  key1000682: `View specifications`,
  key1000683: `Picture requirements`,
  key1000684: `It is recommended to upload large images of squares with 800*800 pixels or more, for example, 800*800px,1000*1000px,1500 * 1500px, with a maximum of 5MB. If the size does not meet the requirements, the upload will fail.`,
  key1000685: `recommend JPG and JPEG format images, but the platform supports uploading PNG, WEBP and BMP format images (limited platform restrictions will convert non-JPG and JPEG formats into JPEG format, but will cause slight distortion of images)`,
  key1000686: `edit`,
  key1000687: `Self portrait of a model`,
  key1000688: `Internet celebrity chart`,
  key1000689: `Picture of physical placement`,
  key1000690: `Automatic generation`,
  key1000691: `Listing product attribute information:`,
  key1000692: `Expand and fill in more attributes`,
  key1000693: `The following attributes require a percentage of the attribute value:`,
  key1000694: `Please select a product category first`,
  key1000695: `Please fill in the attributes accurately. More product attributes will help improve the conversion rate of orders.`,
  key1000696: `Product specifications`,
  key1000697: `Only the first set of specification setting images are supported. The set image should be selected as the set image, and a maximum of 5 images can be uploaded for each specification. The recommended size is 800 * 800px.`,
  key1000698: `Multi specification sorting`,
  key1000699: `Specification details`,
  key1000700: `Custom Columns`,
  key1000701: `Size chart`,
  key1000702: `Please add product specifications first`,
  key1000703: `Fixed amount`,
  key1000704: `discount`,
  key1000705: `Add Layering`,
  key1000706: `Not available temporarily after submission for review`,
  key1000707: `Approved`,
  key1000708: `Reject`,
  key1000709: `The commodity category successfully matches YMS commodity category information`,
  key1000710: `", after confirming that the main material is selected, the current commodity category information will be replaced, and the setting information such as commodity specification will be overwritten by the information corresponding to the new category.`,
  key1000711: `Product classification cannot be empty`,
  key1000712: `Item No./SPU cannot be empty`,
  key1000713: `The product rotation chart cannot be empty`,
  key1000714: `The image type cannot be empty`,
  key1000715: `Accumulated purchase quantity`,
  key1000716: `To`,
  key1000717: `Cannot be less than or equal to the left interval`,
  key1000718: `Discount amount`,
  key1000719: `Discount coefficient`,
  key1000720: `Please enter the discount amount, such as 1.00`,
  key1000721: `Please enter a discount factor, such as 0.1`,
  key1000722: `Layered Price Reference`,
  key1000723: `Discount price:`,
  key1000724: `infinite`,
  key1000725: `Remove hierarchy`,
  key1000726: `Product`,
  key1000727: `The attribute value has been deactivated. Please replace it with a similar attribute value`,
  key1000728: `Abnormal attribute value, please replace the attribute value`,
  key1000729: `You can only select up to 5 optional values for basic attributes!`,
  key1000730: `This property already exists. Please enter a different property name.`,
  key1000731: `Update successfully`,
  key1000732: `Option not filled in completely`,
  key1000733: `商品属性的输入框仅限输入数字及小数点！`,
  key1000734: `The Chinese name of the commodity cannot be empty!`,
  key1000735: `Attribute value scale cannot be empty`,
  key1000736: `Attribute value scale must be a positive integer greater than 0`,
  key1000737: `The sum of the attribute value proportions for each attribute is equal to 100 percent`,
  key1000738: `The specification attribute has a disabled attribute value. Replace it with a similar attribute value and try again.`,
  key1000739: `The attribute value of the specification attribute is abnormal. Please change the attribute value and try again.`,
  key1000740: `The attribute value of the basic attribute is abnormal. Change the attribute value and try again.`,
  key1000741: `审核成功!`,
  key1000742: `One size`,
  key1000743: `Enter after entering the value`,
  key1000744: `Automatic generation`,
  key1000745: `Automatically generate sku rules: item number/SPU + specification attribute`,
  key1000746: `Packaging size (length, width, height cm)`,
  key1000747: `The maximum length of each side of the packaging shall not exceed 40cm, except for swimwear. The maximum height of spring and summer styles shall not exceed 3cm, and the minimum height shall not be less than 1cm`,
  key1000748: `Main material size (length, width, height cm):`,
  key1000749: `long`,
  key1000750: `Width`,
  key1000751: `Height`,
  key1000752: `Length/Width/Height`,
  key1000753: `Weight`,
  key1000754: `Weight(g)`,
  key1000755: `Fill in the maximum weight in yards and grams`,
  key1000756: `Weight of main ingredients (g):`,
  key1000757: `Supply price (CNY)`,
  key1000758: `Supply price（`,
  key1000759: `Suggested supply price is less than or equal to 1688 price`,
  key1000760: `inventory`,
  key1000761: `Inventory is a whole number`,
  key1000762: `Assembled or not`,
  key1000763: `Main material SKU information`,
  key1000764: `Processing main material not selected`,
  key1000765: `Click to select processing main material`,
  key1000766: `Auxiliary material process`,
  key1000767: `Please select main material information first`,
  key1000768: `Click to select excipient process`,
  key1000769: `Excipient process not selected`,
  key1000770: `Location & Material`,
  key1000771: `After setting the first column of 'location & material' information, check it to make the goods with the same available printing position get the same 'location & material' setting, but you need to judge by yourself whether there is information for goods of different specifications that needs to be fine-tuned.`,
  key1000772: `Position & Material not set`,
  key1000773: `(Attribute value has been deactivated)`,
  key1000774: `Preview image`,
  key1000775: `Activity price`,
  key1000776: `Activity price（`,
  key1000777: `Sales price configuration`,
  key1000778: `The percentage markup value cannot be empty`,
  key1000779: `Fixed markup value cannot be empty`,
  key1000780: `Only supports inputting numbers and decimal points`,
  key1000781: `The price reduction cannot be greater than or equal to the supply price`,
  key1000782: `The downward float cannot be greater than or equal to 100%`,
  key1000783: `Only allow up to four decimal places to be entered`,
  key1000784: `Upward floating`,
  key1000785: `Downward float`,
  key1000786: `Markup`,
  key1000787: `Price reduction`,
  key1000788: `Percentage markup`,
  key1000789: `Fixed markup`,
  key1000790: `Please enter the floating ratio`,
  key1000791: `Please enter numerical values`,
  key1000792: `Sales price`,
  key1000793: `Sales price（`,
  key1000794: `Product item number/SPU cannot be empty!`,
  key1000795: `Size`,
  key1000796: `Upload up to 5 images per specification`,
  key1000797: `Close prompt`,
  key1000798: `Content not saved reminder`,
  key1000799: `Unsaved content will be lost. Are you sure you want to exit?`,
  key1000800: `Confirm Exit`,
  key1000801: `Title cannot be empty`,
  key1000802: `Select main material confirmation`,
  key1000803: `This main material is not available`,
  key1000804: `The main material commodity category does not belong to the category content in the YMS commodity category table, please replace and select the main material, or manually bind the factory processing goods.`,
  key1000805: `Switch main material SKU confirmation`,
  key1000806: `After switching SKU of main material, part & material setting information needs to be filled in again. Please confirm whether to switch SKU of main material?`,
  key1000807: `Confirm switch`,
  key1000808: `商品规格不可为空，必须选择填写一种商品规格信息`,
  key1000809: `Padded data cannot be 0`,
  key1000810: `Fill`,
  key1000811: `Billing Model Editing`,
  key1000812: `Support Chinese, English and Binary Code Search`,
  key1000813: `Volume (kg):`,
  key1000814: `Volume weight:`,
  key1000815: `(Volume (kg)=Length (cm) x Width (cm) x Height (cm) ÷`,
  key1000816: `Verify that the shipping cost is correct: weight (`,
  key1000817: `Selected countries:`,
  key1000818: `Clear`,
  key1000819: `Set postal code`,
  key1000820: `View postal code`,
  key1000821: `Add weight range`,
  key1000822: `Verify delivery fee`,
  key1000823: `Delivery fee:`,
  key1000824: `Freight = (Weight * Unit Registration Fee Operating Fee)* (1 Surcharge)`,
  key1000825: `If Package weight<=Primary weight, Freight=minimum price*(1+additional price)`,
  key1000826: `If package weight is greater than the Primary weight, the shipping cost = {Primary weight cost + rounding up (total weight-Primary weight)/renewed weight per unit) * additional weight unit price + registration fee + operation fee}* (1+ surcharge)`,
  key1000827: `Freight= (Unit Registration Fee Operating Fee)* (1 Surcharge)`,
  key1000828: `Not within the weight range`,
  key1000829: `Starting weight`,
  key1000830: `Cut-off weight`,
  key1000831: `Registration fee`,
  key1000832: `Operation cost`,
  key1000833: `Surcharge (%)`,
  key1000834: `Price`,
  key1000835: `First weight (`,
  key1000836: `First weight freight（`,
  key1000837: `Continued unit weight (`,
  key1000838: `Renewal unit price（`,
  key1000839: `Cut-off weight should be greater than initial weight`,
  key1000840: `Please choose the country`,
  key1000841: `Price cannot be empty.`,
  key1000842: `The first priority should not be empty`,
  key1000843: `Priority freight cannot be empty`,
  key1000844: `Continuous weight per unit weight must not be empty`,
  key1000845: `Continuous unit price cannot be empty`,
  key1000846: `Volume weight cannot be empty`,
  key1000847: `Subscription status:`,
  key1000848: `选择确认`,
  key1000849: `创建后若后续平台订单中存在此商品都无需手动匹配YMS商品，可直接通过此关联关系进行此YMS商品匹配。提示：已创建的关联关系可通过路径“商品管理-收藏的商品-操作项[设置关联关系]”进行查看/编辑;（未收藏的商品也可创建关联关系，可在收藏后对其进行查看/编辑）`,
  key1000850: `items`,
  key1000851: `Selected:`,
  key1000852: `Set as main material`,
  key1000853: `是否确认选择该YMS商品进行订单商品匹配？`,
  key1000854: `创建关联关系`,
  key1000855: `SPU, support for multiple row-separated`,
  key1000856: `SKU, support for multiple row-separated`,
  key1000857: `Central warehouse inventory`,
  key1000858: `Add product`,
  key1000859: `collected`,
  key1000860: `Not Favorite`,
  key1000861: `Supplier SPU`,
  key1000862: `Please enter the supplier SPU, support multiple separated by line`,
  key1000863: `Please enter SPU, support multiple commas and delimited by line`,
  key1000864: `Please enter SKU, support multiple commas and delimit by line`,
  key1000865: `Please enter the product name, support multiple commas and separate by line`,
  key1000866: `Supplier Merchant Number`,
  key1000867: `Product title`,
  key1000868: `Please enter the product title`,
  key1000869: `Supplier name`,
  key1000870: `Supplier inventory`,
  key1000871: `Product SPU:`,
  key1000872: `Product SKU:`,
  key1000873: `Available stock`,
  key1000874: `Sales in the past 30 days`,
  key1000875: `Choice`,
  key1000876: `My inventory`,
  key1000877: `In-transit inventory:`,
  key1000878: `Available Inventory:`,
  key1000879: `Unit Price`,
  key1000880: `length, width and height(cm)`,
  key1000881: `trial sale`,
  key1000882: `Clearance`,
  key1000883: `The quantity of goods cannot be empty!`,
  key1000884: `Please select the product first!`,
  key1000885: `该商品现已存在关联关系！`,
  key1000886: `System notification`,
  key1000887: `No more reminders`,
  key1000888: `Product search:`,
  key1000889: `Main materials`,
  key1000890: `accessories`,
  key1000891: `Please enter the complete SPU or product name`,
  key1000892: `Please enter the complete SPU/SKU or product name`,
  key1000893: `The quantity of auxiliary materials cannot be empty!`,
  key1000894: `The quantity of main materials cannot be empty!`,
  key1000895: `Please select the main material data first!`,
  key1000896: `You do not have permission to access this page.`,
  key1000897: `Back`,
  key1000898: `Sorry, the page you visited does not exist!`,
  key1000899: `Return to the system homepage`,
  key1000900: `Return to the menu with permissions`,
  key1000901: `Please check the custom list fields that need to be displayed first!`,
  key1000902: `Medium material `,
  key1000903: `Tag size`,
  key1000904: `Start and end information`,
  key1000905: `Maximum support 100mm`,
  key1000906: `Typeface`,
  key1000907: `About 35mm Width/Pt `,
  key1000908: `Note: The printed content can be dragged to change the position.`,
  key1000909: `Printing`,
  key1000910: `Not print`,
  key1000911: `Bar code`,
  key1000912: `custom content`,
  key1000913: `Storage bin`,
  key1000914: `Preview and occupy storage bin`,
  key1000915: `Storage area`,
  key1000916: `Preview and occupy storage area`,
  key1000917: `Reference Number `,
  key1000918: `preview and occupy reference number`,
  key1000919: `Adjust specification sorting by dragging and dropping`,
  key1000920: `Print box labels`,
  key1000921: `Creation time:`,
  key1000922: `Print quantity:`,
  key1000923: `Print YMS Surface Sheet`,
  key1000924: `The number of prints must be greater than 0`,
  key1000925: `Classification retrieval`,
  key1000926: `Recently used:`,
  key1000927: `Selected category:`,
  key1000928: `Switch category confirmation`,
  key1000929: `After switching categories, information such as product attributes and specifications needs to be filled out again. Please confirm whether to switch categories?`,
  key1000930: `Please select a type`,
  key1000931: `Please enter your phone number`,
  key1000932: `Please enter the graphic verification code`,
  key1000933: `Captcha image`,
  key1000934: `please enter verification code`,
  key1000935: `Input Password`,
  key1000936: `Please confirm the password again`,
  key1000937: `Email (optional)`,
  key1000938: `Improve account information`,
  key1000939: `Kind reminder: For the security of your account, please bind your phone number. After binding, you can log in through your phone number and password.`,
  key1000940: `Bind mobile phone number`,
  key1000941: `Temporarily not bound`,
  key1000942: `Send the verification code`,
  key1000943: `The graphic verification code cannot be empty`,
  key1000944: `verification code must be filled`,
  key1000945: `Resend`,
  key1000946: `The phone verification code has been sent!`,
  key1000947: `Confirm that the new password cannot be empty`,
  key1000948: `The password does not match the confirmed password`,
  key1000949: `Successfully bound phone`,
  key1000950: `Please check the read and accept`,
  key1000951: `Edit Template`,
  key1000952: `Template name:`,
  key1000953: `Edit successfully!`,
  key1000954: `Supports batch input, please separate with line breaks`,
  key1000955: `Before truncating the postal code`,
  key1000956: `position`,
  key1000957: `Before postal code`,
  key1000958: `Position:`,
  key1000959: `Postcode:`,
  key1000960: `Postcode cannot be empty.`,
  key1000961: `The postal code format can only be numbers or letters`,
  key1000962: `Order cancellation prompt`,
  key1000963: `There are cancelled orders in this assignment, and the detailed information of the outbound order included in the order is as follows.`,
  key1000964: `Continue to sort`,
  key1000965: `Remove cancelled outbound orders`,
  key1000966: `Outbound order number `,
  key1000967: `Print Configuration`,
  key1000968: `Regular order waybill`,
  key1000969: `Lemgth:`,
  key1000970: `Width:`,
  key1000971: `AliExpress fully managed product label`,
  key1000972: `AliExpress fully managed logistics waybill`,
  key1000973: `The length and width of the regular order waybill cannot be empty!`,
  key1000974: `The length and width of the AliExpress fully managed product label cannot be empty!`,
  key1000975: `The length and width of the AliExpress fully managed logistics waybill cannot be empty!`,
  key1000976: `Saved successfully！`,
  key1000977: `Commodity Rejection Notice`,
  key1000978: `The following product review is rejected, please revise and resubmit for review:`,
  key1000979: `Next Reminder`,
  key1000980: `Copied!`,
  key1000981: `Choose the platform to publish`,
  key1000982: `This category has not yet maintained platform mapping. Please consult customer service if necessary.`,
  key1000983: `There are currently no platforms to support publishing!`,
  key1000984: `Unbound store`,
  key1000985: `Your account is currently not linked to a store, or the linked store has expired.`,
  key1000986: `Adding pictures from addresses`,
  key1000987: `Image URL:`,
  key1000988: `Please fill in the picture address`,
  key1000989: `Incorrect link address format`,
  key1000990: `Failed to get uploaded image!`,
  key1000991: `New product`,
  key1000992: `Authorized list`,
  key1000993: `You can enter code and account name to search`,
  key1000994: `Code`,
  key1000995: `Account`,
  key1000996: `Effective`,
  key1000997: `invalid`,
  key1000998: `Update shop`,
  key1000999: `Add shop`,
  key1001000: `key words`,
  key1001001: `Rule`,
  key1001002: `You can enter multiple postcodes, separated by a comma or newline`,
  key1001003: `Enter more than one, separated by English commas`,
  key1001004: `Please enter key words...`,
  key1001005: `Multi-postcode wrap requests half-width input comma to separate`,
  key1001006: `Please choose platform`,
  key1001007: `Please enter the shipping method to search.`,
  key1001008: `Add transpotation type`,
  key1001009: `Are you sure to delete?`,
  key1001010: `(USD)`,
  key1001011: `or (EUR)`,
  key1001012: `or (GBP)`,
  key1001013: `or (AUD)`,
  key1001014: `Or RMB`,
  key1001015: `Please enter the SKU. Use comma or new line to separate multiple SKUs`,
  key1001016: `Please Choose or Input Location`,
  key1001017: `Please select the shop or enter the search.`,
  key1001018: `Please select a logistics`,
  key1001019: `Ten-day orders are X-day orders, such as 5-days, 10-days`,
  key1001020: `Specified scope`,
  key1001021: `Designated type`,
  key1001022: `Start`,
  key1001023: `Ending`,
  key1001024: `Specified law`,
  key1001025: `designated SKU`,
  key1001026: `Designated warehouse`,
  key1001027: `or`,
  key1001028: `Designated platform`,
  key1001029: `Selected conditions`,
  key1001030: `Items included in the order:`,
  key1001031: `volumetric factor`,
  key1001032: `The total quantity of products in the package meets the following selected conditions:`,
  key1001033: `The weight meets all the following conditions:`,
  key1001034: `The length of the product should meet all the following conditions:`,
  key1001035: `The total width meets all the following conditions:`,
  key1001036: `The height of products should satisfy all the following conditions:`,
  key1001037: `The product volume of the order meets all the following conditions`,
  key1001038: `The total quantity of products in the order meets the following conditions:`,
  key1001039: `The character length of the zip code should meet the following conditions:`,
  key1001040: `Products in the order:`,
  key1001041: `and the sum of the quantity of the above goods meets all the conditions selected below:`,
  key1001042: `The heavier one of volumetric weight and actual weight will be the final weight of order.`,
  key1001043: `Note: The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c,`,
  key1001044: `The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c. The maximum value of all a1 is a, and the maximum value of all b1 is b. The sum of the short sides c of all items in the order is d,`,
  key1001045: `then the maximum value of a, b, and d is the total length, the middle value is the total width, and the minimum value is the total height.`,
  key1001046: `volumetric weight(g)= (a*b*d)/ volumetric factor*1000`,
  key1001047: `Note: The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c,`,
  key1001048: `The longest side of each item recorded in the commodity management is a1. The middle side is b1, and the shortest is c. The maximum value of all a1 is a, and the maximum value of all b1 is b. The sum of the short sides c of all items in the order is d,`,
  key1001049: `then the maximum value of a, b, and d is the total length, the middle value is the total width, and the minimum value is the total height.`,
  key1001050: `then the maximum value of a, b, and d is the total length, the middle value is the total width, and the minimum value is the total`,
  key1001051: `height, a*b*d is the total volume of all the products in the order.`,
  key1001052: `Order includes multiple transactions(and use the same shipping method)`,
  key1001053: `The order only has one transaction.`,
  key1001054: `The order contains multiple transactions (and the shipping methods are not the exact same`,
  key1001055: `specified conditions`,
  key1001056: `The contact number of recipient should meet the following conditions:`,
  key1001057: `readable character length of mobile number≤`,
  key1001058: `Readable character length of fixed phone number≤`,
  key1001059: `Mobile phone prefix`,
  key1001060: `designated string`,
  key1001061: `appoint the character to be searched in the address(only Street1 + Street2, not include the information of the country, province/state and city):`,
  key1001062: `Add`,
  key1001063: `Designated length`,
  key1001064: `The character length of the shipping address is less than`,
  key1001065: `*。The address information is combined by street1 and stree2, which does not include the information of the country, province/state and city.`,
  key1001066: `, and`,
  key1001067: `, or`,
  key1001068: `includes`,
  key1001069: `specified format`,
  key1001070: `The postcode should meet at least one of the following selected conditions (input content is limited to numbers, English letters, dashes and spaces):`,
  key1001071: `Designated exception`,
  key1001072: `and the postcode is in`,
  key1001073: `Postcode section`,
  key1001074: `Postcode list`,
  key1001075: `Have chosen`,
  key1001076: `,Note: For other currencies to be converted into USD, all options must be filled in`,
  key1001077: `Freight paid by customer convert to (currency):`,
  key1001078: `：then meet all the following conditions:`,
  key1001079: `Specified product status`,
  key1001080: `And for`,
  key1001081: `Designated products`,
  key1001082: `Designated mode of mailing`,
  key1001083: `The total amount of the package includes the amount of all orders merged into one package. The amount of orders in different currencies will be converted to the currencies in the following settings according to the exchange rate.`,
  key1001084: `Currency:`,
  key1001085: `Enter the characters to be searched in Customer ID`,
  key1001086: `Rule Name:`,
  key1001087: `Optional conditions`,
  key1001088: `Please designate`,
  key1001089: `No rule selected`,
  key1001090: `Rule name cannot be empty`,
  key1001091: `More Platforms`,
  key1001092: `Batch Configuration Label`,
  key1001093: `Label:`,
  key1001094: `Please Choose Label`,
  key1001095: `Add tags`,
  key1001096: `delete a tap`,
  key1001097: `Import type：`,
  key1001098: `Please enter the task number`,
  key1001099: `Import time：`,
  key1001100: `Importing`,
  key1001101: `complete import`,
  key1001102: `Failed to import`,
  key1001103: `import type`,
  key1001104: `import files`,
  key1001105: `time of import`,
  key1001106: `import status`,
  key1001107: `Success/Failure`,
  key1001108: `export successfully`,
  key1001109: `failed to export`,
  key1001110: `Location & Material Settings`,
  key1001111: `Click to select parts`,
  key1001112: `Click to enter`,
  key1001113: `Part cannot be empty`,
  key1001114: `Please select parts first`,
  key1001115: `Printable area cannot be empty`,
  key1001116: `Cannot exceed length of printable area`,
  key1001117: `Cannot exceed printable area width`,
  key1001118: `Please enter print size first`,
  key1001119: `Add to`,
  key1001120: `Location`,
  key1001121: `Location schematic`,
  key1001122: `Printable area (length and width cm)`,
  key1001123: `Printing size (length, width cm)`,
  key1001124: `Material`,
  key1001125: `Material coding`,
  key1001126: `Information used by the supplier to identify the material`,
  key1001127: `Finished product renderings`,
  key1001128: `Confirmation of switching position`,
  key1001129: `After switching parts, information such as printing size and material need to be re-entered. Please confirm whether to switch parts?`,
  key1001130: `File size exceeds limit, max 20M!`,
  key1001131: `There are required items not filled in!`,
  key1001132: `Tracking information`,
  key1001133: `Waybill number:`,
  key1001134: `Status:`,
  key1001135: `To be queried`,
  key1001136: `Querying`,
  key1001137: `Not found`,
  key1001138: `In transit`,
  key1001139: `Waiting to receive`,
  key1001140: `Have been received`,
  key1001141: `Delivery failed`,
  key1001142: `Transported too long`,
  key1001143: `May be abnormal`,
  key1001144: `Apply to become a distributor`,
  key1001145: `Find products`,
  key1001146: `Collect products`,
  key1001147: `Product Listing & Download Kit`,
  key1001148: `Place an order to ship`,
  key1001149: `Order Management`,
  key1001150: `financial management`,
  key1001151: `Register now`,
  key1001152: `Click "Become a Distributor" in the upper right corner of the mall homepage, and use your mobile phone number to register or use your Tongtool account to authorize to log in.`,
  key1001153: `Browse`,
  key1001154: `Search keywords to find the products you want to distribute.`,
  key1001155: `In the category navigation, find the product you want to distribute.`,
  key1001156: `On the homepage and core page, find the product you want to distribute.`,
  key1001157: `Go collect`,
  key1001158: `On the product list page, find the product and click the "Save" button to save it for publication.`,
  key1001159: `On the product details page, click the "Save Now" button to save the product for publication.`,
  key1001160: `4. Product Publishing&Download Kit`,
  key1001161: `Product publish`,
  key1001162: `Use Tongtool Listing to publish products on eBay, Aliexpress, Wish, Amazon and other overseas e-commerce platforms with one click.`,
  key1001163: `Download the package`,
  key1001164: `You can also download the data package for free on the "Product List", "Product Details Page" or "Product Management" page, and then go to the third-party sales platform to list the products in the backstage of the merchant.`,
  key1001165: `Basic Settings`,
  key1001166: `Enable logistics`,
  key1001167: `In Tongtool ERP2.0, third-party logistics is enabled, and the Tongtool system has built-in some logistics providers with a large number of users in the industry and a good reputation. You can select and enable it according to your needs.`,
  key1001168: `Enable shipping method`,
  key1001169: `Choose which shipping method you want to enable. After enabling it, you will be prompted "The shipping method activated successfully".`,
  key1001170: `Warehouse setup`,
  key1001171: `Select "YMS Supply Chain" in the third-party warehouse to enable it.`,
  key1001172: `Bind logistics channels`,
  key1001173: `Select the "YMS supply chain" warehouse, click "Bind logistics channel" on the right, and click the "Confirm" button after selecting the logistics method.`,
  key1001174: `Generate Tongtool sku`,
  key1001175: `In the "Product Management - Cloud Sales and Distribution Products" module of Tongtu ERP2.0, click on the favorite products, and in the product details, click "Generate Product Information".`,
  key1001176: `In Tongtool ERP2.0, select the "Shipping Management-Managed Warehouse-Waiting for allocation" module, and the warehouse selects "YMS Supply Chain", click "YMS Order". If the balance is insufficient, you can contact customer service to recharge.`,
  key1001177: `Go to view`,
  key1001178: `In the "Order Management-My Order" module, you can query the order and enter the "Order Details Page" to check the delivery status.`,
  key1001179: `In the "Financial Management" module, you can query the recharge records and view the cost details.`,
  key1001180: `Shipping method:`,
  key1001181: `Shipping mode:`,
  key1001182: `Logistics solution:`,
  key1001183: `Shipping address:`,
  key1001184: `Carrier:`,
  key1001185: `Logistics tracking number:`,
  key1001186: `YMS Logistics`,
  key1001187: `Own logistics`,
  key1001188: `express`,
  key1001189: `YMS on-site collection`,
  key1001190: `Please`,
  key1001191: `Account Management - Basic Information`,
  key1001192: `Maintaining shipping address information in`,
  key1001193: `Please make sure to confirm with YMS offline in advance whether on-site collection is possible!!`,
  key1001194: `When shipping, the goods need to be labeled with YMS product barcodes, and the outer packaging needs to be labeled with box labels in a prominent place!`,
  key1001195: `End boxing`,
  key1001196: `Successfully marked shipment, bill of lading number:`,
  key1001197: `Please select a logistics plan`,
  key1001198: `Please select a shipping address`,
  key1001199: `Please select Logistics Provider`,
  key1001200: `The logistics tracking number cannot be empty`,
  key1001201: `Logistics information`,
  key1001202: `Mark Shipment`,
  key1001203: `Modify logistics information`,
  key1001204: `China`,
  key1001205: `Your browser does not support websocket!`,
  key1001206: `Minutes ago`,
  key1001207: `Hours ago`,
  key1001208: `Days ago`,
  key1001209: `Weeks ago`,
  key1001210: `Months ago`,
  key1001211: `last year`,
  key1001212: `Sunday`,
  key1001213: `Monday`,
  key1001214: `Tuesday`,
  key1001215: `Wednesday`,
  key1001216: `Thursday`,
  key1001217: `Friday`,
  key1001218: `Saturday`,
  key1001219: `day`,
  key1001220: `hour`,
  key1001221: `minute`,
  key1001222: `1 minute`,
  key1001223: `just`,
  key1001224: `Supplier Operations`,
  key1001225: `Please download the tongtool print plugin`,
  key1001226: `Click to download`,
  key1001227: `Run the tongtool print plugin`,
  key1001228: `Current browser Not support websocket`,
  key1001229: `Failed to connect rookie print control,Please confirm that the rookie print control is installed or enabled`,
  key1001230: `Insufficient account balance`,
  key1001231: `Your account balance is insufficient. Please recharge enough amount before submitting the order!`,
  key1001232: `To recharge`,
  key1001233: `你的账户余额不足，请联系运营工作人员进行充值或选择其他支付方式，再进行订单支付!`,
  key1001234: `大洋洲`,
  key1001235: `欧洲`,
  key1001236: `亚洲`,
  key1001237: `南极`,
  key1001238: `美洲`,
  key1001239: `非洲`,
  key1001240: `Loading...`,
  key1001241: `Request timed out`,
  key1001242: `The account has been disabled. To re-enable the account, please contact`,
  key1001243: `Operations staff.`,
  key1001244: `You already have`,
  key1001245: `Account number, you can use this directly`,
  key1001246: `The account number is settled as a supplier.`,
  key1001247: `The account number opens the distributor account.`,
  key1001248: `No access to system`,
  key1001249: `Dear Passage User, you do not have permission to access the system!`,
  key1001250: `Please contact the system administrator!`,
  key1001251: `Reminder for pending confirmation of accounting statement`,
  key1001252: `There are unconfirmed bills, please confirm in a timely manner. If the bill is not confirmed within 14 days after generation, the system will default to the bill being correct and automatically confirm the bill.`,
  key1001253: `View bills`,
  key1001254: `Confirm later`,
  key1001255: `Account deactivation prompt`,
  key1001256: `Confirmation of becoming a supplier`,
  key1001257: `Go to settle in`,
  key1001258: `Confirmation of becoming a distributor`,
  key1001259: `Confirm activation`,
  key1001260: `You already have a Tongtu account, you can directly use this account to open a distributor account.`,
  key1001261: `Uncollected`,
  key1001262: `Collected`,
  key1001263: `Sorted`,
  key1001264: `Outbound`,
  key1001265: `Bill of Lading,`,
  key1001266: `Bill of Lading No]`,
  key1001267: `, there is a large number of goods, has been converted to stock inventory stored in.`,
  key1001268: `Central warehouse.`,
  key1001269: `Automatic order cancellation notification`,
  key1001270: `Your Order`,
  key1001271: `Order Number]]`,
  key1001272: `, has timed out and canceled automatically, please pay attention in time.`,
  key1001273: `Direct outbound`,
  key1001274: `To be picked`,
  key1001275: `Picking`,
  key1001276: `Wait for pick`,
  key1001277: `Sorting`,
  key1001278: `To be packed`,
  key1001279: `In packaging`,
  key1001280: `Package complete`,
  key1001281: `Cancelled`,
  key1001282: `complete creation`,
  key1001283: `In stock`,
  key1001284: `Picking completed`,
  key1001285: `Order has been changed`,
  key1001286: `Boxed`,
  key1001287: `Delivered`,
  key1001288: `Waiting for collection`,
  key1001289: `It has been added to`,
  key1001290: `Already enter the warehouse`,
  key1001291: `closed`,
  key1001292: `Abnormal collection and sorting`,
  key1001293: `Already placed`,
  key1001294: `Single choices can be customized`,
  key1001295: `Multiple choice can be customized value`,
  key1001296: `Matched products`,
  key1001297: `Matched logistics`,
  key1001298: `Order placed`,
  key1001299: `Checking`,
  key1001300: `To be delivered`,
  key1001301: `Partial fullfill`,
  key1001302: `Partial receipt`,
  key1001303: `Partial cancellation`,
  key1001304: `Sales management`,
  key1001305: `Own warehouse stocking`,
  key1001306: `Third-party warehouse stocking`,
  key1001307: `Multiple supplementary orders`,
  key1001308: `Editing`,
  key1001309: `Pending review`,
  key1001310: `Review rejection`,
  key1001311: `Product price`,
  key1001312: `Parcel handling fee`,
  key1001313: `Refund`,
  key1001314: `Logistics handling fee`,
  key1001315: `Subsidy amount`,
  key1001316: `Domestic express delivery fee`,
  key1001317: `Customs clearance fee`,
  key1001318: `Order Cancellation Fee`,
  key1001319: `Normal`,
  key1001320: `Deduction completes`,
  key1001321: `Withheld amount`,
  key1001322: `Mall Registration`,
  key1001323: `Manual addition`,
  key1001324: `To be confirmed`,
  key1001325: `To be received`,
  key1001326: `Received payments`,
  key1001327: `To be confirmed by the supplier`,
  key1001328: `Pending payment`,
  key1001329: `Paid`,
  key1001330: `/yms-distributor-service/video/YMS功能介绍.mp4`,
  key1001331: `/Yms distributor service/video/store authorization. mp4`,
  key1001332: `/yms-distributor-service/video/Post Template Settings. mp4`,
  key1001333: `/yms-distributor-service/video/一键刊登（YMS).mp4`,
  key1001334: `/yms-distributor-service/video/one-click publishing rules.mp4`,
  key1001335: `/Yms distributor service/video/Register for Cloud Selling Supply Chain.mp4`,
  key1001336: `Chinese`,
  key1001337: `English`,
  key1001338: `Order out of stock reminder`,
  key1001339: `When the order status is marked as out of stock`,
  key1001340: `Order No.`,
  key1001341: `You have<span class="highlight_color">[order number]</span> orders out of stock, please pay attention in time!`,
  key1001342: `Stock up`,
  key1001343: `Order stocking reminder`,
  key1001344: `You have <span class="highlight_color">[X]</span> orders. The stocking has been overtime, please stock up in time!`,
  key1001345: `Picking`,
  key1001346: `Order picking reminder`,
  key1001347: `You have <span class="highlight_color">[X]</span> orders, and the picking has timed out, please pick it in time!`,
  key1001348: `Switch bill`,
  key1001349: `Order exchange reminder`,
  key1001350: `You have <span class="highlight_color">[X]</span> orders, which switching bill has timed out. Please switch bill in time!`,
  key1001351: `Packing`,
  key1001352: `Order packing reminder`,
  key1001353: `You have <span class="highlight_color">[X]</span> orders, the boxing has expired, please box in time!`,
  key1001354: `Fullfill`,
  key1001355: `Order Delivery Reminder`,
  key1001356: `You have <span class="highlight_color">[X]</span> orders, and the shipping has timed out, please ship it in time!`,
  key1001357: `Order cancellation reminder`,
  key1001358: `When the order status is marked as cancelled`,
  key1001359: `The distributor has cancelled the order: <span class="highlight_color">[order number]</span>, please pay attention to the delivery situation in time`,
  key1001360: `Notice of Product Suspension`,
  key1001361: `When the supplier marks SPU or SKU as discontinued`,
  key1001362: `Dear distributor, your collection:<span class="highlight_color">【商品名称】</span>, Product Code:<span class="highlight_color">【SPU/SKU】</span>has been discontinued. If you have already listed this product, please remove it promptly.`,
  key1001363: `When the product description or image is modified`,
  key1001364: `Commodity information modification notice`,
  key1001365: `Product <span class = "highlight_color"> [SPU] </span> information modification notice`,
  key1001366: `Dear distributor, hello: The description/image of your favorite product<span class="highlight_color">【SPU】</span>has been modified. If you have already listed this product, please update it to the latest product information in a timely manner.`,
  key1001367: `When the product price is modified`,
  key1001368: `Product Price Adjustment Notice`,
  key1001369: `Commodity <span class = "highlight_color"> [SPU] </span> price adjustment notice`,
  key1001370: `Dear distributor, due to cost changes, <span class="highlight_color">【SPU】</span> the prices of the products you have collected have been adjusted. Please keep an eye on them in a timely manner.`,
  key1001371: `When new products from suppliers are reviewed and approved`,
  key1001372: `Product New Notification`,
  key1001373: `The supplier you are following has launched new products`,
  key1001374: `The supplier you are following:<span class="highlight_color">【供应商名称】</span>There are new products available.`,
  key1001375: `When the inbound sorting is completed and there are over received items in the bill of lading`,
  key1001376: `Notification of multiple shipments of goods`,
  key1001377: `Bill of Lading Number`,
  key1001378: `When adjusting inventory or shelving tool status through automatic rules configured by distributors`,
  key1001379: `Product status automatic synchronization notification`,
  key1001380: `The product you subscribed to<span class="highlight_color">【SPU】</span>has been discontinued and relevant information has been automatically adjusted according to rules. Please stay tuned.
`,
  key1001381: `When adjusting online publication prices through automatic rules configured by distributors`,
  key1001382: `Online publication of automatic price adjustment notifications`,
  key1001383: `The product you subscribed<span class="highlight_color">【SPU】</span>. The supply price has been adjusted, and the online publication price is automatically adjusted according to the rules. Please stay tuned.`,
  key1001384: `When the order is automatically cancelled through the timeout configured by the distributor`,
  key1001385: `When automatic order placement through the platform fails due to insufficient balance`,
  key1001386: `Notification of insufficient balance and failed order placement`,
  key1001387: `Notice of insufficient balance`,
  key1001388: `Platform`,
  key1001389: `<span class="highlight_color">【平台】</span>订单<span class="highlight_color">【订单号】</span> Your balance is insufficient and automatic order placement has failed. Please recharge in a timely manner.`,
  key1001390: `When commodity review is rejected`,
  key1001391: `Notice of Commodity Review Rejection`,
  key1001392: `Your product:<span class = "highlight_color"> [SPU] </span>, the audit failed, rejected reason: XXXXXXXXX.`,
  key1001393: `To be boxed`,
  key1001394: `Out of stock`,
  key1001395: `Regular order`,
  key1001396: `Temu Sample Order`,
  key1001397: `Temu goods order`,
  key1001398: `AliExpress fully managed orders`,
  key1001399: `Use existing IOSS`,
  key1001400: `Logistics agent payment`,
  key1001401: `Manual entry`,
  key1001402: `Awaiting payment`,
  key1001403: `Waiting for in-transit inventory`,
  key1001404: `Waiting for merchants to pick up goods`,
  key1001405: `Pending merchant review`,
  key1001406: `To be packed by merchants`,
  key1001407: `In express transportation`,
  key1001408: `Arriving at the central warehouse`,
  key1001409: `The central warehouse has arrived`,
  key1001410: `Waiting for logistics provider to come and pick up the package`,
  key1001411: `Delivered to logistics provider`,
  key1001412: `English US name`,
  key1001413: `English UK name`,
  key1001414: `English - AU name`,
  key1001415: `English - EU name`,
  key1001416: `Visible after login`,
  key1001417: `确认中`,
  key1001418: `被退回`,
  key1001419: `Completed`,
  key1001420: `付款中`,
  key1001421: `Original recipient name`,
  key1001422: `Original recipient email`,
  key1001423: `Original recipient phone number`,
  key1001424: `Original recipient user`,
  key1001425: `Original receiving country code`,
  key1001426: `Original recipient's mobile phone`,
  key1001427: `Original shipping address 1`,
  key1001428: `Original shipping address 2`,
  key1001429: `Original recipient city`,
  key1001430: `Original recipient state/province`,
  key1001431: `Original recipient's postal code`,
  key1001432: `Original recipient's passport code`,
  key1001433: `Order [`,
  key1001434: `]Successfully modified the address:`,
  key1001435: `Payment, actual amount paid:`,
  key1001436: `Distributor has made payment, actual amount paid:`,
  key1001437: `Price adjustment; Total original price of goods:`,
  key1001438: `; Distributor's payable amount:`,
  key1001439: `; Supplier's receivable amount:`,
  key1001440: `Arrived at warehouse`,
  key1001441: `Mark as not`,
  key1001442: `Order, empty goods & logistics information`,
  key1001443: `Automatic matching of order items or logistics failed`,
  key1001444: `Original order cancellation`,
  key1001445: `No logistics matched`,
  key1001446: `Matching basic logistics failed`,
  key1001447: `Cancel the order successfully`,
  key1001448: `automatic matching logistics success:`,
  key1001449: `Order`,
  key1001450: `Successfully matched logistics:`,
  key1001451: `]Match logistics special rules:[`,
  key1001452: `]Matching scope:`,
  key1001453: `Matching scheme rules:`,
  key1001454: `Successfully matched product manually: Platform SKU:`,
  key1001455: `, match`,
  key1001456: `Failed to manually match product: Platform SKU:`,
  key1001457: `, not matched`,
  key1001458: `Successfully matched product: Platform SKU:`,
  key1001459: `Automatic matching of products failed: Platform SKU:`,
  key1001460: `SKU failed, SKU discontinued`,
  key1001461: `Failed to place order, distributor balance is insufficient`,
  key1001462: `Change logistics information, original logistics method:`,
  key1001463: `Original waybill number:`,
  key1001464: `Original transaction number:`,
  key1001465: `Shipment failed due to:`,
  key1001466: `Successfully shipped with tracking number:`,
  key1001467: `Cancellation of order failed due:`,
  key1001468: `Failed to place the order`,
  key1001469: `Successfully ordered`,
  key1001470: `Automatic matching of logistics failed, no eligible logistics methods were found`,
  key1001471: `(No logistics that meet the basic conditions were found)`,
  key1001472: `Failed to modify address information`,
  key1001473: `]Successfully modified logistics:`,
  key1001474: `Original logistics`,
  key1001475: `Change to`,
  key1001476: `]Failed to modify logistics information`,
  key1001477: `Add Authorization`,
  key1001478: `Update Authorization`,
  key1001479: `Authorization expired`,
  key1001480: `Manual labeling of platform orders indicates successful shipment`,
  key1001481: `Edit IOSS; IOSS Name:`,
  key1001482: `; IOSS number:`,
  key1001483: `Add IOSS; IOSS Name:`,
  key1001484: `Add rules; Rule Name:`,
  key1001485: `; IOSS used:`,
  key1001486: `; Applicable platforms:`,
  key1001487: `; Applicable stores:`,
  key1001488: `Editing rules; Rule Name:`,
  key1001489: `Cancel transaction, Cancellation Amount:`,
  key1001490: `Partial Cancellation, Cancellation Amount:`,
  key1001491: `Create a stock order`,
  key1001492: `Confirm order acceptance and accept price adjustment`,
  key1001493: `Refuse price adjustment`,
  key1001494: `Undelivered part has been refunded, refund amount`,
  key1001495: `, currency:`,
  key1001496: `All sub orders have arrived at the warehouse and have been completed`,
  key1001497: `Place an order`,
  key1001498: `Goods out of stock, restocking in progress:; Estimated delivery time:`,
  key1001499: `; The out of stock content is as follows:;`,
  key1001500: `The goods are out of stock and have been discontinued:; The out of stock content is as follows:;`,
  key1001501: `Please provide postal code information and try again`,
  key1001502: `Add attribute`,
  key1001503: `Enable Properties`,
  key1001504: `Deactivate Properties`,
  key1001505: `Delivery successful`,
  key1001506: `Successfully issued logistics`,
  key1001507: `Change logistics information; Original logistics method:`,
  key1001508: `; Original waybill number:`,
  key1001509: `; Original item flow tracking number:`,
  key1001510: `Payment successful`,
  key1001511: `Void order successfully`,
  key1001512: `Generate bills`,
  key1001513: `Update warehouse bills`,
  key1001514: `Generate customer bills`,
  key1001515: `Logistics sorting completed`,
  key1001516: `New rate:`,
  key1001517: `Edit rate:`,
  key1001518: `Generate a supplier invoice`,
  key1001519: `Generate a distributor invoice`,
  key1001520: `Failed to change order and returned to the generated picking list`,
  key1001521: `From picking list【`,
  key1001522: `】Remove`,
  key1001523: `Reset sorting`,
  key1001524: `Mark Picked`,
  key1001525: `Picking list has been generated, picking list number:【`,
  key1001526: `Failed to change order and returned to the picking list`,
  key1001527: `The bill of lading has been collected`,
  key1001528: `Bill of lading created`,
  key1001529: `The bill of lading has been stored in the warehouse`,
  key1001530: `The bill of lading has been shelved`,
  key1001531: `Bill of lading sorted`,
  key1001532: `There are excess packages, please contact the supplier`,
  key1001533: `Reason for failure to pass the review:`,
  key1001534: `Approved by review`,
  key1001535: `Change to`,
  key1001536: `Review failed:`,
  key1001537: `Failed by review`,
  key1001538: `Failed to pass the audit`,
  key1001539: `Adjusting subsidies`,
  key1001540: `by`,
  key1001541: `adjust to`,
  key1001542: `; Adjustment instructions:`,
  key1001543: `Manually adding suppliers for operations`,
  key1001544: `Deactivate supplier`,
  key1001545: `Enable supplier`,
  key1001546: `Edit Supplier`,
  key1001547: `Registering suppliers in the mall`,
  key1001548: `Entering the mall to improve supplier and enterprise information`,
  key1001549: `Review rejection, reason for rejection:`,
  key1001550: `New configuration added`,
  key1001551: `Edit Configuration`,
  key1001552: `Allocated inventory`,
  key1001553: `Matching intelligent logistics rules:`,
  key1001554: `Create Order`,
  key1001555: `Outbound order No.:`,
  key1001556: `Successfully matched the warehouse automatically; Shipping warehouse:`,
  key1001557: `Canceled order`,
  key1001558: `Cancel Order, Cancellation Reason:`,
  key1001559: `Logistics [`,
  key1001560: `]Change to[`,
  key1001561: `; Special rules:`,
  key1001562: `Applicable scope`,
  key1001563: `Freight/Time Priority`,
  key1001564: `Abnormal package status obtained before uploading package`,
  key1001565: `Application for waybill number failed`,
  key1001566: `Failed to obtain shipping number`,
  key1001567: `Exception in obtaining AliExpress product labels`,
  key1001568: `Successfully applied for waybill number`,
  key1001569: `(Waybill number:`,
  key1001570: `(Reason for failure:`,
  key1001571: `Edits re uploading package exception`,
  key1001572: `Manual modification: Update warehouse bill 【 Document cost 】`,
  key1001573: `Manual modification: Update warehouse bill [other expenses]`,
  key1001574: `Manual modification: adding other expenses【`,
  key1001575: `Add notes`,
  key1001576: `Manual modification`,
  key1001577: `Currency modification`,
  key1001578: `Import billing updates`,
  key1001579: `Confirm settlement`,
  key1001580: `Manual mark mark mark allows order cancellation`,
  key1001581: `Due to the change of address, the current logistics cannot be delivered. The original order has been canceled. Please re-match the logistics and place the order.`,
  key1001582: `Receiving`,
  key1001583: `The original order has been canceled due to address modification.`,
  key1001584: `Voided bill of lading, reason:`,
  key1001585: `Manually mark payment success`,
  key1001586: `The buyer modifies the receiving address and updates the order receiving address.`,
  key1001587: `Payment amount:`,
  key1001588: `, Duration:`,
  key1001589: `Months`,
  key1001590: `First allocation of stock documents`,
  key1001591: `Change of merchant's industrial and commercial information, reason for rejection:`,
  key1001592: `Supplier modified:`,
  key1001593: `Merchant business information change, approved`,
  key1001594: `Add tag to order:`,
  key1001595: `Order Delete Tag:`,
  key1001596: `There is a stock document that has not yet arrived and is waiting for in-transit inventory.`,
  key1001597: `Add notes to order:`,
  key1001598: `Meixi warehouse stock is insufficient, waiting for the next distribution.`,
  key1001599: `Review Billing`,
  key1001600: `Confirm billing`,
  key1001601: `Confirm payment`,
  key1001602: `Goods discontinued:`,
  key1001603: `Stopped Goods:`,
  key1001604: `，生成货品标签异常;`,
  key1001605: `线下付款成功`,
  key1001606: `手动匹配仓库成功:`,
  key1001607: `分销商付款`,
  key1001608: `[Lowest shipping cost]`,
  key1001609: `[Fastest time]`,
  key1001610: `Unable to stock up to`,
  key1001611: `warehouse`,
  key1001612: `Currently, orders can be placed at most.`,
  key1001613: `individual`,
  key1001614: `There are duplicates, please modify them`,
  key1001615: `Size value language:`,
  key1001616: `Cannot be empty`,
  key1001617: `Delivery note:`,
  key1001618: `Not waiting for picking status, please remove it before completing the addition`,
  key1001619: `AliExpress appointment delivery failed`,
  key1001620: `The quantity exceeds the limit, please check the quantity`,
  key1001621: `Current container`,
  key1001622: `Being occupied`,
  key1001623: `Already exists`,
  key1001624: `Operation distribution logistics exception: different warehouses`,
  key1001625: `Orders cannot be shipped simultaneously`,
  key1001626: `Logistics exception during operation: store address mismatch`,
  key1001627: `Booked, Collection Number:`,
  key1001628: `Supplier system outbound exception, bill of lading number:`,
  key1001629: `Handover order number:`,
  key1001630: `Appointment delivery exception`,
  key1001631: `The goods are not required for this order. Please confirm the order to which the goods belong`,
  key1001632: `Bill of Lading:`,
  key1001633: `Not present, please enter the correct bill of lading number`,
  key1001634: `There are issued cloud sell orders`,
  key1001635: `Enable status:`,
  key1001636: `Please enter the rule name`,
  key1001637: `Add Rules`,
  key1001638: `priority`,
  key1001639: `Place to the bottom`,
  key1001640: `Place to the top`,
  key1001641: `Move`,
  key1001642: `Rule name`,
  key1001643: `Enable Status`,
  key1001644: `Founder`,
  key1001645: `Creation time`,
  key1001646: `Last update date`,
  key1001647: `Modification success`,
  key1001648: `Delete prompt`,
  key1001649: `After deleting the rule, it will not be recoverable. Do you want to delete it?`,
  key1001650: `Warehouse cannot be empty`,
  key1001651: `Action type:`,
  key1001652: `Warehouse:`,
  key1001653: `Designated performing party`,
  key1001654: `Set action`,
  key1001655: `Assign shipping warehouse`,
  key1001656: `When the assigned warehouse is out of stock, it supports continuing to match other allocation warehouse rules`,
  key1001657: `Disable`,
  key1001658: `Ali International`,
  key1001659: `View the rules`,
  key1001660: `Edit rules`,
  key1001661: `Copy Rules`,
  key1001662: `Please select a rule item first!`,
  key1001663: `Copy successfully`,
  key1001664: `Add container type`,
  key1001665: `Container Type:`,
  key1001666: `Please enter the container type, e.g. pass box`,
  key1001667: `Weight (kg):`,
  key1001668: `Volume (cm³):`,
  key1001669: `Length (cm):`,
  key1001670: `Width (cm):`,
  key1001671: `Height (cm):`,
  key1001672: `Notes:`,
  key1001673: `Container type`,
  key1001674: `Weight(kg)`,
  key1001675: `Volume (cm³)`,
  key1001676: `Length (cm)`,
  key1001677: `Width(cm)`,
  key1001678: `Height(cm)`,
  key1001679: `Container type cannot be null`,
  key1001680: `Container weight cannot be empty`,
  key1001681: `Container volume cannot be empty`,
  key1001682: `Container length cannot be empty`,
  key1001683: `Container width cannot be empty`,
  key1001684: `Container height cannot be empty`,
  key1001685: `Platform Currency:`,
  key1001686: `Supplier Currency:`,
  key1001687: `Add Supporting Currency`,
  key1001688: `Distributor Currency:`,
  key1001689: `Platform Currency`,
  key1001690: `Transaction Currency`,
  key1001691: `Exchange rate configuration`,
  key1001692: `Fixed exchange rate`,
  key1001693: `Real time exchange rate fluctuations`,
  key1001694: `Floating ratio`,
  key1001695: `Please enter a fixed exchange rate`,
  key1001696: `Exchange rate display`,
  key1001697: `Real time exchange rate:`,
  key1001698: `System exchange rate:`,
  key1001699: `Fixed exchange rate cannot be empty!`,
  key1001700: `The fixed exchange rate must be greater than or equal to 0 and a maximum of 4 decimal places allowed`,
  key1001701: `Real time exchange rate fluctuations cannot be empty!`,
  key1001702: `Real time exchange rate fluctuations must be greater than or equal to 0 and a maximum of 4 decimal places allowed`,
  key1001703: `Print container barcodes`,
  key1001704: `Dielectric Material:`,
  key1001705: `Label size:`,
  key1001706: `Custom content:`,
  key1001707: `Custom content font`,
  key1001708: `Bar code font`,
  key1001709: `Each PT is about 0.35 mm wide. Make sure that the longest number of SKU characters * the left PT value * 0.35 is smaller than the medium size.`,
  key1001710: `Do not print custom content`,
  key1001711: `Print custom content`,
  key1001712: `add`,
  key1001713: `set a unified print quantity`,
  key1001714: `Note: Printed content can be dragged to change position.`,
  key1001715: `Please enter custom content`,
  key1001716: `Add Message Template`,
  key1001717: `Message name`,
  key1001718: `Message type`,
  key1001719: `Notification channel`,
  key1001720: `The way of notification`,
  key1001721: `Mail within Website`,
  key1001722: `Email->SMS`,
  key1001723: `SMS->Mailbox`,
  key1001724: `After deleting the message template, the reminder message will no longer be sent. Are you sure to delete it?`,
  key1001725: `Message name:`,
  key1001726: `Please enter the name of the message, with a maximum of 20 Chinese characters`,
  key1001727: `Message type:`,
  key1001728: `Triggering conditions:`,
  key1001729: `Notification channel:`,
  key1001730: `The way of notification：`,
  key1001731: `Whether to pop up:`,
  key1001732: `Reminder time:`,
  key1001733: `The reminder time period is: 6:00-23:00, and no reminder in other time periods`,
  key1001734: `Please select a time.`,
  key1001735: `The stay time of the status exceeded`,
  key1001736: `Remind in time`,
  key1001737: `Fixed time`,
  key1001738: `Every`,
  key1001739: `Remind once`,
  key1001740: `1) Dynamic fields can be added by dragging and dropping from the right side.`,
  key1001741: `2) The blue font text is a dynamic parameter, please do not modify it.`,
  key1001742: `3) Customized content only supports internal messages and emails, and SMS will be sent according to the default content.`,
  key1001743: `dynamic parameter `,
  key1001744: `Title cannot be empty!`,
  key1001745: `The content cannot be empty!`,
  key1001746: `The message type cannot be empty`,
  key1001747: `Content:`,
  key1001748: `Edit Message Template`,
  key1001749: `Created successfully`,
  key1001750: `Edit successfully`,
  key1001751: `When the reminder time type is fixed time and the unit is the day, the specific reminder time period cannot be empty!`,
  key1001752: `The frequency of trigger conditions cannot be empty!`,
  key1001753: `The frequency cannot be empty when the reminder type is the fixed time!`,
  key1001754: `Storage area Name:`,
  key1001755: `Storage area code:`,
  key1001756: `Storage area Status:`,
  key1001757: `Warehouse name:`,
  key1001758: `Print warehouse barcode`,
  key1001759: `Storage area code`,
  key1001760: `Storage area`,
  key1001761: `Add storage area`,
  key1001762: `Bulk print barcodes`,
  key1001763: `Name of storage area`,
  key1001764: `Warehouse name cannot be empty`,
  key1001765: `Storage area name cannot be empty`,
  key1001766: `The storage area code cannot be empty.`,
  key1001767: `Status cannot be empty`,
  key1001768: `Print quantity`,
  key1001769: `View the library area`,
  key1001770: `Edit Warehouse Area`,
  key1001771: `Please check the data to be printed first!`,
  key1001772: `Warehouse:`,
  key1001773: `Container number:`,
  key1001774: `Please enter the container number`,
  key1001775: `Container color:`,
  key1001776: `Job status:`,
  key1001777: `Number of containers:`,
  key1001778: `Please enter a color name`,
  key1001779: `Bulk delete`,
  key1001780: `Upload audio`,
  key1001781: `Container number`,
  key1001782: `Container color`,
  key1001783: `Job status`,
  key1001784: `Warehouse`,
  key1001785: `Last occupied time`,
  key1001786: `Edit Container Color`,
  key1001787: `The warehouse must not be empty.`,
  key1001788: `idle`,
  key1001789: `Goods sorting completed`,
  key1001790: `Quality inspection in progress`,
  key1001791: `To stick waybill`,
  key1001792: `In sticking waybills`,
  key1001793: `Waiting on-shelf`,
  key1001794: `In publishing`,
  key1001795: `Waiting for multiple item sorting`,
  key1001796: `Multi item sorting`,
  key1001797: `Logistics sorting`,
  key1001798: `Add container`,
  key1001799: `Please select data`,
  key1001800: `The number of containers cannot be empty`,
  key1001801: `The number of containers must be a positive integer greater than 0`,
  key1001802: `Failed to upload audio, please try again`,
  key1001803: `Template selection cannot be empty`,
  key1001804: `Warehouse code:`,
  key1001805: `Warehouse type:`,
  key1001806: `Template selection:`,
  key1001807: `Regular order processing fee:`,
  key1001808: `Regular Multi Product Order Continuation:`,
  key1001809: `Regular order consumables fee:`,
  key1001810: `Stock order processing fee:`,
  key1001811: `Warehouse rent:`,
  key1001812: `/Pieces`,
  key1001813: `/Single`,
  key1001814: `/Month`,
  key1001815: `Warehouse processing fee`,
  key1001816: `+Add tiered pricing`,
  key1001817: `everyday`,
  key1001818: `hour`,
  key1001819: `Add a new warehouse`,
  key1001820: `Warehouse code cannot be empty`,
  key1001821: `Warehouse type cannot be empty`,
  key1001822: `Regular order processing fee cannot be empty`,
  key1001823: `Regular multi item order continuation cannot be empty`,
  key1001824: `The processing fee for stocking orders cannot be empty`,
  key1001825: `Warehouse shipping address`,
  key1001826: `Warehouse delivery address`,
  key1001827: `Warehouse return address`,
  key1001828: `Full name`,
  key1001829: `Country/Region`,
  key1001830: `Province/State`,
  key1001831: `City`,
  key1001832: `county `,
  key1001833: `Street address`,
  key1001834: `Mobile phone number`,
  key1001835: `Zip code`,
  key1001836: `Company Name`,
  key1001837: `Email`,
  key1001838: `Name:`,
  key1001839: `Edit warehouses`,
  key1001840: `View warehouse`,
  key1001841: `Name is required.`,
  key1001842: `The left interval cannot be empty!`,
  key1001843: `The right interval cannot be empty!`,
  key1001844: `Layered price cannot be empty!`,
  key1001845: `The left interval cannot be greater than or equal to the right interval`,
  key1001846: `Mark as Readed`,
  key1001847: `Total`,
  key1001848: `Messages, of which unread messages`,
  key1001849: `Click to view`,
  key1001850: `Message title`,
  key1001851: `Time`,
  key1001852: `Please tick the data you want to mark as read first!`,
  key1001853: `Destination country`,
  key1001854: `Customs clearance rate`,
  key1001855: `updater`,
  key1001856: `Update date`,
  key1001857: `Log`,
  key1001858: `Enable Confirmation`,
  key1001859: `Deactivation confirmation`,
  key1001860: `After activation, this destination country will verify the IOSS number. Please confirm whether to enable it?`,
  key1001861: `After deactivation, this destination country will no longer verify the IOSS number. Please confirm if you want to deactivate it?`,
  key1001862: `Confirm activation`,
  key1001863: `Confirm Deactivation`,
  key1001864: `Enabling Success`,
  key1001865: `Disabling Success`,
  key1001866: `商城默认语言配置`,
  key1001867: `语言选择：`,
  key1001868: `默认语言已设置为`,
  key1001869: `商城语言配置`,
  key1001870: `修改默认语言`,
  key1001871: `Setup succeeded！`,
  key1001872: `Destination country:`,
  key1001873: `Customs clearance rate:`,
  key1001874: `The destination country cannot be empty`,
  key1001875: `The customs clearance rate cannot be empty`,
  key1001876: `Supports up to two decimal places, must be greater than or equal to 0`,
  key1001877: `Add tax rate settings`,
  key1001878: `Edit tax rate settings`,
  key1001879: `The customs clearance rate cannot be empty!`,
  key1001880: `Number of SKU species declared`,
  key1001881: `Number of SKU species declared per declaration`,
  key1001882: `The package contains information about goods to be declared when multiple SKUs are included`,
  key1001883: `Calculating method of declared weight`,
  key1001884: `Calculation method of declared price`,
  key1001885: `Default declaration settings`,
  key1001886: `All declarations`,
  key1001887: `Limit the maximum number of declared varieties`,
  key1001888: `Actual quantity`,
  key1001889: `Limit the maximum number of declarations`,
  key1001890: `This item is only used to determine the name and quantity of declaration, and has no effect on the weight and price of declaration.`,
  key1001891: `Use Fixed Total Weight Declaration`,
  key1001892: `The total weight of the fixed package is`,
  key1001893: `If the weight of each item needs to be declared in detail at the time of declaration, the system will automatically calculate the weighted average of the actual weight of the item declared.`,
  key1001894: `Declaration of true weight of goods used`,
  key1001895: `Set the top weight of the package to be`,
  key1001896: `Currency for declaration`,
  key1001897: `Use fixed price declaration`,
  key1001898: `Fixed declared price`,
  key1001899: `This item only includes the amount of money, and the currencies are taken from the declared currencies.`,
  key1001900: `Use price ratio declaration`,
  key1001901: `Use parcel`,
  key1001902: `Of`,
  key1001903: `Setting the lowest price`,
  key1001904: `Setting cap price`,
  key1001905: `After setting this option, the system will automatically convert the exchange rate according to the declared currency.`,
  key1001906: `Total sum`,
  key1001907: `Total amount of products`,
  key1001908: `Choose according to the order of unit price of goods cost from high to low`,
  key1001909: `Choose from high to low in the order of unit price X of the product cost`,
  key1001910: `Select according to the product volume size from large to small`,
  key1001911: `Choose according to the order of volume X from large to small`,
  key1001912: `Choose according to the order of weight from big to small`,
  key1001913: `Choose according to the order of weight X quantity from large to small`,
  key1001914: `The top price should be higher than the minimum price.`,
  key1001915: `Please enter the lowest price setting.`,
  key1001916: `Please enter Set Top Price`,
  key1001917: `Please enter the setting package capping weight`,
  key1001918: `Warehouse name`,
  key1001919: `Warehouse code`,
  key1001920: `Warehouse type`,
  key1001921: `Country`,
  key1001922: `Self-support`,
  key1001923: `Please select the warehouse first`,
  key1001924: `Reservoir name:`,
  key1001925: `Storage bin Code:`,
  key1001926: `Storage bin Status:`,
  key1001927: `Print storage bin barcode`,
  key1001928: `Storage bin code`,
  key1001929: `Storage bin`,
  key1001930: `Import storage bin`,
  key1001931: `Add storage bin`,
  key1001932: `Hint:`,
  key1001933: `1. Support extension name:`,
  key1001934: `2. When there are exceptions in the imported data, the data import fails;`,
  key1001935: `3. After the import operation, please review the import results later in the "Import/Export Tasks - Import View" section.`,
  key1001936: `Name of reservoir area`,
  key1001937: `Code of the reservoir area to which it belongs`,
  key1001938: `Storage bin code cannot be empty.`,
  key1001939: `View location`,
  key1001940: `Edit location`,
  key1001941: `Return list`,
  key1001942: `Please enter the template name`,
  key1001943: `Deactivate Address`,
  key1001944: `Add address`,
  key1001945: `You need to select a new address to replace the deactivated address. Please select the address template you want to replace`,
  key1001946: `Template name`,
  key1001947: `cellphone number`,
  key1001948: `Enabled`,
  key1001949: `deactivated`,
  key1001950: `Deactivated successfully!`,
  key1001951: `Inbound sorting settings`,
  key1001952: `Picking container:`,
  key1001953: `Packaging container:`,
  key1001954: `Switch Warehouse`,
  key1001955: `Bill of Lading Collection`,
  key1001956: `Process status: enabled`,
  key1001957: `Bill of Lading Warehousing`,
  key1001958: `Inbound sorting`,
  key1001959: `Fast outbound mode`,
  key1001960: `Label during sorting`,
  key1001961: `Product publish`,
  key1001962: `Multi item sorting`,
  key1001963: `Skip this process for single item tasks`,
  key1001964: `Packaging operations`,
  key1001965: `Logistics sorting`,
  key1001966: `Shelving container`,
  key1001967: `Labeling+shelving containers`,
  key1001968: `Outbound container`,
  key1001969: `Packaging container`,
  key1001970: `Labeling+Packaging Container`,
  key1001971: `Picking container`,
  key1001972: `Sticking and picking containers`,
  key1001973: `Quantity limit for sorting container outbound orders`,
  key1001974: `We currently do not have warehouse data permissions. Please configure warehouse permissions and try again.`,
  key1001975: `There are ongoing inbound sorting tasks, please complete them before making any modifications.`,
  key1001976: `Name:`,
  key1001977: `Country/Region:`,
  key1001978: `Province/State:`,
  key1001979: `City:`,
  key1001980: `District:`,
  key1001981: `Street address:`,
  key1001982: `phone number:`,
  key1001983: `Zip code:`,
  key1001984: `Company Name:`,
  key1001985: `Email:`,
  key1001986: `Template name cannot be null.`,
  key1001987: `Name cannot be empty`,
  key1001988: `Country/Region cannot be empty`,
  key1001989: `Province and state cannot be empty`,
  key1001990: `The city cannot be empty`,
  key1001991: `District/County cannot be empty`,
  key1001992: `Street address cannot be empty`,
  key1001993: `Postal code cannot be empty`,
  key1001994: `Edit Address`,
  key1001995: `View address`,
  key1001996: `Special Declaration Rules`,
  key1001997: `Adding time`,
  key1001998: `Order Automation Rules - Edit`,
  key1001999: `Grade name:`,
  key1002000: `Distributors being shown:`,
  key1002001: `Add supplier level`,
  key1002002: `All (including not logged in)`,
  key1002003: `section`,
  key1002004: `Level name cannot be empty`,
  key1002005: `Grade name`,
  key1002006: `Distributor being shown`,
  key1002007: `View supplier level`,
  key1002008: `Edit Supplier Level`,
  key1002009: `Do you want to delete the current supplier level?`,
  key1002010: `Please select a distribution level!`,
  key1002011: `Mobile phone number:`,
  key1002012: `Please fill in the email address`,
  key1002013: `Business license:`,
  key1002014: `Legal representative's ID card:`,
  key1002015: `Business scale:`,
  key1002016: `Contact Name:`,
  key1002017: `Please fill in the contact name`,
  key1002018: `Contact phone number:`,
  key1002019: `Please fill in the contact person's phone number`,
  key1002020: `Fixed phone number:`,
  key1002021: `Please fill in the fixed phone number`,
  key1002022: `Store Name:`,
  key1002023: `Please fill in the store name`,
  key1002024: `Store logo:`,
  key1002025: `Business model:`,
  key1002026: `Planned operating categories:`,
  key1002027: `Expected number of products on the shelves:`,
  key1002028: `Product release frequency:`,
  key1002029: `Please fill in the specific quantity`,
  key1002030: `Business address:`,
  key1002031: `Please fill in the detailed address`,
  key1002032: `The belonging warehouse is the destination warehouse for the supplier's shipment, and at least one should be selected.`,
  key1002033: `The bill of the previous month is generated on the X day of each month, excluding the bill of the current month.`,
  key1002034: `Supplier level:`,
  key1002035: `Whether to allow the printing of logistics receipts:`,
  key1002036: `Whether to allow shipping directly:`,
  key1002037: `Application time:`,
  key1002038: `Reason for rejection:`,
  key1002039: `Account information`,
  key1002040: `Password:`,
  key1002041: `Regenerate`,
  key1002042: `Enterprise Information`,
  key1002043: `Only JPG, JPEG, and PNG formats are supported.`,
  key1002044: `Anthropomorphic portrait`,
  key1002045: `National Emblem Face`,
  key1002046: `Store Information`,
  key1002047: `Size: 300 * 300px, only supports JPG, JPEG, and PNG formats.`,
  key1002048: `paragraph`,
  key1002049: `Belonging to warehouse:`,
  key1002050: `Preferred Warehouse`,
  key1002051: `Set as preferred warehouse`,
  key1002052: `Account settlement:`,
  key1002053: `Monthly`,
  key1002054: `Sunrise Bill`,
  key1002055: `Payment information`,
  key1002056: `findings of audit`,
  key1002057: `upload`,
  key1002058: `The upload file format is wrong, the file`,
  key1002059: `Wrong format,`,
  key1002060: `Please select [jpg, jpeg, png]`,
  key1002061: `Add supplier`,
  key1002062: `Please select the business scale`,
  key1002063: `Contact person cannot be empty`,
  key1002064: `Shop name cannot be null.`,
  key1002065: `Please select a business model`,
  key1002066: `Please select the planned business category`,
  key1002067: `Please select the expected number of products to be listed`,
  key1002068: `The detailed address cannot be empty.`,
  key1002069: `Supplier level cannot be empty`,
  key1002070: `Settlement per month`,
  key1002071: `Settlement every half month`,
  key1002072: `Settlement per week`,
  key1002073: `Less than 10 people`,
  key1002074: `10-30 people`,
  key1002075: `30-50 people`,
  key1002076: `50-100 people`,
  key1002077: `More than 100 people`,
  key1002078: `Own factory`,
  key1002079: `Channel Agent`,
  key1002080: `Distribution and wholesale`,
  key1002081: `Women's clothing`,
  key1002082: `Men's clothing`,
  key1002083: `Boys`,
  key1002084: `Girls`,
  key1002085: `Infants and children`,
  key1002086: `Below 10`,
  key1002087: `Above 100`,
  key1002088: `To be confirmed`,
  key1002089: `Publish daily`,
  key1002090: `Weekly release`,
  key1002091: `Monthly release`,
  key1002092: `Payment method:`,
  key1002093: `Account Holder Name:`,
  key1002094: `Account Name:`,
  key1002095: `Bank card number:`,
  key1002096: `ID number:`,
  key1002097: `Alipay account:`,
  key1002098: `Account Holder ID Number:`,
  key1002099: `company`,
  key1002100: `Entry Review Status:`,
  key1002101: `Review status of industrial and commercial information change:`,
  key1002102: `Supplier Details`,
  key1002103: `Edit supplier information`,
  key1002104: `Audit suppliers`,
  key1002105: `Review supplier business information changes`,
  key1002106: `bank card`,
  key1002107: `Alipay`,
  key1002108: `Business license`,
  key1002109: `Legal Representative ID Card National Emblem Face`,
  key1002110: `Identity of the Legal Representative Witness Image`,
  key1002111: `Store logo`,
  key1002112: `Please upload your business license first`,
  key1002113: `Please upload the national emblem of the legal representative's ID card first`,
  key1002114: `Please upload the photo of the legal representative as a witness first`,
  key1002115: `Please upload the store logo first`,
  key1002116: `Please select the product release frequency type first!`,
  key1002117: `Account period settlement cannot be empty!`,
  key1002118: `The audit was successful!`,
  key1002119: `The number of product releases cannot be empty`,
  key1002120: `The number of product releases must be a positive integer greater than 0`,
  key1002121: `Business address cannot be empty`,
  key1002122: `Please select the complete business address of the province or city`,
  key1002123: `Submission time:`,
  key1002124: `Search characters:`,
  key1002125: `Supplier Name/Supplier Merchant ID/Contact Person/Phone/Email`,
  key1002126: `Add new suppliers`,
  key1002127: `Supplier code`,
  key1002128: `Please enter the supplier number, which supports multiple commas or line breaks for separation`,
  key1002129: `Please enter your phone number, which supports multiple commas or line breaks for separation`,
  key1002130: `Please enter an email address that supports multiple commas or line breaks`,
  key1002131: `Certified Supplier`,
  key1002132: `Blacklist Suppliers`,
  key1002133: `Successfully enabled!`,
  key1002134: `Contact person`,
  key1002135: `Mobile phone`,
  key1002136: `Telephone`,
  key1002137: `Total number of SKUs`,
  key1002138: `Registration time`,
  key1002139: `down time `,
  key1002140: `Enable this supplier? After enabled, the supplier account can be used to log on to the platform.`,
  key1002141: `Name`,
  key1002142: `Reason for discontinuation:`,
  key1002143: `Please enter the reason for deactivation`,
  key1002144: `After deactivation, suppliers will not be able to log in to the system and perform any operations such as shipping. Please operate with caution!`,
  key1002145: `Is the supplier information approved?`,
  key1002146: `Supplier Status`,
  key1002147: `preliminary examination`,
  key1002148: `Information change`,
  key1002149: `Legal Representative Identity Card`,
  key1002150: `Scale of operation`,
  key1002151: `Contact Name`,
  key1002152: `Contact Phone Number`,
  key1002153: `Fixed phone number`,
  key1002154: `Submission time for review`,
  key1002155: `Rejection time`,
  key1002156: `to examine`,
  key1002157: `Through the audit`,
  key1002158: `Entry Review`,
  key1002159: `Business information change audit`,
  key1002160: `Time rejected:`,
  key1002161: `Confirmed`,
  key1002162: `Reject application`,
  key1002163: `Please enter the reason for rejection`,
  key1002164: `Rejected successfully!`,
  key1002165: `Statistics`,
  key1002166: `Cumulative total amount of issued orders in order status.`,
  key1002167: `Cumulative total order quantity whose order status is issued.`,
  key1002168: `The mall SKU status is the total quantity on sale.`,
  key1002169: `Calculate the total number of products subscribed by all distributors, and only calculate once for multiple distributors subscribing to the same product.`,
  key1002170: `Calculate the total number of products that have been listed on the platform by all distributors, and only calculate once for different items of the same product.`,
  key1002171: `Calculate the total number of publications published by all distributors, based on the number of items.`,
  key1002172: `Sales`,
  key1002173: `Order volume`,
  key1002174: `Supplier Order Ranking`,
  key1002175: `Distributor Order Ranking`,
  key1002176: `Total sales revenue（`,
  key1002177: `Sales revenue for this month`,
  key1002178: `Yesterday's sales`,
  key1002179: `Today's sales`,
  key1002180: `Total number of orders (single)`,
  key1002181: `Number of orders for this month`,
  key1002182: `Yesterday's orders`,
  key1002183: `Today's orders`,
  key1002184: `Number of products on sale (SKU)`,
  key1002185: `New this month`,
  key1002186: `New from yesterday`,
  key1002187: `New today`,
  key1002188: `Product Subscriptions (SPU)`,
  key1002189: `Subscription this month`,
  key1002190: `Subscribed yesterday`,
  key1002191: `Subscribe Today`,
  key1002192: `Number of products listed (SPU)`,
  key1002193: `Number of listings this month`,
  key1002194: `Yesterday's listings`,
  key1002195: `Number of listings today`,
  key1002196: `Number of published items`,
  key1002197: `Number of publications this month`,
  key1002198: `Number of publications published yesterday`,
  key1002199: `Number of publications published today`,
  key1002200: `Total number of suppliers`,
  key1002201: `New additions this month`,
  key1002202: `Added yesterday`,
  key1002203: `New today`,
  key1002204: `Total number of distributors`,
  key1002205: `Order state`,
  key1002206: `Waiting for order`,
  key1002207: `Pending order`,
  key1002208: `This week`,
  key1002209: `This month`,
  key1002210: `this year`,
  key1002211: `Month`,
  key1002212: `The time span cannot exceed one year`,
  key1002213: `Ranking`,
  key1002214: `than last month`,
  key1002215: `than last week`,
  key1002216: `Distributor business number`,
  key1002217: `Distributor name`,
  key1002218: `Product list`,
  key1002219: `Stick waybill`,
  key1002220: `Inventory Balance`,
  key1002221: `Bill of Lading List`,
  key1002222: `Outbound details`,
  key1002223: `Questionnaire statistics`,
  key1002224: `Subsidy review`,
  key1002225: `Recharge record`,
  key1002226: `Supplier Total Bill`,
  key1002227: `Supplier Detailed Bill`,
  key1002228: `Supplier orders`,
  key1002229: `Item attribute pool`,
  key1002230: `Customer billing`,
  key1002231: `Logistics billing`,
  key1002232: `Warehouse bill`,
  key1002233: `Inventory details`,
  key1002234: `Item attributes`,
  key1002235: `Product information`,
  key1002236: `Category Mapping`,
  key1002237: `Notify to:`,
  key1002238: `Founder：`,
  key1002239: `Sent:`,
  key1002240: `Create Announcement`,
  key1002241: `draft`,
  key1002242: `To be modified`,
  key1002243: `Waiting to send`,
  key1002244: `has been sent`,
  key1002245: `Announcement Name`,
  key1002246: `send status`,
  key1002247: `Notification object`,
  key1002248: `Send time`,
  key1002249: `(Reservation)`,
  key1002250: `Reviewer`,
  key1002251: `Approval prompt`,
  key1002252: `After passing the review, the system will send an announcement at the specified time. Please confirm if the review has been approved?`,
  key1002253: `Delete announcement prompt`,
  key1002254: `The announcement cannot be restored after deletion. Are you sure you want to delete it?`,
  key1002255: `Deletion succeeded！`,
  key1002256: `Cancel sending`,
  key1002257: `Cancel sending prompt`,
  key1002258: `After cancellation, this announcement will be circulated as a draft. Are you sure you want to cancel sending?`,
  key1002259: `Create a new questionnaire`,
  key1002260: `Questionnaire Name`,
  key1002261: `Questionnaire status`,
  key1002262: `Paused`,
  key1002263: `Answering questions`,
  key1002264: `View volume`,
  key1002265: `Recovery rate`,
  key1002266: `Final modification time`,
  key1002267: `Cannot answer questions after discontinuation`,
  key1002268: `After discontinuation, questionnaire answering will be paused. Are you sure to discontinue?`,
  key1002269: `Questionnaire Title`,
  key1002270: `Questionnaire Description`,
  key1002271: `Please enter the title of the question`,
  key1002272: `Please enter an option`,
  key1002273: `Delivery channels`,
  key1002274: `Display method`,
  key1002275: `Trigger Event`,
  key1002276: `Select question type`,
  key1002277: `Optional`,
  key1002278: `Add Options`,
  key1002279: `Add Other`,
  key1002280: `Questionnaire settings`,
  key1002281: `text`,
  key1002282: `Distributor side`,
  key1002283: `pop-up notification`,
  key1002284: `Do not display the 'skip' button`,
  key1002285: `Display the 'Skip' button`,
  key1002286: `First login`,
  key1002287: `option`,
  key1002288: `Other____`,
  key1002289: `Do you want to save the questionnaire content`,
  key1002290: `Do you need to save an unsaved questionnaire?`,
  key1002291: `Do not save`,
  key1002292: `New successfully!`,
  key1002293: `The title of the question cannot be empty!`,
  key1002294: `The title of the question cannot be duplicate!`,
  key1002295: `New Guidelines`,
  key1002296: `PDF file`,
  key1002297: `Online Content`,
  key1002298: `Guidance Title`,
  key1002299: `Module`,
  key1002300: `Guidance type`,
  key1002301: `Sort`,
  key1002302: `Deactivate reminder`,
  key1002303: `After deactivation, users will not be able to view the tutorial,`,
  key1002304: `Are you sure you want to deactivate it?`,
  key1002305: `Delete Reminder`,
  key1002306: `You are about to permanently delete, and after deletion, you will not be able to recover,`,
  key1002307: `Are you sure you want to delete it?`,
  key1002308: `Directory Name`,
  key1002309: `Content title:`,
  key1002310: `Please enter a content title`,
  key1002311: `Text content:`,
  key1002312: `Sort by:`,
  key1002313: `Display status:`,
  key1002314: `Copy article title`,
  key1002315: `Up to 28 Chinese characters can be entered`,
  key1002316: `The bigger the number, the further back`,
  key1002317: `On`,
  key1002318: `Off`,
  key1002319: `add content`,
  key1002320: `Are you sure to delete [`,
  key1002321: `】Classification?`,
  key1002322: `Title`,
  key1002323: `Release date`,
  key1002324: `Last updated date`,
  key1002325: `display status`,
  key1002326: `Home page recommendation`,
  key1002327: `Add directory`,
  key1002328: `Article title cannot be empty`,
  key1002329: `catalogue`,
  key1002330: `The homepage can recommend up to 5 help documents. Please close other recommendations before opening them!`,
  key1002331: `Are you sure you want to delete the current article?`,
  key1002332: `edit directory`,
  key1002333: `The catalog classification can only have three hierarchical relationships at most, and only the same-level drag sorting is allowed!`,
  key1002334: `Content`,
  key1002335: `Open image on new tab`,
  key1002336: `1: 1 Display image`,
  key1002337: `Summary of problems`,
  key1002338: `Mouse wheel zoom image`,
  key1002339: `Guidance Title:`,
  key1002340: `Please enter the guide title`,
  key1002341: `Module:`,
  key1002342: `Display location:`,
  key1002343: `Guidance type:`,
  key1002344: `Reminder:`,
  key1002345: `1. Only files with PDF extensions are supported.`,
  key1002346: `2. The file size should not exceed 10Mb.`,
  key1002347: `The guide title cannot be empty`,
  key1002348: `The module to which it belongs cannot be empty`,
  key1002349: `Display position cannot be empty`,
  key1002350: `Bread crumbs right`,
  key1002351: `Editing Guidelines`,
  key1002352: `Successfully uploaded the file`,
  key1002353: `Please upload the PDF file first!`,
  key1002354: `The body content cannot be empty!`,
  key1002355: `Announcement Name:`,
  key1002356: `If the sending time has exceeded when the review is passed, the announcement will be sent immediately after the review is passed.`,
  key1002357: `Save as Draft`,
  key1002358: `Submit for review`,
  key1002359: `Please enter the announcement name`,
  key1002360: `Please select the notification object`,
  key1002361: `Edit Announcement`,
  key1002362: `Successfully submitted for review`,
  key1002363: `Please enter the main text content`,
  key1002364: `Please select the sending time`,
  key1002365: `Export Data`,
  key1002366: `Export by Selection`,
  key1002367: `Export according to query results`,
  key1002368: `Please check the data that needs to be exported first!`,
  key1002369: `There is currently no data available for the current filtering results, unable to export!`,
  key1002370: `Merchant ID`,
  key1002371: `Starting time for answering questions`,
  key1002372: `End Answer Time`,
  key1002373: `Customer Name:`,
  key1002374: `Contact person:`,
  key1002375: `Phone number:`,
  key1002376: `Please enter your cell phone number.`,
  key1002377: `Customer level:`,
  key1002378: `Warehouse permissions:`,
  key1002379: `Customer Type:`,
  key1002380: `Distribution customers`,
  key1002381: `Ali International Distribution`,
  key1002382: `Customer level cannot be empty`,
  key1002383: `Link Details`,
  key1002384: `Link Name:`,
  key1002385: `Source channel:`,
  key1002386: `Promoter:`,
  key1002387: `Promotion method:`,
  key1002388: `Promotion content:`,
  key1002389: `Promotion group:`,
  key1002390: `Notes`,
  key1002391: `Link Basic Information`,
  key1002392: `link data`,
  key1002393: `Total number of clicks`,
  key1002394: `Total number of registrations`,
  key1002395: `Registration conversion rate`,
  key1002396: `Please fill in the link name`,
  key1002397: `Time sharing data`,
  key1002398: `Number of clicks`,
  key1002399: `Number of registrations`,
  key1002400: `create link`,
  key1002401: `Please fill in the link address`,
  key1002402: `Created successfully!`,
  key1002403: `Customer details `,
  key1002404: `Customer Information`,
  key1002405: `Number:`,
  key1002406: `Customer Source:`,
  key1002407: `Customer Currency:`,
  key1002408: `Related merchants:`,
  key1002409: `Customer Responsible Person:`,
  key1002410: `Please fill in the remarks`,
  key1002411: `Balance:`,
  key1002412: `Withholding amount:`,
  key1002413: `Total amount:`,
  key1002414: `Used amount:`,
  key1002415: `Input Name`,
  key1002416: `Title:`,
  key1002417: `Please enter a job title`,
  key1002418: `Please enter email.`,
  key1002419: `Please enter a fixed phone number`,
  key1002420: `Micro signal:`,
  key1002421: `Please input micro signal`,
  key1002422: `QQ number:`,
  key1002423: `Please enter the micro QQ number`,
  key1002424: `Customer Journey`,
  key1002425: `Primary Contact`,
  key1002426: `Next contact time:`,
  key1002427: `Operator:`,
  key1002428: `Basic customer information`,
  key1002429: `Customer Financial Information`,
  key1002430: `Customer warehouse permissions`,
  key1002431: `Customer API permissions`,
  key1002432: `Add Contact`,
  key1002433: `Set as Primary Contact`,
  key1002434: `There is currently no travel data available`,
  key1002435: `Contact records`,
  key1002436: `Activate product records`,
  key1002437: `log information`,
  key1002438: `is not a valid mailbox or mailbox format is incorrect!`,
  key1002439: `Information to fill in at least one of them!`,
  key1002440: `Create Group`,
  key1002441: `Group Name`,
  key1002442: `Number of grouped customers`,
  key1002443: `Group Notes`,
  key1002444: `Delete grouping prompt`,
  key1002445: `Operation is in progress to delete the group. Are you sure you want to delete it?`,
  key1002446: `Delete Group`,
  key1002447: `Registration time:`,
  key1002448: `Registration source:`,
  key1002449: `Please select the registration source`,
  key1002450: `Customer label:`,
  key1002451: `Please select the customer label`,
  key1002452: `Please select customer level`,
  key1002453: `Customer Name/Customer Number/Contact Person/Phone/Email/Remarks`,
  key1002454: `New customers`,
  key1002455: `Mobile grouping`,
  key1002456: `Bulk review`,
  key1002457: `Customer number`,
  key1002458: `Please enter the customer number, which supports multiple commas or line breaks for separation`,
  key1002459: `Are you sure to enable it?`,
  key1002460: `Are you sure to deactivate?`,
  key1002461: `Only customers awaiting review can proceed with the review. Please reselect!`,
  key1002462: `Please check the data to be reviewed first`,
  key1002463: `Ungrouped`,
  key1002464: `Customer Name`,
  key1002465: `Customer grouping`,
  key1002466: `Customer label`,
  key1002467: `Customer level`,
  key1002468: `Customer Currency`,
  key1002469: `balance`,
  key1002470: `Withholding amount`,
  key1002471: `Total credit limit`,
  key1002472: `Used quota`,
  key1002473: `Registration Source`,
  key1002474: `Customer remarks`,
  key1002475: `Please select a customer first`,
  key1002476: `Label Name:`,
  key1002477: `Please enter a label name`,
  key1002478: `Label remarks:`,
  key1002479: `Please fill in the label name`,
  key1002480: `create label`,
  key1002481: `Edit Label`,
  key1002482: `Specify grouping:`,
  key1002483: `Please select a group`,
  key1002484: `There is a deletion of the group in this operation. Please regroup the customers under the group`,
  key1002485: `Non Transit Customers`,
  key1002486: `Transit customers`,
  key1002487: `Tongtool merchant account cannot be empty`,
  key1002488: `View Customers`,
  key1002489: `Label Name`,
  key1002490: `Number of labeled customers`,
  key1002491: `Label remarks`,
  key1002492: `Delete label prompt`,
  key1002493: `After deleting the label, it will be synchronously removed from the customer. Are you sure you want to delete it?`,
  key1002494: `Label Details`,
  key1002495: `Basic information of labels`,
  key1002496: `Label type:`,
  key1002497: `Label data`,
  key1002498: `Statistical time:`,
  key1002499: `Search Link Name`,
  key1002500: `Please select a label type`,
  key1002501: `Search Tag Name`,
  key1002502: `Link Name`,
  key1002503: `Link Type`,
  key1002504: `View QR code`,
  key1002505: `Copy Link`,
  key1002506: `Group name:`,
  key1002507: `Please enter a group name`,
  key1002508: `Group remarks:`,
  key1002509: `Please fill in the group name`,
  key1002510: `Enter up to 20 characters`,
  key1002511: `Edit Group`,
  key1002512: `等级折扣系数：`,
  key1002513: `Add customer level`,
  key1002514: `Rank`,
  key1002515: `View customer level`,
  key1002516: `Edit customer level`,
  key1002517: `Do you want to delete the current customer level?`,
  key1002518: `等级折扣系数`,
  key1002519: `Default level when registering`,
  key1002520: `Default`,
  key1002521: `Set as default`,
  key1002522: `Change registration default level`,
  key1002523: `The current operation will affect the default level when registering mall customers. Are you sure to change the default level?`,
  key1002524: `等级折扣系数不能为空`,
  key1002525: `等级折扣系数必须大于 0 且小于等于 1`,
  key1002526: `Enter the outbound order no. to query`,
  key1002527: `Confirm to delete`,
  key1002528: `Picking order details`,
  key1002529: `Pick list No.:`,
  key1002530: `Picking order staus:`,
  key1002531: `Whether to print:`,
  key1002532: `Print picking list`,
  key1002533: `Created by:`,
  key1002534: `Delete the last entry？If the picking list is deleted, it will also be deleted！`,
  key1002535: `View by outbound order`,
  key1002536: `View by SKU`,
  key1002537: `Outbound order status`,
  key1002538: `Logistics distribution status`,
  key1002539: `Not issued`,
  key1002540: `Issued`,
  key1002541: `Remove from picking order`,
  key1002542: `Warehouse`,
  key1002543: `Product Image`,
  key1002544: `Picked quantity`,
  key1002545: `Volume`,
  key1002546: `Unpicked`,
  key1002547: `finished product`,
  key1002548: `Raw material`,
  key1002549: `Picking list:`,
  key1002550: `Material type`,
  key1002551: `Raw material SPU`,
  key1002552: `Raw material SKU`,
  key1002553: `Staff:`,
  key1002554: `(signature/date)`,
  key1002555: `Order picker`,
  key1002556: `(Signature date)`,
  key1002557: `cancel printing`,
  key1002558: `Mark printed successfully`,
  key1002559: `Order No.:`,
  key1002560: `Multiple outbound orders separated by commas`,
  key1002561: `Category:`,
  key1002562: `Sort by:`,
  key1002563: `Generate picking list`,
  key1002564: `Generate picking list based on selection`,
  key1002565: `Generate picking orders (all)`,
  key1002566: `Creation Time`,
  key1002567: `By shipping method`,
  key1002568: `Temu sample`,
  key1002569: `Temu goods`,
  key1002570: `Outbound order type`,
  key1002571: `Distributor order number`,
  key1002572: `SKU Qty`,
  key1002573: `Item Qty`,
  key1002574: `View Details`,
  key1002575: `Please check the data that needs to generate a picking list first!`,
  key1002576: `There is currently no data available for the current filtering results, unable to generate a picking list!`,
  key1002577: `No logistics selected`,
  key1002578: `Unspecified logistic channel`,
  key1002579: `Picking order no.:`,
  key1002580: `Print status：`,
  key1002581: `Bulk operation`,
  key1002582: `Mark as picked`,
  key1002583: `Not printed`,
  key1002584: `Printed`,
  key1002585: `Creation time`,
  key1002586: `By picking order type`,
  key1002587: `Query the packed picking list, the creation time cannot be empty!`,
  key1002588: `Marking Success`,
  key1002589: `Please select`,
  key1002590: `Picking order no.`,
  key1002591: `Picking list type`,
  key1002592: `Picking status`,
  key1002593: `Print status`,
  key1002594: `Number of SKUs`,
  key1002595: `Number of items`,
  key1002596: `Picking finish time`,
  key1002597: `Picking list source`,
  key1002598: `outbound order`,
  key1002599: `Sorting container`,
  key1002600: `Print assembly labels`,
  key1002601: `Failed to obtain the printing assembly label link address!`,
  key1002602: `单位换算`,
  key1002603: `YMS属性单位`,
  key1002604: `平台属性单位`,
  key1002605: `Set successfully`,
  key1002606: `Property name:`,
  key1002607: `Please enter the attribute name, supporting multilingual search`,
  key1002608: `Whether to customize:`,
  key1002609: `Single/Multiple Choice:`,
  key1002610: `Import item attribute`,
  key1002611: `Export Properties`,
  key1002612: `2. When there is an abnormality in the imported data, the data import fails.`,
  key1002613: `number`,
  key1002614: `Specifications/General Properties`,
  key1002615: `Size attribute`,
  key1002616: `Specification/General Properties (Specification, General)`,
  key1002617: `Whether to customize (Yes, No)`,
  key1002618: `Attribute value single/multiple selection (multiple, single selection)`,
  key1002619: `Size attribute (Yes, No)`,
  key1002620: `Filling requirements (mandatory, non mandatory, recommended)`,
  key1002621: `Attribute information`,
  key1002622: `Chinese Simplified`,
  key1002623: `French`,
  key1002624: `German`,
  key1002625: `Spanish`,
  key1002626: `Dutch`,
  key1002627: `Polish`,
  key1002628: `Portuguese`,
  key1002629: `Italian`,
  key1002630: `Attribute Status`,
  key1002631: `属性值/单位信息`,
  key1002632: `Attribute deletion prompt`,
  key1002633: `There is a deletion of attributes in this modification. After deletion, the attribute field in the existing product information will be deleted as well. Are you sure you want to save this modification?`,
  key1002634: `After deactivating the attribute, when suppliers publish new products and modify published products, this attribute will not be available. Are you sure you want to deactivate it?`,
  key1002635: `Immediately deactivate`,
  key1002636: `Add Template`,
  key1002637: `Body part`,
  key1002638: `Unit`,
  key1002639: `Do you want to delete the current size template?`,
  key1002640: `Label commodity quantity`,
  key1002641: `Tag name cannot be empty`,
  key1002642: `Tag`,
  key1002643: `After deleting the label, the label will be removed from the product simultaneously. Please confirm whether to delete it?`,
  key1002644: `Please enter a Chinese title, support multiple lines separated`,
  key1002645: `Supplier:`,
  key1002646: `Commodity Type:`,
  key1002647: `Commodity label:`,
  key1002648: `Please select product label`,
  key1002649: `Export Product List`,
  key1002650: `Bulk Discontinuation`,
  key1002651: `Stop by Select`,
  key1002652: `Stop selling according to query results`,
  key1002653: `Deselect`,
  key1002654: `Addr label`,
  key1002655: `Supplier Information`,
  key1002656: `Classification`,
  key1002657: `Product label`,
  key1002658: `Merchandise available for sale`,
  key1002659: `Recently discontinued`,
  key1002660: `First listing time`,
  key1002661: `First submission for review time`,
  key1002662: `View SKU`,
  key1002663: `View central warehouse inventory`,
  key1002664: `View supplier inventory`,
  key1002665: `1688 Find the same model`,
  key1002666: `Please enter the supplier SKU, which supports multiple row separations`,
  key1002667: `Product categories`,
  key1002668: `Sales status`,
  key1002669: `Last listing time`,
  key1002670: `Inventory allocation in central warehouse`,
  key1002671: `Supplier allocated inventory`,
  key1002672: `Commodity Type`,
  key1002673: `Export in SPU dimension`,
  key1002674: `(One row per SPU in the report)`,
  key1002675: `Export as SKU dimension`,
  key1002676: `(One row per SKU in the report)`,
  key1002677: `minimum value`,
  key1002678: `Maximum value`,
  key1002679: `Supplier warehouse inventory`,
  key1002680: `Product listing time`,
  key1002681: `Please check the data that needs to be stopped first!`,
  key1002682: `The current screening results have no data for the time being and cannot be operated to stop selling!`,
  key1002683: `The minimum inventory value cannot be greater than the maximum value. Please modify the conditions before querying.`,
  key1002684: `Please select goods first`,
  key1002685: `1. Choose a platform`,
  key1002686: `2. Select a site`,
  key1002687: `4. Select Product Type`,
  key1002688: `4. 选择Family Type`,
  key1002689: `5. Product Specification Mapping`,
  key1002690: `6. Product Attribute Mapping`,
  key1002691: `Custom values`,
  key1002692: `Change of platform attribute, original value:`,
  key1002693: `Select Site`,
  key1002694: `Category cannot be empty`,
  key1002695: `Product Specification Mapping`,
  key1002696: `Attribute:`,
  key1002697: `Mapped:`,
  key1002698: `To be mapped:`,
  key1002699: `Attribute value:`,
  key1002700: `Attribute value mapped:`,
  key1002701: `Attribute values to be mapped:`,
  key1002702: `Automatic matching`,
  key1002703: `Matching in progress, please wait`,
  key1002704: `Automatic matching completed, matched attributes:`,
  key1002705: `Matched attribute values:`,
  key1002706: `See more optional values`,
  key1002707: `No data`,
  key1002708: `Product Attribute Mapping`,
  key1002709: `关联属性：`,
  key1002710: `Add platform`,
  key1002711: `Platform cannot be empty`,
  key1002712: `Site cannot be null`,
  key1002713: `Platform attributes`,
  key1002714: `Platform Attribute Value Type`,
  key1002715: `Editing Platform`,
  key1002716: `View Platform`,
  key1002717: `(Mapped)`,
  key1002718: `Product Type cannot be empty`,
  key1002719: `FamiltType cannot be empty`,
  key1002720: `Start matching`,
  key1002721: `Matching...`,
  key1002722: `Matching data acquisition completed`,
  key1002723: `Data matching`,
  key1002724: `Interface request error, which may result in incomplete data matching`,
  key1002725: `Name data matching`,
  key1002726: `Platform mapping table`,
  key1002727: `eCommerce platform:`,
  key1002728: `Site name:`,
  key1002729: `Category name:`,
  key1002730: `Custom attribute values`,
  key1002731: `Searching`,
  key1002732: `Enter text to search`,
  key1002733: `Platform label attributes`,
  key1002734: `Mapping attributes`,
  key1002735: `Rules for generating Listing keywords`,
  key1002736: `Random`,
  key1002737: `Not choose`,
  key1002738: `Switching Product Type will clear the list data`,
  key1002739: `Please select Product Type first`,
  key1002740: `Platform attributes cannot be repeated`,
  key1002741: `No platform category found`,
  key1002742: `Price range (CNY):`,
  key1002743: `Comprehensive score:`,
  key1002744: `24-hour collection rate:`,
  key1002745: `48 hour recovery rate:`,
  key1002746: `Search keywords:`,
  key1002747: `rise`,
  key1002748: `Starting batch`,
  key1002749: `minute`,
  key1002750: `Transactions in the past 30 days:`,
  key1002751: `Unlimited`,
  key1002752: `5 stars`,
  key1002753: `4.5-5.0 stars`,
  key1002754: `4-4.5 stars`,
  key1002755: `Below 4 stars`,
  key1002756: `Certified factory`,
  key1002757: `Same day shipment`,
  key1002758: `24-hour shipping`,
  key1002759: `48 hour shipping`,
  key1002760: `7 days without reason`,
  key1002761: `drop shipping`,
  key1002762: `Support free shipping`,
  key1002763: `at price`,
  key1002764: `Monthly sales volume`,
  key1002765: `The price starting range cannot be greater than the price ending range`,
  key1002766: `The Chinese name cannot be empty!`,
  key1002767: `Size chart template name`,
  key1002768: `Add body part`,
  key1002769: `New unit`,
  key1002770: `The Chinese name of the body part in the same column cannot be repeated!`,
  key1002771: `Body part 1`,
  key1002772: `English name`,
  key1002773: `Dutch name`,
  key1002774: `The multilingual names of the body part in the same list must be filled in completely or all blanks!`,
  key1002775: `The unit name cannot be empty!`,
  key1002776: `Successfully added a template!`,
  key1002777: `Successfully updated the template!`,
  key1002778: `Move upward`,
  key1002779: `Move down`,
  key1002780: `You cannot select duplicate unit values!`,
  key1002781: `Property pool management`,
  key1002782: `Please enter the Chinese name of the attribute`,
  key1002783: `Only one of the product specifications can be a size attribute!`,
  key1002784: `Please select attribute pool data first!`,
  key1002785: `Enter keyword search`,
  key1002786: `Category ID:`,
  key1002787: `The parent category has been configured not to be displayed, and this category is not displayed in the mall.`,
  key1002788: `HS code:`,
  key1002789: `Chinese declaration name:`,
  key1002790: `English declaration name:`,
  key1002791: `Size Chart Template:`,
  key1002792: `Add subcategory`,
  key1002793: `Import Mapping`,
  key1002794: `Classification information`,
  key1002795: `Mall display configuration:`,
  key1002796: `Show`,
  key1002797: `Don't show`,
  key1002798: `Platform Information`,
  key1002799: `Customs declaration information`,
  key1002800: `Please confirm`,
  key1002801: `Is this platform available?`,
  key1002802: `Total number of products:`,
  key1002803: `Are you sure to delete this content?`,
  key1002804: `HS code cannot be empty`,
  key1002805: `The Chinese customs declaration name cannot be empty`,
  key1002806: `The English customs declaration name cannot be empty`,
  key1002807: `The size chart template cannot be empty`,
  key1002808: `Platform Name`,
  key1002809: `Site`,
  key1002810: `Product classification`,
  key1002811: `modify`,
  key1002812: `Whether to update the generation rules of other item attributes for Listing keywords to the current generation rules`,
  key1002813: `synchronization`,
  key1002814: `Main attribute settings`,
  key1002815: `Main attribute`,
  key1002816: `Set as primary attribute`,
  key1002817: `Can only drag at the same level`,
  key1002818: `Please set the main attribute of the product specification first!`,
  key1002819: `Do you want to delete the current category?`,
  key1002820: `clothing`,
  key1002821: `Cannot contain - > special characters`,
  key1002822: `the file format is incorrect. only xls and xlsx files can be uploaded.`,
  key1002823: `Please enter the number of prints`,
  key1002824: `Print face sheet`,
  key1002825: `Supplier Name/Supplier Merchant Number`,
  key1002826: `Bulk reject`,
  key1002827: `Supplier Item Number/SPU`,
  key1002828: `Chinese Name`,
  key1002829: `Supplier Merchant No./Name`,
  key1002830: `Approval Status`,
  key1002831: `Please check the data that needs batch review!`,
  key1002832: `Please check the data to be rejected in batches first!`,
  key1002833: `Function setting`,
  key1002834: `Function Description`,
  key1002835: `Whether the product label is displayed in the mall`,
  key1002836: `是否启用商品可售卖国设置`,
  key1002837: `Bill payment confirmation`,
  key1002838: `Payment password:`,
  key1002839: `Please enter the payment password`,
  key1002840: `Check whether the bill is correct, and confirm that the payment will be directly transferred to the supplier's account through Alipay after the payment is made. Please operate carefully.`,
  key1002841: `Time of the bill:`,
  key1002842: `Customer Merchant Number:`,
  key1002843: `Please enter the customer's merchant number`,
  key1002844: `Export customer bills`,
  key1002845: `year`,
  key1002846: `Order amount`,
  key1002847: `Order Currency`,
  key1002848: `Bill number`,
  key1002849: `Billing cycle`,
  key1002850: `Customer merchant number`,
  key1002851: `Monthly cumulative recharge amount`,
  key1002852: `Alipay recharge amount`,
  key1002853: `Manual recharge amount`,
  key1002854: `Accumulated consumption amount`,
  key1002855: `Bill generation time`,
  key1002856: `Billing Information`,
  key1002857: `Merchant`,
  key1002858: `Bill to which it belongs`,
  key1002859: `Recharge serial number`,
  key1002860: `Third party transaction number`,
  key1002861: `Recharge amount`,
  key1002862: `Payment Mode`,
  key1002863: `Recharge success time`,
  key1002864: `Consumption records`,
  key1002865: `Third-party waybill number`,
  key1002866: `Number or item`,
  key1002867: `Logistics weight (g)`,
  key1002868: `Product amount`,
  key1002869: `Logistics fees`,
  key1002870: `Order completion time`,
  key1002871: `Billing dimension`,
  key1002872: `Detail dimension`,
  key1002873: `Logistics expenses are payable by the supplier, and the amount of goods and subsidies are receivables. Please note when calculating`,
  key1002874: `Charge Details`,
  key1002875: `Audit confirmation`,
  key1002876: `Offline payment made`,
  key1002877: `Settlement amount of goods`,
  key1002878: `Subtotal of goods amount`,
  key1002879: `Supplier bill number:`,
  key1002880: `Supplier Name:`,
  key1002881: `Supplier Merchant Number:`,
  key1002882: `Bill generation time:`,
  key1002883: `Bill settlement status:`,
  key1002884: `Bill status update time:`,
  key1002885: `Bill amount:`,
  key1002886: `Amount of goods (payable):`,
  key1002887: `Logistics expenses (receivable):`,
  key1002888: `Subsidy amount (payable):`,
  key1002889: `Billing cycle:`,
  key1002890: `Logistics costs`,
  key1002891: `Bill review confirmation`,
  key1002892: `Has the bill been reviewed and confirmed to be correct? After confirmation, it will become available for payment.`,
  key1002893: `Confirmation of offline payment for bills`,
  key1002894: `Please confirm if offline payment has been made.`,
  key1002895: `Confirm offline payment made`,
  key1002896: `types of fee`,
  key1002897: `Quantity of goods`,
  key1002898: `Accumulated sales of SPU in the past 30 days`,
  key1002899: `Bill of lading number`,
  key1002900: `End packing time`,
  key1002901: `Collection time`,
  key1002902: `Delivery time on Yunmai Network`,
  key1002903: `Payable logistics fees:`,
  key1002904: `Payable Other Expenses:`,
  key1002905: `Logistics actual weight (g):`,
  key1002906: `Unreconciled order quantity:`,
  key1002907: `Logistics provider currency:`,
  key1002908: `Bill Details`,
  key1002909: `Only look at loss making orders`,
  key1002910: `Only view unreconciled orders`,
  key1002911: `Profit and loss (current filtering criteria):`,
  key1002912: `Bill number:`,
  key1002913: `Bill update time:`,
  key1002914: `Customer paid logistics fees:`,
  key1002915: `Other payable expenses:`,
  key1002916: `Total cost:`,
  key1002917: `Document number`,
  key1002918: `Document Status `,
  key1002919: `System calculates logistics weight (g)`,
  key1002920: `Carrier's waybill number`,
  key1002921: `Logistics actual weight (g)`,
  key1002922: `Customer paid logistics fees`,
  key1002923: `Payable logistics fees`,
  key1002924: `Unreconciled`,
  key1002925: `Payable other expenses`,
  key1002926: `Total cost`,
  key1002927: `Profit and loss`,
  key1002928: `Delivery time of package`,
  key1002929: `Reconciliation data source`,
  key1002930: `Import bills`,
  key1002931: `Payable logistics fees cannot be empty`,
  key1002932: `Notes cannot be null.`,
  key1002933: `Bill confirmation settlement`,
  key1002934: `We are currently processing bill settlement and will no longer be able to modify the bill after settlement. Please confirm whether to settle?`,
  key1002935: `Settlement successful!`,
  key1002936: `Currency modification successful!`,
  key1002937: `Distributor account number:`,
  key1002938: `Top-up time:`,
  key1002939: `Recharge status:`,
  key1002940: `Recharge serial number:`,
  key1002941: `Please enter the recharge serial number, which supports multiple separate lines`,
  key1002942: `Export recharge records`,
  key1002943: `Recharge`,
  key1002944: `In recharging`,
  key1002945: `Recharge successfully`,
  key1002946: `Recharge failed`,
  key1002947: `Third party voucher number`,
  key1002948: `Third party vouchers`,
  key1002949: `Pre recharge balance`,
  key1002950: `Balance after recharge`,
  key1002951: `Initialized quota`,
  key1002952: `Remaining balance`,
  key1002953: `Recharge status`,
  key1002954: `Order processing fee:`,
  key1002955: `Order consumables fee:`,
  key1002956: `Additional service fee:`,
  key1002957: `Return processing fee:`,
  key1002958: `Cost Name:`,
  key1002959: `Cost amount:`,
  key1002960: `+Add fees`,
  key1002961: `Number of orders processed per month:`,
  key1002962: `Total amount：`,
  key1002963: `Customer's actual payment amount:`,
  key1002964: `Other expenses:`,
  key1002965: `Cost Name`,
  key1002966: `Total amount`,
  key1002967: `Associated document number`,
  key1002968: `Quantity of goods in the order`,
  key1002969: `Order processing fee`,
  key1002970: `Order consumables fee`,
  key1002971: `Additional service fee`,
  key1002972: `Return processing fee`,
  key1002973: `total`,
  key1002974: `Customer's actual payment amount`,
  key1002975: `Cost incurred time`,
  key1002976: `Modify fees`,
  key1002977: `Document fees`,
  key1002978: `Other expenses`,
  key1002979: `Order processing fee cannot be empty`,
  key1002980: `The expense amount cannot be empty`,
  key1002981: `The expense name cannot be empty`,
  key1002982: `Add fees`,
  key1002983: `Successfully added fees`,
  key1002984: `Cost modification successful`,
  key1002985: `Successfully added notes`,
  key1002986: `Recharge currency:`,
  key1002987: `If the recharge currency is different from the distributor's currency, the system will convert it to the distributor's currency for recharge based on the exchange rate.`,
  key1002988: `Recharge denomination:`,
  key1002989: `Payment method:`,
  key1002990: `Payment voucher number:`,
  key1002991: `Payment voucher picture:`,
  key1002992: `Upload attachments`,
  key1002993: `The currency of the distributor is`,
  key1002994: `Actual receipt`,
  key1002995: `System exchange rate: 1`,
  key1002996: `Cash`,
  key1002997: `Distributor merchant account number cannot be empty`,
  key1002998: `The payment method cannot be empty`,
  key1002999: `Payment voucher number cannot be empty`,
  key1003000: `Recharge currency cannot be empty`,
  key1003001: `Custom recharge denomination cannot be empty!`,
  key1003002: `Please upload payment flow pictures!`,
  key1003003: `Successfully recharged!`,
  key1003004: `Up to five attachments can be uploaded!`,
  key1003005: `Please enter the distributor's merchant account number`,
  key1003006: `(Distributor Currency)`,
  key1003007: `Billing Status:`,
  key1003008: `Searchable billing number/vendor name`,
  key1003009: `Export supplier invoice`,
  key1003010: `Batch payment`,
  key1003011: `Batch confirmation of offline payment`,
  key1003012: `Supplier bill number`,
  key1003013: `Please enter the supplier account number, which supports multiple commas or line breaks for separation`,
  key1003014: `Bill amount`,
  key1003015: `Billing Currency`,
  key1003016: `Number of orders`,
  key1003017: `Bill settlement status`,
  key1003018: `Supplier confirmation time`,
  key1003019: `Audit time`,
  key1003020: `Payment time`,
  key1003021: `Payer`,
  key1003022: `Payment method`,
  key1003023: `Receiving account information`,
  key1003024: `Payment method`,
  key1003025: `Name of account holder`,
  key1003026: `Bank`,
  key1003027: `account`,
  key1003028: `ID number`,
  key1003029: `Bill Details`,
  key1003030: `Supply price (CNY)`,
  key1003031: `Subtotal of goods amount (CNY)`,
  key1003032: `Time of incurring expenses`,
  key1003033: `Waybill type`,
  key1003034: `Order creation time`,
  key1003035: `Central warehouse delivery time`,
  key1003036: `Whether to prepare goods in the central warehouse`,
  key1003037: `General Ledger`,
  key1003038: `Batch payment can only be made if the collection account is Alipay!`,
  key1003039: `Please select a bill first!`,
  key1003040: `Alipay order number`,
  key1003041: `Offline payment`,
  key1003042: `Subsidy rules:`,
  key1003043: `Please select subsidy rules`,
  key1003044: `Logistics`,
  key1003045: `Logistics mode`,
  key1003046: `Subsidy rules`,
  key1003047: `Bill of Lading Weight`,
  key1003048: `Quantity of goods in the bill of lading`,
  key1003049: `On time delivery rate`,
  key1003050: `Warehousing time`,
  key1003051: `Sorting time`,
  key1003052: `Last updated by`,
  key1003053: `Please enter the bill of lading number, which supports multiple commas or line breaks for separation`,
  key1003054: `Please enter the waybill number, which supports multiple commas or line breaks for separation`,
  key1003055: `Please enter the supplier merchant account, which supports multiple commas or line breaks for separation`,
  key1003056: `Only pending orders can be batch approved!`,
  key1003057: `Please check the data that needs to be reviewed first!`,
  key1003058: `Door-to-door collection`,
  key1003059: `Description`,
  key1003060: `Reviewed`,
  key1003061: `Quantity of goods in the bill of lading`,
  key1003062: `Re review`,
  key1003063: `Subsidy Description`,
  key1003064: `Total:`,
  key1003065: `Logistics-Express`,
  key1003066: `Logistics-door-to-door collection`,
  key1003067: `As of`,
  key1003068: `Previous`,
  key1003069: `single`,
  key1003070: `Subsidy based on the number of bills of lading`,
  key1003071: `Subsidy based on the weight of the bill of lading`,
  key1003072: `No subsidy`,
  key1003073: `Shipping method`,
  key1003074: `Order delivery rate`,
  key1003075: `Subsidy recipients`,
  key1003076: `Subsidy type`,
  key1003077: `Subsidy conditions`,
  key1003078: `apply`,
  key1003079: `Actual data`,
  key1003080: `Subsidy calculation`,
  key1003081: `1. Single bill of lading subsidy:`,
  key1003082: `1.0-1 kg subsidy:`,
  key1003083: `2. Subsidy per kilogram of continued weight:`,
  key1003084: `3. Maximum subsidy for a single bill of lading:`,
  key1003085: `Compliance sub rule:`,
  key1003086: `Compliance with rules:`,
  key1003087: `The subsidy is manually adjusted to:`,
  key1003088: `Export warehouse bills`,
  key1003089: `Update billing`,
  key1003090: `Settlement status`,
  key1003091: `In the settlement`,
  key1003092: `Paid`,
  key1003093: `Total amount (CNY)`,
  key1003094: `Order processing volume`,
  key1003095: `Warehouse rent`,
  key1003096: `Bill update time`,
  key1003097: `Monthly order processing volume (CNY)`,
  key1003098: `Order consumables cost (CNY)`,
  key1003099: `Additional fees (CNY)`,
  key1003100: `Return processing fee (CNY)`,
  key1003101: `Warehouse rent (CNY)`,
  key1003102: `Order consumables cost`,
  key1003103: `Additional service fees`,
  key1003104: `Return processing fees`,
  key1003105: `Total amount`,
  key1003106: `The storage fees are being recalculated, please refresh the list later to view!`,
  key1003107: `Please check the data that needs to be updated!`,
  key1003108: `Rule calculation subsidy:`,
  key1003109: `Adjusted subsidy:`,
  key1003110: `Please enter the adjusted subsidy amount`,
  key1003111: `Adjustment instructions:`,
  key1003112: `Please enter adjustment instructions`,
  key1003113: `The adjusted subsidy amount cannot be empty`,
  key1003114: `Adjustment description cannot be empty`,
  key1003115: `Cost status:`,
  key1003116: `types of fee:`,
  key1003117: `Cost incurred time:`,
  key1003118: `Distributor`,
  key1003119: `Document number`,
  key1003120: `Month to which the bill belongs`,
  key1003121: `Cost flow status`,
  key1003122: `Invoice has been generated`,
  key1003123: `No bill generated`,
  key1003124: `Export logistics bills`,
  key1003125: `Import logistics provider bills`,
  key1003126: `Import logistics bills`,
  key1003127: `1. Once the settled billing data is imported, it will no longer be updated and will be displayed as a failure;`,
  key1003128: `2. Support extension:`,
  key1003129: `3. When there is an abnormality in the imported data, the data import fails;`,
  key1003130: `Account number`,
  key1003131: `Number of monthly packages`,
  key1003132: `Bill matching quantity`,
  key1003133: `Please select the subsidy review result:`,
  key1003134: `Reason for failure to pass the audit:`,
  key1003135: `Audit reason cannot be empty`,
  key1003136: `Delete payment password`,
  key1003137: `Please enter the payment password:`,
  key1003138: `New payment password:`,
  key1003139: `Please enter the new password again:`,
  key1003140: `Please re-enter the payment password`,
  key1003141: `Please enter the password again:`,
  key1003142: `Billing payment password`,
  key1003143: `Remove password`,
  key1003144: `After deleting the payment password, it will no longer be necessary to enter the password for online payment confirmation. Please operate with caution.`,
  key1003145: `Not set`,
  key1003146: `Set payment password`,
  key1003147: `The payment password cannot be empty`,
  key1003148: `Replacement successful!`,
  key1003149: `Saved successfully!`,
  key1003150: `The passwords entered twice do not match`,
  key1003151: `Set`,
  key1003152: `Change payment password`,
  key1003153: `Consumption records (only calculate cancelled and shipped parts of orders)`,
  key1003154: `Monthly cumulative recharge:`,
  key1003155: `Monthly Alipay cumulative recharge:`,
  key1003156: `Monthly manual cumulative recharge:`,
  key1003157: `Monthly cumulative consumption:`,
  key1003158: `Third party transaction vouchers`,
  key1003159: `Multiple`,
  key1003160: `SKU line break`,
  key1003161: `Bill of Lading SKU Details`,
  key1003162: `The current SKU in the bill of lading is shown in the following figure, totaling`,
  key1003163: `Species, total`,
  key1003164: `Pieces.`,
  key1003165: `Goods on the shelves`,
  key1003166: `Quantity to be put on shelves`,
  key1003167: `On-shelf quantity`,
  key1003168: `The warehouse name cannot be empty!`,
  key1003169: `The library area name cannot be empty!`,
  key1003170: `The location name cannot be empty!`,
  key1003171: `The quantity on the shelf cannot be empty!`,
  key1003172: `The quantity on the shelf must be an integer greater than 0`,
  key1003173: `The number of shelves cannot be greater than the number of shelves!`,
  key1003174: `The quantity on the shelf must be greater than 0`,
  key1003175: `Scan or enter supplier SKU`,
  key1003176: `Bulk print`,
  key1003177: `In sticker job...`,
  key1003178: `End the sticker job`,
  key1003179: `Quantity to be sticked`,
  key1003180: `Scan successfully!`,
  key1003181: `Please enter the correct supplier SKU!`,
  key1003182: `Supplier SKU cannot be empty!`,
  key1003183: `Please tick the data to be printed first!`,
  key1003184: `The number of prints cannot be empty!`,
  key1003185: `Conventional sorting`,
  key1003186: `Stock sorting`,
  key1003187: `Scan:`,
  key1003188: `It is able to scan outbound order number, waybill number, logistics order number, container number`,
  key1003189: `Weigh:`,
  key1003190: `Please keep the weight unit of the system and the weight unit of the electronic scale consistent.`,
  key1003191: `Actual weighing (`,
  key1003192: `Address`,
  key1003193: `Weigh`,
  key1003194: `Shipping method:`,
  key1003195: `End container sorting`,
  key1003196: `Knot bag`,
  key1003197: `Print appointment delivery note prompt`,
  key1003198: `For online shipment on AliExpress, a pre delivery note is required. Please print the pre delivery note in the logistics handover note after closing the bag.`,
  key1003199: `Not yet bagged`,
  key1003200: `Direct bagging`,
  key1003201: `Booking and bagging`,
  key1003202: `Print collection waybill prompt`,
  key1003203: `AliExpress fully managed JIT orders require printing of collection waybills. If not printed, delivery to AliExpress collection will not be possible.`,
  key1003204: `Printing and bagging`,
  key1003205: `Remove Package Reminder`,
  key1003206: `The package is being removed and can be rescanned for packaging after removal`,
  key1003207: `Still need`,
  key1003208: `Complete`,
  key1003209: `Remove success`,
  key1003210: `gram`,
  key1003211: `Kilogram`,
  key1003212: `The weighing unit is:`,
  key1003213: `Please enter the outbound order number or waybill number!`,
  key1003214: `Weighing must be an integer greater than 0!`,
  key1003215: `The weighing cannot be empty!`,
  key1003216: `Failed to obtain the waybill address!`,
  key1003217: `You still have parcels that have not been sorted. Do you want to end sorting?`,
  key1003218: `Logistic sorting exception reminder`,
  key1003219: `Forced end`,
  key1003220: `Handover person:`,
  key1003221: `Please enter the handover person`,
  key1003222: `Mark out`,
  key1003223: `Handover order number`,
  key1003224: `Parcel qty`,
  key1003225: `Delivery method`,
  key1003226: `Express delivery to pickup point`,
  key1003227: `Logistics distribution method`,
  key1003228: `System distribution`,
  key1003229: `Manual distribution`,
  key1003230: `Domestic logistics tracking number`,
  key1003231: `Handover time`,
  key1003232: `Handover person`,
  key1003233: `Continue packing`,
  key1003234: `Print Pre Delivery Form`,
  key1003235: `Print express waybill`,
  key1003236: `Failed to obtain the express delivery address`,
  key1003237: `Appointment collection`,
  key1003238: `Print collection waybill`,
  key1003239: `Please enter the handover number, which supports multiple commas or line breaks for separation`,
  key1003240: `Please enter the outbound tracking number, which supports multiple commas or line breaks for separation`,
  key1003241: `Please check the data to be marked out of the library first!`,
  key1003242: `Failed to obtain pre delivery waybill address`,
  key1003243: `Appointment successful!`,
  key1003244: `Failed to obtain the collection waybill address`,
  key1003245: `Please scan or enter the bill of lading number/express number/three-way bill of lading number.`,
  key1003246: `Tripartite Bill of Lading No.`,
  key1003247: `Number of outbound orders in box/bag`,
  key1003248: `Warehousing is successful!`,
  key1003249: `Please enter the bill of lading number!`,
  key1003250: `Listing operation:`,
  key1003251: `Scan or enter the container number`,
  key1003252: `Supports multiple container numbers separated by commas or line breaks`,
  key1003253: `Export listing data`,
  key1003254: `Container status:`,
  key1003255: `Container status`,
  key1003256: `Product quantity`,
  key1003257: `Homework start time`,
  key1003258: `Continue to publish`,
  key1003259: `Actual number of listings`,
  key1003260: `Assignment End Time`,
  key1003261: `Job duration`,
  key1003262: `Export by container dimension`,
  key1003263: `Export by SKU dimension`,
  key1003264: `Number of SKUs inside the container`,
  key1003265: `Storage bin for putaway`,
  key1003266: `The bill of lading number cannot be empty!`,
  key1003267: `Product shelving job Reminder`,
  key1003268: `You have unfinished product publishing jobs. Do you want to continue the job?`,
  key1003269: `Sticking homework:`,
  key1003270: `Export sticker data`,
  key1003271: `Sticker job`,
  key1003272: `Continue to stick waybills`,
  key1003273: `Posted`,
  key1003274: `The container number cannot be empty!`,
  key1003275: `Product sticker job reminder`,
  key1003276: `You have an unfinished product sticker job. Do you want to continue the job?`,
  key1003277: `Sorting exception:`,
  key1003278: `Collecting time:`,
  key1003279: `Export Bill of Lading List`,
  key1003280: `Batch marked as collected`,
  key1003281: `Missing package`,
  key1003282: `Logistics trajectory`,
  key1003283: `Quantity of goods receivable`,
  key1003284: `Actual received quantity of accounts receivable`,
  key1003285: `Supplier's quantity of multiple shipments`,
  key1003286: `Abnormal state`,
  key1003287: `Abnormal quantity, please click to view`,
  key1003288: `Shipping Address`,
  key1003289: `Mark received`,
  key1003290: `Cancel`,
  key1003291: `Please enter SKU, supporting multiple commas or line breaks for separation`,
  key1003292: `Please enter the three-way bill of lading number, support multiple commas or line breaks.`,
  key1003293: `Bill of lading number`,
  key1003294: `Supplier Merchant Name`,
  key1003295: `courier number`,
  key1003296: `Shipping method`,
  key1003297: `Export by picking order dimension`,
  key1003298: `Please tick the data to be marked as received first!`,
  key1003299: `Delivery time:`,
  key1003300: `Details`,
  key1003301: `(Cancelled)`,
  key1003302: `Actual weight of package (g)`,
  key1003303: `data sources `,
  key1003304: `Weigher`,
  key1003305: `Removal successful!`,
  key1003306: `Shipping settings`,
  key1003307: `Unit:`,
  key1003308: `Cut off:`,
  key1003309: `The waybill number scanned by some logistics providers will have a prefix or suffix, so the system cannot identify the waybill number. Check to cut off the corresponding prefix or suffix.`,
  key1003310: `Open (required)`,
  key1003311: `Open (not required)`,
  key1003312: `Not open`,
  key1003313: `Before`,
  key1003314: `After`,
  key1003315: `Please scan the TEMU tag number`,
  key1003316: `TEMU tag number`,
  key1003317: `Has it been scanned`,
  key1003318: `Weight (g)`,
  key1003319: `Print packaging labels`,
  key1003320: `Please scan the TEMU tag number!`,
  key1003321: `Scan successful`,
  key1003322: `Please scan all TEMU bags before proceeding with the bag tying operation.`,
  key1003323: `The shipping address was not obtained!`,
  key1003324: `Void Bill of Lading Confirmation`,
  key1003325: `Reason for cancellation:`,
  key1003326: `Please enter a void reason`,
  key1003327: `After voiding the bill of lading, the unshipped order will be transferred to the supplier to be shipped list again.`,
  key1003328: `Confirm void`,
  key1003329: `Canceled successfully!`,
  key1003330: `Scan Record`,
  key1003331: `Revoke Action`,
  key1003332: `The container has ended and cannot be revoked`,
  key1003333: `Sorting container:`,
  key1003334: `Bill of lading number:`,
  key1003335: `Sorting time:`,
  key1003336: `Supplier SKU:`,
  key1003337: `Please scan the bill of lading number first!`,
  key1003338: `Revoke operation prompt`,
  key1003339: `After revocation, the SKU in the corresponding container will be removed. Please operate with caution.`,
  key1003340: `Revoke`,
  key1003341: `There are multiple shipments in the bill of lading,`,
  key1003342: `It will be processed as stock, and the corresponding stock order number has been automatically created, and the type is multiple replenishment order.`,
  key1003343: `Bill of Lading Number:`,
  key1003344: `Third Party Bill of Lading No:`,
  key1003345: `Bill of lading declared quantity and actual arrival quantity`,
  key1003346: `Sales Order:`,
  key1003347: `Stock list:`,
  key1003348: `Quantity of multiple shipments`,
  key1003349: `Actual received quantity`,
  key1003350: `Destination warehouse`,
  key1003351: `Packed`,
  key1003352: `Operation content/message`,
  key1003353: `Please enter the content of the remarks!`,
  key1003354: `Sorting operations:`,
  key1003355: `Scan or enter bill of lading number/express bill of lading number/third party bill of lading number`,
  key1003356: `Export sorting data`,
  key1003357: `Enter the homework interface`,
  key1003358: `Bill of Lading Status:`,
  key1003359: `Bill of Lading Status`,
  key1003360: `Bill of Lading Type`,
  key1003361: `Number of outbound orders`,
  key1003362: `Sorting operation`,
  key1003363: `Number of goods on the bill of lading`,
  key1003364: `Actual sorting quantity`,
  key1003365: `SKU quantity`,
  key1003366: `Warehousing operation time`,
  key1003367: `Sorting start time`,
  key1003368: `Sorting end time`,
  key1003369: `Sorting end operator`,
  key1003370: `Inbound sorting operation reminder`,
  key1003371: `You have unfinished sorting operations. Do you want to continue the operation?`,
  key1003372: `Prompt for abnormal listing`,
  key1003373: `The number of listings cannot exceed the number of listings to be placed`,
  key1003374: `The warehouse area and location are not filled in`,
  key1003375: `Partial listing`,
  key1003376: `End listing`,
  key1003377: `The number of SKU listings is inconsistent with the number of pending listings. The details are as follows. Please confirm whether to end the listing?`,
  key1003378: `Continuing homework`,
  key1003379: `Quantity to be listed`,
  key1003380: `There is an abnormal SKU, please check and try again`,
  key1003381: `Incomplete listing data`,
  key1003382: `Scan or input`,
  key1003383: `SKU/Supplier SKU/Logistics Surface Sheet`,
  key1003384: `Scan or enter the bill of lading number`,
  key1003385: `Please scan or enter the container number`,
  key1003386: `Sorting job...`,
  key1003387: `End sorting operation`,
  key1003388: `End this order sorting`,
  key1003389: `Piece-by-piece`,
  key1003390: `Whether to prompt if the quantity to be shipped is exceeded`,
  key1003391: `Enable voice broadcasting`,
  key1003392: `To be labeled`,
  key1003393: `Please add a container`,
  key1003394: `Container sorting`,
  key1003395: `Find a variety of types of waybills, please choose the right one!`,
  key1003396: `Not to be processed temporarily`,
  key1003397: `Bind Container`,
  key1003398: `End sorting`,
  key1003399: `Already on`,
  key1003400: `Same SKU exists in container, please end`,
  key1003401: `Rescan bill of lading after container`,
  key1003402: `SKU/supplier SKU/logistics list cannot but be empty!`,
  key1003403: `Receivable quantity`,
  key1003404: `Sorting quantity`,
  key1003405: `Sorting still needed`,
  key1003406: `Quantity scanned`,
  key1003407: `Please scan the barcode`,
  key1003408: `Sorted quantity`,
  key1003409: `Unsorted`,
  key1003410: `The bill of lading number cannot be empty!`,
  key1003411: `There is no this product in the bill of lading, please confirm carefully!`,
  key1003412: `This package has been sorted, please do not repeat scanning`,
  key1003413: `Container picking prompt`,
  key1003414: `The picking quantity for this container has reached the maximum limit, and the system will automatically complete the picking of the container. Please bind a new container to continue the operation.`,
  key1003415: `The packaging quantity of this container has reached the maximum limit, and the system will automatically pick the container. Please bind a new container to continue the operation.`,
  key1003416: `Will end the bill of lading and container sorting at the same time, are you sure to end?`,
  key1003417: `Binding succeeded!`,
  key1003418: `Container color repetition prompt`,
  key1003419: `This color container already exists. Please select a different color container.`,
  key1003420: `Set container color prompt`,
  key1003421: `This container has no color. Please select a container with color attributes.`,
  key1003422: `Please scan the bill of lading number first!`,
  key1003423: `Container number cannot be empty!`,
  key1003424: `Please select the type of waybill!`,
  key1003425: `Reminder for SKU exceeding accounts receivable`,
  key1003426: `The SKUs in progress have exceeded the receivable quantity. If there are SKUs exceeding the receivable quantity after sorting is completed, the system will automatically make up the order and send a prompt to the supplier, without the need for manual operation.`,
  key1003427: `This SKU needs to be shelved, and there is currently no bound sticker+shelving container. Please bind sticker+shelving container:`,
  key1003428: `This SKU needs to be put on the shelves, and there is currently no bound container. Please bind the container to be put on the shelves:`,
  key1003429: `Prompt for abnormal SKU quantity`,
  key1003430: `There are differences between the following SKU quantities and the expected quantity. After sorting, the actual overcharged SKU system will automatically make up the order and send a prompt to the supplier.`,
  key1003431: `Domestic courier selection`,
  key1003432: `Ordering method:`,
  key1003433: `Domestic express delivery:`,
  key1003434: `This logistics channel requires express delivery to the pickup point. After packaging, please repackage all packages under this channel, with the outermost layer labeled with a domestic express waybill!`,
  key1003435: `System Ordering`,
  key1003436: `Placing an offline order`,
  key1003437: `Please select domestic express delivery`,
  key1003438: `Please enter cancel`,
  key1003439: `Order reason, this reason is visible to the distributor.`,
  key1003440: `Cancel Order`,
  key1003441: `Order Cancellation Reason:`,
  key1003442: `Confirm cancellation`,
  key1003443: `Order Reason`,
  key1003444: `Canceled successfully!`,
  key1003445: `收款渠道：`,
  key1003446: `收款账户：`,
  key1003447: `新增收款账户`,
  key1003448: `收款渠道`,
  key1003449: `Collection account`,
  key1003450: `补充收款信息`,
  key1003451: `Date Created`,
  key1003452: `启用中`,
  key1003453: `编辑&查看`,
  key1003454: `是否确认删除该信息？`,
  key1003455: `confirm deletion`,
  key1003456: `删除成功!`,
  key1003457: `Total amount of supplier orders:`,
  key1003458: `Total distributor order amount:`,
  key1003459: `Distributor's payable amount:`,
  key1003460: `Supplier's receivable amount:`,
  key1003461: `Save and place an order`,
  key1003462: `The total amount of supplier orders and distributor orders cannot be empty`,
  key1003463: `Save and place order successfully`,
  key1003464: `Stock order status:`,
  key1003465: `Stock order number:`,
  key1003466: `Please enter the stocking order number, which supports multiple line separations`,
  key1003467: `Order date:`,
  key1003468: `Payment time:`,
  key1003469: `Completion time:`,
  key1003470: `Order amount:`,
  key1003471: `Order original amount:`,
  key1003472: `Amount after price adjustment:`,
  key1003473: `Cancelled Amount:`,
  key1003474: `Refund amount:`,
  key1003475: `Distributor:`,
  key1003476: `SUPPLIER SUBorder No:`,
  key1003477: `Shipment date:`,
  key1003478: `Receipt Time:`,
  key1003479: `Amount:`,
  key1003480: `Price adjustment:`,
  key1003481: `Settlement:`,
  key1003482: `Original unit price:`,
  key1003483: `After price adjustment:`,
  key1003484: `Unit price:`,
  key1003485: `Order Quantity:`,
  key1003486: `Quantity received:`,
  key1003487: `Number of shelves:`,
  key1003488: `Number of cancellations:`,
  key1003489: `Number of refunds:`,
  key1003490: `Out of stock quantity:`,
  key1003491: `Expected delivery time:`,
  key1003492: `Order number:`,
  key1003493: `Abnormal state:`,
  key1003494: `Replenishing`,
  key1003495: `Quantity received`,
  key1003496: `Cancel quantity`,
  key1003497: `Refund Quantity`,
  key1003498: `Price to be adjusted`,
  key1003499: `Waiting for suppliers to accept orders`,
  key1003500: `To be paid by the distributor`,
  key1003501: `To be put into storage`,
  key1003502: `Please enter SPU, supporting multiple commas or line breaks for separation`,
  key1003503: `Please enter SKU, supporting multiple commas or line breaks for separation`,
  key1003504: `Please enter the distributor merchant account, which supports multiple commas or line breaks`,
  key1003505: `Please enter the supplier merchant account, which supports multiple commas or line breaks for separation`,
  key1003506: `Query in...`,
  key1003507: `To be resent`,
  key1003508: `Partial arrival`,
  key1003509: `Completed`,
  key1003510: `Order confirmation`,
  key1003511: `We are currently placing an order. Please confirm if you want to place an order?`,
  key1003512: `Confirm order placement`,
  key1003513: `Order placed successfully!`,
  key1003514: `Main information`,
  key1003515: `Consignee information`,
  key1003516: `logistics provider information`,
  key1003517: `Order details:`,
  key1003518: `Order SKU`,
  key1003519: `packaging information `,
  key1003520: `Shipped（`,
  key1003521: `Row no.`,
  key1003522: `Supplier order number`,
  key1003523: `Out of stock status`,
  key1003524: `Out of stock, please check details`,
  key1003525: `Order status update time`,
  key1003526: `Void or not`,
  key1003527: `unclosed`,
  key1003528: `Details`,
  key1003529: `Chinese Declaration Name`,
  key1003530: `English Declaration Name`,
  key1003531: `Customs value`,
  key1003532: `Customs weight`,
  key1003533: `Declaration Amount`,
  key1003534: `HS Code`,
  key1003535: `Item link`,
  key1003536: `Customs information`,
  key1003537: `Log content description`,
  key1003538: `Waybill type:`,
  key1003539: `Distributor order number:`,
  key1003540: `Warehouse for shipment:`,
  key1003541: `Consignee name:`,
  key1003542: `Country:`,
  key1003543: `Customer ID:`,
  key1003544: `Address 1:`,
  key1003545: `Address 2:`,
  key1003546: `Logistics order no.:`,
  key1003547: `Shipping method:`,
  key1003548: `Waybill No.1:`,
  key1003549: `Waybll No.2:`,
  key1003550: `Logistics provider weighing (g):`,
  key1003551: `Freight from logistics provider:`,
  key1003552: `Temu Tag Number`,
  key1003553: `SKU * Quantity`,
  key1003554: `Package weight (g)`,
  key1003555: `There is currently no packaging label available`,
  key1003556: `Allocated inventory`,
  key1003557: `Stocked`,
  key1003558: `Please fill in the new row fully.`,
  key1003559: `Please add declaration information first!`,
  key1003560: `The current small package has not undergone logistics sorting and cannot print the box label`,
  key1003561: `SKU Information`,
  key1003562: `Outbound order type:`,
  key1003563: `Arrival time:`,
  key1003564: `All SKUs`,
  key1003565: `Abnormal quantity SKU upon arrival`,
  key1003566: `Initiate stock quantity`,
  key1003567: `Actual Quantity Received`,
  key1003568: `Change shipping method`,
  key1003569: `Please select the shipping method after change:`,
  key1003570: `Account:`,
  key1003571: `Modify failed data`,
  key1003572: `Logistics dynamic parameters`,
  key1003573: `Confirm`,
  key1003574: `previous logistics provider`,
  key1003575: `Original shipping method`,
  key1003576: `Destination`,
  key1003577: `Reason`,
  key1003578: `No data selected`,
  key1003579: `Please choose a logistics channel`,
  key1003580: `Account number cannot be null.`,
  key1003581: `SKU, supports multiple commas or line breaks`,
  key1003582: `Order Status:`,
  key1003583: `Order Tag:`,
  key1003584: `Distribution status:`,
  key1003585: `Shipping method:`,
  key1003586: `Order type:`,
  key1003587: `Source:`,
  key1003588: `Performing Party:`,
  key1003589: `是云卖物流或来源为自有渠道的数据，才能修改物流方式！`,
  key1003590: `Issue logistics`,
  key1003591: `Order number, supports multiple commas or line breaks`,
  key1003592: `Status update time:`,
  key1003593: `Order creation time:`,
  key1003594: `Order delivery time:`,
  key1003595: `Order cancellation time:`,
  key1003596: `Supplier Sub-Doc`,
  key1003597: `The number of the products:`,
  key1003598: `Order information`,
  key1003599: `Order SKU Information`,
  key1003600: `Order delivery time`,
  key1003601: `Delivery time`,
  key1003602: `Please enter the distributor order number, which supports multiple commas or line breaks for separation`,
  key1003603: `Please enter the supplier order number, which supports multiple commas or line breaks for separation`,
  key1003604: `Please enter the waybill number, which supports multiple commas or line breaks for separation`,
  key1003605: `Please enter the Temu tag number, which supports multiple commas or line breaks for separation`,
  key1003606: `Logistics tracking number`,
  key1003607: `Please enter the third-party logistics tracking number, which supports multiple commas or line breaks for separation`,
  key1003608: `(To be executed for shipment)`,
  key1003609: `To be issued`,
  key1003610: `Under distribution`,
  key1003611: `Successful distribution`,
  key1003612: `Distribution failed`,
  key1003613: `Domestic express delivery fee:`,
  key1003614: `Amount of goods:`,
  key1003615: `Logistics fees:`,
  key1003616: `Processing fee:`,
  key1003617: `Parcels that need to be sent by courier to the collection point will be subject to an additional domestic courier fee.`,
  key1003618: `Customs clearance fee:`,
  key1003619: `Update carrier`,
  key1003620: `Add Carrier`,
  key1003621: `logistics tripartite single number:`,
  key1003622: `domestic express single number:`,
  key1003623: `Shipping Information `,
  key1003624: `Customer logistics:`,
  key1003625: `Supplier Sub-Doc Information`,
  key1003626: `Partial replenishment in progress`,
  key1003627: `Partially out of sale`,
  key1003628: `View Sublist`,
  key1003629: `Product information`,
  key1003630: `Notify distributors`,
  key1003631: `Allow Cancel`,
  key1003632: `Own channel`,
  key1003633: `Third-party channels`,
  key1003634: `Cost of goods (CNY)`,
  key1003635: `Total cost (CNY)`,
  key1003636: `Processing fee (CNY)`,
  key1003637: `Logistics fee (CNY)`,
  key1003638: `Logistics name`,
  key1003639: `Waybill No.`,
  key1003640: `Waybill number 1`,
  key1003641: `Waybill number 2`,
  key1003642: `Supplier delivery time`,
  key1003643: `Bill of lading collection time`,
  key1003644: `Order cancellation time`,
  key1003645: `Reason for order cancellation`,
  key1003646: `Order Type`,
  key1003647: `Order Label`,
  key1003648: `Platform order information`,
  key1003649: `Platform Site`,
  key1003650: `Store Name`,
  key1003651: `Platform order number`,
  key1003652: `Platform order amount`,
  key1003653: `Platform order status`,
  key1003654: `Platform order SKU information`,
  key1003655: `Order date`,
  key1003656: `Payment date`,
  key1003657: `Order dimension`,
  key1003658: `SKU dimension`,
  key1003659: `(Column)`,
  key1003660: `(Line)`,
  key1003661: `When displaying by row, general information will be merged, please note when making statistics.`,
  key1003662: `Status update time`,
  key1003663: `By update time`,
  key1003664: `Supplier warehouse straight hair`,
  key1003665: `Distribution warehouse`,
  key1003666: `Distribution warehouse (unlabeled)`,
  key1003667: `A maximum of 1000 items can be queried at a time. Please adjust and try again.`,
  key1003668: `Update completed!`,
  key1003669: `请选择已付款且下发状态为待下发或下发失败的订单进行下发物流！`,
  key1003670: `Please tick the data to be distributed first!`,
  key1003671: `Please tick the data to be modified first!`,
  key1003672: `Please check the data to be labeled first!`,
  key1003673: `Delete Tag Confirmation`,
  key1003674: `Deleting order label. Please confirm whether to delete?`,
  key1003675: `Packing status allows cancellation of order confirmation`,
  key1003676: `Cancellation processing fee:`,
  key1003677: `This order has been packed, please be sure to confirm whether the site has not been issued, after the cancellation of the need to promptly contact the relevant personnel to take out the package.`,
  key1003678: `Allow Order Cancellation`,
  key1003679: `Cancellation processing fee cannot be empty`,
  key1003680: `Supplier order number:`,
  key1003681: `Outbound order status:`,
  key1003682: `Quantity of goods:`,
  key1003683: `Out of stock information`,
  key1003684: `Please select the status of the backup order`,
  key1003685: `Supplier delivery order status:`,
  key1003686: `Please select the status of the outbound order`,
  key1003687: `Supplier delivery order type:`,
  key1003688: `Please select the type of supplier's outbound order`,
  key1003689: `Export Order`,
  key1003690: `Received`,
  key1003691: `Sku, supports multiple commas or line breaks`,
  key1003692: `Stock order number`,
  key1003693: `Please enter the stock order number, which supports multiple commas or line breaks for separation`,
  key1003694: `Stock order status`,
  key1003695: `Supplier Delivery Order Status`,
  key1003696: `Supplier delivery order type`,
  key1003697: `Shipment date`,
  key1003698: `Cancel Time`,
  key1003699: `Please select order status.`,
  key1003700: `Settlement status:`,
  key1003701: `Please select settlement status`,
  key1003702: `Time out:`,
  key1003703: `The actual received quantity in the warehouse is insufficient. Please check the details in the shipping record`,
  key1003704: `It has been invalidated and needs to be reshipped.`,
  key1003705: `Remaining delivery time`,
  key1003706: `Timed out`,
  key1003707: `Barcode:`,
  key1003708: `Received quantity:`,
  key1003709: `Timeout time`,
  key1003710: `End Time`,
  key1003711: `End time refers to the node when all the order processes have ended. The time at this node will be included in the corresponding accounting period for the order.`,
  key1003712: `Mark arrival`,
  key1003713: `Update tags`,
  key1003714: `Timed out but not sent`,
  key1003715: `Please enter the order number, which supports multiple commas or line breaks for separation`,
  key1003716: `Please enter the supplier SKU, which supports multiple commas or line breaks for separation`,
  key1003717: `Please enter the supplier name, supporting multiple commas or line breaks for separation`,
  key1003718: `Supplier Merchant Number`,
  key1003719: `total amount of the order`,
  key1003720: `Sub-Doc Type`,
  key1003721: `Not recorded`,
  key1003722: `The start duration of the timeout cannot be greater than the end duration`,
  key1003723: `Failed to obtain marked out of stock details data!`,
  key1003724: `Timed out`,
  key1003725: `second`,
  key1003726: `请输入收款渠道`,
  key1003727: `请输入收款账户`,
  key1003728: `收款币种：`,
  key1003729: `补充收款信息：`,
  key1003730: `可根据收款渠道，在“补充收款信息”处，补充输入使用该渠道进行支付时所需的必要信息;`,
  key1003731: `收款渠道不能为空`,
  key1003732: `收款账户不能为空`,
  key1003733: `收款币种不能为空`,
  key1003734: `编辑&查看收款账户`,
  key1003735: `请输入业务单号,支持多个按行分隔`,
  key1003736: `支付批次号：`,
  key1003737: `请输入支付批次号,支持多个按行分隔`,
  key1003738: `业务单号：`,
  key1003739: `支付渠道：`,
  key1003740: `运营收款账户：`,
  key1003741: `对账状态：`,
  key1003742: ` 日志信息`,
  key1003743: `批量对账`,
  key1003744: `支付批次号`,
  key1003745: `订单应付总额`,
  key1003746: `支付渠道`,
  key1003747: `运营收款账户`,
  key1003748: `分销商户号`,
  key1003749: `业务单号`,
  key1003750: `业务金额`,
  key1003751: `Payment voucher picture`,
  key1003752: `对账状态`,
  key1003753: `对账`,
  key1003754: `待对账`,
  key1003755: `对账通过`,
  key1003756: `对账退回`,
  key1003757: `Please check the data to be operated first!`,
  key1003758: `请选择待对账的支付数据进行对账`,
  key1003759: `Add Remarks:`,
  key1003760: `订单金额修改`,
  key1003761: `The total amount of the order:`,
  key1003762: `货品金额修改`,
  key1003763: `是否允许开启分销商线上余额充值入口`,
  key1003764: `是否开启分销商订单”线下支付"付款方式`,
  key1003765: `至少需要开启一个功能入口`,
  key1003766: `Modify waybill number`,
  key1003767: `Please enter the waybill number`,
  key1003768: `Please enter the logistics tracking number`,
  key1003769: `Please choose logistics mode`,
  key1003770: `Waybill No. cannot be empty`,
  key1003771: `请输入退回原因`,
  key1003772: `条信息`,
  key1003773: `对账结果：`,
  key1003774: `退回原因：`,
  key1003775: `对账退回表示运营方未收到此订单款项或实收与应收金额不符，需分销商重新进行支付，审核通过后，运营方才会对此订单进行履约。`,
  key1003776: `对账通过表示运营方已收到此订单款项，该订单会由运营方进行后续履约。`,
  key1003777: `订单支付对账`,
  key1003778: `SKUs, separated by commas or newlines`,
  key1003779: `SPUs, separated by commas or newlines`,
  key1003780: `Multiple supplier merchant accounts separated by commas or line breaks`,
  key1003781: `Storage bin:`,
  key1003782: `Please enter storage bin code.`,
  key1003783: `Shipper:`,
  key1003784: `Please enter the merchant number`,
  key1003785: `Total Inventory:`,
  key1003786: `Export inventory surplus`,
  key1003787: `Export Inventory Details`,
  key1003788: `Export inventory balance data`,
  key1003789: `Export inventory detail data`,
  key1003790: `1. After importing inventory, it will overwrite the original inventory. Please be cautious when importing;`,
  key1003791: `Import products`,
  key1003792: `SKU`,
  key1003793: `Specification attribute values`,
  key1003794: `Owner role`,
  key1003795: `Merchant ID of the shipper`,
  key1003796: `By product`,
  key1003797: `Search by SKU / storage bin`,
  key1003798: `The available quantity must be a positive integer greater than or equal to 0`,
  key1003799: `Available inventory start interval cannot be greater than available inventory end interval`,
  key1003800: `Details`,
  key1003801: `Inventory Details`,
  key1003802: `Display data with inventory 0`,
  key1003803: `Conventional packaging`,
  key1003804: `Stock packaging`,
  key1003805: `Packager:`,
  key1003806: `Please enter the picking list number, which supports multiple line separation`,
  key1003807: `Export multiple item sorting`,
  key1003808: `Scan picking list number/container number:`,
  key1003809: `You have unfinished packaging tasks, picking order number:`,
  key1003810: `Do you want to continue with the assignment?`,
  key1003811: `Order number`,
  key1003812: `Picking order type`,
  key1003813: `Number of outbound orders`,
  key1003814: `Picking/sorting completion time`,
  key1003815: `Picker/Sorter`,
  key1003816: `Packager`,
  key1003817: `Start homework`,
  key1003818: `Operation operator`,
  key1003819: `Start homework time`,
  key1003820: `End homework time`,
  key1003821: `Reminder for regular packaging operations`,
  key1003822: `Reminder for stocking and packaging operations`,
  key1003823: `Processing`,
  key1003824: `Please enter the pick order number!`,
  key1003825: `The input sequence of the list of goods when the single-item picking list is printed:`,
  key1003826: `The input order of the product list when printing the multi-product pick list:`,
  key1003827: `Collection timeout:`,
  key1003828: `Print the address sheet when sorting:`,
  key1003829: `Pick order Setup`,
  key1003830: `Collection timeout setting`,
  key1003831: `Packaging job settings`,
  key1003832: `Conventional sorting rack settings (only available when the automatic sorting function for multiple items and packages is enabled)`,
  key1003833: `Enable left-side sorting rack`,
  key1003834: `Shelf matrix`,
  key1003835: `row`,
  key1003836: `column`,
  key1003837: `Enabling intermediate sorting shelves`,
  key1003838: `Enable right-hand sorting rack`,
  key1003839: `Preparation and sorting rack settings (only available when the automatic sorting function for multiple items and packages is enabled)`,
  key1003840: `Save the setting of see goods issuance`,
  key1003841: `sort by storage bin in ascending order`,
  key1003842: `Only in descending order by product location`,
  key1003843: `Please select at least one regular picking rack!`,
  key1003844: `Please select at least one stocking and picking shelf!`,
  key1003845: `Sorter:`,
  key1003846: `You have unfinished sorting tasks, picking list number:`,
  key1003847: `Sorter`,
  key1003848: `Scan/enter the picking list number to start multiple item sorting:`,
  key1003849: `Reminder for regular sorting operations`,
  key1003850: `Reminder for stocking and sorting operations`,
  key1003851: `The weight of the package cannot be empty`,
  key1003852: `Small knot bag`,
  key1003853: `Package weight (g):`,
  key1003854: `The current SKU in the small package is shown in the following figure, totaling`,
  key1003855: `After confirming the quantity, please weigh it first and then perform the bagging operation.`,
  key1003856: `Printing labels and bagging`,
  key1003857: `To be inventory`,
  key1003858: `processing`,
  key1003859: `Inventory order:`,
  key1003860: `Warehouse:`,
  key1003861: `Picking reminder:`,
  key1003862: `Inventory order status:`,
  key1003863: `Dynamic inventory:`,
  key1003864: `Random inventory:`,
  key1003865: `Inventory operator:`,
  key1003866: `Inventory`,
  key1003867: `Counting quantity`,
  key1003868: `Gains and losses`,
  key1003869: `Success`,
  key1003870: `Picking orders`,
  key1003871: `Picking order no.`,
  key1003872: `Picking order generation rules:`,
  key1003873: `Go to the list of picking order`,
  key1003874: `The maximum number of packages per stocking and picking list cannot be empty!`,
  key1003875: `The maximum number of packages per regular picking list cannot be empty!`,
  key1003876: `Picking order`,
  key1003877: `商品标签选择`,
  key1003878: `Picking order no.:{pickingGoodsNo} After sorting, click【End the sorting】End this assignment`,
  key1003879: `Products you scan{sku}，The packages in the current picking list are not required or the required quantity has been met。`,
  key1003880: `Pick list of current sorting operation:`,
  key1003881: `End this sorting work`,
  key1003882: `SKU/UPC`,
  key1003883: `All sorting completed`,
  key1003884: `Product abnormal warning`,
  key1003885: `Possible reasons:`,
  key1003886: `1. You picked the products by mistake or picked too many products.`,
  key1003887: `2. The barcode of the product is pasted incorrectly.`,
  key1003888: `Please send the item to the exception handling department。`,
  key1003889: `Continue to scan other items (Enter)`,
  key1003890: `Pick list information:`,
  key1003891: `Outbound order information:`,
  key1003892: `Reset the package to not start sorting`,
  key1003893: `Remove the package from the current picking order and wait for a new picking order`,
  key1003894: `Print Temu Middle Label`,
  key1003895: `请选择商品标签：`,
  key1003896: `See the manifest`,
  key1003897: `Sorting list`,
  key1003898: `Temu Shipping Order`,
  key1003899: `SKU/Name/Variation`,
  key1003900: `Total quantity of items`,
  key1003901: `Number of items sorted`,
  key1003902: `package`,
  key1003903: `All outbound orders under the picking list have been changed`,
  key1003904: `Please scan the correct SKU/UPC`,
  key1003905: `No picking basket available`,
  key1003906: `The current product has no label address`,
  key1003907: `Multi item stocking sorting - end sorting`,
  key1003908: `Prompt for cancellation of picking list`,
  key1003909: `There is no outbound order in the picking list, and the picking list has been cancelled. Clicking the button will automatically end this operation.`,
  key1003910: `Failed to print`,
  key1003911: `Printing label successfully!`,
  key1003912: `Completed`,
  key1003913: `The quantity that should be shipped`,
  key1003914: `Container quantity of goods`,
  key1003915: `Quantity to be sorted`,
  key1003916: `print label`,
  key1003917: `Successfully printed Temu middle label!`,
  key1003918: `Failed to obtain Temu intermediate label address!`,
  key1003919: `Unable to obtain the address of the Temu middle label face sheet`,
  key1003920: `Picking review-end operation`,
  key1003921: `You are about to end the picking review of the pick list {pickingGoodsNo}`,
  key1003922: `A total of {scannedCount} people participated in this picking review`,
  key1003923: `Total packaging time {scannedTime} minutes`,
  key1003924: `Total (person.time) {userScannedTime} minutes`,
  key1003925: `You are about to end the picking review of the pick list {pickingGoodsNo}, but the system detects the following exception`,
  key1003926: `The following package have been scanned but the address label is being printed`,
  key1003927: `Save as Excel`,
  key1003928: `Manually confirm whether the address label has been printed normally.`,
  key1003929: `If you mark it as printed, the parcels will be deemed to have been packed normally when the picking review is mandatorily completed, and no new pick list can be generated for the parcels.`,
  key1003930: `If you mark as unprinted, these packages will be deemed unprinted when the picking review is mandatorily ended, and the system will remove these packages from the pick list, and you can regenerate a new pick list.`,
  key1003931: `The following packages have not been printed or the package was invalidated before printing.`,
  key1003932: `Unprinted packages will be removed from the pick list when the picking review is forced to end, and a new pick list will be generated again.`,
  key1003933: `If the package is voided during the packing of the picking order, there may be a surplus of the physical item.`,
  key1003934: `Under normal circumstances, the total quantity of this item should be equal the actual quantity your picked.`,
  key1003935: `The following packages are void after printing.`,
  key1003936: `After the address label is printed, the package is voided，It will cause the package to be packed normally. It is recommended to intercept it immediately，And in the[Recycle package]For confirmation。`,
  key1003937: `End current job`,
  key1003938: `Return to continue packaging`,
  key1003939: `Scanned quantity`,
  key1003940: `Unpicked quantity`,
  key1003941: `Packaged packages number`,
  key1003942: `Packaged items number`,
  key1003943: `Statistics project`,
  key1003944: `Picking order number`,
  key1003945: `Actual completion number`,
  key1003946: `Operator`,
  key1003947: `Total number of packages`,
  key1003948: `Working time (minutes)`,
  key1003949: `Packages (per minute)`,
  key1003950: `Items (per minute)`,
  key1003951: `Addressee`,
  key1003952: `Scanning time`,
  key1003953: `Printed`,
  key1003954: `unPrinted`,
  key1003955: `Subtotal of items`,
  key1003956: `Scan SKU`,
  key1003957: `Load package information`,
  key1003958: `Checking quantity`,
  key1003959: `Send to printer`,
  key1003960: `Mark printed`,
  key1003961: `SKU error`,
  key1003962: `The SKU [ {warnSku} ] that you scanned or entered was not found in the current picking order`,
  key1003963: `Not matched to specified`,
  key1003964: `Current picking review pick list:`,
  key1003965: `Current operator:`,
  key1003966: `Exit current job`,
  key1003967: `Automatically match packages:`,
  key1003968: `Clear scanned items and rescan`,
  key1003969: `Outbound order for picking order`,
  key1003970: `You need to process the currently unprinted package first.`,
  key1003971: `As a result of the scan you submitted, the package information could not be found in the current pick list. Possible reasons:`,
  key1003972: `*There is an error in the products of the current package.`,
  key1003973: `*There are omissions or duplications in your scanning process.`,
  key1003974: `The picking order does not exist or the status has changed. Please refresh and try again.`,
  key1003975: `The outbound order scanned by the picking review does not exist`,
  key1003976: `Picking review and scanning outbound order is invalid`,
  key1003977: `SKU does not match the appropriate outbound order.`,
  key1003978: `Picking review is over`,
  key1003979: `There are delivery orders with incomplete packaging`,
  key1003980: `waiting for operation`,
  key1003981: `Carrier / shipping method`,
  key1003982: `SKU & quantity`,
  key1003983: `Print product labels`,
  key1003984: `Failed to obtain barcode for fully managed goods on AliExpress!`,
  key1003985: `Name/Specification`,
  key1003986: `Check`,
  key1003987: `The current SKU corresponding order is a AliExpress fully managed JIT order, and the corresponding product barcode needs to be printed. Please note that the paper size needs to be 60mm * 30mm.`,
  key1003988: `Print successfully`,
  key1003989: `Print Temu barcode`,
  key1003990: `Current outbound order:`,
  key1003991: `Quantity of goods loaded:`,
  key1003992: `Complete the entire order`,
  key1003993: `Sorting containers and corresponding outbound orders`,
  key1003994: `Name/Specification`,
  key1003995: `Scanned quantity cannot be empty!`,
  key1003996: `Scanned/Unscanned`,
  key1003997: `Already Screened`,
  key1003998: `Unscanned`,
  key1003999: `Print Temu labels`,
  key1004000: `(Partial packaging)`,
  key1004001: `(Packaged)`,
  key1004002: `(Completed)`,
  key1004003: `Exceeding the quantity on the picking list, please check the actual quantity of goods!`,
  key1004004: `SKU is not a required item for this order. Please confirm the order to which the item belongs!`,
  key1004005: `SKU cannot be empty!`,
  key1004006: `There are unfinished delivery orders. Please mark them as completed before ending the operation.`,
  key1004007: `All goods have been scanned, please end the assignment`,
  key1004008: `Exceeding the quantity on the picking list, please check the actual quantity of goods`,
  key1004009: `This delivery order has been marked as completed, please do not repeat the marking!`,
  key1004010: `After the entire order editing is completed, no changes can be made. Please confirm that there are no errors before marking.`,
  key1004011: `Mark completion`,
  key1004012: `Abnormal completion prompt`,
  key1004013: `At the end of the current outbound order, the packaging operation will be carried out. After the marking is completed, the status will be transferred to the picking list to be generated. Please confirm that there are no errors before marking.`,
  key1004014: `Please mark the completion of the entire order after completing the small package.`,
  key1004015: `You are about to end the picking list {{pickingGoodsNo}} But the system detected the following exception`,
  key1004016: `The following packages have not been sorted`,
  key1004017: `1.The product quantity are less than the quantity required by the pick list.`,
  key1004018: `2. In the previous sorting process, too many products were placed in a pick basket.`,
  key1004019: `3.The remaining products in the awaiting sorting area that have not been scanned.`,
  key1004020: `Under the condition that the quantity of goods to be picked is accurate, the quantity of the goods to be picked should be consistent with the quantity of the remaining goods in the waiting area。`,
  key1004021: `If you feel that there are too many mistakes to trace back，You can put all the items in the picking box back to the waiting area，`,
  key1004022: `and`,
  key1004023: `Cancel the sorting`,
  key1004024: `Picking list can start sorting operation again。`,
  key1004025: `Return to continue sorting`,
  key1004026: `Picking box`,
  key1004027: `Unassigned`,
  key1004028: `Goods (picked/to be picked)`,
  key1004029: `Error reason`,
  key1004030: `Have not started sorting yet`,
  key1004031: `Sorting not completed`,
  key1004032: `Picked`,
  key1004033: `Unpicked`,
  key1004034: `Prompt for abnormal quantity of goods`,
  key1004035: `In this assignment, the following SKUs have not been scanned yet. Please double check the quantity of goods. If there is any loss of goods, please contact the management personnel in a timely manner.`,
  key1004036: `Return to Job`,
  key1004037: `Quantity to be scanned`,
  key1004038: `Actual scanning quantity`,
  key1004039: `Print product barcode`,
  key1004040: `The quantity of items that should be shipped`,
  key1004041: `The quantity of items in Picking box`,
  key1004042: `Failed to obtain printing label address!`,
  key1004043: `Reset successful!`,
  key1004044: `Multi item conventional sorting - end sorting`,
  key1004045: `Failed to obtain product barcode!`,
  key1004046: `(Temu sample order)`,
  key1004047: `(Temu goods order)`,
  key1004048: `No picking order details exist`,
  key1004049: `Print SKU`,
  key1004050: `Delete`,
  key1004051: `Do not close automatically`,
  key1004052: `Download print control`,
  key1004053: `The barcode was not generated successfully. Please click again to print.`,
  key1004054: `Show more`,
  key1004055: `Longer than input limit`,
  key1004056: `Add notes successfully!`,
  key1004057: `Failed to add notes!`,
  key1004058: `Failed to add`,
  key1004059: `SKU, support for multiple row-separated`,
  key1004060: `Export outbound details`,
  key1004061: `Order number, support multiple separated by line`,
  key1004062: `Please enter the distributor merchant account, which supports multiple separate lines`,
  key1004063: `Please enter the distributor order number, which supports multiple separate lines`,
  key1004064: `Please enter the waybill number, which supports multiple separate lines`,
  key1004065: `Outbound person`,
  key1004066: `Generate Picker`,
  key1004067: `Logistics Sorter`,
  key1004068: `Shipment quantity (pieces)`,
  key1004069: `Allocate inventory time`,
  key1004070: `Generate picking list time`,
  key1004071: `Completion of picking time`,
  key1004072: `Sort completion time`,
  key1004073: `Packaging completion time`,
  key1004074: `Time to complete logistics sorting`,
  key1004075: `Outbound time`,
  key1004076: `Logistics carrier waybill number`,
  key1004077: `Quantity of shipments`,
  key1004078: `Operator`,
  key1004079: `Activity status:`,
  key1004080: `Activity Name:`,
  key1004081: `Please enter the activity name keyword`,
  key1004082: `New Activity`,
  key1004083: `Not Started`,
  key1004084: `In progress`,
  key1004085: `finished`,
  key1004086: `Active picture`,
  key1004087: `Activity Name`,
  key1004088: `Activity status`,
  key1004089: `Activity start time`,
  key1004090: `Event End Time`,
  key1004091: `Long term effectiveness`,
  key1004092: `Start Now`,
  key1004093: `Start activity confirmation immediately`,
  key1004094: `After confirmation, the activity will immediately start and be displayed on the mall. Please confirm whether to start?`,
  key1004095: `End Now`,
  key1004096: `Immediately End Activity Confirmation`,
  key1004097: `After confirmation, the activity will immediately end. Please confirm if it ends?`,
  key1004098: `Please enter the entrance name`,
  key1004099: `Image:`,
  key1004100: `Click to jump to:`,
  key1004101: `replace`,
  key1004102: `Renderings`,
  key1004103: `Jingang District`,
  key1004104: `Upload images`,
  key1004105: `(Recommended size: 60 * 60)`,
  key1004106: `Not filled in`,
  key1004107: `Effect Preview`,
  key1004108: `entrance`,
  key1004109: `The image of Jingang District is empty. Please upload and try again.`,
  key1004110: `Name cannot be empty!`,
  key1004111: `Please upload first`,
  key1004112: `Picture of!`,
  key1004113: `The click to redirect address cannot be empty!`,
  key1004114: `Image successful`,
  key1004115: `Image failed`,
  key1004116: `Click to jump`,
  key1004117: `Jump Target Type:`,
  key1004118: `Jump target:`,
  key1004119: `activity`,
  key1004120: `Specify Link`,
  key1004121: `Do not jump`,
  key1004122: `Jump target type cannot be empty`,
  key1004123: `The specified link address must be a link starting with https://or http://!`,
  key1004124: `Jump target cannot be empty!`,
  key1004125: `Please enter a site name`,
  key1004126: `Site domain name:`,
  key1004127: `Please enter a site domain name`,
  key1004128: `Site Description:`,
  key1004129: `Please enter a site description`,
  key1004130: `Site Name`,
  key1004131: `Site Domain Name`,
  key1004132: `Site Description`,
  key1004133: `View site information`,
  key1004134: `Edit Site Information`,
  key1004135: `Banner image:`,
  key1004136: `Homepage rotation chart`,
  key1004137: `Top advertising space`,
  key1004138: `Side advertising space`,
  key1004139: `Home page - advertising space below personal information`,
  key1004140: `Change Enable Status Prompt`,
  key1004141: `This operation will change whether the advertising space is visible on the mall end. Please confirm whether to continue with the change.`,
  key1004142: `(Recommended size: 70 * 228)`,
  key1004143: `The advertising image is empty. Please upload and try again.`,
  key1004144: `Clicking on the redirect address cannot be empty!`,
  key1004145: `(Recommended height: 80px)`,
  key1004146: `The banner image is empty. Please upload and try again.`,
  key1004147: `(Recommended size: 228 * 70)`,
  key1004148: `Unified pricing（`,
  key1004149: `Add as homepage tab:`,
  key1004150: `Is supplier information displayed`,
  key1004151: `Event start time:`,
  key1004152: `Select activity start time`,
  key1004153: `Event End Time:`,
  key1004154: `Select activity end time`,
  key1004155: `Is the price set uniformly`,
  key1004156: `Configure matching rules`,
  key1004157: `Matching rules:`,
  key1004158: `New Days:`,
  key1004159: `Remove Product Confirmation`,
  key1004160: `Event Information`,
  key1004161: `(Recommended size: 1200 * 160)`,
  key1004162: `Event Pricing`,
  key1004163: `Activity products`,
  key1004164: `Designated product`,
  key1004165: `Rule matching`,
  key1004166: `Configuration Rules`,
  key1004167: `Batch removal`,
  key1004168: `Rule description:`,
  key1004169: `1. The system will automatically match products that meet a certain number of new days. For example, if you fill in 7 days, the system will match products that are within 7 days of today according to the approved time and add them to the activity.`,
  key1004170: `After removal, the product will no longer participate in this event, and the price may change. The system will remind the subscribed distributors.`,
  key1004171: `Remove products`,
  key1004172: `The start time of the activity cannot be empty`,
  key1004173: `Match by new days`,
  key1004174: `Matching rule cannot be empty`,
  key1004175: `The number of new days cannot be empty`,
  key1004176: `Slogan:`,
  key1004177: `Product activities`,
  key1004178: `The end time of the activity cannot be empty`,
  key1004179: `Please check the products participating in the activity first!`,
  key1004180: `Please configure matching rules first!`,
  key1004181: `Unified pricing cannot be empty!`,
  key1004182: `The end time of the activity cannot be less than the start time of the activity!`,
  key1004183: `Cost allocation`,
  key1004184: `Shipping warehouse:`,
  key1004185: `Single piece processing fee:`,
  key1004186: `Renewal processing fee:`,
  key1004187: `Warehouse processing fee=`,
  key1004188: `(Number of pieces -1)`,
  key1004189: `The shipping warehouse cannot be empty`,
  key1004190: `Single item processing fee cannot be empty`,
  key1004191: `Renewal processing fee cannot be empty`,
  key1004192: `(Recommended size: 644 * 324)`,
  key1004193: `Delete Poll Entry`,
  key1004194: `+Add a rotation item`,
  key1004195: `Rotation item`,
  key1004196: `The rotation chart is empty. Please upload and try again.`,
  key1004197: `Rotation item 1`,
  key1004198: `Create subsidy rules`,
  key1004199: `Subsidy Rule Name`,
  key1004200: `Rule Status`,
  key1004201: `Add Subrule`,
  key1004202: `Freight subsidy`,
  key1004203: `Add fee configuration`,
  key1004204: `Order Continuation`,
  key1004205: `piece`,
  key1004206: `Shipping warehouse`,
  key1004207: `Fee rules`,
  key1004208: `Subsidy recipients:`,
  key1004209: `Subsidy type:`,
  key1004210: `Select time`,
  key1004211: `Multiple merchant accounts should be separated by commas or line breaks in English`,
  key1004212: `condition`,
  key1004213: `This name is visible to the customer, please name it reasonably`,
  key1004214: `Subsidy conditions:`,
  key1004215: `Add conditions`,
  key1004216: `Subsidy amount:`,
  key1004217: `The subrule subsidy will be superimposed with the main rule, please configure it reasonably`,
  key1004218: `Single bill of lading subsidy:`,
  key1004219: `0-1 kg subsidy:`,
  key1004220: `Subsidy per kilogram of continued weight:`,
  key1004221: `Maximum subsidy for single bill of lading:`,
  key1004222: `Subsidy object cannot be empty`,
  key1004223: `Subsidy type cannot be empty`,
  key1004224: `Create sub rules`,
  key1004225: `Edit subsidy rules`,
  key1004226: `Edit Subrules`,
  key1004227: `The subsidy rules are not complete. Please fill them out completely and try again.`,
  key1004228: `The quantity of goods in the bill of lading must be an integer greater than or equal to 0`,
  key1004229: `The order delivery rate must be greater than or equal to 0`,
  key1004230: `The subsidy amount is not complete. Please fill it out completely and try again.`,
  key1004231: `Enter Merchant ID`,
  key1004232: `Server selection`,
  key1004233: `Search:`,
  key1004234: `Yunmai Publishing`,
  key1004235: `Successfully settled`,
  key1004236: `More Roles`,
  key1004237: `Enter employee's name, mailbox, mobile phone number`,
  key1004238: `Account status:`,
  key1004239: `Manage staff roles`,
  key1004240: `Add new staff`,
  key1004241: `By Name`,
  key1004242: `By Email`,
  key1004243: `Last Login Time`,
  key1004244: `Do you want to delete the current role?`,
  key1004245: `Role Name:`,
  key1004246: `Input Role Name`,
  key1004247: `Assembly and processing-`,
  key1004248: `Picking-`,
  key1004249: `Module`,
  key1004250: `Add Roles`,
  key1004251: `Role name cannot be empty`,
  key1004252: `Edit Role`,
  key1004253: `Basic Information`,
  key1004254: `Role`,
  key1004255: `Data permission`,
  key1004256: `Warehouse authority`,
  key1004257: `All data permissions in the warehouse`,
  key1004258: `Access to customer data`,
  key1004259: `All data permissions`,
  key1004260: `New employee`,
  key1004261: `E-mail can not be empty`,
  key1004262: `View employees`,
  key1004263: `Edit Employee`,
  key1004264: `Rule name:`,
  key1004265: `Actual shipping method:`,
  key1004266: `Account:`,
  key1004267: `choose assigned logistics`,
  key1004268: `choose suitable logistics`,
  key1004269: `Deactivated, please modify and save again`,
  key1004270: `logistics setup`,
  key1004271: `time of arrival：less than`,
  key1004272: `Null`,
  key1004273: `have`,
  key1004274: `Please enter rule name.`,
  key1004275: `Please add a shipping method.`,
  key1004276: `At least choose one of delivery time or waybill number`,
  key1004277: `The real shipping method cannot be null.`,
  key1004278: `The account cannot be null.`,
  key1004279: `Add logistics`,
  key1004280: `Choose logistics:`,
  key1004281: `Please select a custom logistics type:`,
  key1004282: `Custom logistics provider code:`,
  key1004283: `Custom logistics provider name:`,
  key1004284: `API docking`,
  key1004285: `Type cannot be null.`,
  key1004286: `Custom logistics provider code cannot be empty`,
  key1004287: `The name of the custom logistics provider cannot be empty`,
  key1004288: `Data docking is not required.`,
  key1004289: `Excel docking`,
  key1004290: `Input Name Search`,
  key1004291: `Logistics channel name/code:`,
  key1004292: `Logistics mode not enabled`,
  key1004293: `Add custom logistics`,
  key1004294: `Logistics Plan Code`,
  key1004295: `Designated code of receiver:`,
  key1004296: `Logistics timeliness`,
  key1004297: `Sending address`,
  key1004298: `Collect address`,
  key1004299: `Return address`,
  key1004300: `Please check the logistics mode to be enabled.`,
  key1004301: `Linked logistics`,
  key1004302: `Logistics channel Name:`,
  key1004303: `Add association`,
  key1004304: `Logistics only supports binding one channel!`,
  key1004305: `(Day)`,
  key1004306: `Are you sure you want to unlink?`,
  key1004307: `Unlink`,
  key1004308: `Please add the logistics first!`,
  key1004309: `Added successfully!`,
  key1004310: `Please choose a complete logistics method first!`,
  key1004311: `Edit custom logistics`,
  key1004312: `Delivery method cannot be empty`,
  key1004313: `Collection time cannot be empty`,
  key1004314: `Delivery method:`,
  key1004315: `Domestic express delivery fee:`,
  key1004316: `Carrier:`,
  key1004317: `Shipping country:`,
  key1004318: `Collection method:`,
  key1004319: `Delivery Services:`,
  key1004320: `Shipping method:`,
  key1004321: `Surface single template`,
  key1004322: `Waybill size`,
  key1004323: `Notes:`,
  key1004324: `Global accessibility (same freight)`,
  key1004325: `Global accessibility (freight difference)`,
  key1004326: `Non-global accessibility`,
  key1004327: `Freight discount:`,
  key1004328: `Passthrough:`,
  key1004329: `Basic information of channels`,
  key1004330: `type of custom logistics:`,
  key1004331: `no docking`,
  key1004332: `Logistics solution code:`,
  key1004333: `Timeliness for reference:`,
  key1004334: `Insurance service:`,
  key1004335: `Size limit(cm):`,
  key1004336: `Applicable weight`,
  key1004337: `Perimeter limit(cm):`,
  key1004338: `The limitation of the longest side (cm):`,
  key1004339: `Volume limit(cm³):`,
  key1004340: `URL of tracking information:`,
  key1004341: `Collection point address information`,
  key1004342: `国内快递收货地址选择：`,
  key1004343: `使用固定地址模板`,
  key1004344: `使用订单地址`,
  key1004345: `Logistics provider optional settings (default settings)`,
  key1004346: `Distribution scope and freight`,
  key1004347: `Delivery scope`,
  key1004348: `Billing model`,
  key1004349: `Quote from other channels`,
  key1004350: `Bulk import`,
  key1004351: `Add partition`,
  key1004352: `Freight Currency:`,
  key1004353: `Freight discount`,
  key1004354: `Pass through`,
  key1004355: `Please note that when there is no discount, please enter 1, 20% discount, please enter 0.8.`,
  key1004356: `Applicable warehouse`,
  key1004357: `All self-owned warehouses`,
  key1004358: `Platform standard delivery settings`,
  key1004359: `No-fill-in bill number`,
  key1004360: `(default)`,
  key1004361: `Adding exceptional countries`,
  key1004362: `Side sheet printing settings`,
  key1004363: `Print distribution list`,
  key1004364: `Standard quotation`,
  key1004365: `Operating fee (yuan/ticket)`,
  key1004366: `Priority (g)`,
  key1004367: `First heavy freight`,
  key1004368: `Continuous weight unit weight (g)`,
  key1004369: `Continuous unit price (yuan)`,
  key1004370: `Special Country cannot be empty`,
  key1004371: `Logistics Service Provider (Carrier) cannot be empty`,
  key1004372: `You cannot add a delivery service with the same name!`,
  key1004373: `Partition`,
  key1004374: `Default partition`,
  key1004375: `Accessible country`,
  key1004376: `All countries`,
  key1004377: `Billing mode`,
  key1004378: `Weighting method`,
  key1004379: `Accounting formula`,
  key1004380: `manually synchronize`,
  key1004381: `synchronize after successfully creating logistics order`,
  key1004382: `synchronize after acquiring waybill number`,
  key1004383: `synchronize after successfully shipping out`,
  key1004384: `Discount price`,
  key1004385: `return address `,
  key1004386: `Address of recipient`,
  key1004387: `Return recipient address`,
  key1004388: `Delivered by buyer`,
  key1004389: `Domestic express delivery fee cannot be empty`,
  key1004390: `Logistics channel code cannot be null.`,
  key1004391: `The default out-of-zone billing model country cannot be empty`,
  key1004392: `Incompatible or incomplete parameters of piecewise billing table`,
  key1004393: `Edit logistics channels`,
  key1004394: `View logistics channels`,
  key1004395: `Add logistics channels`,
  key1004396: `First priority`,
  key1004397: `Yuan`,
  key1004398: `Continuous weight`,
  key1004399: `Can not add the same name of the logistics provider service!`,
  key1004400: `designated order source by rule`,
  key1004401: `/The designated shipping country/region by rules include`,
  key1004402: `Designated shipping method by rule`,
  key1004403: `Scope of application`,
  key1004404: `Applicable platform:`,
  key1004405: `Applicable stores:`,
  key1004406: `Applicable package amount ($):`,
  key1004407: `Range of Shipping fee (buyer pay) ($):`,
  key1004408: `Included SKU:`,
  key1004409: `Support multiple SKUs, separated by commas or spaces`,
  key1004410: `Range of total quantity of products:`,
  key1004411: `lowest freight`,
  key1004412: `fastest delivery time`,
  key1004413: `lowest upload time`,
  key1004414: `highest probability rate`,
  key1004415: `Explanation of intelligent logistics matching rules`,
  key1004416: `the basic attribute of matching logistics channel`,
  key1004417: `Necessary process: find available logistics provider`,
  key1004418: `The order match  in the basic attribute of logistics channel`,
  key1004419: `1.size limit`,
  key1004420: `2.weight limit`,
  key1004421: `3.type limit`,
  key1004422: `4.delivery area limit`,
  key1004423: `=1，>1matching result=1, over; matching result>1, move on to the next step`,
  key1004424: `5. Logistics channels supported by the shipping warehouse`,
  key1004425: `logistics matching rules`,
  key1004426: `The result set that was successfully matched in the previous step runs logistics matching rules, matches through the rule engine, and matches from top to bottom`,
  key1004427: `Match the scope of application of logistics `,
  key1004428: `Optional process: Range of application`,
  key1004429: `，Users can customize the scope of application of logistics channels to match the scope of application of all channels`,
  key1004430: `freight/timeliness priority`,
  key1004431: `：necessary process: choose the lowest freight or fastest shipping`,
  key1004432: `Calculate the freight/timeliness of all eligible logistics channels, and select the lowest freight or the fastest logistics according to the priority that you have set`,
  key1004433: `Logistics rules`,
  key1004434: `Add logistics rules`,
  key1004435: `Selected shipping method:`,
  key1004436: `The scope of application of logistics channels`,
  key1004437: `More warehouses`,
  key1004438: `Drag`,
  key1004439: `form name`,
  key1004440: `Final Modifier`,
  key1004441: `Logistics vendor/logistics channel`,
  key1004442: `Platform/Account`,
  key1004443: `Freight area`,
  key1004444: `sku included`,
  key1004445: `Quantity range`,
  key1004446: `All`,
  key1004447: `New rules`,
  key1004448: `Logistics name:`,
  key1004449: `Associated logistics channels:`,
  key1004450: `Associated logistics channels`,
  key1004451: `Link`,
  key1004452: `Distributor Logistics`,
  key1004453: `Supplier Logistics`,
  key1004454: `Scope of application:`,
  key1004455: `Applicable weight (g):`,
  key1004456: `Volume limit (cm):`,
  key1004457: `Province/State`,
  key1004458: `Street:`,
  key1004459: `AliTM:`,
  key1004460: `Pickup time:`,
  key1004461: `Proxy`,
  key1004462: `domestic`,
  key1004463: `abroad`,
  key1004464: `Basic information of the plan`,
  key1004465: `Weight Unit:`,
  key1004466: `Delivery Settings on Express Line`,
  key1004467: `EDIS Logistics Settings`,
  key1004468: `Logistics Settings`,
  key1004469: `Select a shipping address:`,
  key1004470: `Please select a pickup address:`,
  key1004471: `Address:`,
  key1004472: `Select return address:`,
  key1004473: `Shipping mode and package time`,
  key1004474: `Delivery by sellers`,
  key1004475: `Customs clearance fee configuration`,
  key1004476: `Whether to charge customs clearance fees:`,
  key1004477: `collect`,
  key1004478: `No charge`,
  key1004479: `Proportional configuration: declared value in`,
  key1004480: `At a rate of 150 EUR, calculated at the following tax rates`,
  key1004481: `Parcels exceeding 150 euros will be issued with logistics failure.`,
  key1004482: `Unit price of renewal weight`,
  key1004483: `tax rate`,
  key1004484: `The tax rate must be greater than or equal to 0 and a maximum of 2 decimal places are allowed`,
  key1004485: `The billing model country outside the default partition cannot be empty!`,
  key1004486: `Required fields cannot be empty!`,
  key1004487: `Content cannot be empty`,
  key1004488: `Logistics company name:`,
  key1004489: `Available:`,
  key1004490: `Applicable platforms cannot be empty.`,
  key1004491: `Offline logistics`,
  key1004492: `Online logistics`,
  key1004493: `Carrier name`,
  key1004494: `Is the distributor available`,
  key1004495: `Logistics account`,
  key1004496: `Applicable platform`,
  key1004497: `Docking type`,
  key1004498: `Edit account`,
  key1004499: `Modify the scope of application`,
  key1004500: `Is the distributor available:`,
  key1004501: `Account name cannot be empty`,
  key1004502: `Personal name/company name cannot be empty!`,
  key1004503: `Address information cannot be empty!`,
  key1004504: `Account Name:`,
  key1004505: `Authorization status:`,
  key1004506: `Personal Name/Company Name:`,
  key1004507: `Phone:`,
  key1004508: `Address information:`,
  key1004509: `Authorize again`,
  key1004510: `Authorize`,
  key1004511: `Authorized`,
  key1004512: `Unauthorized`,
  key1004513: `EU agent`,
  key1004514: `Failed to obtain the application of authorization callback address!`,
  key1004515: `IOSS used:`,
  key1004516: `Condition type:`,
  key1004517: `Platform:`,
  key1004518: `Shop:`,
  key1004519: `This name is only used to better distinguish different IOSS`,
  key1004520: `By Store`,
  key1004521: `By platform`,
  key1004522: `Please select the IOSS to use`,
  key1004523: `Please choose the shop.`,
  key1004524: `Add Rule`,
  key1004525: `Add IOSS`,
  key1004526: `Declaration instructions:`,
  key1004527: `YMS will declare based on the sales price of the product in YMS, which corresponds to the price of the goods in the YMS order.`,
  key1004528: `View tax rates`,
  key1004529: `When there is an IOSS number in the platform order, YMS will prioritize using the IOSS number returned by the platform. If the IOSS number cannot be matched, the system will intercept this order.`,
  key1004530: `manual order`,
  key1004531: `IOSS management`,
  key1004532: `IOSS rule configuration`,
  key1004533: `IOSS name`,
  key1004534: `IOSS number`,
  key1004535: `Use IOSS name`,
  key1004536: `Use IOSS number`,
  key1004537: `Applicable platforms/stores`,
  key1004538: `Applicable stores`,
  key1004539: `Enable IOSS confirmation`,
  key1004540: `Deactivate IOSS confirmation`,
  key1004541: `After enabling IOSS, relevant rules will be able to be matched. Please confirm whether to enable IOSS?`,
  key1004542: `After deactivating IOSS, the rules using this IOSS number will also be deactivated. Please confirm whether to deactivate IOSS?`,
  key1004543: `Enable IOSS rules`,
  key1004544: `Deactivate IOSS rule`,
  key1004545: `Please confirm whether to enable the rule?`,
  key1004546: `Please confirm whether to deactivate the rule?`,
  key1004547: `Original price`,
  key1004548: `Adjusted price`,
  key1004549: `rate`,
  key1004550: `Verify identidy`,
  key1004551: `Set up a new mailbox`,
  key1004552: `Verification code:`,
  key1004553: `Phone verification code:`,
  key1004554: `Please enter the phone verification code`,
  key1004555: `new mail box:`,
  key1004556: `E-mail verification code:`,
  key1004557: `Please enter the email verification code`,
  key1004558: `Next`,
  key1004559: `mention`,
  key1004560: `cross`,
  key1004561: `Image verification code cannot be empty`,
  key1004562: `Phone number format is incorrect!`,
  key1004563: `The phone number and picture verification code cannot be empty!`,
  key1004564: `E-mail format is incorrect!`,
  key1004565: `E-mail can not be empty!`,
  key1004566: `The verification code has been sent!`,
  key1004567: `Phone number cannot be blank`,
  key1004568: `E-mail format is incorrect.`,
  key1004569: `Phone verification`,
  key1004570: `E-mail verification`,
  key1004571: `login password`,
  key1004572: `Changing passwords regularly helps account security`,
  key1004573: `New date:`,
  key1004574: `New SPU:`,
  key1004575: `Mark all read`,
  key1004576: `Mark read`,
  key1004577: `Read Only Unread`,
  key1004578: `Order exception notification`,
  key1004579: `Notice of discontinuation`,
  key1004580: `Price adjustment notice`,
  key1004581: `New notifications on the store`,
  key1004582: `Automatic Rule Notification`,
  key1004583: `Please check the data to be marked first!`,
  key1004584: `Distributor Name:`,
  key1004585: `currency：`,
  key1004586: `Modify Currency`,
  key1004587: `Modify phone`,
  key1004588: `Phone number:`,
  key1004589: `Modify email`,
  key1004590: `IOSS Name:`,
  key1004591: `IOSS number:`,
  key1004592: `IOSS name cannot be empty`,
  key1004593: `Edit IOSS`,
  key1004594: `IOSS number cannot be empty`,
  key1004595: `Please enter IM+10 digits`,
  key1004596: `old password:`,
  key1004597: `New password:`,
  key1004598: `Confirm the new password again:`,
  key1004599: `The original password cannot be empty`,
  key1004600: `New password cannot be empty`,
  key1004601: `update completed!`,
  key1004602: `The new password is inconsistent with the confirmed new password`,
  key1004603: `Set up a new phone`,
  key1004604: `New phone number:`,
  key1004605: `Please enter a new phone number`,
  key1004606: `The email and picture verification code cannot be empty!`,
  key1004607: `The phone number format is incorrect!`,
  key1004608: `The phone number cannot be empty!`,
  key1004609: `Minimum inventory`,
  key1004610: `Maximum inventory`,
  key1004611: `¥ Lowest price`,
  key1004612: `¥ Maximum price`,
  key1004613: `Price:`,
  key1004614: `One click publishing`,
  key1004615: `Download materials`,
  key1004616: `Comprehensive`,
  key1004617: `sales volume`,
  key1004618: `Last New Time`,
  key1004619: `Total inventory`,
  key1004620: `Please check the items that need to be cancelled as favorites`,
  key1004621: `Cancel collection successful!`,
  key1004622: `Please check the items you want to bookmark`,
  key1004623: `Product collection successful`,
  key1004624: `Please tick the products that need to download data`,
  key1004625: `YMS order`,
  key1004626: `Platform order`,
  key1004627: `Stock list`,
  key1004628: `Order Rules`,
  key1004629: `every other`,
  key1004630: `Every minute`,
  key1004631: `Available quantity=`,
  key1004632: `Order Exceeds`,
  key1004633: `Automatically cancel when hours are not boxed`,
  key1004634: `Inventory synchronization rules`,
  key1004635: `Product status synchronization rules`,
  key1004636: `Shop`,
  key1004637: `Listings Template `,
  key1004638: `Synchronization time`,
  key1004639: `Enable`,
  key1004640: `Inventory synchronization`,
  key1004641: `Product status synchronization`,
  key1004642: `Price synchronization`,
  key1004643: `Supplier warehouse`,
  key1004644: `Automatically adjust inventory to 0 when the product is discontinued`,
  key1004645: `Automatically synchronize the status to the listing tool when the product is discontinued`,
  key1004646: `After deletion, it will not be recoverable. Are you sure you want to delete it?`,
  key1004647: `Please select the applicable platform`,
  key1004648: `This rule applies to automatic approval when there is a change in product price`,
  key1004649: `Adjusting prices`,
  key1004650: `Please select an applicable store`,
  key1004651: `Add price synchronization rule`,
  key1004652: `Modify price synchronization rules`,
  key1004653: `Price synchronization rules`,
  key1004654: `It may be because the inventory quantity base is small, causing the inventory to be zero after floating. It may also be because the inventory base is large, and there is no change in the inventory after floating compared to before floating. Please set the floating ratio carefully.`,
  key1004655: `The formula calculates the result to be rounded, for example: the calculated result is 9.5, and the actual value is 10.`,
  key1004656: `Float Down:`,
  key1004657: `Synchronization time:`,
  key1004658: `Please select warehouse.`,
  key1004659: `Add inventory rules`,
  key1004660: `Edit inventory rules`,
  key1004661: `Platforms`,
  key1004662: `Order timeout cannot be empty`,
  key1004663: `Order synchronization rules`,
  key1004664: `This rule applies to the automatic adjustment of inventory to zero and synchronization with the status of the listed tools when the product is discontinued.`,
  key1004665: `When this option is checked in any rule, the product's suspension status will be synchronized to the listing tool.`,
  key1004666: `Add product status synchronization rule`,
  key1004667: `Modify product status synchronization rules`,
  key1004668: `Change type`,
  key1004669: `Purchase arrival`,
  key1004670: `Allocate inventory`,
  key1004671: `Stock taking`,
  key1004672: `Change time`,
  key1004673: `Temu Store:`,
  key1004674: `Upload Status`,
  key1004675: `Uploaded`,
  key1004676: `Not uploaded`,
  key1004677: `Re upload`,
  key1004678: `The upload file format is incorrect. Please upload a barcode PDF file or PNG image`,
  key1004679: `Publish Store`,
  key1004680: `Listing status:`,
  key1004681: `Publication platform:`,
  key1004682: `Published`,
  key1004683: `Not published`,
  key1004684: `Number of published items`,
  key1004685: `Publication status`,
  key1004686: `设置别名`,
  key1004687: `请输入别名`,
  key1004688: `该别名已与 YMSSKU`,
  key1004689: `alike`,
  key1004690: `不允许出现相同别名`,
  key1004691: `设置商品别名后，出现平台订单，订单商品SKU与YMS商品SKU匹配失败时，订单商品SKU`,
  key1004692: `可再与YMS商品的别名进行匹配，匹配成功后订单自动匹配该商品，无需分销商手动选择匹配;`,
  key1004693: `Favorite status:`,
  key1004694: `Collection time:`,
  key1004695: `Sales Information:`,
  key1004696: `Inventory:`,
  key1004697: `please enter an integer`,
  key1004698: `Export product information`,
  key1004699: `Generate sales information prompt`,
  key1004700: `Batch generation of sales materials`,
  key1004701: `Batch export of product information`,
  key1004702: `Ignore generated`,
  key1004703: `Generate`,
  key1004704: `Checked`,
  key1004705: `There are items in the list`,
  key1004706: `Sales records have been generated. Please choose whether to regenerate them?`,
  key1004707: `Inventory (piece)`,
  key1004708: `Enjoy`,
  key1004709: `fracture`,
  key1004710: `reduce`,
  key1004711: `More than pieces`,
  key1004712: `Discount price:`,
  key1004713: `Discount:`,
  key1004714: `Next stage discounts:`,
  key1004715: `Sales within the past 30 days`,
  key1004716: `On-shelf time`,
  key1004717: `Collection time`,
  key1004718: `Sales Information`,
  key1004719: `Generate sales time`,
  key1004720: `Generate sales information`,
  key1004721: `Cancel Favorite`,
  key1004722: `Republish`,
  key1004723: `设置关联关系`,
  key1004724: `Product SPU`,
  key1004725: `Please enter the product SPU, which supports multiple line separations`,
  key1004726: `Product SKU`,
  key1004727: `Please enter the product SKU, which supports multiple line separations`,
  key1004728: `Please enter the product title, which supports multiple line separations`,
  key1004729: `Please enter the supplier name, supporting multiple line separated names`,
  key1004730: `Please enter the supplier number, which supports multiple separate lines`,
  key1004731: `Basic information of the product`,
  key1004732: `Basic Product Information Table`,
  key1004733: `Product Image`,
  key1004734: `Rotation chart`,
  key1004735: `Attribute Graph`,
  key1004736: `Not generated`,
  key1004737: `Already generated`,
  key1004738: `Exceeding the number of single downloads`,
  key1004739: `You can download up to 10 products at a time. Please reselect and download.`,
  key1004740: `Please input SKU`,
  key1004741: `此SKU已与 YMSSKU`,
  key1004742: `形成关联关系`,
  key1004743: `平台商品SKU：`,
  key1004744: `一个平台商品仅允许形成一个绑定关系`,
  key1004745: `设置商品关联关系后，出现平台订单，订单商品SKU与YMS商品SKU匹配失败时，订单商品可通过关联关`,
  key1004746: `系与YMS商品进行匹配，匹配成功后订单自动匹配该商品，无需分销商手动选择匹配；当YMS商品同时设`,
  key1004747: `置了`,
  key1004748: `别名`,
  key1004749: `关联关系”时，优先使用`,
  key1004750: `关联关系”进行匹配;`,
  key1004751: `需要将“平台`,
  key1004752: `、“店铺”、“平台商品SKU”填写完整，才会创建对应的关联关系;`,
  key1004753: `Please enter the product SPU, which supports multiple line separations`,
  key1004754: `Product title:`,
  key1004755: `Initiated generation time:`,
  key1004756: `Originator:`,
  key1004757: `surplus`,
  key1004758: `Expected`,
  key1004759: `Generate completed in minutes`,
  key1004760: `Batch stop generating sales information`,
  key1004761: `Generating`,
  key1004762: `Generated successfully`,
  key1004763: `Generation failed`,
  key1004764: `Generate Status`,
  key1004765: `To be generated`,
  key1004766: `Initiate generation of sales time`,
  key1004767: `Failure reason`,
  key1004768: `Initiator`,
  key1004769: `Stop generating`,
  key1004770: `Only the status to be generated can stop generating sales data`,
  key1004771: `Please check the data you want to stop generating first!`,
  key1004772: `Stop generating sales information confirmation`,
  key1004773: `We are currently working to stop generating sales data. Once confirmed, all tasks that have not been generated will be cancelled. If you need to regenerate them, you will need to redo the operation in your favorite products. Please confirm if you want to stop generating them?`,
  key1004774: `Inventory priority configuration`,
  key1004775: `Use inventory priority`,
  key1004776: `Whether to wait for in transit inventory`,
  key1004777: `Prioritize the use of my own inventory with ownership rights`,
  key1004778: `Prioritize the use of other inventory in the YMS warehouse`,
  key1004779: `wait for`,
  key1004780: `Don't wait`,
  key1004781: `SKU inventory range:`,
  key1004782: `Export inventory`,
  key1004783: `On the 7th:`,
  key1004784: `On the 14th:`,
  key1004785: `Recent sales`,
  key1004786: `My available inventory`,
  key1004787: `My in transit inventory`,
  key1004788: `Average cost of goods`,
  key1004789: `The starting range of SKU inventory cannot be greater than the ending range of SKU inventory`,
  key1004790: `Select Duration`,
  key1004791: `Store duration`,
  key1004792: `Price CNY`,
  key1004793: `1 month`,
  key1004794: `3 months`,
  key1004795: `6 months`,
  key1004796: `12 months`,
  key1004797: `Open shop`,
  key1004798: `Renewal Confirmation`,
  key1004799: `Confirm renewal`,
  key1004800: `Recharge in...`,
  key1004801: `Successful opening`,
  key1004802: `Renewal success`,
  key1004803: `Failed to open`,
  key1004804: `Renewal failed`,
  key1004805: `Please enter the code and account name`,
  key1004806: `If you need eDIS to ship without adding a store, please bind the eBay ID to eDIS before adding a store.`,
  key1004807: `When a store has been added, if eDIS shipment is required, please bind eDIS to eBay ID before re authorizing the store.`,
  key1004808: `Authorization status`,
  key1004809: `Go to Authorization`,
  key1004810: `First authorization time`,
  key1004811: `Authorization update time`,
  key1004812: `Thoroughfare`,
  key1004813: `Expiration time`,
  key1004814: `Permanently Effective`,
  key1004815: `Not opened`,
  key1004816: `Expired`,
  key1004817: `Open`,
  key1004818: `Renewal`,
  key1004819: `Authorization successful!`,
  key1004820: `Successfully added account!`,
  key1004821: `Role description:`,
  key1004822: `Please enter a role description`,
  key1004823: `Permission allocation`,
  key1004824: `New Role`,
  key1004825: `View Role`,
  key1004826: `Add Role`,
  key1004827: `Role Name`,
  key1004828: `Role Description`,
  key1004829: `Number of users (enabled)`,
  key1004830: `When a user is already associated with a role, the role cannot be deleted.`,
  key1004831: `Are you sure you want to delete this role?`,
  key1004832: `After deleting this role, it will not be recoverable. Are you sure you want to delete it?`,
  key1004833: `Successfully deleted the role!`,
  key1004834: `View Users`,
  key1004835: `account type`,
  key1004836: `Main account`,
  key1004837: `Sub account`,
  key1004838: `Account Role`,
  key1004839: `Please input the email address.`,
  key1004840: `Generate password`,
  key1004841: `Shop permissions`,
  key1004842: `Add User`,
  key1004843: `Edit User`,
  key1004844: `Successfully added, failed to update user role authorization for the shelved tool!`,
  key1004845: `Please enter your phone number, email, or name`,
  key1004846: `Change password`,
  key1004847: `After changing the password, the original password will no longer be usable. Please modify it with caution!`,
  key1004848: `Please enter the supplier name`,
  key1004849: `Number of fans:`,
  key1004850: `enter a shop`,
  key1004851: `Are you sure to unfollow?`,
  key1004852: `Refund of goods cost`,
  key1004853: `Refund for package handling`,
  key1004854: `Logistics refund`,
  key1004855: `Domestic express refund`,
  key1004856: `Cost of goods`,
  key1004857: `Event description`,
  key1004858: `Your current available balance is`,
  key1004859: `Continue payment`,
  key1004860: `The payment is successful and the account is being credited`,
  key1004861: `Third party voucher number:`,
  key1004862: `Export expense details`,
  key1004863: `Deduction`,
  key1004864: `refund`,
  key1004865: `Platform order currency`,
  key1004866: `Product quantity (pieces)`,
  key1004867: `Logistics weight`,
  key1004868: `SKU sales price`,
  key1004869: `SKU discounted price`,
  key1004870: `Please enter the platform order number`,
  key1004871: `Recharge your balance`,
  key1004872: `Recharge amount:`,
  key1004873: `Other amounts`,
  key1004874: `Current exchange rate: 1`,
  key1004875: `The account currency is`,
  key1004876: `The recharge amount cannot exceed 100,000,000`,
  key1004877: `The recharge amount cannot be less than or equal to 0`,
  key1004878: `(Cancelled)`,
  key1004879: `Stock up to`,
  key1004880: `Cancellation Amount:`,
  key1004881: `Refund amount:`,
  key1004882: `Order Item`,
  key1004883: `item pricing `,
  key1004884: `Actual paid unit price`,
  key1004885: `Order quantity`,
  key1004886: `Received quantity`,
  key1004887: `Some goods have not arrived in the warehouse, and some have been refunded.`,
  key1004888: `Number of items canceled by user`,
  key1004889: `Total weight of goods (g)`,
  key1004890: `Total value of goods`,
  key1004891: `Paid`,
  key1004892: `Supplier has shipped`,
  key1004893: `To be prepared`,
  key1004894: `Not completed`,
  key1004895: `Please select a Temu store`,
  key1004896: `Please fill in the Temu order number`,
  key1004897: `You haven't added any products yet`,
  key1004898: `Processing fee=3+(quantity of goods -1) * 0.5`,
  key1004899: `The total amount payable will be withheld when submitting the order, please ensure that the account has sufficient balance.`,
  key1004900: `Collapse Address`,
  key1004901: `More addresses`,
  key1004902: `Shipping Order`,
  key1004903: `Sample order`,
  key1004904: `Delete product`,
  key1004905: `Please note:`,
  key1004906: `1. For sample orders, if multiple SKUs need to be shipped under one SPU, please ensure that the SKUs are placed within one order for order placement.`,
  key1004907: `2. For shipping orders, please make sure to place the order according to the TEMU backend stock order. Multiple stock orders cannot be placed under the same YMS order.`,
  key1004908: `Product quantity:`,
  key1004909: `Total weight of goods (g):`,
  key1004910: `Total amount of goods:`,
  key1004911: `shipping address`,
  key1004912: `Set as default address`,
  key1004913: `Add shipping address`,
  key1004914: `Amount payable:`,
  key1004915: `Quantity (piece)`,
  key1004916: `Subtotal`,
  key1004917: `Temu store cannot be empty`,
  key1004918: `Temu order number cannot be empty`,
  key1004919: `Please check the items to be removed first!`,
  key1004920: `Edit shipping address`,
  key1004921: `Do you want to delete the current shipping address?`,
  key1004922: `Cancel prompt`,
  key1004923: `After canceling, the already filled content will not be saved. Are you sure you want to cancel?`,
  key1004924: `Continue filling in`,
  key1004925: `The order information is incomplete. Please complete the order information before submitting!`,
  key1004926: `Delivery method`,
  key1004927: `Freight:`,
  key1004928: `Modify delivery method`,
  key1004929: `Choose delivery method`,
  key1004930: `Using IOSS:`,
  key1004931: `Go to configuration`,
  key1004932: `Additional fees will be incurred during logistics payment, and YMS will collect them on behalf of you.`,
  key1004933: `This order will be subject to additional charges`,
  key1004934: `Please select logistics first`,
  key1004935: `Warehouse Storage`,
  key1004936: `是否确定提交该订单？`,
  key1004937: `当您账户余额充足时，会自动使用账户余额进行订单金额预扣，如果账户余额不足，`,
  key1004938: `则会为您创建“待付款”状态的订单，需要您进行支付后，平台才会进行订单履约。`,
  key1004939: `The current shipping address does not support delivery!`,
  key1004940: `Please select the IOSS to use!`,
  key1004941: `Please enter the IOSS number!`,
  key1004942: `Please enter IM+10 digits!`,
  key1004943: `订单提交`,
  key1004944: `账户余额不足，以为您创建"待付款”状态订单`,
  key1004945: `账户余额充足，预扣成功`,
  key1004946: `Enter IOSS`,
  key1004947: `Matching logistics:`,
  key1004948: `After changing the logistics, the customs clearance fee will be recalculated, and the originally charged fee will be refunded to this order`,
  key1004949: `Customs clearance fees`,
  key1004950: `Match logistics`,
  key1004951: `assign warehouse and logistics`,
  key1004952: `Modify logistics address`,
  key1004953: `The buyer modifies the address. The information before and after the modification is as follows. Please confirm whether to modify the address of Ali International Order?`,
  key1004954: `Original Address:`,
  key1004955: `receiver's name`,
  key1004956: `Contact information`,
  key1004957: `New address:`,
  key1004958: `Reason for failure:`,
  key1004959: `Address update failed`,
  key1004960: `Tag processed`,
  key1004961: `Retry`,
  key1004962: `Logistics channel name`,
  key1004963: `Please check the data for the delivery method first!`,
  key1004964: `Not within the weight range!`,
  key1004965: `Order reason, this reason is only`,
  key1004966: `Visible.`,
  key1004967: `Cancel scope:`,
  key1004968: `Cancel product:`,
  key1004969: `Please note that this order will be voided after partial cancellation and a new YMS order will be generated!`,
  key1004970: `Current`,
  key1004971: `No goods in the order`,
  key1004972: `After the order is canceled, the order will not be shipped, and a handling fee will be charged for the cancellation of the boxed order.`,
  key1004973: `Fees, OK to cancel the order?`,
  key1004974: `Please choose to cancel the scope`,
  key1004975: `Whole order cancellation`,
  key1004976: `Cancel some products`,
  key1004977: `When canceling some products, the cancelled quantity cannot be all empty!`,
  key1004978: `When canceling some products, the total quantity of cancellations cannot be greater than or equal to the total order quantity!`,
  key1004979: `Confirm Cancel Order`,
  key1004980: `Order trajectory`,
  key1004981: `Collection, sending to ymsWarehouseName warehouse`,
  key1004982: `Order trajectory`,
  key1004983: `Order confirmed, waiting for merchant to pick up`,
  key1004984: `The order has been picked and is awaiting merchant review`,
  key1004985: `The order has been reviewed and completed, waiting for the merchant to package it`,
  key1004986: `Express delivery in transit, currently being sent to ymsWarehouseName, tracking number: expressageNo`,
  key1004987: `YmsWarehouseName has been received and is awaiting warehouse processing`,
  key1004988: `YmsWarehouseName is being stocked`,
  key1004989: `Goods picking completed, waiting for warehouse packaging`,
  key1004990: `The goods are packaged and waiting for the warehouse to pack them`,
  key1004991: `The warehouse has shipped and ShippingMethodName has been notified for logistics pickup`,
  key1004992: `The order has been shipped out and the final logistics trajectory is required. Please check the official logistics website or third-party platform by yourself`,
  key1004993: `Order cancelled`,
  key1004994: `Current delivery to:`,
  key1004995: `Please enter the province/state`,
  key1004996: `Please enter the city`,
  key1004997: `Please enter the district/county`,
  key1004998: `detailed address:`,
  key1004999: `Please enter detailed address information, such as road, house number, community, building number, unit, etc`,
  key1005000: `Please enter your zip code.`,
  key1005001: `Name of consignee:`,
  key1005002: `Not exceeding 25 characters in length`,
  key1005003: `Please enter a fixed phone number`,
  key1005004: `Email address:`,
  key1005005: `Set as default shipping address`,
  key1005006: `Province/State cannot be empty`,
  key1005007: `The recipient name cannot be empty`,
  key1005008: `Successfully added!`,
  key1005009: `Reason for cancellation:`,
  key1005010: `Cancellation type:`,
  key1005011: `Please select the reason for platform order cancellation, which is visible to buyers.`,
  key1005012: `When canceling a platform order, the system will automatically call the platform interface to cancel the order and refund the buyer at the same time!`,
  key1005013: `A handling fee will be charged for cancellation of a packed order, and this order will be charged after cancellation.`,
  key1005014: `cost`,
  key1005015: `Please select the cancellation type`,
  key1005016: `Please select the reason for cancellation`,
  key1005017: `Cancel platform order`,
  key1005018: `Platform order transaction number`,
  key1005019: `When canceling some products, canceling all products cannot be checked!`,
  key1005020: `Please check the data that needs to be cancelled first!`,
  key1005021: `There are currently no products in the current platform order`,
  key1005022: `There is an issue with the buyer providing the address`,
  key1005023: `Cancel the order at the buyer's request`,
  key1005024: `Buyer unpaid`,
  key1005025: `Product out of stock or other performance issues`,
  key1005026: `The buyer no longer wants the product`,
  key1005027: `The buyer bought the wrong product`,
  key1005028: `There is a dispute between the buyer and seller regarding this product`,
  key1005029: `Product out of stock`,
  key1005030: `Only defective products remain`,
  key1005031: `Arbitration cancellation`,
  key1005032: `Other reasons`,
  key1005033: `The buyer did not collect the order`,
  key1005034: `Unable to deliver to the region`,
  key1005035: `The order was lost during transportation`,
  key1005036: `Customer requests cancellation`,
  key1005037: `Pricing error`,
  key1005038: `Fraudulent transactions, cancellation of orders`,
  key1005039: `Buyer address not available`,
  key1005040: `Unable to deliver`,
  key1005041: `The item is damaged`,
  key1005042: `Item returned to sender`,
  key1005043: `匹配仓库`,
  key1005044: `仓库选择：`,
  key1005045: `Since this order is fulfilled by Ali International's multi-merchant, it has been automatically split into different orders and needs to be paid according to the order.`,
  key1005046: `payment status`,
  key1005047: `Go to pay`,
  key1005048: `Update Status`,
  key1005049: `Payment Confirmation`,
  key1005050: `Is the payment successful?`,
  key1005051: `State Not Updated Prompt`,
  key1005052: `The status of the obtained Ali international order information is still unpaid, please try again later or manually mark it as successful.`,
  key1005053: `Manual marking`,
  key1005054: `Replace order item`,
  key1005055: `Confirm replacement`,
  key1005056: `Please select the product and quantity to be replaced`,
  key1005057: `Please note that this order will be voided after partial cancellation and a new YMS order will be generated!`,
  key1005058: `Quantity to be replaced`,
  key1005059: `Must be less than the order quantity`,
  key1005060: `SKU information in the original order`,
  key1005061: `Replace SKU information`,
  key1005062: `Select product`,
  key1005063: `Replace the product with the current one`,
  key1005064: `The quantity of replacement products must be less than the order quantity`,
  key1005065: `No replacement order item selected`,
  key1005066: `No replacement quantity entered for the selected product`,
  key1005067: `Please select a replacement product`,
  key1005068: `Submitting`,
  key1005069: `Modify receipient information`,
  key1005070: `Buyer's name:`,
  key1005071: `Address 1:`,
  key1005072: `Address 2:`,
  key1005073: `Province / State:`,
  key1005074: `Telephone number:`,
  key1005075: `The currently selected destination country needs to enter IOSS related information`,
  key1005076: `Please note that after modifying the address information, this order will be voided and a new order will be generated!`,
  key1005077: `calibration in...`,
  key1005078: `Address Change Prompt`,
  key1005079: `The current logistics mode does not support this region. The logistics mode will be left empty after the address is changed. Please re-match the logistics in the pending status. Please confirm whether to change the address?`,
  key1005080: `Identify changes`,
  key1005081: `Modifying`,
  key1005082: `order details`,
  key1005083: `Billing weight (`,
  key1005084: `Processing Completed`,
  key1005085: `Expected`,
  key1005086: `Arrival`,
  key1005087: `This time is the estimated arrival time of the supplier's warehouse, and it will take 2-3 days until the final package is sent out.`,
  key1005088: `Outbound order no.`,
  key1005089: `Name of consignee`,
  key1005090: `Buyer identification number`,
  key1005091: `Detailed Address 1`,
  key1005092: `Detailed Address 2`,
  key1005093: `Shipping method`,
  key1005094: `Logistics provider weighing (g)`,
  key1005095: `Freight for logistics providers`,
  key1005096: `Waiting for logistics collection`,
  key1005097: `Supplier stocking`,
  key1005098: `Logistics collected`,
  key1005099: `Automatic platform order creation`,
  key1005100: `Cancellation quantity cannot be empty`,
  key1005101: `Due to price adjustment, payment cannot be made at the moment when the supplier accepts the order`,
  key1005102: `The product has been discontinued`,
  key1005103: `Partial cancellation successful!`,
  key1005104: `Third Party Orders:`,
  key1005105: `Please enter a third-party order, support multiple separated by lines`,
  key1005106: `Order notes:`,
  key1005107: `Payment status:`,
  key1005108: `Add Order`,
  key1005109: `Bulk Import Orders`,
  key1005110: `批量付款`,
  key1005111: `Order Commodity Information`,
  key1005112: `Order number, support multiple separated by line`,
  key1005113: `Order number:`,
  key1005114: `Third party tracking number:`,
  key1005115: `Parcels that need to be sent by courier to the collection point will be subject to an additional domestic courier fee. The specific fees can be viewed by clicking on the corresponding channel in the self owned logistics module.`,
  key1005116: `Order remark`,
  key1005117: `Destination and buyer information`,
  key1005118: `Destination:`,
  key1005119: `Buyer ID:`,
  key1005120: `Product fee:`,
  key1005121: `Customs clearance fees:`,
  key1005122: `Partial shortage, product has been discontinued`,
  key1005123: `Cancel order confirmation`,
  key1005124: `Whether to confirm the cancellation of the order, the canceled order will be moved to the "canceled" list, the order information cannot be directly restored, please operate with caution!`,
  key1005125: `All`,
  key1005126: `Failed to issue logistics`,
  key1005127: `Please enter a third-party tracking number, which supports multiple separate lines`,
  key1005128: `Please enter the waybill number, which supports multiple separate lines`,
  key1005129: `Pending`,
  key1005130: `账户完整信息`,
  key1005131: `账户补充信息：`,
  key1005132: `Platform:`,
  key1005133: `Distribution Order Status:`,
  key1005134: `Store:`,
  key1005135: `Is there a refund:`,
  key1005136: `Platform order abnormal status:`,
  key1005137: `Distribution Order Exception Status:`,
  key1005138: `Execution Shipment Status:`,
  key1005139: `Export platform orders`,
  key1005140: `Batch Mark Non`,
  key1005141: `Execute shipment`,
  key1005142: `Batch order acceptance`,
  key1005143: `Mark as shipped`,
  key1005144: `Standard shipping settings`,
  key1005145: `Non`,
  key1005146: `After execution of the shipment,`,
  key1005147: `The status of the Temu order will be automatically transferred, and the checked order will be sent as a logistics package.`,
  key1005148: `Confirming pass`,
  key1005149: `Fulfillment AliExpress fully managed JIT orders, due to more restrictions on JIT orders, in the event of platform penalties,`,
  key1005150: `Will be handled in accordance with the after-sales terms, no additional compensation, please confirm whether to receive the order?`,
  key1005151: `Platform order information`,
  key1005152: `PO Number:`,
  key1005153: `Platform order product information`,
  key1005154: `Whether to label shipment:`,
  key1005155: `Order quantity:`,
  key1005156: `Buyer's note:`,
  key1005157: `Platform Order Exception`,
  key1005158: `Distribution Order Information`,
  key1005159: `Ali International Station Order`,
  key1005160: `Shipping logistics:`,
  key1005161: `Distribution Order Commodity Information`,
  key1005162: `Distribution Order Status`,
  key1005163: `Distribution Order Amount`,
  key1005164: `Abnormal distribution order`,
  key1005165: `Out of stock, product has been discontinued`,
  key1005166: `Match products`,
  key1005167: `Process orders`,
  key1005168: `Confirm waiting`,
  key1005169: `Taking orders`,
  key1005170: `Modify Logistics`,
  key1005171: `Collecting goods`,
  key1005172: `Pending shipment`,
  key1005173: `Shipment executed`,
  key1005174: `Marked for shipment`,
  key1005175: `Platform order has been cancelled`,
  key1005176: `Unmatched goods`,
  key1005177: `Failed to match logistics`,
  key1005178: `PO Number`,
  key1005179: `Affiliated platform`,
  key1005180: `Shop Name`,
  key1005181: `Platform order label shipping status`,
  key1005182: `Order Original SKU * Quantity`,
  key1005183: `Is there a refund for platform orders`,
  key1005184: `Platform order abnormal status`,
  key1005185: `Customer ID`,
  key1005186: `Customer Name`,
  key1005187: `Buyer Notes`,
  key1005188: `Distribution Order Number`,
  key1005189: `Distribution order SKU information`,
  key1005190: `Distribution Order Creation Time`,
  key1005191: `Distribution Order Shipment Time`,
  key1005192: `Distribution Order Delivery Time`,
  key1005193: `Pending orders`,
  key1005194: `Matching Logistics`,
  key1005195: `Delivery failed`,
  key1005196: `To be recorded in IOSS`,
  key1005197: `待匹配仓库`,
  key1005198: `Customer Replacement Address`,
  key1005199: `Customer Mark Cancel`,
  key1005200: `You can enter the platform order number, PO number, distribution order number, parcel number, buyer ID, buyer name, SKU, platform product code`,
  key1005201: `You can enter the platform order number, distribution order number, package number, buyer ID, buyer name, SKU, platform product code`,
  key1005202: `Please enter the platform order number, which supports multiple commas or line breaks for separation`,
  key1005203: `Please enter a PO document number. Multiple commas or line breaks are supported.`,
  key1005204: `Please tick the data to be marked as shipped first!`,
  key1005205: `Marked for shipment, please refresh the page later to view.`,
  key1005206: `Please tick the data that you want to place an order for first!`,
  key1005207: `Partially failed to place orders`,
  key1005208: `Orders from different stores cannot be shipped simultaneously!`,
  key1005209: `Only orders awaiting shipment can be shipped. Please reselect!`,
  key1005210: `Successfully executed shipment!`,
  key1005211: `Manual orders and platform orders cannot be shipped simultaneously. Please proceed in batches!`,
  key1005212: `Sample orders and shipment orders cannot be shipped simultaneously. Please proceed in batches!`,
  key1005213: `Please check the data to be shipped first!`,
  key1005214: `Confirm waiting order`,
  key1005215: `We are currently confirming and waiting for the order. If the order does not arrive on time, a second reminder will still be sent. Please confirm whether to wait for the order to arrive?`,
  key1005216: `Manual marking of shipments`,
  key1005217: `The system failed to mark the shipment to the platform. Please go to the platform backend to mark the shipment. After marking the shipment in the platform backend, you can click the confirm marked shipment button here to eliminate the abnormal status of this order.`,
  key1005218: `Confirm marked shipment`,
  key1005219: `Only pending orders can be accepted!`,
  key1005220: `Please check the data that needs to be accepted first!`,
  key1005221: `Confirm order acceptance`,
  key1005222: `Order accepted successfully!`,
  key1005223: `Some orders received are abnormal`,
  key1005224: `Sample order`,
  key1005225: `Goods order`,
  key1005226: `Regular orders`,
  key1005227: `The platform order has been canceled. Please go to Ali International to cancel the order. After cancellation, please manually mark the order as processed.`,
  key1005228: `Can search product name,`,
  key1005229: `Keyword search:`,
  key1005230: `Export stock document`,
  key1005231: `Stock order information`,
  key1005232: `Procurement status:`,
  key1005233: `Stock up warehouse:`,
  key1005234: `Purchasing Product Information`,
  key1005235: `Total value of goods:`,
  key1005236: `Time information`,
  key1005237: `Cancellation time:`,
  key1005238: `Partially discontinued`,
  key1005239: `Cancel transaction`,
  key1005240: `Out of stock - restocking in progress`,
  key1005241: `Out of stock - discontinued`,
  key1005242: `Procurement status`,
  key1005243: `Warehouse`,
  key1005244: `Cancelled amount`,
  key1005245: `Refunded amount`,
  key1005246: `SKU unit price`,
  key1005247: `SKU paid unit price`,
  key1005248: `Cancel transaction prompt`,
  key1005249: `Canceling the stock order, please confirm if you want to cancel it?`,
  key1005250: `Cancel transaction successful!`,
  key1005251: `账户收款币种对应金额：`,
  key1005252: `查看完整消息`,
  key1005253: `提交后需运营人员审核确认支付信息的有效性，确认后订单才会生效，请准确的输入支付信息。`,
  key1005254: `上传文件格式有误，限制上传图片格式为JPG、JPEG、PNG`,
  key1005255: `图片大小最大不超过5MB`,
  key1005256: `该渠道账户已被停用，请联系运营人员或选择其他支付渠道账户`,
  key1005257: `支付渠道不能为空`,
  key1005258: `运营收款账户不能为空`,
  key1005259: `编辑支付凭证`,
  key1005260: `查看支付凭证`,
  key1005261: `修改支付凭证`,
  key1005262: `支付流水图片不能为空！`,
  key1005263: `Missing barcode prompt`,
  key1005264: `None of the following products`,
  key1005265: `Information, please enter`,
  key1005266: `My product`,
  key1005267: `Try again after uploading in.`,
  key1005268: `SKU barcode`,
  key1005269: `Sample barcode`,
  key1005270: `请输入订单备注`,
  key1005271: `1, please ensure that the file content of the order commodity information and the file header field correspondence is accurate, if there is no`,
  key1005272: `Effective content, will cause the file upload failure;`,
  key1005273: `2. Currently, only xls and xlsx format files are supported.`,
  key1005274: `3、文件上传成功后，解析对应的订单内容会存入“YMS订单-待付款”状态列表，需用户二次`,
  key1005275: `The YMS order will not be created until the order is confirmed.`,
  key1005276: `Source notes can only be entered in English!`,
  key1005277: `The file format is incorrect. Only xls and xlsx files are supported.`,
  key1005278: `Total amount due`,
  key1005279: `退回原因`,
  key1005280: `Cancel payment`,
  key1005281: `取消确认`,
  key1005282: `是否确认取消该付款信息？`,
  key1005283: `Warehouse arrival details`,
  key1005284: `Successfully matched SKU`,
  key1005285: `Successfully matched SKU (manually matched)`,
  key1005286: `No match`,
  key1005287: `ali international single number:`,
  key1005288: `Distribution Number:`,
  key1005289: `launch`,
  key1005290: `Original SKU:`,
  key1005291: `Logistics service:`,
  key1005292: `Price and quantity`,
  key1005293: `Decoded SKU:`,
  key1005294: `Matching SKU failed`,
  key1005295: `Manual matching`,
  key1005296: `Rematch`,
  key1005297: `SKU has been discontinued, please cancel or replace the product`,
  key1005298: `The product is null, which cannot be associated.`,
  key1005299: `Total cost = Product + Handling fee + Logistics fee`,
  key1005300: `Handling fee = 3 + (Quantity of products - 1) * 0.5`,
  key1005301: `Total cost（`,
  key1005302: `Commodity fee（`,
  key1005303: `Processing fee（`,
  key1005304: `Logistics fees（`,
  key1005305: `Domestic express delivery fee（`,
  key1005306: `Customs clearance fees（`,
  key1005307: `Ali International Order Information`,
  key1005308: `Shipping address information`,
  key1005309: `Platform order address information`,
  key1005310: `No more data available at the moment`,
  key1005311: `Warehouse for shipment`,
  key1005312: `Domestic express tracking number`,
  key1005313: `Download Order`,
  key1005314: `Marked for shipment`,
  key1005315: `To be marked for shipment`,
  key1005316: `The entire order has been cancelled`,
  key1005317: `The order has been partially cancelled`,
  key1005318: `Ali international merchants have delivered goods`,
  key1005319: `Expected delivery time:`,
  key1005320: `, discontinued`,
  key1005321: `Date Created:`,
  key1005322: `New Template`,
  key1005323: `Delete Template Confirmation`,
  key1005324: `Confirm to delete the template. The information cannot be restored after deletion. Please operate carefully!`,
  key1005325: `Order multi-SKU import method:`,
  key1005326: `One per line`,
  key1005327: `Multiple columns in the same row`,
  key1005328: `Template Field Mapping`,
  key1005329: `When there are many commodities, the SKU column title of the second commodity is incremented by the number of the SKU column title of the first commodity;`,
  key1005330: `For example, the original column title of commodity SKU is "commodity SKU". if there are multiple commodities, then the first`,
  key1005331: `The title name of the SKU column is set to "SKU1",`,
  key1005332: `Second item SKU column header name`,
  key1005333: `Set to "commodity SKU2", increasing by this;`,
  key1005334: `Note: only the first SKU column title needs to be entered in the input box, "quantity`,
  key1005335: `The content is the same;`,
  key1005336: `When the same order number appears in the file:`,
  key1005337: `considered to be an order containing multiple SKUs`,
  key1005338: `Error`,
  key1005339: `文件中订单号在系统相同备注中已存在时：`,
  key1005340: `ignore and import other orders`,
  key1005341: `Third Party Order Number`,
  key1005342: `Distribution to (country)`,
  key1005343: `Address`,
  key1005344: `Consignee's Name`,
  key1005345: `Email address`,
  key1005346: `IOSS No.`,
  key1005347: `YMS Order Information`,
  key1005348: `Content Nature`,
  key1005349: `Processing method`,
  key1005350: `mapping`,
  key1005351: `Excelcolumn heading of Excel`,
  key1005352: `Mapped Excel file column header cannot be empty!`,
  key1005353: `Stock warehouse`,
  key1005354: `YMS Warehouse:`,
  key1005355: `Automatic payment after placing an order`,
  key1005356: `是否确定提交该备货单？`,
  key1005357: `当前您已勾选”下单后自动付款`,
  key1005358: `，当您账户余额充足时，会自动使用账户余额进行支`,
  key1005359: `付，如果账户余额不足，则会为您创建“待付款”状态的备货单，需要您进行支付后，平台才会进行备货单履约`,
  key1005360: `Please select a stocking warehouse!`,
  key1005361: `账户余额不足，以为您创建"待付款”状态备货单`,
  key1005362: `账户余额充足，支付成功`,
  key1005363: `Configuration method:`,
  key1005364: `Global unified configuration`,
  key1005365: `Configure by platform`,
  key1005366: `You can choose any of the following YMS order nodes as the standard shipping nodes:`,
  key1005367: `Logistics:`,
  key1005368: `This information needs to be confirmed with the buyer. If not filled in, no additional shipping information will be pasted on the package`,
  key1005369: `Consignee:`,
  key1005370: `Please fill in the recipient`,
  key1005371: `Receiving Group:`,
  key1005372: `Please fill in the receiving group`,
  key1005373: `The shipping cost will be attached to the first order that executes the shipment`,
  key1005374: `Step 1: Choose logistics`,
  key1005375: `Step 2: Fill in the receiving information`,
  key1005376: `Logistics cannot be empty`,
  key1005377: `Please fill in the logistics tracking number`,
  key1005378: `Step 1: Upload packaging labels`,
  key1005379: `Step 2: Fill in the logistics tracking number`,
  key1005380: `Package number`,
  key1005381: `Package weight (g)`,
  key1005382: `Total number of package items`,
  key1005383: `Upload packaging labels`,
  key1005384: `No need to upload tags`,
  key1005385: `Please upload the packaging label first!`,
  key1005386: `The logistics tracking number cannot be empty!`,
  key1005387: `Delivery scope:`,
  key1005388: `Account name cannot be empty`,
  key1005389: `Account Authorization`,
  key1005390: `Account name:`,
  key1005391: `Associated stores`,
  key1005392: `Platform Store:`,
  key1005393: `Not Enabled`,
  key1005394: `Enabling`,
  key1005395: `Account, please confirm before proceeding.`,
  key1005396: `Deactivate this`,
  key1005397: `After the account is completed, the associated stores will not be able to distribute logistics. Please confirm whether to deactivate the account?`,
  key1005398: `Logistics Channel Code`,
  key1005399: `Logistics type`,
  key1005400: `Disable logistics reminder`,
  key1005401: `After disabling this logistics, it will not be applicable for distribution. Please confirm whether to disable it?`,
  key1005402: `Enable logistics reminder`,
  key1005403: `Enabling logistics, please confirm before proceeding.`,
  key1005404: `Enable channel prompts`,
  key1005405: `Enabling logistics channel, please confirm before proceeding.`,
  key1005406: `Enable account prompt`,
  key1005407: `Deactivate channel prompt`,
  key1005408: `After disabling this logistics channel, it will not be applicable for distribution. Please confirm whether to disable it?`,
  key1005409: `Deactivate account prompt`,
  key1005410: `Account`,
  key1005411: `Please upload your business license according to the following requirements. If you do not upload it as required, your application may be rejected`,
  key1005412: `Please ensure that the font of the business license image is clear, the edges and corners are complete, and there are no irrelevant watermarks. Do not intentionally cover up the QR code or information. If the shooting is blurry, it is recommended to upload a scanned copy.`,
  key1005413: `Please check the Unified Social Credit Identifier/registration number you filled in by yourself in the national enterprise credit information publicity system to ensure that your subject information can be found, and the registration status is "existing (in operation, opening, registered)".`,
  key1005414: `If there has been a change in the company name or legal representative information, please upload a business license image with the latest approval date.`,
  key1005415: `Example image`,
  key1005416: `Please upload according to the following requirements. Failure to meet the following specifications will result in review rejection`,
  key1005417: `Please ensure that the name on your uploaded legal representative's ID card matches the name of the legal representative on your business license.`,
  key1005418: `Please confirm that the font of the legal representative's ID card is clear, without reflection, with complete edges and corners, and without any irrelevant watermarks or intentional covers.`,
  key1005419: `YMS platform privacy policy`,
  key1005420: `Please fill in the full name of the company on the business license`,
  key1005421: `Please select [jpg, jpeg, png]`,
  key1005422: `Company name cannot be empty`,
  key1005423: `Corporate Identity Card National Emblem Face`,
  key1005424: `Face of legal representative witness`,
  key1005425: `Please upload the national emblem of the legal person's ID card first`,
  key1005426: `Please upload the profile of the legal representative witness first`,
  key1005427: `Specification for Identity Card of Legal Representative`,
  key1005428: `Business License Specification`,
  key1005429: `Graphic verification code:`,
  key1005430: `Please enter the SMS verification code`,
  key1005431: `Set password:`,
  key1005432: `8-26 digits (it is recommended to use two or more character combinations)`,
  key1005433: `Confirm password:`,
  key1005434: `Please enter the password again`,
  key1005435: `This phone number has already been registered as a distributor. If you continue, it will be considered as using this account to join the supplier`,
  key1005436: `I have read and agree`,
  key1005437: `The password length must be 8-26 digits`,
  key1005438: `Please fill in your phone number and graphic verification code first!`,
  key1005439: `Account registration successful`,
  key1005440: `Your account has been successfully registered. Please continue to improve the registration information and complete the registration!`,
  key1005441: `Please check first that I have read and agree`,
  key1005442: `Setting password cannot be empty`,
  key1005443: `The set password and confirmed password are inconsistent`,
  key1005444: `Fill in account information`,
  key1005445: `Fill in enterprise information`,
  key1005446: `Review of entry materials`,
  key1005447: `login was successful`,
  key1005448: `Existing account?`,
  key1005449: `log in immediately`,
  key1005450: `Submitted successfully`,
  key1005451: `Your application materials have been submitted, and we usually take 3 working days`,
  key1005452: `Please be patient and wait for the internal audit to be completed. If you have any questions, please`,
  key1005453: `Contact online customer service!`,
  key1005454: `Entry information review in progress, please be patient and wait~`,
  key1005455: `After approval, the supplier backend can be used normally. If you have`,
  key1005456: `If you have any questions, please contact online customer service!`,
  key1005457: `Your application has not been approved`,
  key1005458: `Please verify and modify the following information before resubmitting.`,
  key1005459: `Revise`,
  key1005460: `Everything is ready`,
  key1005461: `Your application has been approved!`,
  key1005462: `Go and publish the product`,
  key1005463: `Exiting`,
  key1005464: `Account login`,
  key1005465: `Mobile phone number/email/travel account`,
  key1005466: `Password`,
  key1005467: `Verification code`,
  key1005468: `SMS login`,
  key1005469: `SMS verification code`,
  key1005470: `Authorize now`,
  key1005471: `Retrieve password`,
  key1005472: `Mobile phone number/email/travel account cannot be empty`,
  key1005473: `SMS verification code cannot be empty`,
  key1005474: `Incorrect phone number/email format`,
  key1005475: `Mobile number registration`,
  key1005476: `Email registration`,
  key1005477: `Email (optional)`,
  key1005478: `Thoroughfare login`,
  key1005479: `Welcome to register`,
  key1005480: `register`,
  key1005481: `Already have an account, please log in`,
  key1005482: `Login Tongtool account`,
  key1005483: `If you already have a Tongtool account, you can log in with your Tongtool account directly.`,
  key1005484: `Login will activate YMS Mall distribution permissions for you.`,
  key1005485: `registration success!`,
  key1005486: `set password`,
  key1005487: `Please enter email / verified phone number`,
  key1005488: `Go to log in`,
  key1005489: `Mobile phone number/email cannot be empty`,
  key1005490: `confirm password can not be blank`,
  key1005491: `Submit`,
  key1005492: `Home page`,
  key1005493: `Supplier Entry Guidelines`,
  key1005494: `Immediate entry`,
  key1005495: `Welcome to join the YMS distribution platform`,
  key1005496: `YMS is a leading cross-border clothing one-stop distribution platform in China. The founding team members have been deeply involved in the cross-border industry for more than ten years and have rich and high-quality cross-border e-commerce supply chain resources,`,
  key1005497: `We can provide integrated services such as cargo collection, warehousing, logistics, distribution, etc. We can help source factories, brand agents, industry and trade integrated enterprises and other types of suppliers expand their overseas sales,`,
  key1005498: `Adhering to the business philosophy of "win-win cooperation and common development", we sincerely invite high-quality cross-border e-commerce suppliers to join us and assist you in your overseas market.`,
  key1005499: `Our Advantages`,
  key1005500: `Investment category`,
  key1005501: `Supplier Qualification Requirements`,
  key1005502: `Enterprise Information`,
  key1005503: `Entry materials`,
  key1005504: `Material requirements`,
  key1005505: `Enterprise business license with three certificates in one;`,
  key1005506: `Ensure that the enterprise applying to settle in is not listed in the "Abnormal Business List" and that the sales`,
  key1005507: `The goods are within its business scope;`,
  key1005508: `The deadline for expiration should be greater than 3 months.`,
  key1005509: `ID card`,
  key1005510: `Corporate ID card;`,
  key1005511: `The deadline for expiration should be greater than 1 month.`,
  key1005512: `Support bank card or Alipay.`,
  key1005513: `Product requirements`,
  key1005514: `Product Qualification`,
  key1005515: `Legal and compliant products`,
  key1005516: `The promised goods are legal and compliant, and comply with the laws, regulations, and quality of the sales destination`,
  key1005517: `Quantity standards and YMS shelving specifications;`,
  key1005518: `For patented products, a sales area patent certificate is required;`,
  key1005519: `Suppliers with a brand need to provide a brand authorization letter;`,
  key1005520: `Having stable supply channels and willing to follow platform rules.`,
  key1005521: `Sincere long-term cooperation`,
  key1005522: `Ensure the lowest commodity prices on the entire network;`,
  key1005523: `Agree with YMS development philosophy and have a sincere and long-term cooperation attitude.`,
  key1005524: `order fulfillment `,
  key1005525: `Ensure that the goods arrive at the YMS warehouse within the delivery period.`,
  key1005526: `Entry process`,
  key1005527: `Instantly enter YMS suppliers and enjoy a new mode of rapid distribution`,
  key1005528: `YMS sincerely invites you to join us and become our high-quality partner. Click to join now!`,
  key1005529: `special`,
  key1005530: `Focus on cross-border e-commerce`,
  key1005531: `Over 10 years of professional cross-border e-commerce clothing product team`,
  key1005532: `wide`,
  key1005533: `Covering mainstream e-commerce platforms`,
  key1005534: `Distribution stores cover mainstream e-commerce platforms such as Amazon and eBay`,
  key1005535: `collection`,
  key1005536: `Centralized processing of orders`,
  key1005537: `Suppliers only need to ship to the YMS consolidation warehouse`,
  key1005538: `Exemption`,
  key1005539: `Free storage service fee`,
  key1005540: `Receive free warehousing services upon entry`,
  key1005541: `Quick delivery`,
  key1005542: `One stop delivery system hosting, saving storage energy and controlling labor costs`,
  key1005543: `Women's clothing`,
  key1005544: `Men's clothing`,
  key1005545: `Girls`,
  key1005546: `Boys`,
  key1005547: `Infants and children`,
  key1005548: `Product research`,
  key1005549: `YMS assists in conducting product research to determine that the product meets market demand and has a competitive price advantage.`,
  key1005550: `Register an account`,
  key1005551: `Fill in account information as required and register a corporate account.`,
  key1005552: `Fill in the entry information`,
  key1005553: `Submit business license, legal representative ID card, and fill in relevant store information.`,
  key1005554: `After submitting the entry materials, YMS will complete the review within 3 working days.`,
  key1005555: `Completion of settlement`,
  key1005556: `After the review is approved, the supplier logs in to the backend and publishes the product for sale.`,
  key1005557: `Please describe your problem, eg: Logistics`,
  key1005558: `Video tutorial`,
  key1005559: `Yunmai Supply Chain`,
  key1005560: `Function Introduction`,
  key1005561: `Introduction to Cloud Selling Supply Chain Functions`,
  key1005562: `Register for Cloud Selling Supply Chain`,
  key1005563: `Store authorization`,
  key1005564: `self service`,
  key1005565: `Changing your password regularly helps keep your account secure`,
  key1005566: `Modify mobile number`,
  key1005567: `The mobile phone number is involved in key businesses such as login and authentication to ensure account security`,
  key1005568: `The mailbox involves key businesses such as login and message notification, and ensures account security`,
  key1005569: `search"`,
  key1005570: `” related results, a total of`,
  key1005571: `No matching search results`,
  key1005572: `Help Home`,
  key1005573: `Log in`,
  key1005574: `Login to view prices`,
  key1005575: `Daily update`,
  key1005576: `Trendy women's clothing`,
  key1005577: `Fashion men's clothing`,
  key1005578: `Boutique children's clothing`,
  key1005579: `Hot selling products`,
  key1005580: `Good Product Recommendation`,
  key1005581: `YMS Mall (www.yms. com) is a supply chain service platform established by Shenzhen Yunmai Supply Chain Management Co., Ltd. that focuses on cross-border e-commerce clothing categories. The platform integrates the source factory clothing mall, the Tongtu Listing publishing platform, the Tongtu ERP platform, and the Tongtu logistics platform, providing an efficient order fulfillment link between cross-border e-commerce sellers and the source factory. Customer orders from foreign sales terminals are directly shipped by the source factory one by one.`,
  key1005582: `Just 3 steps to make cross-border business easier`,
  key1005583: `You just need to focus on operations, and we'll help you with the rest`,
  key1005584: `Selection`,
  key1005585: `Register a free account and subscribe to products at YMS Mall`,
  key1005586: `Publication`,
  key1005587: `Directly connected to the listing and publishing system, quick listing to multi platform stores such as eBay and Amazon with just one click`,
  key1005588: `After the store issues an order, you can place an order in the YMS mall, supporting one piece shipping`,
  key1005589: `YMS Mall cross-border e-commerce, focusing on clothing distribution`,
  key1005590: `Now you can publish thousands of clothing products from YMS Mall to major overseas platforms, thereby winning more sales opportunities`,
  key1005591: `Massive product selection`,
  key1005592: `40000+clothing SKUs, 1000+monthly updates`,
  key1005593: `0 inventory risk`,
  key1005594: `No need for domestic stock preparation, zero inventory risk`,
  key1005595: `No financial pressure`,
  key1005596: `First issue documents and then pay to improve Asset turnover`,
  key1005597: `You just need to focus on product sales, and even if you only sell one product, we will kindly help you ship it`,
  key1005598: `Quick listing to multiple platforms`,
  key1005599: `Private label logo makes customers more reliable`,
  key1005600: `Private label can be identified through packages and packages to make customers more reliable`,
  key1005601: `Automatic tracking of orders`,
  key1005602: `The sales order system automatically tracks customer order details without the need to copy and paste them`,
  key1005603: `Fast delivery with guaranteed delivery time`,
  key1005604: `Open API interface`,
  key1005605: `YMS Mall provides an open interface that enables various functions such as product management, order management, and warehouse management`,
  key1005606: `Distributor Business`,
  key1005607: `1) Service content: Provide one-stop shipping services for cross-border e-commerce clothing sellers' orders on platforms such as eBay, Walmart, AliExpress, Wish, Shopify, etc.`,
  key1005608: `2) Service process:`,
  key1005609: `Register as a distributor of YMS Mall`,
  key1005610: `Option 1: Through an ERP account (http:`,
  key1005611: `Option 2: Register and activate directly with your phone number.`,
  key1005612: `Publish sales`,
  key1005613: `Select and collect products in the YMS mall, and quickly publish them on platforms such as eBay, Walmart, and AliExpress through the Tongtu Listing system for sale.`,
  key1005614: `Order shipment`,
  key1005615: `Tongtu ERP captures platform orders, matches international logistics methods set by distributors, and distributes them to YMS Mall. The order can be fulfilled and sent to foreign customers on a commission basis.`,
  key1005616: `Supplier Business`,
  key1005617: `1) Service content: Factories in the cross-border e-commerce clothing category will settle in YMS Mall to open direct stores.`,
  key1005618: `The clothing factory contacted the supplier's investment manager to register and settle in.`,
  key1005619: `Manage stores, standardize product listings, manage inventory, and operate order shipments.`,
  key1005620: `Central warehouse warehousing business`,
  key1005621: `1) Service content: Provide warehousing services for YMS Mall suppliers.`,
  key1005622: `YMS Mall supplier opens cloud warehouse.`,
  key1005623: `The central warehouse prepares and ships goods, the supplier creates a stocking plan, affixes the YMS mall product barcode into the warehouse, and the central warehouse automatically matches the order for shipment.`,
  key1005624: `Introduction to Platform Business`,
  key1005625: `Contact address: 6th floor, Building C1, Nanshan Zhiyuan, Xueyuan Avenue, Nanshan District, Shenzhen`,
  key1005626: `Contact number: 400-0600-405`,
  key1005627: `Distributor Online Service QQ: 2853913746`,
  key1005628: `View after reaching the previous sales volume`,
  key1005629: `Address:`,
  key1005630: `current location：`,
  key1005631: `Publishing time:`,
  key1005632: `Discount`,
  key1005633: `Accumulated distribution within 30 days`,
  key1005634: `collection`,
  key1005635: `One click publishing supports the following platforms`,
  key1005636: `High quality supplier`,
  key1005637: `Price: Visible after login`,
  key1005638: `Product details`,
  key1005639: `Item parameters`,
  key1005640: `Supplier recommendations`,
  key1005641: `I currently do not have permission to view. Please contact customer service to activate permissions.`,
  key1005642: `Follow the shop`,
  key1005643: `Packing size (cm)`,
  key1005644: `US West Warehouse Inventory`,
  key1005645: `Cancel Collection Successfully`,
  key1005646: `★ Followed`,
  key1005647: `Getting Started Video`,
  key1005648: `New User Introduction Operations`,
  key1005649: `New User Registration`,
  key1005650: `Authorized Store`,
  key1005651: `One click publishing process`,
  key1005652: `One click publishing success prerequisite: Successfully authorized store`,
  key1005653: `Set store publishing template`,
  key1005654: `One click publishing rules`,
  key1005655: `Step 1: Publish Template Settings`,
  key1005656: `Publication template settings`,
  key1005657: `Step 2: Setting Publication Rules`,
  key1005658: `Publishing Rule Settings`,
  key1005659: `Step 3: One click publishing`,
  key1005660: `Company Profile`,
  key1005661: `Image Type`,
  key1005662: `One click publishing support platform:`,
  key1005663: `Favorite or not:`,
  key1005664: `Contact name cannot be empty`,
  key1005665: `Settlement Currency:`,
  key1005666: `Province/City/District:`,
  key1005667: `Warehouse configuration`,
  key1005668: `Enable warehouse management`,
  key1005669: `Shipping address information`,
  key1005670: `+Add shipping address`,
  key1005671: `You changed the submitted merchant business information, and the platform is under review......`,
  key1005672: `The business information you changed and submitted has been rejected by the platform! Please review the reasons for rejection and resubmit for review.`,
  key1005673: `View reasons for rejection >>>`,
  key1005674: `The merchant business information platform you submitted for change has been approved!`,
  key1005675: `Warehouse management not enabled`,
  key1005676: `Industrial and commercial information is already under review and modification is not supported for the time being!`,
  key1005677: `Don't save edited information after confirmation`,
  key1005678: `Close warehousing function?`,
  key1005679: `After the current warehousing function is closed, it cannot be restarted. Are you sure you want to close it?`,
  key1005680: `Warehouse management has been enabled`,
  key1005681: `Please enter the name of the account holder`,
  key1005682: `Please enter the account opening bank`,
  key1005683: `Please enter your bank card number`,
  key1005684: `Please enter ID number number`,
  key1005685: `Please enter your Alipay login number, which supports email and mobile number formats`,
  key1005686: `Please enter the name of the account holder of this Alipay account`,
  key1005687: `Please enter the ID number of the account holder of this Alipay account`,
  key1005688: `binding`,
  key1005689: `Bind Collection Account`,
  key1005690: `(Recommended)`,
  key1005691: `Replacement successful!`,
  key1005692: `Please enter your Alipay login number`,
  key1005693: `(Recommended)`,
  key1005694: `Change Log`,
  key1005695: `Bind Account`,
  key1005696: `Change account`,
  key1005697: `Replace binding`,
  key1005698: `Original record`,
  key1005699: `Record after change`,
  key1005700: `Change time`,
  key1005701: `Not added yet`,
  key1005702: `Add Collection Account`,
  key1005703: `Not currently bound`,
  key1005704: `Replacing the receiving account`,
  key1005705: `Modification failed`,
  key1005706: `Operation failed`,
  key1005707: `Role`,
  key1005708: `During the review, the subsidy amount will be displayed after the review.`,
  key1005709: `accord with`,
  key1005710: `Does not meet the existing subsidy rules, if you have any questions, please contact your`,
  key1005711: `Docking personnel`,
  key1005712: `Receiving time`,
  key1005713: `Actual quantity to be shipped`,
  key1005714: `Multiple quantity`,
  key1005715: `Sent to warehouse`,
  key1005716: `Additional goods`,
  key1005717: `Voided bill of lading`,
  key1005718: `Invalid bill of lading reminder`,
  key1005719: `After the bill of lading is invalidated, all orders in the bill of lading will be transferred to the status of waiting for resend, and the outbound order will be transferred to the status of waiting for picking. Please confirm whether to invalidate the bill of lading?`,
  key1005720: `Boxing`,
  key1005721: `Tripartite Bill of Lading No.`,
  key1005722: `Logistics tracking number`,
  key1005723: `Please enter the bill of lading number, support multiple commas or line breaks separated`,
  key1005724: `Voided successfully!`,
  key1005725: `Operator operation`,
  key1005726: `Modify stock quantity`,
  key1005727: `Modified quantity`,
  key1005728: `Treat as not modified when not entered`,
  key1005729: `Please enter the quantity of products to be modified`,
  key1005730: `Successfully modified the stock quantity!`,
  key1005731: `Purchase Doc Import`,
  key1005732: `Destination warehouse:`,
  key1005733: `1. Uploading too much stocking information at one time is easy to read and fails to time out. It is recommended to upload no more than 100 stocking information in a single batch;`,
  key1005734: `2. If there is no matching item of commodity SKU in the file content SKU, or the corresponding quantity content of SKU is not a positive integer, it will be regarded as invalid content;`,
  key1005735: `3. Only xls and xlsx format files are supported;`,
  key1005736: `4. If there is invalid content in the file, the upload will fail;`,
  key1005737: `The outbound order type cannot be empty!`,
  key1005738: `The destination warehouse cannot be empty!`,
  key1005739: `Initiate stocking`,
  key1005740: `Batch shipment`,
  key1005741: `Selected orders`,
  key1005742: `Filter result orders`,
  key1005743: `Only stock documents with issue document type of self-owned warehouse stock can be printed`,
  key1005744: `Barcode!`,
  key1005745: `Scan SKU printing`,
  key1005746: `Print the selected order barcode`,
  key1005747: `Please check the data that needs to be marked for shipment first!`,
  key1005748: `There is currently no data in the current filtering results, unable to mark shipment!`,
  key1005749: `Only orders with an outbound status of pending shipment in the current order can be shipped!`,
  key1005750: `Only orders with a stock status of pending shipment in the current order can be shipped!`,
  key1005751: `There are currently no products in the shipping desk, and the distribution list cannot be printed.`,
  key1005752: `Please check the order barcode data that needs to be printed first!`,
  key1005753: `There is currently no data available for the current filtering results! Unable to print order barcode`,
  key1005754: `Cancel stocking`,
  key1005755: `Please confirm whether to cancel stocking?`,
  key1005756: `Distribution List Format`,
  key1005757: `List format:`,
  key1005758: `Paper checklist`,
  key1005759: `Table file`,
  key1005760: `Scan and exchange orders`,
  key1005761: `Please scan/input SKU`,
  key1005762: `Please scan/input SKU:`,
  key1005763: `Batch printing:`,
  key1005764: `Close Popup`,
  key1005765: `SKU cannot be empty!`,
  key1005766: `Print quantity cannot be empty!`,
  key1005767: `Failed to get print address!`,
  key1005768: `Commodity processing status:`,
  key1005769: `Factory Name:`,
  key1005770: `Please enter a factory name`,
  key1005771: `Settlement amount:`,
  key1005772: `Commodity processing status`,
  key1005773: `Factory Name`,
  key1005774: `Reject`,
  key1005775: `To be paid by the customer`,
  key1005776: `Please enter the order number, which supports multiple commas or line breaks for separation`,
  key1005777: `Please enter the supplier SKU, which supports multiple commas or line breaks for separation`,
  key1005778: `To be processed`,
  key1005779: `In processing`,
  key1005780: `Processing completed to be shipped`,
  key1005781: `Self Shipment Order`,
  key1005782: `POD Order`,
  key1005783: `Out of stock orders need to be marked as received before shipping!`,
  key1005784: `Only orders with a pending shipment status in the current order can be shipped!`,
  key1005785: `There is currently no data available for the current filtering results, unable to operate!`,
  key1005786: `Please check the data that needs to be marked first!`,
  key1005787: `Mark arrival reminder`,
  key1005788: `After marking the arrival, the order will be transferred to pending shipment. Please confirm whether to mark the arrival?`,
  key1005789: `Only out of stock orders can be marked with arrival, please reselect!`,
  key1005790: `Only non out of stock orders can be marked as out of stock. Please reselect!`,
  key1005791: `Order confirmation`,
  key1005792: `Refusal to accept order confirmation`,
  key1005793: `We are in the process of accepting the order. After confirming the acceptance, the settlement will be based on the amount of this order. We need to wait for the customer to make payment. Please confirm if you want to accept the order?`,
  key1005794: `After rejecting the order, the order may be cancelled. Please confirm if you want to reject the order?`,
  key1005795: `Refuse to accept orders`,
  key1005796: `Successful order acceptance`,
  key1005797: `Rejected order successfully`,
  key1005798: `Shipment reminder`,
  key1005799: `The product quantity cannot be empty or 0`,
  key1005800: `The quantity must be an integer greater than 0`,
  key1005801: `Please add products first!`,
  key1005802: `Screen`,
  key1005803: `Please scan or enter a scan`,
  key1005804: `Bill of Lading Number:`,
  key1005805: `Please enter the delivery note number`,
  key1005806: `Complete addition`,
  key1005807: `The outbound order has been scanned`,
  key1005808: `Encoding Information`,
  key1005809: `Packing reminder`,
  key1005810: `The outbound tracking number you scanned is different from the warehouse where the current outbound tracking has been packed. Please scan the outbound tracking number of the same warehouse for packing.`,
  key1005811: `Please enter sku`,
  key1005812: `Print quantity cannot be empty`,
  key1005813: `Commodity management`,
  key1005814: `Publish new products`,
  key1005815: `Sales Run chart`,
  key1005816: `Hot selling product ranking list`,
  key1005817: `Statistics of shops completed this month (natural month)`,
  key1005818: `Warehouse issued`,
  key1005819: `The total amount of the order.`,
  key1005820: `Statistics completed this month (natural month)`,
  key1005821: `The total number of orders.`,
  key1005822: `Last month's sales revenue`,
  key1005823: `Order quantity this month (orders)`,
  key1005824: `Last month's order quantity`,
  key1005825: `Yesterday's order volume`,
  key1005826: `Today's order volume`,
  key1005827: `Total number of SPUs on sale`,
  key1005828: `Calculate the total number of SPUs on sale, excluding SPUs in suspended or temporary storage status.`,
  key1005829: `Total number of SKUs on sale`,
  key1005830: `Calculate the total number of SKUs on sale, excluding SKUs in suspended or temporary storage status.`,
  key1005831: `Number of subscribed products (SPU)`,
  key1005832: `Count the total number of products subscribed by distributors, and only calculate once for multiple distributors subscribing to the same product.`,
  key1005833: `Number of subscriptions this month`,
  key1005834: `Number of subscriptions yesterday`,
  key1005835: `Number of subscriptions today`,
  key1005836: `Calculate the total number of SPUs that have been listed on the platform by distributors, and calculate the same SPU with different items only once.`,
  key1005837: `Calculate the total number of SPUs published by distributors, based on the number of items.`,
  key1005838: `Delivery time in the past 30 days (hours)`,
  key1005839: `Calculate the average time taken for all orders in the past 30 days from waiting to be picked to being shipped (courier pickup)`,
  key1005840: `Delivery time in the past 7 days`,
  key1005841: `Delivery time in the past 3 days`,
  key1005842: `On sale`,
  key1005843: `Sold out`,
  key1005844: `Rejected`,
  key1005845: `Last 7 days`,
  key1005846: `In the past 30 days`,
  key1005847: `Sales amount (CNY): ¥`,
  key1005848: `Order quantity (order):`,
  key1005849: `Central Warehouse`,
  key1005850: `Inventory Details Log`,
  key1005851: `bill`,
  key1005852: `Bill of Lading`,
  key1005853: `Listing/Discontinuation`,
  key1005854: `Please enter Chinese title`,
  key1005855: `Approval Status:`,
  key1005856: `Batch price change`,
  key1005857: `Batch listing/discontinuation`,
  key1005858: `Export Product`,
  key1005859: `Obtain 1688 items`,
  key1005860: `Collect 1688 items`,
  key1005861: `Print barcode`,
  key1005862: `1. After importing the sales price, the original price will be overwritten, please import carefully;`,
  key1005863: `4. After the import operation, please check the import result in "Import/Export Task - Import View" later.`,
  key1005864: `1. According to the SKU dimension for listing/discontinuation, after discontinuation, distributors will not be able to continue placing orders;`,
  key1005865: `sku, supports multiple row-separated`,
  key1005866: `Updated on:`,
  key1005867: `Please enter the supplier sku, support multiple lines separated`,
  key1005868: `Modify the price`,
  key1005869: `Import Product`,
  key1005870: `Name of central warehouse`,
  key1005871: `Allocated quantity of central warehouse`,
  key1005872: `Available quantity of central warehouse`,
  key1005873: `Allocated quantity of self owned warehouses`,
  key1005874: `Available quantity of self owned warehouses`,
  key1005875: `Supply price (yuan)`,
  key1005876: `This product is currently being used by a mapped product and cannot be deleted`,
  key1005877: `SKU does not exist`,
  key1005878: `This SKU has inventory and cannot be deleted`,
  key1005879: `The system is busy, please try again`,
  key1005880: `Select classification:`,
  key1005881: `template:`,
  key1005882: `Step 1: Download Template`,
  key1005883: `Step 2: Upload materials`,
  key1005884: `When importing data and discovering the same SPU:`,
  key1005885: `1. Please follow the import instructions in the template to upload product information.`,
  key1005886: `2. Only support extension:. zip.`,
  key1005887: `3. When there are exceptions in the imported data, the data import fails.`,
  key1005888: `5. It is currently not possible to import or overwrite assembled products.`,
  key1005889: `The file size exceeds the limit, with a maximum of 500M`,
  key1005890: `Please select a category first!`,
  key1005891: `Successfully downloaded the template!`,
  key1005892: `Acquisition type:`,
  key1005893: `Select a store:`,
  key1005894: `Product link:`,
  key1005895: `Please enter the 1688 product link, such as: https://detail.1688.com/offer/673838787879.html Support for multiple row separations`,
  key1005896: `Please select 1688 product creation time`,
  key1005897: `obtain`,
  key1005898: `The mapping will be replenished as soon as possible.`,
  key1005899: `Matching mapping failed,`,
  key1005900: `SKU Duplicate`,
  key1005901: `The time range cannot be empty`,
  key1005902: `Store cannot be empty`,
  key1005903: `1688 store not bound`,
  key1005904: `1688 store is not currently bound. Please bind and try again.`,
  key1005905: `Failed to obtain product`,
  key1005906: `Failed to obtain the product section`,
  key1005907: `Copy failed SPU`,
  key1005908: `Matching mapping failed, you can click`,
  key1005909: `Successfully obtained product`,
  key1005910: `View products`,
  key1005911: `Successfully obtained the product, which can be found on the product page`,
  key1005912: `Product acquisition in progress, please wait!`,
  key1005913: `Product link cannot be empty!`,
  key1005914: `The specified link address must be a link starting with https://or http://!`,
  key1005915: `Class 1688:`,
  key1005916: `Batch collection`,
  key1005917: `Transactions in the past 90 days:`,
  key1005918: `Product collection in progress, please wait!`,
  key1005919: `Brand name cannot be empty`,
  key1005920: `brand name:`,
  key1005921: `Please enter brand name.`,
  key1005922: `Brand description:`,
  key1005923: `Please enter brand description.`,
  key1005924: `Brand status:`,
  key1005925: `Brand URL:`,
  key1005926: `Please enter URL of the brand.`,
  key1005927: `Brand logo:`,
  key1005928: `Add brand`,
  key1005929: `Click or drag here to upload files`,
  key1005930: `By brand status`,
  key1005931: `By brand name`,
  key1005932: `Brand Logo`,
  key1005933: `Brand name`,
  key1005934: `Brand description`,
  key1005935: `Brand website`,
  key1005936: `Brand status`,
  key1005937: `Do you want to delete the current brand?`,
  key1005938: `Editorial brand`,
  key1005939: `Price cannot be 0 or empty!`,
  key1005940: `Waiting for payment`,
  key1005941: `Original price of goods`,
  key1005942: `Goods amount (receivable):`,
  key1005943: `Subsidy amount (receivable):`,
  key1005944: `Logistics expenses (payable):`,
  key1005945: `Please enter the billing number, which supports multiple commas or line breaks for separation`,
  key1005946: `Export Bill`,
  key1005947: `Confirming the bill means that the contents and amount of the bill have been checked correctly. Please confirm the bill and then operate.`,
  key1005948: `The background will initiate the payment process and the funds will be transferred to the bound collection account.`,
  key1005949: `Settlement amount of goods (CNY)`,
  key1005950: `Collection account not bound`,
  key1005951: `The current account is not bound to a receiving account and cannot complete the receiving process. Please bind and try again.`,
  key1005952: `Unbind`,
  key1005953: `Bill number`,
  key1005954: `Bill status update time`,
  key1005955: `Chinese description:`,
  key1005956: `Export inventory (self owned warehouse)`,
  key1005957: `(Deleted)`,
  key1005958: `SKU, Product name`,
  key1005959: `fuzzy search`,
  key1005960: `SKU attributes`,
  key1005961: `Inventory`,
  key1005962: `Frozen quantity`,
  key1005963: `Quantity of defective products`,
  key1005964: `Please select the storage area.`,
  key1005965: `Export Inventory (`,
  key1005966: `central warehouse)`,
  key1005967: `Storage area:`,
  key1005968: `Own warehouse`,
  key1005969: `Failed to open picking review`,
  key1005970: `The picking list you scanned or entered【 {pickList} 】Completed`,
  key1005971: `The picking list you scanned or entered【 {pickList} 】Does not exist or the state has changed,Please refresh and try again`,
  key1005972: `The picking list you scanned or entered【 {pickList} 】There is no delivery order under`,
  key1005973: `The picking list you scanned or entered【 {pickList} 】There is no valid delivery order under`,
  key1005974: `The picking list you scanned or entered【 {pickList} 】There are common print delivery orders under`,
  key1005975: `The picking list you scanned or entered【 {pickList} 】No multi product sorting`,
  key1005976: `The picking list you scanned or entered【 {pickList} 】The delivery order has not been issued to the logistics provider successfully`,
  key1005977: `The picking list you scanned or entered【 {pickList} 】Picking not completed`,
  key1005978: `The picklist you scanned or entered was not found in the system. Please make sure that the picklist has been deleted.`,
  key1005979: `Scan/enter the pick list number to start picking review`,
  key1005980: `Picking review in progress`,
  key1005981: `Mix`,
  key1005982: `Duration`,
  key1005983: `Package progress`,
  key1005984: `Progress of products`,
  key1005985: `Team members`,
  key1005986: `Single item single item`,
  key1005987: `Single product and multiple parts`,
  key1005988: `There is an ongoing picking review, whether to enter immediately`,
  key1005989: `There is an ongoing picking review, whether to jump immediately`,
  key1005990: `Uploaded logistics`,
  key1005991: `Please choose a logistics provider.`,
  key1005992: `Outbound order details:`,
  key1005993: `Outbound order no.:`,
  key1005994: `Reference no.:`,
  key1005995: `Picking status:`,
  key1005996: `Boxing status:`,
  key1005997: `Last modification time:`,
  key1005998: `Last modifier:`,
  key1005999: `Estimated weight of the thoroughfare:`,
  key1006000: `Weighing weight in Tongtool:`,
  key1006001: `Estimated freight for the entire route:`,
  key1006002: `Shipping cost after weighing:`,
  key1006003: `Logistics provider weighs:`,
  key1006004: `Allocation list`,
  key1006005: `Other outlets`,
  key1006006: `Manufacturing`,
  key1006007: `Row state`,
  key1006008: `Reason for abnormal distribution`,
  key1006009: `SKU`,
  key1006010: `Fill in the planned transfer quantity`,
  key1006011: `Quantity shipped`,
  key1006012: `ID code`,
  key1006013: `Allocated quantity`,
  key1006014: `Unallocated quantity`,
  key1006015: `Assign storage bin`,
  key1006016: `Allocate batches`,
  key1006017: `Allocate finish time`,
  key1006018: `New order`,
  key1006019: `Partial allocation`,
  key1006020: `Allocation complete`,
  key1006021: `Full shipped`,
  key1006022: `Order completed`,
  key1006023: `unboxed `,
  key1006024: `Details failed to load`,
  key1006025: `Packing operations during central warehouse stocking:`,
  key1006026: `Automatic sorting function for multi-product package:`,
  key1006027: `Conduct packing during picking review:`,
  key1006028: `This setting is invalid for the supplier stocking process`,
  key1006029: `Do I need to weigh the package during picking review?`,
  key1006030: `Mark the packaged package in the packaging operation as shipped?`,
  key1006031: `open`,
  key1006032: `Print waybill while sorting`,
  key1006033: `Not weighing`,
  key1006034: `Sorting rack settings (available only when multi-item multi-package automatic sorting function is turned on)`,
  key1006035: `sort by SKU character in ascending order`,
  key1006036: `Please choose at least one picking rack.`,
  key1006037: `outbound order no. or tracking no.`,
  key1006038: `The waybill number scanned by some logistics companies will have an extension or suffix, so that the system cannot recognize the waybill number, and the replacement can cut off the corresponding replacement or suffix.`,
  key1006039: `Size:`,
  key1006040: `Warning of abnormal weighing`,
  key1006041: `Local shipments`,
  key1006042: `Outbound after scanning`,
  key1006043: `Switch to bulk outbound`,
  key1006044: `Volume`,
  key1006045: `The weighing unit is:`,
  key1006046: `Automatic mark shipped`,
  key1006047: `Actual weight:`,
  key1006048: `Cut off`,
  key1006049: `Already issued`,
  key1006050: `Bulk mark shipped`,
  key1006051: `Clear`,
  key1006052: `Need to weigh`,
  key1006053: `Required size`,
  key1006054: `Weighing exception reminder settings`,
  key1006055: `When the products in the package are:`,
  key1006056: `And the error between package weight and commodity information weight is greater than or equal to`,
  key1006057: `You will be prompted`,
  key1006058: `Shipping freight exception settings`,
  key1006059: `Scan shipment order by order to calculate freight greater than`,
  key1006060: `Product weight subtotal (g):`,
  key1006061: `Total weight(g):`,
  key1006062: `Continue to ship`,
  key1006063: `Cancel mark shipped`,
  key1006064: `Parcel(s)`,
  key1006065: `Total Weight`,
  key1006066: `Derive Logistics and Confirm Excel`,
  key1006067: `Product weight`,
  key1006068: `Scanned`,
  key1006069: `Mark shipped success`,
  key1006070: `This package already exists`,
  key1006071: `Measuring Size`,
  key1006072: `The package number cannot be empty`,
  key1006073: `No authority`,
  key1006074: `Package summary information for your current filter:`,
  key1006075: `Common labels：`,
  key1006076: `Order qty`,
  key1006077: `Number of SKU`,
  key1006078: `Opening abnormal multi-item picking operation`,
  key1006079: `The picking list you scanned or entered【 {pickList} 】It is not found in the system. Please confirm whether the picking list has been deleted or the picking order number is entered incorrectly。`,
  key1006080: `The picklist you scan or enter is not a multi-item multi-item picklist and cannot open the picking operation.`,
  key1006081: `Each operator of the system is allowed to open only one picking operation at the same time, sorry that you can't open the picking operation on the picklist.`,
  key1006082: `The pick list ({picklist}) you scan or enter is picking. Each picklist allows only one operator to do picking at the same time and cannot be reopened.`,
  key1006083: `The pick list ({picklist}) you scan or enter has been sorted, and you can reopen the picking operation on the picklist.`,
  key1006084: `The pick list [{pickList}] you scanned or entered is undergoing picking review, and the sorting operation cannot be started.`,
  key1006085: `The picking list [{pickList}] you scanned or entered has completed the picking review, and the sorting operation cannot be started.`,
  key1006086: `There are common print delivery orders under the pick list 【 {pickList} 】 you scanned or entered.`,
  key1006087: `Scanning/Inputting Order Number to Start Multi-item Picking`,
  key1006088: `On-going picking operations`,
  key1006089: `The pick basket cannot find the only pick list that is not packaged.`,
  key1006090: `There is no pick list to be completed in the pick basket.`,
  key1006091: `There are unfinished picking job opened by you.`,
  key1006092: `The start time of picking operation:`,
  key1006093: `Misoperation.I don't need to re-sort.`,
  key1006094: `Yes, I need to re-sort this order.`,
  key1006095: `Picking state`,
  key1006096: `Picking`,
  key1006097: `Picking completed`,
  key1006098: `Operator`,
  key1006099: `No permission`,
  key1006100: `Partial picking`,
  key1006101: `Waiting for sorting`,
  key1006102: `Waiting for review`,
  key1006103: `Reviewing`,
  key1006104: `Reviewed`,
  key1006105: `Normal printing`,
  key1006106: `See delivery order`,
  key1006107: `Picking reminder`,
  key1006108: `Please confirm whether to cancel`,
  key1006109: `Successfully exported!`,
  key1006110: `Picking list generation time:`,
  key1006111: `Single-product rules saving`,
  key1006112: `Multi-product rules saving`,
  key1006113: `Single-product rules`,
  key1006114: `Multi-product rules`,
  key1006115: `Merge rules`,
  key1006116: `time setting`,
  key1006117: `Fixed cycle`,
  key1006118: `Every time:`,
  key1006119: `Hour generate picking order`,
  key1006120: `Daily time`,
  key1006121: `every day:`,
  key1006122: `Whether to turn on`,
  key1006123: `Please fill in the time`,
  key1006124: `Normal picking order`,
  key1006125: `Automatic picking waves`,
  key1006126: `Please enter the bill of lading number`,
  key1006127: `Add outbound orders in batches`,
  key1006128: `Please separate multiple entries with commas or newlines`,
  key1006129: `Export bill of lading data`,
  key1006130: `Add new box/bag`,
  key1006131: `Add Parcel`,
  key1006132: `Do you want to delete the current bill of lading number?`,
  key1006133: `Please enter the outbound order!`,
  key1006134: `The package does not exist.`,
  key1006135: `The package is not out of the warehouse`,
  key1006136: `The package has been billed`,
  key1006137: `Unpicked/printed outbound order details`,
  key1006138: `At least one of the unpicked quantities in the same outbound order must be greater than 0.`,
  key1006139: `printing time:`,
  key1006140: `Please enter the picking order no.`,
  key1006141: `Please enter the waybill number. Multiple waybill numbers can be separated by commas in English.`,
  key1006142: `Please enter the outbound order no.`,
  key1006143: `Please enter the order No.`,
  key1006144: `Please enter the customer's name.`,
  key1006145: `Please enter the customer ID.`,
  key1006146: `You can enter Order No., Outbound order no., Customer ID, Customer name or SKU.`,
  key1006147: `Multiple storage areas, separated by commas or newlines`,
  key1006148: `Is there a waybill number:`,
  key1006149: `Is there a logistics company:`,
  key1006150: `Is there a logistics provider weight:`,
  key1006151: `Is there a logistics provider freight:`,
  key1006152: `previous`,
  key1006153: `The following SKU list is a sum of the SKUs contained in the selected package. Please select the out-of-stock SKU. After marking as out-of-stock, the system will automatically restore the package to the out-of-stock state and restore the inventory of the selected SKU. In the meantime, the stock check form of selected SKU will be created to help check and correct inventory quantity.`,
  key1006154: `by Print Time`,
  key1006155: `Payment date`,
  key1006156: `By outbound order no.`,
  key1006157: `Customer ID/Name`,
  key1006158: `Mark as shipped`,
  key1006159: `Please select package`,
  key1006160: `mark as outbound`,
  key1006161: `Mark as outbound (The system will mark all the selected packages as outbound)`,
  key1006162: `The system will mark the package that matches your selection as shipped.`,
  key1006163: `Mark as outbound(All)`,
  key1006164: `When you mark packages as outbound(All), system will mark all the packages which meet current query conditions as outbound.`,
  key1006165: `Please select marked orders.`,
  key1006166: `pick baskets`,
  key1006167: `Print address label (selected)`,
  key1006168: `Packaging material`,
  key1006169: `Labels reprint`,
  key1006170: `Picking review`,
  key1006171: `No pick list being scanned`,
  key1006172: `The currently selected data has been printed!`,
  key1006173: `Box/bag management`,
  key1006174: `Scan and Pack`,
  key1006175: `Freeze distribution`,
  key1006176: `Unfreeze and distribute`,
  key1006177: `Inbound order`,
  key1006178: `Freeze order`,
  key1006179: `Product ID`,
  key1006180: `Storage bin for use`,
  key1006181: `Storage bin for receipt`,
  key1006182: `Storage bin for picking`,
  key1006183: `Source merchant:`,
  key1006184: `Identity code:`,
  key1006185: `Export outbound order data`,
  key1006186: `Failed to void outbound order`,
  key1006187: `Add outbound order`,
  key1006188: `Please enter the tracking no., multiple tracking no. can be separated by commas`,
  key1006189: `Please enter the logistics order no., multiple logistics order no. can be separated by commas`,
  key1006190: `Outbound time`,
  key1006191: `This delivery order is a supplementary order generated by the actual shipment quantity exceeding the required quantity in other delivery orders.`,
  key1006192: `Source channel`,
  key1006193: `Source merchants`,
  key1006194: `Tracking no./logistics no.`,
  key1006195: `Logistics weight/logistics freight`,
  key1006196: `Download the menu`,
  key1006197: `Associated order number`,
  key1006198: `Customer Name`,
  key1006199: `Zip code`,
  key1006200: `Print Time`,
  key1006201: `Printed by`,
  key1006202: `Actual weight`,
  key1006203: `Weight of logistics provider (g)`,
  key1006204: `organization`,
  key1006205: `Item SKU`,
  key1006206: `Item ID`,
  key1006207: `Multi-product sorting - end sorting`,
  key1006208: `Outbound quantity`,
  key1006209: `items(Picked/Unpicked)`,
  key1006210: `The new order is successful!`,
  key1006211: `Scan picking order number/issue order number/waybill number/`,
  key1006212: `Face sheet:`,
  key1006213: `The outbound order is empty and cannot be operated to end the packing!`,
  key1006214: `Please scan the bill of lading number first!`,
  key1006215: `Please enter the bill of lading number!`,
  key1006216: `Please enter the picking list number/outbound order number/waybill number!`,
  key1006217: `Do you want to delete the current outbound order number?`,
  key1006218: `(Invalid)`,
  key1006219: `Storage area group`,
  key1006220: `Quantity of picks`,
  key1006221: `ID:`,
  key1006222: `Scan SKU barcode or UPC`,
  key1006223: `Scannable SKU`,
  key1006224: `Scan the ID code:`,
  key1006225: `Scannable ID`,
  key1006226: `Mark exception`,
  key1006227: `Packaging settings`,
  key1006228: `abnormal`,
  key1006229: `Check SKU`,
  key1006230: `Package scanned`,
  key1006231: `Unpack`,
  key1006232: `Continue packaging`,
  key1006233: `Name of package`,
  key1006234: `Quantity to be packed`,
  key1006235: `Grams (g)`,
  key1006236: `Kilogram (Kg)`,
  key1006237: `Quantity to be packed`,
  key1006238: `Product weight (g)`,
  key1006239: `The current package has a SKU which has not been scanned yet.`,
  key1006240: `There is still an ID code in the current package that has not been scanned!`,
  key1006241: `The currently scanned outbound order number or waybill number or logistics business order number has been out of the warehouse!`,
  key1006242: `The ID number cannot be empty!`,
  key1006243: `The current scanned quantity cannot be greater than the quantity to be packaged!`,
  key1006244: `The sku does not exist!`,
  key1006245: `SKU cannot be empty!`,
  key1006246: `The weighing cannot be empty and must be greater than 0!`,
  key1006247: `Please scan the ID number first!`,
  key1006248: `The scanned quantity cannot be all equal to the quantity to be packaged, otherwise the exception cannot be marked!`,
  key1006249: `The exception has been successfully marked.`,
  key1006250: `The package has been scanned successfully!`,
  key1006251: `Number of reprints`,
  key1006252: `Quantity printed`,
  key1006253: `Scan completed`,
  key1006254: `Match failed`,
  key1006255: `Multiple orders separated by commas`,
  key1006256: `SKU/quantity:`,
  key1006257: `Brand:`,
  key1006258: `Delivery reminder:`,
  key1006259: `Picking list generation settings`,
  key1006260: `Category`,
  key1006261: `Picking storage area group`,
  key1006262: `Storage area`,
  key1006263: `Label name cannot be empty`,
  key1006264: `Select files to import:`,
  key1006265: `Select file`,
  key1006266: `Download template`,
  key1006267: `There is an empty Tongtool sku.`,
  key1006268: `There is an emptyskuCode`,
  key1006269: `TongdaoskuThe system does not exist`,
  key1006270: `skuCode The system does not exist`,
  key1006271: `The first journey cost must be a number`,
  key1006272: `Cancel package`,
  key1006273: `Check reset delivery order to generate an issue document again`,
  key1006274: `Reset delivery order`,
  key1006275: `This is the record after the delivery order has been reset，Will return to【Waiting for allocation】state,At the same time, the delivery order will perform the following operations：`,
  key1006276: `1.If the delivery order has been successfully distributed, it will try to call the logistics provider interface to cancel the order`,
  key1006277: `2.If the inventory has been allocated successfully in the issue document, the allocated inventory will be returned`,
  key1006278: `Code:`,
  key1006279: `Input Code Name`,
  key1006280: `Account Name:`,
  key1006281: `Please enter an authorized platform account`,
  key1006282: `Expiration time:`,
  key1006283: `Account name`,
  key1006284: `Authorization time`,
  key1006285: `Data synchronization`,
  key1006286: `Select a warehouse`,
  key1006287: `Selecting multiple warehouses will synchronize the total inventory of all warehouses.`,
  key1006288: `Synchronize warehouse`,
  key1006289: `Authorization`,
  key1006290: `New products`,
  key1006291: `Please check the warehouse first!`,
  key1006292: `Inventory =`,
  key1006293: `Main warehouse actual inventory`,
  key1006294: `Order occupancy`,
  key1006295: `Warehouse waiting quantity`,
  key1006296: `Virtual Inventory`,
  key1006297: `Procurement in transit quantity`,
  key1006298: `Sales return inventory`,
  key1006299: `Incoming warehouse inventory`,
  key1006300: `Defective inventory`,
  key1006301: `Inventory =`,
  key1006302: `Rule item cannot be empty!`,
  key1006303: `You do not have permission to access this resource, please contact the administrator`,
  key1006304: `The system is busy, please try again (the interface reports an error, please check the interface error on the console)`,
  key1006305: `The account has been disabled.`,
  key1006306: `The supplier settlement status must be Pending Settlement and`,
  key1006307: `The issue status must be issued`,
  key1006308: `The customer's settlement status must be Pending Settlement and`,
  key1006309: `Failed to confirm payment of the bill, the bill has been voided`,
  key1006310: `Category ID already exists`,
  key1006311: `Parent node does not exist`,
  key1006312: `Category name cannot contain ->`,
  key1006313: `The name cannot contain spaces.`,
  key1006314: `Maximum classification level reached`,
  key1006315: `Category name at the same level already exists`,
  key1006316: `The email is already occupied`,
  key1006317: `The phone number is already used`,
  key1006318: `The package is not collected`,
  key1006319: `No relevant outbound order`,
  key1006320: `The bill of lading is not sorted`,
  key1006321: `There are multiple packages`,
  key1006322: `The status of the bill of lading must be collected or sorted`,
  key1006323: `Existing non-sorting status`,
  key1006324: `The outbound order is not in the picked state`,
  key1006325: `The handover order is not in the packed state`,
  key1006326: `Already a customer`,
  key1006327: `Graphic verification code error`,
  key1006328: `Verification code error`,
  key1006329: `Email not registered`,
  key1006330: `Repeat page submission`,
  key1006331: `SMS failed to be sent`,
  key1006332: `Failed to query user`,
  key1006333: `Mobile phone number is empty`,
  key1006334: `wrong password`,
  key1006335: `SMS verification code verification error`,
  key1006336: `Graphic verification code is invalid`,
  key1006337: `The number of errors exceeded, please enter the graphic verification code and try again`,
  key1006338: `repeated submit`,
  key1006339: `Non-current user mailbox`,
  key1006340: `Non-current user phone`,
  key1006341: `Login failed, user does not exist`,
  key1006342: `Please log in to register a distribution account with the admin account`,
  key1006343: `Successfully created a customer account by logging in to the Tongtu account. Once approved, access the account again`,
  key1006344: `The current user, please log in after passing the review`,
  key1006345: `Product does not exist`,
  key1006346: `Customer level is already bound to supplier level`,
  key1006347: `The supplier has set the supplier level`,
  key1006348: `The queried picking order number does not exist`,
  key1006349: `Brand name already exists`,
  key1006350: `Non-draft status cannot be deleted`,
  key1006351: `The Chinese name, English name, title, picture cannot be modified pending review`,
  key1006352: `cannot contain commas`,
  key1006353: `SKU with the same attributes exists.`,
  key1006354: `Duplicate SPU or SKU`,
  key1006355: `Variable parameter commodity SKU cannot repeat with SPU`,
  key1006356: `The product is being used by the mapped product and cannot be deleted.`,
  key1006357: `The package has a replenishment order to be completed.`,
  key1006358: `The SKU does not exist.`,
  key1006359: `There is a normal picking package in the abnormal package.`,
  key1006360: `The package has been cancelled.`,
  key1006361: `Failed to mark as shipped, this package has been cancelled for recycling`,
  key1006362: `Failed to complete the packaging, the packaging status has been changed!`,
  key1006363: `It is not a picking list for multi-products one package, and the sorting operation cannot be started.`,
  key1006364: `Each operator of the system is only allowed to start one sorting operation at the same time`,
  key1006365: `The picking list is currently being sorted by someone else`,
  key1006366: `The sorting operation has been completed, and you can restart the sorting operation for the pick list.`,
  key1006367: `There are common print delivery orders.`,
  key1006368: `The pick list is not printed and the picking review cannot be started`,
  key1006369: `Pick list completed.`,
  key1006370: `There is no outbound order under the pick list.`,
  key1006371: `There is no valid outbound order under the pick order.`,
  key1006372: `There is a product note under the pick list.`,
  key1006373: `There is an ordinary-print outbound list under the pick list.`,
  key1006374: `The pick list has not been multi-product sorting.`,
  key1006375: `There is an outbound order which is not issued to the logistics provider successfully under the pick list.`,
  key1006376: `The pick list is not completed.`,
  key1006377: `Sorting operation only supports pick lists for sales.`,
  key1006378: `sku and upc cannot be repeated`,
  key1006379: `upcupc has existed`,
  key1006380: `UPCDuplicate UPC`,
  key1006381: `Duplicate SKUs`,
  key1006382: `The pick list of non-sale outbound order does not support picking review.`,
  key1006383: `The package has been intercepted.`,
  key1006384: `The user is packing other pick lists.`,
  key1006385: `No inventory can be allocated, unable to allocate`,
  key1006386: `The package has been shipped out.`,
  key1006387: `Package information does not exist`,
  key1006388: `Box No. cannot be empty.`,
  key1006389: `SKU cannot be empty`,
  key1006390: `No corresponding outbound order found`,
  key1006391: `Outbound order No. error`,
  key1006392: `The SKU does not exist.`,
  key1006393: `Pick list does not exist.`,
  key1006394: `Failed to clear scan. The package has been scanned.`,
  key1006395: `Wrong boxing quantity`,
  key1006396: `There is only one package under the picking list, which cannot be removed`,
  key1006397: `The pick list has completed the picking review and cannot be re-sorted`,
  key1006398: `The warehouse is in inventory allocation, please try again later.`,
  key1006399: `The package has no items awaiting re-picking.`,
  key1006400: `The inbound order has been closed and cannot be received!`,
  key1006401: `The current package is not printed`,
  key1006402: `The number of scanned SKU does not match the number of packages`,
  key1006403: `Did not match a suitable outbound order`,
  key1006404: `Invalid key`,
  key1006405: `The number of scanned sku is inconsistent with the number of ID codes.`,
  key1006406: `The current bill of lading status is not in packing and cannot continue packing`,
  key1006407: `The sorting status of the picking list that ends the sorting is not sorting, and the sorting cannot be ended`,
  key1006408: `Cannot be repeatedly marked as picked`,
  key1006409: `Package status has changed and operation is abnormal`,
  key1006410: `Bill of lading number does not exist`,
  key1006411: `Bill of Lading not waiting for collection/packing status,`,
  key1006412: `Cannot delete`,
  key1006413: `The current attribute is being referenced and cannot be deleted`,
  key1006414: `Property with the same name already exists`,
  key1006415: `Please stop selling the deleted SKU and try again`,
  key1006416: `The SKU with inventory cannot be deleted.`,
  key1006417: `SKU already exists.`,
  key1006418: `Order Voided`,
  key1006419: `There is a non-waiting collection state`,
  key1006420: `The number of packages received by the bill of lading is not uniform`,
  key1006421: `Illegal attributes`,
  key1006422: `The Chinese name of the color already exists`,
  key1006423: `Duplicate library code`,
  key1006424: `Duplicate library area name`,
  key1006425: `Duplicate warehouse name`,
  key1006426: `Duplicate warehouse code`,
  key1006427: `The`,
  key1006428: `The order has been exchanged and cannot be canceled.`,
  key1006429: `The operator has completed the picking, please do not submit repeatedly`,
  key1006430: `It has been sorted and is in a packed state, please do not submit repeatedly`,
  key1006431: `Has been withdrawn successfully, please do not submit repeatedly`,
  key1006432: `Duplicate location number`,
  key1006433: `The pick list is being sorted`,
  key1006434: `No match to be packed`,
  key1006435: `The current pick order status is not waiting for picking/picking status`,
  key1006436: `Picking list is not to be packaged or in package`,
  key1006437: `The status has changed, please refresh and try again!`,
  key1006438: `Only packages that have been changed can be scanned`,
  key1006439: `There are commodities under the category`,
  key1006440: `There are sub-categories under category`,
  key1006441: `Weight cannot be empty.`,
  key1006442: `When there is one sku left`,
  key1006443: `Can not be deleted`,
  key1006444: `Obtaining classification information is abnormal.`,
  key1006445: `Non-logistics face order types are not allowed to be marked out of the warehouse`,
  key1006446: `Surface single anomaly`,
  key1006447: `It cannot map deleted attributes`,
  key1006448: `There is a mapping attribute, so it is not allowed to delete`,
  key1006449: `Bill of lading has been deleted`,
  key1006450: `There are mapping attributes under the changed category, please delete the mapping attributes first`,
  key1006451: `The customer number currently being recharged does not exist`,
  key1006452: `Under the same platform, the same site cannot exist/without a site, the same classification cannot exist`,
  key1006453: `There is no picture of the product, please upload in time`,
  key1006454: `The product has no color picture information, please upload it in time`,
  key1006455: `The logistics provider did not exist.`,
  key1006456: `The logistics provider name already exists.`,
  key1006457: `Platform account has been shut down.`,
  key1006458: `Duplicate service name or number`,
  key1006459: `The logistics provider code has existed.`,
  key1006460: `The name already exists.`,
  key1006461: `Duplicate association of freight forwarding`,
  key1006462: `Store number already exists`,
  key1006463: `The store name already exists`,
  key1006464: `The store name and number already exist`,
  key1006465: `Authorized stores cannot switch stores`,
  key1006466: `Abnormal order status`,
  key1006467: `Not satisfied`,
  key1006468: `Special rules of logistics`,
  key1006469: `Logistics scope rules`,
  key1006470: `Cancel order exception`,
  key1006471: `Failed to update user information`,
  key1006472: `Update logistics information abnormal`,
  key1006473: `Failed to create order`,
  key1006474: `Order has been placed`,
  key1006475: `The package has been out of the warehouse`,
  key1006476: `Processing the operation of placing logistics orders`,
  key1006477: `failed to cancel logistics`,
  key1006478: `Failed to void the sub-order in the supplier system`,
  key1006479: `The specified product information to be queried is empty`,
  key1006480: `Manual order failed`,
  key1006481: `Failed to generate shipping information`,
  key1006482: `Failed to deactivate logistics channel, associated`,
  key1006483: `Logistics cannot be deactivated`,
  key1006484: `The logistics channel code cannot be repeated`,
  key1006485: `The package is not waiting for outbound`,
  key1006486: `The specified list of pick lists is empty`,
  key1006487: `The issue document of the central warehouse has generated a picking list and cannot be voided.`,
  key1006488: `The face list is under picking review.`,
  key1006489: `The issue document is not`,
  key1006490: `The issue document of the central warehouse stock.`,
  key1006491: `There is a supplier sub-order that has been outbound`,
  key1006492: `No product information corresponding to sku was found`,
  key1006493: `Central warehouse stock picking list is being scanned`,
  key1006494: `A notification message template of the same type already exists`,
  key1006495: `The merchant has not set a Listing address`,
  key1006496: `Role name already exists`,
  key1006497: `The role is being used.`,
  key1006498: `Default customer level not set`,
  key1006499: `The outbound order has been marked as "Out of stock"`,
  key1006500: `The supplier has not set up shipping directly, and shipping directly is not allowed`,
  key1006501: `The warehousing status of the current container is not "To switch bill"`,
  key1006502: `A container type with the same name already exists`,
  key1006503: `The current container type is already bound`,
  key1006504: `The container is not in the same warehouse as the bill of lading`,
  key1006505: `This does not exist in the sorting container`,
  key1006506: `The container does not exist`,
  key1006507: `The container is bound`,
  key1006508: `The container status is abnormal or changed`,
  key1006509: `The container is empty and the sorting cannot be ended`,
  key1006510: `The number of bills of lading is abnormal, and there are SKU or waybills that have not been sorted`,
  key1006511: `The current container warehousing status is not "In switching bill".`,
  key1006512: `Order has been sorted`,
  key1006513: `The bill of lading is in sorting`,
  key1006514: `Container status does not exist`,
  key1006515: `The bill of lading status needs to be either received or sorted`,
  key1006516: `There is a bill of lading in sorting`,
  key1006517: `This bill of lading is in sorting by other  users`,
  key1006518: `A container of the same type has been bound`,
  key1006519: `Please complete the sticker job of the current container`,
  key1006520: `The container is in sticking waybill`,
  key1006521: `Please complete the publishing operation of the current container`,
  key1006522: `Containers are in publishing`,
  key1006523: `There is a container in the processing`,
  key1006524: `The sorting handover document already exists`,
  key1006525: `Orders need to go through commodity sorting first.`,
  key1006526: `Order has been shipped and cannot be removed`,
  key1006527: `The logistics is being processed, please try again later`,
  key1006528: `The current size type is being used`,
  key1006529: `The template is already in use, so deletion is not allowed`,
  key1006530: `The Chinese name of the body part has been used, and modification is not allowed`,
  key1006531: `The size value already exists`,
  key1006532: `The specification attribute is already in use, and the deletion is not allowed`,
  key1006533: `The specification attribute is already in use, modification is not allowed`,
  key1006534: `The current size value is in use and cannot be deleted`,
  key1006535: `The specification of the main attribute is already used, and modification is not allowed`,
  key1006536: `Export template exception`,
  key1006537: `Unauthorized Jushuitan Merchant`,
  key1006538: `Jushuitan Merchant not added`,
  key1006539: `No Jushuitan merchants with synchronized inventory checked and enabled were found`,
  key1006540: `Sku value is empty`,
  key1006541: `SPUs that are not for sale cannot be discontinued`,
  key1006542: `The number of shelves and picking quantity are inconsistent`,
  key1006543: `The exported bill information is empty`,
  key1006544: `Phone is not registered`,
  key1006545: `SPU cannot contain Chinese`,
  key1006546: `The current size attribute template is currently in use`,
  key1006547: `An attribute with the same name already exists. Please modify the attribute name before submitting`,
  key1006548: `No warehouse has been created. Please create a warehouse first`,
  key1006549: `This order has been cancelled. Please replenish the goods offline to the central warehouse`,
  key1006550: `The packaged delivery note cannot be voided`,
  key1006551: `Multiple primary attributes present`,
  key1006552: `Failure to meet the conditions for duplicate publication`,
  key1006553: `The settled merchant does not exist`,
  key1006554: `Password verification error`,
  key1006555: `No recipient information found`,
  key1006556: `The picking list is not pending or in sorting`,
  key1006557: `The picking list has been cancelled`,
  key1006558: `The picking list is not in the packaging`,
  key1006559: `The delivery note being packed cannot be voided`,
  key1006560: `The end time of the activity cannot be less than the start time of the activity`,
  key1006561: `This product already exists in another activity`,
  key1006562: `Could not find a container of the corresponding type for the product. Please bind and rescan.`,
  key1006563: `WishPost is currently not authorized. Please authorize and associate the store in your own logistics management and try again`,
  key1006564: `The store corresponding to the order is not currently associated with a WishPost account. Please associate it in your own logistics management and try again`,
  key1006565: `WishPost authorization is invalid. Please re authorize in your own logistics management and try again`,
  key1006566: `The store is already associated with another account`,
  key1006567: `Associated store not enabled`,
  key1006568: `A questionnaire with the same name already exists`,
  key1006569: `A question with the same name already exists in the current questionnaire`,
  key1006570: `The warehouse has been associated with a supplier`,
  key1006571: `The scanned package and delivery note are shipped from different warehouses`,
  key1006572: `1688 platform unauthorized store`,
  key1006573: `Link does not exist`,
  key1006574: `Currency configuration already exists`,
  key1006575: `This picking list is a regular order, please go to the regular packaging module for operation`,
  key1006576: `This picking list is a preparation picking list. Please proceed to the preparation sorting for operation`,
  key1006577: `The customer has not yet executed the shipment. Please try again in the next round of operations`,
  key1006578: `No relevant package information found`,
  key1006579: `The current small package has been sorted and scanned`,
  key1006580: `There are small packages that have not been packaged yet`,
  key1006581: `There are small packages that have not been sorted by logistics. Please complete the sorting before closing the bags`,
  key1006582: `Failed to modify the order synchronization status to 'synchronizing'`,
  key1006583: `The warehouse where the logistics handover order is located is not authorized. Please apply for the corresponding warehouse permission`,
  key1006584: `The handover form does not exist`,
  key1006585: `Please activate Temu virtual logistics first`,
  key1006586: `Platform order already exists`,
  key1006587: `The logistics tracking number already exists`,
  key1006588: `Recharged currency cannot be modified`,
  key1006589: `Currency cannot be modified after product creation`,
  key1006590: `The ongoing operation is not in sorting or packaging`,
  key1006591: `The number of SKUs scanned exceeds the expected picking quantity on the picking list`,
  key1006592: `This attribute is already used as a specification attribute by an existing product and cannot be deleted`,
  key1006593: `Failed to restore inventory`,
  key1006594: `The bill has been generated, but the subsidy review failed`,
  key1006595: `Logistics only supports binding one channel`,
  key1006596: `Failed to upload package`,
  key1006597: `Failed to acquire waybills`,
  key1006598: `Warehouse address does not exist`,
  key1006599: `Logistics Not Associated with Basic Logistics`,
  key1006600: `Logistics provider failed to obtain token`,
  key1006601: `Scanning temu outbound orders is not allowed`,
  key1006602: `AliExpress OAID does not exist`,
  key1006603: `Decryption failed`,
  key1006604: `The bill has been generated, but the subsidy re review failed`,
  key1006605: `Abnormal barcode printing for the selected order`,
  key1006606: `Abnormal printing of SKU barcode`,
  key1006607: `Items that are not out of stock cannot be marked as arrival`,
  key1006608: `No order for this product, please verify the order`,
  key1006609: `The bill of lading is not in a waiting for collection status`,
  key1006610: `The delivery order has been issued and no other operations are allowed`,
  key1006611: `There are orders in the current order with an outbound order that is not in a pending shipment status`,
  key1006612: `There is only one order in this picking list, and there is no need to sort it. Please proceed with the preparation and packaging operation`,
  key1006613: `This picking list is a regular order, please proceed to the regular sorting for operation`,
  key1006614: `This picking list is a backup list. Please proceed to the backup sorting for operation`,
  key1006615: `Failed to add goods and synchronize bill of lading operation`,
  key1006616: `There are orders with out of stock status in the current order`,
  key1006617: `The middle label code cannot be empty`,
  key1006618: `No shipping orders`,
  key1006619: `The picking list exists in`,
  key1006620: `Manual orders and platform orders cannot be shipped simultaneously`,
  key1006621: `Goods orders and sample orders cannot be shipped simultaneously`,
  key1006622: `Orders from different stores cannot be shipped simultaneously`,
  key1006623: `Orders with different shipping addresses cannot be shipped simultaneously`,
  key1006624: `There are small bags with open bags in this order`,
  key1006625: `The order has been completed and cannot be scanned again.`,
  key1006626: `The number of manually modified scans must be a positive integer greater than 0`,
  key1006627: `The selected batch of outbound orders exceeds the maximum limit. Please adjust the maximum number of packages`,
  key1006628: `The maximum number of outbound orders per picking list cannot be empty or less than 1`,
  key1006629: `The picking list is currently being processed by another user and cannot be processed again`,
  key1006630: `temu_s Picking Doc Existence Not Printed`,
  key1006631: `The bill of lading and container belong to different warehouses. Please check and try again`,
  key1006632: `Non-matching goods or matching logistics failure or order failure status, cannot be marked as non-`,
  key1006633: `Order Actions`,
  key1006634: `The title guide for this title already exists in the same system and module. Please redefine the title`,
  key1006635: `Logistics/basic logistics cannot be transmitted at the same time`,
  key1006636: `Logistics/Basic Logistics Must Pass One`,
  key1006637: `Basic logistics trackingNumber, labelPath must be passed`,
  key1006638: `SKU has been discontinued`,
  key1006639: `Multiple warehouse matches for product shipment. Please split the platform order before placing the order`,
  key1006640: `Failed to match warehouse`,
  key1006641: `The available inventory of the product is empty or illegal`,
  key1006642: `Account not bound to store, or account has expired`,
  key1006643: `Customer label with the same name already exists`,
  key1006644: `The customer group name already exists`,
  key1006645: `The user has already started other picking operations`,
  key1006646: `The mobile phone is bound, not support updating mobile phone.`,
  key1006647: `Product category not mapped to platform`,
  key1006648: `Failed to obtain supplier currency interface`,
  key1006649: `Duplicate SPU, please modify`,
  key1006650: `Failed to query third-party platform orders`,
  key1006651: `Alipay payment order failed`,
  key1006652: `Failed to add recharge record`,
  key1006653: `Failed to obtain distributor currency`,
  key1006654: `Operational order not queried`,
  key1006655: `Failed to return shipment status`,
  key1006656: `Store address is empty`,
  key1006657: `The order in the logistics handover form is empty`,
  key1006658: `Multiple item sorting is not allowed to remove the picking list after the order has been changed`,
  key1006659: `Not applying for an appointment form`,
  key1006660: `The container type has already been used. Please delete the container that has already used this type before editing this container type`,
  key1006661: `No logistics tracking records found`,
  key1006662: `No valid logistics channels were matched`,
  key1006663: `There are failed orders`,
  key1006664: `Order, please replace the goods after completion.`,
  key1006665: `The customer level name already exists, please replace it and try again`,
  key1006666: `It is not allowed to modify the buyer's shipping address after being packed`,
  key1006667: `Email registration method: Email cannot be empty`,
  key1006668: `At least one email and mobile phone need to be filled out`,
  key1006669: `This logistics channel is currently unavailable. For more information, please consult customer service`,
  key1006670: `The selected product is currently generating data`,
  key1006671: `Failed to generate sales information`,
  key1006672: `Vendor account already exists`,
  key1006673: `Please login again`,
  key1006674: `Distributor account already exists, please log in again`,
  key1006675: `The supplier account permission has not been opened yet. Please contact the main account to open it. If you have any questions, please contact online customer service for consultation!`,
  key1006676: `HTML type waybills only allow individual printing`,
  key1006677: `Sales price less than or equal to 0`,
  key1006678: `When marking out of stock, the out of stock quantity must be greater than 1`,
  key1006679: `This account is an operator system account. Please change the account and try again`,
  key1006680: `This shipping warehouse already has this type of expense configuration. Please check and try again`,
  key1006681: `No warehouse processing fee set`,
  key1006682: `Fixed billing does not set regular or multi item order processing fees`,
  key1006683: `Layered billing is not set with tiered pricing or multi frequency order processing fees`,
  key1006684: `The currently selected bill has already been confirmed`,
  key1006685: `This specification attribute has been used and cannot be modified as a regular attribute`,
  key1006686: `Address book with the same template name exists`,
  key1006687: `The note content is too long, adding failed`,
  key1006688: `Failed to update permission authorization for shelving tools`,
  key1006689: `The bill of lading where the backup note is located has been packed`,
  key1006690: `Same platform configuration already exists`,
  key1006691: `The specified platform is not configured`,
  key1006692: `No specified store configured`,
  key1006693: `No publishing template configured`,
  key1006694: `Store token invalid`,
  key1006695: `No timeout time set`,
  key1006696: `Please activate AliExpress fully managed logistics first`,
  key1006697: `Incorrect IOSS encoding format`,
  key1006698: `Target country tax rate setting already exists`,
  key1006699: `IOSS configuration with the same name already exists`,
  key1006700: `Some of the raw materials are being used in assembled products and cannot be deleted.`,
  key1006701: `Platform order IOSS has been entered`,
  key1006702: `Logistics agent payment, customs clearance fee cannot be empty`,
  key1006703: `This logistics channel has been deactivated. Please select a new logistics channel`,
  key1006704: `There is data that has already generated sales information`,
  key1006705: `The distributor's stock order is not in a pending payment status`,
  key1006706: `Abnormal verification of the quantity of goods in the distributor's cancellation of the stock order`,
  key1006707: `The distributor's stock order is not in the status of awaiting price adjustment`,
  key1006708: `The distributor's stock order is not in the supplier's pending price status`,
  key1006709: `Failed to list inventory on the allocation order`,
  key1006710: `Set assembly items to include deleted items`,
  key1006711: `Set the original materials of the assembled goods to include the assembled goods`,
  key1006712: `The raw materials for assembling the product cannot be consistent with the current product`,
  key1006713: `There is an export template with the same name`,
  key1006714: `Unconfigured virtual storage location for assembled products`,
  key1006715: `Failed to assemble raw materials and finished products into and out of the warehouse`,
  key1006716: `Logistics handover form not in sorting status`,
  key1006717: `The number of operational orders is inconsistent with the selection`,
  key1006718: `No matching buyer's zip code`,
  key1006719: `The number of operable orders is inconsistent with the selection`,
  key1006720: `Failed to save declaration information`,
  key1006721: `Unsupported inventory operations`,
  key1006722: `Inventory operation failed`,
  key1006723: `Failed to retrieve supplier data through interface call`,
  key1006724: `The current bill of lading is not owned by the logistics company and is in a waiting for collection status. The logistics cannot be modified`,
  key1006725: `Item allocation failed`,
  key1006726: `Withdrawal`,
  key1006727: `Speed regulating AliExpress failed to create collection interface`,
  key1006728: `Printing collection waybill image access failed`,
  key1006729: `Speed regulating AliExpress fully managed printing collection waybill interface failed`,
  key1006730: `AliExpress reservation delivery interface call API exception`,
  key1006731: `Exceeded the logistics size limit. Please change the logistics and try again`,
  key1006732: `Exceeded the weight limit of this logistics. Please change the logistics and try again`,
  key1006733: `Beyond the delivery range of this logistics, please change the logistics and try again`,
  key1006734: `The pending order status cannot match the product`,
  key1006735: `Different purpose warehouse orders please separate shipment`,
  key1006736: `Due to the excessive quantity of goods, please make a single print in the details`,
  key1006737: `Proportion of unfilled attribute for commodity attribute`,
  key1006738: `There is a canceled package in the current delivery slip. Please remove the canceled package and try again`,
  key1006739: `Non-boxed orders exist`,
  key1006740: `This item has been discontinued,`,
  key1006741: `The quantity stored in the warehouse is less than the quantity of the ordered goods, and the order cannot be placed for the time being.`,
  key1006742: `Failed to generate picking list`,
  key1006743: `There is a distributor and the order has not been canceled. Please try again later.`,
  key1006744: `The current logistics is not available, please cancel the order and place the order again.`,
  key1006745: `The distributor account authority has not been opened yet. Please contact the main account. If you have any questions, please contact the online customer service consultation!`,
  key1006746: `Change delivery address cancel`,
  key1006747: `order anomaly`,
  key1006748: `Failed to change shipping address, order canceled`,
  key1006749: `There is a duplicate express number, please scan this package through the bill of lading number.`,
  key1006750: `Non-waiting collection status cannot be voided`,
  key1006751: `Void supplier bill of lading failed`,
  key1006752: `The site name is already in use, please change another site name`,
  key1006753: `Only orders that have been created and are in stock are supported for cancellation. Please refresh the data to view`,
  key1006754: `Cloud selling logistics rules are not set`,
  key1006755: `Failed to return the sample shipment information.`,
  key1006756: `Failed to obtain large warehouse receiving address.`,
  key1006757: `Commodity barcode query failed`,
  key1006758: `Query sample list`,
  key1006759: `Not matched to shipping method`,
  key1006760: `Logistics payment, customs clearance fee can not be empty`,
  key1006761: `No valid shipping information`,
  key1006762: `No transaction information exists in the platform sheet.`,
  key1006763: `Order Shipped`,
  key1006764: `The issue document is not in issue status`,
  key1006765: `Goods not to be reviewed`,
  key1006766: `I have paid attention to this store.`,
  key1006767: `Commodity classification information does not exist`,
  key1006768: `Favourite canceled`,
  key1006769: `The current bill of lading to be put on the shelf is not in receipt status.`,
  key1006770: `Failed to reset package status`,
  key1006771: `Not matched to warehouse, please contact customer service`,
  key1006772: `Container non-goods sorting completion status`,
  key1006773: `It has been put on the shelf, please do not repeat the operation.`,
  key1006774: `The goods have been sorted`,
  key1006775: `No posting rules set`,
  key1006776: `Not matched to the reservoir area, please contact customer service`,
  key1006777: `SKU does not match the location number, please check and try again`,
  key1006778: `SKU picked`,
  key1006779: `Bill is not payment status`,
  key1006780: `Must pass one`,
  key1006781: `Vendor has not set up a vesting warehouse`,
  key1006782: `ymsWarehouseLocation not fully allocated`,
  key1006783: `AliExpress online delivery UserNick must be transmitted`,
  key1006784: `The parcel is not authorized in the warehouse`,
  key1006785: `Logistics handover order warehouse does not match with parcel warehouse`,
  key1006786: `Logistics delivery order warehouse is inconsistent with parcel logistics`,
  key1006787: `Missing package`,
  key1006788: `Logistics provider number already exists`,
  key1006789: `The three-party platform does not exist.`,
  key1006790: `Failed to obtain the delivery and return address of the store.`,
  key1006791: `The current cloud order has not been shipped`,
  key1006792: `The current order logistics method is not cloud selling logistics.`,
  key1006793: `AliExpress Print Face Single Interface Failure`,
  key1006794: `Can handle up to 200 packages at a time, please remove them before binding the bag.`,
  key1006795: `The distributor stock order has been paid and cannot be paid again.`,
  key1006796: `Category does not exist`,
  key1006797: `Order ID Query Order Id Set Exception`,
  key1006798: `Failed to save package information`,
  key1006799: `Obtain the exception of the logistics provider.`,
  key1006800: `Print Surface Sheet Abnormal`,
  key1006801: `Package already exists`,
  key1006802: `Logistics provider account does not exist`,
  key1006803: `There are unrecycled packages`,
  key1006804: `Parcel order data exception`,
  key1006805: `Get Order Exception`,
  key1006806: `Failed to update order print time`,
  key1006807: `The package data in the delivery system is inconsistent with the data in the order system.`,
  key1006808: `Delivery document delivery logistics failed`,
  key1006809: `The issue document mark failed to print, and some packages have been printed in other ways.`,
  key1006810: `Package not delivered`,
  key1006811: `Invalid number of available stock Please enter more than 0 number of available stock`,
  key1006812: `There are no goods in the delivery desk, and the distribution list cannot be printed.`,
  key1006813: `Failed to modify stock document item quantity.`,
  key1006814: `The current bill of lading is not waiting for collection, and the logistics cannot be modified.`,
  key1006815: `The current bill of lading is not self-owned logistics and cannot be modified.`,
  key1006816: `Obtaining Warehouse Exception`,
  key1006817: `Update Package Wrap Status Abnormal`,
  key1006818: `Print the address sheet when the user does not open the sorting`,
  key1006819: `There are products using this brand and cannot be deleted!`,
  key1006820: `Commit exists same property`,
  key1006821: `Variable parameter commodity null attribute`,
  key1006822: `The attribute key is inconsistent`,
  key1006823: `Delete SKU Exceptions`,
  key1006824: `The upload path is empty.`,
  key1006825: `common commodity spu and sku must be consistent`,
  key1006826: `Variable parameter commodity attribute cannot be empty`,
  key1006827: `Property labels contain commas`,
  key1006828: `Non-variable parameter cannot add extra sku`,
  key1006829: `spu cannot be empty`,
  key1006830: `cnName cannot be empty`,
  key1006831: `Classification ID cannot be empty`,
  key1006832: `productGoodsList cannot be empty`,
  key1006833: `sku not matched`,
  key1006834: `Null request parameter`,
  key1006835: `Failed to unallocate`,
  key1006836: `Unallocation failed. The issue document does not exist.`,
  key1006837: `Unallocation failed Shipped, Cancelled, Picking Order Generated or Picking cannot be canceled`,
  key1006838: `Failed to cancel allocation Failed to delete picking list`,
  key1006839: `Unallocation failed to restore original inventory data`,
  key1006840: `(before allocation`,
  key1006841: `) failed`,
  key1006842: `Failed to cancel the allocation. Failed to modify the inventory product details data.`,
  key1006843: `(Assigned Quantity`,
  key1006844: `Failed to unassign product assignment record deletion failed`,
  key1006845: `Failed to operate issue Doc`,
  key1006846: `Detail data is empty`,
  key1006847: `No issue document found`,
  key1006848: `Failed to operate goods`,
  key1006849: `Picking Status Incorrect`,
  key1006850: `Shipment Status Status Incorrect`,
  key1006851: `The logistics address is blank, please fill in the logistics address`,
  key1006852: `Address does not match, please contact online customer service consultation`,
  key1006853: `Abnormal Alipay transfer call`,
  key1006854: `Failed to synchronize platform address`,
  key1006855: `Only pending customers can proceed with the audit, please reselect`,
  key1006856: `AliExpress interface exception`,
  key1006857: `the pms logistics account token is empty`,
  key1006858: `Package number is empty`,
  key1006859: `Product link is empty`,
  key1006860: `Abnormal Logistics Cancellation`,
  key1006861: `Failed to obtain ioss tax rate configuration`,
  key1006862: `The current order is not a manual order.`,
  key1006863: `Contact corresponding to customer number not found`,
  key1006864: `The data YmsInventoryWarehouse to be updated in the inventory statistics table is not found.`,
  key1006865: `Failed to modify alibabagj shipping address.`,
  key1006866: `当前订单中存在出库单为非尾程物流的POD订单`,
  key1006867: `Confirmed order payment error, interface exception`,
  key1006868: `Ali International Platform List is not canceled manually`,
  key1006869: `Shipment retry`,
  key1006870: `No distributor platform orders found for matched items`,
  key1006871: `wms delivery failed`,
  key1006872: `No cloud selling logistics meeting the order conditions was found`,
  key1006873: `wms cancel failed`,
  key1006874: `Warehouse is empty`,
  key1006875: `The current picking list contains a non-`,
  key1006876: `Pending/picking`,
  key1006877: `The ordershipingid under the package already exists`,
  key1006878: `Get shipment setting exception`,
  key1006879: `Invalid quantity in issue document list`,
  key1006880: `Allocate Inventory Exceptions`,
  key1006881: `Undelivered packages`,
  key1006882: `Failed to update bill and issue document status`,
  key1006883: `The same site cannot exist under the same platform`,
  key1006884: `Please confirm whether the merchant number is correct`,
  key1006885: `店铺不存在`,
  key1006886: `Picking list is being packed`,
  key1006887: `The picking list is not to be picked or picking.`,
  key1006888: `The current YMS order is being reviewed during picking`,
  key1006889: `Product status error`,
  key1006890: `Picking list status is abnormal`,
  key1006891: `Get Wrapper Settings Exception`,
  key1006892: `Does not match the specified YMS order`,
  key1006893: `No exception obtained`,
  key1006894: `Reservoir name name duplicate`,
  key1006895: `Reservoir location cannot all be null`,
  key1006896: `Available inventory cannot be less than 0`,
  key1006897: `商品生成面单标签异常`,
  key1006898: `Exception of YMS order`,
  key1006899: `Invalid warehouse inventory information ID`,
  key1006900: `Only batch approval of bills in the status to be approved is supported. Please check and try again.`,
  key1006901: `Only batch approval of bills in payment status is supported. Please check and try again.`,
  key1006902: `No this API permission, please contact customer service to open`,
  key1006903: `Goods stocking document splitting failed`,
  key1006904: `Order tag with same name already exists`,
  key1006905: `This order contains special processing goods, can not be canceled directly, if you need to cancel please contact customer service`,
  key1006906: `Product tag with same name already exists`,
  key1006907: `An order import template with the same name already exists`,
  key1006908: `存在相同Excel文件标题`,
  key1006909: `等级折扣系数值设置无效`,
  key1006910: `存在非待确认支付信息`,
  key1006911: `取消失败，运营已对账通过，请刷新后查看`,
  key1006912: `未匹配平台仓库`,
  key1006913: `查询卖家发货仓库基础信息接口失败`,
  key1006914: `仅普通属性可选值可关联子属性`,
  key1006915: `商品属性之间存在循环关联`,
  key1006916: `被关联属性不可修改为规格属性`,
  key1006917: `Edit Product`,
  key1006918: `Size Chart Template`,
  key1006919: `Add/Edit Size Chart Template`,
  key1006920: `Pending list`,
  key1006921: `Review/Edit products`,
  key1006922: `Product Settings`,
  key1006923: `Supplier order details`,
  key1006924: `Distributor Stock List`,
  key1006925: `Supplier Stock List`,
  key1006926: `Supplier Stock Order Details`,
  key1006927: `订单支付设置`,
  key1006928: `订单收款账户`,
  key1006929: `Warehousing management`,
  key1006930: `Bill of Lading Management`,
  key1006931: `Bill of lading management details`,
  key1006932: `View the details of the outbound order`,
  key1006933: `Picking list`,
  key1006934: `Setting of consignment order`,
  key1006935: `Logistics provider handover order management`,
  key1006936: `Logistics provider handover order management details`,
  key1006937: `Supplier Management`,
  key1006938: `Supplier list`,
  key1006939: `Supplier Audit`,
  key1006940: `Supplier level management`,
  key1006941: `customer management`,
  key1006942: `Customer List`,
  key1006943: `Tuoke Link`,
  key1006944: `Supplier bill`,
  key1006945: `Supplier billing details`,
  key1006946: `Customer billing details`,
  key1006947: `Logistics billing details`,
  key1006948: `Warehouse billing details`,
  key1006949: `Password management`,
  key1006950: `Logistics Management`,
  key1006951: `Basic logistics`,
  key1006952: `Intelligent Logistics Rules`,
  key1006953: `Warehouse management`,
  key1006954: `Warehouse management`,
  key1006955: `Storage bin management`,
  key1006956: `Message template`,
  key1006957: `Container management`,
  key1006958: `Container type settings`,
  key1006959: `Address library management`,
  key1006960: `Configuration of inbound and outbound processes`,
  key1006961: `IOSS tax rate setting`,
  key1006962: `商城配置`,
  key1006963: `Declaration rules`,
  key1006964: `Automatic warehousing rules`,
  key1006965: `Content management`,
  key1006966: `questionnaire survey`,
  key1006967: `Edit questionnaire`,
  key1006968: `Operating instructions`,
  key1006969: `Data Center`,
  key1006970: `marketing management `,
  key1006971: `Site Management`,
  key1006972: `Event Management`,
  key1006973: `Add/Edit Activity`,
  key1006974: `Advertising management`,
  key1006975: `Distributor fee allocation`,
  key1006976: `View messages and Email`,
  key1006977: `Employee Management`,
  key1006978: `Task Center`,
  key1006979: `Import Task`,
  key1006980: `Export task`,
  key1006981: `Order Center`,
  key1006982: `Order list`,
  key1006983: `Stock Order Details`,
  key1006984: `Shipping Record`,
  key1006985: `Shipment Record Details`,
  key1006986: `Product Center`,
  key1006987: `Add/Edit products`,
  key1006988: `Brand management`,
  key1006989: `Third party platforms`,
  key1006990: `Platform Authorization`,
  key1006991: `Reconciliation management`,
  key1006992: `Account management`,
  key1006993: `Outbound order details`,
  key1006994: `Bill of lading`,
  key1006995: `order picking `,
  key1006996: `Outbound operation`,
  key1006997: `Outbound Job Settings`,
  key1006998: `Mall`,
  key1006999: `distribution market`,
  key1007000: `Favorite Items`,
  key1007001: `Generate sales records`,
  key1007002: `Automatic rules`,
  key1007003: `Store management`,
  key1007004: `Favorite suppliers`,
  key1007005: `Platform order details`,
  key1007006: `Add Temu Order`,
  key1007007: `付款管理`,
  key1007008: `Order Import Template`,
  key1007009: `Right Management`,
  key1007010: `Role Management`,
  key1007011: `User Management`,
  key1007012: `Details of price changes`,
  key1007013: `Account management`,
  key1007014: `Security Information`,
  key1007015: `List of products and stores`
}
