/**
 * @Description: 运营系统API
 * @author jing
 * @date 2021/11/22 14:31
 * @params:
 */
import commonApi from "@/api/commonApi";

const ymsApi = {
  ...commonApi,
  // 仓库管理
  put_ymsInventory: "/ymsInventory", // 修改
  post_ymsInventory_query: "/ymsInventory/query", // 列表
  post_ymsInventoryChangeLog_query: "/ymsInventoryChangeLog/query", // 库存变更列表
  put_ymsPackageInfo_queryWaitPickingPackageInfo:
    "/ymsPackageInfo/queryWaitPickingPackageInfo", // 查询生成拣货单列表
  put_ymsPickingOrder_startSorting: "/ymsPickingOrder/startSorting", // 常规分拣
  put_ymsPickingOrder_preparationStartSorting:
    "/ymsPickingOrder/preparationStartSorting", // 备货分拣
  post_ymsPickingOrder_querySortingListByParam:
    "/ymsPickingOrder/querySortingListByParam", // 多品分拣列表查询
  post_ymsPickingOrder_exportSortingListByParam:
    "/ymsPickingOrder/exportSortingListByParam", // 多品分拣列表导出
  post_ymsPickingOrder_preparationStartSortingScanSku:
    "/ymsPickingOrder/preparationStartSortingScanSku", // 备货分拣--扫描sku创建小包
  post_ymsPickingOrder_printMidLabel: "/ymsPickingOrder/printMidLabel", // 打印Temu中间标签
  put_ymsPickingOrder_resetSorting: "/ymsPickingOrder/resetSorting", // 撤销分拣
  delete_ymsPickingOrderRelated: "/ymsPickingOrderRelated/", // 移除云卖订单（更新云卖订单->订单状态 为待拣货）
  post_ymsPickingOrder: "/ymsPickingOrder", // 拣货单列表
  post_ymsPickingOrder_querySalesPickingCarriers:
    "/ymsPickingOrder/querySalesPickingCarriers", // 查询邮寄方式拣货单
  get_ymsPickingOrder_getPackageInfoList:
    "/ymsPickingOrder/getPackageInfoList/", // 拣货单详情 出库单
  get_ymsPickingOrder_pickingOrderGoodsList:
    "/ymsPickingOrder/pickingOrderGoodsList/", // 拣货单详情 拣货单货品
  get_ymsPickingOrder_pickingOrderGoodsDetailList:
    "/ymsPickingOrder/pickingOrderGoodsDetailList", // 拣货单详情 拣货单货品 List
  post_ymsPickingOrder_create: "/ymsPickingOrder/create", // 生成拣货单
  post_ymsPackageInfo_querySalesPickingCarriers:
    "/ymsPackageInfo/querySalesPickingCarriers", // 获取生成拣货单的邮寄方式数据
  post_ymsPickingOrder_batchCreate: "/ymsPickingOrder/batchCreate", // 批量生成拣货单
  put_ymsPickingOrder_markPicking: "/ymsPickingOrder/markPicking", // 标记已拣货
  put_ymsPickingOrder_batchMarkPicking: "/ymsPickingOrder/batchMarkPicking", // 批量标记已拣货
  put_ymsPickingOrder_markPrinted: "/ymsPickingOrder/markPrinted", // 标记已打印
  put_ymsPickingOrder_startPacking: "/ymsPickingOrder/startPacking", // 开始包装
  post_ymsPickingOrder_queryPackingListByParam:
    "/ymsPickingOrder/queryPackingListByParam", // 包装作业列表查询
  post_ymsPickingOrder_exportPackingListByParam:
    "/ymsPickingOrder/exportPackingListByParam", // 导出包装作业列表数据
  put_ymsPickingOrder_matchYmsPackage: "/ymsPickingOrder/matchYmsPackage", // 根据sku匹配包裹
  put_ymsPickingOrderRelated_resetWaitingPacked:
    "/ymsPickingOrderRelated/resetWaitingPacked/", // 修改包装状态为待包装-带状态去更新
  put_ymsPickingOrderRelated_markChangedLabel:
    "/ymsPickingOrderRelated/markChangedLabel", // 标记云卖订单已换单
  put_ymsPickingOrderRelated_resetChangedLabel:
    "/ymsPickingOrderRelated/resetChangedLabel", // 重置云卖订单已换单
  put_ymsPickingOrder_quitPacking: "/ymsPickingOrder/quitPacking/", // 结束作业
  put_ymsPickingOrder_endPacking: "/ymsPickingOrder/endPacking", // 结束作业
  put_ymsPickingOrder_endSorting: "/ymsPickingOrder/endSorting", // 分拣-分拣结束（如果有异常的出库单errorpackageidlist从拣货单中移除）
  post_ymsInventory_excelImport: "/ymsInventory/excelImport", // 库存导入
  post_ymsInventory_batchExport: "/ymsInventory/batchExport", // 库存余量导出
  post_ymsInventory_batchExportDetail: "/ymsInventory/batchExportDetail", // 导出库存明细
  // 商品管理
  put_productInfo: "/productInfo", // 编辑商品(供应商系统)
  post_productInfo: "/productInfo", // 新增商品
  get_productInfo_queryForSkuList: "/productInfo/queryForSkuList/", // 查看SKU
  get_productInfo: "/productInfo/", // SPU详情
  put_productInfo_productId: "/productInfo/", // 停售SPU商品
  put_productInfo_dercarriage: "/productInfo/dercarriage/", // 上架SPU
  delete_productInfo: "/productInfo/", // 删除SPU
  put_productGoods_updateStatus: "/productGoods/updateStatus", // 上架、停售sku
  delete_productGoods: "/productGoods/", // 删除sku
  post_productGoodsOperationLog_query: "/productGoodsOperationLog/query", // spu、sku操作日志
  post_erpCommon_queryTask: "/erpCommon/queryTask", // 导入导出查看列表
  put_erpCommon_getBarCodes: "/erpCommon/getBarCodes", // getBarCodes
  get_packageCommon_downloadTongtoolPrinter:
    "/erpCommon/yms-supplier-service/packageCommon/downloadTongtoolPrinter", // 下载全路径 = 当前系统域名:端口 + 控件路径
  get_ymsSupplierBill: "/ymsSupplierBill/", // 获取供应商账单的基本信息
  post_ymsProductInfo_queryByYmsProductGoodsIds:
    "/ymsProductInfo/queryByYmsProductGoodsIds", // 商品id查询产品信息
  post_ymsProductGoods_list: "/ymsProductInfo/query", // yms商品管理列表
  get_productGoods_queryInventoryByYmsProductId:
    "/productGoods/queryInventoryByYmsProductId", // 查询商品列表库存数据
  get_ymsProductGoods: "/ymsProductInfo/", // 商品管理--商品列表详情
  post_ymsProductInfo_batchExport: "/ymsProductInfo/batchExport", // 商品列表导出
  post_productInfo_stopMoreProductInfo: "/productInfo/stopMoreProductInfo", // 批量停售商品
  post_ymsProductColorTemplate: "/ymsProductColorTemplate", // 新增颜色
  get_ymsProductColorTemplate_query: "/ymsProductColorTemplate/query", // 获取颜色列表的数据
  delete_ymsProductColorTemplate: "/ymsProductColorTemplate/", // 删除颜色
  // 云卖订单
  post_ymsPackageInfo_query: "/ymsPackageInfo/query", // 云卖订单列表
  post_ymsPackageInfo_addTagOrRemark: "/ymsPackageInfo/addTagOrRemark", // 批量添加标签或备注
  post_ymsPackageInfo_deleteTag: "/ymsPackageInfo/deleteTag", // 删除标签
  get_ymsPackageTag_queryAllTags: "/ymsPackageTag/queryAllTags", // 获取yms订单标签
  put_ymsPackageInfo_invalidConfirm: "/ymsPackageInfo/invalidConfirm", // 标记取消
  post_ymsPackageInfo_createDistributorBill:
    "/ymsPackageInfo/createDistributorBill", // 生成分销商账单
  post_ymsPackageInfo_createSupplierBill: "/ymsPackageInfo/createSupplierBill", // 生成供应商账单
  get_ymsPackageInfo: "/ymsPackageInfo/", // 云卖订单详情
  get_ymsSubPackageInfo: "/ymsSubPackageInfo/", // 供应商订单详情
  post_ymsProductGoods_queryByIdList: "/ymsProductGoods/queryByIdList", // 根据id获取商品的相关数据
  post_systemTrackingMoreCarrier_query:
    "/erpCommon/common-service/systemTrackingMoreCarrier/query", // 获取承运商的数据
  post_ymsPackageInfo_addLogisticsTracking:
    "/ymsPackageInfo/addLogisticsTracking", // 添加物流轨迹信息
  put_ymsPackageInfo_updateLogisticsTracking:
    "/ymsPackageInfo/updateLogisticsTracking", // 更新物流轨迹信息
  get_ymsPackageInfo_searchLogisticsTracking:
    "/ymsPackageInfo/searchLogisticsTracking/", // 获取物流轨迹信息
  get_ymsPackageDeclare_queryByPackageId: "/ymsPackageDeclare/queryByPackageId", // 获取云卖订单详情的申报信息
  post_ymsPackageDeclare: "/ymsPackageDeclare", // 新增申报信息
  put_ymsPackageDeclare: "/ymsPackageDeclare", // 更新申报信息
  put_ymsPackageInfo_uploadLogistics: "/ymsPackageInfo/uploadLogistics", // 下发物流
  put_ymsPackageInfo_updateCarrierInfo: "/ymsPackageInfo/updateCarrierInfo", //修改物流方式
  post_ymsLogisService_queryByIds: "/ymsLogisService/queryByIds", // 根据id获取云卖物流信息
  post_ymsLogisService_queryAll: "/ymsLogisService/queryAll", // 云卖物流查询全部
  get_salesPlatformSite_query:
    "/erpCommon/common-service/salesPlatformSite/query",
  post_ymsPackageInfo_exportYmsPackageInfo:
    "/ymsPackageInfo/exportYmsPackageInfo", // 导出云卖订单
  post_ymsPackageInfo_modifyCarrierMessage:
    "/ymsPackageInfo/modifyCarrierMessage", // 修改物流信息

  // 财务管理
  yms_post_ymsDistributorBill_query: "/ymsDistributorBill/query", // 分销商账单管理
  put_ymsDistributorBill_cancel: "/ymsDistributorBill/cancel/", // 作废分销商账单
  put_ymsDistributorBill_payConfirm: "/ymsDistributorBill/payConfirm/", // 分销商确认收款
  yms_get_ymsDistributorBill: "/ymsDistributorBill/", // 获取分销商账单的基本信息
  yms_post_ymsSupplierBill_query: "/ymsSupplierBill/query", // 供应商账单管理
  post_ymsSupplierBill_exportSupplierBill:
    "/ymsSupplierBill/exportSupplierBill", // 供应商账单导出
  post_ymsSupplierBillPackageDetail_exportBillDetail:
    "/ymsSupplierBillPackageDetail/exportBillDetail", // 供应商账单明细导出
  put_ymsSupplierBill_cancel: "/ymsSupplierBill/cancel/", // 作废供应商账单
  post_ymsLog_queryByBusinessId: "/ymsLog/queryByBusinessId", // 账单日志
  get_ymsBillPackageDetail_queryByYmsDistributorBillId:
    "/ymsDistributorBillPackageDetail/queryByYmsDistributorBillId", // 分销商账单明细列表
  get_ymsBillPackageDetail_queryByYmsSupplierBillId:
    "/ymsSupplierBillPackageDetail/queryByYmsSupplierBillId", // 供应商账单明细列表
  post_ymsDistributorMerchantRechargeRecord_recharge:
    "/ymsDistributorMerchantRechargeRecord/recharge", // 充值
  post_ymsSupplierBill_batchApproved: "/ymsSupplierBill/batchApproved", // 批量审核账单
  post_ymsSupplierBill_batchReceiveConfirmOffLine:
    "/ymsSupplierBill/batchReceiveConfirmOffLine", // 批量确认线下打款
  post_ymsSupplierBill_updateBillPayment: "/ymsSupplierBill/updateBillPayment", // 确认打款
  // 分类
  post_ymsProductCategory: "/ymsProductCategory", // 新增商品分类
  put_ymsProductCategory: "/ymsProductCategory", // 编辑商品分类
  put_ymsProductCategory_updateCateguryAndMapping:
    "/ymsProductCategory/updateCateguryAndMapping", // 更新分类属性映射
  delete_ymsProductCategory: "/ymsProductCategory/", // 删除商品分类
  post_ymsProductCategory_excelImport: "/ymsProductCategory/excelImport", // 导入映射
  get_ymsListingCategoryMapping_getCategoryTree:
    "/ymsListingCategoryMapping/getCategoryTree", // 最近使用的组织树
  post_ymsListingCategoryMappingAttribute_getAttributeMappingList:
    "/ymsListingCategoryMappingAttribute/getAttributeMappingList", // 获取分类自动匹配

  get_ymsProductCategory_ymsProductCategoryId: "/ymsProductCategory/", // 获取分类的详情数据
  post_ymsProductAttributeTemplate: "/ymsProductAttributeTemplate", // 新增属性模板
  put_ymsProductAttributeTemplate: "/ymsProductAttributeTemplate", // 编辑属性池模板的数据
  get_ymsProductAttributeTemplate: "/ymsProductAttributeTemplate/", // 查看属性池的详情
  put_ymsProductAttributeDictionary_disable:
    "/ymsProductAttributeDictionary/disable/", // 停用属性
  put_ymsProductAttributeDictionary_enable:
    "/ymsProductAttributeDictionary/enable/", // 启用属性

  delete_ymsProductAttributeTemplate: "/ymsProductAttributeTemplate/", // 删除属性
  get_ymsProductAttributeTemplate_queryCount:
    "/ymsProductAttributeTemplate/queryCount", // 获取属性池对应状态的数量
  post_ymsProductAttributeTemplate_query: "/ymsProductAttributeTemplate/query", // 获取属性池列表的数据
  post_ymsProductAttributeTemplate_exportTemplatr:
    "/ymsProductAttributeTemplate/exportTemplatr", // 导出物品属性池数据
  put_ymsProductAttributeTemplate_enable:
    "/ymsProductAttributeTemplate/enable/", // 启用属性
  put_ymsProductAttributeTemplate_disable:
    "/ymsProductAttributeTemplate/disable/", // 停用属性
  post_ymsProductAttributeTemplate_queryByTemplateIds:
    "/ymsProductAttributeTemplate/queryByTemplateIds", // 批量查询属性值
  get_erpCommon_queryAccountSite: "/erpCommon/queryAccountSite/", // 根据平台查询对应的站点
  post_listing_queryCategory: "/listing/queryCategory", // 查询站点对应的类目
  post_queryCategorys: "/erpCommon/queryCategorys", // 根据指定条件查询类目列表接口
  get_ymsProductCategory_productCount: "/ymsProductCategory/productCount", //  查询商品分类的商品个数
  put_ymsCategoryProductAttribute: "/ymsCategoryProductAttribute", //  分类商品属性-修改listing
  delete_ymsCategoryProductAttribute: "/ymsCategoryProductAttribute/", //  分类商品属性-删除listing
  post_ymsListingCategoryMappingAttribute_save:
    "/ymsListingCategoryMappingAttribute/save", // 平台属性和云卖属性映射保存
  get_ymsListingCategoryMapping: "/ymsListingCategoryMapping/", // {ymsListingCategoryMappingId}获取映射属性
  post_category_attribute_getCommonCategoryAttributes:
    "/erpCommon/listing-common-service/category/attribute/getCommonCategoryAttributes", // 获取平台属性
  post_amazon_getProductType:
    "/erpCommon/listing-common-service/amazon/getProductType", // 获取平台属性
  post_emag_category_getEmagFamilyTypes:
    "/erpCommon/listing-common-service/category/getEmagFamilyTypes", // 获取emag平台的FamilyType的数据
  post_category_attribute_variation_getAttributeValueByAttributeIdAndAttributeText:
    "/erpCommon/listing-common-service/category/attribute/variation/getAttributeValueByAttributeIdAndAttributeText", // 获取平台属性值
  post_ymsProductAttributeDictionary_query:
    "/ymsProductAttributeDictionary/query", // 获取云卖属性值名称
  post_ymsProductAttributeTemplate_excelImport:
    "/ymsProductAttributeTemplate/excelImport", // 物品属性导入
  get_carrier_queryByIds: "/carrier/queryByIds", // 获取物流商名称
  get_carrierShippingMethodPrice:
    "/carrierShippingMethodPriceModel/getByShippingMethodId", // 获取引用其他渠道的计费模板信息的数据
  get_carrier_queryForEnableCarriers: "/carrier/queryForEnableCarriers", // 查询可用物流商(api对接)
  get_erpCommonSettingParam_querySettingByType:
    "/erpCommonSettingParam/querySettingByType/", // 获取重量单位数据
  get_queryShippingMethod: "/merchantShippingMethod/queryShippingMethod", // 商户平台-服务
  add_merchantShippingMethod: "/merchantShippingMethod", // 新增商户平台邮寄方式
  delete_merchantShippingMethod: "/merchantShippingMethod/", // 删除商户平台邮寄方式
  post_ymsDistributorMerchantRechargeRecord_query:
    "/ymsDistributorMerchantRechargeRecord/query", // 充值记录列表
  post_ymsDistributorMerchantRechargeRecord_exportDistributorMerchantRechargeRecord:
    "/ymsDistributorMerchantRechargeRecord/exportDistributorMerchantRechargeRecord", // 导出充值记录数据
  // 客户管理
  post_ymsDistributorMerchant_create: "/ymsDistributorMerchant/create", // 新增客户
  post_ymsDistributorMerchant_query: "/ymsDistributorMerchant/query", // 客户管理列表
  put_ymsDistributorMerchant_disable: "/ymsDistributorMerchant/disable/", // 停用客户
  put_ymsDistributorMerchant_enable: "/ymsDistributorMerchant/enable/", // 启用客户
  put_ymsDistributorMerchant_update: "/ymsDistributorMerchant/update", // 编辑客户
  get_ymsMerchantApiOper_query: "/ymsMerchantApiOper/query/", // 获取客户api权限的基础数据
  post_ymsMerchantApiOper_insertOrUpdate: "/ymsMerchantApiOper/insertOrUpdate", // 更新客户api权限
  get_ymsDistributorMerchant_id: "/ymsDistributorMerchant/", // 查看客户
  put_ymsDistributorMerchant_settleIn: "/ymsDistributorMerchant/settleIn", // 成为客户
  post_ymsDistributorMerchant_configurationTabs:
    "/ymsDistributorMerchant/configurationTabs", // 批量配置标签
  put_ymsDistributorMerchant_approvalPass:
    "/ymsDistributorMerchant/approvalPass", // 批量审核
  post_ymsDistributorMerchantGrade_create:
    "/ymsDistributorMerchantGrade/create", // 新增客户等级
  put_ymsDistributorMerchantGrade_update: "/ymsDistributorMerchantGrade/update", // 更新客户等级
  post_ymsDistributorMerchantGrade_query: "/ymsDistributorMerchantGrade/query", // 客户等级列表
  get_ymsDistributorMerchantGrade_list: "/ymsDistributorMerchantGrade/list", // 获取所有客户等级数据
  get_ymsDistributorMerchantGrade: "/ymsDistributorMerchantGrade/", // 查看客户等级
  delete_ymsDistributorMerchantGrade: "/ymsDistributorMerchantGrade/", // 删除客户等级
  put_ymsDistributorMerchantGrade: "/ymsDistributorMerchantGrade/", // 设置默认等级
  post_ymsDistributorMerchant_ymsCustomerInfoFu:
    "/ymsDistributorMerchant/ymsCustomerInfoFu", // 获取客户旅程数据
  get_ymsDistributorMerchant_ymsCustomerInfoFu_queryCustomerInfoStatusList:
    "/ymsDistributorMerchant/ymsCustomerInfoFu/queryCustomerInfoStatusList", // 获取客户旅程标记信息
  // 供应商管理
  post_ymsSupplierMerchant_create: "/ymsSupplierMerchant/create",
  post_ymsSupplierMerchant_disable: "/ymsSupplierMerchant/disable",
  post_ymsSupplierMerchant_enable: "/ymsSupplierMerchant/enable",
  post_ymsSupplierMerchant_query: "/ymsSupplierMerchant/query",
  post_ymsSupplierMerchant_updateSupplierCompanyInfo:
    "/ymsSupplierMerchant/updateSupplierCompanyInfo", // 更新供应商的注册企业信息
  post_ymsSupplierMerchant_queryAuditList:
    "/ymsSupplierMerchant/queryAuditList", // 供应商审核列表
  get_ymsSupplierMerchant_queryTotalBySupplierAuditStatus:
    "/ymsSupplierMerchant/queryTotalBySupplierAuditStatus", // 获取供应商不同状态下的总数
  post_ymsSupplierMerchant_updateSupplierAuditInfo:
    "/ymsSupplierMerchant/updateSupplierAuditInfo", // 审核、驳回供应商
  post_ymsSupplierMerchant_updateSupplierCompanyAuditInfo:
    "/ymsSupplierMerchant/updateSupplierCompanyAuditInfo", // 供应商工商信息变更审核
  put_ymsSupplierMerchant_update: "/ymsSupplierMerchant/update",
  get_ymsSupplierMerchant: "/ymsSupplierMerchant/",
  get_ymsSupplierMerchant_audit: "/ymsSupplierMerchant/audit/",
  get_ymsSupplierMerchant_queryPayAccount:
    "/ymsSupplierMerchant/queryPayAccount/", // 查看收款信息
  get_ymsProductCategory: "/ymsProductCategory", // 查看商品分类
  post_ymsPackageCost_query: "/ymsPackageCost/query", // 费用明细列表
  post_ymsSupplierMerchantGrade_create: "/ymsSupplierMerchantGrade/create", // 新增供应商等级
  get_ymsSupplierMerchantGrade_list: "/ymsSupplierMerchantGrade/list", // 获取供应商等级所有数据
  post_ymsSupplierMerchantGrade_query: "/ymsSupplierMerchantGrade/query", // 获取供应商等级列表数据
  get_ymsSupplierMerchantGrade: "/ymsSupplierMerchantGrade/", // 查询供应商等级
  put_ymsSupplierMerchantGrade_update: "/ymsSupplierMerchantGrade/update", // 更新供应商等级
  delete_ymsSupplierMerchantGrade: "/ymsSupplierMerchantGrade/", // 删除供应商等级
  post_ymsProductCategory_queryAll: "/ymsProductCategory/queryAll", // 获取所有分类
  post_ymsProductCategory_api_queryAll:
    "/erpCommon/yms-core-service/ymsProductCategory/api/queryAll", // 获取云卖分类的数据
  get_ymsProductCategory_detail: "/ymsProductCategory/detail/", // 获取物品属性的数据（只返回已启用的可选值）
  get_ymsProductCategory_detailForAll: "/ymsProductCategory/detailForAll/", // 获取物品属性的数据 （返回所有可选值）
  get_ymsProductCategory_shopping: "/ymsProductCategory/shopping/", // 获取物品属性的数据 (shopping)
  get_ymsProductSizeTemplate_queryByYmsProductCategoryId:
    "/ymsProductSizeTemplate/queryByYmsProductCategoryId/", // 获取尺码表的数据
  put_ymsProductCategory_sort: "/ymsProductCategory/sort", // 商品分类排序

  // 商城
  // 商城--首页
  post_ymsProductCategory_shopping_queryForExistsProduct:
    "/ymsProductCategory/shopping/queryForExistsProduct", // 返回当前分类下都是有sku的分类接口
  get_ymsCommon_shopping_baseInfo: "/ymsCommon/shopping/baseInfo", // 获取商城信息
  get_ymsSupplierMerchant_getYmsMerchantByDomain:
    "/ymsSupplierMerchant/queryBySupplierWebDomain", // 根据域名获取供应商信息

  post_ymsUserSetting_sendMobileVerificationCode:
    "/ymsUserInfo/sendMobileVerificationCode", // 发送手机验证码
  post_ymsUserSetting_sendEmailVerificationCode:
    "/ymsUserInfo/sendEmailVerificationCode", // 发送邮箱验证码
  post_ymsDistributorMerchant_registry: "/ymsDistributorMerchant/registry", // 手机/邮箱注册
  post_ymsDistributorMerchant_tokerLinkEventReport:
    "/ymsDistributorMerchant/tokerLinkEventReport", // 拓客事件上报
  put_ymsUserSetting_settingPassword: "/ymsUserInfo/settingPassword", //设置密码
  post_ymsUserInfo_login: "/ymsUserInfo/login", // 登录
  post_ymsUserInfo_settleIn: "/ymsUserInfo/settleIn", // 成为分销商
  yms_get_captcha: "/captcha", // 图片验证码链接
  get_ymsUserInfo_getLoginFailTimes: "/ymsUserInfo/getLoginFailTimes", // 获取登录失败的次数
  post_ymsUserSetting_validVerificationCode:
    "/ymsUserInfo/validVerificationCode", // 下一步
  get_ymsSupplierMerchant_isAssociatedStore:
    "/ymsSupplierMerchant/isAssociatedStore", // 是否关注供应商店铺
  post_ymsSupplierMerchant_addAssociatedStore:
    "/ymsSupplierMerchant/addAssociatedStore", // 关注供应商店铺
  post_ymsSupplierMerchant_deleteAssociatedStore:
    "/ymsSupplierMerchant/deleteAssociatedStore", // 取消关注

  // 尺码管理
  post_ymsProductSizeTemplate: "/ymsProductSizeTemplate", // 新增尺码模板
  put_ymsProductSizeTemplate: "/ymsProductSizeTemplate", // 更新出门模板
  get_ymsProductSizeTemplate_query: "/ymsProductSizeTemplate/query", // 获取尺码表模板列表的数据
  delete_ymsProductSizeTemplate: "/ymsProductSizeTemplate/", // 删除尺码模板
  get_ymsProductSizeTemplate: "/ymsProductSizeTemplate/", // 获取尺码模板的详情的数据
  get_ymsProductSizeTemplate_isBindingCategorys:
    "/ymsProductSizeTemplate/isBindingCategorys", // 获取所有已绑定模板的分类
  get_ymsProductSizeValue_query: "/ymsProductSizeValue/query", // 获取尺码类型的列表数据
  post_ymsProductSizeValue_queryByYmsProductSizeValueIds:
    "/ymsProductSizeValue/queryByYmsProductSizeValueIds", // 获取尺码类型的详情数据

  // 待审核列表
  post_ymsProductInfoTemplate_query: "/ymsProductInfoTemplate/query", // 待审核列表
  get_ymsProductInfoTemplate: "/ymsProductInfoTemplate/", // 待审核列表商品明细
  put_ymsProductInfoTemplate_approved: "/ymsProductInfoTemplate/approved/", // 审核通过
  put_ymsProductInfoTemplate_batchApproved:
    "/ymsProductInfoTemplate/batchApproved", // 批量审核通过
  put_ymsProductInfoTemplate_batchRejected:
    "/ymsProductInfoTemplate/batchRejected", // 批量驳回
  post_ymsProductInfo_shopping_queryAlibabaTongkuan:
    "/ymsProductInfo/shopping/queryAlibabaTongkuan", // 1688找同款

  // 入库管理
  post_ymsPickupOrder_query: "/ymsPickupOrder/query", // 提单管理列表
  post_ymsPickupOrder_invalid: "/ymsPickupOrder/invalid", // 作废提单
  post_ymsPickupOrder_pickupOrderSkuDetail:
    "/ymsPickupOrder/pickupOrderSkuDetail", // 查当前提单下的sku
  put_ymsPickupOrder_batchDelivery: "/ymsPickupOrder/batchDelivery", // 批量标记已揽收
  put_ymsPickupOrder_delivery: "/ymsPickupOrder/delivery/", // 单个标记已揽收
  post_ymsPickupOrder_detail: "/ymsPickupOrder/detail", // 获取提单管理详情的数据
  get_ymsPickupOrder_queryLogistics: "/ymsPickupOrder/queryLogistics", // 查询被使用过的物流商
  get_expressageHundred_query:
    "/erpCommon/common-service/expressageHundred/query", // 获取运输方式的数据
  post_ymsPickupOrderDetail_queryPickOrderDetail:
    "/ymsPickupOrderDetail/queryPickOrderDetail", // 获取提单管理--备货单、销售单的明细
  post_ymsPickupOrder_createNote: "/ymsPickupOrder/createNote", // 添加操作日志的备注
  post_ymsCarrierHandoverOrder_query: "/ymsCarrierHandoverOrder/query", // 物流商交接单管理列表
  get_ymsCarrierHandoverOrder_getHadnloverBys:
    "/ymsCarrierHandoverOrder/getHadnloverBys", // 获取交接人数据
  get_ymsCarrierHandoverOrder_queryCarrierHandover:
    "/ymsCarrierHandoverOrder/queryCarrierHandover", // 获取交接单下的物流商
  put_ymsCarrierHandoverOrder_appointmentCollection:
    "/ymsCarrierHandoverOrder/appointmentCollection/", // 预约揽收
  get_ymsCarrierHandoverOrder_printCollectionReceipt:
    "/ymsCarrierHandoverOrder/printCollectionReceipt/", // 打印揽收面单
  put_ymsCarrierHandoverOrder_updateDelivery:
    "/ymsCarrierHandoverOrder/updateDelivery", // 批量标记已出库
  put_ymsCarrierHandoverOrder_batchUpdateDelivery:
    "/ymsCarrierHandoverOrder/batchUpdateDelivery/", // 单个标记已出库
  get_ymsCarrierHandoverOrder: "/ymsCarrierHandoverOrder/", // 获取物流商交接单管理列表详情的数据
  put_ymsCarrierHandoverOrder_printCarrierHandoverOrder:
    "/ymsCarrierHandoverOrder/printCarrierHandoverOrder/", // 物流交接单管理打印箱唛
  post_erpCommonSettingParam_queryByParamKeys:
    "/erpCommonSettingParam/queryByParamKeys", // 获取系统的属性设置数据
  post_erpCommonSettingParam_queryByParams:
    "/erpCommonSettingParam/queryByParams", // 获取系统拣货属性设置数据
  post_erpCommonSettingParam_queryByParam: "/erpCommonSettingParam/queryByParam", // 查询自定义设置
  post_erpCommonSettingParam_save: "/erpCommonSettingParam/save", // 保存系统的属性设置
  put_ymsPickupOrder_scanPickupOrder: "/ymsPickupOrder/scanPickupOrder/", // 扫描提单号
  put_ymsCarrierHandoverOrder_startSorting:
    "/ymsCarrierHandoverOrder/startSorting/", // 扫描包裹
  put_ymsPackageInfo_packingWeighting: "/ymsPackageInfo/packingWeighting", // 称重
  post_ymsCarrierHandoverOrder_endSorting:
    "/ymsCarrierHandoverOrder/endSorting", // 结袋
  get_ymsCarrierHandoverOrder_querySortingCarrierHandoverOrder:
    "/ymsCarrierHandoverOrder/querySortingCarrierHandoverOrder", // 查询当前正在处理的交接单
  get_ymsPickupOrder_pickingQuery: "/ymsPickupOrder/pickingQuery", // 获取分拣中提单列表
  post_ymsCarrierHandoverOrder_removePackage:
    "/ymsCarrierHandoverOrder/removePackage", // 移除包裹
  put_ymsPickupOrder_warehousing: "/ymsPickupOrder/warehousing/", // 提单入库
  put_ymsPickupOrder_goodsList: "/ymsPickupOrder/goodsList", // 获取货品上架的详情信息
  put_ymsPickupOrder_onShelves: "/ymsPickupOrder/onShelves", // 货品上架
  post_productGoods_appendYmsExpressBill: "/productGoods/appendYmsExpressBill", // 生成云卖面单
  post_ymsPickupOrder_orderListExport: "/ymsPickupOrder/orderListExport", // 提单管理--数据导出
  // 备货数据分拣
  get_ymsCarrierHandoverOrder_querySortingForThirdPlatform:
    "/ymsCarrierHandoverOrder/querySortingForThirdPlatform", // 查询当前正在处理的备货交接单
  put_ymsCarrierHandoverOrder_startSortingForThirdPlatform:
    "/ymsCarrierHandoverOrder/startSortingForThirdPlatform/", // 扫描备货包裹
  put_ymsCarrierHandoverOrder_printHandoverOrderForThirdPlatform:
    "/ymsCarrierHandoverOrder/printHandoverOrderForThirdPlatform/", // 备货分拣打印箱唛
  post_ymsCarrierHandoverOrder_endSortingForThirdPlatform:
    "/ymsCarrierHandoverOrder/endSortingForThirdPlatform", // 备货结袋

  // 即时经营数据
  get_ymsReportManagementStatistics_queryTwoDay:
    "/ymsReportManagementStatisticsController/queryTwoDay",

  // 基础设置
  get_ymsWarehouse_query: "/ymsWarehouse/query", // 获取仓库列表的数据
  get_ymsWarehouse_queryWarehouse: "/ymsWarehouse/queryWarehouse/", // 获取已启用的仓库数据
  post_ymsWarehouse: "/ymsWarehouse", // 新增仓库
  get_ymsWarehouse: "/ymsWarehouse/", // 查看仓库详情信息
  put_ymsWarehouse: "/ymsWarehouse", // 更新仓库
  put_ymsWarehouse_enable: "/ymsWarehouse/enable/", // 启用仓库
  put_ymsWarehouse_disable: "/ymsWarehouse/disable/", // 停用仓库

  post_ymsWarehouseBlock: "/ymsWarehouseBlock", // 新增库区
  put_ymsWarehouseBlock: "/ymsWarehouseBlock", // 更新库区
  put_ymsWarehouseBlock_disable: "/ymsWarehouseBlock/disable/", // 停用库区
  put_ymsWarehouseBlock_enable: "/ymsWarehouseBlock/enable/", // 启用库区
  get_ymsWarehouseBlock: "/ymsWarehouseBlock/", // 获取库区详情数据
  post_ymsWarehouseBlock_query: "/ymsWarehouseBlock/query", // 获取库区列表数据

  post_ymsWarehouseLocation: "/ymsWarehouseLocation", // 新增库位
  put_ymsWarehouseLocation: "/ymsWarehouseLocation", // 更新库位
  put_ymsWarehouseLocation_disable: "/ymsWarehouseLocation/disable/", // 停用库位
  put_ymsWarehouseLocation_enable: "/ymsWarehouseLocation/enable/", // 启用库位
  get_ymsWarehouseLocation: "/ymsWarehouseLocation/", // 获取库位详情数据
  post_ymsWarehouseLocation_query: "/ymsWarehouseLocation/query", // 获取库位列表数据
  post_ymsWarehouseLocation_excelImport: "/ymsWarehouseLocation/excelImport", // 导入库位
  get_ymsWarehouseBlock_queryByYmsWarehouesId:
    "/ymsWarehouseBlock/queryByYmsWarehouseId", // 根据仓库获取对应库区
  get_ymsWarehouseLocation_queryByYmsWarehouseBlockId:
    "/ymsWarehouseLocation/queryByYmsWarehouseBlockId", // 根据库区获取对应库位数据
  post_ymsWarehouse_ymsProductInfo_ymsWarehouseLocationIds:
    "/ymsWarehouse/ymsProductInfo/ymsWarehouseLocationIds", // 根据库位id获取仓库的库区、仓库的相关数据
  // 基础物流
  add_customShip: "/carrierShippingMethod/addDefine", // 添加自定义物流方式
  set_updateWithAccount: "/carrierShippingMethod/updateWithAccount", // 速卖通线上发货 更新物流方式
  set_carrierShippingMethod: "/carrierShippingMethod/", // 物流-更新物流方式

  get_carrierAliexpressDetail:
    "/carrierShippingMethod/detailByAccountShippingMethodId", // 速卖通线上发货
  get_carrierShippingMethod: "/carrierShippingMethod/", // 获取物流渠道设置详情
  get_orderCommonParameter: "/erpCommon/common-service/commonParameter/list", // 物流 remark
  get_carrierAliexpressAddress:
    "/erpCommon/setting-service/carrierPlatformAccountAddress/", // 获取速卖通线上发货设置信息

  get_carrier_queryForDisableCarriers: "/carrier/queryForDisableCarriers", // 查询未启用的物流商
  post_carrier_insertWithApi: "/carrier/insertWithApi/", // 新增API--对接物流商
  post_carrier_addDefine: "/carrier/addDefine", // 新增--自定义物流商
  get_carrier_listDefine: "/carrier/listDefine", //  查询可用物流商(自定义)
  get_carrierShippingMethod_queryForEnableShippingMethods:
    "/carrierShippingMethod/queryForEnableShippingMethods", // 获取物流渠道列表数据
  put_carrier_updateDefine: "/carrier/updateDefine", // 编辑自定义物流,
  put_carrierShippingMethod_enable: "/carrierShippingMethod/enable", // 物流商渠道的启用、停用
  put_carrierShippingMethod_disable: "/carrierShippingMethod/disable", // 物流商渠道的停用
  get_ymsLogisServiceMapping_disable: "/ymsLogisServiceMapping/disable/", // 物流商渠道的停用（新接口）
  get_carrierAccount_queryByCarrierId: "/carrierAccount/queryByCarrierId/", // 查询物流账号信息
  get_carrierVovaAddress: "/erpCommon/setting-service/carrierVovaAddress", // vova地址查询
  get_carrierPlatformAccountAddress_syncAddress:
    "/erpCommon/setting-service/carrierPlatformAccountAddress/syncAddress", // 速卖通线上发货 同步
  get_carrierAccount_getCarrierAccount: "/carrierAccount/getCarrierAccount/", // 获取新账户模板{carrierId}
  post_carrierAccount_save: "/carrierAccount/save", // 保存账号信息
  delete_carrierAccount: "/carrierAccount/", // 删除账号
  post_carrierAccount_auth: "/carrierAccount/auth", // 01 授权验证
  get_carrierShippingMethod_queryForDisableShippingMethods:
    "/carrierShippingMethod/queryForDisableShippingMethods", // 查询未启用的物流方式
  post_ymsCarrierAccount_applyAuth: "/ymsCarrierAccount/applyAuth", // 申请授权

  // 云卖物流
  post_ymsLogisService_query: "/ymsLogisService/query", // 云卖物流列表

  put_ymsLogisService_enable: "/ymsLogisService/enable/", // 云卖物流--启用
  put_ymsLogisService_disable: "/ymsLogisService/disable/", // 云卖物流--停用
  post_ymsLogisService: "/ymsLogisService", // 新增物流渠道
  put_ymsLogisService: "/ymsLogisService", // 更新物流渠道
  get_ymsLogisService: "/ymsLogisService/", // 查看物流渠道信息

  get_ymsLogisServiceMapping: "/ymsLogisServiceMapping/", // 获取关联物流的数据
  post_ymsLogisServiceMapping_save: "/ymsLogisServiceMapping/save", // 保存关联物流
  post_ymsPackageRuleDetails_save: "/ymsPackageRuleDetails/save", // 保存申报规则
  get_ymsPackageRuleDetails_default: "/ymsPackageRuleDetails/default", // 详情
  get_ymsPackageRuleDetails: "/ymsPackageRuleDetails/", // 获取申报规则设置
  delete_ymsPackageDeclareAutoRule: "/ymsPackageDeclareAutoRule", // 删除
  post_ymsPackageDeclareAutoRule: "/ymsPackageDeclareAutoRule", // 新增
  put_ymsPackageDeclareAutoRule: "/ymsPackageDeclareAutoRule", // 更新
  put_ymsPackageDeclareAutoRule_disable: "/ymsPackageDeclareAutoRule/disable/", // 停用
  put_ymsPackageDeclareAutoRule_enable: "/ymsPackageDeclareAutoRule/enable/", // 启用
  post_ymsPackageDeclareAutoRule_query: "/ymsPackageDeclareAutoRule/query", // 查询
  put_ymsPackageDeclareAutoRule_updatePriority:
    "/ymsPackageDeclareAutoRule/updatePriority", // 更新优先级
  get_ymsPackageDeclareAutoRule: "/ymsPackageDeclareAutoRule/", // 详情

  post_ymsCarrierAutoRule: "/ymsCarrierAutoRule", // 新增物流规则
  post_ymsCarrierAutoRule_query: "/ymsCarrierAutoRule/query", // 查询物流规则列表
  get_ymsCarrierAutoRule: "/ymsCarrierAutoRule/", // 获取物流规则详情
  put_ymsCarrierAutoRule_enable: "/ymsCarrierAutoRule/enable/", // 物流规则-启用
  put_ymsCarrierAutoRule_disable: "/ymsCarrierAutoRule/disable/", // 物流规则-停用
  put_ymsCarrierAutoRule: "/ymsCarrierAutoRule", // 更新物流规则
  put_ymsCarrierAutoRule_updatePriority: "/ymsCarrierAutoRule/updatePriority", // 更新自动规则优先级
  delete_ymsCarrierAutoRule: "/ymsCarrierAutoRule", // 删除物流规则

  get_ymsCarrierProposalRule: "/ymsCarrierProposalRule/", // 查询物流规则详情
  get_ymsCarrierProposalRule_query: "/ymsCarrierProposalRule/query", // 云卖基础物流方案规则
  put_ymsCarrierProposalRule_batchUpdate: "/ymsCarrierProposalRule/batchUpdate", //更新物流规则

  get_ymsCarrierScopeRule_warehouseId: "/ymsCarrierScopeRule/warehouseId/", // 查询物流渠道适用范围列表
  get_ymsCarrierScopeRule_query: "/ymsCarrierScopeRule/query", // 查询物流渠道适用范围列表
  put_ymsCarrierScopeRule: "/ymsCarrierScopeRule", // 更新物流渠道适用范围
  get_carrierAccount_authUrl: "/carrierAccount/authUrl/", // 获取授权url

  get_ymsWebsocket: "/notify/default/", // websocket连接后台服务实时更新消息通知
  post_ymsStationNotificationTemplate_query:
    "/ymsStationNotificationTemplate/query", // 获取消息通知模板列表数据
  get_ymsStationNotificationTemplate: "/ymsStationNotificationTemplate/", // 获取消息通知模板的详情数据
  post_ymsStationNotificationTemplate: "/ymsStationNotificationTemplate", // 新增消息通知模板
  put_ymsStationNotificationTemplate: "/ymsStationNotificationTemplate", // 更新消息通知模板
  delete_ymsStationNotificationTemplate: "/ymsStationNotificationTemplate/", // 删除消息通知模板
  post_ymsStationNotification_query: "/ymsStationNotification/query", // 获取消息中心列表的数据
  post_ymsStationNotification_batchRead: "/ymsStationNotification/batchRead", // 标记已读
  get_ymsStationNotification_markPopipRead:
    "/ymsStationNotification/markPopipRead/", // 弹窗消息通知（全部标记已读）
  get_ymsStationNotification: "/ymsStationNotification/", // 获取消息中心详情信息
  get_ymsStationNotification_count: "/ymsStationNotification/count", // 获取已读未读消息个数
  get_ymsStationNotification_lastUnRead: "/ymsStationNotification/lastUnRead", // 获取消息提醒的数据
  get_ymsDistributorStationNotification_lastUnRead:
    "/ymsDistributorStationNotification/lastUnRead", // 获取消息提醒的数据
  post_ymsSupplierMerchant_api_queryByIds:
    "/ymsSupplierMerchant/api/queryByIds", // 批量获取供应商基本信息

  post_role_query: "/role/query", // 获取所有角色列表
  get_role: "/role/", // 获取角色的详情数据
  post_ymsCoreUserInfo_id: "/ymsCoreUserInfo/", // 查看员工信息
  post_ymsCoreUserInfo: "/ymsCoreUserInfo", // 新增员工
  put_ymsCoreUserInfo: "/ymsCoreUserInfo", // 修改员工
  put_ymsCoreUserInfo_enable: "/ymsCoreUserInfo/enable", // 启用员工
  put_ymsCoreUserInfo_disable: "/ymsCoreUserInfo/disable", // 停用员工
  delete_role: "/role/", // 删除角色
  post_ymsCoreUserInfo_query: "/ymsCoreUserInfo/query", // 分页查询员工列表
  post_role: "/role", // 新增角色
  put_role: "/role", // 修改角色
  get_ymsCoreUserInfo_queryMenu: "/ymsCoreUserInfo/queryMenu", // 查询用户菜单
  post_ymsCoreUserInfo_queryOperByMenu: "/ymsCoreUserInfo/queryOperByMenu/", // 获取单页权限
  get_ymsCoreUserInfo_getAllUser: "/ymsCoreUserInfo/getAllUser", // 获取用户信息

  // 容器管理
  delete_ymsContainer_batchDelete: "/ymsContainer/batchDelete", // 批量删除容器
  post_ymsContainer_batchInsert: "/ymsContainer/batchInsert", // 新增
  put_ymsContainer_closeContainerSorting:
    "/ymsContainer/closeContainerSorting/", // 结束容器分拣 {code}
  post_ymsContainer_query: "/ymsContainer/query", // 查询容器列表
  delete_ymsContainer_query: "/ymsContainer/", // {ymsContainerId} 删除容器
  get_ymsContainer_queryContainerTypeList:
    "/ymsContainer/queryContainerTypeList", // 获取容器类型的数据
  get_ymsContainer_querySortingContainer: "/ymsContainer/querySortingContainer", // 查询当前正在处理的容器

  // 容器属性
  post_ymsContainerType: "/ymsContainerType", // 新增容器属性
  put_ymsContainerType: "/ymsContainerType", // 编辑容器属性
  delete_ymsContainerType: "/ymsContainerType/", // {ymsContainerTypeId} 删除容器
  get_ymsContainerType_query: "/ymsContainerType/query", // 查询容器属性列表
  put_ymsContainer_batchPrintContainer: "/ymsContainer/batchPrintContainer", // 批量打印容器条形码
  put_ymsContainer_printContainer: "/ymsContainer/printContainer", // 打印容器条形码

  // 商品贴单
  post_ymsContainerStatus_queryChangeOrderList:
    "/ymsContainerStatus/queryChangeOrderList", // 商品待贴单列表
  post_ymsContainerStatus_queryChangingOrderList:
    "/ymsContainerStatus/queryChangingOrderList", // 商品贴单中列表
  post_ymsContainerStatus_queryChanggedOrderList:
    "/ymsContainerStatus/queryChanggedOrderList", // 商品已贴单列表
  post_ymsContainerStatus_changingOrderExport:
    "/ymsContainerStatus/changingOrderExport", // 商品贴单导出
  get_ymsContainerStatus_getChangingOrder:
    "/ymsContainerStatus/getChangingOrder", // 查询当前用户是否有正在贴单中的操作

  get_ymsContainerStatus_startChangeOrder:
    "/ymsContainerStatus/startChangeOrder", // 开始换单
  post_ymsUserInfo_checkLogin: "/ymsUserInfo/checkLogin", // 检查登录
  post_ymsContainerGoodsCheck_batchAppendYmsExpressBill:
    "/ymsContainerGoodsCheck/batchAppendYmsExpressBill", // 批量扫描打印云卖面单
  post_ymsContainerGoodsCheck_appendYmsExpressBill:
    "/ymsContainerGoodsCheck/appendYmsExpressBill", // 单个扫描打印云卖面单
  get_ymsContainerStatus_endChangeOrder: "/ymsContainerStatus/endChangeOrder", // 结束商品贴单作业

  // 商品分拣
  post_ymsPickupOrder_queryWaitSortingList:
    "/ymsPickupOrder/queryWaitSortingList", // 商品待分拣列表
  post_ymsPickupOrder_querySortingList: "/ymsPickupOrder/querySortingList", // 入库分拣中列表
  post_ymsPickupOrder_querySortedList: "/ymsPickupOrder/querySortedList", // 入库已分拣列表
  post_ymsPickupOrder_batchExport: "/ymsPickupOrder/batchExport", // 入库分拣导出
  get_ymsPickupOrder_getSortingOrder: "/ymsPickupOrder/getSortingOrder", // 查询当前用户是否有正在分拣中的操作

  put_ymsPickupOrder_startSorting: "/ymsPickupOrder/startSorting", // 开始入库分拣
  get_ymsContainerStatus_querySortingContainerList:
    "/ymsContainerStatus/querySortingContainerList", // 正在分拣中容器列表
  post_ymsContainerStatus_bindingContainer:
    "/ymsContainerStatus/bindingContainer", // 绑定容器
  delete_ymsContainerStatus: "/ymsContainerStatus/", // 重新绑定容器
  put_ymsContainerStatus_closeContainerSorting:
    "/ymsContainerStatus/closeContainerSorting", // 结束容器分拣
  put_ymsPickupOrder_closeSorting: "/ymsPickupOrder/closeSorting", // 结束本单分拣
  post_ymsPickupOrder_scanningGoods: "/ymsPickupOrder/scanningGoods", // 扫描匹配货品
  post_ymsPickupOrderSorting_saveScanningGoods:
    "/ymsPickupOrderSorting/saveScanningGoods", // 扫描货品到容器
  put_ymsPickupOrder_quitSorting: "/ymsPickupOrder/quitSorting", // 结束入库分拣作业
  post_ymsPickupOrderSorting_updateSortingGoods:
    "/ymsPickupOrderSorting/updateSortingGoods", // 更新容器货品数量
  get_ymsPickupOrderSortingLog: "/ymsPickupOrderSortingLog/", // 获取当前提单中的扫描记录
  put_ymsPickupOrderSortingLog: "/ymsPickupOrderSortingLog/", // 分拣作业操作记录中的撤销操作

  // 商品上架
  post_ymsContainerStatus_queryWaitingShelves:
    "/ymsContainerStatus/queryWaitingShelves", // 待上架列表
  post_ymsContainerStatus_queryShelvesing:
    "/ymsContainerStatus/queryShelvesing", // 上架中列表
  post_ymsContainerStatus_queryShelved: "/ymsContainerStatus/queryShelved", // 已上架列表
  post_ymsContainerStatus_shelvesOrderExport:
    "/ymsContainerStatus/shelvesOrderExport", // 商品上架导出
  get_ymsContainerStatus_getShelvesingOrder:
    "/ymsContainerStatus/getShelvesingOrder", // 查询当前登录的用户是否有正在上架中的操作

  get_ymsContainerStatus_startShelves: "/ymsContainerStatus/startShelves", // 扫描上架
  get_ymsContainerStatus_shelvesDetail: "/ymsContainerStatus/shelvesDetail", // 上架、继续上架
  post_ymsContainerStatus_partShelves: "/ymsContainerStatus/partShelves", // 部分上架
  post_ymsContainerStatus_completeShelves:
    "/ymsContainerStatus/completeShelves", // 结束上架
  post_ymsContainerStatus_getShelvesQuantity:
    "/ymsContainerStatus/getShelvesQuantity", // 获取商品上架的数量数据

  // 数据统计
  post_ymsCoreCommon: "/ymsCoreCommon", // 查询订单销售趋势
  get_ymsCoreCommon_index: "/ymsCoreCommon/index", // 查询数据看板
  get_ymsCoreCommon_queryOrderRanking: "/ymsCoreCommon/queryOrderRanking", // 查询订单排名
  get_ymsCoreCommon_skuQuantity: "/ymsCoreCommon/skuQuantity", // 查询SKU商品数据

  // 帮助中心
  get_ymsHelpCategory_shopping_buildCategoryTree:
    "/ymsHelpCategory/shopping/buildCategoryTree", // 查询总目录
  get_ymsHelpCategory_shopping_listCategorys:
    "/ymsHelpCategory/shopping/listCategorys", // 列表
  get_ymsHelpCategory_shopping_selectByPrimaryKey:
    "/ymsHelpCategory/shopping/selectByPrimaryKey", // 文章详情
  get_ymsHelpDocs_shopping_recordShowQuantity:
    "/ymsHelpDocs/shopping/recordShowQuantity", // 记录浏览量
  post_ymsHelpDocs_shopping_listDocs: "/ymsHelpDocs/shopping/listDocs", // 商城获取帮助中心推荐文章列表数据

  post_ymsHelpCategory_buildCategoryTree: "/ymsHelpCategory/buildCategoryTree", // 帮助中心--树形目录列表数据
  post_ymsHelpDocs_listDocsPage: "/ymsHelpDocs/listDocsPage", // 获取文章列表数据(有分页)
  post_ymsHelpDocs_listDocs: "/ymsHelpDocs/listDocs", // 获取文章列表数据 (没有分页)
  post_ymsHelpDocs_update: "/ymsHelpDocs/update", // 文章的更新接口
  post_ymsHelpDocs_delete: "/ymsHelpDocs/delete", // 删除文章的接口
  post_ymsHelpDocs_insert: "/ymsHelpDocs/insert", // 新增文章
  get_ymsHelpDocs_selectByPrimaryKey: "/ymsHelpDocs/selectByPrimaryKey", // 获取文章信息
  get_erpCommon_ueditorEnter: "/erpCommon/ueditorEnter", // 帮助中心--富文本编辑器
  post_ymsHelpCategory_delete: "/ymsHelpCategory/delete", // 删除目录
  post_ymsHelpCategory_insert: "/ymsHelpCategory/insert", // 新增目录
  post_ymsHelpCategory_update: "/ymsHelpCategory/update", // 更新目录

  post_ymsPickupOrderDetail_appendYmsExpressBill:
    "/ymsPickupOrderDetail/appendYmsExpressBill", // 分拣作业--打印面单

  get_ymsProductCategory_checkIsApparelCategory:
    "/ymsProductCategory/checkIsApparelCategory", // 判断平台是否开通服装大类

  post_ymsPlatform_query: "/ymsPlatform/query", //获取平台
  get_shopping_settleInMerchant: "/ymsCommon/shopping/settleInMerchant", //入驻商户
  get_merchant_queryMerchantById:
    "/erpCommon/common-service/merchant/queryMerchantById", // 查询商户

  get_erpCommonSettingParam_queryPaymentPassword:
    "/erpCommonSettingParam/queryPaymentPassword", // 获取打款密码
  put_erpCommonSettingParam_savePaymentPassword:
    "/erpCommonSettingParam/savePaymentPassword/", // 保存打款密码
  post_erpCommonSettingParam_updatePaymentPassword:
    "/erpCommonSettingParam/updatePaymentPassword", // 更换打款密码
  delete_erpCommonSettingParam: "/erpCommonSettingParam/", // 删除打款密码

  get_ymsPackageInfo_checkScanPackage: "/ymsPackageInfo/checkScanPackage/", // 检查扫描包裹中是否存在已取消的订单
  put_ymsPickingOrder_checkPickingOrder: "/ymsPickingOrder/checkPickingOrder", // 校验拣货单中出库单状态
  put_ymsPickingOrderRelated_deleteYmsPickingRelatedByIds:
    "/ymsPickingOrderRelated/deleteYmsPickingRelatedByIds", // 批量移除已取消的出库单

  // 营销管理
  post_ymsActivityPlan_query: "/ymsActivityPlan/query", // 查询活动列表数据
  post_ymsActivityPlan_save: "/ymsActivityPlan/save", // 新增活动
  put_ymsActivityPlan_update: "/ymsActivityPlan/update", // 编辑活动
  get_ymsActivityPlan_detail: "/ymsActivityPlan/detail/", // 获取活动详情数据
  put_ymsActivityPlan_updateStatus: "/ymsActivityPlan/updateStatus/", // 修改活动状态
  post_ymsProductInfo_queryProductPageInfo:
    "/ymsProductInfo/queryProductPageInfo", // 获取spu级别的商品信息
  post_ymsProductInfo_queryYmsProductListInfo:
    "/ymsProductInfo/queryYmsProductListInfo", //  获取spu级别的商品信息（没有分页）
  get_ymsActivityPlan_shopping: "/ymsActivityPlan/shopping", // 查询商城中正在进行中的活动

  get_ymsAdvertisement: "/ymsAdvertisement", // 查询广告数据
  get_ymsAdvertisement_detail: "/ymsAdvertisement/detail/", // 获取广告的详情信息
  put_ymsAdvertisement_updateStatus: "/ymsAdvertisement/updateStatus/", // 修改活动广告状态
  put_ymsAdvertisement_update: "/ymsAdvertisement/update", // 编辑广告活动
  post_ymsAdvertisement_save: "/ymsAdvertisement/save", // 新增广告活动
  get_ymsAdvertisement_shopping: "/ymsAdvertisement/shopping", // 查询商城已启用的广告

  get_erpCommonSettingParam_queryPickupParam:
    "/erpCommonSettingParam/queryPickupParam/", // 获取用户入库配置
  post_erpCommonSettingParam_saveOrUpdatePickupParam:
    "/erpCommonSettingParam/saveOrUpdatePickupParam", // 修改用户入库配置
  put_ymsWarehouse_addVirtualWarehouse: "/ymsWarehouse/addVirtualWarehouse/", // 新增虚拟库位库区
  post_ymsContainer_updateColor: "/ymsContainer/updateColor", // 更换容器颜色
  get_ymsContainer_queryContainerColor: "/ymsContainer/queryContainerColor", // 获取容器颜色的基础数据
  get_ymsPickupOrder_checkSortingOrder: "/ymsPickupOrder/checkSortingOrder", // 查看是否有分拣中提单

  post_carrier_updateDistributorUsage: "/carrier/updateDistributorUsage", // 更新分销商可用状态
  post_carrier_coreUsageStatus: "/carrier/coreUsageStatus", // 更新运营可用状态
  put_carrier_saveUsePlatform: "/carrier/saveUsePlatform", // 更新平台可以范围
  get_carrier: "/carrier/", // 获取物流商详情

  post_ymsPackageInfoDataCenter_query: "/ymsPackageInfoDataCenter/query", // 获取出库明细列表数据
  post_ymsPackageInfoDataCenter_export: "/ymsPackageInfoDataCenter/export", // 导出出库明细数据

  put_ymsCarrierHandoverOrder_printHandoverPdfGetr:
    "/ymsCarrierHandoverOrder/printHandoverPdfGetr/", // 打印预交面单

  post_ymsQuestionnaire: "/ymsQuestionnaire", // 新增问卷
  put_ymsQuestionnaire: "/ymsQuestionnaire", // 更新问卷
  get_ymsQuestionnaire: "/ymsQuestionnaire/", // 获取问卷的详情
  post_ymsQuestionnaire_query: "/ymsQuestionnaire/query", // 问卷调查列表
  put_ymsQuestionnaire_disable: "/ymsQuestionnaire/disable/", // 停用问卷
  put_ymsQuestionnaire_enable: "/ymsQuestionnaire/enable/", // 启用问卷
  post_ymsQuestionnaire_queryQuantityStatistics:
    "/ymsQuestionnaire/queryQuantityStatistics", // 问卷统计列表
  post_ymsQuestionnaire_exportQuantityStatistics:
    "/ymsQuestionnaire/exportQuantityStatistics", // 导出问卷统计数据

  post_ymsWarehouseAutoRule_query: "/ymsWarehouseAutoRule/query", // 自动分仓规则列表查询
  put_ymsWarehouseAutoRule_updatePriority:
    "/ymsWarehouseAutoRule/updatePriority", // 自动分仓规则优先级
  delete_ymsWarehouseAutoRule: "/ymsWarehouseAutoRule", // 删除自动分仓规则
  post_ymsWarehouseAutoRule: "/ymsWarehouseAutoRule", // 新增自动分仓规则
  put_ymsWarehouseAutoRule: "/ymsWarehouseAutoRule", // 更新自动分仓规则
  get_ymsWarehouseAutoRule: "/ymsWarehouseAutoRule/", // 获取自动分仓规则详情

  get_ymsCoreUserInfo_getYmsUserWarehouseOpers:
    "/ymsCoreUserInfo/getYmsUserWarehouseOpers/", // 查询商户下拥有所有授权仓库的用户

  post_ymsCurrencyConfig_insert: "/ymsCurrencyConfig/insert", // 新增币种设置
  get_ymsCurrencyConfig_query: "/ymsCurrencyConfig/query", // 查询系统币种配置数据
  post_exchangeRate_settingUpdateRate: "/exchangeRate/settingUpdateRate", // 更新商户汇率比率
  get_ymsDistributorMerchant_queryDistributorMerchantCurrency:
    "/ymsDistributorMerchant/queryDistributorMerchantCurrency", // 查询商户在分销商系统中设置的币种

  put_ymsPickingOrder_startPackingForThirdPlatform:
    "/ymsPickingOrder/startPackingForThirdPlatform", // 开始备货包装
  post_ymsPickingOrder_endPackingForThirdPlatform:
    "/ymsPickingOrder/endPackingForThirdPlatform", // 结束备货包装
  post_ymsThirdPlatformPackage_scanYmsSku:
    "/ymsThirdPlatformPackage/scanYmsSku", // 根据sku扫描包裹匹配包裹信息
  put_ymsThirdPlatformPackageGoods_manualUpdatedPackageSku:
    "/ymsThirdPlatformPackageGoods/manualUpdatedPackageSku", // 手动修改扫描的小包中sku数量
  put_ymsThirdPlatformPackage_endPacking: "/ymsThirdPlatformPackage/endPacking", // 小包结袋称重
  post_ymsThirdPlatformPackageGoods_batchDelete:
    "/ymsThirdPlatformPackageGoods/batchDelete", // 清空已扫描货品并重新扫描
  post_ymsThirdPlatformPackageGoods_deleteSingle:
    "/ymsThirdPlatformPackageGoods/deleteSingle", // 清空小包包裹下单个sku
  post_ymsPickingOrder_finishOrderExecuteCreate:
    "/ymsPickingOrder/finishOrderExecuteCreate", // 整单完结

  put_ymsSubsidyAutoRule_disable: "/ymsSubsidyAutoRule/disable/", // 停用补贴规则
  put_ymsSubsidyAutoRule_enable: "/ymsSubsidyAutoRule/enable/", // 启用补贴规则
  put_ymsSubsidyAutoRule_updatePriority: "/ymsSubsidyAutoRule/updatePriority", // 更新补贴规则优先级
  post_ymsSubsidyAutoRule: "/ymsSubsidyAutoRule", // 新增补贴规则
  put_ymsSubsidyAutoRule: "/ymsSubsidyAutoRule", // 更新补贴规则
  get_ymsSubsidyAutoRule: "/ymsSubsidyAutoRule/", // 获取补贴规则详情
  get_ymsSubsidyAutoRule_query: "/ymsSubsidyAutoRule/query", // 获取补贴规则列表数据

  post_ymsPickupOrder_querySubsidyAudit: "/ymsPickupOrder/querySubsidyAudit", // 补贴审核列表
  post_ymsPickupOrder_againSubsidyAudit: "/ymsPickupOrder/againSubsidyAudit", // 重新审核
  post_ymsPickupOrder_batchSubsidyAudit: "/ymsPickupOrder/batchSubsidyAudit", // 批量补贴审核
  post_ymsPickupOrder_updateSubsidyAudit: "/ymsPickupOrder/updateSubsidyAudit", // 更新补贴金额
  post_ymsPickupOrder_exportSubsidyAudit: "/ymsPickupOrder/exportSubsidyAudit", // 导出补贴审核数据
  get_ymsPickupOrder_querySubsidyRuleLog: "/ymsPickupOrder/querySubsidyRuleLog", // 获取补贴规则名称数据
  get_ymsPickupOrder_subsidyDetails: "/ymsPickupOrder/subsidyDetails/", // 补贴审核--说明详情

  post_ymsSubPackageInfo_queryForStock: "/ymsSubPackageInfo/queryForStock", // 备货单列表数据
  get_ymsSubPackageInfo_subPackageInfoStock:
    "/ymsSubPackageInfo/subPackageInfoStock/", // 查看备货单详情
  post_ymsSubPackageInfo_queryPackageGoodsById:
    "/ymsSubPackageInfo/queryPackageGoodsById", // 获取备货单sku列表数据
  post_ymsSubPackageInfo_exportPackageInfoStockOrder:
    "/ymsSubPackageInfo/exportPackageInfoStockOrder", // 导出备货数据

  get_ymsOperatingInstructions_queryList:
    "/ymsOperatingInstructions/queryList/", // 根据指定系统获取对应的指引列表数据
  put_ymsOperatingInstructions_disable: "/ymsOperatingInstructions/disable/", // 停用指引
  put_ymsOperatingInstructions_enable: "/ymsOperatingInstructions/enable/", // 启用指引
  delete_ymsOperatingInstructions_delete: "/ymsOperatingInstructions/delete/", // 删除指引
  put_ymsOperatingInstructions: "/ymsOperatingInstructions", // 更新指引
  post_ymsOperatingInstructions_save: "/ymsOperatingInstructions/save", // 新增指引
  get_ymsOperatingInstructions_detail: "/ymsOperatingInstructions/detail/", // 获取指引详情数据

  post_ymsSupplierMerchant_registry: "/ymsSupplierMerchant/registry", // 供应商商户注册
  post_ymsSupplierMerchant_updateSupplierMerchantInfo:
    "/ymsSupplierMerchant/updateSupplierMerchantInfo", // 企业信息注册

  post_ymsNoticeInfo_query: "/ymsNoticeInfo/query", // 查询系统公告列表数据
  post_ymsNoticeInfo: "/ymsNoticeInfo", // 新增系统公共
  put_ymsNoticeInfo: "/ymsNoticeInfo", // 编辑系统公告
  get_ymsNoticeInfo: "/ymsNoticeInfo/", // 获取系统公告详情
  put_ymsNoticeInfo_checkNoticeInfo: "/ymsNoticeInfo/checkNoticeInfo", // 审核/退回/取消发送
  put_ymsNoticeInfo_cancelSend: "/ymsNoticeInfo/cancelSend", // 取消发送
  delete_ymsNoticeInfo: "/ymsNoticeInfo/", // 删除系统公告

  post_ymsDistributorLabel: "/ymsDistributorLabel", // 新增客户标签
  get_ymsDistributorLabel_list: "/ymsDistributorLabel/list", // 获取所有客户标签数据(无权限控制)
  get_ymsDistributorLabel_query: "/ymsDistributorLabel/query", // 获取客户标签列表数据
  put_ymsDistributorLabel_update: "/ymsDistributorLabel/update", // 编辑客户标签
  delete_ymsDistributorLabel: "/ymsDistributorLabel/", // 删除客户标签

  post_ymsToker: "/ymsToker", // 新增拓客链接
  put_ymsToker: "/ymsToker", // 编辑拓客链接
  post_ymsToker_detail: "/ymsToker/detail", // 查看拓客链接详情
  post_ymsToker_query: "/ymsToker/query", // 获取拓客链接列表数据

  post_ymsTokerLabel_query: "/ymsTokerLabel/query", // 拓客标签列表数据
  post_ymsTokerLabel_detail: "/ymsTokerLabel/detail", // 拓客标签详情
  get_ymsTokerLabel_type: "/ymsTokerLabel/type/", // 拓客标签类型

  post_ymsMerchantGroup_insertYmsMerchantGroup:
    "/ymsMerchantGroup/insertYmsMerchantGroup", // 新增客户分组
  post_ymsMerchantGroup_deleteByYmsMerchantGroup:
    "/ymsMerchantGroup/deleteByYmsMerchantGroup", // 删除客户分组
  get_ymsMerchantGroup_queryTabGroupName: "/ymsMerchantGroup/queryTabGroupName", // 获取客户分组数据
  put_ymsMerchantGroup_saveYmsMerchantGroup:
    "/ymsMerchantGroup/saveYmsMerchantGroup", // 编辑客户分组
  get_ymsDistributorMerchant_ymsMerchantGroup:
    "/ymsDistributorMerchant/ymsMerchantGroup/", // 查询当前客户分组下是否有客户
  post_ymsDistributorMerchant_mobileGrouping:
    "/ymsDistributorMerchant/mobileGrouping", // 移动分组

  get_ymsLangDictionaryDetail_queryLang: "/ymsLangDictionaryDetail/queryLang", // 获取系统的语种配置
  get_common_countrys_query: "/common/country/query", // 获取所有国家的基础数据
  get_common_state_queryByCountryId: "/common/state/queryByCountryId", // 根据countryId（国家id）获取对应省级区域的基础数据
  get_common_city_queryByStateCode: "/common/city/queryByStateCode", // 根据code获取对应市级区域的基础数据
  get_common_district_queryByCityCode: "/common/district/queryByCityCode", // 根据code获取对应区级区域的基础数据
  get_common_country_queryZoneCountry: "/common/country/queryZoneCountry", // 获取地区国家的基础数据
  get_common_country_getDetailByTwoCode: "/common/country/getDetailByTwoCode/", // 根据国家二次码获取省市区地址的基础数据

  post_ymsSubPackageInfo_querySupplierPackageInfoOrder:
    "/ymsSubPackageInfo/querySupplierPackageInfoOrder", // 获取供应商订单数据
  get_ymsSupplierMerchant_validateMobileOrEmail:
    "/ymsSupplierMerchant/validateMobileOrEmail/", // 供应商入驻新增手机号校验

  post_ymsSubPackageInfo_exportSupplierPackageInfoOrder:
    "/ymsSubPackageInfo/exportSupplierPackageInfoOrder", // 导出供应商订单列表数据
  post_ymsSubPackageInfo_markOutStock: "/ymsSubPackageInfo/markOutStock", // 标记缺货
  get_ymsSubPackageInfo_querySupplierLog:
    "/ymsSubPackageInfo/querySupplierLog/", // 获取供应商日志

  post_ymsAddressTemplate_queryList: "/ymsAddressTemplate/queryList", // 获取地址库管理的列表数据
  post_ymsAddressTemplate_insert: "/ymsAddressTemplate/insert", // 新增地址库数据
  put_ymsAddressTemplate: "/ymsAddressTemplate", // 更新地址库数据
  get_ymsAddressTemplate_detail: "/ymsAddressTemplate/detail/", // 获取地址库的详情数据
  put_ymsAddressTemplate_enable: "/ymsAddressTemplate/enable/", // 启用地址模板
  post_ymsAddressTemplate_disable: "/ymsAddressTemplate/disable", // 停用地址模板
  post_ymsAddressTemplate_chooseList: "/ymsAddressTemplate/chooseList", // 获取所有的地址模板数据（无权限控制）

  post_ymsListingCategoryMapping_enable: "/ymsListingCategoryMapping/enable/", // 启用刊登映射平台
  post_ymsListingCategoryMapping_disable: "/ymsListingCategoryMapping/disable/", // 停用刊登映射平台

  get_ymsWarehousePriceModel_query: "/ymsWarehousePriceModel/query", // 获取分销商费用配置列表数据
  post_ymsWarehousePriceModel: "/ymsWarehousePriceModel", // 新增分销商费用配置
  put_ymsWarehousePriceModel: "/ymsWarehousePriceModel", // 更新分销商费用配置

  post_ymsBill_queryLogisticsBill: "/ymsBill/queryLogisticsBill", // 物流账单列表
  get_ymsBill_queryCarrier: "/ymsBill/queryCarrier", // 获取已经生成账单的物流商
  get_ymsBill_detailLogisticsBill: "/ymsBill/detailLogisticsBill/", // 获取物流账单详情数据
  put_ymsBill_confirmSettlement: "/ymsBill/confirmSettlement/", // 物流账单--确认结算
  post_ymsBill_excelLogisticsBill: "/ymsBill/excelLogisticsBill", // 物流账单明细导入
  post_ymsBill_exportLogisticsBill: "/ymsBill/exportLogisticsBill", // 物流账单导出
  post_ymsBill_exportLogisticsBillDetail: "/ymsBill/exportLogisticsBillDetail", // 物流账单明细导出
  post_ymsBillPackageDetail_queryLogisticsBillPackageDetail:
    "/ymsBillPackageDetail/queryLogisticsBillPackageDetail", // 获取物流账单明细列表数据
  post_ymsBillPackageDetail_updateLogisticsYmsBillPackageDetail:
    "/ymsBillPackageDetail/updateLogisticsYmsBillPackageDetail", // 物流账单--账单明细--修改
  post_ymsBillPackageDetail_insertRemarks:
    "/ymsBillPackageDetail/insertRemarks", // 添加物流账单明细备注
  post_ymsBillPackageDetail_unreconciledAndProfitAndLoss:
    "/ymsBillPackageDetail/unreconciledAndProfitAndLoss", // 获取未对账和盈亏数据
  post_ymsBillPackageDetail_updateLogisticsBillCurrency:
    "/ymsBillPackageDetail/updateLogisticsBillCurrency", // 物流账单币种修改

  post_ymsBill_queryForWarehouseBill: "/ymsBill/queryForWarehouseBill", // 获取仓储账单列表数据
  post_ymsBill_updateWarehouseBill: "/ymsBill/updateWarehouseBill", // 更新仓储账单
  put_ymsBill_confirmWarehouseSettlement:
    "/ymsBill/confirmWarehouseSettlement/", // 确认结算
  post_ymsBill_exportWarehouseBill: "/ymsBill/exportWarehouseBill", // 仓储账单--导出(账单维度)
  post_ymsBill_exportWarehouseDetailBill: "/ymsBill/exportWarehouseDetailBill", // 仓储账单--导出(明细维度)
  get_ymsBill_warehouseBill: "/ymsBill/warehouseBill/", // 获取仓储账单详情数据
  post_ymsBillPackageDetail_queryWarehouseBillPackageDetail:
    "/ymsBillPackageDetail/queryWarehouseBillPackageDetail", // 获取仓储账单明细列表数据
  post_ymsBillPackageDetail_insertWarehouseRemarks:
    "/ymsBillPackageDetail/insertWarehouseRemarks", // 仓储账单--添加备注
  post_ymsBillPackageDetail_insertYmsBillPackageDetail:
    "/ymsBillPackageDetail/insertYmsBillPackageDetail", // 仓库账单--明细信息--添加费用
  post_ymsBillPackageDetail_updateWarehouseYmsBillPackageDetail:
    "/ymsBillPackageDetail/updateWarehouseYmsBillPackageDetail", // 仓库账单--明细信息--修改费用
  post_ymsBillPackageDetail_queryWarehouseYmsBillPayoffAmount:
    "/ymsBillPackageDetail/queryWarehouseYmsBillPayoffAmount", // 仓库账单--明细信息--盈亏

  post_ymsBill_queryForDistributorBill: "/ymsBill/queryForDistributorBill", // 客户账单列表数据
  post_ymsBill_exportDistributorBill: "/ymsBill/exportDistributorBill", // 客户账单列表--导出(账单维度)
  post_ymsBill_exportDistributorDetailBill:
    "/ymsBill/exportDistributorDetailBill", // 客户账单列表--导出(明细维度)
  get_ymsBill_distributorBill: "/ymsBill/distributorBill/", // 获取客户账单详情数据
  post_ymsBillPackageDetail_billDetailRechargeRecord:
    "/ymsBillPackageDetail/billDetailRechargeRecord", // 获取客户账单充值记录明细
  post_ymsBillPackageDetail_billDetailPackageInfo:
    "/ymsBillPackageDetail/billDetailPackageInfo", // 获取客户账单消费记录明细

  post_ymsNoticeInfo_queryNewNoticeInfo: "/ymsNoticeInfo/queryNewNoticeInfo", // 获取系统最新公告
  post_ymsNoticeInfo_disable: "/ymsNoticeInfo/disable", // 标记系统公告已读
  post_ymsNoticeInfo_queryNoticeInfoCount:
    "/ymsNoticeInfo/queryNoticeInfoCount", // 获取系统公告未读数据

  get_ymsIossRateSetting_query: "/ymsIossRateSetting/query", // 获取ioss费率设置列表数据
  post_ymsIossRateSetting_insert: "/ymsIossRateSetting/insert", // 新增ioss费率设置
  put_ymsIossRateSetting_update: "/ymsIossRateSetting/update", // 更新ioss费率设置
  put_ymsIossRateSetting_disable: "/ymsIossRateSetting/disable/", // 停用ioss费率设置
  put_ymsIossRateSetting_enable: "/ymsIossRateSetting/enable/", // 启用ioss费率设置

  put_ymsPackageInfo_notifyDistributor: "/ymsPackageInfo/notifyDistributor/", // 通知分销商
  post_ymsPickupOrder_updateLogisticsProviderInfo:
    "/ymsPickupOrder/updateLogisticsProviderInfo", // 修改物流信息

  get_ymsStockUpInfo_queryTabTotal: "/ymsStockUpInfo/queryTabTotal", // 获取分销商备货单待处理的数量
  post_ymsStockUpInfo_query: "/ymsStockUpInfo/query", // 获取分销商备货单列表数据
  put_ymsStockUpInfo_executeCreateOrder: "/ymsStockUpInfo/executeCreateOrder/", // 下单
  post_ymsStockUpInfo_saveBargain: "/ymsStockUpInfo/saveBargain", // 调价
  get_ymsSubPackageInfo_queryStockPackageDetail:
    "/ymsSubPackageInfo/queryStockPackageDetail/", // 获取备货单的详情数据

  post_ymsPackageInfo_queryAssembleWaitPickingPackageInfo:
    "/ymsPackageInfo/queryAssembleWaitPickingPackageInfo", // 查询组装加工生成拣货单列表
  post_ymsPickingOrder_queryAssembleList: "/ymsPickingOrder/queryAssembleList", // 查询组装加工拣货单列表
  post_ymsPickingOrder_printPickingList: "/ymsPickingOrder/printPickingList", // 打印拣货单(组装)
  get_ymsPickingOrder_printAssemblyLabel:
    "/ymsPickingOrder/printAssemblyLabel/", // 打印组装标签

  get_ymsDistributorMerchant_api_queryYmsCoreMerchant:
    "/ymsDistributorMerchant/api/queryYmsCoreMerchant", // 获取运营商信息

  post_ymsSite_queryAllYmsSite: "/ymsSite/queryAllYmsSite", // 获取站点管理列表数据-分页
  get_ymsSite_queryAll: "/ymsSite/queryAll", // 获取所有站点管理列表数据-无分页
  put_ymsSite: "/ymsSite", // 编辑站点信息
  get_ymsSite_detail: "/ymsSite/detail/", // 获取站点信息
  post_ymsProductCategory_queryByYmsProductCategoryIds:
    "/ymsProductCategory/queryByYmsProductCategoryIds", // 根据分类id批量查询分类信息
  get_ymsProductCategory_querySubCategorysById:
    "/ymsProductCategory/querySubCategorysById/", // 获取当前分类下的子类目数据
  post_ymsProductCategory_queryCategorysByName:
    "/ymsProductCategory/queryCategorysByName", // 根据关键字查询类目

  get_ymsBase_info_query: '/ymsBase/info/query', // 获取服务配置的域名数据
  post_ymsPackageInfo_cancelPackageInfo: "/ymsPackageInfo/cancelPackageInfo", // 取消云卖订单
  post_productGoods_api_queryPodProductList: "/erpCommon/yms-supplier-service/productGoods/api/queryPodProductList", //工厂商品查询
  get_ymsProductCategory_navigation: "/ymsProductCategory/productCategoryNavigation", //{productCategoryNavigation} 根据主料查询否能匹配上YMS商品类目表中类目信息

  post_ymsProductTag_create: '/ymsProductTag/create', // 创建商品标签
  put_ymsProductTag_update: '/ymsProductTag/update', // 编辑商品标签
  delete_ymsProductTag_delete: '/ymsProductTag/delete/', // 删除商品标签
  get_ymsProductTag_queryList: '/ymsProductTag/queryList', // 商品标签列表
  get_ymsProductTag_queryAllList: '/ymsProductTag/queryAllList', // 获取商品标签所有数据（无权限）
  post_productInfo_batchSetProductTagRelated: '/productInfo/batchSetProductTagRelated', // 批量配置商品标签

  post_ymsProductSaleCountry_batchSetSaleCountry: '/ymsProductSaleCountry/batchSetSaleCountry', // 设置商品可售卖国
  get_ymsProductSaleCountry_queryYmsProductSaleCountry: '/ymsProductSaleCountry/queryYmsProductSaleCountry/', // 获取商品可售卖国

  post_ymsReceivePayAccount_create: '/ymsReceivePayAccount/create', // 新增收款账户
  post_ymsReceivePayAccount_queryList: '/ymsReceivePayAccount/queryList', // 收款账户列表
  put_ymsReceivePayAccount_update: '/ymsReceivePayAccount/update', // 编辑收款账户
  delete_ymsReceivePayAccount: '/ymsReceivePayAccount/', // 删除收款账户
  get_ymsReceivePayAccount_detail: '/ymsReceivePayAccount/detail/', // 获取收款账户详情
  put_ymsReceivePayAccount_enableOrDisable: '/ymsReceivePayAccount/enableOrDisable', // 启用、停用收款账户

  post_ymsPackagePay_query: '/ymsPackagePay/query', // 订单支付对账列表
  post_ymsPackagePay_confirmPayVoucher: '/ymsPackagePay/confirmPayVoucher', // 对账确认
  get_ymsReceivePayAccount_queryAll: '/ymsReceivePayAccount/queryAll', // 查询所有的收款账户

  get_ymsProductAttributeTemplate_getRelateTemplates: '/ymsProductAttributeTemplate/getRelateTemplates/', // 获取关联属性数据
  post_erpCommonSettingParam_saveSetting: '/erpCommonSettingParam/saveSetting',

  post_ymsPackageInfo_updatePackagePrice: '/ymsPackageInfo/updatePackagePrice', // 修改订单金额
};

for (let key in ymsApi) {
  ymsApi[key] = "/yms-core-service" + ymsApi[key];
}

export default ymsApi;
